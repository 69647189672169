import { ActionTypes } from "../../../constants/ActionConstants";

export const getCampaignPerformanceByCityId = (campaignId, cityId) => ({
  type: ActionTypes.CampaignPerformance.GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID,
  payload: { campaignId, cityId },
});

export const getCampaignPerformanceById = (campaignId) => ({
  type: ActionTypes.CampaignPerformance.GET_CAMPAIGN_PERFORMANCE_BY_ID,
  payload: { campaignId },
});
