import { useDispatch } from "react-redux";

// actions
import { closeConfirmationForm } from "../../actions/ConfirmationFormActions";
import { FormDataTargets } from "../../constants/GeneralConstants";

/**
 * Confirmation Form Modal
 * it will trigger the function on Form-Submit
 * @input bodyText -> text message,
 * buttonLabel -> submit button label
 */
function ConfirmationForm({
  bodyText = "Are you sure?",
  buttonLabel = "Yes",
  onClick,
}) {
  // Dispatch
  const dispatch = useDispatch();
  return (
    <div
      className="modal fade"
      id={FormDataTargets.confirmationForm}
      data-backdrop="static"
    >
      <div className="modal-dialog mt-2" role="document">
        <div className="modal-content">
          {/* Form body */}
          <div className="modal-body px-5 py-4">
            <p className="mb-0 h5">{bodyText}</p>
          </div>

          {/* Form Footer */}
          <div className="modal-footer border-0 px-5">
            <button
              type="button"
              className="btn btn-sm btn-outline-primary rounded-lg shadow-none"
              data-dismiss="modal"
              onClick={() => dispatch(closeConfirmationForm())}
            >
              {"No"}
            </button>
            <button
              type="button"
              className="btn btn-sm btn-primary rounded-lg shadow-none"
              data-dismiss="modal"
              onClick={() => {
                onClick();
                dispatch(closeConfirmationForm());
              }}
            >
              {buttonLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationForm;
