import { useDispatch } from "react-redux";

// Utils & constants
import { CheckBox } from "./MapControlsUtils";
import { useUrlKeyCheck } from "../../../mavin/utils/hooks/HooksUtil";
import { UrlKeys } from "../../../constants/GeneralConstants";

// Actions
import {
  setBoundarySteps,
  setMaxColor,
} from "../../../actions/map-view/BoundariesActions";

// Components
import CardCollapsible from "../../../components/card-collapsible/CardCollapsible";
import { PlainDropdown } from "../../../components/dropdown/Dropdown";

// Page Components
function TitleSection({ selectedBoundary }) {
  const boundary = selectedBoundary.split("_")[0];
  const boundaryType = selectedBoundary.split("_")[1];

  if (!boundary || boundary === "None" || boundary === "none") {
    return null;
  }
  return <p className="sec-title">{`${boundary} :: ${boundaryType}`}</p>;
}

// Color Picker
function ColorPicker({ label = "", value, onChange }) {
  return (
    <div className="form-group col-7 pr-0">
      <label htmlFor="color-picker" className="mb-0">
        {label}
      </label>
      <span title="Choose Dark Color">
        <i className="fa fa-info-circle ml-1"></i>
      </span>
      <input
        type="color"
        className="form-control col-8 shadow-none p-0"
        value={value}
        onChange={onChange}
        id="color-picker"
      />
    </div>
  );
}

// Boundaries Setting
function BoundariesSetting({ boundaryMapSettings }) {
  const dispatch = useDispatch();

  const { maxColor, noOfSteps } = boundaryMapSettings;

  const dropdownItems = [
    { id: 2, label: "2" },
    { id: 3, label: "3" },
    { id: 4, label: "4" },
    { id: 5, label: "5" },
    { id: 6, label: "6" },
  ];
  return (
    <div className="d-flex justify-content-between">
      <div className="form-group col-5 px-0">
        <label htmlFor="dropDown" className="mb-0">
          {"No. Of Steps"}
        </label>

        <PlainDropdown
          items={dropdownItems}
          selectedId={noOfSteps}
          className="form-control shadow-none"
          onItemSelect={(value) => dispatch(setBoundarySteps(value))}
        />
      </div>

      <ColorPicker
        label={"Max Color"}
        value={maxColor}
        onChange={({ target }) => dispatch(setMaxColor(target.value))}
      />
    </div>
  );
}

// Control Panel Components
function BoundariesCheckBoxes({ settings }) {
  const {
    boundaryLegendData = [],
    selectedBoundary = "",
    boundaryMapSettings,
  } = settings;

  return (
    <div className="sec">
      {/* Boundaries Setting */}
      <BoundariesSetting boundaryMapSettings={boundaryMapSettings} />

      <TitleSection selectedBoundary={selectedBoundary} />
      {/* data legend section */}
      {boundaryLegendData.map((item) => {
        const [minVal, maxVal, colorHex] = item;
        const labelText = (
          <>
            <span
              className="border px-4 mr-2"
              style={{ backgroundColor: colorHex }}
            ></span>
            <span>{`${minVal} - ${maxVal}`}</span>
          </>
        );

        return (
          <div key={colorHex} className="d-flex">
            <CheckBox
              key={colorHex}
              id={colorHex}
              name={colorHex}
              labelText={labelText}
              disabled={true}
            />
          </div>
        );
      })}
    </div>
  );
}

// Controls :: Boundaries
function BoundariesControlsPanel({ settings = {} }) {
  const selectedBoundary = settings.selectedBoundary;

  const { campaignPlanning, cityAnalyser } = UrlKeys;
  // plan and city-analyser key will not present in campaign analyser page url
  // so this will return true for campaign planner page and city analyser tool
  // if the plan is present in url then its is from campaign planning page
  const isDisabled = useUrlKeyCheck(campaignPlanning, cityAnalyser);
  return (
    <CardCollapsible
      id="boundaries-controls"
      header={"Boundaries Settings"}
      subHeader={`${!selectedBoundary ? "No Boundaries are selected" : ""}`}
      disabled={!isDisabled}
    >
      <BoundariesCheckBoxes settings={settings} />
    </CardCollapsible>
  );
}

export default BoundariesControlsPanel;
