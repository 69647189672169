/**
 * Search Input Component ::
 */
export default function SearchInput({
  placeholder,
  className = "",
  onClickSearch,
  onTextChange,
  searchButton = true,
}) {
  return (
    <div>
      <div className="input-group">
        <input
          type="search"
          className={`form-control ${className}`}
          placeholder={placeholder}
          aria-label={placeholder}
          aria-describedby="btn-search"
          onChange={onTextChange}
        />
        {searchButton && (
          <div className="input-group-append">
            <span
              className="input-group-text cursor-pointer"
              id="btn-search"
              onClick={onClickSearch}
            >
              <i className="fa fa-search" />
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
