import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Apis
import { addOrgMediaImagesFn, getOrgMediaInfoFn } from "../../apis/OrgMediaAPI";
import { getkypTraffic } from "../../apis/CampaignMediaAPI";

// Constants and Utils
import { OrgMedia } from "../../constants/action-constants/org/OrgMediaActionConstants";
import { setErrorStatusCode } from "../../utils/ErrorUtils";
import { getErrorMessage } from "../../utils/util";

/**
 *  Org media info
 */
export function* getOrgMediaInfo(action) {
  const { mediaId, orgId } = action.payload;

  try {
    // Api call
    const orgMediaInfo = yield getOrgMediaInfoFn(orgId, mediaId);
    const kypTrafficData = {};

    // Api to get traffic data
    if (orgMediaInfo.roadSegmentIds?.length > 0) {
      const roadSegmentId = orgMediaInfo.roadSegmentIds[0];
      Object.assign(kypTrafficData, yield getkypTraffic(roadSegmentId));
    }

    yield put({
      type: OrgMedia.GET_ORG_MEDIA_INFO_SUCCESS,
      orgMediaInfo: orgMediaInfo,
      kypTrafficData: kypTrafficData?.result,
    });
  } catch (error) {
    // stores the error and render the error image when the api fails
    setErrorStatusCode(error);

    yield put({
      type: OrgMedia.GET_ORG_MEDIA_INFO_FAILURE,
      payload: error,
    });
  }
}

// Add-Org Media Images
export function* addOrgMediaImages(action) {
  const { orgId, mediaId, files } = action.payload;

  try {
    const sellerMediaFileItems = yield addOrgMediaImagesFn(
      orgId,
      mediaId,
      files
    );

    yield put({
      type: OrgMedia.ADD_ORG_MEDIA_IMAGES_SUCCESS,
      sellerMediaFileItems: sellerMediaFileItems,
    });
    toast.success("Successfully Added");
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: OrgMedia.ADD_ORG_MEDIA_IMAGES_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(OrgMedia.GET_ORG_MEDIA_INFO, getOrgMediaInfo),
    takeLatest(OrgMedia.ADD_ORG_MEDIA_IMAGES, addOrgMediaImages),
  ]);
}
