import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { resetLocationSummary } from "../../../actions/location-summary/LocationSummaryActions";
import { resetSingleSiteAnalyserInfo } from "../../../actions/mavin-tools/SingleSiteAnalyserActions";

// Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Pages and Sections
import SiteDetailAndMapSection from "./SiteDetailAndMapSection";
import TrafficAndImpressionSection from "./TrafficAndImpressionSection";
import SiteLocationSummarySection from "./SiteLocationSummarySection";
import MediaInfoAndPerformanceSection from "./MediaInfoAndPerformanceSection";

/**
 * TOOL - Site Analyser Page
 */
function SiteAnalyserToolPage() {
  const dispatch = useDispatch();
  // State
  const [radius, setRadius] = useState(1000);
  const [locationStr, setLocationStr] = useState("");
  const [coordinate, setCoordinate] = useState([]);

  // Selector States
  // Single Site Info
  const singleSiteAnalyserInfo = useSelector(
    (state) => state.singleSiteAnalyserTool.singleSiteAnalyserInfo
  );

  const {
    regionInfo = {},
    mavinMetrics = {},
    roads = [],
  } = singleSiteAnalyserInfo;

  // Reset/ clearing all info
  useEffect(() => {
    dispatch(resetSingleSiteAnalyserInfo());
    dispatch(resetLocationSummary());

    // to clear the Coordinates
    if (!locationStr) {
      setCoordinate([]);
    }
  }, [dispatch, locationStr]);

  return (
    <div className="content-wrapper">
      {/* Map Left Section */}
      <PageHeader
        title={"Single Media Site Report"}
        shadow={false}
        border={true}
      />

      <div className="page-content">
        {/* Media Details and Map Section */}
        <SiteDetailAndMapSection
          locationStr={locationStr}
          setLocationStr={setLocationStr}
          coordinate={coordinate}
          setCoordinate={setCoordinate}
          radius={radius}
        />
        <hr className="divider"></hr>

        {/* Site Information Section */}
        <MediaInfoAndPerformanceSection
          mavinMetrics={mavinMetrics}
          regionInfo={regionInfo}
        />

        {/* Hourly Traffic section */}
        {Object.keys(singleSiteAnalyserInfo).length > 0 && (
          <TrafficAndImpressionSection roads={roads} />
        )}

        {/* Site Location Data Section */}
        <SiteLocationSummarySection
          setRadius={setRadius}
          coordinate={coordinate}
        />
      </div>
    </div>
  );
}

export default SiteAnalyserToolPage;
