export const Sec = {
  GET_SEC_INFO: "GET_SEC_INFO",
  GET_SEC_INFO_SUCCESS: "GET_SEC_INFO_SUCCESS",
  GET_SEC_INFO_FAILURE: "GET_SEC_INFO_FAILURE",

  // Select Sec
  SELECT_SEC: "SELECT_SEC",

  // Remove selected sec
  REMOVE_SELECTED_SEC: "REMOVE_SELECTED_SEC",

  // Reset Sec
  RESET_SEC: "RESET_SEC",
};
