import { Link } from "react-router-dom";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Constants and Utils
import { checkIsSellerUser } from "../../utils/SellerUtils";

// Page Constants
const {
  helpOtsPageUrl,
  helpLtsPageUrl,
  helpCovidChangePageUrl,
  helpDefinitionPageUrl,
  helpVideosPageUrl,
} = RedirectTo;

const sidebarGeneralConstants = [
  {
    label: "Definitions",
    pageUrl: helpDefinitionPageUrl,
  },
  {
    label: "Videos",
    pageUrl: helpVideosPageUrl,
  },
];

const sidebarMethodologiesConstants = [
  {
    label: "OTS",
    pageUrl: helpOtsPageUrl,
  },
  {
    label: "LTS",
    pageUrl: helpLtsPageUrl,
  },
  {
    label: "Covid % Change",
    pageUrl: helpCovidChangePageUrl,
  },
];

/**
 * Help Sidebar
 */
export default function HelpSidebar({ activePageUrl = "" }) {
  // Checking the USER is Seller or not
  const isSellerUser = checkIsSellerUser();

  return (
    <aside className="sidebar bg-alt">
      <ul className="nav flex-column mt-3">
        <li className="nav-item group-header">General</li>
        {sidebarGeneralConstants.map((info, i) => (
          <li
            key={i}
            className={`nav-item ${activePageUrl === info.pageUrl && "active"}`}
          >
            <Link className="nav-link" to={info.pageUrl}>
              {info.label}
            </Link>
          </li>
        ))}

        {/* Hiding in case of "Seller-Login" */}
        {!isSellerUser && (
          <>
            <li className="nav-item group-header">Methodologies</li>
            {sidebarMethodologiesConstants.map((info, i) => (
              <li
                key={i}
                className={`nav-item ${
                  activePageUrl === info.pageUrl && "active"
                }`}
              >
                <Link className="nav-link" to={info.pageUrl}>
                  {info.label}
                </Link>
              </li>
            ))}
          </>
        )}
      </ul>
    </aside>
  );
}
