import {
  confirmCampaignPlanUrl,
  addSellerMediaToCampaignUrl,
} from "../urls/CampaignConfirmationURL";
import { sendCreateOrUpdateRequest } from "../../utils/api/apiUtils";

export async function confirmCampaignPlan(campaignId, bean) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = confirmCampaignPlanUrl.replace(":campaignId", campaignId);

  return sendCreateOrUpdateRequest(url, bean, true, "PUT", headerParams);
}

export async function addSellerMediaToCampaign(
  campaignId,
  sellerIdToSplitOtsBean
) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = addSellerMediaToCampaignUrl.replace(":campaignId", campaignId);
  return sendCreateOrUpdateRequest(
    url,
    sellerIdToSplitOtsBean,
    true,
    "POST",
    headerParams
  );
}
