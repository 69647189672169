import { CompareLocations } from "../../constants/action-constants/mavin-tools/CompareLocationsActionConstants";

const initialState = {
  // for Compare Location Tool
  // ---------------------------------------------------
  compareLocationSummary: "",
  compareLocationSummaryError: "",
  compareLocationSummaryLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CompareLocations.GET_COMPARE_LOCATION_SUMMARY:
      return {
        ...state,
        compareLocationSummaryLoading: true,
      };

    case CompareLocations.GET_COMPARE_LOCATION_SUMMARY_SUCCESS:
      return {
        ...state,
        compareLocationSummaryLoading: false,
        compareLocationSummary: action.compareLocationSummary,
      };

    case CompareLocations.GET_COMPARE_LOCATION_SUMMARY_FAILURE:
      return {
        ...state,
        compareLocationSummaryLoading: false,
        compareLocationSummaryError: action.payload,
      };

    case CompareLocations.CLEAR_COMPARE_LOCATION_SUMMARY:
      return initialState;

    default:
      return state;
  }
};
