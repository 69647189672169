//Constants
import { RouteTypes } from "./TabConstants";

export default function ArterialRouteTab() {
  return (
    <>
      <h2 className="mb-0">Route Type</h2>
      <hr className="my-3" />
      <div className="px-3">
        {RouteTypes.map((route, index) => (
          <div className="d-flex justify-content-between mt-2" key={index}>
            <label className="mb-0">{route}</label>
            <input type="checkbox" />
          </div>
        ))}
      </div>
    </>
  );
}
