/* eslint-disable no-case-declarations */
import { OrgMediaPrice } from "../../constants/action-constants/org/OrgMediaPriceActionConstants";

const initialState = {
  // get media price by ids
  mediaPriceInfo: {},
  mediaPriceInfoLoading: false,
  mediaPriceInfoError: "",

  // update media price
  updateMediaPriceLoading: {},
  updateMediaPriceError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // get media price by ids
    // ---------------------------------------------------------------------------
    case OrgMediaPrice.GET_MEDIA_PRICE_BY_IDS:
      return {
        ...state,
        mediaPriceInfoLoading: true,
      };
    case OrgMediaPrice.GET_MEDIA_PRICE_BY_IDS_SUCCESS: {
      const { mediaPriceInfo } = action.payload;
      return {
        ...state,
        mediaPriceInfoLoading: false,
        mediaPriceInfo,
      };
    }
    case OrgMediaPrice.GET_MEDIA_PRICE_BY_IDS_FAILURE:
      return {
        ...state,
        mediaPriceInfoLoading: false,
        mediaPriceInfoError: action.payload,
      };

    // update media price
    // ---------------------------------------------------------------------------
    case OrgMediaPrice.UPDATE_MEDIA_PRICE:
      const { mediaId } = action.payload.mediaPriceBean;

      return {
        ...state,
        updateMediaPriceLoading: { [mediaId]: true },
      };
    case OrgMediaPrice.UPDATE_MEDIA_PRICE_SUCCESS: {
      return {
        ...state,
        updateMediaPriceLoading: {},
      };
    }
    case OrgMediaPrice.UPDATE_MEDIA_PRICE_FAILURE:
      return {
        ...state,
        updateMediaPriceLoading: {},
        updateMediaPriceError: action.payload,
      };
    default:
      return state;
  }
};
