import { ActionTypes } from "../../../constants/ActionConstants";

const initialState = {
  // Media Setup-Monitoring -----------------------------------------------
  setupMonitoringLoading: false,
  setupMonitoringSuccess: false,
  setupMonitoringError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Media Setup-Monitoring
    // -------------------------------------------------------------------
    case ActionTypes.CampaignConfirmation.MEDIA_SETUP_MONITORING:
      return {
        ...state,
        setupMonitoringLoading: true,
      };

    case ActionTypes.CampaignConfirmation.MEDIA_SETUP_MONITORING_SUCCESS:
      return {
        ...state,
        setupMonitoringSuccess: true,
        setupMonitoringLoading: false,
      };

    case ActionTypes.CampaignConfirmation.MEDIA_SETUP_MONITORING_FAILURE:
      return {
        ...state,
        setupMonitoringError: action.payload,
        setupMonitoringLoading: false,
      };

    default:
      return state;
  }
};
