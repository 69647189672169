import { ExploreSites } from "../../../constants/action-constants/map-view/media-sites/ExploreSitesActionConstants";

// Constants and Utils
import { filterMedia, sortMediaListByScore } from "../../../utils/MediaUtils";
import {
  DefaultFilterObjects,
  MediaColor,
} from "../../../constants/GeneralConstants";

// initial state
const initialState = {
  originalExploreSites: [],
  exploreSites: [],
  pagination: {},
  err: "",
  loading: true,

  // This stores the total media Sites
  totalExploreSites: [],

  // Inventory Media Fill Color
  inventoryMediaFillColor: MediaColor.inventory, // Orange

  // InventoryMedia Filters
  inventoryMediaFilterObj: DefaultFilterObjects.media,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ExploreSites.GET_EXPLORE_SITES:
      return {
        ...state,
        loading: true,
        exploreSites: [],
      };
    case ExploreSites.GET_EXPLORE_SITES_SUCCESS: {
      const { items, pagination } = action.payload;
      // Filtering the Media According to the SavedMapState
      const filteredMediaList = filterMedia(
        sortMediaListByScore(items),
        state.inventoryMediaFilterObj
      );
      return {
        ...state,
        loading: false,
        pagination: pagination,
        originalExploreSites: sortMediaListByScore(items),
        exploreSites: filteredMediaList,
        totalExploreSites: state.totalExploreSites.concat(items),
      };
    }

    case ExploreSites.GET_EXPLORE_SITES_FAILURE:
      return {
        ...state,
        err: action.payload,
        loading: false,
      };

    // set InventoryMedia Fill Color
    case ExploreSites.SET_INVENTORY_MEDIA_FILL_COLOR: {
      const { color } = action.payload;
      return {
        ...state,
        inventoryMediaFillColor: color,
      };
    }

    // set InventoryMedia (Explore Sites) FILTERS
    case ExploreSites.SET_INVENTORY_MEDIA_FILTERS: {
      const { appliedFiltersObj } = action.payload;
      const filteredMediaList = filterMedia(
        state.originalExploreSites,
        appliedFiltersObj
      );
      return {
        ...state,
        inventoryMediaFilterObj: appliedFiltersObj,
        exploreSites: filteredMediaList,
      };
    }

    // set InventoryMedia FILTERS
    case ExploreSites.RESET_EXPLORE_SITES: {
      return initialState;
    }

    default:
      return state;
  }
};
