// TODO Move this to components folder
function CheckBox({
  boxStyle = "",
  id,
  name,
  labelText,
  checked = true,
  disabled = false,
  onChange,
}) {
  return (
    <div className={`form-check ${boxStyle}`}>
      <input
        type="checkbox"
        className="form-check-input"
        id={id}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={() => onChange(name)}
      />
      <label className="form-check-label" htmlFor={id}>
        {labelText || name}
      </label>
    </div>
  );
}

function Collapse(props) {
  return (
    <div className="panel panel-default" id={props.id}>
      <div className="panel-heading">{props.displayDetails()}</div>
      <div id={props.href} className="panel-collapse collapse in">
        {props.data()}
      </div>
    </div>
  );
}

function CollapseIcon({ dataParent, href }) {
  return (
    <div
      data-toggle="collapse"
      data-parent={dataParent}
      href={href}
      className="pt-2 px-3 media__collapse-icon"
    >
      <i className="fa fa-minus" />
    </div>
  );
}

export { CheckBox, Collapse, CollapseIcon };
