import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Actions
import {
  addSellerMediaImages,
  getSellerMediaInfo,
  removeSellerMediaImage,
  updateSellerMediaInfo,
} from "../../actions/seller/SellerMediaActions";

// Utils and Constants
import { selectFile } from "../../common-utils/file-utils/FileUtils";
import { SiteMapViewSection } from "../mavin-tools/ToolsUtils";
import { ImageFileTypes } from "../../constants/GeneralConstants";
import {
  getCoordinates,
  isValidCoordinate,
} from "../../common-utils/geo-util/coordinatesUtil";

// Components
import Spinner from "../../components/spinner/Spinner";
import MediaInfo from "../../mavin/components/media-info/MediaInfo";
import SiteDetailsFormSection from "../../mavin/components/media-info/SiteDetailsFormSection";

// Page Components
function ExistingImages({
  image,
  imageUrl,
  removeImageApiLoading,
  removedImageUrlMatchLoading,
}) {
  const dispatch = useDispatch();
  const { mediaId } = useParams();

  if (removeImageApiLoading && removedImageUrlMatchLoading) {
    return (
      <div className="img-thumbnail-md d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }
  return (
    <img
      className="img-thumbnail-md cursor-pointer"
      src={imageUrl}
      alt="thumbnail-image"
      onClick={() => dispatch(removeSellerMediaImage(mediaId, image))}
    />
  );
}

function AddImagesButton({ addImagesLoading, onClick }) {
  if (addImagesLoading) {
    return <Spinner />;
  }
  return (
    <button className="btn shadow-none text-primary p-0" onClick={onClick}>
      <i className="fa fa-plus"></i>
      <span className="d-block">{"Add Images"}</span>
    </button>
  );
}

function MediaImageSection({ media }) {
  const dispatch = useDispatch();
  const { id: mediaId, sellerId } = media;

  // Selector States
  const sellerMediaFileItems = useSelector(
    (state) => state.sellerMedia.sellerMediaFileItems
  );
  const addImagesLoading = useSelector(
    (state) => state.sellerMedia.addSellerMediaImagesLoading
  );
  const removeImageApiLoading = useSelector(
    (state) => state.sellerMedia.removeSellerMediaImageLoading
  );
  const removedImage = useSelector((state) => state.sellerMedia.removedImage);

  // upload functions
  async function addSellerMediaImageFn() {
    const files = await selectFile(true, ImageFileTypes);
    dispatch(addSellerMediaImages(sellerId, mediaId, files));
  }

  return (
    <div className="py-3">
      <h2>{"Images "}</h2>

      {/* Images */}
      <div className="row mx-0">
        {sellerMediaFileItems.map((image, i) => {
          const imageUrl = image.thumbnailUrl || "";
          const removedImageUrlMatchLoading =
            imageUrl === removedImage.thumbnailUrl;
          return (
            <div className="img-cont mr-2 mb-2" key={i}>
              <ExistingImages
                image={image}
                imageUrl={imageUrl}
                removeImageApiLoading={removeImageApiLoading}
                removedImageUrlMatchLoading={removedImageUrlMatchLoading}
              />
            </div>
          );
        })}

        {/* Add Images Button */}
        <div className="img-cont mb-2">
          <div className="img-thumbnail-md d-flex justify-content-center align-items-center">
            <AddImagesButton
              addImagesLoading={addImagesLoading}
              onClick={addSellerMediaImageFn}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function EditMediaMapView({ coordinate, setCoordinate }) {
  const coordinateStr = coordinate.join(",");

  function onSelectLocation(coordinateString) {
    if (!coordinateString) {
      return setCoordinate([]);
    }

    if (isValidCoordinate(coordinateString)) {
      setCoordinate(getCoordinates(coordinateString));
    }
  }

  return (
    <SiteMapViewSection
      locationStr={coordinateStr}
      setLocationStr={onSelectLocation}
      coordinate={coordinate}
      setCoordinate={setCoordinate}
    />
  );
}

function MediaSiteDetailAndMapView({ media }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { latitude, longitude, id: mediaId } = media;
  const [coordinate, setCoordinate] = useState([latitude, longitude]);

  const updateSellerMediaInfoLoading = useSelector(
    (state) => state.sellerMedia.updateSellerMediaInfoLoading
  );

  // Function called on "SiteDetailsFormSubmit"
  function dispatchFunction(mediaInfoBean) {
    dispatch(updateSellerMediaInfo(mediaId, mediaInfoBean, history));
  }

  if (Object.keys(media).length === 0) {
    return null;
  }

  return (
    <div className="py-3">
      <h2 className="mb-3">{"Edit Media Info"}</h2>
      <div className="d-flex">
        {/* Component :: Site Details Form */}
        <SiteDetailsFormSection
          media={media}
          className="col-6 single-site-detail pl-0"
          coordinate={coordinate}
          dispatchFunction={dispatchFunction}
          isLoading={updateSellerMediaInfoLoading}
          buttonLabel={"Save Media"}
        />

        {/* map-view */}
        <EditMediaMapView
          coordinate={coordinate}
          setCoordinate={setCoordinate}
        />
      </div>
    </div>
  );
}

/**
 * Page - Edit Seller Media
 */
function SellerEditMediaPage() {
  const dispatch = useDispatch();
  const { mediaId, sellerId } = useParams();

  // Selector
  const media = useSelector((state) => state.sellerMedia.sellerMediaInfo);
  const sellerMediaInfoLoading = useSelector(
    (state) => state.sellerMedia.sellerMediaInfoLoading
  );

  // Dispatch
  useEffect(() => {
    dispatch(getSellerMediaInfo(mediaId, sellerId));
  }, [dispatch, mediaId]);

  // Page Loading
  if (sellerMediaInfoLoading) {
    return <Spinner className="mt-2 spinner-center" />;
  }

  return (
    <div className="content-wrapper">
      {/* Page Content */}
      <div className="page-content">
        {/* Media info  */}
        <MediaInfo media={media} className="border-bottom" />

        {/* Media Image Section */}
        <MediaImageSection media={media} />
        <hr className="divider my-0"></hr>

        {/* Edit Media Inputs + map Section */}
        <MediaSiteDetailAndMapView media={media} />
      </div>
    </div>
  );
}

export default SellerEditMediaPage;
