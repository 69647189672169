import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Apis
import { getBuyersListFn, getUsersByBuyerIdFn } from "../../apis/BuyerAPI";

// Contants and Utils
import { Buyer } from "../../constants/action-constants/BuyerActionConstants";
import { getErrorMessage } from "../../utils/util";

function* getBuyersList() {
  try {
    const buyersList = yield getBuyersListFn();

    yield put({
      type: Buyer.GET_BUYERS_LIST_SUCCESS,
      payload: { buyersList },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Buyer.GET_BUYERS_LIST_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* getUsersByBuyerId(action) {
  try {
    const { buyerId } = action.payload;
    const usersList = yield getUsersByBuyerIdFn(buyerId);

    yield put({
      type: Buyer.GET_USERS_BY_BUYER_ID_SUCCESS,
      payload: { usersList },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Buyer.GET_USERS_BY_BUYER_ID_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(Buyer.GET_BUYERS_LIST, getBuyersList),
    takeLatest(Buyer.GET_USERS_BY_BUYER_ID, getUsersByBuyerId),
  ]);
}
