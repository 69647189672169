import {
  LegendMediaSitesActions,
  MediaInfluenceCircleMarkers,
} from "../../../constants/action-constants/map-view/media-sites/LegendAndInfluenceActionConstants";

// to Hide/Show Media Influence CIrcle
export const addMediaInfluenceCircle = () => ({
  type: MediaInfluenceCircleMarkers.SHOW_MEDIA_INFLUENCE_CIRCLE,
});

export const removeMediaInfluenceCircle = () => ({
  type: MediaInfluenceCircleMarkers.REMOVE_MEDIA_INFLUENCE_CIRCLE,
});

export const setMediaInfluenceCircleRadius = (radius) => ({
  type: MediaInfluenceCircleMarkers.SET_MEDIA_INFLUENCE_CIRCLE_RADIUS,
  payload: { radius },
});

export const showMediaMarkers = (mediaTypeKey) => ({
  type: LegendMediaSitesActions.SHOW_MEDIA_MARKERS_FROM_LEGEND,
  payload: { id: mediaTypeKey },
});

export const removeMediaMarkers = (mediaTypeKey) => ({
  type: LegendMediaSitesActions.REMOVE_MEDIA_MARKERS_FROM_LEGEND,
  payload: { id: mediaTypeKey },
});

export const resetMediaMarkers = () => ({
  type: LegendMediaSitesActions.RESET_MEDIA_MARKERS,
});

export const resetMediaInfluence = () => ({
  type: MediaInfluenceCircleMarkers.RESET_MEDIA_INFLUENCE,
});
