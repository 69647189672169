import { Link } from "react-router-dom";

//Url
import { RedirectTo } from "../../urls/PageLinksURL";

// Utils
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";

// Images
import error400Image from "../../assets/imgs/error/400.svg";
import error401Image from "../../assets/imgs/error/401.svg";
import error403Image from "../../assets/imgs/error/403.svg";
import error404Image from "../../assets/imgs/error/404.svg";
import error500Image from "../../assets/imgs/error/500.svg";
import error503Image from "../../assets/imgs/error/503.svg";

/**
 * ErrorPage
 */
function ErrorPage({ errorStatusCode = 404 }) {
  const errorsInfo = {
    400: {
      imageUrl: error400Image,
      errorMessage: "400 - You've Sent a Bad Request",
    },
    401: {
      imageUrl: error401Image,
      errorMessage: "401 - Authorization Required",
    },
    403: {
      imageUrl: error403Image,
      errorMessage: "403 - Access Forbidden",
    },
    404: {
      imageUrl: error404Image,
      errorMessage: "404 - Oops! The page your looking for does not exist",
    },
    500: {
      imageUrl: error500Image,
      errorMessage: "500 - Oops! Something went wrong",
    },
    503: {
      imageUrl: error503Image,
      errorMessage: "503 - Service Unavailable",
    },
  };

  const { imageUrl, errorMessage } = errorsInfo[errorStatusCode];

  return (
    <div className="d-flex justify-content-center p-5">
      <div className="w-25 text-center">
        <img className="img-fluid" src={imageUrl} alt="error-image" />

        <h4 className="my-3">{errorMessage}</h4>

        <Link
          className="btn btn-outline-primary"
          to={constructRedirectPath(RedirectTo.homeUrl)}
        >
          Back To Home
        </Link>
      </div>
    </div>
  );
}

export default ErrorPage;
