import {
  DummyBoundaryButtonNames,
  DummyMediaTypeButtonNames,
  DummyRouteButtonNames,
  DummyTGButtonNames,
  DummyTouchPointButtonNames,
} from "../campaign-planning/TabConstants";

export const PreviewData = [
  {
    title: "Zone",
    list: DummyBoundaryButtonNames,
  },
  {
    title: "Media Type",
    list: DummyMediaTypeButtonNames,
  },
  {
    title: "Route",
    list: DummyRouteButtonNames,
  },
  {
    title: "Touch Points",
    list: DummyTouchPointButtonNames,
  },
  {
    title: "Target Group",
    list: DummyTGButtonNames,
  },
];

export const SelectionOverviewData = [
  {
    title: "Stretches",
    value: 5,
  },
  {
    title: "Total Sites",
    value: 30,
  },
  {
    title: "Total Impressions",
    value: 11111111,
  },
  {
    title: "TG Impressions",
    value: 11111111,
  },
];

export const SiteSelectionData = [
  {
    title: "Total",
    value: 30,
  },
  {
    title: "Selected",
    value: 10,
  },
  {
    title: "Static",
    value: 30,
  },
  {
    title: "DOOH",
    value: 30,
  },
];

export const OtsData = [
  {
    title: "Impressions",
    value: 11111111,
  },
  {
    title: "TG Impressions",
    value: 400000,
  },
  {
    title: "Estimated Cost",
    value: 1000000,
  },
];
