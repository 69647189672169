import { ActionTypes } from "../../../constants/ActionConstants";

const initialState = {
  // campaign confirm modal
  openModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Open and close campaign confirm modal
    //--------------------------------------------------------------------------------
    case ActionTypes.CampaignConfirmFormModal.OPEN_CAMPAIGN_CONFIRM_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case ActionTypes.CampaignConfirmFormModal.CLOSE_CAMPAIGN_CONFIRM_MODAL:
      return {
        ...state,
        openModal: false,
      };
    default:
      return state;
  }
};
