import { ExploreSites } from "../../../constants/action-constants/map-view/media-sites/ExploreSitesActionConstants";

export const getMapViewExploreSites = (
  cityId,
  isOrgMedia,
  orgId,
  pageNumber,
  pageSize,
  applyFilters
) => ({
  type: ExploreSites.GET_EXPLORE_SITES,
  payload: {
    cityId,
    pn: pageNumber,
    ps: pageSize,
    isOrgMedia,
    applyFilters,
    orgId,
  },
});

// to set Inventory media site Fill Color
export const setInventoryMediaFillColor = (color) => ({
  type: ExploreSites.SET_INVENTORY_MEDIA_FILL_COLOR,
  payload: { color },
});

// to set FILTER InventoryMedia
export const setInventoryMediaAppliedFilters = (appliedFiltersObj) => ({
  type: ExploreSites.SET_INVENTORY_MEDIA_FILTERS,
  payload: { appliedFiltersObj },
});

export const resetExploreSites = () => ({
  type: ExploreSites.RESET_EXPLORE_SITES,
});
