// Constants
import { TouchPointsInputItems } from "./TabConstants";

export default function TouchPointsTab() {
  return (
    <>
      <h2 className="mb-0">Touch Point</h2>
      <hr className="my-3" />
      <div className="px-3">
        {TouchPointsInputItems.map((item) => (
          <div className="d-flex justify-content-between mt-2" key={item.id}>
            <label className="mb-0">{item.label}</label>
            <input type="checkbox" id={item.id} />
          </div>
        ))}
      </div>
    </>
  );
}
