import { ExploreSites } from "../../../constants/action-constants/map-view/media-sites/ExploreSitesActionConstants";

export const selectExploreSite = (campaignId, mediaList) => ({
  type: ExploreSites.ADD_EXPLORE_SITE,
  payload: {
    campaignId,
    mediaList,
  },
});

export const selectMultipleExploreSites = (mediaIds) => ({
  type: ExploreSites.EXPLORE_SITES_SELECT_MULTIPLE,
  payload: {
    ids: mediaIds,
  },
});

export const unselectMultipleExploreSites = (mediaIds) => ({
  type: ExploreSites.EXPLORE_SITES_UNSELECT_MULTIPLE,
  payload: {
    ids: mediaIds,
  },
});

export const resetExploreSiteSelection = () => ({
  type: ExploreSites.RESET_EXPLORE_SITES_SELECTION,
});
