export const Region = {
  // getting Region-data
  GET_REGION_DATA_BY_CITY: "GET_REGION_DATA_BY_CITY",
  GET_REGION_DATA_BY_CITY_SUCCESS: "GET_REGION_DATA_BY_CITY_SUCCESS",
  GET_REGION_DATA_BY_CITY_FAILURE: "GET_REGION_DATA_BY_CITY_FAILURE",

  // Clear RegionData
  CLEAR_REGION_DATA_BY_CITY: "CLEAR_REGION_DATA_BY_CITY",

  // Region Names actions
  GET_REGION_NAMES: "GET_REGION_NAMES",
  GET_REGION_NAMES_SUCCESS: "GET_REGION_NAMES_SUCCESS",
  GET_REGION_NAMES_FAILURE: "GET_REGION_NAMES_FAILURE",
};
