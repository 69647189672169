// Constants and Utils
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import { constructCityReachSplit } from "../GraphReportsUtils";

// Components
import { BarChart } from "../../../components/charts/Charts";
import TableHeaders from "../../../components/table/TableHeaders";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "City",
      className: "col-4 text-center",
    },
  },
  {
    title: {
      displayName: "Reach",
      className: "col-4 text-center",
    },
  },
  {
    title: {
      displayName: "TG Reach",
      className: "col-4 text-center",
    },
  },
];

// Reach Split Table Section
function ReachSplitTableSection({ infoByCity = {} }) {
  return (
    <div className="col-5 table-responsive max-h-400">
      <table className="table">
        {/* Table- Header */}
        <TableHeaders requiredSubHeader={false} tableHeaders={tableHeaders} />

        <tbody>
          {Object.values(infoByCity).map((cityInfo) => {
            const { cityName, cityId, reachFrequency = {} } = cityInfo;
            const { reach, tgReach } = reachFrequency;
            return (
              <tr key={cityId}>
                <td className="text-center text-primary">{cityName}</td>
                <td className="text-center">
                  {formatText(toLocaleString(reach))}
                </td>
                <td className="text-center">
                  {formatText(toLocaleString(tgReach))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

// Reach Split Graph Section
function ReachSplitGraphSection({ infoByCity = {} }) {
  // bar data key
  const eachBarData = [
    {
      dataKey: "Reach",
    },
    {
      dataKey: "TG Reach",
    },
  ];

  // reach Split Data
  const reachSplitData = constructCityReachSplit(infoByCity);

  return (
    <div className="col-7">
      <h4 className="font-weight-bold pr-2 mb-0">
        City Wise Reach V/s TG-Reach Split
      </h4>

      {/* bar chart */}
      <div className="px-4 pt-5">
        <BarChart
          data={reachSplitData}
          barsData={eachBarData}
          showCartesianGrid={true}
          showCustomXAxisTick={true}
        />
      </div>
    </div>
  );
}

/**
 * Campaign Reach Split Section
 */
function CampaignReachSplitSection({ infoByCity = {} }) {
  return (
    <div className="row py-4 border-bottom">
      {/* graph */}
      <ReachSplitGraphSection infoByCity={infoByCity} />

      {/* Table */}
      <ReachSplitTableSection infoByCity={infoByCity} />
    </div>
  );
}

export default CampaignReachSplitSection;
