/* eslint-disable no-case-declarations */
import { DataLayer } from "../../constants/action-constants/DataLayerActionConstants";

//intital state
const initialState = {
  // map-view ==> dataLayer selection
  // ------------------------------------------------------------------------------
  isSelectionLoading: {},
  isLayerSelected: {},
};

function setDataLayersSelection(ids, select = true) {
  const isLayerSelected = {};
  for (const id of ids) {
    isLayerSelected[id] = select;
  }
  return isLayerSelected;
}

export default (state = initialState, action) => {
  switch (action.type) {
    // map-view ==> dataLayer selection
    // ------------------------------------------------------------------------------
    case DataLayer.DATA_LAYER_SELECTION_LOADING:
      return {
        ...state,
        isSelectionLoading: {
          ...state.isSelectionLoading,
          [action.payload.id]: true,
        },
      };

    case DataLayer.DATA_LAYER_SELECTION_LOADED:
      return {
        ...state,
        isSelectionLoading: {
          ...state.isSelectionLoading,
          [action.payload.id]: false,
        },
      };

    case DataLayer.DATA_LAYER_SELECT:
      return {
        ...state,
        isLayerSelected: {
          ...state.isLayerSelected,
          [action.payload.id]: true,
        },
      };

    case DataLayer.DATA_LAYER_UNSELECT:
      const updatedLayerSelected = { ...state.isLayerSelected };
      delete updatedLayerSelected[action.payload.id];
      return {
        ...state,
        isLayerSelected: updatedLayerSelected,
      };

    case DataLayer.DATA_LAYER_SELECT_MULTIPLE:
      return {
        ...state,
        isLayerSelected: {
          ...state.isLayerSelected,
          ...setDataLayersSelection(action.payload.ids, true),
        },
      };

    case DataLayer.DATA_LAYER_UNSELECT_MULTIPLE:
      return {
        ...state,
        isLayerSelected: {
          ...state.isLayerSelected,
          ...setDataLayersSelection(action.payload.ids, false),
        },
      };

    case DataLayer.RESET:
      return initialState;

    default:
      return state;
  }
};
