import { useState } from "react";
import Autocomplete from "react-google-autocomplete";

// Constants and Utils
import { UrlKeys } from "../../constants/GeneralConstants";
import { useUrlKeyCheck } from "../../mavin/utils/hooks/HooksUtil";

// CSS
import "./PlacesAutocomplete.scss";

/**
 * PlacesAutocomplete Component
 */
function PlacesAutocomplete({
  className,
  placeholder,
  countryCode = "IN",
  setLocationStr,
  locationStr,
  center = [],
}) {
  // State
  const [selectedAddress, setSelectedAddress] = useState("");

  // this function will give locationStr from input
  function getSearchStr(event) {
    const value = event.target.value;
    const valueLength = value.split(",").length;
    if (valueLength <= 2) {
      setLocationStr(value);
    }
    setSelectedAddress(value);
  }

  // this function handles values after selecting any address from suggestions
  function getPlaceSelectedInfo(place) {
    const { geometry, formatted_address: address } = place;
    const lat = geometry.location.lat();
    const lng = geometry.location.lng();
    const locationString = `${lat},${lng}`;

    // setting the locationStr value
    setLocationStr(locationString);

    // setting the address to show in input
    setSelectedAddress(address);
  }

  const { map, cityAnalyser } = UrlKeys;

  //checking if the page is from CampaignMapView or CityAnalyser
  const canAddBoundsField = useUrlKeyCheck(map, cityAnalyser);

  const placeholderValue = placeholder
    ? placeholder
    : "Enter Latitude, Longitude Or Search Address...";

  // AutoComplete Options
  const autoCompleteOpts = {
    componentRestrictions: { country: countryCode },
    types: [],
  };

  // Adding Bounds to restrict the search to be with in city
  canAddBoundsField &&
    center !== null &&
    center.length > 0 &&
    Object.assign(autoCompleteOpts, {
      bounds: {
        north: Number(center[0]) + 0.1,
        south: Number(center[0]) - 0.1,
        east: Number(center[1]) + 0.1,
        west: Number(center[1]) - 0.1,
      },
      strictBounds: true,
    });

  const apiKey = process.env.REACT_APP_API_KEY_GOOGLE_MAPS;

  return (
    <Autocomplete
      className={`form-control ${className}`}
      type="search"
      placeholder={placeholderValue}
      options={autoCompleteOpts}
      apiKey={apiKey}
      value={selectedAddress || locationStr}
      onChange={getSearchStr}
      onPlaceSelected={getPlaceSelectedInfo}
    />
  );
}

export default PlacesAutocomplete;
