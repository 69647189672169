import { ActionTypes } from "../../constants/ActionConstants";

export const getMapState = (campaignId, cityId) => ({
  type: ActionTypes.MapState.GET_MAP_STATE,
  payload: { campaignId, cityId },
});

export const resetMapState = () => ({
  type: ActionTypes.MapState.RESET_MAP_STATE,
});
