import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// APIs
import {
  getPoiData,
  getPoiHierarchy,
  getPoisOnMapView,
} from "../../../apis/PoiAPI";
import { Poi } from "../../../constants/action-constants/map-view/poi/PoiActionConstants";

// Constants and Utils
import { ActionTypes } from "../../../constants/ActionConstants";
import { PageSize } from "../../../constants/GeneralConstants";
import { getErrorMessage } from "../../../utils/util";

function* getPoiHierarchyData(action) {
  try {
    const { cityId } = action.payload;
    const poiHierarchy = yield getPoiHierarchy(cityId);
    yield put({
      type: Poi.GET_POI_SUCCESS,
      payload: poiHierarchy,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: Poi.GET_POI_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* getPoisDataOnMapView(action) {
  try {
    const { poiType, cityId } = action.payload;
    const poisData = yield getPoisOnMapView(poiType, cityId);
    yield put({
      type: ActionTypes.Poi.GET_POI_INFO_SUCCESS,
      payload: poisData.result,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: ActionTypes.Poi.GET_POI_INFO_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

/**
 * this fxn to get POI-obj having only "Id" and "name"
 * @param {*} poiData
 * @returns poiNamesWithId
 */
function* getPoiNamesWithId(poiData) {
  const poiNamesWithId = poiData.items.map((poi) => {
    return {
      id: poi.id,
      name: poi.name,
    };
  });
  yield poiNamesWithId;
}

export function* getPoiNamesList(action) {
  const { inputText, pn = 1, ps = PageSize.PoiNames } = action.payload;
  try {
    // full info of poi's
    const poiData = yield getPoiData(inputText, pn, ps);

    // storing only "id" and "name" of poi
    const poiList = yield getPoiNamesWithId(poiData);

    yield put({
      type: ActionTypes.PoiNames.GET_POI_NAMES_LIST_SUCCESS,
      poiList: poiList,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.PoiNames.GET_POI_NAMES_LIST_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(Poi.GET_POI, getPoiHierarchyData),
    takeLatest(ActionTypes.Poi.GET_POI_INFO, getPoisDataOnMapView),
    takeLatest(ActionTypes.PoiNames.GET_POI_NAMES_LIST, getPoiNamesList),
  ]);
}
