import { useState } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

// Constants and Utils
import { DATE_FORMATS } from "../../constants/GeneralConstants";

// Sub-Components
function PlaceHolderText({
  isSingleDatePicker,
  startDate,
  endDate,
  placeHolder,
  dateFormat,
}) {
  const formattedStartDate = moment(startDate).format(dateFormat);
  const formattedEndDate = moment(endDate).format(dateFormat);

  if (isSingleDatePicker) {
    return (
      <span className="text-primary">
        {startDate ? formattedStartDate : placeHolder}
      </span>
    );
  }
  return (
    <span className="text-primary">
      {startDate && endDate
        ? `${formattedStartDate} ~ ${formattedEndDate}`
        : placeHolder}
    </span>
  );
}

function RangePickerButtonLabel({
  isSingleDatePicker,
  startDate,
  endDate,
  dateFormat,
  calenderIcon,
  placeHolder,
}) {
  return (
    <div className="d-flex justify-content-between align-items-center px-2">
      <PlaceHolderText
        isSingleDatePicker={isSingleDatePicker}
        startDate={startDate}
        endDate={endDate}
        placeHolder={placeHolder}
        dateFormat={dateFormat}
      />
      {calenderIcon && <i className="fa fa-calendar-alt opacity-50 px-2"></i>}
    </div>
  );
}

/**
 * Component :: DATE-RANGE-PICKER
 * @http://www.daterangepicker.com :: refer this link for "props" reference
 * @https://www.npmjs.com/package/bootstrap-daterangepicker
 * @https://www.npmjs.com/package/react-bootstrap-daterangepicker
 */
function BootstrapDateRangePicker({
  initialStartDate,
  initialEndDate,
  onApplyDates,
  placeHolder,
  minDate,
  maxDate,
  isSingleDatePicker,

  // Style Object
  styleObject,
}) {
  // States
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  // Default Styles to picker
  const {
    buttonClassName = "",
    border = false, // border to picker
    calenderIcon = true,
    dateSeparator = " ~ ", // separator between dates
    popupDrops = "auto", // open on which side
    popupOpens = "right", // popup starts from
    isPickerDisabled = false, // enable/disable picker
    showDropdowns = false, // enable/disable Month and Year selector
    autoApply = false, // Hide/Show submit/cancel button at bottom
    dateFormat = DATE_FORMATS.full_month_with_date_year,
  } = styleObject;

  // classes
  const borderClassName = border ? "border" : "p-0";

  // Function
  function onApply(e, picker) {
    const pickerStartDate = picker.startDate;
    const pickerEndDate = picker.endDate;
    const selectedDate = {
      startDate: pickerStartDate,
      endDate: pickerEndDate,
    };

    // for "Single-Date-Selector" ------------------------------------------
    if (isSingleDatePicker) {
      const selectedDate = pickerStartDate.toDate();
      setStartDate(selectedDate);
      // final submit Action
      onApplyDates(selectedDate);
      return;
    }

    // For "Date-Range-Picker" ---------------------------------------------
    setStartDate(pickerStartDate);
    setEndDate(pickerEndDate);
    // final submit Action
    onApplyDates(selectedDate);
  }

  return (
    <DateRangePicker
      initialSettings={{
        startDate: startDate || false,
        endDate,
        singleDatePicker: isSingleDatePicker,
        autoApply,
        minDate,
        maxDate,
        showDropdowns,
        locale: { format: dateFormat, separator: dateSeparator },
        drops: popupDrops,
        opens: popupOpens,
      }}
      onApply={onApply}
    >
      <button
        type="button"
        className={`btn shadow-none rounded-lg ${borderClassName} ${buttonClassName}`}
        disabled={isPickerDisabled}
      >
        <RangePickerButtonLabel
          isSingleDatePicker={isSingleDatePicker}
          startDate={startDate}
          endDate={endDate}
          dateFormat={dateFormat}
          calenderIcon={calenderIcon}
          placeHolder={placeHolder}
        />
      </button>
    </DateRangePicker>
  );
}

BootstrapDateRangePicker.defaultProps = {
  placeHolder: "+ Add Dates",
  minDate: false,
  maxDate: false,
  isSingleDatePicker: false,
  styleObject: {},
};

export default BootstrapDateRangePicker;
