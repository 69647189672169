import { useSelector } from "react-redux";

// Page Components
import MapTab from "../MapTab";
import SecListingSection from "./SecListingSection";
import SelectedSecSection from "./SelectedSecSection";

// Page Constants
const selectedViewLabels = {
  header: {
    title: "SEC",
    changeViewBtnText: "Add SEC",
  },
  body: {
    emptyText: "Add SEC",
    emptyIconCls: "fas fa-border-none",
  },
};

const listingViewLabels = {
  header: {
    title: "Add SEC",
    createBtnText: "",
    createBtnUrl: "",
  },
};

function SecTab({ cityId, bbox }) {
  // selector
  const isSelectedSec = useSelector((state) => state.sec.selectedSec);

  // Functions
  // getting content for "selectedViewContent"
  function checkForSelectedViewContent() {
    if (Object.keys(isSelectedSec).length === 0) {
      return null;
    }
    return (
      <SelectedSecSection
        selectedSecList={[]}
        selectedViewLabels={selectedViewLabels}
      />
    );
  }

  const selectedViewContent = checkForSelectedViewContent();

  const listingViewContent = (
    <SecListingSection
      selectedViewLabels={listingViewLabels}
      cityId={cityId}
      bbox={bbox}
    />
  );

  return (
    <MapTab
      selectedViewLabels={selectedViewLabels}
      selectedViewChildren={selectedViewContent}
      listingViewLabels={listingViewLabels}
      listingViewChildren={listingViewContent}
    />
  );
}

export default SecTab;
