import { getLocationSummaryUrl } from "../urls/LocationSummaryURL";
import { sendGetRequest } from "../utils/api/apiUtils";

export async function getLocationSummaryFn(latitude, longitude, distance) {
  const url = getLocationSummaryUrl
    .replace(":lat", latitude)
    .replace(":lng", longitude)
    .replace(":radius", distance);
  return sendGetRequest(url);
}
