import { Poi } from "../../../constants/action-constants/map-view/poi/PoiActionConstants";
import { ActionTypes } from "../../../constants/ActionConstants";

export const getPoiHierarchy = (cityId) => ({
  type: Poi.GET_POI,
  payload: { cityId },
});

export const setSelected = (path, label) => ({
  type: Poi.SET_CHECKLIST_SELECTED,
  payload: {
    path,
    label,
  },
});

export const setCheckboxStatus = (path, label, status) => ({
  type: Poi.SET_CHECKBOX_STATUS,
  payload: {
    path,
    label,
    status,
  },
});

export const getPois = (poiType, cityId) => ({
  type: ActionTypes.Poi.GET_POI_INFO,
  payload: {
    cityId,
    poiType,
  },
});

export const setPoiStatus = (label, status) => ({
  type: Poi.SET_POI_STATUS,
  payload: {
    label,
    status,
  },
});

export const resetPoiInfo = () => ({
  type: Poi.RESET,
});
