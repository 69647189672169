/* eslint-disable no-case-declarations */

// Actions
import { TargetGroup } from "../../constants/action-constants/TargetGroupActionConstants";

// Utils and Constants
import {
  Status,
  TargetGroupAttributes,
} from "../../constants/GeneralConstants";
import {
  formatRawData,
  setDatalistAdded,
  setDatalistSelected,
} from "../util/mapViewUtil";

const initialState = {
  // Target-Groups
  // --------------------------------------------------------------------
  targetGroupLoading: false,
  targetGroupError: "",
  tgTitles: [],
  tgList: [],
  tgPagination: {},

  // Target-Group CREATE
  // --------------------------------------------------------------------
  isCreateTgLoading: false,
  createTgError: "",

  // Target-Group UPDATE
  // --------------------------------------------------------------------
  isUpdateTgLoading: false,
  updateTgError: "",

  // Target-group ATTRIBUTES
  // ------------------------------------------------------------------
  // DataList will be in the format..
  // {"Female": {Botique:{}, Mall:{}, }}
  dataList: [],
  isGetTgAttrLoading: false,
  getTggAttrError: "",
  originalData: {},

  // Name__AttributrType__Id map
  // Example Bar__AGE_GROUP__23-34__bar
  poiNameToLayerMap: {},
  attSelectionMap: {},

  // Target-group ARCHIVE-RESTORE
  // ---------------------------------------------------------------------
  isTgArchiveRestore: {},
  tgArchiveErr: "",
  tgRestoreErr: "",

  // Target-group ATTRIBUTES-SELECTION
  // --------------------------------------------------------------------
  attSelections: {},
  resPoiLayers: [], // array of resPoiLayers NAME
  resPoiLayersIds: [], // Array of resPoiLayers IDS
  selectedPois: [],

  // Taget-Group information
  // -------------------------------------------------------------------
  tgId: "",
  tgName: "",
  tgInfo: {},
  tgInfoLoading: false,
  tgInfoError: "",

  // Set/Remove Selected TG Id
  selectedTgId: "",
};

// tg-attributes datalist
function constructDataList(data) {
  const { poiTypesMap, targetGroupAttrs } = data;
  const poiNameToIdMap = {};
  const rawData = Object.keys(targetGroupAttrs).reduce((acc, eachKey) => {
    const attributes = targetGroupAttrs[eachKey];
    const attributesMap = attributes.reduce((attAcc, eachAttribute) => {
      const poiBrandIds = eachAttribute.poiBrandIds;
      const poiBrandIdsMap = Object.keys(poiBrandIds).reduce(
        (brandAcc, eachBrandKey) => {
          // key will be like
          // AGE_GROUP__23-34__ar
          const key = `${eachKey}__${eachAttribute.name}__${poiTypesMap[eachBrandKey].name}`;
          poiNameToIdMap[key] = {
            attributeId: eachAttribute.id,
            attributeType: eachKey,
            poiTypeId: eachBrandKey,
            type: "POINT",
            displayType: "HEATMAP",
          };
          brandAcc[poiTypesMap[eachBrandKey].name] = {};
          return brandAcc;
        },
        {}
      );
      attAcc[eachAttribute.name] = poiBrandIdsMap;
      return attAcc;
    }, {});
    attributesMap["type"] = eachKey;
    acc[eachKey] = attributesMap;
    return acc;
  }, {});

  return { rawData, poiNameToIdMap };
}

// constructs attributes selection funnction
function getSelectedPois(pois) {
  // poi label id will be in the format of AGE_GROUP__23-34__Bar
  return pois.reduce((acc, eachPoi) => {
    if (eachPoi.status === Status.CHECKED) {
      // this will be resulting in Bar__AGE_GROUP__24-34
      acc.push(eachPoi.labelId);
    }
    return acc;
  }, []);
}

function getListIntersection(listA, listB) {
  if (listB.length === 0 && listA.length !== 0) {
    return listA;
  }

  if (listA.length === 0 && listB.length !== 0) {
    return listB;
  }

  // Construct Map from ListA..
  const listAMap = listA.reduce((acc, eachElement) => {
    // eachElement ==> AGE_GROUP__23-34__Bar
    // we will have a map like {Bar: AGE_GROUP__23-34__Bar}
    const key = eachElement.split("__")[2];
    acc[key] = eachElement;
    return acc;
  }, {});

  const intersection = listB.reduce((acc, eachElement) => {
    // we will create an intersection list like
    // [AGE_GROUP__23-34__Bar, GENDER__MALE__Bar]
    const key = eachElement.split("__")[2];
    if (listAMap[key]) {
      acc.push(eachElement);
      acc.push(listAMap[key]);
    }
    return acc;
  }, []);

  return intersection;
}

function getAttributesSelectedDetails(tgInfo, tgAttributes) {
  // construct Tg Atrribute Id to Name Map
  const tgAttributeIdToNameMap = Object.keys(tgAttributes).reduce(
    (acc, eachAttributeArrayKey) => {
      const attributeArray = tgAttributes[eachAttributeArrayKey];
      const tempAttIdToNameMap = attributeArray.reduce(
        (attributeAcc, eachAttribute) => {
          attributeAcc[eachAttribute.id] = eachAttribute.name;
          return attributeAcc;
        },
        {}
      );
      acc = { ...acc, ...tempAttIdToNameMap };
      return acc;
    },
    {}
  );
  const { poiTypesMap, targetGroup } = tgInfo;
  const { layers } = targetGroup;
  const layerIds = layers.reduce((acc, eachSelectedLayer) => {
    const { attributeId, attributeType, poiTypeId } = eachSelectedLayer;

    // This is the layerId we construct in the dataList of TargetGroupAttributes
    const layerId = `${attributeType}__${tgAttributeIdToNameMap[attributeId]}__${poiTypesMap[poiTypeId].name}`;
    acc.push(layerId);
    return acc;
  }, []);
  return layerIds;
}

function selectLabelOfTgAttributes(payload, dataList, attSelectionMap) {
  const { labelId, status } = payload;
  const resultantDataList = setDatalistAdded(
    dataList,
    { labelId: labelId },
    status
  );

  // Logic to maintain att-Selection-Map
  const resultantAttSelectionMap = { ...attSelectionMap };
  if (status === Status.CHECKED) {
    resultantAttSelectionMap[labelId] = true;
  } else {
    delete resultantAttSelectionMap[labelId];
  }

  return {
    dataList: resultantDataList,
    attSelectionMap: resultantAttSelectionMap,
  };
}

function constructAttributeSelection(dataList) {
  let resultantPoiLayers = [];
  const selectedPois = [];
  const attSelections = dataList.reduce((acc, eachData) => {
    const selectedAttArray = [];

    // This holds the all the poi layers added across the attributes of a type
    const selectedPoiArray = [];
    const attributes = eachData.children;
    for (const eachAttribute of attributes) {
      const pois = eachAttribute.children;

      // get selected poi labels
      const selectedPoiLabels = getSelectedPois(pois);

      if (selectedPoiLabels.length > 0) {
        // push selected attribute..
        selectedAttArray.push(eachAttribute.label);

        // push selected poi layers..
        selectedPoiArray.push(...selectedPoiLabels);

        selectedPois.push(...selectedPoiLabels);
      }
    }

    // selected attributes..
    if (selectedAttArray.length > 0) {
      acc[eachData.label] = selectedAttArray;
    }

    // Find intersection of the selected poi layers..
    resultantPoiLayers = getListIntersection(
      selectedPoiArray,
      resultantPoiLayers
    );
    return acc;
  }, {});

  return { attSelections, resultantPoiLayers, selectedPois };
}

/**
 *
 * @param {*} poiNameToLayerMap = layerName: {}
 * @param {*} resultantPoiLayers = ["string", "string"]
 * @returns resultingPoiLayerIds = array of IDS
 */
function getResultingPoiLayerIds(poiNameToLayerMap, resultantPoiLayers) {
  const resultingPoiLayerIds = resultantPoiLayers.map((poiLayer) => {
    return poiNameToLayerMap[poiLayer].poiTypeId;
  });
  return resultingPoiLayerIds;
}

export default (state = initialState, action) => {
  switch (action.type) {
    // Target-group
    // ----------------------------------------------------------------------
    case TargetGroup.GET_TARGET_GROUPS:
      return {
        ...state,
        targetGroupLoading: true,
      };

    case TargetGroup.GET_TARGET_GROUPS_SUCCESS:
      const { tgList, pagination } = action.payload;
      return {
        ...state,
        targetGroupLoading: false,
        tgTitles: tgList.map((eachTg) => {
          return {
            id: eachTg.id,
            label: eachTg.name,
          };
        }),
        tgList,
        tgPagination: pagination,
      };

    case TargetGroup.GET_TARGET_GROUPS_FAILURE:
      return {
        ...state,
        targetGroupLoading: false,
        targetGroupError: action.payload,
      };

    case TargetGroup.UPDATE_TARGET_GROUP_STATUS: {
      const { tgId, isArchived } = action.payload;
      const targetGroups = state.tgList.map((eachTg) => {
        if (tgId !== eachTg.id) {
          return eachTg;
        }
        return {
          ...eachTg,
          isArchived,
          updatedOn: Date.now(),
        };
      });
      return {
        ...state,
        tgList: targetGroups,
      };
    }

    case TargetGroup.RESET_TARGET_GROUPS:
      return initialState;

    // Target-Group CREATE
    // ------------------------------------------------------------------
    case TargetGroup.CREATE_TARGET_GROUP: {
      return {
        ...state,
        isCreateTgLoading: true,
      };
    }

    case TargetGroup.CREATE_TARGET_GROUP_SUCCESS: {
      return {
        ...state,
        isCreateTgLoading: false,
      };
    }

    case TargetGroup.CREATE_TARGET_GROUP_FAILURE: {
      return {
        ...state,
        isCreateTgLoading: false,
        createTgError: action.payload,
      };
    }

    // Target-Group UPDATE
    // ------------------------------------------------------------------
    case TargetGroup.UPDATE_TARGET_GROUP: {
      return {
        ...state,
        isUpdateTgLoading: true,
      };
    }

    case TargetGroup.UPDATE_TARGET_GROUP_SUCCESS: {
      return {
        ...state,
        isUpdateTgLoading: false,
      };
    }

    case TargetGroup.UPDATE_TARGET_GROUP_FAILURE: {
      return {
        ...state,
        isUpdateTgLoading: false,
        updateTgError: action.payload,
      };
    }

    // Target-Group ATTRIBUTES
    case TargetGroup.GET_TARGET_GROUP_ATTRIBUTES:
      return {
        ...state,
        isGetTgAttrLoading: true,
      };

    case TargetGroup.GET_TARGET_GROUP_ATTRIBUTES_SUCCESS: {
      const originalData = action.payload;
      const { rawData, poiNameToIdMap } = constructDataList(originalData);
      const dataList = formatRawData(rawData);

      // TODO: remove this when Income Group is required
      const newDataList = dataList.filter(
        (eachDataPoint) =>
          eachDataPoint.label !== TargetGroupAttributes.incomeGroup
      );

      return {
        ...state,
        isGetTgAttrLoading: false,
        originalData,
        rawData: rawData,
        dataList: newDataList,
        poiNameToLayerMap: poiNameToIdMap,
      };
    }

    case TargetGroup.GET_TARGET_GROUP_ATTRIBUTES_FAILURE:
      return {
        ...state,
        isGetTgAttrLoading: false,
        getTggAttrError: action.payload,
      };

    case TargetGroup.RESET_TARGET_GROUP_ATTRIBUTES:
      return {
        ...state,
        dataList: [],
        originalData: {},
        poiNameToLayerMap: {},
      };

    case TargetGroup.SET_TG_SELECTED: {
      const { level, label } = action.payload;
      const dataList = setDatalistSelected(state.dataList, level, label);
      return {
        ...state,
        dataList,
      };
    }
    case TargetGroup.SET_TG_STATUS: {
      const { dataList, attSelectionMap } = selectLabelOfTgAttributes(
        action.payload,
        state.dataList,
        state.attSelectionMap
      );
      return {
        ...state,
        dataList,
        attSelectionMap,
      };
    }

    // Target-group ARCHIVE-RESTORE
    case TargetGroup.ARCHIVE_TARGET_GROUP: {
      const { tgId } = action.payload;
      return {
        ...state,
        isTgArchiveRestore: { ...state.isTgArchiveRestore, [tgId]: true },
      };
    }

    case TargetGroup.ARCHIVE_TARGET_GROUP_SUCCESS: {
      const { tgId } = action.payload;
      return {
        ...state,
        isTgArchiveRestore: { ...state.isTgArchiveRestore, [tgId]: false },
      };
    }

    case TargetGroup.ARCHIVE_TARGET_GROUP_FAILURE: {
      const { tgId, errMsg } = action.payload;
      return {
        ...state,
        isTgArchiveRestore: { ...state.isTgArchiveRestore, [tgId]: false },
        tgArchiveErr: errMsg,
      };
    }

    case TargetGroup.RESTORE_TARGET_GROUP: {
      const { tgId } = action.payload;
      return {
        ...state,
        isTgArchiveRestore: { ...state.isTgArchiveRestore, [tgId]: true },
      };
    }

    case TargetGroup.RESTORE_TARGET_GROUP_SUCCESS: {
      const { tgId } = action.payload;
      return {
        ...state,
        isTgArchiveRestore: { ...state.isTgArchiveRestore, [tgId]: false },
      };
    }

    case TargetGroup.RESTORE_TARGET_GROUP_FAILURE: {
      const { tgId, errMsg } = action.payload;
      return {
        ...state,
        isTgArchiveRestore: { ...state.isTgArchiveRestore, [tgId]: false },
        tgRestoreErr: errMsg,
      };
    }

    // Target-group ATTRIBUTES-SELECTION
    case TargetGroup.REVIEW_TARGET_GROUP: {
      const { attSelections, resultantPoiLayers, selectedPois } =
        constructAttributeSelection(action.payload.dataList);
      const resPoiLayersIds = getResultingPoiLayerIds(
        state.poiNameToLayerMap,
        resultantPoiLayers
      );
      return {
        ...state,
        resPoiLayersLoading: true,
        attSelections: attSelections,
        resPoiLayers: resultantPoiLayers,
        resPoiLayersIds: resPoiLayersIds,
        selectedPois: selectedPois,
      };
    }

    // Setting Target Group Attributes in Edit Scenario
    case TargetGroup.SET_TARGET_GROUP_ATTRIBUTES: {
      let tempDataList = [...state.dataList];
      let tempAttSelectionMap = { ...state.attSelectionMap };
      const layerIds = getAttributesSelectedDetails(
        action.payload.tgInfo,
        action.payload.tgAttributes
      );

      layerIds.forEach((layerId) => {
        const { dataList, attSelectionMap } = selectLabelOfTgAttributes(
          { labelId: layerId, status: Status.CHECKED },
          tempDataList,
          tempAttSelectionMap
        );
        tempDataList = dataList;
        tempAttSelectionMap = attSelectionMap;
      });

      return {
        ...state,
        dataList: tempDataList,
        attSelectionMap: tempAttSelectionMap,
      };
    }

    // Target-group INFORMATION
    case TargetGroup.GET_TG_BASIC_INFO:
      return {
        ...state,
        tgInfoLoading: true,
        tgId: action.payload.tgId,
      };

    case TargetGroup.GET_TG_BASIC_INFO_SUCCESS:
      return {
        ...state,
        tgInfoLoading: false,
        tgName: action.payload.tgInfo.targetGroup.name,
        tgInfo: action.payload.tgInfo,
      };

    case TargetGroup.GET_TG_BASIC_INFO_FAILURE:
      return {
        ...state,
        tgInfoLoading: false,
        tgInfoError: action.payload,
      };

    case TargetGroup.CLEAR_TG_BASIC_INFO:
      return {
        ...state,
        tgName: "",
        tgInfo: {},
      };

    case TargetGroup.SET_SELECTED_TG_ID:
      return {
        ...state,
        selectedTgId: action.payload.tgId,
      };

    case TargetGroup.REMOVE_SELECTED_TG_ID:
      return {
        ...state,
        selectedTgId: "",
      };

    default:
      return state;
  }
};
