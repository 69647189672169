import {
  downloadMonitorPdfUrl,
  getMonitoringLogByMediaUrl,
} from "./../urls/MonitoringLogURL";

// Constants and Utils..
import { getToken } from "../utils/token";
import { downloadFile, sendGetRequest } from "../utils/api/apiUtils";

export async function getMonitoringLogByMedia(
  campaignId,
  mediaId,
  pageNumber,
  pageSizeCount
) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    pn: pageNumber,
    ps: pageSizeCount,
  };

  const url = getMonitoringLogByMediaUrl
    .replace(":campaignId", campaignId)
    .replace(":mediaId", mediaId);
  return sendGetRequest(url, params, true, headerParams);
}

export async function downloadMonitorMediaLogPdfReportFn(campaignId, mediaId) {
  const headerParams = {
    Authorization: "Bearer " + getToken(),
  };

  return downloadFile(
    downloadMonitorPdfUrl
      .replace(":campaignId", campaignId)
      .replace(":mediaId", mediaId),
    true,
    headerParams
  );
}
