// TODO: Move all util functions to this file

export const getErrorMessage = (error) => {
  if (error.response) {
    if (error.response.data instanceof Object) {
      return error.response.data.message;
    }
    return error.response.data;
  } else if (error.request) {
    return error.request;
  } else {
    return error.message;
  }
};

export const getBoundsFromBbox = (bbox) => {
  if (!bbox)
    return [
      [0, 0],
      [0, 0],
    ];
  const latLngs = bbox.split(",");
  return [
    [latLngs[1], latLngs[0]],
    [latLngs[3], latLngs[2]],
  ];
};
