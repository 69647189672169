export const DataLayer = {
  // get all data-layers
  GET_CUSTOM_DATA_LAYERS: "GET_CUSTOM_DATA_LAYERS",
  GET_CUSTOM_DATA_LAYERS_SUCCESS: "GET_CUSTOM_DATA_LAYERS_SUCCESS",
  GET_CUSTOM_DATA_LAYERS_FAILURE: "GET_CUSTOM_DATA_LAYERS_FAILURE",

  // to change data-layer status
  CHANGE_DATA_LAYER_STATUS: "CHANGE_DATA_LAYER_STATUS",
  CHANGE_DATA_LAYER_STATUS_DONE: "CHANGE_DATA_LAYER_STATUS_DONE",

  // data-layer-details
  GET_DATA_LAYER_DETAILS: "GET_DATA_LAYER_DETAILS",
  GET_DATA_LAYER_DETAILS_SUCCESS: "GET_DATA_LAYER_DETAILS_SUCCESS",
  GET_DATA_LAYER_DETAILS_FAILURE: "GET_DATA_LAYER_DETAILS_FAILURE",

  // data-layer selection and loading state on map-view
  DATA_LAYER_SELECTION_LOADING: " DATA_LAYER_SELECTION_LOADING",
  DATA_LAYER_SELECTION_LOADED: " DATA_LAYER_SELECTION_LOADED",

  DATA_LAYER_SELECT: "DATA_LAYER_SELECT",
  DATA_LAYER_SELECT_MULTIPLE: "DATA_LAYER_SELECT_MULTIPLE",
  DATA_LAYER_UNSELECT: "DATA_LAYER_UNSELECT",
  DATA_LAYER_UNSELECT_MULTIPLE: "DATA_LAYER_UNSELECT_MULTIPLE",

  // Reset DataLayers
  RESET: "DATA_LAYER_RESET",
};
