import { useState } from "react";

// Component
import PlanCityViewMediaTable from "./PlanCityViewMediaTable";
import { SearchFilterAndSortMediaSection } from "../../../../pages/map-view/media-sites/SelectedSitesSection";

/**
 * Campaign Media Table With Actions
 */
function CampaignMediaTableWithActions({
  mediaList = [],

  // switch inventory specific
  inventoryToShow,
  setInventoryToShow = () => {},
}) {
  const [filteredMediaList, setFilteredMediaList] = useState([]);

  // selectedViewLabels
  const selectedViewLabels = {
    search: {
      placeholder: "Search media sites",
      fields: ["title"],
    },
  };

  return (
    <>
      <div className="my-3">
        <SearchFilterAndSortMediaSection
          mediaList={mediaList}
          setSelectedMediaList={setFilteredMediaList}
          selectedViewLabels={selectedViewLabels}
          // switch inventory specific
          inventoryToShow={inventoryToShow}
          setInventoryToShow={setInventoryToShow}
        />
      </div>

      {/* Media Table */}
      <PlanCityViewMediaTable
        mediaList={filteredMediaList}
        campaignMediaSites={mediaList}
        inventoryToShow={inventoryToShow}
      />
    </>
  );
}

export default CampaignMediaTableWithActions;
