import { useState } from "react";
import { useDispatch } from "react-redux";

//Actions
import { createMedia } from "../../actions/media-selection/MediaSelectionActions";

// Constants and Utils
import { ProohFormDataTargets } from "../../constants/GeneralConstants";

//Components
import {
  CreateMediaModalFooter,
  CreateMediaModalTitle,
  MediaDimension,
  MediaImageUploadAndPreview,
  MediaLocationAndTitle,
  MediaTypeAndLitStatus,
} from "../../../components/campaign-media-form/CampaignMediaFormUtils";

function SellerDetails({ sellerDetailsValuesAndFunction }) {
  const {
    setSellerName,
    setSellerContactNo,
    setSellerEmail,
    sellerName,
    sellerContactNo,
    sellerEmail,
  } = sellerDetailsValuesAndFunction;

  const sellerDetailsInfo = [
    {
      placeholder: "Name",
      setValueFunction: setSellerName,
      value: sellerName,
      maxLength: "",
    },
    {
      placeholder: "Phone No.",
      setValueFunction: setSellerContactNo,
      maxLength: 10,
      value: sellerContactNo,
    },
    {
      placeholder: "Email",
      setValueFunction: setSellerEmail,
      value: sellerEmail,
      maxLength: "",
    },
  ];
  return (
    <div className="form-group row">
      <label className="col-3 col-form-label">{"Seller Details"}</label>
      {sellerDetailsInfo.map((info, i) => (
        <div key={i} className="col-3">
          <input
            className={`form-control shadow-none`}
            maxLength={info.maxLength}
            placeholder={info.placeholder}
            value={info.value}
            onChange={({ target }) => info.setValueFunction(target.value)}
          />
        </div>
      ))}
    </div>
  );
}

function Multiplier({ multiplier, setMultiplier }) {
  return (
    <div className="form-group row">
      <label className="col-3 col-form-label">{"Multiplier"}</label>
      <div className="col-9">
        <input
          className={`form-control shadow-none`}
          placeholder={"Enter multiplier"}
          value={multiplier}
          onChange={({ target }) => setMultiplier(target.value)}
        />
      </div>
    </div>
  );
}

function CreateMediaModalContent({ campaignId, cityId }) {
  //State
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [elevation, setElevation] = useState("");
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [litStatusStr, setLitStatusStr] = useState("");
  const [locationStr, setLocationStr] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [sellerContactNo, setSellerContactNo] = useState("");
  const [sellerEmail, setSellerEmail] = useState("");
  const [multiplier, setMultiplier] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  //Dispatch
  const dispatch = useDispatch();

  function handleSubmitForm() {
    const createMediaBean = {
      width: width,
      height: height,
      elevation: elevation,
      type: type,
      title: title,
      litStatusStr: litStatusStr,
      locationStr: locationStr,
      sellerName: sellerName,
    };
    dispatch(createMedia([createMediaBean], imageFiles, campaignId, cityId));
  }

  // to clear the input state when cancel button is clicked
  function handleClearForm() {
    setWidth("");
    setHeight("");
    setElevation("");
    setType("");
    setTitle("");
    setLitStatusStr("");
    setLocationStr("");
    setSellerName("");
    setSellerContactNo("");
    setSellerEmail("");
    setMultiplier("");
    setImageFiles([]);
    setPreviewImages([]);
  }

  const sellerDetailsValuesAndFunction = {
    sellerName,
    sellerContactNo,
    sellerEmail,
    setSellerName,
    setSellerContactNo,
    setSellerEmail,
  };

  const mediaDimensionValuesAndFunction = {
    width,
    height,
    elevation,
    setWidth,
    setHeight,
    setElevation,
  };

  return (
    <div className="modal-content">
      {/* modal title */}
      <CreateMediaModalTitle />
      <div className="modal-body px-5">
        {/* media location and name */}
        <MediaLocationAndTitle
          locationStr={locationStr}
          title={title}
          setLocationStr={setLocationStr}
          setTitle={setTitle}
        />
        <MediaTypeAndLitStatus
          type={type}
          setType={setType}
          litStatusStr={litStatusStr}
          setLitStatusStr={setLitStatusStr}
        />
        {/* media dimension */}
        <MediaDimension
          mediaDimensionValuesAndFunction={mediaDimensionValuesAndFunction}
        />

        {/* seller details */}
        <SellerDetails
          sellerDetailsValuesAndFunction={sellerDetailsValuesAndFunction}
        />

        {/* Multiplier */}
        <Multiplier multiplier={multiplier} setMultiplier={setMultiplier} />

        {/* Upload media image and preview */}
        <MediaImageUploadAndPreview
          imageFiles={imageFiles}
          previewImages={previewImages}
          setPreviewImages={setPreviewImages}
          setImageFiles={setImageFiles}
        />
      </div>

      {/* Modal Footer */}
      <CreateMediaModalFooter
        handleSubmitForm={handleSubmitForm}
        handleClearForm={handleClearForm}
      />
    </div>
  );
}

function CreateMediaForm({ campaignId, cityId }) {
  return (
    <div
      className="modal fade"
      id={ProohFormDataTargets.sellerCreateMediaForm}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <CreateMediaModalContent campaignId={campaignId} cityId={cityId} />
      </div>
    </div>
  );
}

export default CreateMediaForm;
