import { AlertForm } from "../constants/action-constants/AlertModalActionConstants";

// below two actions are for Alert-Modal
export const openAlertForm = () => ({
  type: AlertForm.OPEN_ALERT_MODAL,
});

export const closeAlertForm = () => ({
  type: AlertForm.CLOSE_ALERT_MODAL,
});
