import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Api'ss
import { createSetupMonitoringSettings } from "../../../apis/MonitoringSettingsAPI";

// Constants and Utils
import { ActionTypes } from "../../../constants/ActionConstants";
import { getErrorMessage } from "../../../utils/util";
import { defaultPagination } from "../../../constants/UrlConstants";

// Create Setup-Monitoring Form Saga
export function* createSetupMonitoring(action) {
  const { inputFieldBean, campaignId, mediaIds } = action.payload;

  // converting "start-date" to timestamp
  const startFullDate = inputFieldBean.startTimestamp;
  const STS = new Date(startFullDate).getTime();

  // converting "end-date" to timestamp
  const endFullDate = inputFieldBean.endTimestamp;
  const ETS = new Date(endFullDate).getTime();

  // updating "timestamps" (STS=startTimeStamp, ETS=endTimeStamp)
  inputFieldBean.startTimestamp = STS;
  inputFieldBean.endTimestamp = ETS;

  // pageNo, pageSize are to pass in "payload" for "Get Campaign Media"
  const pageNo = defaultPagination.pageNo;
  const pageSize = defaultPagination.pageSize;

  // making POST call for create-setup-monitoring
  try {
    const createdMonitoringSettings = yield createSetupMonitoringSettings(
      inputFieldBean,
      campaignId,
      mediaIds
    );
    yield put({
      type: ActionTypes.CampaignConfirmation.MEDIA_SETUP_MONITORING_SUCCESS,
      payload: createdMonitoringSettings,
    });

    // below action is called to get "updated media-details"
    yield put({
      type: ActionTypes.Media.GET_CAMPAIGN_MEDIA,
      payload: { campaignId, pageNo, pageSize },
    });

    toast.success("Successfully Created Monitoring Settings");
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignConfirmation.MEDIA_SETUP_MONITORING_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
    return;
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.CampaignConfirmation.MEDIA_SETUP_MONITORING,
      createSetupMonitoring
    ),
  ]);
}
