import { useDispatch } from "react-redux";
import { useLeafletContext } from "@react-leaflet/core";
import { useEffect } from "react";

// Actions
import { storeMapDetails } from "../../../actions/map-view/SaveMapStateActions";

/**
 * Component
 */
function MapImage() {
  const context = useLeafletContext();
  const container = context.layerContainer || context.map;
  const dispatch = useDispatch();

  useEffect(() => {
    const mapContainer = container;
    const center = container.options.center;
    const zoom = container.options.zoom;
    dispatch(storeMapDetails(mapContainer, center, zoom));
  }, [dispatch]);

  return null;
}

export default MapImage;
