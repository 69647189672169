import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getTgHeatMapData,
  removeTgHeatMapData,
} from "../../actions/geo-data/GeoDataActions";
import {
  clearRegionDataByCity,
  getRegionDataByCity,
} from "../../actions/regions/RegionActions";

// Components
import Spinner from "../../components/spinner/Spinner";

export function LoadingData({ className = "", dataType }) {
  return (
    <div
      className={`d-flex justify-content-center bg-alt border p-1 ${className}`}
    >
      <Spinner />
      <span className="p-2">{dataType} Loading, Please Wait...</span>
    </div>
  );
}

export function OrgViewPageLoader() {
  return (
    <div className="d-flex justify-content-center">
      <Spinner className="mt-2" />
    </div>
  );
}

/**
 * No Segments Available Message Section for finding "ClosestRoadSegments"
 */
export function NoSegmentsMessageSection({
  roadSegments,
  locationStr,
  className,
}) {
  // Selector States
  const closestRoadSegmentLoading = useSelector(
    (state) => state.orgRoadStretch.closestRoadSegmentLoading
  );

  if (roadSegments.length > 0 || !locationStr) {
    return null;
  }

  const msgClassName = className ? className : "p-3";
  const message = closestRoadSegmentLoading
    ? "Loading..."
    : "No Road Segments are Available, Select another Location.";

  return (
    <div className={`text-center ${msgClassName}`}>
      <p className="font-italic">{message}</p>
    </div>
  );
}

/**
 * Select City Input for TGCreate and TGView Page
 */
export function CitySelectInputSection({ bbox, resPoiLayers }) {
  const dispatch = useDispatch();
  // State
  const [suggestedCities, setSuggestedCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  // Selector State
  const regionsName = useSelector((state) => state.region.regionsName) || [];

  // poiTypeLayer data loading
  const poiTypeLayerDataLoading = useSelector(
    (state) => state.geoData.poiTypeLayerDataLoading
  );
  const regionDataLoading = useSelector(
    (state) => state.region.regionDataLoading
  );

  // clearing the data (region + heatmap) if city is removed.
  useEffect(() => {
    if (!selectedCity) {
      dispatch(removeTgHeatMapData());
      dispatch(clearRegionDataByCity());
    }
  }, [dispatch, selectedCity]);

  // this useEffect will occure whenever we change city (i.e; bbox also changed),
  // and when resultingPoiLayer also changed
  useEffect(() => {
    dispatch(removeTgHeatMapData());

    // Dispatching only if "City" is Selected and changed
    if (bbox) {
      dispatch(getTgHeatMapData(resPoiLayers, bbox));
    }
  }, [dispatch, resPoiLayers.join(","), bbox]);

  // Search City function for Input
  function inputSearchCityFn(e) {
    const searchText = e.target.value;
    setSelectedCity(searchText);
    if (searchText.length > 2) {
      const suggestion = regionsName.filter((cityObj) =>
        cityObj.label.toLowerCase().includes(searchText.toLowerCase())
      );
      setSuggestedCities(suggestion);
    } else {
      setSuggestedCities([]);
    }
  }

  // function after selecting city from suggestions
  function suggestedCitySelectFn(cityId, cityLabel) {
    setSelectedCity(cityLabel);
    dispatch(getRegionDataByCity(cityId));
    setSuggestedCities([]);
  }

  return (
    <div className="autocomplete-map p-2 w-50">
      <input
        type="search"
        className="form-control rounded-lg shadow-none"
        value={selectedCity}
        placeholder="Search And Select City"
        onChange={inputSearchCityFn}
      />
      {(regionDataLoading || poiTypeLayerDataLoading) && (
        <Spinner className="spinner-border-sm spinner-inside-search" />
      )}

      {/* Suggestions of Cities */}
      {suggestedCities.length > 0 && (
        <div className="border rounded-lg bg-white">
          {suggestedCities.slice(0, 5).map((city) => (
            <li
              key={city.id}
              className="dropdown-item cursor-pointer"
              data-dismiss="modal"
              onClick={() => suggestedCitySelectFn(city.id, city.label)}
            >
              {city.label}
            </li>
          ))}
        </div>
      )}
    </div>
  );
}
