import { Link } from "react-router-dom";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

//Components
import Spinner from "../../components/spinner/Spinner";

export function AuthSubmitButton({ label, loading }) {
  return (
    <button type="submit" disabled={loading} className="btn btn-primary">
      <div className="d-flex justify-content-center align-items-center">
        <span className="font-weight-bold">{label}</span>
        {loading && (
          <Spinner className={" spinner-border-sm ml-2 text-light"} />
        )}
      </div>
    </button>
  );
}

export function MessageWithRedirectionBtn({ message }) {
  return (
    <>
      {message}

      <Link className="mx-auto mt-5 " to={RedirectTo.loginPageUrl}>
        <button className="btn btn-primary">Take me to Login</button>
      </Link>
    </>
  );
}

export function FailureMessage() {
  return (
    <div className="bg-danger px-5 py-4 rounded-lg font-weight-bold">
      <h5 className="text-center mb-3">
        Failed to complete the password reset request.
      </h5>
      <h5 className="text-center">
        Please contact support team: contact@admavin.com
      </h5>
    </div>
  );
}
