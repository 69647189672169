import {
  getCampaignPerformanceMapUrl,
  getCampaignPerformanceUrl,
} from "../prooh/urls/CampaignMediaPerformanceURL";
import { sendGetRequest } from "../utils/api/apiUtils";

export async function getCampaignPerformance(campaignId, cityId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    cityId: cityId,
  };

  const url = getCampaignPerformanceUrl.replace(":campaignId", campaignId);

  return sendGetRequest(url, params, true, headerParams);
}

export async function getCampaignPerformanceMap(campaignIdList) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const campaignIds = campaignIdList.join();

  const params = {
    campaignIds: campaignIds,
  };

  return sendGetRequest(
    getCampaignPerformanceMapUrl,
    params,
    true,
    headerParams
  );
}
