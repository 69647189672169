function DownloadTemplateCard({ downloadTemplateData, onClick, isDisable }) {
  const { step, title, message, buttonLabel } = downloadTemplateData;

  return (
    <div className="card shadow mb-4 mt-3">
      <div className="d-flex justify-content-between">
        <div className="card-content">
          <p className="card__step">{step}</p>
          <p className="card__title">{title}</p>
          <p className="card__message">
            {message}
            <a className="text-primary"> Learn about templates</a>
          </p>
        </div>
        <div className="card-action">
          <button
            className="btn btn-primary btn-action mt-5"
            onClick={onClick}
            disabled={isDisable}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DownloadTemplateCard;
