import {
  archiveDataLayerUrl,
  dataLayerDownloadTemplateUrl,
  dataLayerUploadTemplateUrl,
  getDataLayerUrl,
  orgDataLayersSearchUrl,
  restoreDataLayerUrl,
} from "../urls/OrgDataLayerURL";

import {
  downloadFile,
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

export function getDataLayerDetailsById(id) {
  return sendGetRequest(getDataLayerUrl.replace(":dataLayerId", id), {});
}

export async function getDataLayers(
  excludeArchive,
  keyWord,
  pageNumber,
  pageSize
) {
  const params = {
    pn: pageNumber,
    ps: pageSize,
  };
  if (keyWord) {
    params._kw = keyWord;
  }

  // Here excludeArchive == true ==> Meaning we need only
  // DataLayers which are not archived. When we make a request we
  // say "_ar=flase" which would give the results with out
  // archived one..
  if (excludeArchive) {
    params._ar = !excludeArchive;
  }

  return sendGetRequest(orgDataLayersSearchUrl, params);
}

export async function toggleDataLayerStatus(id, isArchived) {
  const url = isArchived ? restoreDataLayerUrl : archiveDataLayerUrl;
  return sendCreateOrUpdateRequest(
    url.replace(":dataLayerId", id),
    {},
    true,
    "PUT"
  );
}

export function downloadDataLayerTemplate(layerType) {
  const downloadUrl = dataLayerDownloadTemplateUrl.replace(
    ":layerType",
    layerType
  );
  return downloadFile(downloadUrl);
}

export async function uploadDataLayerTemplate(layerType, layerName, file) {
  const formData = new FormData();

  formData.append("name", layerName);
  formData.append("type", layerType);
  formData.append("desc", "description"); // TODO: take description input from user
  formData.append("file", file);
  return sendCreateOrUpdateRequest(dataLayerUploadTemplateUrl, formData);
}
