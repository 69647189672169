import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

// Actions
import { getSellerMediaByCity } from "../../actions/seller/SellerMediaActions";
import { getSellerInfoById } from "../../actions/seller/SellerActions";

// Utils & Constants
import { SellerWebsiteMediaCard, ProfileInfoSection } from "./SellerUtils";
import { getSellerId } from "../../utils/SellerUtils";
import { DefaultFilterObjects } from "../../constants/GeneralConstants";

// Components
import MediaFilterLocal from "../../components/media-filter/MediaFilter";
import SearchInput from "../../components/search-input/SearchInput";
import Spinner from "../../components/spinner/Spinner";

// Search, Filter and city selection section
function SearchFilterAndCitySelectionSection() {
  // State
  const [filters, setFilters] = useState(DefaultFilterObjects.media);

  return (
    <div className="d-none">
      <div className="d-flex align-items-center py-3 bg-white mt-4">
        <div className="col-3 px-2">
          {/* Search Input */}
          <SearchInput
            placeholder={"Search media sites inventory"}
            className={"shadow-none rounded-lg"}
            searchButton={false}
          />
        </div>

        {/* Filter */}
        <MediaFilterLocal
          className={"rounded-lg shadow-none"}
          mediaFilterObj={filters}
        />

        {/* city selection */}
        {/* TODO:: integrate city selection form */}
        <span className="ml-auto pr-3 d-none">City: All</span>
      </div>
    </div>
  );
}

/**
 * Main Page
 */
export default function SellerWebsitePreviewPage() {
  const dispatch = useDispatch();

  // State
  // TODO:: change "city-selection" with some hardcoded dropdown options
  const [cityId, setCityId] = useState("city-delhi-ncr");

  // Selector State
  const sellerInfo = useSelector((state) => state.seller.sellerInfo);
  const sellerInfoLoading = useSelector(
    (state) => state.seller.sellerInfoLoading
  );

  const mediaList = useSelector(
    (state) => state.sellerMedia.sellerMediaByCityList
  );
  const mediaListLoading = useSelector(
    (state) => state.sellerMedia.sellerMediaByCityLoading
  );

  // Seller Id
  const sellerId = getSellerId();

  // Dispatch
  useEffect(() => {
    dispatch(getSellerInfoById());
    dispatch(getSellerMediaByCity(cityId, sellerId));
  }, [dispatch, cityId]);

  if (sellerInfoLoading || mediaListLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <div className="content-wrapper min-h-100 bg-alt">
      <div className="position-absolute page-banner w-100 bg-alt"></div>
      <div className="container">
        <div className="seller-website-page">
          {/* Profile Section */}
          <ProfileInfoSection sellerInfo={sellerInfo} isPreviewPage={true} />

          {/* Search, Filter and city selection section */}
          <SearchFilterAndCitySelectionSection />

          {/* Media Card */}
          <div className="row">
            {mediaList.map((media) => (
              <SellerWebsiteMediaCard key={media.mediaId} media={media} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
