import { GeoData } from "../../constants/action-constants/GeoDataActionConstants";

//intital state
const initialState = {
  // poi-type-layer data
  // -------------------------------------------------------------------
  poiTypeLayerDataLoading: false,
  poiTypeLayerDataError: "",
  allPoiPointsArr: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // to get "poi-type-layer data"
    // ----------------------------------------------------------------
    case GeoData.GET_POI_TYPE_LAYER_DATA:
      return {
        ...state,
        poiTypeLayerDataLoading: true,
      };
    case GeoData.GET_POI_TYPE_LAYER_DATA_SUCCESS:
      return {
        ...state,
        allPoiPointsArr: action.payload.allPoiPointsArr,
        poiTypeLayerDataLoading: false,
      };
    case GeoData.GET_POI_TYPE_LAYER_DATA_FAILURE:
      return {
        ...state,
        poiTypeLayerDataError: action.payload,
        poiTypeLayerDataLoading: false,
      };

    case GeoData.REMOVE_POI_TYPE_LAYER_DATA:
      return {
        ...state,
        allPoiPointsArr: [],
      };
    default:
      return state;
  }
};
