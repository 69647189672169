import axios from "axios";
import { getToken } from "../token";

function convertFalseToString(params, key) {
  if (params[key] === false) {
    params[key] = "false";
  }
}

function convertStringToFalse(params, key) {
  if (params[key] === "false") {
    params[key] = false;
  }
}

function serializeQueryParams(params) {
  let options = "";
  for (const key in params) {
    convertFalseToString(params, key);
    if (typeof params[key] !== "object" && params[key]) {
      convertStringToFalse(params, key);
      options += `${key}=${params[key]}&`;
    } else if (
      typeof params[key] === "object" &&
      params[key] &&
      params[key].length
    ) {
      params[key].forEach((el) => {
        options += `${key}=${el}&`;
      });
    }
  }
  return options ? options.slice(0, -1) : options;
}

export async function deleteRequest(
  url,
  data = {},
  needsAuth = true,
  params = {},
  incomingHeaders = {}
) {
  const headers = needsAuth
    ? { ...incomingHeaders, Authorization: "Bearer " + getToken() }
    : incomingHeaders;

  const response = await axios({
    url,
    data,
    method: "DELETE",
    params,
    headers,
  });
  return response.data;
}

export async function sendGetRequest(
  url,
  params = {},
  needsAuth = true,
  incomingHeaders = {}
) {
  const headers = needsAuth
    ? { ...incomingHeaders, Authorization: "Bearer " + getToken() }
    : incomingHeaders;

  const response = await axios({
    url,
    method: "GET",
    params,
    headers,
    paramsSerializer: serializeQueryParams,
  });
  return response.data;
}

export async function sendCreateOrUpdateRequest(
  url,
  data = {},
  needsAuth = true,
  method = "POST",
  incomingHeaders = {},
  params = {}
) {
  const headers = needsAuth
    ? { ...incomingHeaders, Authorization: "Bearer " + getToken() }
    : incomingHeaders;

  // Define the default timeout value (in milliseconds)
  const defaultTimeout = 0; //

  // Extract timeout value from params or use the default timeout
  const timeout = params.hasOwnProperty("timeout")
    ? params.timeout
    : defaultTimeout;

  const response = await axios({
    url,
    method,
    params,
    data,
    headers,
    timeout: timeout, // Set the timeout
  });
  return response.data;
}

export async function downloadFile(
  url,
  needsAuth = true,
  incomingHeaders = {},
  params = {},
  responseType = "blob"
) {
  const headers = needsAuth
    ? { ...incomingHeaders, Authorization: "Bearer " + getToken() }
    : incomingHeaders;
  const response = await axios({
    url,
    method: "GET",
    params,
    headers,
    responseType,
  });
  return response.data;
}
