// graph report
export const CampaignGraphReport = {
  // Campaign Media Demographic Stats
  GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS: "GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS",
  GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS_SUCCESS:
    "GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS_SUCCESS",
  GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS_FAILURE:
    "GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS_FAILURE",

  // download campaign graph PDF report
  DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT: "DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT",
  DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT_SUCCESS:
    "DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT_SUCCESS",
  DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT_FAILURE:
    "DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT_FAILURE",
};
