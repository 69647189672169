import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Actions
import {
  downloadTemplate,
  resetSiteUploadState,
  uploadTemplate,
} from "../../../actions/SiteUploadActions";
import { getTargetGroups } from "../../../actions/org/OrgTargetGroupActions";

// Utils and Constants
import { selectFile } from "../../../common-utils/file-utils/FileUtils";
import { DispatchFrom } from "../../../constants/GeneralConstants";
import { defaultPagination } from "../../../constants/UrlConstants";

// Components
import DownloadTemplateCard from "../../../components/site-upload-utils/DownloadTemplateCard";
import UploadTemplateCard from "../../../components/site-upload-utils/UploadTemplateCard";
import { CloseSiteUpload } from "../../../components/site-upload-utils/Utils";
import GenerateTemplateCard from "../../../components/site-upload-utils/GenerateTemplateCard";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import TargetGroupSelectionCard from "../../../components/site-upload-utils/TargetGroupSelectionCard";

const mavinSiteUpload = {
  DownloadTemplateData: {
    step: "STEP 1",
    title: "Download and fill template",
    message: "If you already have a filled site template jump to step 2.",
    buttonLabel: "Download Template",
  },
  TargetGroupData: {
    step: "STEP 2",
    title: "Select Target Group",
    message:
      "Please select a target group for a campaign from created target groups.",
  },
  UploadSiteListData: {
    step: "STEP 3",
    title: "Upload site list",
    message:
      "Please upload using the template format recommended to avoid errors.",
    buttonLabel: "Upload Template",
  },

  GenerateTemplateData: {
    step: "STEP 4",
    title: "Generate Site Report",
    message:
      "We automatically create a report for you that can be modified as needed.",
    buttonLabel: "Generate Report",
  },
};

function PageHeaderSection({ campaignId, cityId }) {
  const pageFrom = DispatchFrom.campaignAnalyser;

  // variables
  const pageTitle = "Upload sites via excel";
  const pageActions = (
    <CloseSiteUpload
      campaignId={campaignId}
      cityId={cityId}
      pageFrom={pageFrom}
    />
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={false} />;
}

/**
 * Page
 */
function CampaignCreatePage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state = {} } = location;
  const { campaignId = "", cityId = "", targetGroupId = "" } = state || {};

  // State
  const [tgId, setTgId] = useState("");

  // from uploaded-template
  const campaignData =
    useSelector((state) => state.c_siteUpload.campaignData) || {};
  const mediaCount = useSelector((state) => state.c_siteUpload.mediaCount);
  const templateDownloading = useSelector(
    (state) => state.c_siteUpload.downloadLoading
  );

  // update tg with existing campaign tg
  useEffect(() => {
    if (targetGroupId) {
      setTgId(targetGroupId);
    }
  }, [targetGroupId]);

  // downloaded template fileName and link to Redirect on Summary-page
  const fileName = "campaign_report_excel_template.xlsx";
  const linkTo = `/campaign/${
    campaignId ? campaignId : campaignData.id
  }/summary`;

  // object for reducerKey and selectorValues to pass in "uploadTemplate"
  const reducerKeyValuesObj = {
    reducerKey: "c_siteUpload",
    selectorValue: {
      loading: "uploadLoading",
      error: "uploadError",
      mediaCount: "mediaCount",
    },
  };

  // Selected Tg Info
  const tgList = useSelector((state) => state.orgTargetGroup.tgTitles) || [];

  // Pagination
  const pageNo = defaultPagination.pageNo,
    pageSize = defaultPagination.pageSize;

  useEffect(() => {
    dispatch(resetSiteUploadState());
    dispatch(getTargetGroups(true, "", pageNo, pageSize));
  }, [dispatch]);

  function handleTgSelection(selectedId) {
    setTgId(selectedId);
  }
  // this function handles file-uploads
  async function uploadTemplateFunction() {
    const files = await selectFile();
    dispatch(uploadTemplate(campaignId, files, false, tgId));
  }

  return (
    <div className="content-wrapper bg-alt h-100 site-upload">
      <PageHeaderSection campaignId={campaignId} cityId={cityId} />

      <div className="page-content">
        {/* for downloading template of excel file */}
        <DownloadTemplateCard
          isDisable={templateDownloading}
          onClick={() => dispatch(downloadTemplate(fileName))}
          downloadTemplateData={mavinSiteUpload.DownloadTemplateData}
        />

        {/* for selecting target group */}
        <TargetGroupSelectionCard
          TargetGroupData={mavinSiteUpload.TargetGroupData}
          tgList={tgList}
          campaignTgId={targetGroupId}
          handleTgSelection={handleTgSelection}
        />

        {/* for excel-file template upload */}
        <UploadTemplateCard
          uploadSiteListData={mavinSiteUpload.UploadSiteListData}
          reducerKeyValuesObj={reducerKeyValuesObj}
          uploadTemplateFunction={uploadTemplateFunction}
          isUploadTemplateButtonDisabled={!tgId}
        />

        {/* to redirect to campaign-summary-page */}
        <GenerateTemplateCard
          linkTo={linkTo}
          mediaCount={mediaCount}
          generateTemplateData={mavinSiteUpload.GenerateTemplateData}
          campaignId={campaignId}
        />
      </div>
    </div>
  );
}

export default CampaignCreatePage;
