import { ActionTypes } from "../../constants/ActionConstants";
import { CampaignMedia } from "../../constants/action-constants/CampaignMediaActionConstants";

export const getMediaDetails = (campaignId, mediaId) => ({
  type: CampaignMedia.GET_MEDIA_BY_ID,
  payload: { campaignId, mediaId },
});

export const createMedia = (
  createMediaBean,
  imageFiles,
  campaignId,
  cityId
) => ({
  type: CampaignMedia.CAMPAIGN_MEDIA_CREATE,
  payload: { createMediaBean, imageFiles, campaignId, cityId },
});

export const generateMavinMetrics = (campaignId, cityId) => ({
  type: CampaignMedia.GENERATE_MAVIN_METRICS,
  payload: { campaignId, cityId },
});

// These below actions are for create media form
export const openCreateCampaignMediaForm = () => ({
  type: CampaignMedia.OPEN_CREATE_MEDIA_MODAL,
});

export const closeCreateCampaignMediaForm = () => ({
  type: CampaignMedia.CLOSE_CREATE_MEDIA_MODAL,
});

export const uploadFileItems = (data) => ({
  type: CampaignMedia.UPLOAD_FILE_ITEMS,
  payload: data,
});

export const updateCampaignMediaDuration = (dateObj, media) => ({
  type: CampaignMedia.UPDATE_CAMPAIGN_MEDIA_DURATION,
  payload: { dateObj, media },
});

export const getCampaignMediaByCity = (
  id,
  cityId,
  pageNumber = 1,
  pageSize = 10,
  applyFilters
) => ({
  type: CampaignMedia.CAMPAIGN_CITYVIEW,
  payload: {
    id,
    cityId,
    pn: pageNumber,
    ps: pageSize,
    applyFilters,
  },
});

export const addMedia = (mediaId) => ({
  type: CampaignMedia.ADD_CAMPAIGN_MEDIA,
  payload: { mediaId },
});

export const removeMedia = (mediaId) => ({
  type: CampaignMedia.REMOVE_CAMPAIGN_MEDIA,
  payload: { mediaId },
});

export const getCampaignMedia = (campaignId, pageNo, pageSize) => ({
  type: ActionTypes.Media.GET_CAMPAIGN_MEDIA,
  payload: { campaignId, pageNo, pageSize },
});

export const downloadMediaPdfReport = (
  campaignId,
  mediaId,
  radius,
  mediaTitle
) => ({
  type: CampaignMedia.DOWNLOAD_MEDIA_PDF_REPORT,
  payload: { campaignId, mediaId, radius, mediaTitle },
});
