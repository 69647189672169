import { getRegionDataByCityUrl, getRegionNamesUrl } from "../urls/RegionURL";
import { sendGetRequest } from "../utils/api/apiUtils";

export async function getRegionDataByCityId(cityId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getRegionDataByCityUrl.replace(":cityId", cityId);
  return sendGetRequest(url, {}, true, headerParams);
}

export async function getRegionNamesList() {
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendGetRequest(getRegionNamesUrl, {}, true, headerParams);
}
