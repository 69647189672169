export const getRoadStretchesByBboxUrl = "/api/v3/org/road_stretch/search/bbox";
export const getRoadStretchesOfOrgUrl = "/api/v3/org/road_stretch/search";
export const getRoadStretchesByIdsUrl = "/api/v3/org/road_stretch/find";
export const restoreRoadStretchUrl =
  "/api/v3/org/road_stretch/:stretchId/restore";
export const archiveRoadStretchUrl =
  "/api/v3/org/road_stretch/:stretchId/archive";
export const createRoadStretchUrl = "/api/v3/org/road_stretch";
export const getRoadStretchInfoByIdUrl = "/api/v3/org/road_stretch/:stretchId";
export const roadStretchPriceRefreshUrl =
  "/api/v3/org/road_stretch/:stretchId/price_indicators";
