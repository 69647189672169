import React from "react";

function CheckBox({
  boxStyle,
  checkBoxKey,
  inputBoxStyle,
  onChange,
  disabled = false,
  checked = true,
}) {
  return (
    <div className={`form-group form-check ${boxStyle}`}>
      <input
        id={checkBoxKey}
        type="checkbox"
        className={`form-check-input ${inputBoxStyle}`}
        checked={checked}
        onChange={(e) => onChange(e, checkBoxKey)}
        disabled={disabled}
      />
      <label className="form-check-label px-1" htmlFor={checkBoxKey}>
        {checkBoxKey}
      </label>
    </div>
  );
}

export default CheckBox;
