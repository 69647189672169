import { RoadSegment } from "../../constants/action-constants/RoadSegmentActionConstants";

const initialState = {
  roadSegmentDetailsMap: {},
  segmentsLoading: false,
  segmentsErr: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RoadSegment.GET_ROAD_SEGMENTS:
      return {
        ...state,
        segmentsLoading: true,
      };

    case RoadSegment.GET_ROAD_SEGMENTS_SUCCESS:
      return {
        ...state,
        segmentsLoading: false,
        roadSegmentDetailsMap: action.payload.segmentsMap,
      };

    case RoadSegment.GET_ROAD_SEGMENTS_FAILURE:
      return {
        ...state,
        segmentsLoading: false,
        segmentsErr: action.payload,
      };

    default:
      return state;
  }
};
