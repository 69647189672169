// Constants and Utils
import { selectFile } from "../../../common-utils/file-utils/FileUtils";
import { HourlyOtsProfileByGenderData } from "./CameraAnalyticToolConstants";

// Components
import { BarChart } from "../../../components/charts/Charts";
import { OutlineButtonWithLoader } from "../../../mavin/components/button/Button";

// Device And Location And Upload Section
function DeviceAndLocationAndUploadSection() {
  const deviceAndLocationData = [
    { label: "Device ID", value: "Cam_xyz123" },
    { label: "Location Type", value: "Ambient / Road" },
  ];

  const buttonDisplayContent = (
    <div>
      <span>Upload Video</span>
      <i className="fas fa-upload ml-2" />
    </div>
  );

  // function
  async function handleUploadVideo() {
    const files = await selectFile(true);
  }

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div>
        {deviceAndLocationData.map((info, i) => (
          <p key={i} className="mb-0">
            <b>{info.label}: </b>
            {info.value}
          </p>
        ))}
      </div>

      <div className="text-center">
        <OutlineButtonWithLoader
          onClickFunction={handleUploadVideo}
          className="btn-outline-secondary btn-sm"
          displayContent={buttonDisplayContent}
        />
        <p className="mb-0 text-muted">max 250Mb</p>
      </div>
    </div>
  );
}

// Video Container
function VideoContainer() {
  return (
    <div className="border rounded-xl camera-analytic-video-cont">
      <i className="fas fa-play-circle fa-5x text-light-green cursor-pointer" />
    </div>
  );
}

// Hourly Ots Profile By Gender
function HourlyOtsProfileByGender() {
  // bar data keys
  const eachBarData = [{ dataKey: "Male" }, { dataKey: "Female" }];

  return (
    <>
      <h4 className="my-3">Hourly OTS Profile (by Gender)</h4>
      <BarChart
        data={HourlyOtsProfileByGenderData}
        barsData={eachBarData}
        showCartesianGrid={true}
        showCustomXAxisTick={true}
      />
    </>
  );
}

/**
 * Camera Analytic Right Section Content
 */
export default function CameraAnalyticRightSectionContent() {
  return (
    <>
      {/* device and location, upload video */}
      <DeviceAndLocationAndUploadSection />

      {/* Video Container */}
      <VideoContainer />

      {/* Hourly OTS Profile by Gender */}
      <HourlyOtsProfileByGender />
    </>
  );
}
