import React from "react";
import { Link } from "react-router-dom";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Constants and Utils
import { checkIsSellerUser } from "../../utils/SellerUtils";

// Page Constants
const {
  orgProfileUrl,
  orgUserListUrl,
  orgSubscriptionUrl,
  orgTargetGroupListUrl,
  orgRoadStretchListUrl,
  orgDataLayerListUrl,
  orgMediaPricePageUrl,
} = RedirectTo;

// Page Components
function Profile({ activeIdx }) {
  return (
    <li className={`nav-item ${activeIdx === 1 ? "active" : ""} `}>
      <Link className="nav-link" to={orgProfileUrl}>
        <i className="fas fa-address-card"></i> Profile
      </Link>
    </li>
  );
}

function UserAndRoles({ activeIdx }) {
  return (
    <li className={`nav-item ${activeIdx === 2 ? "active" : ""} `}>
      <Link className="nav-link" to={orgUserListUrl}>
        <i className="fas fa-users"></i> Users &amp; Roles
      </Link>
    </li>
  );
}

function Subscription({ activeIdx }) {
  return (
    <li className={`nav-item ${activeIdx === 3 ? "active" : ""} `}>
      <Link className="nav-link" to={orgSubscriptionUrl}>
        <i className="fas fa-crown"></i> Subscription
      </Link>
    </li>
  );
}

function TargetGroups({ activeIdx }) {
  return (
    <li className={`nav-item ${activeIdx === 4 ? "active" : ""} `}>
      <Link className="nav-link" to={orgTargetGroupListUrl}>
        <i className="fas fa-user-tie"></i> Target Groups
      </Link>
    </li>
  );
}
function RoadStretches({ activeIdx }) {
  return (
    <li className={`nav-item ${activeIdx === 5 ? "active" : ""} `}>
      <Link className="nav-link" to={orgRoadStretchListUrl}>
        <i className="fas fa-road"></i> Road Stretches
      </Link>
    </li>
  );
}

function DataLayers({ activeIdx }) {
  return (
    <li className={`nav-item ${activeIdx === 6 ? "active" : ""} `}>
      <Link className="nav-link" to={orgDataLayerListUrl}>
        <i className="fas fa-layer-group"></i> Data Layers
      </Link>
    </li>
  );
}

function MediaPrice({ activeIdx }) {
  return (
    <li className={`nav-item ${activeIdx === 7 ? "active" : ""} `}>
      <Link className="nav-link" to={orgMediaPricePageUrl}>
        <i className="fas fa-map-marker-alt"></i> Media Price
      </Link>
    </li>
  );
}

/**
 * Common Sidebar for Organization Settings pages - URL Pattern : "/org/**"
 */
export default function OrgSettingsSidebar({ activeIdx }) {
  // Checking the USER is Seller or not
  const isSellerUser = checkIsSellerUser();

  return (
    <aside className="sidebar bg-alt">
      <ul className="nav flex-column mt-3">
        {/** Basic Settings */}
        <li className="nav-item group-header">Organization</li>
        <Profile activeIdx={activeIdx} />
        <UserAndRoles activeIdx={activeIdx} />
        <Subscription activeIdx={activeIdx} />

        {!isSellerUser && (
          <>
            {/** Definitions */}
            <li className="nav-item group-header">Definitions</li>
            <TargetGroups activeIdx={activeIdx} />
            <RoadStretches activeIdx={activeIdx} />
            <DataLayers activeIdx={activeIdx} />

            {/** Inventory */}
            <li className="nav-item group-header">Inventory</li>
            <MediaPrice activeIdx={activeIdx} />
          </>
        )}
      </ul>
    </aside>
  );
}
