// Urls
import { getRouteSummaryUrl } from "../../urls/mavin-tools/RouteAnalysisURL";

// Utils
import { sendCreateOrUpdateRequest } from "../../utils/api/apiUtils";

export async function getRouteSummaryFn(
  stretchId,
  targetGroupId,
  startTS,
  duration
) {
  // Request Headers
  const headerParams = {
    "Content-Type": "application/json",
  };

  const body = {
    targetGroupId,
    startTS,
    durationInDays: duration,
  };
  const url = getRouteSummaryUrl.replace(":roadStretchId", stretchId);

  return sendCreateOrUpdateRequest(url, body, true, "POST", headerParams);
}
