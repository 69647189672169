import { all, put, takeLatest } from "redux-saga/effects";

// Api
import { getLocationSummaryFn } from "../../apis/LocationSummaryAPI";

// Constants
import { LocationSummary } from "../../constants/action-constants/LocationSummaryActionConstants";

export function* getLocationSummary(action) {
  try {
    const { latitude, longitude, distance } = action.payload;

    const kypData = yield getLocationSummaryFn(latitude, longitude, distance);

    // dispatching action
    yield put({
      type: LocationSummary.GET_LOCATION_SUMMARY_SUCCESS,
      data: kypData.result,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: LocationSummary.GET_LOCATION_SUMMARY_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(LocationSummary.GET_LOCATION_SUMMARY, getLocationSummary),
  ]);
}
