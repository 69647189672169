import { MediaSitesSelection } from "../../../constants/action-constants/map-view/media-sites/MediaSitesActionConstants";

//initial state
const initialState = {
  selectedSites: {},
};

function multipleSitesAction(ids, select = true) {
  const selectedSites = {};
  for (const id of ids) {
    selectedSites[id] = select;
  }
  return selectedSites;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MediaSitesSelection.SELECT:
      return {
        ...state,
        selectedSites: {
          ...state.selectedSites,
          [action.payload.id]: true,
        },
      };

    case MediaSitesSelection.SELECT_MULTIPLE:
      return {
        ...state,
        selectedSites: Object.assign(
          state.selectedSites,
          multipleSitesAction(action.payload.ids, true)
        ),
      };

    case MediaSitesSelection.UNSELECT:
      return {
        ...state,
        selectedSites: {
          ...state.selectedSites,
          [action.payload.id]: false,
        },
      };

    case MediaSitesSelection.UNSELECT_MULTIPLE:
      return {
        ...state,
        selectedSites: Object.assign(
          state.selectedSites,
          multipleSitesAction(action.payload.ids, false)
        ),
      };

    case MediaSitesSelection.RESET:
      return {
        selectedSites: {},
      };

    default:
      return state;
  }
};
