export const Poi = {
  GET_POI: "GET_POI_HIRARCHY",
  GET_POI_SUCCESS: "GET_POI_HIRARCHY_SUCCESS",
  GET_POI_FAILURE: "GET_POI_HIRARCHY_FAILURE",
  SET_CHECKLIST_SELECTED: "SET_POI_CHECKLIST_SELECTED",
  SET_CHECKBOX_STATUS: "SET_POI_CHECKBOX_STATUS",
  RESET: "REST_POI",

  GET_BRAND_INFO: "GET_POI_BRAND_INFO",
  GET_BRAND_INFO_SUCCESS: "GET_POI_BRAND_INFO_SUCCESS",
  GET_BRAND_INFO_FAILURE: "GET_POI_BRAND_INFO_FAILURE",

  REMOVE_SECTORS_INFO: "REMOVE_POI_SECTORS_INFO",
  RESET_SECTORS_INFO: "RESET_SECTORS_INFO",

  REMOVE_BRANDS_INFO: "REMOVE_POI_BRAND_INFO",
  RESET_BRANDS_INFO: "RESET_BRANDS_INFO",

  SET_POI_STATUS: "SET_POI_STATUS",

  // Hide/Show Influence Circle Markers
  SHOW_POI_INFLUENCE_CIRCLE_MARKERS: "SHOW_POI_INFLUENCE_CIRCLE_MARKERS",
  REMOVE_POI_INFLUENCE_CIRCLE_MARKERS: "REMOVE_POI_INFLUENCE_CIRCLE_MARKERS",
  SET_INFLUENCE_CIRCLE_RADIUS: "SET_INFLUENCE_CIRCLE_RADIUS",

  // Reset POI influnce
  RESET_POI_INFLUENCE: "RESET_POI_INFLUENCE",
};
