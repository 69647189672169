import { CampaignGraphReport } from "../../constants/action-constants/CampaignGraphReportActionConstants";

// Campaign Media Demographic stats
export const getCampaignMediaDemographicStats = (campaignId) => ({
  type: CampaignGraphReport.GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS,
  payload: { campaignId },
});

// campaign graph pdf report
export const downloadCampaignGraphPdfReport = (
  campaignId,
  campaignTitle,
  selectedCityId,
  selectedMetricKey,
  mapCenter,
  mapZoom
) => ({
  type: CampaignGraphReport.DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT,
  payload: {
    campaignId,
    campaignTitle,
    selectedCityId,
    selectedMetricKey,
    mapCenter,
    mapZoom,
  },
});
