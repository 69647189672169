import { useEffect } from "react";
import { Marker } from "react-leaflet";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

// Actions
import {
  getLtsInfo,
  resetLtsInfo,
} from "../../actions/mavin-tools/LtsToolActions";

// Utils & Constants
import { ApiDataErrorMessage, getMapZoomLevel } from "./ToolsUtils";
import { constructRadarChartData } from "../../utils/ChartUtils";
import { India } from "../../constants/GeneralConstants";
import {
  getCoordinates,
  isValidCoordinate,
} from "../../common-utils/geo-util/coordinatesUtil";

// Components
import {
  MediaDimension,
  MediaTypeAndLitStatus,
} from "../../components/campaign-media-form/CampaignMediaFormUtils";
import LLMap from "../../components/map/leaflet-map/LLMap";
import PinDropLocationAndZoom from "../../components/map/pin-drop-select/PinDropLocationAndZoom";
import PlacesAutocomplete from "../../components/places-autocomplete/PlacesAutocomplete";
import PageHeader from "../../mavin/components/page-header/PageHeader";
import MapIcon from "../../components/map/map-icon/MapIcon";
import { ButtonWithLoader } from "../../mavin/components/button/Button";
import Rating from "../../mavin/components/media-site-performance/Rating";
import {
  validateForm,
  MediaTiltAngle,
} from "../../mavin/components/media-info/SiteDetailsFormSection";
import { RadarChart } from "../../components/charts/Charts";

// Page Components
function LtsInformation() {
  // Selector
  const ltsInfo = useSelector((state) => state.ltsTool.ltsInfo) || {};
  const ltsInfoError = useSelector((state) => state.ltsTool.ltsError);
  const { lts, region } = ltsInfo;

  // Lts Info Error Msg
  if (ltsInfoError) {
    return (
      <ApiDataErrorMessage
        errorMessage={ltsInfoError}
        customMessage={"Please Select Another Location"}
      />
    );
  }

  // Checks for ltsInfo
  if (Object.keys(ltsInfo).length < 1) {
    return null;
  }

  const {
    areaIndex,
    aspectRatioIndex,
    clutterIndex,
    elevationIndex,
    tiltIndex,
  } = lts || {};

  const ltsInfoConstants = [
    {
      label: "Aspect ratio",
      value: aspectRatioIndex,
    },
    {
      label: "Area",
      value: areaIndex,
    },
    {
      label: "Clutter",
      value: clutterIndex,
    },
    {
      label: "Elevation",
      value: elevationIndex,
    },
    {
      label: "Tilt",
      value: tiltIndex,
    },
  ];

  // Chart data
  const chartData = constructRadarChartData(lts, region);

  return (
    <>
      <h3 className="mb-3 px-3">{"Likelihood to See"}</h3>
      <div className="d-flex">
        <div className="col-6 mt-3">
          {/* Lts info list */}
          {ltsInfoConstants.map((info) => (
            <div key={info.label} className="mb-2">
              <Rating displayName={info.label} value={info.value} />
            </div>
          ))}
        </div>

        {/* Radar Chart */}
        <div className="col-6">
          <RadarChart data={chartData} height={379} />
        </div>
      </div>
    </>
  );
}

function LtsToolMapView({ coordinate, getCoordinatesFromPinDrop }) {
  // State
  const [mapZoom, setMapZoom] = useState(null);
  // Center
  const center = coordinate.length > 1 ? coordinate : India.mapCenter;
  const zoom = getMapZoomLevel(coordinate, mapZoom);
  const icon = new MapIcon({ type: "selected-poi" });

  return (
    <div className="map-layout-right bg-alt">
      <LLMap center={center} zoom={zoom}>
        <PinDropLocationAndZoom
          onLocationSelect={getCoordinatesFromPinDrop}
          mapZoom={mapZoom}
          setMapZoom={setMapZoom}
        />
        {coordinate.length > 1 && <Marker position={coordinate} icon={icon} />}
      </LLMap>
    </div>
  );
}

/**
 *
 * Media Inputs
 */
function MediaInputSection({ coordinate }) {
  const dispatch = useDispatch();
  // State
  // Type & Lit status
  const [type, setType] = useState("");
  const [litStatusStr, setLitStatusStr] = useState("");

  // Dimension
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [elevation, setElevation] = useState("");
  const [tiltAngle, setTiltAngle] = useState("");

  // Selector
  const ltsInfoLoading = useSelector((state) => state.ltsTool.ltsInfoLoading);

  // Media dimension value and set value function
  const mediaDimensionValuesAndFunction = {
    width,
    height,
    elevation,
    setWidth,
    setHeight,
    setElevation,
  };

  const [latitude, longitude] = coordinate;

  const mediaInfoBean = {
    type,
    lit: litStatusStr,
    width,
    height,
    elevation,
    latitude,
    longitude,
    tilt: tiltAngle,
  };

  // Function to get an lts information
  function getLtsInfoFn() {
    // Checking whether all the fields are filled
    const isAllInputsFilled = validateForm(mediaInfoBean);
    if (isAllInputsFilled) {
      dispatch(getLtsInfo(mediaInfoBean));
    }
  }

  return (
    <div className="py-3">
      <MediaTypeAndLitStatus
        type={type}
        setType={setType}
        litStatusStr={litStatusStr}
        setLitStatusStr={setLitStatusStr}
        className={"rounded-lg"}
      />
      <MediaDimension
        mediaDimensionValuesAndFunction={mediaDimensionValuesAndFunction}
        className={"rounded-lg"}
      />

      {/* temporarily hiding tilt angle field*/}
      <div className="d-none">
        <MediaTiltAngle tiltAngle={tiltAngle} setTiltAngle={setTiltAngle} />
      </div>

      <div className="text-right">
        <ButtonWithLoader
          displayContent={"Get LTS"}
          onClickFunction={getLtsInfoFn}
          loader={ltsInfoLoading}
          isDisabled={ltsInfoLoading}
        />
      </div>
      <hr className="divider mt-4 row"></hr>
    </div>
  );
}

/**
 * Lts Tool Page
 */
function LtsToolPage() {
  // State
  const [locationStr, setLocationStr] = useState("");
  const [coordinate, setCoordinate] = useState([]);

  // Dispatch
  const dispatch = useDispatch();

  // Reset lts info
  useEffect(() => {
    dispatch(resetLtsInfo());

    // to clear the Coordinates
    if (!locationStr) {
      return setCoordinate([]);
    }
  }, [dispatch, locationStr]);

  // This call-back function is used to pick the lat-lng
  function getCoordinatesFromPinDrop({ lat, lng }) {
    const coordinateString = `${lat},${lng}`;
    setLocationStr(coordinateString);
    setCoordinate(getCoordinates(coordinateString));
  }

  function getCoordinatesFromAutoComplete(locationStr) {
    setLocationStr(locationStr);
    if (isValidCoordinate(locationStr)) {
      setCoordinate(getCoordinates(locationStr));
    }
  }

  return (
    <div className="content-wrapper map-content-wrapper">
      {/* Map Left Section */}
      <div className="map-layout-left">
        <PageHeader title={"Likelihood to See (LTS)"} shadow={false} />
        <hr className="divider my-3 row"></hr>

        {/* Location search */}
        <PlacesAutocomplete
          className={"rounded-lg shadow-none"}
          placeholder={"Search location OR enter Lat Lng"}
          locationStr={locationStr}
          setLocationStr={getCoordinatesFromAutoComplete}
        />

        {/* Media Input Section */}
        <MediaInputSection coordinate={coordinate} />

        {/* Lts Information */}
        <LtsInformation />
      </div>

      {/* Map Right Section */}
      <LtsToolMapView
        coordinate={coordinate}
        getCoordinatesFromPinDrop={getCoordinatesFromPinDrop}
      />
    </div>
  );
}

export default LtsToolPage;
