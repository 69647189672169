import { useSelector } from "react-redux";

// Pages and Sections
import PoiListingSection from "./PoiListingSection";
import SelectedPoiSection from "./SelectedPoiSection";
import MapTab from "../MapTab";

// constants
const selectedViewLabels = {
  header: {
    title: "Points of Interest",
    changeViewBtnText: "Add Points of Interest To Map",
  },
  body: {
    emptyText: "Add Points of Interest",
    emptyIconCls: "fas fa-compass",
  },
  search: {
    placeholder: "Search points of interest",
    fields: ["label"],
  },
};

const listingViewLabels = {
  header: {
    title: "Add Points of Interest",
    createBtnText: "",
    createBtnUrl: "",
  },
  body: {
    emptyText: "No Points of Interest are available.",
  },
  search: {
    placeholder: "Search points of interest",
    fields: ["label"],
  },
};

/**
 * TAB - POINTS OF INTEREST
 */
function PointsOfInterestTab({ cityId }) {
  // Selector - poi dataList
  const dataList = useSelector((state) => state.poi.dataList);

  // Selected Brand Data
  const selectedBrandData = useSelector(
    (state) => state.poiSelection.brandData
  );
  // Selected Brands Name
  const selectedBrandsMap = useSelector((state) => state.poiSelection.brandIds);
  const selectedBrandsNames = Object.keys(selectedBrandsMap).filter(
    (eachBrandKey) => selectedBrandsMap[eachBrandKey]["selected"]
  );

  // Functions
  // getting content for "selectedViewContent"
  function checkForSelectedViewContent() {
    if (!selectedBrandsNames || selectedBrandsNames.length === 0) {
      return null;
    }
    return <SelectedPoiSection selectedBrandData={selectedBrandData} />;
  }

  // Selected View :: Body
  const selectedViewContent = checkForSelectedViewContent();
  // Listing View :: Body
  const listingViewContent = (
    <PoiListingSection dataList={dataList} cityId={cityId} />
  );

  // Tab Rendered Content ----------------------------------------
  return (
    <MapTab
      selectedViewLabels={selectedViewLabels}
      selectedViewChildren={selectedViewContent}
      listingViewLabels={listingViewLabels}
      listingViewChildren={listingViewContent}
    />
  );
}

export default PointsOfInterestTab;
