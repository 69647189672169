function PageLink({ pageSize, pageNumber, label, onClick }) {
  return (
    <span
      className="page-link cursor-pointer"
      onClick={() => onClick(pageNumber, pageSize)}
    >
      {label}
    </span>
  );
}

function MoveToStartPage({ pageSize, pageNo, onClick }) {
  return (
    <li className={`page-item ${pageNo === 1 ? "disabled" : ""}`}>
      <PageLink
        pageSize={pageSize}
        pageNumber={1}
        label={"«"}
        onClick={onClick}
      ></PageLink>
    </li>
  );
}

function MoveToLastPage({ pageSize, pageNo, numberOfPages, onClick }) {
  return (
    <li className={`page-item ${pageNo === numberOfPages ? "disabled" : ""}`}>
      <PageLink
        pageSize={pageSize}
        pageNumber={numberOfPages}
        label={"»"}
        onClick={onClick}
      ></PageLink>
    </li>
  );
}

function Pages({ pageSize, pageNo, numberOfPages, onClick }) {
  const rows = [];
  for (let i = 1; i < numberOfPages + 1; i++) {
    rows.push(
      <li className={`page-item ${pageNo === i ? "active" : ""}`} key={i}>
        <PageLink
          pageSize={pageSize}
          pageNumber={i}
          label={i}
          onClick={onClick}
        ></PageLink>
      </li>
    );
  }
  return rows;
}

function Pagination({ totalCount, pageNo, pageSize, onClick, className = "" }) {
  const numberOfPages = Math.ceil(totalCount / pageSize);
  if (numberOfPages <= 1) {
    return null;
  }
  return (
    <div className={`pagination-cont clearfix ${className}`}>
      <ul className="pagination float-right">
        <MoveToStartPage
          pageSize={pageSize}
          pageNo={pageNo}
          onClick={onClick}
        />

        <Pages
          pageSize={pageSize}
          pageNo={pageNo}
          numberOfPages={numberOfPages}
          onClick={onClick}
        />

        <MoveToLastPage
          pageSize={pageSize}
          pageNo={pageNo}
          numberOfPages={numberOfPages}
          onClick={onClick}
        />
      </ul>
    </div>
  );
}

export default Pagination;
