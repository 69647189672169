import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { getCampaignPlan } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";

// Utils and constants
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";

// Components
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import Spinner from "../../../components/spinner/Spinner";
import CityViewCards from "../components/CityViewCards";
import { CampaignDetails, CancelCampaign } from "../components/HeaderNav";
import MapView from "../components/map-view/MapView";
import MediaDetailsTable from "../components/MediaDetailsTable";
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Page-Component
function HeaderSection({ campaign }) {
  const updateStateLoading = useSelector(
    (state) => state.c_campaignBasicInfo.updateStateLoading
  );

  if (!campaign) {
    return null;
  }
  const EnableCancelCampaign = isAgencySpecific(CancelCampaign);

  const pageTitle = <CampaignDetails campaign={campaign} />;
  const pageActions = (
    <div className="d-flex mt-2">
      <div>
        {EnableCancelCampaign && <EnableCancelCampaign campaign={campaign} />}
      </div>
      {updateStateLoading && <Spinner className="mx-3" />}
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}

/**
 * Page - Campaign Complete
 */
function CampaignCompletePage() {
  // State
  const { campaignId, cityId } = useParams();

  // Get campaignPlan
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);
  const { targetGroupId } = campaignPlan;

  // tg-name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Campaign Media
  const campaignMedia = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  // Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCampaignPlan(campaignId, cityId));
    dispatch(getBasicCampaignDetails(campaignId));
  }, [dispatch, campaignId, cityId]);

  useGetTgInfo(targetGroupId);

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar campaign={false} />

      <div className="content-wrapper">
        {/* Page Header Section */}
        <HeaderSection campaign={campaign} />

        {/* Page-Content */}
        <div className="page-content">
          {/* city view Cards Section */}
          <CityViewCards
            tgName={tgName}
            campaign={campaign}
            cityPlan={campaignPlan}
            campaignMedia={campaignMedia}
          />

          {/* TODO: integration pending - 
          I (Trilok) commented this component (please revisit this component Manoj) */}
          {/* <CityPerformanceTable /> */}

          {/* Map View */}
          <MapView campaignPlan={campaignPlan} />

          {/* Media Sites Table */}
          <MediaDetailsTable
            campaignId={campaignId}
            campaign={campaign}
            campaignPlan={campaignPlan}
          />
        </div>
      </div>
    </>
  );
}

export default CampaignCompletePage;
