import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getMapViewMediaSites } from "../../../actions/map-view/media-sites/MediaSitesActions";

// Constants
import { defaultPagination } from "../../../constants/UrlConstants";
import { useUrlKeyCheck } from "../../../mavin/utils/hooks/HooksUtil";
import { UrlKeys } from "../../../constants/GeneralConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";

//Sections
import SelectedSitesSection from "./SelectedSitesSection";
import ExploreSitesSection from "./ExploreSitesSection";
import MapTab from "../MapTab";

// Context
import { orgMediaContext as mantaraySpecificContext } from "../../../mantaray/pages/campaign/campaign-map-view/CampaignMapView";
import { orgMediaContext } from "../../campaign/campaign-map-view/CampaignMapView";

// constants
const selectedViewLabels = {
  header: {
    title: "Selected Media Site",
    changeViewBtnText: "View Media Inventory",
    changeViewBtnText2: "View Org Media Inventory",
  },
  body: {
    emptyText: "Add Media Sites to campaign",
    emptyIconCls: "fas fa-map-marker-alt",
  },
  search: {
    placeholder: "Search media sites",
    fields: ["title"],
  },
};

const listingViewLabels = {
  header: {
    title: "Media Inventory",
    createBtnText: "",
    createBtnUrl: "",
  },

  search: {
    placeholder: "Search media sites from inventory",
    fields: ["title"],
  },
};

/**
 * TAB - MEDIA SITES
 */
export default function MediaSitesTab({ campaignId, cityId }) {
  const dispatch = useDispatch();

  // checking if url contains product id and using accordingly
  const { mantaraySpecific } = UrlKeys;
  const isMantaraySpecific = useUrlKeyCheck(mantaraySpecific);

  const [isOrgMedia, setIsOrgMedia] = useContext(
    isMantaraySpecific ? mantaraySpecificContext : orgMediaContext
  );

  //Selectors
  // not Filtered (Api Response)
  const originalMediaSites = useSelector(
    (state) => state.mapViewMediaSites.originalMediaSites
  );

  // Filtered media sites
  const mediaList = useSelector(
    (state) => state.mapViewMediaSites.filteredMediaSites
  );
  const mediaListLoading = useSelector(
    (state) => state.mapViewMediaSites.loading
  );

  const pageNumber = defaultPagination.pageNo,
    pageSize = defaultPagination.pageSize;

  //Dispatch
  useEffect(() => {
    // When we want to actually dispatch the getMedia request..
    // 1) When mediaList is empty
    // 2) If the mediaList is not empty and if the current cityId does not
    //    with the mediaCityId
    if (mediaList.length === 0 || cityIdFromMedia !== cityId) {
      dispatch(getMapViewMediaSites(campaignId, cityId, pageNumber, pageSize));
    }
  }, [dispatch, campaignId, cityId]);

  const oneMedia = mediaList[0] || {};
  const cityIdFromMedia = oneMedia.cityId || "";

  // Functions
  // getting content for "selectedViewContent"
  function checkForSelectedViewContent() {
    if (originalMediaSites.length === 0) {
      return null;
    }

    return (
      <SelectedSitesSection
        mediaList={mediaList}
        selectedViewLabels={selectedViewLabels}
        campaignId={campaignId}
        cityId={cityId}
      />
    );
  }

  // Selected View :: Body
  const selectedViewChildren = checkForSelectedViewContent();
  // Listing View :: Body
  const listingViewChildren = (
    <ExploreSitesSection
      listingViewLabels={listingViewLabels}
      cityId={cityId}
      isOrgMedia={isOrgMedia}
    />
  );

  // Tab Rendered Content ------------------------------------
  if (mediaListLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner className="mt-2" />
      </div>
    );
  }

  return (
    <MapTab
      selectedViewLabels={selectedViewLabels}
      selectedViewChildren={selectedViewChildren}
      listingViewLabels={listingViewLabels}
      listingViewChildren={listingViewChildren}
      setIsOrgMedia={setIsOrgMedia}
    />
  );
}
