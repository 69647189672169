import React from "react";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { approxOts } from "../../../actions/campaign-report/CampaignCityReportActions";

//Components
import Spinner from "../../../components/spinner/Spinner";

function cannotApproximateMessage(cannotApproximateMediaCount) {
  const message = "can not be approximated.";
  if (cannotApproximateMediaCount > 1) {
    return `${cannotApproximateMediaCount} sites ${message}`;
  }
  return `${cannotApproximateMediaCount} site ${message}`;
}

function CannotApproximate({ cannotApproximateMediaCount }) {
  return (
    cannotApproximateMediaCount > 0 && (
      <span className="text-info ml-2">
        {cannotApproximateMessage(cannotApproximateMediaCount)}
      </span>
    )
  );
}

function canApproximateMessage(canApproximateMediaCount) {
  const message = " can be approximated, ";
  if (canApproximateMediaCount > 1) {
    return `${canApproximateMediaCount} sites ${message}`;
  }
  return `${canApproximateMediaCount} site ${message}`;
}

function CanApproximate({ canApproximateMediaCount, onClickApproxOts }) {
  return (
    canApproximateMediaCount > 0 && (
      <div className="d-flex flex-column ml-4">
        <span className="text-info">
          {canApproximateMessage(canApproximateMediaCount)}
        </span>
        <button
          data-html2canvas-ignore="true"
          className="btn btn-link pl-0"
          onClick={onClickApproxOts}
        >
          {"Please Click here to approve"}
        </button>
      </div>
    )
  );
}

function ApproxMavinMetrics({ campaignId, cityId }) {
  //Dispatch
  const dispatch = useDispatch();

  //Selectors states
  const { summary: citySummary = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );
  const canApproximateMediaCount = citySummary?.canBeApproximatedCount;
  const cannotApproximateMediaCount = citySummary?.cannotBeApproximatedCount;

  const previouscanBeApproximatedCount = useSelector(
    (state) => state.campaignCityReport.previouscanBeApproximatedCount
  );
  const previousCannotBeApproximatedCount = useSelector(
    (state) => state.campaignCityReport.previousCannotBeApproximatedCount
  );

  // approx Ots Loading
  const isLoading = useSelector(
    (state) => state.campaignCityReport.approxOtsForCityloading
  );

  // Functions
  async function approximateOts() {
    dispatch(
      approxOts(
        campaignId,
        cityId,
        previouscanBeApproximatedCount,
        previousCannotBeApproximatedCount
      )
    );
  }

  //Checks for Approximate Ots loading
  if (isLoading) {
    return (
      <div className="d-inline ml-5">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <CannotApproximate
        cannotApproximateMediaCount={cannotApproximateMediaCount}
      />
      <CanApproximate
        canApproximateMediaCount={canApproximateMediaCount}
        onClickApproxOts={approximateOts}
      />
    </>
  );
}

export default ApproxMavinMetrics;
