import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { removeTgHeatMapData } from "../../../actions/geo-data/GeoDataActions";
import {
  getTgInfo,
  removeSelectedTgId,
} from "../../../actions/org/OrgTargetGroupActions";

// Components
import Spinner from "../../../components/spinner/Spinner";

function RemoveTGButton() {
  const dispatch = useDispatch();

  function removeTgHeatMapFn() {
    dispatch(removeTgHeatMapData());
    dispatch(removeSelectedTgId());
  }

  return (
    <button
      className="btn add-button btn-outline-primary shadow-none"
      onClick={removeTgHeatMapFn}
    >
      <span className="font-weight-bold">Remove</span>
    </button>
  );
}

/**
 * Selected TG Section
 */
export default function SelectedTargetGroupSection({ selectedTgId }) {
  const dispatch = useDispatch();

  // Selector
  const tgInfoLoading = useSelector(
    (state) => state.orgTargetGroup.tgInfoLoading
  );

  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  const resultingPoiLayerList =
    useSelector((state) => state.orgTargetGroup.tgInfo.poiTypesMap) || {};

  // to get target group info
  useEffect(() => {
    if (!tgName) {
      dispatch(getTgInfo(selectedTgId));
    }
  });

  // Checks for loading
  if (tgInfoLoading) {
    return (
      <div className="text-center mt-2">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      {/* Selected Tg  */}
      <div className="navbar border-bottom py-3">
        <h4 className="mt-1">{tgName}</h4>
        <RemoveTGButton />
      </div>

      {/* Resulting Poi Layers */}
      <h5 className="mt-3">Resulting POI Layers </h5>
      <ul className="mb-0">
        {Object.values(resultingPoiLayerList).map((eachPoi) => (
          <li key={eachPoi.id} className="p-1">
            <span>{eachPoi.name}</span>
          </li>
        ))}
      </ul>
    </>
  );
}
