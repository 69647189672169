import { CampaignBudget } from "../../action-constants/CampaignBudgetActionConstants";

const initialState = {
  openModal: false,
  processDataObject: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CampaignBudget.OPEN_CAMPAIGN_BUDGET_EXCEED_MODAL: {
      const { campaignMediaBean, setIsSiteAdded, remainingBudget } =
        action.payload;
      return {
        ...state,
        openModal: true,
        processDataObject: {
          campaignMediaBean,
          setIsSiteAdded,
          remainingBudget,
        },
      };
    }

    case CampaignBudget.CLOSE_CAMPAIGN_BUDGET_EXCEED_MODAL:
      return {
        ...state,
        openModal: false,
      };

    default:
      return state;
  }
};
