export const LegendMediaSitesActions = {
  SHOW_MEDIA_MARKERS_FROM_LEGEND: "SHOW_MEDIA_MARKERS_FROM_LEGEND",
  REMOVE_MEDIA_MARKERS_FROM_LEGEND: "REMOVE_MEDIA_MARKERS_FROM_LEGEND",
  RESET_MEDIA_MARKERS: "RESET_MEDIA_MARKERS",
};

export const MediaInfluenceCircleMarkers = {
  // Hide/Show Media Influence Circle Markers
  SHOW_MEDIA_INFLUENCE_CIRCLE: "SHOW_MEDIA_INFLUENCE_CIRCLE",
  REMOVE_MEDIA_INFLUENCE_CIRCLE: "REMOVE_MEDIA_INFLUENCE_CIRCLE",
  SET_MEDIA_INFLUENCE_CIRCLE_RADIUS: "SET_MEDIA_INFLUENCE_CIRCLE_RADIUS",

  // Reset Media influnce
  RESET_MEDIA_INFLUENCE: "RESET_MEDIA_INFLUENCE",
};
