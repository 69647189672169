// Utils and Constants
import { ContentHeading, ContentList, ContentParagraph } from "./HelpPageUtils";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Components
import HelpSidebar from "./HelpSidebar";
import TableHeaders from "../../components/table/TableHeaders";
import PageHeader from "../../mavin/components/page-header/PageHeader";

// page image
import mobilityPercent from "../../assets/imgs/help-page-images/mobility-percent.png";

function CovidChangeMethodologyDescription() {
  const covidChangeContentOne = `COVID19 Lockdown has significantly changed the User mobility (Traffic flow patterns). The current Traffic Movements are
     very different when compared to the Usual (baseline) movements and are quite unpredictable.`;

  const covidChangeContentTwo = [
    "Google has been releasing ",
    <a href="https://www.google.com/covid19/mobility" key={1}>
      COVID-19 Community Mobility Reports
    </a>,
    ", in order to help their API/Data consumers with these changes. These reports are available for various countries and contain state-wise summaries.",
  ];

  const covidChangeContentThree = `Our aim is to utilise these reports to update the current OTS estimates algorithm so that the estimates are susceptible
     to these changes and are up to date.`;

  return (
    <>
      <ContentParagraph paragraph={covidChangeContentOne} />
      <ContentParagraph paragraph={covidChangeContentTwo} />
      <ContentParagraph paragraph={covidChangeContentThree} />
    </>
  );
}

function GoogleMobilityReport() {
  const mobilityReportContent = `The mobility report has charted the movement trends in various categories of places such as
      retail and recreation, groceries and pharmacies, parks, transit stations, workplaces, and residential.`;

  const mobilityReportListTitle = `Find below the list of place categories and details of their compositions :`;

  const mobilityReportList = [
    {
      content: `Grocery & pharmacy - Mobility trends for places like grocery markets, food warehouses, farmers markets, specialty
      food shops, drug stores, and pharmacies.`,
    },
    {
      content: `Parks - Mobility trends for places like local parks, national parks, public beaches, marinas, dog parks, plazas, 
      and public gardens.`,
    },
    {
      content: `Transit stations - Mobility trends for places like public transport hubs such as subway, bus, and train stations.`,
    },
    {
      content: `Retail &amp; recreation - Mobility trends for places like restaurants, cafes, shopping centers, theme parks, museums,
      libraries, and movie theaters.`,
    },
    {
      content: `Residential - Mobility trends for places of residence.`,
    },
    {
      content: `Workplaces - Mobility trends for places of work.`,
    },
  ];

  const mobilityReportListFooter = `The dataset shows the change compared to a baseline value for that day of the week. The baseline is
        the median value for corresponding day of the week, during the 5-week period [Jan 3 - Feb 6].`;

  return (
    <>
      <ContentHeading heading="About Google Mobility Report" />
      <ContentParagraph paragraph={mobilityReportContent} />
      <ContentList
        list={mobilityReportList}
        heading={mobilityReportListTitle}
      />
      <ContentParagraph paragraph={mobilityReportListFooter} />
    </>
  );
}

function MavinOtsAlgorithm() {
  const MavinOtsListTitle = "Quick overview of the the Process";

  const MavinOtsList = [
    {
      content: `Similar to Google’s Baseline definition, baseline OTS is measured using the pre-covid lockdown traffic information.`,
    },
    {
      content: `As the traffic movement is predominantly contributed by the people visiting various places (POIs), the change percentage
      in the traffic is determined based on the mobility changes in the nearby POIs of a given location - (Google Mobility report provides
      the mobility changes in Places).`,
    },
    {
      content: `This change_percentage is then applied on the Baseline OTS (from step 1) to come up with the present day OTS estimate.`,
    },
  ];

  return (
    <>
      <ContentHeading heading="MAVIN OTS Algorithm/Methodology (for COVID19)" />
      <ContentList list={MavinOtsList} heading={MavinOtsListTitle} />
    </>
  );
}

function MethodologyEstimate() {
  const methodologyEstimateConstant = `A perimeter of radius 1000 meters around the road’s coordinates is used to understand the locality by
     analysing the Points of Interest (POI) present in it.`;

  const methodologyListTitle = `The current MAVIN classification of POIs is as follows:`;

  const methodologyList = [
    { content: "Sustenance" },
    { content: "Education" },
    { content: "Transportation" },
    { content: "Entertainment" },
    { content: "Residential" },
    { content: "Commercial" },
    { content: "Industrial" },
  ];

  const methodologyFooterText = `These MAVIN POI Classification is mapped with Google&#39;s place categories (from the Mobility Report), to map 
     the classification wise change percentage. As a given location contains various kinds of POIs, we estimate the change_pecentage calculating 
     the weighted average of the change percentage of these respective categories. An example calculation is provided in the example section.`;

  return (
    <>
      <ContentHeading heading="Methodology used to estimate the the change_percentage" />
      <ContentParagraph paragraph={methodologyEstimateConstant} />
      <ContentList list={methodologyList} heading={methodologyListTitle} />
      <ContentParagraph paragraph={methodologyFooterText} />
    </>
  );
}

function ContentImage() {
  return (
    <>
      <p>Image below shows the day wise change_percentage of OTS.</p>
      <img
        className="mb-2"
        src={mobilityPercent}
        alt="mobility change percent"
      ></img>
    </>
  );
}

function KeyNotes() {
  const keyNotesListOne = [
    {
      content: `The change percentages of Google Report are not Traffic Change percentages. They are the avg. change in people observed in a given
      Point of Interest.`,
    },
    {
      content: `Even though the residential areas have a +ve change in mobility, the overall traffic change can still be -ve in residential areas due
       the drop in the other Points of Interest (Shops, restaurants, etc..) in that locality.`,
    },
    {
      content: `As the Google report provides the day wise change_percentages since Feb 15th 2020, the OTS can also be measured day wise since Feb 15th
       till date.`,
    },
  ];

  const keyNotesListTwo = [
    {
      content: `Google mobility reports are released with a delay of 4 days to one week. Hence the OTS can be measured for any day up until a week before
       from today.`,
    },
  ];
  return (
    <>
      <ContentHeading heading="Key Notes / Observations" />
      <ContentList list={keyNotesListOne} />
      <ContentImage />
      <ContentList list={keyNotesListTwo} />
    </>
  );
}

function OTSCalculationCOVID19Times() {
  const location = "Wadern Road St.Stephen Church, South Mumbai";
  const latLong = "18.964521, 72.804455";
  const mediaAssumed = "Media is assumed to be - “LIT”";

  const googleMobilityReportListTitle = `For this Locality, the change percentages as per Google Mobility Report on - 14th June`;

  const googleMobilityReportList = [
    { content: "Grocery &amp; pharmacy : -25.0%" },
    { content: "Retail &amp; recreation : - 72.0%" },
    { content: "Parks : - 70.0%" },
    { content: "Transit stations : - 52.0%" },
    { content: "Residential : 16.0%" },
    { content: "Workplaces : -24.0%" },
  ];

  const mavinPoiDistributionListTitle =
    "MAVIN POI distribution (share of various POIs) within 1000mts radius for this Locality";

  const mavinPoiDistributionList = [
    { content: "Commercial : 9.0%" },
    { content: "Education : 1.0%" },
    { content: "Residential : 15.0%" },
    { content: "Sustenance : 65.0%" },
    { content: "Residential : 16.0%" },
    { content: "Transportation : 8.0%" },
  ];

  const keyNotesContentOne = `Post the Mapping of mobility changes to MAVIN classification, the traffic change_percent calculated using the
      Weighted Average method : -52.19 %`;

  const keyNotesContentTwo = `As the OTS changes daily and can be estimated for any date between Mar 15th till date, find below the OTS data 
      for several dates..`;

  return (
    <>
      <ContentHeading heading="Example OTS Calculation for COVID19 times" />
      <ContentParagraph paragraph={location} className="mb-0" />
      <ContentParagraph paragraph={latLong} className="mb-0" />
      <ContentParagraph paragraph={mediaAssumed} />
      <ContentList
        list={googleMobilityReportList}
        heading={googleMobilityReportListTitle}
      />
      <ContentList
        list={mavinPoiDistributionList}
        heading={mavinPoiDistributionListTitle}
      />
      <ContentParagraph paragraph={keyNotesContentOne} />
      <ContentParagraph paragraph={keyNotesContentTwo} />
    </>
  );
}

function OTSCalculationTable() {
  const covidChangeTableTitle = [
    {
      title: {
        displayName: "Date",
        className: "col-2 align-text-top",
      },
    },
    {
      title: {
        displayName: "Change Percentage",
        className: "col-2 align-text-top",
      },
    },
    {
      title: {
        displayName: "Ots (Impressions)",
        className: "col-2 align-text-top",
      },
    },
  ];

  const covidChangePageConstants = [
    {
      date: "March 1st (Baseline)",
      percentage: "-1 %",
      ots: "29,38,590",
    },
    {
      date: "March 22nd",
      percentage: "-65.2 %",
      ots: "10,22,630",
    },
    {
      date: "April 22nd",
      percentage: "-63.5 %",
      ots: "10,72,586",
    },
    {
      date: "May 22nd",
      percentage: "-57.9 %",
      ots: "12,37,147",
    },
    {
      date: "June 22nd",
      percentage: "-47.1 %",
      ots: "15,54,515",
    },
    {
      date: "July 22nd",
      percentage: "-51.7 %",
      ots: "14,19,339",
    },
  ];
  return (
    <div>
      <table className="table table-bordered col-10">
        <TableHeaders
          tableHeaders={covidChangeTableTitle}
          headerClass={"thead"}
          requiredSubHeader={false}
        />
        <tbody>
          {covidChangePageConstants.map((info, i) => (
            <tr key={i}>
              <td>{info.date}</td>
              <td>{info.percentage}</td>
              <td>{info.ots}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

/**
 * Covid Change Help Page
 */
export default function CovidChangeHelpPage() {
  const pageTitle = "Covid Percentage Change Methodologies";
  const pageAction = <h5 className="bg-warning px-5 py-2">Not in Use</h5>;

  return (
    <>
      <HelpSidebar activePageUrl={RedirectTo.helpCovidChangePageUrl} />

      <div className="content-wrapper">
        <PageHeader title={pageTitle} shadow={true} actions={pageAction} />

        <div className="page-content">
          <CovidChangeMethodologyDescription />
          <GoogleMobilityReport />
          <MavinOtsAlgorithm />
          <MethodologyEstimate />
          <KeyNotes />
          <OTSCalculationCOVID19Times />
          <OTSCalculationTable />
        </div>
      </div>
    </>
  );
}
