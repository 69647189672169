import { RoadStretch } from "../../../constants/action-constants/RoadStretchActionConstants";

export const selectRoadStretch = (roadStretch) => ({
  type: RoadStretch.SELECT_ROAD_STRETCH,
  payload: { roadStretch },
});

export const selectRoadStretchs = (roadStretchs) => ({
  type: RoadStretch.SELECT_ROAD_STRETCHS,
  payload: { roadStretchs },
});

export const unselectRoadStretch = (roadStretch) => ({
  type: RoadStretch.UN_SELECT_ROAD_STRETCH,
  payload: { roadStretch },
});

export const resetRoadStretches = () => ({
  type: RoadStretch.RESET_ROAD_STRETCHES,
});
