import { all, put, takeLatest } from "redux-saga/effects";

// Api
import { getSingleSiteAnalyserInfoFn } from "../../apis/mavin-tools/SingleSiteAnalyserToolAPI";

// Constants & Utils
import { SingleSiteAnalyser } from "../../constants/action-constants/mavin-tools/SingleSiteAnalyserActionConstants";
import { getErrorMessage } from "../../utils/util";

function* getSingleSiteAnalyserInfo(action) {
  try {
    const { mediaInfoBean } = action.payload;

    // Api call
    const singleSiteInfo = yield getSingleSiteAnalyserInfoFn(mediaInfoBean);

    yield put({
      type: SingleSiteAnalyser.GET_SINGLE_SITE_ANALYSER_INFO_SUCCESS,
      payload: { singleSiteAnalyserInfo: singleSiteInfo, mediaInfoBean },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: SingleSiteAnalyser.GET_SINGLE_SITE_ANALYSER_INFO_FAILURE,
      payload: errorMessage,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      SingleSiteAnalyser.GET_SINGLE_SITE_ANALYSER_INFO,
      getSingleSiteAnalyserInfo
    ),
  ]);
}
