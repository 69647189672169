/**
 * No Table Data Message
 */
export default function NoTableDataMessage({
  colSpan = 6,
  message = "No Data Present",
  className = "",
}) {
  return (
    <tr>
      <td
        colSpan={colSpan}
        className={`text-center p-5 font-italic ${className}`}
      >
        {message}
      </td>
    </tr>
  );
}
