import { DataLayer } from "../../constants/action-constants/DataLayerActionConstants";

export const selectDataLayer = (dataLayerId) => ({
  type: DataLayer.DATA_LAYER_SELECT,
  payload: { id: dataLayerId },
});

export const selectMultipleDataLayers = (dataLayerIds) => ({
  type: DataLayer.DATA_LAYER_SELECT_MULTIPLE,
  payload: { ids: dataLayerIds },
});

export const unselectDataLayer = (dataLayerId) => ({
  type: DataLayer.DATA_LAYER_UNSELECT,
  payload: { id: dataLayerId },
});

export const resetDataLayerInfo = () => ({
  type: DataLayer.RESET,
});
