import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Actions
import { getCampaignPlan } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { getRoadStretchesOfOrg } from "../../../actions/org/OrgRoadStretchActions";
import { mediaSetupMonitoring } from "../../actions/campaign-confirmation/CampaignConfirmationActions";

// Utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";

// Components
import MediaDetailsTable from "../components/MediaDetailsTable";
import MapView from "../components/map-view/MapView";
import CityViewCards from "../components/CityViewCards";
import { HeaderSection } from "./HeaderSection";
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import MediaSetupMonitoringForm from "../../../components/media-setup-monitoring-form/MediaSetupMonitoringForm";
import { EstimatedPriceBreakDown } from "../components/EstimatedPriceBreakDown";

/**
 * Page
 */
function CampaignConfirmationPage() {
  const { campaignId, cityId } = useParams();

  // Get campaignPlan
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);
  const { targetGroupId } = campaignPlan;

  // tg-name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Campaign Media
  const campaignMedia = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  // for Setup-Monitoring Popup
  const openSetupMonitoringForm = useSelector(
    (state) => state.monitoringSettings.openModal
  );
  // monitoring mediaId
  const monitoringMediaId = useSelector(
    (state) => state.monitoringSettings.monitoringMediaId
  );
  // Monitoring Media Dates
  const monitoringMediaDateObj = useSelector(
    (state) => state.monitoringSettings.monitoringMediaDateObj
  );

  // City road stretches
  const roadStretchMap = useSelector(
    (state) => state.orgRoadStretch.orgRoadStretches
  );

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCampaignPlan(campaignId, cityId));
    dispatch(getBasicCampaignDetails(campaignId));
    dispatch(getRoadStretchesOfOrg(false, cityId, "", 1, 1000));
  }, [dispatch, campaignId, cityId]);

  useGetTgInfo(targetGroupId);

  const EnableMediaSetupMonitoringForm = isAgencySpecific(
    MediaSetupMonitoringForm
  );

  const redirectLinkObj = {
    campaignOverviewLink: constructRedirectPath(
      `/campaign/${campaignId}/confirm`
    ),
    citySummaryLink: constructRedirectPath(
      `/campaign/${campaignId}/city/:cityId/confirm`
    ),
  };

  const citySelected = { cityId };

  // Function
  // Create Monitoring Settings Function
  function onSubmitMonitoringSettings(inputFieldBean) {
    dispatch(
      mediaSetupMonitoring(inputFieldBean, campaignId, cityId, [
        monitoringMediaId,
      ])
    );
  }

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar
        campaign={campaign}
        citySelected={citySelected}
        redirectLinkObj={redirectLinkObj}
      />

      <div className="content-wrapper">
        <HeaderSection campaign={campaign} />

        <div className="page-content">
          {/* city view Cards */}
          <CityViewCards
            tgName={tgName}
            campaign={campaign}
            cityPlan={campaignPlan}
            campaignMedia={campaignMedia}
          />

          {/* Estimated Price Breakdown Table */}
          <EstimatedPriceBreakDown
            tgName={tgName}
            campaign={campaign}
            campaignPlan={campaignPlan}
            submissionImpact={false}
          />

          {/* Map view */}
          <MapView
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchMap}
          />

          {/* Media Sites Table */}
          <MediaDetailsTable
            campaignId={campaignId}
            campaign={campaign}
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchMap}
          />
        </div>
      </div>

      {/* modals */}
      {openSetupMonitoringForm && EnableMediaSetupMonitoringForm && (
        <EnableMediaSetupMonitoringForm
          onFormSubmit={onSubmitMonitoringSettings}
          defaultDateRange={monitoringMediaDateObj}
        />
      )}
    </>
  );
}

export default CampaignConfirmationPage;
