import {
  LegendMediaSitesActions,
  MediaInfluenceCircleMarkers,
} from "../../constants/action-constants/map-view/media-sites/LegendAndInfluenceActionConstants";

//intital state
const initialState = {
  mediaTypeToShow: {
    campaignMedia: true,
    inventoryMedia: true,
  },

  // toggle Media influence circle markers
  showMediaInfluenceCircle: false,
  mediaInfluenceCircleRadius: 500,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LegendMediaSitesActions.SHOW_MEDIA_MARKERS_FROM_LEGEND:
      return {
        ...state,
        mediaTypeToShow: {
          ...state.mediaTypeToShow,
          [action.payload.id]: true,
        },
      };

    case LegendMediaSitesActions.REMOVE_MEDIA_MARKERS_FROM_LEGEND:
      return {
        ...state,
        mediaTypeToShow: {
          ...state.mediaTypeToShow,
          [action.payload.id]: false,
        },
      };

    case LegendMediaSitesActions.RESET_MEDIA_MARKERS:
      return {
        ...state,
        mediaTypeToShow: {
          campaignMedia: true,
          inventoryMedia: true,
        },
      };

    // Toggle Media Influence Circle Markers
    case MediaInfluenceCircleMarkers.SHOW_MEDIA_INFLUENCE_CIRCLE:
      return {
        ...state,
        showMediaInfluenceCircle: true,
      };

    case MediaInfluenceCircleMarkers.REMOVE_MEDIA_INFLUENCE_CIRCLE:
      return {
        ...state,
        showMediaInfluenceCircle: false,
      };

    case MediaInfluenceCircleMarkers.SET_MEDIA_INFLUENCE_CIRCLE_RADIUS:
      return {
        ...state,
        mediaInfluenceCircleRadius: action.payload.radius,
      };

    case MediaInfluenceCircleMarkers.RESET_MEDIA_INFLUENCE:
      return {
        ...state,
        showMediaInfluenceCircle: false,
        mediaInfluenceCircleRadius: 500,
      };

    default:
      return state;
  }
};
