//Components
import DashboardCard from "../../../components/dashboard-card/DashboardCard";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Page Constants
const cardsInfo = [
  {
    title: "View Earnings",
    content:
      "Manage your campaign end to end from creation, analysis and monitoring.",
    CTALabel: "View Earnings",
    CTALink: "/seller/earnings",
  },
  {
    title: "Site Report",
    content:
      "Manage your campaign end to end from creation, analysis and monitoring.",
    CTALabel: "Create a Campaign",
    CTALink: "/",
  },
];

// Page Components
function HomeCardsSection() {
  return (
    <div className="row">
      {cardsInfo.map((card, i) => (
        <DashboardCard key={i} card={card} i={i} />
      ))}
    </div>
  );
}

const pageActions = (
  <>
    <p className="pt-2">Version : {process.env.REACT_APP_VERSION}</p>
    {/* <p>Last updated Thursday, March 20, 2021, 10:45 PM</p> */}
  </>
);

/**
 * Page
 */
export default function SellerHomePage() {
  return (
    <div className="content-wrapper bg-alt min-vh-100">
      {/* Page Header */}
      <PageHeader
        title={"Dashboard"}
        border={false}
        shadow={false}
        actions={pageActions}
      />

      <div className="page-content">
        {/* Dashboard Card */}
        <HomeCardsSection />
      </div>
    </div>
  );
}
