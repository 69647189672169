import { PlatformUpdatesForm } from "../constants/action-constants/PlatformUpdatesFormActionConstants";

const initialState = {
  openModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PlatformUpdatesForm.OPEN_PLATFORM_UPDATES_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case PlatformUpdatesForm.CLOSE_PLATFORM_UPDATES_MODAL:
      return {
        ...state,
        openModal: false,
      };

    default:
      return state;
  }
};
