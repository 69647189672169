import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Actions
import { getCampaignPlan } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { getRoadStretchesOfOrg } from "../../../actions/org/OrgRoadStretchActions";
import { getBuyersList } from "../../../actions/buyer/BuyerActions";

//utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";
import { constructDateString } from "../../../common-utils/date-utils/DateUtils";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";
import { PreviewData } from "../media-selection/MediaSelectionPageConstants";

// Components
import MediaDetailsTable from "../components/MediaDetailsTable";
import MapView from "../components/map-view/MapView";
import CityViewCards from "../components/CityViewCards";
import { HeaderSection } from "./HeaderSection";
import CampaignConfirmationForm from "./CampaignConfirmationForm";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import { EstimatedPriceBreakDown } from "../components/EstimatedPriceBreakDown";
import Chips from "../../../components/Chips/Chips";

// TODO:will remove after api integration
const campaignMediaData = [
  {
    title: "Stretches",
    value: 5,
  },
  {
    title: "Sites",
    value: 30,
  },
  {
    title: "Static",
    value: 5,
  },
  {
    title: "DOOH",
    value: 5,
  },
];

const otsAndCostData = [
  {
    title: "Total Imp",
    value: 11111111,
  },
  {
    title: "TG Imp",
    value: 100000,
  },
  {
    title: "Total Cost",
    value: 1111111,
  },
  {
    title: "Avg CPI",
    value: 11111,
  },
];

function CityOverview({ campaign, cityPlan }) {
  const { infoByCity = {} } = campaign;
  const { cityId, startTimestamp, endTimestamp } = cityPlan;
  const { cityName } = infoByCity[cityId] || "";
  const dateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-6">
          <h5>Campaign overview</h5>
          <div className="row">
            <div className="col-5">
              <div className="row justify-content-between border p-3 overview-container mr-2">
                {campaignMediaData.map(({ title, value }) => (
                  <div key={title}>
                    <h4>{title}</h4>
                    <h3>{value}</h3>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-7">
              <div className="row justify-content-between border p-3 overview-container ">
                {otsAndCostData.map(({ title, value }) => (
                  <div className="mr-2" key={title}>
                    <h4>{title}</h4>
                    <h3>{value}</h3>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-xl-6 mt-3 mt-xl-0 px-0 px-xl-3">
          <div className="d-flex justify-content-between align-items-center">
            <h5>City: {cityName}</h5>
            <h5>{dateString}</h5>
          </div>
          <div className="border p-3 overview-container overflow-x-scroll">
            <div className="d-flex">
              {PreviewData.map(({ title, list }) => (
                <div className="col chips-container" key={title}>
                  <Chips
                    title={title}
                    selectedItems={list}
                    headerClassName="sticky-top"
                    containerClassName="scrollable-container flex-column"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/**
 * Page
 */
function CampaignReviewPage() {
  const { campaignId, cityId } = useParams();

  // Get campaignPlan
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);

  const { targetGroupId } = campaignPlan;

  // tg-info
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Campaign Media
  const campaignMedia = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  // Campaign confirm popup
  const openCampaignConfirmModal = useSelector(
    (state) => state.campaignConfirmModal.openModal
  );

  // City road stretches
  const roadStretchMap = useSelector(
    (state) => state.orgRoadStretch.orgRoadStretches
  );

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBuyersList());
    dispatch(getCampaignPlan(campaignId, cityId));
    dispatch(getBasicCampaignDetails(campaignId));
    dispatch(getRoadStretchesOfOrg(false, cityId, "", 1, 1000));
  }, [dispatch, campaignId, cityId]);

  useGetTgInfo(targetGroupId);

  const redirectLinkObj = {
    campaignOverviewLink: constructRedirectPath(
      `/campaign/${campaignId}/review`
    ),
    citySummaryLink: constructRedirectPath(
      `/campaign/${campaignId}/city/:cityId/review`
    ),
  };

  const citySelected = { cityId };

  return (
    <>
      {/* <CampaignSidebar
        campaign={campaign}
        citySelected={citySelected}
        redirectLinkObj={redirectLinkObj}
      /> */}

      <div className="content-wrapper">
        <HeaderSection campaign={campaign} cityId={cityId} />

        <div className="page-content">
          {/* <CityViewCards
            tgName={tgName}
            campaign={campaign}
            cityPlan={campaignPlan}
            campaignMedia={campaignMedia}
          /> 
          <EstimatedPriceBreakDown
            tgName={tgName}
            campaign={campaign}
            campaignPlan={campaignPlan}
            submissionImpact={true}
          /> */}
          <CityOverview campaign={campaign} cityPlan={campaignPlan} />
          {/* Map View */}
          <MapView
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchMap}
          />

          {/* Media Sites Table */}
          <MediaDetailsTable
            campaignId={campaignId}
            campaign={campaign}
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchMap}
          />
        </div>
      </div>

      {/* MODALS */}
      {openCampaignConfirmModal && <CampaignConfirmationForm />}
    </>
  );
}

export default CampaignReviewPage;
