import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  addInfluenceCircleMarkers,
  removeInfluenceCircleMarkers,
  setInfluenceCircleRadius,
} from "../../../actions/map-view/poi/PoiSelectionActions";

// Utils and Constants
import {
  RadiusConstants,
  Status,
  UrlKeys,
} from "../../../constants/GeneralConstants";
import { CheckBox, Collapse, CollapseIcon } from "./MapControlsUtils";
import { useUrlKeyCheck } from "../../../mavin/utils/hooks/HooksUtil";

// Components
import CardCollapsible from "../../../components/card-collapsible/CardCollapsible";

// Control Panel Components
function CollapseCheckList({ data, index }) {
  const [rightArrow, setRightArrow] = useState(true);
  const dataType = data.type;
  function changeCollapseIcon() {
    rightArrow ? setRightArrow(false) : setRightArrow(true);
  }
  return (
    <div className="panel-group" id={`mapSelection_accordion_${index}`}>
      <div className="panel panel-default mb-2">
        <div className="panel-heading">
          <i
            className={`collapse__icon fa fa-angle-${
              rightArrow ? "right" : "down"
            }`}
            data-toggle="collapse"
            data-parent={`#mapSelection_accordion_${index}`}
            href={`#mapSelection_collapse_${index}`}
            onClick={changeCollapseIcon}
          ></i>
          <span className="collapse__display">{dataType}</span>
        </div>
        <div
          id={`mapSelection_collapse_${index}`}
          className="panel-collapse collapse in"
        >
          {data.children.map((eachData) => (
            <CheckBox
              key={eachData.label}
              boxStyle={"media__metaData--checkBox"}
              checkBoxKey={eachData.label}
              disabled={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function Checklist({ dataList }) {
  if (!dataList || !dataList.length) {
    return <span>0 poi selected</span>;
  }

  return dataList.map((data, index) => {
    const dataType = data.type;
    if (dataType) {
      return <CollapseCheckList key={index} index={index} data={data} />;
    }
    return (
      <CheckBox
        key={data.label}
        boxStyle={"media__metaData--checkBox"}
        checkBoxKey={data.label}
        disabled={true}
      />
    );
  });
}

function Poi() {
  function getCheckedChildren(data) {
    const newChildData = [];
    for (const eachChild of data.children) {
      if (eachChild.status === Status.CHECKED) {
        newChildData.push({ ...eachChild });
      }
    }
    return newChildData;
  }
  function getCheckedData(data) {
    if (data.status === Status.CHECKED) {
      return data;
    }
    if (data.status === Status.PARTIALLY_CHECKED) {
      data.children = getCheckedChildren(data);
      return data;
    }
    return {};
  }

  const dataList = useSelector((state) =>
    state.poi.dataList.reduce((acc, data) => {
      const tempData = { ...data };
      if (data.type) {
        const checkedChildren = data.children.reduce((acc, eachChild) => {
          const checkedData = getCheckedData(eachChild);
          if (Object.keys(checkedData).length > 0) {
            acc.push(checkedData);
          }
          return acc;
        }, []);
        if (checkedChildren.length > 0) {
          tempData.children = checkedChildren;
          acc.push(tempData);
        }
        return acc;
      }
      const checkedData = getCheckedData(tempData);
      if (Object.keys(checkedData).length > 0) {
        acc.push(checkedData);
      }
      return acc;
    }, [])
  );

  const displayDetails = () => {
    return (
      <div className="d-flex justify-content-between align-items-baseline pr-2">
        <p>Points of interest</p>
        <CollapseIcon dataParent="#poi" href="#collapse_poi" />
      </div>
    );
  };

  const data = () => {
    return <Checklist dataList={dataList} />;
  };
  return (
    <Collapse
      id="poi"
      href="collapse_poi"
      displayDetails={displayDetails}
      data={data}
    />
  );
}

// Controls :: POI
function PoiControlsPanel({ settings = {}, count = 0 }) {
  const dispatch = useDispatch();
  const influenceCircleRadius = useSelector(
    (state) => state.poiSelection.influenceCircleRadius
  );

  // to SHow/Hide Influence Circle
  const showInfluenceCircle = settings.circle;

  // Function
  function toggleInfluenceCircleMarkers() {
    const action = showInfluenceCircle
      ? removeInfluenceCircleMarkers
      : addInfluenceCircleMarkers;
    dispatch(action());
  }

  const { campaignPlanning, cityAnalyser } = UrlKeys;
  // plan and city-analyser key will not present in campaign analyser page url
  // so this will return true for campaign planner and city analyser page
  // if the plan is present in url then its is from campaign planning page
  const isDisabled = useUrlKeyCheck(campaignPlanning, cityAnalyser);

  return (
    <CardCollapsible
      id="poi-controls"
      header={"Points of Interest Settings"}
      subHeader={`Showing ${count} POI Markers`}
      disabled={!isDisabled}
    >
      <div className="sec">
        <CheckBox
          id={"chk-poi-cirle"}
          name={"influenceCircle"}
          labelText={"Show POI Influence Circle"}
          checked={showInfluenceCircle}
          onChange={toggleInfluenceCircleMarkers}
        />
        <div className="form-group">
          <label htmlFor="select-poi-circle">{"Radius of Influence"}</label>
          <select
            id="select-poi-circle"
            value={influenceCircleRadius}
            className="form-control shadow-none rounded-lg"
            onChange={(e) => dispatch(setInfluenceCircleRadius(e.target.value))}
          >
            {RadiusConstants.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </CardCollapsible>
  );
}

export default PoiControlsPanel;
