import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getSecData,
  removeSelectedSec,
  selectSec,
} from "../../../actions/map-view/SecActions";

// Utils
import { getMergedCityBBox } from "../CityInfoTempFixUtil";

// Components
import Spinner from "../../../components/spinner/Spinner";

// Section Components
function SecSelectedList({ sec, cityId, bbox }) {
  const dispatch = useDispatch();

  // Selector
  const isSelectedSec = useSelector((state) => state.sec.selectedSec)[sec];
  const secLoading = useSelector((state) => state.sec.secLoading)[sec];

  const secLabel = sec.split("_")[1];

  // TODO: For temporary fix of cityIds for Delhi and mumbai we are hardcoding the
  // their Ids and BBOX
  function onClick() {
    if (!isSelectedSec) {
      const mergedCityBBox = getMergedCityBBox(cityId, bbox);
      dispatch(selectSec(sec));
      dispatch(getSecData(mergedCityBBox, sec));
      return;
    }

    dispatch(removeSelectedSec(sec));
  }

  const buttonIconClassName = isSelectedSec ? "fa fa-check" : "";

  const buttonClassName = isSelectedSec
    ? "btn-outline-success"
    : "btn-outline-primary";

  const buttonLabel = isSelectedSec ? (
    <>
      <i className={buttonIconClassName} /> Added
    </>
  ) : (
    "Add"
  );

  return (
    <div className="navbar px-0 py-2 border-bottom">
      <label className="form-check-label ml-2" htmlFor={sec}>
        {secLabel}
      </label>
      <div>
        {secLoading && <Spinner className="mr-2 spinner-border-sm" />}
        <button
          className={`btn ${buttonClassName} add-button shadow-none`}
          onClick={onClick}
          disabled={secLoading}
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  );
}

function SecListingSection({ cityId, bbox }) {
  const SEC_DATA_LIST = [
    "SEC_Sec A+",
    "SEC_Sec A",
    "SEC_Sec B+",
    "SEC_Sec B",
    "SEC_Sec C",
  ];

  return (
    <>
      {SEC_DATA_LIST.map((sec) => (
        <SecSelectedList sec={sec} key={sec} cityId={cityId} bbox={bbox} />
      ))}
    </>
  );
}

export default SecListingSection;
