export const ActionTypes = {
  MapState: {
    GET_MAP_STATE: "GET_MAP_STATE_PROOH",
    GET_MAP_STATE_SUCCESS: "GET_MAP_STATE_SUCCESS_PROOH",
    GET_MAP_STATE_FAILURE: "GET_MAP_STATE_FAILURE_PROOH",

    RESET_MAP_STATE: "RESET_MAP_STATE_PROOH",
  },

  MapView: {
    // Selections based on Media Type (Billlboard, Unipole, ..)
    SET_MEDIA_TYPES: "SET_MEDIA_TYPES_PROOH",
    SELECT_MEDIA_TYPE: "SELECT_MEDIA_TYPE_PROOH",
    UNSELECT_MEDIA_TYPE: "UNSELECT_MEDIA_TYPE_PROOH",

    // Selections based on RoadStretches (Inventory, Selected)
    SELECT_ROAD_STRETCH_TYPE: "SELECT_ROAD_STRETCH__TYPE_PROOH",
    UNSELECT_ROAD_STRETCH_TYPE: "UNSELECT_ROAD_STRETCH_TYPE_PROOH",
    SET_ROAD_STRETCHES: "SET_ROAD_STRETCHES_PROOH",

    // Selections based on POI
    SELECT_POI: "SELECT_POI_PROOH",
    UNSELECT_POI: "UNSELECT_POI_PROOH",
    SET_POI_AND_BRANDS: "SET_POI_AND_BRANDS_PROOH",
    SET_POI_AND_BRANDS_SUCCESS: "SET_POI_AND_BRANDS_SUCCESS_PROOH",
    SET_POI_AND_BRANDS_FAILURE: "SET_POI_AND_BRANDS_FAILURE_PROOH",

    // Selections based on TargetGroup
    SET_TG: "SET_TG_PROOH",
    SELECT_TG: "SELECT_TG_PROOH",
    UNSELECT_TG: "UNSELECT_TG_PROOH",
  },
};
