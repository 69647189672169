import { MapViewImage } from "../../constants/action-constants/MapViewImageActionConstants";

//intital state
const initialState = {
  imageUrl: "",
  loading: false,
  err: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MapViewImage.GET_MAP_VIEW_IMAGE:
      return {
        ...state,
        loading: true,
      };

    case MapViewImage.GET_MAP_VIEW_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        imageUrl: action.payload.imageUrl,
      };

    case MapViewImage.GET_MAP_VIEW_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        err: action.payload,
      };

    default:
      return state;
  }
};
