// Constants and Utils
import { RoadSegment } from "../../../constants/action-constants/RoadSegmentActionConstants";
import { updateRoadTrace } from "../../utils/RoadUtil";

const initialState = {
  roadSegmentDetailsMap: {},
  roadSegmentDetails: [],
  roadSegmentMediaInfo: {},
  mediaIds: [],
  roadSegmentMediaLoading: false,
  roadSegmentMediaErr: "",

  // Fetching roadSegment states
  segmentsLoading: false,
  segmentsErr: "",
  segmentsSuccess: false,

  // Below details stores the "roadSegment-selections"
  selectedSegments: {},

  // This variable is useful only while restoring the campaignPlan
  roadSegmentMediaInfoSuccess: false,
};

function removeRoadSegment(roadSegmentId, state) {
  const {
    roadSegmentDetailsMap,
    selectedSegments,
    roadSegmentMediaInfo,
    mediaIds,
  } = state;

  // Remove the segment
  delete roadSegmentDetailsMap[roadSegmentId];

  // Remove the segmentId from the selectedSegments
  delete selectedSegments[roadSegmentId];

  // Remove mediaDetails of the segment
  const mediaDetailsOfSegment = roadSegmentMediaInfo[roadSegmentId];
  const mediaIdsToRemove = mediaDetailsOfSegment.map(
    (eachMedia) => eachMedia.mediaId
  );
  const newMediaIds = mediaIds.filter((el) => !mediaIdsToRemove.includes(el));
  delete roadSegmentMediaInfo[roadSegmentId];

  // Construct nw objects altogether..
  const newRoadSegmentDetailsMap = { ...roadSegmentDetailsMap };
  const newSelectedSegments = { ...selectedSegments };
  const newRoadSegmentMediaInfo = { ...roadSegmentMediaInfo };
  return {
    newRoadSegmentDetailsMap,
    newSelectedSegments,
    newMediaIds,
    newRoadSegmentMediaInfo,
  };
}

function getUpdatedSegments(newRoadSegmentsMap, existingSegments) {
  const updatedRoadSegmentsMap = { ...existingSegments };

  Object.keys(newRoadSegmentsMap).reduce((acc, segmentId) => {
    const updatedSegment = updateRoadTrace(newRoadSegmentsMap[segmentId]);
    acc[segmentId] = updatedSegment;
    return acc;
  }, updatedRoadSegmentsMap);

  return updatedRoadSegmentsMap;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RoadSegment.ADD_ROAD_SEGMENT: {
      const segment = action.payload.roadSegment;
      return {
        ...state,
        roadSegmentDetails: [...state.roadSegmentDetails, segment],
        roadSegmentDetailsMap: {
          ...state.roadSegmentDetailsMap,
          [segment.id]: segment,
        },
        selectedSegments: {
          ...state.selectedSegments,
          [segment.id]: true,
        },
      };
    }

    case RoadSegment.SELECT_ROAD_SEGMENT:
      return {
        ...state,
        selectedSegments: {
          ...state.selectedSegments,
          [action.payload.roadSegmentId]: true,
        },
      };

    case RoadSegment.GET_PLANNING_ROAD_SEGMENTS:
      return {
        ...state,
        segmentsLoading: true,
      };

    case RoadSegment.GET_PLANNING_ROAD_SEGMENTS_SUCCESS:
      return {
        ...state,
        segmentsLoading: false,
        roadSegmentDetailsMap: getUpdatedSegments(
          action.payload.segmentsMap,
          state.roadSegmentDetailsMap
        ),
        segmentsSuccess: true,
      };

    case RoadSegment.GET_PLANNING_ROAD_SEGMENTS_FAILURE:
      return {
        ...state,
        segmentsLoading: false,
        segmentsErr: action.payload,
      };

    case RoadSegment.REMOVE_ROAD_SEGMENT: {
      const roadSegmentId = action.payload.roadSegmentId;
      const {
        newRoadSegmentDetailsMap,
        newSelectedSegments,
        newMediaIds,
        newRoadSegmentMediaInfo,
      } = removeRoadSegment(roadSegmentId, state);
      return {
        ...state,
        selectedSegments: newSelectedSegments,
        roadSegmentDetailsMap: newRoadSegmentDetailsMap,
        mediaIds: newMediaIds,
        roadSegmentMediaInfo: newRoadSegmentMediaInfo,
      };
    }

    case RoadSegment.GET_ROAD_SEGMENT_MEDIA:
      return {
        ...state,
        roadSegmentMediaLoading: true,
      };

    case RoadSegment.GET_ROAD_SEGMENT_MEDIA_SUCCESS: {
      const mediasInfo = action.payload.mediaInfo;

      // getting "segmentMediaIds" from "mediaInfo"
      const mediaIdArrays = Object.values(mediasInfo).map((obj) =>
        Object.values(obj).map((media) => media.mediaId)
      );
      const mediaIds = mediaIdArrays.flat();

      return {
        ...state,
        roadSegmentMediaInfo: { ...state.roadSegmentMediaInfo, ...mediasInfo },
        mediaIds: state.mediaIds.concat(mediaIds),
        roadSegmentMediaLoading: false,
        roadSegmentMediaInfoSuccess: true,
      };
    }

    case RoadSegment.GET_ROAD_SEGMENT_MEDIA_FAILURE:
      return {
        ...state,
        loading: false,
        roadSegmentMediaErr: action.payload,
      };

    case RoadSegment.RESET_ROAD_SEGMENTS:
      return initialState;

    default:
      return state;
  }
};
