import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getAllCampaigns } from "../../../actions/campaign/CampaignActions";

// Constants and Utils
import { PageSize } from "../../../constants/GeneralConstants";

// Components
import CampaignsTable from "../../../components/campaign-list-table/CampaignsTable";
import SearchInput from "../../../components/search-input/SearchInput";

// Page Constants
const TXT_PLACEHOLDER_SEARCH = "Search Campaign";

// Page Components
function HeaderActionSection({ campaigns, onClickSearch, onSearchTextChange }) {
  return (
    <div className="d-flex justify-content-between">
      <h2>My Campaigns ( {`Total: ${campaigns.length}`} )</h2>
      <SearchInput
        placeholder={TXT_PLACEHOLDER_SEARCH}
        onClickSearch={onClickSearch}
        onTextChange={onSearchTextChange}
      />
    </div>
  );
}

/**
 * Page
 */
function CampaignList() {
  const dispatch = useDispatch();

  // State
  const [searchText, setSearchText] = useState("");

  // Selector State
  const campaigns =
    useSelector((state) => state.campaign.campaigns.items) || [];
  const pagination =
    useSelector((state) => state.campaign.campaigns.pagination) || {};
  const isCampaignsPresent = campaigns.length;
  const loading = useSelector((state) => state.campaign.loading);

  const pageNumber = 1;
  const pageSizeCount = PageSize.campaignList;

  // Dispatch
  useEffect(() => {
    dispatch(getAllCampaigns("", pageNumber, pageSizeCount));
  }, [dispatch, pageSizeCount]);

  // Functions
  function onClickSearch() {
    dispatch(getAllCampaigns(searchText, pageNumber, pageSizeCount));
  }
  function onSearchTextChange(e) {
    setSearchText(e.target.value);
  }

  return (
    <div className="content-wrapper">
      <div className="page-content">
        <HeaderActionSection
          campaigns={campaigns}
          onClickSearch={onClickSearch}
          onSearchTextChange={onSearchTextChange}
        />

        <CampaignsTable
          campaigns={campaigns}
          isCampaignsPresent={isCampaignsPresent}
          loading={loading}
          pagination={pagination}
          searchText={searchText}
        />
      </div>
    </div>
  );
}

export default CampaignList;
