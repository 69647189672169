import { ActionTypes } from "../../constants/ActionConstants";

// These below actions are for "Multi-City-Selection" form
export const openMultiCitySelectionForm = () => ({
  type: ActionTypes.CitySelectionForm.OPEN_MULTI_CITY_SELECTION_MODAL,
});

export const closeMultiCitySelectionForm = () => ({
  type: ActionTypes.CitySelectionForm.CLOSE_MULTI_CITY_SELECTION_MODAL,
});
