export const OrgProfile = {
  //get org profile
  GET_ORG_PROFILE: "GET_ORG_PROFILE",
  GET_ORG_PROFILE_SUCCESS: "GET_ORG_PROFILE_SUCCESS",
  GET_ORG_PROFILE_FAILURE: "GET_ORG_PROFILE_FAILURE",

  //update org profile
  UPDATE_ORG_PROFILE: "UPDATE_ORG_PROFILE",
  UPDATE_ORG_PROFILE_SUCCESS: "UPDATE_ORG_PROFILE_SUCCESS",
  UPDATE_ORG_PROFILE_FAILURE: "UPDATE_ORG_PROFILE_FAILURE",

  //update org profile logo
  UPDATE_ORG_PROFILE_LOGO: "UPDATE_ORG_PROFILE_LOGO",
  UPDATE_ORG_PROFILE_LOGO_SUCCESS: "UPDATE_ORG_PROFILE_LOGO_SUCCESS",
  UPDATE_ORG_PROFILE_LOGO_FAILURE: "UPDATE_ORG_PROFILE_LOGO_FAILURE",
};
