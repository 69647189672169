import { ActionTypes } from "../constants/ActionConstants";

// These below actions are for "City-Selection" form
export const openSingleCitySelectionForm = () => ({
  type: ActionTypes.CitySelectionForm.OPEN_SINGLE_CITY_SELECTION_MODAL,
});

export const closeSingleCitySelectionForm = () => ({
  type: ActionTypes.CitySelectionForm.CLOSE_SINGLE_CITY_SELECTION_MODAL,
});
