import { Auth } from "../../constants/action-constants/AuthActionConstants";

export const login = (email, password) => ({
  type: Auth.USER_LOGIN,
  payload: { email, password },
});

export const forgotPassword = (email) => ({
  type: Auth.FORGOT_PASSWORD,
  payload: { email },
});

export const resetPassword = (newPassword, token) => ({
  type: Auth.RESET_PASSWORD,
  payload: { newPassword, token },
});

export const clearForgotPasswordState = () => ({
  type: Auth.CLEAR_FORGOT_PASSWORD_STATE,
});

export const clearLoginInfo = () => ({
  type: Auth.CLEAR_LOGIN_INFO,
});
