import { ActionTypes } from "../../../constants/ActionConstants";

const initialState = {
  campaignConfirmLoading: false,
  errro: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    //update campaign adn save settings
    //-------------------------------------------------------------------------------------
    case ActionTypes.CampaignReview.UPDATE_CAMPAIGN_AND_SAVE_SETTINGS:
      return {
        ...state,
        campaignConfirmLoading: true,
      };

    case ActionTypes.CampaignReview.UPDATE_CAMPAIGN_AND_SAVE_SETTINGS_SUCCESS:
      return {
        ...state,
        campaignConfirmLoading: false,
      };

    case ActionTypes.CampaignReview.UPDATE_CAMPAIGN_AND_SAVE_SETTINGS_FAILURE:
      return {
        ...state,
        campaignConfirmLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
