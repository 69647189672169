import { Marker, Popup } from "react-leaflet";

// Constants and Utils
import { getMediaIcon } from "../../../utils/MediaUtils";
import { MapZoom, MediaColor } from "../../../constants/GeneralConstants";

//Components
import LLMap from "../../../components/map/leaflet-map/LLMap";
import ImageUpload from "../../../mavin/components/image-upload/ImageUpload";

function Map({ media }) {
  const zoom = MapZoom.zoomLevel13;
  const position = [media.latitude, media.longitude];
  const campaignMediaIcon = getMediaIcon(MediaColor.campaign);

  return (
    <LLMap tile="transport" center={position} zoom={zoom}>
      {/* Markers */}
      <Marker position={position} icon={campaignMediaIcon}>
        <Popup>
          <b>{media.title}</b>
        </Popup>
      </Marker>
    </LLMap>
  );
}

function MediaImageWithMap({ media, fileItems, loading, onClick }) {
  return (
    <div className="py-4">
      <h3>Map View with Media Images</h3>
      <div className="card col-12">
        <div className="map row">
          <div className="col px-0">
            {/* Image uploader */}
            <ImageUpload
              fileItems={fileItems}
              isLoading={loading}
              selectAndUpload={onClick}
              canDownloadImage={false}
            />
          </div>
          <div className="col px-0">
            {/* Media Map View */}
            <Map media={media} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaImageWithMap;
