// Constants and Utils
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import { getLtsRatingAvg } from "../../../components/campaign-table-row/CampaignRowCells";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "City",
      className: "pl-4",
    },
  },
  {
    title: {
      displayName: "Sites Shortlisted",
      className: "text-center",
    },
  },
  {
    title: {
      displayName: "Avg. LTS",
      className: "text-center",
    },
  },
  {
    title: {
      displayName: "City Population",
      className: "text-center",
    },
  },
  {
    title: {
      displayName: "Reach (%)",
      className: "text-center",
    },
  },
  {
    title: {
      displayName: "Frequency",
      className: "text-center",
    },
  },
  {
    title: {
      displayName: "Cost Per Thousand",
      className: "text-center",
    },
  },
  {
    title: {
      displayName: "Total Area (sq ft)",
      className: "text-center",
    },
  },
  {
    title: {
      displayName: "Budget",
      className: "text-center",
    },
  },
  {
    title: {
      displayName: "Spent",
      className: "text-center",
    },
  },
];

// get reachPercent
function getReachPercent(reachFrequency = {}) {
  const { reachPercent } = reachFrequency;

  if (reachPercent === 0) {
    return reachPercent;
  }

  if (!reachPercent) {
    return formatText(reachPercent);
  }

  return reachPercent.toFixed(2);
}

/**
 * Campaign Overview Table
 */
function CampaignOverviewTable({ infoByCity = {} }) {
  return (
    <div className="pt-4 pb-5">
      <h4 className="font-weight-bold pr-2 mb-3">Campaign Overview</h4>

      {/* Table */}
      <div className="table-responsive">
        <table className="table">
          {/* Table- Header */}
          <TableHeaders requiredSubHeader={false} tableHeaders={tableHeaders} />

          <tbody>
            {Object.values(infoByCity).map((cityInfo) => {
              const {
                cityName,
                cityId,
                mediaCount,
                ltsRatingAvg,
                population,
                reachFrequency = {},
                costPerThousand,
                totalArea,
                budget,
                spend,
              } = cityInfo;

              const ltsAvgRating = getLtsRatingAvg(ltsRatingAvg);
              const { frequency } = reachFrequency;
              const reachPercent = getReachPercent(reachFrequency);
              const formattedFrequency = frequency
                ? toLocaleString(frequency)
                : formatText(frequency);
              const totalBudget = budget ? toLocaleString(budget) : 0;
              const totalSpend = spend ? toLocaleString(spend) : 0;
              const formattedTotalArea =
                totalArea === 0
                  ? totalArea
                  : formatText(toLocaleString(totalArea));
              const formattedCostPerThousand =
                costPerThousand === 0
                  ? costPerThousand
                  : formatText(toLocaleString(costPerThousand));

              return (
                <tr key={cityId}>
                  <td className="pl-4 text-primary">{cityName}</td>
                  <td className="text-center">{toLocaleString(mediaCount)}</td>
                  <td className="text-center">{ltsAvgRating}</td>
                  <td className="text-center">
                    {formatText(toLocaleString(population))}
                  </td>
                  <td className="text-center">{reachPercent}</td>
                  <td className="text-center">{formattedFrequency}</td>
                  <td className="text-center">{formattedCostPerThousand}</td>
                  <td className="text-center">{formattedTotalArea}</td>
                  <td className="text-center">{totalBudget}</td>
                  <td className="text-center">{totalSpend}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CampaignOverviewTable;
