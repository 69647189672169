import Spinner from "../../../components/spinner/Spinner";

function SegmentLoading() {
  return (
    <div className="d-flex justify-content-center mx-3">
      <Spinner className="m-1" />
    </div>
  );
}

/**
 *
 * @param {*} allSegmentsTgOts
 * @returns combined sumObj of all segments tg ots
 */
function getTotalOfSegmentsTgOts(allSegmentsTgOts) {
  const arrayOfTgOts = Object.values(allSegmentsTgOts);

  // this function give sum of ots
  function combinedSum(arrayOfTgOts, value) {
    return arrayOfTgOts.reduce((sum, current) => sum + current[value], 0);
  }

  return {
    genericOts: combinedSum(arrayOfTgOts, "genericOts"),
    genericOtsLit: combinedSum(arrayOfTgOts, "genericOtsLit"),
    targetOts: combinedSum(arrayOfTgOts, "targetOts"),
    targetOtsLit: combinedSum(arrayOfTgOts, "targetOtsLit")
  };
}

/**
 *
 * @param {*} lit- LitStatus
 * @param {*} data - ots data
 * @returns Total Est Impression
 */
function getTotalEstImpressions(lit, data) {
  const result = data.reduce((acc, val) => ({
    otsAvg: acc.otsAvg + val.otsAvg,
    otsLitAvg: acc.otsLitAvg + val.otsLitAvg
  }));

  const { otsAvg, otsLitAvg } = result;

  // returning OTS according to LitStatus
  if (lit === "Lit") {
    return otsLitAvg;
  }
  return otsAvg;
}

/**
 * @returns Tg Selected Impressions (Specific and Generic)
 */
function getTgSelectedImpressions(ots, otsLit, lit) {
  if (lit === "Lit") {
    return otsLit;
  }
  return ots;
}

export {
  SegmentLoading,
  getTotalOfSegmentsTgOts,
  getTotalEstImpressions,
  getTgSelectedImpressions
};
