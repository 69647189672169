import { ActionTypes } from "../constants/ActionConstants";

const initialState = {
  openModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CitySelectionForm.OPEN_SINGLE_CITY_SELECTION_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case ActionTypes.CitySelectionForm.CLOSE_SINGLE_CITY_SELECTION_MODAL:
      return {
        ...state,
        openModal: false,
      };

    default:
      return state;
  }
};
