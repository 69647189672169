import { useState } from "react";

// Components
import { CustomButton } from "../button/Button";

// Page Constants
const dropdownItems = [
  { id: 7, label: "7 Days" },
  { id: 14, label: "14 Days" },
  { id: 21, label: "21 Days" },
  { id: 30, label: "30 Days" },
  { id: 60, label: "60 Days" },
  { id: 90, label: "90 Days" },
  { id: 180, label: "180 Days" },
];

export default function DurationSelector({
  items = dropdownItems,
  onItemSelect,
  startDate = "",
  buttonClassName = "",
  initialDays = "",
}) {
  const [days, setDays] = useState(initialDays);
  function handleDurationSelect(id) {
    onItemSelect(id);
    setDays(id);
  }
  return (
    <div className="dropdown">
      <button
        className={`border btn shadow-none rounded-lg px-2 ${buttonClassName} `}
        type="button"
        id="durationSelector"
        data-toggle="dropdown"
        aria-expanded="false"
        disabled={!startDate}
      >
        <span>{days ? days + " days" : "Select Duration"}</span>
        <i className="fa fa-angle-down text-primary mt-1 ml-2"></i>
      </button>
      <div
        className="dropdown-menu col rounded-lg"
        aria-labelledby="durationSelector"
      >
        {items.map((item, i) => (
          <CustomButton
            key={i}
            buttonClassName="dropdown-item px-2"
            onClickFunction={() => handleDurationSelect(item.id)}
            displayContent={item.label}
          />
        ))}
      </div>
    </div>
  );
}
