import { useDispatch, useSelector } from "react-redux";

// Actions
import { setSelectedTG } from "../../actions/campaign-planning/CampaignPlanningActions";

// Components
import NestedList from "../../components/nested-list/NestedList";

export default function TargetGroupTab() {
  const dispatch = useDispatch();

  const targetGroupData = useSelector(
    (state) => state.campaignPlanning.targetGroupData
  );

  function actionOnSelect(selectedIndex, label) {
    dispatch(setSelectedTG(selectedIndex, label));
  }

  return (
    <>
      {/* Header Section */}
      <div className="d-flex justify-content-between p-0">
        <h2 className="mb-0">Target Group</h2>
        <button
          type="button"
          className="btn btn-primary px-4 rounded-lg"
          onClick={() => {}}
          disabled={true}
        >
          Generate Heatmap
        </button>
      </div>
      <hr className="my-3"></hr>
      <NestedList
        data={targetGroupData}
        selectAllAttr={false}
        setSelected={actionOnSelect}
        setAdded={() => {}}
        onCheckboxChange={() => {}}
      />
    </>
  );
}
