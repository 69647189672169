// This function checks if the passed string is actually a valid coorinate string
export function isValidCoordinate(coordinateString) {
  if (!coordinateString) {
    return false;
  }
  const coordinatesArray = coordinateString.split(",");

  // handling the case of entering lat-lng by manually (typing each number)
  // if the length is "one" then also return "false"
  if (coordinatesArray.length !== 2) {
    return false;
  }

  return coordinatesArray.every(function (eachString) {
    // isNaN("abc") ==> returns True
    // isNaN("123") ==> returns False
    return !isNaN(eachString);
  });
}

// This function is used to convert coordinates in string format to number formates
// Please make sure the string passed is a valid latLong String
export function getCoordinates(coordinateString) {
  return coordinateString.split(",").map((eachString) => Number(eachString));
}
