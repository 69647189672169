export const MediaSitesSelection = {
  SELECT: "MEDIA_SITES_SELECT",
  SELECT_MULTIPLE: "MEDIA_SITES_SELECT_MULTIPLE",
  UNSELECT: "MEDIA_SITES_UNSELECT",
  UNSELECT_MULTIPLE: "MEDIA_SITES_UNSELECT_MULTIPLE",
  RESET: "RESET_MEDIA_SITES_SELECTION",
};

export const MediaSites = {
  GET_MEDIA_SITES: "GET_MEDIA_SITES",
  GET_MEDIA_SITES_SUCCESS: "GET_MEDIA_SITES_SUCCESS",
  GET_MEDIA_SITES_FAILURE: "GET_MEDIA_SITES_FAILURE",

  // Remove media site
  REMOVE_MEDIA_SITE: "REMOVE_MEDIA_SITE",
  REMOVE_MEDIA_SITE_SUCCESS: "REMOVE_MEDIA_SITE_SUCCESS",
  REMOVE_MEDIA_SITE_FAILURE: "REMOVE_MEDIA_SITE_FAILURE",

  // RESET:
  RESET: "RESET_MEDIA_SITES",

  // set CampaignMedia Fill Color
  SET_CAMPAIGN_MEDIA_FILL_COLOR: "SET_CAMPAIGN_MEDIA_FILL_COLOR",

  // to set FILTER CampaignMedia
  SET_CAMPAIGN_MEDIA_FILTERS: "SET_CAMPAIGN_MEDIA_FILTERS",
};

export const MapExploreSites = {
  MAP_EXPLORE_SITES: "MAP_EXPLORE_SITES",
  MAP_EXPLORE_SITES_SUCCESS: "MAP_EXPLORE_SITES_SUCCESS",
  MAP_EXPLORE_SITES_FAILURE: "MAP_EXPLORE_SITES_FAILURE",
};
