// Utils
import { downloadFile, sendGetRequest } from "../utils/api/apiUtils";
import { getToken } from "../utils/token";
import { roundUpNearestInteger } from "../common-utils/number-utils/NumberUtils";

// Urls
import {
  campaignMediaDemographicStatsUrl,
  downloadCampaignGraphPdfReportUrl,
} from "../urls/GraphReportsURL";

// get campaign media demographic stats Api
export async function getCampaignMediaDemographicStatsApi(campaignId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendGetRequest(
    campaignMediaDemographicStatsUrl.replace(":id", campaignId),
    {},
    true,
    headerParams
  );
}

// download Campaign Graph Pdf Report Api
export async function downloadCampaignGraphPdfReportFn(
  campaignId,
  selectedCityId,
  selectedMetricKey,
  mapCenter,
  mapZoom
) {
  const headerParams = {
    Authorization: "Bearer " + getToken(),
  };

  // string => "lat,lng"
  const centerAsString = mapCenter.join(",");

  // round off zoom
  const roundOffZoom = roundUpNearestInteger(mapZoom);

  // params
  const params = {
    metricKey: selectedMetricKey,
    center: centerAsString,
    zoom: roundOffZoom,
  };

  return downloadFile(
    downloadCampaignGraphPdfReportUrl
      .replace(":id", campaignId)
      .replace(":cityId", selectedCityId),
    true,
    headerParams,
    params
  );
}
