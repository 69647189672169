import { Auth } from "../../constants/action-constants/AuthActionConstants";

//intital state
const initialState = {
  // login in
  loginLoading: false,
  authUser: {},
  loginError: "",

  // forgot password
  forgotPasswordLoading: false,
  forgotPasswordError: "",
  resetMailSent: false,

  // reset password
  resetPasswordLoading: false,
  resetPasswordError: "",
  isPasswordChanged: false,
};

//reducer to handle state changes
export default (state = initialState, action) => {
  switch (action.type) {
    // Login
    //------------------------------------------------------------
    case Auth.USER_LOGIN:
      return {
        ...state,
        loginLoading: true,
        loginError: "",
      };
    case Auth.USER_LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        authUser: action.payload.loggedInData,
      };

    case Auth.USER_LOGIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        loginError: action.payload ?? "Invalid credentials",
      };

    // Forgot password
    //------------------------------------------------------------
    case Auth.FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordLoading: true,
      };

    case Auth.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordLoading: false,
        resetMailSent: true,
      };

    case Auth.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordLoading: false,
        resetMailSent: false,
        forgotPasswordError: action.payload,
      };

    // Reset Password
    //------------------------------------------------------------
    case Auth.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordLoading: true,
      };

    case Auth.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        isPasswordChanged: true,
      };

    case Auth.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordLoading: false,
        isPasswordChanged: false,
        resetPasswordError: action.payload,
      };

    // forgot password state
    case Auth.CLEAR_FORGOT_PASSWORD_STATE:
      return {
        ...state,
        forgotPasswordError: "",
        resetMailSent: false,
      };

    // clear login info
    case Auth.CLEAR_LOGIN_INFO:
      return {
        ...state,
        authUser: {},
      };
    default:
      return state;
  }
};
