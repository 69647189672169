import { OrgMediaPrice } from "../../constants/action-constants/org/OrgMediaPriceActionConstants";

export const updateMediaPrice = (mediaPriceBean) => ({
  type: OrgMediaPrice.UPDATE_MEDIA_PRICE,
  payload: { mediaPriceBean },
});

export const getMediaPriceByIds = (mediaIds) => ({
  type: OrgMediaPrice.GET_MEDIA_PRICE_BY_IDS,
  payload: { mediaIds },
});
