import { CampaignDetails, CancelCampaign } from "../components/HeaderNav";
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

export function HeaderSection({ campaign }) {
  const EnableCancelCampaign = isAgencySpecific(CancelCampaign);

  const pageTitle = <CampaignDetails campaign={campaign} />;
  const pageActions = (
    <div className="d-flex ">
      {EnableCancelCampaign && <EnableCancelCampaign />}
    </div>
  );
  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}
