/* eslint-disable no-case-declarations */
import {
  CreateNewRoadStretches,
  RoadStretch,
} from "../../constants/action-constants/RoadStretchActionConstants";

//initial state
const initialState = {
  // RoadSegment States for Road-stretch-creation
  // ------------------------------------------------------------------------------------
  roadSegmentsMap: {},
  closestRoadSegmentErr: "",
  closestRoadSegmentLoading: false,
  // Selected Segments
  // {
  //   latLong1 : {segmentId1: true, segmentId2: true},
  //   latLong2 : {segmentId1: true, segmentId3: true},
  //     .
  //     .
  //   segmentIds: {segmentId1: true, segmentId2: true, ...},
  // }
  // Here latLong    ==>  startCordinate / endCoordinate of the segment
  // And  segmentIds ==>  stores all the selectedSegemntIds
  selectedSegments: {},
  // Create RoadStretch states
  createStretchLoading: false,
  createStretchErr: "",

  // road-stretch archive-restore
  // ----------------------------------------------------------------------------------
  isStretchArchiveRestore: {},
  stretchArchiveErr: "",
  stretchRestoreErr: "",

  // get road-stretches by IDS
  // ------------------------------------------------------------------------------------
  roadStretchByIdsLoading: false,
  roadStretchByIdsError: "",
  roadStretchDetailsMap: {},

  // Road-stretches for ORGANISATIONS
  // ------------------------------------------------------------------------------------
  orgRoadStretchesLoading: false,
  orgRoadStretchesError: "",
  orgRoadStretches: {},
  orgRoadStretchesPagination: {},
  cityId: "",

  // Single Road-Stretch Info by ID
  // ---------------------------------------------------------------------------------
  roadStretchInfoLoading: false,
  roadStretchInfoMap: {},
  roadStretchInfoError: "",

  //  Refresh Pricing
  // ---------------------------------------------------------------------------------
  stretchPriceRefreshLoading: {},
  stretchPriceRefreshErr: "",
};

/**
 * @param {*} stretchInfo
 * @returns updateStretchTrace
 */
function updateStretchTrace(stretchInfo) {
  const trace = stretchInfo.trace.map((eachCoordinate) => [
    eachCoordinate.latitude,
    eachCoordinate.longitude,
  ]);
  return { ...stretchInfo, trace };
}

function addSelectedSegment(roadSegment, selectedSegments) {
  const { id, trace } = roadSegment;
  const startCoordinate = trace[0].join();
  const endCoordinate = trace[trace.length - 1].join();

  const segmentsWithStartCoordinate = selectedSegments[startCoordinate] || {};
  segmentsWithStartCoordinate[id] = true;
  selectedSegments[startCoordinate] = segmentsWithStartCoordinate;

  const segmentsWithEndCoordinate = selectedSegments[endCoordinate] || {};
  segmentsWithEndCoordinate[id] = true;
  selectedSegments[endCoordinate] = segmentsWithEndCoordinate;

  const segmentIds = selectedSegments.segmentIds || {};
  segmentIds[id] = true;
  selectedSegments.segmentIds = segmentIds;

  return selectedSegments;
}

function removeSelectedSegment(roadSegment, selectedSegments) {
  const { id, trace } = roadSegment;
  const startCoordinate = trace[0].join();
  const endCoordinate = trace[trace.length - 1].join();

  const segmentsWithStartCoordinate = selectedSegments[startCoordinate];
  delete segmentsWithStartCoordinate[id];
  selectedSegments[startCoordinate] = segmentsWithStartCoordinate;

  const segmentsWithEndCoordinate = selectedSegments[endCoordinate];
  delete segmentsWithEndCoordinate[id];
  selectedSegments[endCoordinate] = segmentsWithEndCoordinate;

  const segmentIds = selectedSegments.segmentIds;
  delete segmentIds[id];
  selectedSegments.segmentIds = segmentIds;

  return selectedSegments;
}

function addRoadSegments(roadSegments, originalRoadSegments) {
  const updatedSegments = roadSegments.reduce((acc, eachSegment) => {
    // We are updating the trace so as to display on the Map

    const trace = eachSegment.trace.map((eachCoordinate) => [
      eachCoordinate.latitude,
      eachCoordinate.longitude,
    ]);
    const updatedSegment = { ...eachSegment, trace };
    acc[updatedSegment.id] = updatedSegment;
    return acc;
  }, {});
  return { ...originalRoadSegments, ...updatedSegments };
}

export default (state = initialState, action) => {
  switch (action.type) {
    // Road-Stretch-Creation
    // -----------------------------------------------------------------------------
    case CreateNewRoadStretches.GET_CLOSEST_ROAD_SG:
      return {
        ...state,
        closestRoadSegmentLoading: true,
      };

    case CreateNewRoadStretches.GET_CLOSEST_ROAD_SG_SUCCESS:
      return {
        ...state,
        roadSegmentsMap: addRoadSegments(
          action.payload.roadSegments,
          state.roadSegmentsMap
        ),
        closestRoadSegmentLoading: false,
      };

    case CreateNewRoadStretches.GET_CLOSEST_ROAD_SG_FAILURE:
      return {
        ...state,
        closestRoadSegmentLoading: false,
        closestRoadSegmentErr: action.payload,
      };

    case CreateNewRoadStretches.RESET_CLOSEST_ROAD_SG:
      return {
        ...state,
        roadSegmentsMap: {},
      };

    case CreateNewRoadStretches.ADD_ROAD_SEGMENT: {
      const { roadSegment } = action.payload;
      return {
        ...state,
        selectedSegments: addSelectedSegment(roadSegment, {
          ...state.selectedSegments,
        }),
      };
    }

    case CreateNewRoadStretches.REMOVE_ROAD_SEGMENT: {
      const { roadSegment } = action.payload;
      return {
        ...state,
        selectedSegments: removeSelectedSegment(roadSegment, {
          ...state.selectedSegments,
        }),
      };
    }

    case CreateNewRoadStretches.CREATE_ROAD_STRETCH: {
      return {
        ...state,
        createStretchLoading: true,
      };
    }

    case CreateNewRoadStretches.CREATE_ROAD_STRETCH_SUCCESS: {
      return {
        ...state,
        createStretchLoading: false,
      };
    }

    case CreateNewRoadStretches.CREATE_ROAD_STRETCH_FAILURE: {
      return {
        ...state,
        createStretchLoading: false,
        createStretchErr: action.payload,
      };
    }

    case CreateNewRoadStretches.RESET: {
      return initialState;
    }

    // Road-stretch Archive-Restore
    // ---------------------------------------------------------------------------
    case RoadStretch.ARCHIVE_ROAD_STRETCH: {
      const { stretchId } = action.payload;
      return {
        ...state,
        isStretchArchiveRestore: {
          ...state.isStretchArchiveRestore,
          [stretchId]: true,
        },
      };
    }
    case RoadStretch.ARCHIVE_ROAD_STRETCH_SUCCESS: {
      const { stretchId } = action.payload;
      return {
        ...state,
        isStretchArchiveRestore: {
          ...state.isStretchArchiveRestore,
          [stretchId]: false,
        },
      };
    }
    case RoadStretch.ARCHIVE_ROAD_STRETCH_FAILURE: {
      const { stretchId, errMsg } = action.payload;
      return {
        ...state,
        isStretchArchiveRestore: {
          ...state.isStretchArchiveRestore,
          [stretchId]: false,
        },
        archiveErr: errMsg,
      };
    }

    case RoadStretch.RESTORE_ROAD_STRETCH: {
      const { stretchId } = action.payload;
      return {
        ...state,
        isStretchArchiveRestore: {
          ...state.isStretchArchiveRestore,
          [stretchId]: true,
        },
      };
    }
    case RoadStretch.RESTORE_ROAD_STRETCH_SUCCESS: {
      const { stretchId } = action.payload;
      return {
        ...state,
        isStretchArchiveRestore: {
          ...state.isStretchArchiveRestore,
          [stretchId]: false,
        },
      };
    }
    case RoadStretch.RESTORE_ROAD_STRETCH_FAILURE: {
      const { stretchId, errMsg } = action.payload;
      return {
        ...state,
        isStretchArchiveRestore: {
          ...state.isStretchArchiveRestore,
          [stretchId]: false,
        },
        restoreErr: errMsg,
      };
    }

    // Getting Road-stretches by IDS
    // ----------------------------------------------------------------------------
    case RoadStretch.GET_ROAD_STRETCHES_BY_IDS:
      return {
        ...state,
        roadStretchByIdsLoading: true,
      };

    case RoadStretch.GET_ROAD_STRETCHES_BY_IDS_SUCCESS:
      const roadStetchMap = action.payload.roadStretchDetailsMap;
      const updatedStretches = Object.keys(roadStetchMap).reduce(
        (acc, eachStetchId) => {
          acc[eachStetchId] = updateStretchTrace(roadStetchMap[eachStetchId]);
          return acc;
        },
        {}
      );

      return {
        ...state,
        roadStretchDetailsMap: updatedStretches,
        roadStretchByIdsLoading: false,
      };

    case RoadStretch.GET_ROAD_STRETCHES_BY_IDS_FAILURE:
      return {
        ...state,
        roadStretchByIdsLoading: false,
        roadStretchByIdsError: action.payload,
      };

    // Road-stretches for ORGANISATION
    // ------------------------------------------------------------------------
    case RoadStretch.GET_ROAD_STRETCHES_OF_ORG:
      return {
        ...state,
        orgRoadStretchesLoading: true,
        cityId: action.payload.cityId,
      };

    case RoadStretch.GET_ROAD_STRETCHES_OF_ORG_SUCCESS:
      const roadStretches = action.payload.roadStretches;
      const updatedTraceStretches = roadStretches.reduce((acc, stretch) => {
        const updatedStretch = updateStretchTrace(stretch);
        acc[updatedStretch.id] = updatedStretch;
        return acc;
      }, {});
      return {
        ...state,
        orgRoadStretchesLoading: false,
        orgRoadStretches: updatedTraceStretches,
        orgRoadStretchesPagination: action.payload.pagination,
      };

    case RoadStretch.GET_ROAD_STRETCHES_OF_ORG_FAILURE:
      return {
        ...state,
        orgRoadStretchesLoading: false,
        orgRoadStretchesError: action.payload,
      };

    case RoadStretch.UPDATE_ROAD_STRETCH_STATUS: {
      const { id, isArchived } = action.payload;
      const roadStretchesMap = { ...state.orgRoadStretches };
      const roadStretchToUpdate = roadStretchesMap[id];
      roadStretchesMap[id] = {
        ...roadStretchToUpdate,
        isArchived,
        updatedOn: Date.now(),
      };
      return {
        ...state,
        orgRoadStretches: roadStretchesMap,
      };
    }

    // Single Road-Stretch Info By ID
    // ---------------------------------------------------------------------------
    case RoadStretch.GET_ROAD_STRETCH_INFO_BY_ID:
      return {
        ...state,
        roadStretchInfoLoading: true,
        roadStretchId: action.payload.stretchId,
      };

    case RoadStretch.GET_ROAD_STRETCH_INFO_BY_ID_SUCCESS:
      const stretchInfo = action.payload.stretchInfo;
      return {
        ...state,
        roadStretchInfoMap: {
          ...state.roadStretchInfoMap,
          [stretchInfo.id]: updateStretchTrace(stretchInfo),
        },
        roadStretchInfoLoading: false,
      };

    case RoadStretch.GET_ROAD_STRETCH_INFO_BY_ID_FAILURE:
      return {
        ...state,
        roadStretchInfoLoading: false,
        roadStretchInfoError: action.payload,
      };

    // Refresh Pricing
    // ---------------------------------------------------------------------------
    case RoadStretch.ROAD_STRETCH_PRICE_REFRESH:
      return {
        ...state,
        stretchPriceRefreshLoading: {
          [action.payload.stretchId]: true,
        },
      };

    case RoadStretch.ROAD_STRETCH_PRICE_REFRESH_SUCCESS:
      return {
        ...state,
        stretchPriceRefreshLoading: {},
      };

    case RoadStretch.ROAD_STRETCH_PRICE_REFRESH_FAILURE:
      return {
        ...state,
        stretchPriceRefreshLoading: {},
        stretchPriceRefreshErr: action.payload,
      };

    case RoadStretch.CLEAR_ROAD_STRETCH_DATA:
      return {
        ...state,
        roadStretchInfoMap: {},
      };

    default:
      return state;
  }
};
