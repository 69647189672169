import { DivIcon } from "leaflet";
import { Marker, Popup } from "react-leaflet";

// CSS
import "./DataPointMarkers.scss";
// TODO :: currently the css marker classes are hard-coded to support only 20 layers
// Include type and dynamically allot colors and icons

export default function DataPointMarkers({ layerIdx, dataPoints }) {
  const markerIcon = new DivIcon({
    className: `data-layer-marker layer_${layerIdx}`,
    iconSize: [20, 20],
  });

  return dataPoints.map((dataPoint, index) => {
    const position = dataPoint.latLngStr.split(",");
    return (
      <Marker key={index} position={position} icon={markerIcon}>
        <Popup>
          <b>{dataPoint.name}</b>.
        </Popup>
      </Marker>
    );
  });
}
