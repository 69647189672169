function ErrorMessage({ error }) {
  if (!error) {
    return null;
  }

  return (
    <div id="validationServer03Feedback" className="invalid-feedback">
      {error}
    </div>
  );
}

function PermissionSection({ formikValuesAndFn }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  const inputDetails = [
    {
      id: "siteAnalyzer",
      value: "Site Analyzer",
    },
    {
      id: "cityAnalyzer",
      value: "City Analyzer",
    },
    {
      id: "campaignReport",
      value: "Campaign Report",
    },
    {
      id: "campaignCreator",
      value: "Campaign Creator",
    },
    {
      id: "campaignMonitor",
      value: "Campaign Monitor",
    },
    {
      id: "customDataLayerManager",
      value: "Custom Data Layer Manager",
    },
    {
      id: "targetGroupManager",
      value: "Target Group Manager",
    },
  ];

  function checksForPermissions(value) {
    return values.roles.some((role) => role === value);
  }
  return (
    <>
      <fieldset className="form-group row ">
        <legend className="col-form-label col-sm-2 float-sm-left pt-0">
          Permission
        </legend>

        <div className="col-10 form-check col-sm-10 px-0">
          {inputDetails.map((info, i) => (
            <div key={i}>
              <input
                className={`form-check-input ml-3 mr-1 ${
                  errors.roles && touched.roles && "is-invalid"
                }`}
                type="checkbox"
                id={info.id}
                name={"roles"}
                checked={checksForPermissions(info.value)}
                value={info.value}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor={info.id}>
                {info.value}
              </label>
            </div>
          ))}

          {/* error message for permission */}
          <small className="d-block text-danger mt-1 ml-3">
            {touched.roles && errors.roles}
          </small>
        </div>
      </fieldset>
    </>
  );
}

export { PermissionSection, ErrorMessage };
