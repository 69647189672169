import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import { openConfirmationForm } from "../../actions/ConfirmationFormActions";

// Constants and Utils
import {
  toLocaleString,
  formatText,
} from "../../common-utils/string-utils/StringUtils";
import { hasAccess } from "../../utils/hasAccess";
import { FormDataTargets } from "../../constants/GeneralConstants";
import { IsTrafficOtsMethodology } from "../../common-utils/subscription-utils/SubscriptionUtil";
import { getOtsTotal } from "../../utils/mavinMetrics";
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";
import { calculatePercentage } from "../../common-utils/number-utils/NumberUtils";

// Components
import Spinner from "../spinner/Spinner";
import Hamburger from "../hamburger-menu/Hamburger";
import BootstrapDateRangePicker from "../bootstrap-date-range-picker/BootstrapDateRangePicker";
import { ProgressBar } from "../progress-bar/ProgressBar";

// Table Row Cells
function CityNameCell({ cityElementStyle, cityInfo, redirectUrl }) {
  const cityName = cityInfo.cityName || cityInfo.cityId;
  // TODO: can be added monitoring status here..
  return (
    <td className={cityElementStyle}>
      <Link to={constructRedirectPath(redirectUrl)}>
        {formatText(cityName)}
      </Link>
    </td>
  );
}

function MonitoredMediaCountCell({
  mediaCountElementStyle,
  monitoredMediaCount,
}) {
  return (
    <td className={mediaCountElementStyle}>
      {monitoredMediaCount === 0 ? 0 : formatText(monitoredMediaCount)}
    </td>
  );
}

function MediaCountCell({ mediaCountElementStyle, cityInfo }) {
  const mediaCount = cityInfo.mediaCount;
  return (
    <td className={mediaCountElementStyle}>
      {mediaCount === 0 ? 0 : toLocaleString(mediaCount)}
    </td>
  );
}

function DurationCell({
  dateObj = {},
  durationElementStyle,
  onDateSelect,
  duration,

  // optional for disabled
  disabledOptions = {},
}) {
  const startDate = new Date(dateObj.startTimestamp);
  const endDate = new Date(dateObj.endTimestamp);

  // disabled
  const {
    isDisabled,
    disabledOnClick,
    showCalenderIcon,
    showInfoIcon = false,
    disabledIconTooltip,
  } = disabledOptions || {};

  // styles
  const styleObject = {
    border: false,
    isPickerDisabled: isDisabled,
    calenderIcon: showCalenderIcon,
  };

  return (
    <td className={durationElementStyle}>
      <BootstrapDateRangePicker
        initialStartDate={startDate}
        initialEndDate={endDate}
        onApplyDates={onDateSelect}
        styleObject={styleObject}
      />

      {/* disabled info icon */}
      {showInfoIcon && (
        <i
          title={disabledIconTooltip}
          onClick={disabledOnClick}
          className="fas fs-6 fa-exclamation-circle opacity-75 text-danger cursor-pointer"
        />
      )}

      <div className="mr-1 px-2">
        <small className="text-muted">{duration} days</small>
      </div>
    </td>
  );
}

function ImpressionsCell({ impressionsElementStyle, cityInfo }) {
  const { mediaWithOtsCount, mediaWithhMobileOtsCount, mediaCount } = cityInfo;

  const isTrafficMethodology = IsTrafficOtsMethodology();

  const { otsTotal, tgOtsTotal } = getOtsTotal(cityInfo) || {};
  const tgPercentage = calculatePercentage(
    Number(tgOtsTotal),
    Number(otsTotal)
  );

  return (
    <td className={impressionsElementStyle}>
      <p className="mb-2 d-flex align-items-center">
        <span className="d-inline-block border border-dark mr-2 square bg-light-green"></span>
        {formatText(toLocaleString(tgOtsTotal))}
        {/* <span className="float-right">{`(${tgPercentage}%)`}</span> */}
      </p>
      <p className="mb-2 d-flex align-items-center">
        <span className="d-inline-block border border-dark mr-2 square bg-light-blue"></span>
        {formatText(toLocaleString(otsTotal))}
      </p>

      {/* showing bars only if otsTotal>0 */}
      {parseInt(otsTotal) > 0 && (
        <>
          <ProgressBar className="bg-light-green" percentage={tgPercentage} />
          <ProgressBar className="bg-light-blue" percentage={100} />
        </>
      )}
      <small className="sub-text mt-1">
        {isTrafficMethodology ? mediaWithOtsCount : mediaWithhMobileOtsCount} /{" "}
        {mediaCount} sites
      </small>
    </td>
  );
}

function getLtsRatingAvg(ltsRatingAvg) {
  if (!ltsRatingAvg || isNaN(ltsRatingAvg)) {
    return "";
  }
  return ltsRatingAvg.toFixed(2) + "/5";
}

function LtsCell({ ltsElementClass, cityInfo = {} }) {
  const { ltsRatingAvg } = cityInfo;
  const ltsAvg = getLtsRatingAvg(ltsRatingAvg);

  return (
    <td className={ltsElementClass}>
      <p className="mb-0">{formatText(ltsAvg)}</p>
      <small className="text-muted">
        {cityInfo.mediaWithLTSCount} / {cityInfo.mediaCount} sites
      </small>
    </td>
  );
}

function RemoveCityButton({ cityId }) {
  const dispatch = useDispatch();

  return (
    <button
      className="dropdown-item"
      data-toggle="modal"
      data-target={`#${FormDataTargets.confirmationForm}`}
      onClick={() => dispatch(openConfirmationForm({ cityId }))}
    >
      {"Remove city"}
    </button>
  );
}

function HamburgerCell({ hamburgerElementStyle, cityInfo }) {
  const { cityId } = cityInfo;

  // Remove City States
  const processDataObject = useSelector(
    (state) => state.confirmationModal.processDataObject
  );
  const isRemovedCityIdMatches = processDataObject?.cityId === cityId;
  const removeCityLoading = useSelector(
    (state) => state.campaign.removeCityLoading
  );

  if (removeCityLoading && isRemovedCityIdMatches) {
    return (
      <td className={hamburgerElementStyle}>
        <Spinner className="mr-4" />
      </td>
    );
  }
  return (
    <td className={hamburgerElementStyle}>
      <Hamburger>
        <button className="dropdown-item d-none">{"Download report"}</button>
        <button className="dropdown-item d-none">{"Share link"}</button>
        <RemoveCityButton cityId={cityId} />
        {hasAccess("monitoring") && (
          <button className="dropdown-item d-none">{"Setup monitoring"}</button>
        )}
      </Hamburger>
    </td>
  );
}

export {
  DurationCell,
  ImpressionsCell,
  LtsCell,
  CityNameCell,
  MediaCountCell,
  MonitoredMediaCountCell,
  getLtsRatingAvg,
  HamburgerCell,
};
