// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Constants and Utils
import { getIsSubscriptionActive } from "../../common-utils/subscription-utils/SubscriptionUtil";

// Components
import DashboardCard from "../../components/dashboard-card/DashboardCard";
import PageHeader from "../../mavin/components/page-header/PageHeader";

// Page constants
const {
  sellerInventoryHomeUrl,
  sellerWebsiteSetupPageUrl,
  toolsSiteAnalyserPageUrl,
} = RedirectTo;

const cardsInfo = [
  {
    title: "View Inventory",
    content:
      "Manage your Media Inventory. Manage Reports, Pricing and public pages.",
    CTALabel: "View Inventory",
    CTALink: sellerInventoryHomeUrl,
  },
  {
    title: "Site Report",
    content:
      "Analyze individual sites with detailed report on traffic, demographics, points of interest, etc.",
    CTALabel: "Enter site location",
    CTALink: toolsSiteAnalyserPageUrl,
  },
  {
    title: "Website Setup",
    content:
      "Publish your SEO optimised website and Manage your Media site content.",
    CTALabel: "View Website Settings",
    CTALink: sellerWebsiteSetupPageUrl,
  },
];

function SellerDashboardCards() {
  return (
    <div className="justify-content-left row">
      {cardsInfo.map((card, i) => {
        // Checking Org is Subscribed or Not
        const isSubscribed = getIsSubscriptionActive();
        const link = card.CTALink;
        const isBtnDisable = !isSubscribed && link === toolsSiteAnalyserPageUrl;

        return (
          <DashboardCard
            key={i}
            card={card}
            i={i}
            isDisabledBtn={isBtnDisable}
          />
        );
      })}
    </div>
  );
}

function SellerDashboardPage() {
  const pageActions = (
    <p className="pt-2">Version : {process.env.REACT_APP_VERSION}</p>
  );

  return (
    <div className="content-wrapper bg-alt h-100">
      <PageHeader
        title={"Dashboard"}
        border={false}
        shadow={false}
        actions={pageActions}
      />

      <div className="page-content">
        <SellerDashboardCards />
        <hr className="divider" />
      </div>
    </div>
  );
}

export default SellerDashboardPage;
