//campaign report
export const CampaignReport = {
  // Campaign Summary
  CAMPAIGN_OVERVIEW: "CAMPAIGN_OVERVIEW",
  CAMPAIGN_OVERVIEW_SUCCESS: "CAMPAIGN_OVERVIEW_SUCCESS",
  CAMPAIGN_OVERVIEW_FAILURE: "CAMPAIGN_OVERVIEW_FAILURE",

  // Generate Mavin Report
  GENERATE_MAVIN_REPORT: "GENERATE_MAVIN_REPORT",
  GENERATE_MAVIN_REPORT_SUCCESS: "GENERATE_MAVIN_REPORT_SUCCESS",
  GENERATE_MAVIN_REPORT_FAILURE: "GENERATE_MAVIN_REPORT_FAILURE",

  // Download Report Xls
  DOWNLOAD_XLS_REPORT: "DOWNLOAD_CAMPAIGN_XLS_REPORT",
  DOWNLOAD_XLS_REPORT_SUCCESS: "DOWNLOAD_XLS_REPORT_SUCCESS",
  DOWNLOAD_XLS_REPORT_FAILURE: "DOWNLOAD_XLS_REPORT_FAILURE",

  // Download Report Pdf
  DOWNLOAD_CAMPAIGN_PDF_REPORT: "DOWNLOAD_CAMPAIGN_PDF_REPORT",
  DOWNLOAD_CAMPAIGN_PDF_REPORT_SUCCESS: "DOWNLOAD_CAMPAIGN_PDF_REPORT_SUCCESS",
  DOWNLOAD_CAMPAIGN_PDF_REPORT_FAILURE: "DOWNLOAD_CAMPAIGN_PDF_REPORT_FAILURE",

  // Generate Campaign Excel Report
  GENERATE_CAMPAIGN_EXCEL_REPORT: "GENERATE_CAMPAIGN_EXCEL_REPORT",
  GENERATE_CAMPAIGN_EXCEL_REPORT_SUCCESS:
    "GENERATE_CAMPAIGN_EXCEL_REPORT_SUCCESS",
  GENERATE_CAMPAIGN_EXCEL_REPORT_FAILURE:
    "GENERATE_CAMPAIGN_EXCEL_REPORT_FAILURE",
};
