import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// Component - Generate Template Card
function GenerateTemplateCard({
  linkTo,
  mediaCount,
  generateTemplateData,
  campaignId,
}) {
  const history = useHistory();

  const { step, title, message, buttonLabel } = generateTemplateData;

  // if user upload single site then "site" else "sites"
  const isSingleSiteText = mediaCount === 1 ? "site" : "sites";

  // Functions
  function onGenerateTempClick() {
    history.push(linkTo);

    // Showing toaster msg only if user create media in existing campaign
    if (campaignId) {
      toast.success(
        `${mediaCount} media ${isSingleSiteText} has been added to campaign.`
      );
    }
  }

  return (
    <div className="card shadow mb-4 mt-3">
      <div className="d-flex justify-content-between">
        <div className="card-content">
          <p className="card__step">{step}</p>
          <p className="card__title">{title}</p>
          <p className="card__message">
            {message}
            <a className="text-primary"> Learn more</a>
          </p>
        </div>
        <div className="card-action">
          <button
            className="btn btn-primary btn-action mt-5"
            disabled={!mediaCount}
            onClick={onGenerateTempClick}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

export default GenerateTemplateCard;
