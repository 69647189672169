import { Monitoring } from "../../constants/action-constants/MonitoringActionConstants";

export const getMonitoringCampaignSummary = (id) => ({
  type: Monitoring.GET_MONITORING_CAMPAIGN_SUMMARY,
  payload: { id },
});

export const getMonitoringCitySummary = (
  campaignId,
  cityId,
  pageNumber,
  pageSizeCount,
  keyword,
  applyFilters
) => ({
  type: Monitoring.GET_MONITORING_CITY_SUMMARY,
  payload: {
    campaignId,
    cityId,
    pageNumber,
    pageSizeCount,
    keyword,
    applyFilters,
  },
});

// for Media-Selection
export const selectMediaSite = (mediaId) => ({
  type: Monitoring.SELECT,
  payload: {
    id: mediaId,
  },
});

export const unselectMediaSite = (mediaId) => ({
  type: Monitoring.UNSELECT,
  payload: {
    id: mediaId,
  },
});

export const selectMultipleMediaSites = (mediaIds) => ({
  type: Monitoring.SELECT_MULTIPLE,
  payload: {
    ids: mediaIds,
  },
});

export const unselectMultipleMediaSites = (mediaIds) => ({
  type: Monitoring.UNSELECT_MULTIPLE,
  payload: {
    ids: mediaIds,
  },
});

export const getMonitoringMediaSettings = (campaignId, mediaId) => ({
  type: Monitoring.GET_MONITORING_MEDIA_SETTING,
  payload: { campaignId, mediaId },
});

export const createSetupMonitoring = (
  inputFieldBean,
  campaignId,
  cityId,
  mediaIds
) => ({
  type: Monitoring.CREATE_SETUP_MONITORING,
  payload: { inputFieldBean, campaignId, cityId, mediaIds },
});

// These below actions are for setup monitoring form
export const openSetupMonitoringForm = (id, mediaDateObj, settingId) => ({
  type: Monitoring.OPEN_SETUP_MONITORING_MODAL,
  payload: { id, mediaDateObj, settingId },
});

export const closeSetupMonitoringForm = () => ({
  type: Monitoring.CLOSE_SETUP_MONITORING_MODAL,
});

// cancel site monitoring
export const cancelSiteMonitoring = (campaignId, cityId, monSettingIds) => ({
  type: Monitoring.CANCEL_SITE_MONITORING,
  payload: { campaignId, cityId, monSettingIds },
});
