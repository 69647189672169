import { ActionTypes } from "../../constants/ActionConstants";
import { updateTgImpDetails } from "../utils/RoadUtil";

const initialState = {
  loading: false,
  error: "",
  tgSpecificOts: {},
  tgId: "",

  // TgSpecificOts for ROAD-SEGMENT
  // ----------------------------------------------------------------------------------
  segmentTgOtsLoading: false,
  allSegmentsTgOtsMap: {},
  segmentTgOtsError: "",
};

function constructTgSpecificOtsObject(
  currentTgId,
  newTgId,
  currentTgOts,
  newTgOts
) {
  const updatedImpTgOts = Object.keys(newTgOts).reduce((acc, eachStretchId) => {
    const updatedStretchImp = updateTgImpDetails(newTgOts[eachStretchId]);
    acc[eachStretchId] = updatedStretchImp;
    return acc;
  }, {});

  if (currentTgId !== newTgId) {
    return updatedImpTgOts;
  }
  return { ...currentTgOts, ...updatedImpTgOts };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS:
    case ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_CITY:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_SUCCESS:
    case ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        tgSpecificOts: constructTgSpecificOtsObject(
          state.tgId,
          action.tgId,
          state.tgSpecificOts,
          action.data
        ),
        tgId: action.tgId,
      };

    case ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FAILURE ||
      ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_CITY_FAILURE:
      return {
        ...state,
        loading: false,
        err: action.payload,
      };

    case ActionTypes.CampaignPlanner.RESET_TG_SPECIFIC_OTS:
      return initialState;

    // TgSpecificOts for ROAD-SEGMENT
    // ----------------------------------------------------------------------------------
    case ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_SEGMENTS:
      return {
        ...state,
        segmentTgOtsLoading: true,
      };

    case ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_SEGMENTS_SUCCESS:
      return {
        ...state,
        segmentTgOtsLoading: false,
        allSegmentsTgOtsMap: action.payload.allSegmentsTgOtsMap,
      };

    case ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_SEGMENTS_FAILURE:
      return {
        ...state,
        segmentTgOtsLoading: false,
        segmentTgOtsError: action.payload,
      };

    case ActionTypes.CampaignPlanner.CLEAR_TG_SPECIFIC_OTS_FOR_SEGMENTS:
      return {
        ...state,
        allSegmentsTgOtsMap: {},
      };

    default:
      return state;
  }
};
