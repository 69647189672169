/* eslint-disable no-case-declarations */
import { ActionTypes } from "../constants/ActionConstants";

const initialState = {
  loading: false,
  error: "",
  poiList: [],

  // selected pois
  selectedPois: {},

  //pois to show on map view
  activePOI: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PoiNames.GET_POI_NAMES_LIST:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.PoiNames.GET_POI_NAMES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        poiList: action.poiList,
      };

    case ActionTypes.PoiNames.GET_POI_NAMES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // to clear poiNames state
    case ActionTypes.PoiNames.CLEAR_POI_NAMES_LIST:
      return {
        ...state,
        poiList: [],
      };

    // to set-poiNames
    case ActionTypes.PoiNames.STORING_POI_INFO:
      const { poiObj } = action.payload;
      return {
        ...state,
        selectedPois: {
          ...state.selectedPois,
          [poiObj.name]: poiObj.id,
        },
      };

    // to clear poiNames
    case ActionTypes.PoiNames.REMOVE_POI_INFO:
      const activePOI = state.activePOI;
      const { poiName } = action.payload;
      const updatedActivePoi = activePOI.filter(
        (info) => info.type !== poiName
      );
      delete state.selectedPois[poiName];
      return {
        ...state,
        selectedPois: state.selectedPois,
        activePOI: updatedActivePoi,
      };

    //to show selected poi's
    case ActionTypes.Poi.GET_POI_INFO:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.Poi.GET_POI_INFO_SUCCESS:
      return {
        ...state,
        activePOI: [...state.activePOI, ...action.payload],
      };
    case ActionTypes.Poi.GET_POI_INFO_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
