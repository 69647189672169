/**
 * construct Redirect Path
 */
export function constructRedirectPath(path) {
  const productId = process.env.REACT_APP_PRODUCT_ID;
  if (productId) {
    return `/${productId}${path}`;
  }
  return path;
}
