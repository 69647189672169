import { MapViewImage } from "../../constants/action-constants/MapViewImageActionConstants";

export const getMapImage = (id, cityId) => ({
  type: MapViewImage.GET_MAP_VIEW_IMAGE,
  payload: { campaignId: id, cityId },
});

export const downloadMapImage = (imageUrl) => ({
  type: MapViewImage.DOWNLOAD_MAP_IMAGE,
  payload: { imageUrl },
});
