// Urls
import {
  getCampaignCityPerformanceUrl,
  getCampaignPerformanceByIdUrl,
} from "../urls/CampaignPerformanceURL";

// Utils
import { sendGetRequest } from "../../utils/api/apiUtils";

export async function getCampaignCityPerformanceFn(campaignId, cityId) {
  const url = getCampaignCityPerformanceUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);

  return sendGetRequest(url);
}

export async function getCampaignPerformanceByIdFn(campaignId) {
  const url = getCampaignPerformanceByIdUrl.replace(":campaignId", campaignId);
  return sendGetRequest(url);
}
