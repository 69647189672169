import { useEffect, useState } from "react";

// Utils and Constants
import {
  getCoordinates,
  isValidCoordinate,
} from "../../../common-utils/geo-util/coordinatesUtil";
import { DurationOptions } from "../../../constants/GeneralConstants";
import {
  DeviceConstants,
  HourlyTrafficProfileSplit,
} from "./CameraAnalyticToolConstants";

// Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import PlacesAutocomplete from "../../../components/places-autocomplete/PlacesAutocomplete";
import { ButtonWithLoader } from "../../../mavin/components/button/Button";
import { PlainDropdown } from "../../../components/dropdown/Dropdown";
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";
import DurationSelector from "../../../mavin/components/duration-selector/DurationSelector";
import OtsReachAndVehicleSplitSection from "./OtsReachAndVehicleSplitSection";
import { LineChart } from "../../../components/charts/Charts";
import CameraAnalyticRightSectionContent from "./CameraAnalyticRightSectionContent";

// Device And Time Duration Selector
function DeviceAndTimeDurationSelector() {
  const [deviceId, setDeviceId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [duration, setDuration] = useState(null);

  const isButtonDisabled = !deviceId || !duration || !startDate;

  return (
    <div className="d-flex justify-content-between align-items-center pt-3">
      {/* Device */}
      <PlainDropdown
        items={DeviceConstants}
        className="btn shadow-none rounded-lg border"
        placeHolder="Select Device"
        onItemSelect={(id) => setDeviceId(id)}
        dropMenuClassName="dropdown-fixed-height"
      />
      <BootstrapDateRangePicker
        isSingleDatePicker={true}
        initialStartDate={startDate}
        onApplyDates={setStartDate}
        placeHolder={"Select Date"}
        styleObject={{
          autoApply: true,
          border: true,
          buttonClassName: "col-2 px-0",
        }}
      />
      <DurationSelector
        items={DurationOptions}
        onItemSelect={setDuration}
        startDate={startDate}
        initialDays={duration}
        buttonClassName="shadow-none col px-2"
      />
      <ButtonWithLoader
        displayContent="Get Analysis"
        loader={false}
        isDisabled={isButtonDisabled}
        onClick={() => {}}
      />
    </div>
  );
}

/**
 * Camera Analytic Tool Page
 */
export default function CameraAnalyticToolPage() {
  const [locationStr, setLocationStr] = useState("");
  const [coordinate, setCoordinate] = useState([]);

  useEffect(() => {
    // to clear the Coordinates
    if (!locationStr) {
      return setCoordinate([]);
    }
  }, [locationStr]);

  function getCoordinatesFromAutoComplete(locationStr) {
    setLocationStr(locationStr);
    if (isValidCoordinate(locationStr)) {
      setCoordinate(getCoordinates(locationStr));
    }
  }

  return (
    <div className="content-wrapper map-content-wrapper">
      <div className="map-layout-left">
        <PageHeader title={"Camera Analytic"} shadow={false} />
        <hr className="divider my-3 row" />

        {/* Location search */}
        <PlacesAutocomplete
          className="rounded-lg shadow-none"
          placeholder={"Search location OR enter Lat Lng"}
          locationStr={locationStr}
          setLocationStr={getCoordinatesFromAutoComplete}
        />

        {/* Device, Start Time, Duration */}
        <DeviceAndTimeDurationSelector />

        {/* OTS, Reach, Vehicle Split */}
        <OtsReachAndVehicleSplitSection />

        {/* Hourly Traffic Profile */}
        <h4 className="my-3">Hourly Traffic Profile</h4>
        <LineChart data={HourlyTrafficProfileSplit} />
      </div>

      <div className="map-layout-right shadow-none p-3">
        <CameraAnalyticRightSectionContent />
      </div>
    </div>
  );
}
