import { Lts } from "../../constants/action-constants/mavin-tools/LtsActionConstants";

const initialState = {
  // for LTS tool
  // ---------------------------------------------------
  ltsInfo: {},
  ltsError: "",
  ltsInfoLoading: false,
  mediaInfoBean: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Lts.GET_LTS_INFO:
      return {
        ...state,
        ltsInfoLoading: true,
      };

    case Lts.GET_LTS_INFO_SUCCESS:
      return {
        ...state,
        ltsInfoLoading: false,
        ltsInfo: action.payload.ltsInfo,
        mediaInfoBean: action.payload.mediaInfoBean,
      };

    case Lts.GET_LTS_INFO_FAILURE:
      return {
        ...state,
        ltsInfoLoading: false,
        ltsError: action.payload,
      };

    case Lts.RESET_LTS_INFO:
      return initialState;

    default:
      return state;
  }
};
