import React from "react";
import { useSelector } from "react-redux";

// Constants and Utils
import { getSelectedBoundaryLabelAndId } from "./BoundariesUtil";

// Pages and Sections
import BoundariesListingSection from "./BoundariesListingSection";
import SelectedBoundariesSection from "./SelectedBoundariesSection";
import MapTab from "../MapTab";

// constants
const selectedViewLabels = {
  header: {
    title: "Boundaries",
    changeViewBtnText: "Add Boundaries To Map",
  },
  body: {
    emptyText: "Add Boundaries",
    emptyIconCls: "fas fa-border-all",
  },
  search: {
    placeholder: "Search boundaries",
    fields: ["name"],
  },
};

const listingViewLabels = {
  header: {
    title: "Add Boundaries",
    createBtnText: "",
    createBtnUrl: "",
  },
  body: {
    emptyText: "No Boundaries are available.",
  },
  search: {
    placeholder: "Search boundaries",
    fields: ["name"],
  },
};

/**
 * TAB - BOUNDARIES
 */
function BoundariesTab({ cityId }) {
  // Boundaries data list
  const dataList = useSelector((state) => state.boundaries.dataList);

  // Getting Selected Boundary Data
  const selectedBoundary = getSelectedBoundaryLabelAndId(dataList);

  // Function
  // getting content for "selectedViewContent"
  function checkForSelectedViewContent() {
    const parentLabel = selectedBoundary.parentLabel;

    if (!parentLabel || parentLabel === "None") {
      return null;
    }
    return <SelectedBoundariesSection selectedBoundary={selectedBoundary} />;
  }

  // Selected View :: Body
  const selectedViewContent = checkForSelectedViewContent();
  // Listing View :: Body
  const listingViewContent = (
    <BoundariesListingSection dataList={dataList} cityId={cityId} />
  );

  // Tab Rendered Content ---------------------------------
  return (
    <MapTab
      selectedViewLabels={selectedViewLabels}
      selectedViewChildren={selectedViewContent}
      listingViewLabels={listingViewLabels}
      listingViewChildren={listingViewContent}
    />
  );
}

export default BoundariesTab;
