import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

// Actions
import { updateCampaignMediaDuration } from "../../../../actions/campaign-media/CampaignMediaActions";
import { openConfirmationForm } from "../../../../actions/ConfirmationFormActions";
import { openCampaignBudgetExceedForm } from "../../../actions/campaign/CampaignBudgetActions";

// Constants and Utils
import {
  FormDataTargets,
  LocalStorageKeys,
  MediaInventoryOptions,
  MediaSourceTypeStrings,
  PriceModeConstants,
  UrlKeys,
} from "../../../../constants/GeneralConstants";
import { getItem } from "../../../../utils/localstorage";
import { getModifiedPopulation } from "../../../../utils/ReachAndFrequencyUtils";
import {
  getMediaCostForCampaign,
  getTotalCfcForSelectedMedias,
} from "../../../../utils/MediaUtils";

// Urls
import { RedirectTo } from "../../../../urls/PageLinksURL";

// Component
import Spinner from "../../../../components/spinner/Spinner";
import TableHeaders from "../../../../components/table/TableHeaders";
import {
  FrequencyCell,
  ImpressionsCell,
  InfoCell,
  LtsCell,
  ReachCell,
} from "../../../../components/campaign-media-table-row/MediaRow";
import { DurationCell } from "../../../../components/campaign-table-row/CampaignRowCells";
import { OtsAndReachHeader } from "../../../../components/table/OtsAndReachHeader";
import NoTableDataMessage from "../../../../components/table/NoTableDataMessage";

//  Page Constants
const otsHeaderInfo = [
  {
    displayText: "TG Impressions",
    className: "bg-light-green",
    percentage: "25",
  },
  {
    displayText: "Total Impressions",
    className: "bg-light-blue",
    percentage: "100",
  },
];

const reachHeaderInfo = [
  {
    displayText: "TG Reach",
    className: "bg-light-pink",
    percentage: "25",
  },
  {
    displayText: "Total Reach",
    className: "bg-light-yellow",
    percentage: "100",
  },
];

// table headers
const tableHeaders = (cityPopulation) => [
  {
    title: {
      displayName: "Site Name",
      className: "col-4 align-middle",
    },
    subTitle: {
      displayName: "Media type, Dimensions, Lighting, Region",
      className: "",
    },
  },
  {
    title: {
      displayName: "Start and end date",
      className: "col-2 text-right align-middle",
    },
    subTitle: {
      displayName: "Duration",
      className: "text-right",
    },
  },
  {
    element: <OtsAndReachHeader headerInfo={otsHeaderInfo} />,
  },
  {
    element: (
      <OtsAndReachHeader
        headerInfo={reachHeaderInfo}
        subText={`Population: ${getModifiedPopulation(cityPopulation)}`}
      />
    ),
  },
  {
    title: {
      displayName: "Frequency",
      className: "col-1 text-right align-middle",
    },
  },
  {
    title: {
      displayName: "LTS",
      className: "col-1 text-center align-middle",
    },
  },
  {
    title: {
      displayName: "Action",
      className: "col-1 text-center align-middle",
    },
  },
];

function findMediaSiteById(mediaSites, mediaId) {
  return mediaSites.find((mediaSite) => mediaSite.mediaId === mediaId);
}

function MediaSelectionButton({
  campaignId,
  campaignMediaInfo = {},
  selectedMediaSites = [],
  setSelectedMediaSites = () => {},
  campaignMediaSites = [],
}) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const isBudgetExceedFormDisabled = new URLSearchParams(search).get(
    UrlKeys.disableBudgetExceedWarning
  );

  // useState
  const [isSiteAdded, setIsSiteAdded] = useState(false);

  // campaign budget
  const { campaign = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );
  const { budget = 0, spend = 0 } = campaign;

  const { mediaId, pricing = {}, cityId, duration, title } = campaignMediaInfo;
  const costForCampaignPrice = getMediaCostForCampaign(pricing, duration);
  const { price } = pricing || {};

  // campaignMediaBean
  const campaignMediaBean = {
    price: price || 0,
    priceFactor: 1,
    mediaId,
    priceMode: PriceModeConstants.FIXED,
    cityId,
    sellerPrice: costForCampaignPrice,
    mediaTypeStr: MediaSourceTypeStrings.orgMedia,
    title,
  };

  // is campaign media
  const isCampaignMedia = campaignMediaSites.some(
    (media) => media.mediaId === mediaId
  );

  // is media selected for adding
  const isSelectedMediaSite = selectedMediaSites.some(
    (media) => media.mediaId === mediaId
  );

  const selectedMediaSite =
    campaignMediaSites.length > 0 &&
    findMediaSiteById(campaignMediaSites, mediaId);

  // to  unselect or remove media site
  function unSelectMedia() {
    setSelectedMediaSites((prevState) =>
      prevState.filter((media) => media.mediaId !== mediaId)
    );
    setIsSiteAdded(false);
    if (isCampaignMedia) {
      dispatch(
        openConfirmationForm({
          campaignId,
          campaignMediaInfo: selectedMediaSite,
        })
      );
    }
  }

  // to select media site
  function selectMedia() {
    // total cost for campaign of selectedMediaSites
    const totalCfcForSelectedMedias =
      getTotalCfcForSelectedMedias(selectedMediaSites);

    const remainingBudget =
      budget - spend - totalCfcForSelectedMedias - costForCampaignPrice;

    // adding to selected
    if (remainingBudget >= 0 || isBudgetExceedFormDisabled === "true") {
      setIsSiteAdded(true);
      setSelectedMediaSites((prev) => [...prev, campaignMediaBean]);
      return;
    }

    // else show budget exceed warning modal
    dispatch(
      openCampaignBudgetExceedForm({
        campaignMediaBean,
        setIsSiteAdded, // callback function on submit
        remainingBudget,
      })
    );
  }

  const btnClassName = isCampaignMedia
    ? "btn-outline-danger"
    : "btn-outline-success";

  if (isSiteAdded || isCampaignMedia || isSelectedMediaSite) {
    return (
      <td className="col-1 align-middle">
        <button
          type="button"
          className={`btn added-button shadow-none ${btnClassName}`}
          onClick={unSelectMedia}
          data-toggle="modal"
          data-target={`#${FormDataTargets.confirmationForm}`}
        >
          {isCampaignMedia && <b>Remove</b>}
          {!isCampaignMedia && (
            <>
              <i className="fa fa-check mr-1"></i>
              <b>selected</b>
            </>
          )}
        </button>
      </td>
    );
  }

  return (
    <td className="col-1 align-middle">
      <button
        type="button"
        className="btn add-button shadow-none btn-outline-primary"
        onClick={selectMedia}
        data-toggle="modal"
        data-target={`#${FormDataTargets.campaignBudgetExceedModal}`}
      >
        <b>Select</b>
      </button>
    </td>
  );
}

// table body row
function PlanCityViewMediaRow({
  media = {},
  cityPopulation,
  selectedMediaSites = [],
  setSelectedMediaSites = () => {},
  campaignMediaSites = [],
  inventoryToShow,
}) {
  // dispatch
  const dispatch = useDispatch();

  const user = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  const { orgId } = user;

  const { id } = media;

  const { campaign = {}, summary: citySummary = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );
  const {
    startTimestamp: campaignStartTimestamp,
    endTimestamp: campaignEndTimestamp,
    id: campaignId,
  } = campaign;
  const { duration: citySummaryDuration } = citySummary;

  const selectedMediaSite = findMediaSiteById(campaignMediaSites, id);
  media.isSelected = selectedMediaSite ? true : false;
  media.startTimestamp = campaignStartTimestamp;
  media.endTimestamp = campaignEndTimestamp;
  media.duration = citySummaryDuration;

  // TODO::keep this for future need
  // const mediaInfo = selectedMediaSite || media;

  const mediaInfo = media;
  const { startTimestamp, endTimestamp, duration, reachFrequency } = mediaInfo;
  const dateObj = { startTimestamp, endTimestamp };

  if (!media || Object.keys(media).length === 0) {
    return null;
  }

  // function to update date
  function onDateSelect(selectedDate) {
    dispatch(updateCampaignMediaDuration(selectedDate, mediaInfo));
  }

  // Redirect Url
  const campaignMediaViewPageUrl =
    inventoryToShow === MediaInventoryOptions.campaign
      ? RedirectTo.campaignMediaViewPageUrl
          .replace(":campaignId", campaignId)
          .replace(":mediaId", id)
      : RedirectTo.orgMediaDetailPageUrl
          .replace(":orgId", orgId)
          .replace(":mediaId", id);

  // disable duration select for org-inventory
  const isDisableDurationSelect = inventoryToShow === MediaInventoryOptions.org;

  // disabled message
  const unSelectedDisabledMessage = `Please add this media site to campaign and switch to "Campaign Media" tab for duration change.`;
  const selectedDisabledMessage = `Switch to "Campaign Media" tab for duration change.`;
  const isMediaSelected = mediaInfo?.isSelected || false;
  const finalDisabledMessage = isMediaSelected
    ? selectedDisabledMessage
    : unSelectedDisabledMessage;

  // disabled options
  const disabledOptions = {
    isDisabled: isDisableDurationSelect,
    disabledOnClick: () => toast.info(finalDisabledMessage),
    showCalenderIcon: !isDisableDurationSelect,
    showInfoIcon: true,
    disabledIconTooltip: finalDisabledMessage,
  };

  return (
    <tr>
      <InfoCell
        mediaElementStyle={"p-2"}
        media={mediaInfo}
        redirectUrl={campaignMediaViewPageUrl}
      />
      <DurationCell
        dateObj={dateObj}
        durationElementStyle={"p-2 text-right align-middle"}
        onDateSelect={onDateSelect}
        duration={duration}
        disabledOptions={isDisableDurationSelect && disabledOptions}
      />
      <ImpressionsCell
        impressionsElementStyle={"p-2 text-right align-middle"}
        media={mediaInfo}
      />
      <ReachCell
        reachElementStyle={"p-2 text-right align-middle"}
        reachFrequency={reachFrequency}
        cityPopulation={cityPopulation}
        showPopulation={false}
      />
      <FrequencyCell
        frequencyElementClass={"p-2 text-right align-middle"}
        reachFrequency={reachFrequency}
      />
      <LtsCell ltsElementClass={"p-2 text-center align-middle"} media={media} />
      <MediaSelectionButton
        campaignId={campaignId}
        campaignMediaInfo={mediaInfo}
        selectedMediaSites={selectedMediaSites}
        setSelectedMediaSites={setSelectedMediaSites}
        campaignMediaSites={campaignMediaSites}
      />
    </tr>
  );
}

// body
function PlanCityViewMediaBody({
  mediaList = [],
  cityPopulation,
  campaignMediaSites = [],
  selectedMediaSites = [],
  setSelectedMediaSites = () => {},
  inventoryToShow,
}) {
  const isMediaSitesPresent = mediaList.length > 0;

  if (!isMediaSitesPresent) {
    return (
      <NoTableDataMessage
        colSpan={7}
        message="City don't have any media sites"
      />
    );
  }

  return (
    <>
      {mediaList.map((media) => (
        <PlanCityViewMediaRow
          media={media}
          key={media.id}
          cityPopulation={cityPopulation}
          selectedMediaSites={selectedMediaSites}
          setSelectedMediaSites={setSelectedMediaSites}
          campaignMediaSites={campaignMediaSites}
          inventoryToShow={inventoryToShow}
        />
      ))}
    </>
  );
}

// Table
function PlanCityViewMediaTable({
  mediaList = [],
  selectedMediaSites = [],
  setSelectedMediaSites = () => {},
  campaignMediaSites = [],
  inventoryToShow,
}) {
  const { summary } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );
  const { population: cityPopulation } = summary || {};

  // explore site loading
  const loading = useSelector((state) => state.mapViewExploreSites.loading);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="table-responsive mt-3">
      <table className="table table-media">
        {/* Table header */}
        <TableHeaders
          tableHeaders={tableHeaders(cityPopulation)}
          headerClass={"thead"}
        />

        <tbody>
          <PlanCityViewMediaBody
            mediaList={mediaList}
            cityPopulation={cityPopulation}
            campaignMediaSites={campaignMediaSites}
            selectedMediaSites={selectedMediaSites}
            setSelectedMediaSites={setSelectedMediaSites}
            inventoryToShow={inventoryToShow}
          />
        </tbody>
      </table>
    </div>
  );
}

export default PlanCityViewMediaTable;
