/* eslint-disable no-case-declarations */
import { ActionTypes } from "../../../constants/ActionConstants";

const initialState = {
  // campaign(overview)-performance
  campaignPerformance: null,
  campaignPerformanceLoading: false,
  campaignPerformanceError: "",

  // city performance details
  campaignCityPerformanceLoading: false,
  campaignCityPerformanceErr: "",
  campaignCityOtsLogSummary: "",
  campaignMediaOtsLogSummary: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    // campaign(overview)-performance --------------------------------------------
    case ActionTypes.CampaignPerformance.GET_CAMPAIGN_PERFORMANCE_BY_ID: {
      return {
        ...state,
        campaignPerformanceLoading: true,
      };
    }

    case ActionTypes.CampaignPerformance
      .GET_CAMPAIGN_PERFORMANCE_BY_ID_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        campaignPerformanceLoading: false,
        campaignPerformance: data.otsLogSummary,
      };
    }

    case ActionTypes.CampaignPerformance
      .GET_CAMPAIGN_PERFORMANCE_BY_ID_FAILURE: {
      return {
        ...state,
        campaignPerformanceLoading: false,
        campaignPerformanceError: action.payload,
      };
    }

    // city performance details-------------------------------------------------------------
    case ActionTypes.CampaignPerformance.GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID:
      return {
        ...state,
        campaignCityPerformanceLoading: true,
      };

    case ActionTypes.CampaignPerformance
      .GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID_SUCCESS:
      const { cityOtsLogSummary, mediaOtsLogSummary } =
        action.payload.cityPerformance;
      return {
        ...state,
        campaignCityPerformanceLoading: false,
        campaignCityOtsLogSummary: cityOtsLogSummary,
        campaignMediaOtsLogSummary: mediaOtsLogSummary,
      };

    case ActionTypes.CampaignPerformance
      .GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID_FAILURE:
      return {
        ...state,
        campaignCityPerformanceLoading: false,
        campaignCityPerformanceErr: action.payload,
      };

    default:
      return state;
  }
};
