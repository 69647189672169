// Urls
import { getSingleSiteAnalyserInfoUrl } from "../../urls/mavin-tools/SingleSiteAnalyserToolURL";

//Utils
import { sendCreateOrUpdateRequest } from "../../utils/api/apiUtils";

// Getting full summary for Single Site Analyser Tool
export async function getSingleSiteAnalyserInfoFn(mediaInfoBean) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(
    getSingleSiteAnalyserInfoUrl,
    mediaInfoBean,
    true,
    "POST",
    headerParams
  );
}
