import { formatText } from "../../../common-utils/string-utils/StringUtils";

function distance(point, latInDegrees, lonInDegrees) {
  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  const lon1 = (point.center.longitude * Math.PI) / 180;
  const lat1 = (point.center.latitude * Math.PI) / 180;
  const lat2 = (latInDegrees * Math.PI) / 180;
  const lon2 = (lonInDegrees * Math.PI) / 180;

  // Haversine formula
  const dlon = lon2 - lon1;
  const dlat = lat2 - lat1;
  const a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

  const c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  const r = 6371;

  // calculate the result
  return c * r;
}

/**
 *
 * @param   sortedPoints=>array of objects
 * @param   type=>string
 * @returns a string which is name of the landmark
 */
function findNearByLandmarks(sortedPoints, type) {
  // filtering the sortedPoints to get the required type of landmark we need(eg:college,supermarket,etc)
  // and then finding the  object containing name field
  const requiredData = sortedPoints
    .filter((point) => point.type === type)
    .find((point) => point?.name);

  return formatText(requiredData?.name);
}

/**
 * Landmarks Component
 */
export default function Landmarks({
  className = "",
  points = [],
  latitude,
  longitude,
}) {
  if (points.length === 0) {
    return null;
  }

  const sortedPoints = points.sort((a, b) => {
    const distanceA = distance(a, latitude, longitude);
    const distanceB = distance(b, latitude, longitude);
    if (distanceA > distanceB) {
      return 1;
    }
    return -1;
  });
  return (
    <div className={`landmarks ${className}`}>
      <p>
        <strong>Landmarks nearby</strong>
      </p>
      <p>Colleges: {findNearByLandmarks(sortedPoints, "college")}</p>
      <p>Supermarkets: {findNearByLandmarks(sortedPoints, "supermarket")}</p>
      <p>Apartment complexes: {findNearByLandmarks(sortedPoints, "society")}</p>
      <p>School: {findNearByLandmarks(sortedPoints, "school")}</p>
      <p>Mall: {findNearByLandmarks(sortedPoints, "mall")}</p>
      <p>Hospital:{findNearByLandmarks(sortedPoints, "hospital")}</p>
    </div>
  );
}
