/**
 * This function is used to replace the city-ids of
 * a) ==> city-delhi-ncr to city-delhi
 * b) ==> city-greater-mumbai to city-mumbai
 * to support the functionality from Mavin-v2
 * @param {*} cityId
 * @returns
 */
export function getMergedCityId(cityId) {
  if (cityId === "city-delhi-ncr") {
    return "city-delhi";
  }

  if (cityId === "city-greater-mumbai") {
    return "city-mumbai";
  }

  return cityId;
}

/**
 * * This function is used to replace the BBOX of
 * a) ==> city-delhi-ncr to city-delhi
 * b) ==> city-greater-mumbai to city-mumbai
 * to support the functionality from Mavin-v2
 * @param {*} cityId
 * @param {*} originalBBOX
 * @returns
 */
export function getMergedCityBBox(cityId, originalBBOX) {
  if (cityId === "city-delhi-ncr") {
    return "76.79787008411716,28.070049108329755,77.62595804088282,28.93492752393108";
  }

  if (cityId === "city-greater-mumbai") {
    return "72.7740694508,18.8784750477,73.0044390187,19.3205006624";
  }

  return originalBBOX;
}
