import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

// Utils and Constants
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import {
  getFrequencyValue,
  getModifiedPopulation,
  getReachPercentage,
  getReachValueAsString,
} from "../../../utils/ReachAndFrequencyUtils";
import { getOtsTotal } from "../../../utils/mavinMetrics";
import { UrlKeys } from "../../../constants/GeneralConstants";
import { useUrlKeyCheck } from "../../utils/hooks/HooksUtil";
import { calculatePercentage } from "../../../common-utils/number-utils/NumberUtils";
import { getIsSubscriptionActive } from "../../../common-utils/subscription-utils/SubscriptionUtil";

// Components
import { getLtsRatingAvg } from "../../../components/campaign-table-row/CampaignRowCells";
import { ProgressBar } from "../../../components/progress-bar/ProgressBar";

// Section Components
function ImpressionsCardContent({ tgOtsTotal, tgPercentage, otsTotal }) {
  return (
    <>
      <p className="mb-2 mt-0 d-flex align-items-center">
        <span className="d-inline-block border border-dark mr-2 square bg-light-green"></span>
        TG : {formatText(toLocaleString(tgOtsTotal))}
        {/* {`(${tgPercentage}%)`}  */}
      </p>
      <p className="mb-2 mt-0 d-flex align-items-center">
        <span className="d-inline-block border border-dark mr-2 square bg-light-blue"></span>
        Total : {formatText(toLocaleString(otsTotal))}
      </p>

      {parseInt(otsTotal) > 0 && (
        <>
          <ProgressBar className="bg-light-green" percentage={tgPercentage} />
          <ProgressBar className="bg-light-blue" percentage={100} />
        </>
      )}
    </>
  );
}

function ReachCardContent({
  tgReach,
  // finalTgReachPercent,
  reach,
  // reachPercentage,
  tgReachPercent,
}) {
  return (
    <>
      <p className="mb-2 mt-0 d-flex align-items-center">
        <span className="d-inline-block border border-dark mr-2 square bg-light-pink"></span>
        TG : {formatText(tgReach)}
        {/* {finalTgReachPercent} */}
      </p>
      <p className="mb-2 mt-0 d-flex align-items-center">
        <span className="d-inline-block border border-dark mr-2 square bg-light-yellow"></span>
        Total : {formatText(reach)}
        {/* {reachPercentage} */}
      </p>

      {parseInt(reach) > 0 && (
        <>
          <ProgressBar className="bg-light-pink" percentage={tgReachPercent} />
          <ProgressBar className="bg-light-yellow" percentage={100} />
        </>
      )}
    </>
  );
}

function HeadCards({ item }) {
  const { title, text, component } = item;
  return (
    <div className="col">
      <div className="card h-100">
        <div className="card-body">
          <h4 className="card-title">{title}</h4>
          {component ? component : <p className="card-text">{text}</p>}
        </div>
      </div>
    </div>
  );
}

/**
 * @param {*} citySummary
 * @returns City Specific Performance
 */
export function CampaignCityPerformance({
  citySummary = {},
  budget = 0,
  spend = 0,
}) {
  const history = useHistory();
  const { search } = useLocation();
  const isBudgetExceedFormDisabled = new URLSearchParams(search).get(
    UrlKeys.disableBudgetExceedWarning
  );

  // data
  const { ltsRatingAvg, reachFrequency, population } = citySummary;
  const { otsTotal, tgOtsTotal } = getOtsTotal(citySummary) || {};
  const ltsAvg = getLtsRatingAvg(ltsRatingAvg);

  // Reach And Frequency
  // original reach, tgReach
  const { reach, tgReach } = reachFrequency || {};
  const tgReachPercentWithBaseReach = calculatePercentage(
    Number(tgReach),
    Number(reach)
  );

  // as string
  const { reach: reachString, tgReach: tgReachString } =
    getReachValueAsString(reachFrequency);
  const reachPercentage = getReachPercentage(population, reachFrequency);
  const frequencyValue = getFrequencyValue(reachFrequency);
  const { tgReachPercent } = reachFrequency || {};

  const tgPercentage = calculatePercentage(
    Number(tgOtsTotal),
    Number(otsTotal)
  );

  // Checking Org is Subscribed or Not and is Org Type is Seller
  const isSubscribed = getIsSubscriptionActive();

  // If not subscribed showing * otherwise
  // showing reach percent with two decimals
  const finalTgReachPercent = !isSubscribed
    ? `(*%)`
    : tgReachPercent
    ? `(${tgReachPercent?.toFixed(2)}%)`
    : "";

  const headCardsData = [
    {
      title: "No. of Sites",
      text: `${formatText(citySummary.mediaCount)}`,
    },
    {
      title: "Impressions",
      component: (
        <ImpressionsCardContent
          tgOtsTotal={tgOtsTotal}
          tgPercentage={tgPercentage}
          otsTotal={otsTotal}
        />
      ),
    },
    {
      title: "Population",
      text: getModifiedPopulation(population),
    },
    {
      title: "Reach",
      component: (
        <ReachCardContent
          tgReach={tgReachString}
          finalTgReachPercent={finalTgReachPercent}
          reach={reachString}
          reachPercentage={reachPercentage}
          tgReachPercent={tgReachPercentWithBaseReach}
        />
      ),
    },
    {
      title: "Frequency",
      text: frequencyValue,
    },
    {
      title: "Avg LTS rating",
      text: `${formatText(ltsAvg)}`,
    },
  ];

  const { mantaraySpecific } = UrlKeys;
  const isMantaraySpecific = useUrlKeyCheck(mantaraySpecific);
  const totalBudget = budget ? toLocaleString(budget) : 0;
  const usedBudget = spend ? toLocaleString(Math.floor(spend)) : 0;
  const remainingBudget = budget - spend;
  const remainingBudgetFormatted = remainingBudget
    ? toLocaleString(Math.ceil(remainingBudget))
    : 0;

  // remaining budget className
  const remainingBudgetClassName =
    budget < spend ? "text-danger" : "text-success";

  isMantaraySpecific &&
    headCardsData.push({
      title: "Budget",
      component: (
        <>
          <p className="mb-1">Total : {totalBudget}</p>
          <p className="mb-1">Used : {usedBudget}</p>
          <p className="mb-0">
            Remaining :{" "}
            <span className={remainingBudgetClassName}>
              {remainingBudgetFormatted}
            </span>
          </p>
        </>
      ),
    });

  // enable budget warning
  function handleEnableBudgetWarning() {
    history.replace({ search: `` });
    toast.success("Budget Warning Enabled");
  }

  return (
    <>
      <div className="d-flex justify-content-between items-center">
        <h4 className="sec-title">City Performance</h4>

        {/* Enable Budget Warning */}
        {isMantaraySpecific && isBudgetExceedFormDisabled === "true" && (
          <span
            className="text-primary cursor-pointer"
            onClick={handleEnableBudgetWarning}
          >
            Enable Budget Warning
          </span>
        )}
      </div>
      <div className="row row-gap-6">
        {/* Card */}
        {headCardsData.map((item, index) => (
          <HeadCards key={index} item={item} />
        ))}
      </div>
    </>
  );
}
