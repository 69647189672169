/**
 * Default Top Header Branding for all private/public pages
 */
import React from "react";
import { Link } from "react-router-dom";

// Theme
import AppThemes from "../../theme/AppThemes";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Constants and Utils
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";

// Component Definition
// ----------------------------------------------------------------------------

/**
 * Header :: Branding
 */
const NavbarBranding = () => {
  // Theme Info
  const theme = AppThemes.getTheme();
  const homeUrl = RedirectTo.homeUrl;

  return (
    <div className="main-branding">
      <Link className="navbar-brand" to={constructRedirectPath(homeUrl)} />
    </div>
  );
};

export default NavbarBranding;
