import { SaveMapState } from "../../constants/action-constants/map-view/SaveMapStateActionConstants";

export const storeMapDetails = (mapContainer, center, zoom) => ({
  type: SaveMapState.Save.SAVE_MAP_CONTAINER,
  payload: { mapContainer, center, zoom },
});

export const saveMapDetails = (campaignId, cityId, saveImage = true) => ({
  type: SaveMapState.Save.SAVE_MAP_STATE,
  payload: { campaignId, cityId, saveImage },
});

export const restoreMapSavedState = (campaignId, cityId) => ({
  type: SaveMapState.Restore.RESTORE_SAVE_MAP_STATE,
  payload: { campaignId, cityId },
});
