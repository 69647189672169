import {
  archiveTargetGroupUrl,
  createTargetGroupUrl,
  getTargetGroupListUrl,
  getTargetGroupUrl,
  restoreTargetGroupUrl,
  targetGroupAttributesUrl,
  updateTargetGroupUrl,
} from "../urls/OrgTargetGroupURL";
import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

export async function getTargetGroupsList(
  includeDefault,
  keyWord,
  pageNumber,
  pageSize,
  excludeArchive
) {
  const params = {
    inclDefault: includeDefault,
    pn: pageNumber,
    ps: pageSize,
  };

  // Here excludeArchive == true ==> Meaning we need only
  // Tg's which are not archived. When we make a request we
  // say "_ar=flase" which would give the results with out
  // archived one..
  if (excludeArchive) {
    params._ar = !excludeArchive;
  }

  if (keyWord) {
    params._kw = keyWord;
  }

  return sendGetRequest(getTargetGroupListUrl, params, true);
}

export async function getTargetGroupAttributes() {
  return sendGetRequest(targetGroupAttributesUrl);
}

// create TG
export async function createTargetGroup(targetGroupBean) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  return sendCreateOrUpdateRequest(
    createTargetGroupUrl,
    targetGroupBean,
    true,
    "POST",
    headerParams
  );
}

// Update TG
export async function updateTargetGroup(targetGroupBean, tgId) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  return sendCreateOrUpdateRequest(
    updateTargetGroupUrl.replace(":tgId", tgId),
    targetGroupBean,
    true,
    "PUT",
    headerParams
  );
}

export async function getTg(tgId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getTargetGroupUrl.replace(":tgId", tgId);

  return sendGetRequest(url, {}, true, headerParams);
}

export async function archiveTargetGroup(tgId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = archiveTargetGroupUrl.replace(":tgId", tgId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT", headerParams);
}

export async function restoreTargetGroup(tgId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = restoreTargetGroupUrl.replace(":tgId", tgId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT", headerParams);
}
