// Utils and Constants
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import {
  OtsData,
  ReachData,
  VehicularData,
  VehicularSplitPieChartData,
} from "./CameraAnalyticToolConstants";

// Components
import { PieChart } from "../../../components/charts/Charts";

// Icon with Metrics
function MetricWithIcon({
  data = {},
  containerClassName = "",
  firstMetricClassName = "",
}) {
  const {
    firstValue,
    firstIcon,
    firstLabel,
    secondValue,
    secondIcon,
    secondLabel,
  } = data;

  // formatted values
  const formattedFirstValue = formatText(toLocaleString(firstValue));
  const formattedSecondValue = formatText(toLocaleString(secondValue));

  return (
    <div className={`d-flex ${containerClassName}`}>
      <p className={`col-6 mb-0 text-truncate ${firstMetricClassName}`}>
        <i className={`fas ${firstIcon} fa-2x mr-3`} />
        <span title={formattedFirstValue}>{formattedFirstValue}</span>
        {firstLabel && <p className="mb-0">{firstLabel}</p>}
      </p>
      <p className="col-6 mb-0 text-truncate border-left border-dark">
        <i className={`fas ${secondIcon} fa-2x mr-3`} />
        <span title={formattedSecondValue}>{formattedSecondValue}</span>
        {secondLabel && <p className="mb-0">{secondLabel}</p>}
      </p>
    </div>
  );
}

/**
 * Ots Reach And Vehicle Split Section
 */
export default function OtsReachAndVehicleSplitSection() {
  const otsSplit = {
    firstValue: OtsData.male,
    firstIcon: "fa-male text-primary",
    secondValue: OtsData.female,
    secondIcon: "fa-female text-danger",
  };

  const reachSplit = {
    firstValue: ReachData.male,
    firstIcon: "fa-male text-primary",
    secondValue: ReachData.female,
    secondIcon: "fa-female text-danger",
  };

  const busAndFourWheelerSplit = {
    firstValue: VehicularData.fourWheelers,
    firstIcon: "fa-car",
    firstLabel: "4W",
    secondValue: VehicularData.buses,
    secondIcon: "fa-bus",
    secondLabel: "Bus",
  };

  const pedestriansAndTwoWheelerSplit = {
    firstValue: VehicularData.twoWheelers,
    firstIcon: "fa-biking",
    firstLabel: "2W",
    secondValue: VehicularData.pedestrians,
    secondIcon: "fa-walking",
    secondLabel: "Pedestrians",
  };

  return (
    <>
      <hr className="my-3" />

      {/* Ots and Reach */}
      <div className="d-flex align-items-center">
        <div className="w-50 border rounded-xl p-3 mr-3">
          <h5 className="mb-3">OTS</h5>
          <MetricWithIcon data={otsSplit} />
        </div>

        <div className="w-50 border rounded-xl p-3">
          <h5 className="mb-3">Reach</h5>
          <MetricWithIcon data={reachSplit} />
        </div>
      </div>

      {/* Vehicle Split */}
      <div className="d-flex mt-3">
        <div className="w-100 border rounded-xl p-3">
          <h5 className="mb-3">Vehicles</h5>
          <div className="d-flex align-items-center">
            <MetricWithIcon
              data={busAndFourWheelerSplit}
              containerClassName="col-6"
            />
            <MetricWithIcon
              data={pedestriansAndTwoWheelerSplit}
              containerClassName="col-6"
              firstMetricClassName="border-left border-dark"
            />
          </div>

          <div className="text-center">
            <PieChart
              data={VehicularSplitPieChartData}
              height={325}
              showCustomToolTip={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
