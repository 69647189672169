//Components
import Carousel from "../../../components/carousel/Carousel";
import Spinner from "../../../components/spinner/Spinner";
import { CustomButton } from "../button/Button";

// Constants
const UPLOAD_IMAGES = "Upload Images";

// components
function UploadImage({ onUploadClick }) {
  return (
    <>
      <i className="far fa-images fa-10x text-primary opacity-25"></i>
      <p className="mb-5">
        <strong>No site image available</strong>
      </p>
      <CustomButton
        displayContent={UPLOAD_IMAGES}
        onClickFunction={onUploadClick}
      />
    </>
  );
}

function MediaImageSection({
  urls,
  selectAndUpload,
  isLoading,
  canDownloadImage,
}) {
  if (isLoading) {
    return <Spinner />;
  }
  if (urls.length) {
    return <Carousel urls={urls} canDownloadImage={canDownloadImage} />;
  }
  return <UploadImage onUploadClick={selectAndUpload} />;
}

// Component
export default function ImageUpload({
  fileItems,
  isLoading,
  selectAndUpload,
  canDownloadImage,
}) {
  const urls =
    fileItems && fileItems.length
      ? fileItems.map((fileItem) => fileItem.originalUrl)
      : [];

  return (
    <>
      <div className="image-container">
        {/* Media image selection */}
        <MediaImageSection
          urls={urls}
          selectAndUpload={selectAndUpload}
          isLoading={isLoading}
          canDownloadImage={canDownloadImage}
        />
      </div>

      {/* upload button */}
      {urls.length > 0 && (
        <div className="p-3">
          <CustomButton
            displayContent={UPLOAD_IMAGES}
            onClickFunction={selectAndUpload}
          />
        </div>
      )}
    </>
  );
}
