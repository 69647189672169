import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Api
import { getRouteSummaryFn } from "../../apis/mavin-tools/RouteAnalysisToolAPI";

// Utils and Constants
import { getErrorMessage } from "../../utils/util";
import { RouteAnalysis } from "../../constants/action-constants/mavin-tools/RouteAnalysisActionConstants";

function* getRouteSummary(action) {
  const { stretchId, targetGroupId, startTS, duration } = action.payload;
  try {
    const routeInfo = yield getRouteSummaryFn(
      stretchId,
      targetGroupId,
      startTS,
      duration
    );

    yield put({
      type: RouteAnalysis.GET_ROUTE_SUMMARY_SUCCESS,
      payload: { routeInfo: routeInfo },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put({
      type: RouteAnalysis.GET_ROUTE_SUMMARY_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(RouteAnalysis.GET_ROUTE_SUMMARY, getRouteSummary)]);
}
