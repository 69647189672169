export const LocationOfIntrest = {
  // Hide/Show Influence Circle marker
  ADD_LOCATION_OF_INTREST_INFLUENCE_CIRCLE:
    "ADD_LOCATION_OF_INTREST_INFLUENCE_CIRCLE",
  REMOVE_LOCATION_OF_INTREST_INFLUENCE_CIRCLE:
    "REMOVE_LOCATION_OF_INTREST_INFLUENCE_CIRCLE",

  // set/reset location of intrest influnce
  SET_LOCATION_OF_INTREST_INFLUENCE_CIRCLE_RADIUS:
    "SET_LOCATION_OF_INTREST_INFLUENCE_CIRCLE_RADIUS",
  RESET_LOCATION_OF_INTREST_INFLUENCE_CIRCLE_RADIUS:
    "RESET_LOCATION_OF_INTREST_INFLUENCE_CIRCLE_RADIUS",

  // open/close location of intrest modal
  OPEN_LOCATION_OF_INTREST_MODAL: "OPEN_LOCATION_OF_INTREST_MODAL",
  CLOSE_LOCATION_OF_INTREST_MODAL: "CLOSE_LOCATION_OF_INTREST_MODAL",
};
