//Constants
import {
  MapExploreSites,
  MediaSites,
} from "../../../constants/action-constants/map-view/media-sites/MediaSitesActionConstants";

export const getMapViewMediaSites = (
  id,
  cityId,
  pageNumber,
  pageSize,
  applyFilters
) => ({
  type: MediaSites.GET_MEDIA_SITES,
  payload: {
    id,
    cityId,
    pn: pageNumber,
    ps: pageSize,
    applyFilters,
  },
});

export const mapExploreSites = (campaignId, mediaIds) => ({
  type: MapExploreSites.MAP_EXPLORE_SITES,
  payload: { campaignId, mediaIds },
});

// to remove media site from campaign
export const removeMediaSite = (campaignId, campaignMediaInfo) => ({
  type: MediaSites.REMOVE_MEDIA_SITE,
  payload: { campaignId, campaignMediaInfo },
});

// to set Campaign media site Fill Color
export const setCampaignMediaFillColor = (color) => ({
  type: MediaSites.SET_CAMPAIGN_MEDIA_FILL_COLOR,
  payload: { color },
});

// to set FILTER CampaignMedia
export const setCampaignMediaAppliedFilters = (appliedFiltersObj) => ({
  type: MediaSites.SET_CAMPAIGN_MEDIA_FILTERS,
  payload: { appliedFiltersObj },
});

// Reset the state..
export const resetMediaSites = () => ({
  type: MediaSites.RESET,
});
