import React from "react";

/**
 * HAMBURGER COMPONENT
 */
function Hamburger(props) {
  return (
    <div className="dropdown inline">
      <button
        className="btn shadow-none"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="fas fa-ellipsis-v"></i>
      </button>
      <div
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton"
      >
        {props.children}
      </div>
    </div>
  );
}

export default Hamburger;
