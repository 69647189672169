import { AlertForm } from "../constants/action-constants/AlertModalActionConstants";

const initialState = {
  // Alert Modal
  // ---------------------------------------------------------------
  openModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ALERT Modal
    // ---------------------------------------------------------------
    case AlertForm.OPEN_ALERT_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case AlertForm.CLOSE_ALERT_MODAL:
      return {
        ...state,
        openModal: false,
      };

    default:
      return state;
  }
};
