import { ActionTypes } from "../../constants/ActionConstants";

//initial state
const initialState = {
  // campaign performance details
  camListLoading: false,
  camListErr: "",
  camList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CampaignDetails.GET_CAMPAIGN_LIST:
      return {
        ...state,
        camListLoading: true,
      };

    case ActionTypes.CampaignDetails.GET_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        camListLoading: false,
        camList: action.payload.campaignList,
      };

    case ActionTypes.CampaignDetails.GET_CAMPAIGN_LIST_FAILURE:
      return {
        ...state,
        camListLoading: false,
        camListErr: action.payload,
      };

    default:
      return state;
  }
};
