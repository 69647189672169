//Utils
import { downloadFile, sendGetRequest } from "./api/apiUtils";

// URLS
import { restoreMapStateUrl } from "../urls/map-view/SaveMapStateURL";

// TODO : Once the API for fetching mapViewImage changes, refactor this
export async function getMapImage(campaignId, cityId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = restoreMapStateUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);
  return sendGetRequest(url, {}, true, headerParams);
}

export async function downloadImage(imageUrl) {
  // adding dummy text at end to avoid CORS error in chromium based browsers
  // https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
  const url = imageUrl + "?not-from-cache";
  return downloadFile(url, false);
}
