import { RoadStretch } from "../../constants/action-constants/RoadStretchActionConstants";

export const addSelectedRoadStretch = (stretchId) => ({
  type: RoadStretch.ADD_SELECTED_ROAD_STRETCH,
  payload: { stretchId },
});

export const removeSelectedRoadStretch = (stretchId) => ({
  type: RoadStretch.REMOVE_SELECTED_ROAD_STRETCH,
  payload: { stretchId },
});

export const resetSelectedRoadStretches = () => ({
  type: RoadStretch.RESET_SELECTED_ROAD_STRETCHES_IN_MAP_VIEW,
});
