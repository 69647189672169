import { Link } from "react-router-dom";

// Utils
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";

// CSS
import "./Breadcrumb.scss";

export default function Breadcrumb({ items }) {
  const links = items.map((item, index) => {
    return (
      <li key={index} className="breadcrumb-item">
        {item.to ? (
          <Link
            className="breadcrumb__text"
            to={constructRedirectPath(item.to)}
          >
            {item.label}
          </Link>
        ) : (
          <span className="breadcrumb__text">{item.label}</span>
        )}
      </li>
    );
  });
  return (
    <nav aria-label="breadcrumb" data-html2canvas-ignore="true">
      <ol className="breadcrumb__container">{links}</ol>
    </nav>
  );
}
