import React from "react";
import { useDispatch, useSelector } from "react-redux";

//Actions
import {
  closeMonitoringMediaLogImageModal,
  downloadMonitoringMediaLogImage,
} from "../../../actions/campaign-monitoring/MonitoringLogActions";

// Constants
import { FormDataTargets } from "../../../constants/GeneralConstants";

// Components
import { CustomButton } from "../../../mavin/components/button/Button";
import Carousel from "../../../components/carousel/Carousel";

function getOriginalUrls(dayLog) {
  const urls = [];
  dayLog.forEach((log) => {
    log.images.forEach((item) => {
      if (item.originalUrl) {
        urls.push(item.originalUrl);
      }
    });
  });
  return urls;
}

/**
 * To download and show the  monitoring media log image in modal
 * @param {*} dayLog =>day log array
 */
export default function MonitoringMediaLogImageModal({ dayLog = [] }) {
  const dispatch = useDispatch();
  const originalUrls = getOriginalUrls(dayLog);

  // Selector state
  const isImageDownloading = useSelector(
    (state) => state.monitoringLog.downloadMonitoringMediaLogImage
  );

  const handleDownloadImage = (imageUrl) => {
    dispatch(downloadMonitoringMediaLogImage(imageUrl));
  };

  return (
    <div
      className="modal fade"
      id={FormDataTargets.mediaLogImageForm}
      data-backdrop="static"
      role="dialog"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          {/* modal  header */}
          <div className="modal-header bg-alt">
            <h5 className="modal-title">Media Log Image</h5>
          </div>

          {/* modal body  */}
          <div className="modal-body">
            <Carousel
              urls={originalUrls}
              imageClassName="media-log-image"
              canDownloadImage={true}
              onClickFunction={handleDownloadImage}
              isImageDownloading={isImageDownloading}
            />
          </div>

          {/* modal footer */}
          <div className="modal-footer">
            <CustomButton
              onClickFunction={() => {
                dispatch(closeMonitoringMediaLogImageModal());
              }}
              data-dismiss="modal"
              displayContent="Close"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
