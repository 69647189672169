import { useSelector } from "react-redux";
import { Circle, Marker } from "react-leaflet";

// Components
import MapIcon from "../../../../components/map/map-icon/MapIcon";

export default function LocationOfIntrestMarker({ position }) {
  // selector
  const locationOfIntrestInfluenceCircleRadius = useSelector(
    (state) => state.locationOfIntrest.locationOfIntrestInfluenceCircleRadius
  );
  const IsLocationOfIntrestInfluenceCircleVisible = useSelector(
    (state) => state.locationOfIntrest.IsLocationOfIntrestInfluenceCircleVisible
  );

  // map icon
  const icon = new MapIcon({ type: "selected-poi" });

  return (
    <>
      <Marker position={position} icon={icon} />
      {IsLocationOfIntrestInfluenceCircleVisible && (
        <Circle
          center={position}
          pathOptions={{ color: "yellow", fillColor: "green" }}
          radius={locationOfIntrestInfluenceCircleRadius}
        />
      )}
    </>
  );
}
