import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// Actions
import {
  resetSiteUploadState,
  uploadTemplate,
} from "../../../actions/SiteUploadActions";
import { downloadTemplate } from "../../../actions/SiteUploadActions";

// Constants and Utils
import { selectFile } from "../../../common-utils/file-utils/FileUtils";
import { DispatchFrom } from "../../../constants/GeneralConstants";

// Components
import { CloseSiteUpload } from "../../../components/site-upload-utils/Utils";
import DownloadTemplateCard from "../../../components/site-upload-utils/DownloadTemplateCard";
import GenerateTemplateCard from "../../../components/site-upload-utils/GenerateTemplateCard";
import UploadTemplateCard from "../../../components/site-upload-utils/UploadTemplateCard";

// Page-Constants
const monitoringSiteUpload = {
  DownloadTemplateData: {
    step: "STEP 1",
    title: "Download and fill template",
    message: "If you already have a filled site template jump to step 2.",
    buttonLabel: "Download Template",
  },
  UploadSiteListData: {
    step: "STEP 2",
    title: "Upload site monitoring template",
    message:
      "Please upload using the template format recommended to avoid errors.",
    buttonLabel: "Upload Template",
  },
  GenerateTemplateData: {
    step: "STEP 3",
    title: "Setup monitoring",
    message:
      "We automatically create a report for you that can be modified as needed.",
    buttonLabel: "Setup monitoring",
  },
};

// Page-Components
function PageHeaderSection({ campaignId, cityId }) {
  const pageFrom = DispatchFrom.campaignMonitoring;

  return (
    <div className="page-header d-flex justify-content-between bg-alt">
      <h1 className="title">{"Upload media sites via Excel to monitor"}</h1>
      <p className="mr-1">
        <CloseSiteUpload
          campaignId={campaignId}
          cityId={cityId}
          pageFrom={pageFrom}
        />
      </p>
    </div>
  );
}

// getting campaignId and cityId ==> for adding more media
// by excel-file upload from city-summary page in same campaign
function getCampaignAndCityIds(location) {
  const { state = {} } = location;
  const { campaignId = "", cityId = "" } = state;
  return { campaignId, cityId };
}

/**
 * page
 */
function MonitoringCampaignCreatePage() {
  const dispatch = useDispatch();
  const location = useLocation();

  // Selector States
  // from uploaded-template
  const campaignData =
    useSelector((state) => state.c_siteUpload.campaignData) || {};
  const mediaCount = useSelector((state) => state.c_siteUpload.mediaCount);

  const templateDownloading = useSelector(
    (state) => state.c_siteUpload.downloadLoading
  );

  // Dispatch
  useEffect(() => {
    dispatch(resetSiteUploadState());
  }, [dispatch]);

  // getting Ids for add media from campaign-context
  const { campaignId, cityId } = getCampaignAndCityIds(location);

  // downloaded template fileName and link to Redirect on Summary-page
  const fileName = "monitor_campaign_template.xlsx";
  const linkTo = `/monitor/settings/campaign/${
    campaignId ? campaignId : campaignData.id
  }/summary`;

  // object for reducerKey and selectorValues to pass in "uploadTemplate"
  const reducerKeyValuesObj = {
    reducerKey: "c_siteUpload",
    selectorValue: {
      loading: "uploadLoading",
      error: "uploadError",
      mediaCount: "mediaCount",
    },
  };

  // this function handles file-uploads
  async function uploadTemplateFunction() {
    const files = await selectFile();
    dispatch(uploadTemplate(campaignId, files, true));
  }

  return (
    <div className="content-wrapper bg-alt h-100 site-upload">
      {/* PageHeader */}
      <PageHeaderSection campaignId={campaignId} cityId={cityId} />

      <div className="page-content">
        {/* for downloading template of excel file */}
        <DownloadTemplateCard
          isDisable={templateDownloading}
          onClick={() => dispatch(downloadTemplate(fileName))}
          downloadTemplateData={monitoringSiteUpload.DownloadTemplateData}
        />

        {/* for excel-file template upload */}
        <UploadTemplateCard
          uploadSiteListData={monitoringSiteUpload.UploadSiteListData}
          reducerKeyValuesObj={reducerKeyValuesObj}
          uploadTemplateFunction={uploadTemplateFunction}
        />

        {/* to redirect to monitoring-campaign-summary-page */}
        <GenerateTemplateCard
          linkTo={linkTo}
          mediaCount={mediaCount}
          generateTemplateData={monitoringSiteUpload.GenerateTemplateData}
          campaignId={campaignId}
        />
      </div>
    </div>
  );
}

export default MonitoringCampaignCreatePage;
