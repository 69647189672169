import { Sec } from "../../constants/action-constants/map-view/SecActionConstants";

//initial state
const initialState = {
  // sec Info
  secData: {},
  secLoading: {},
  secErr: "",

  // selected sec map
  selectedSec: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Sec.GET_SEC_INFO: {
      const { sec } = action.payload;
      return {
        ...state,
        secLoading: { ...state.secLoading, [sec]: true },
      };
    }

    case Sec.GET_SEC_INFO_SUCCESS: {
      return {
        ...state,
        secLoading: {},
        secData: action.payload,
      };
    }

    case Sec.GET_SEC_INFO_FAILURE: {
      return {
        ...state,
        secLoading: {},
        secErr: action.payload,
      };
    }

    case Sec.SELECT_SEC: {
      return {
        ...state,
        selectedSec: { ...state.selectedSec, [action.payload.sec]: true },
      };
    }

    case Sec.REMOVE_SELECTED_SEC: {
      const updatedSelectedSec = { ...state.selectedSec };
      delete updatedSelectedSec[action.payload.sec];
      return {
        ...state,
        selectedSec: updatedSelectedSec,
      };
    }

    case Sec.RESET_SEC: {
      return initialState;
    }

    default:
      return state;
  }
};
