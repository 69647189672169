import { useState } from "react";
import { useDispatch } from "react-redux";

// Actions
import { removeSelectedRoadStretch } from "../../../actions/map-view/RoadStretchesSelectionActions";

// Components
import SearchInputLocal from "../../../components/search-input-local/SearchInputLocal";

// Page Component
function RemoveStretchBtn({ id }) {
  const dispatch = useDispatch();

  return (
    <div>
      <button
        className="btn add-button btn-outline-primary shadow-none"
        onClick={() => dispatch(removeSelectedRoadStretch(id))}
      >
        <span className="font-weight-bold">{"Remove"}</span>
      </button>
    </div>
  );
}

function SelectedStretchesList({ stretchInfo }) {
  const { name, id } = stretchInfo;
  return (
    <div className="navbar px-0 border-top py-2">
      <span className="mt-1">{name}</span>
      <RemoveStretchBtn id={id} />
    </div>
  );
}

// Page Section
function SelectedStretchesSection({
  selectedStretchesArray,
  selectedViewLabels = {},
}) {
  // State
  const [roadStretches, setRoadStretches] = useState([]);

  // Search Labels
  const { search } = selectedViewLabels;

  return (
    <>
      {/* Search section */}
      <SearchInputLocal
        placeholder={search.placeholder}
        dataList={selectedStretchesArray}
        searchFields={search.fields}
        onSearchComplete={setRoadStretches}
      />

      {/* Selected Stretches Container */}
      {roadStretches.map((stretchInfo) => (
        <SelectedStretchesList key={stretchInfo.id} stretchInfo={stretchInfo} />
      ))}
    </>
  );
}

export default SelectedStretchesSection;
