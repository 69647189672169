// Constants and Utils
import {
  LocalStorageKeys,
  OtsMethodologyType,
} from "../../constants/GeneralConstants";
import { getItem } from "../../utils/localstorage";

/**
 * this function will check Organisation is Subscribed or Not.
 * @returns => true/false
 */
export function getIsSubscriptionActive() {
  // getting "User" from localStorage
  const user = JSON.parse(getItem(LocalStorageKeys.USER));

  const { org } = user;
  const { isSubscriptionActive } = org || {};

  if (isSubscriptionActive) {
    return true;
  }
  return false;
}

/**
 * Function for masking the character
 * @param {*} value => number or string
 * @param {*} unMaskedCharCount = un-masked character count from Last (optional)
 * @returns => ****3765
 */
export function maskCharacter(initialValue = "", unMaskedCharCount) {
  // Converting value into string for processing
  const processValue =
    typeof value !== String ? initialValue?.toString() : initialValue;

  // length of "processValue"
  const processValueLength = processValue?.length;

  // if length is Zero || undefined then return "4 times star"
  if (!processValueLength || processValueLength === 0) {
    return `****`;
  }

  const unMaskedCharCountFromLast = unMaskedCharCount
    ? unMaskedCharCount
    : Math.floor(processValueLength / 2);

  // Slice the value and replace with maskChar then add remaining string
  return (
    ("" + processValue)
      .slice(0, -unMaskedCharCountFromLast)
      .replace(/./g, "*") +
    ("" + processValue).slice(-unMaskedCharCountFromLast)
  );
}

/**
 * this function will check Organisation is Subscribed to mobile based ots or traffic ots.
 * @returns => true/false
 */
export function IsTrafficOtsMethodology() {
  // getting "User" from localStorage
  const user = JSON.parse(getItem(LocalStorageKeys.USER));

  const { org } = user || {};
  const { otsMethodologyType } = org || {};

  return otsMethodologyType === OtsMethodologyType.TRAFFIC;
}
