/* eslint-disable no-case-declarations */
import { Buyer } from "../../constants/action-constants/BuyerActionConstants";

const initialState = {
  // buyers list
  buyersList: null,
  buyersLoading: false,
  error: "",

  // users list by buyer id
  usersList: [],
  usersListLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Buyers List
    //--------------------------------------------------------------------------------
    case Buyer.GET_BUYERS_LIST:
      return {
        ...state,
        buyersLoading: true,
      };
    case Buyer.GET_BUYERS_LIST_SUCCESS:
      const buyersList = action.payload.buyersList;
      return {
        ...state,
        buyersLoading: false,
        buyersList: buyersList.items,
      };
    case Buyer.GET_BUYERS_LIST_FAILURE:
      return {
        ...state,
        buyersLoading: false,
        error: action.payload,
      };

    // users by buyer id
    //--------------------------------------------------------------------------------
    case Buyer.GET_USERS_BY_BUYER_ID:
      return {
        ...state,
        usersListLoading: true,
      };
    case Buyer.GET_USERS_BY_BUYER_ID_SUCCESS:
      const usersList = action.payload.usersList;
      return {
        ...state,
        usersListLoading: false,
        usersList: usersList.items,
      };
    case Buyer.GET_USERS_BY_BUYER_ID_FAILURE:
      return {
        ...state,
        usersListLoading: false,
        error: action.payload,
      };

    // clear the users state
    case Buyer.CLEAR_BUYER_USERS:
      return {
        ...state,
        usersList: [],
      };
    default:
      return state;
  }
};
