import React, { useCallback } from "react";
import { useSelector } from "react-redux";

// Constants and Utils
import { getBoundaryMarkersData } from "./boundaries/BoundariesUtil";
import { getItem } from "../../utils/localstorage";
import {
  LocalStorageKeys,
  OrganizationIdsMapping,
  PatrikaSellerId,
} from "../../constants/GeneralConstants";
import { checkIsOrgSpecific } from "../../utils/OrgUtils";

// Sections
import MediaControlsPanel from "./map-legend/MediaControlsPanel";
import PoiControlsPanel from "./map-legend/PoiControlsPanel";
import BoundariesControlsPanel from "./map-legend/BoundariesControlsPanel";

// Functions
function getMediaMarkersCount(
  campaignMediaList = [],
  exploreMediaList = [],
  mediaTypeToShow = {}
) {
  // "mediaTypeToShow = {campaignMedia: true/false, inventoryMedia: true/false}
  // mediaTypeToShow ==> handles which media count to show
  const campaignMediaCount = mediaTypeToShow.campaignMedia
    ? campaignMediaList.length
    : 0;
  const exploreMediaCount = mediaTypeToShow.inventoryMedia
    ? exploreMediaList.length
    : 0;

  return campaignMediaCount + exploreMediaCount;
}

/**
 /* Component
 /* ----------------------------------------------------------------------------
 */
function MapControlsSection() {
  // Media info from reducer ----------------------
  const campaignMediaList = useSelector(
    (state) => state.mapViewMediaSites.filteredMediaSites
  );
  const exploreMediaList = useSelector(
    (state) => state.mapViewExploreSites.exploreSites
  );
  // media fill-color
  const campaignMediaFillColor = useSelector(
    (state) => state.mapViewMediaSites.campaignMediaFillColor
  );
  const inventoryMediaFillColor = useSelector(
    (state) => state.mapViewExploreSites.inventoryMediaFillColor
  );
  const metaData = useSelector((state) => state.mapMetaData.metaData);
  const mediaTypeToShow = useSelector(
    (state) => state.legendAndInfluence.mediaTypeToShow
  );

  // POI info from Reducer -----------------------
  const selectedBrandData = useSelector(
    (state) => state.poiSelection.brandData
  );
  const totalPoiCount = Object.keys(selectedBrandData).reduce(
    (acc, eachPoi) => acc + selectedBrandData[eachPoi].length,
    0
  );
  const showPoiInfluenceCircle = useSelector(
    (state) => state.poiSelection.showInfluenceCircle
  ); // true/false

  const showMediaInfluenceCircle = useSelector(
    (state) => state.legendAndInfluence.showMediaInfluenceCircle
  ); // true/false

  // Boundaries Info ----------------------------
  const polygonData =
    useSelector((state) => state.boundaries.polygonData) || {};
  const selected = useSelector((state) => state.boundaries.selectedLabel) || "";
  const boundaryMapSettings =
    useSelector((state) => state.boundaries.boundaryMapSettings) || {};

  const { boundaryLegendData } = getBoundaryMarkersData(
    polygonData,
    selected,
    boundaryMapSettings
  );

  const user = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  const { orgId } = user;
  const { patrika } = OrganizationIdsMapping;
  const isPatrikaSpecific = checkIsOrgSpecific(orgId, patrika);

  const getPatrikaInventory = useCallback(
    (mediaList) => {
      return mediaList.filter((media) => media.sellerId === PatrikaSellerId);
    },
    [isPatrikaSpecific]
  );

  const updatedMediaList = isPatrikaSpecific
    ? getPatrikaInventory(exploreMediaList)
    : exploreMediaList;

  // map Setting for show/hide
  // -----------------------------------------------------------------------
  const mapSettings = {
    // Media Sites  ( all keys: true/false)
    media: {
      campaignMedia: mediaTypeToShow?.campaignMedia,
      inventoryMedia: mediaTypeToShow?.inventoryMedia,
      metadata: {
        title: metaData.title,
        image: metaData.image,
        dimensions: metaData.dimensions,
        lts: metaData.lts,
        ots: metaData.ots,
      },
      circle: showMediaInfluenceCircle, // circle: true/false
      fillColor: {
        campaignMedia: campaignMediaFillColor,
        inventoryMedia: inventoryMediaFillColor,
      },
    },

    // POI
    poi: { circle: showPoiInfluenceCircle }, // circle: true/false

    // Boundaries
    boundaries: {
      boundaryLegendData, // boundaryLegendData: []
      selectedBoundary: selected, // selectedBoundary: ""
      boundaryMapSettings,
    },
  };

  // Settings -------------------------------
  // Media Sites
  const mediaSettings = mapSettings.media || {};
  const mediaCount = getMediaMarkersCount(
    campaignMediaList,
    updatedMediaList,
    mediaTypeToShow
  );

  // POI
  const poiSettings = mapSettings.poi || {};
  const poiCount = totalPoiCount;

  // Boundaries
  const boundariesSettings = mapSettings.boundaries || {};

  return (
    <div className="map-controls-cont top-0">
      <h6 className="header">{"Map Display Settings"}</h6>

      {/* Media Controls */}
      <MediaControlsPanel settings={mediaSettings} count={mediaCount} />

      {/* Poi Controls */}
      <PoiControlsPanel settings={poiSettings} count={poiCount} />

      {/* Boundaries Controls */}
      <BoundariesControlsPanel settings={boundariesSettings} />
    </div>
  );
}

export default MapControlsSection;
