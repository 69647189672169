import { Seller } from "../../constants/action-constants/seller/SellerActionConstants";

export const getSellerSummaryByMediaId = (mediaId, dateRange) => ({
  type: Seller.GET_SELLER_SUMMARY_BY_MEDIA_ID,
  payload: { mediaId, dateRange },
});

export const getSellerSummaryByCity = (cityId, dateRange) => ({
  type: Seller.GET_SELLER_SUMMARY_BY_CITY,
  payload: { cityId, dateRange },
});
