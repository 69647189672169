// Urls
import {
  getSellerInfoByIdUrl,
  getSellerInventorySummaryUrl,
  updateSellerInfoUrl,
  updateSellerLogoUrl,
  searchSellerUrl,
  enableSellerSite,
  disableSellerSite,
} from "../urls/SellerURL";

// Utils
import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

// Seller inventory summary
export async function getSellerInventorySummaryFn(sellerId) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const url = getSellerInventorySummaryUrl.replace(":sellerId", sellerId);
  return sendGetRequest(url, {}, true, headerParams);
}

// Seller Info BY ID
export async function getSellerInfoByIdFn(sellerId) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const url = getSellerInfoByIdUrl.replace(":sellerId", sellerId);
  return sendGetRequest(url, {}, true, headerParams);
}

// Search Seller
export async function searchSellerFn(searchText) {
  const url = searchSellerUrl.replace(":searchText", searchText);
  return sendGetRequest(url);
}

// Update seller info
export async function updateSellerInfoFn(sellerId, sellerInfoBean) {
  const url = updateSellerInfoUrl.replace(":sellerId", sellerId);

  return sendCreateOrUpdateRequest(url, sellerInfoBean, true, "PUT");
}

// Update Seller Logo
export async function updateSellerLogoFn(sellerId, file) {
  const formData = new FormData();
  formData.append("file", file);

  const url = updateSellerLogoUrl.replace(":sellerId", sellerId);

  return sendCreateOrUpdateRequest(url, formData);
}

// Enable Seller Site
export async function enableSellerSiteFn(sellerId) {
  const url = enableSellerSite.replace(":sellerId", sellerId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT");
}

// Disable Seller Site
export async function disableSellerSiteFn(sellerId) {
  const url = disableSellerSite.replace(":sellerId", sellerId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT");
}
