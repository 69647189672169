// Constants
import { DefaultPagination } from "../constants/GeneralConstants";

//urls
import {
  archiveRoadStretchUrl,
  createRoadStretchUrl,
  getRoadStretchesByIdsUrl,
  getRoadStretchesOfOrgUrl,
  restoreRoadStretchUrl,
  getRoadStretchInfoByIdUrl,
  getRoadStretchesByBboxUrl,
  roadStretchPriceRefreshUrl,
} from "../urls/OrgRoadStretchURL";

import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

export async function getRoadStretchesData(bbox) {
  // Construct NorthWest and SouthEast coordinates from the bbox
  const bboxArray = bbox.split(",");
  const nw = `${bboxArray[3]}, ${bboxArray[2]}`;
  const se = `${bboxArray[1]}, ${bboxArray[0]}`;
  const isEnabled = true;

  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    nw: nw,
    se: se,
    isEnabled: isEnabled,
    pn: 1,
    ps: 50,
  };

  return sendGetRequest(getRoadStretchesByBboxUrl, params, true, headerParams);
}

export async function getRoadStretchesByIds(stretchIds) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const roadStretchIds = stretchIds.join();

  const params = {
    ids: roadStretchIds,
  };

  return sendGetRequest(getRoadStretchesByIdsUrl, params, true, headerParams);
}

export async function getRoadStretchesOfOrg(
  excludeArchive,
  cityId,
  keyWord,
  pageNumber,
  pageSize
) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };
  if (keyWord) {
    params._kw = keyWord;
  }
  if (cityId) {
    params._city = cityId;
  }

  // Here excludeArchive == true ==> Meaning we need only
  // RoadStretches which are not archived. When we make a request we
  // say "_ar=flase" which would give the results with out
  // archived one..
  if (excludeArchive) {
    params._ar = !excludeArchive;
  }

  return sendGetRequest(getRoadStretchesOfOrgUrl, params);
}

export async function archiveRoadStretch(stretchId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = archiveRoadStretchUrl.replace(":stretchId", stretchId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT", headerParams);
}

export async function restoreRoadStretch(stretchId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = restoreRoadStretchUrl.replace(":stretchId", stretchId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT", headerParams);
}

export async function createRoadStretch(stretchBean) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = createRoadStretchUrl;

  return sendCreateOrUpdateRequest(
    url,
    stretchBean,
    true,
    "POST",
    headerParams
  );
}

export async function getRoadStretchInfoByIdFn(stretchId) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const url = getRoadStretchInfoByIdUrl.replace(":stretchId", stretchId);

  return sendGetRequest(url, {}, true, headerParams);
}

// Price refresh for stretches
export async function roadStretchPriceRefreshFn(stretchId) {
  const url = roadStretchPriceRefreshUrl.replace(":stretchId", stretchId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT");
}
