import React from "react";
import { useSelector } from "react-redux";
import { Circle, Marker, Popup } from "react-leaflet";

import MapIcon from "../map-icon/MapIcon";

function _toMarkers(dataPoints) {
  if (!dataPoints) {
    return [];
  }

  return dataPoints.map((dp) => ({
    id: dp.id,
    type: dp.type,
    markerText: dp.name,
    position: [dp.center.latitude, dp.center.longitude],
  }));
}

/**
 * POI Markers
 */
export default function PoiMarkers() {
  const markers = useSelector((state) =>
    Object.keys(state.poiSelection.brandIds).reduce((acc, brandId) => {
      if (
        !state.poiSelection.brandIds[brandId] ||
        !state.poiSelection.brandIds[brandId].selected
      ) {
        return acc;
      }
      const brandData = state.poiSelection.brandData[brandId];
      acc = acc.concat(_toMarkers(brandData));
      return acc;
    }, [])
  );

  // to show/hide ==> poi-influence-marker
  const showInfluenceCircle = useSelector(
    (state) => state.poiSelection.showInfluenceCircle
  ); // true/false
  const influenceCircleRadius = useSelector(
    (state) => state.poiSelection.influenceCircleRadius
  );

  if (markers.length <= 0) {
    return null;
  }

  return (
    <>
      {markers.map((marker, i) => {
        const icon = new MapIcon({ type: marker.type });
        return (
          <Marker key={i} position={marker.position} icon={icon}>
            <Popup>
              <b>{marker.markerText}</b>
            </Popup>
          </Marker>
        );
      })}

      {/* influence circle markers */}
      {showInfluenceCircle &&
        markers.map((marker, i) => (
          <Circle
            key={i}
            center={marker.position}
            pathOptions={{ color: "grey", fillColor: "red" }}
            radius={influenceCircleRadius}
          />
        ))}
    </>
  );
}
