function PerformanceCards({ info, className }) {
  return (
    <div className={`pl-0 p-card ${className}`}>
      <div className="card h-100">
        <div className="card-body">
          <p className="card-title">{info.title}</p>
          <h4 className="card-text mb-0">{info.value}</h4>
          <div className="card-subtitle mt-1">{info.subValue}</div>
        </div>
      </div>
    </div>
  );
}

export default PerformanceCards;
