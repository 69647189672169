// Constants
import { DurationConstants } from "../../constants/GeneralConstants";

export function calculatePriceFactor(ltsSummary) {
  if (!ltsSummary) {
    return 1;
  }
  return ltsSummary.lts ? ltsSummary.lts / 0.8 : 1;
}

export function calculateAdjustedMop(mop, occupancy, dayCount) {
  const adjestedMop = (mop / occupancy) * 12;
  const adjestedMopPerDay = adjestedMop / DurationConstants.THIRTY_DAYS;
  if (dayCount < 10) {
    return adjestedMopPerDay * dayCount;
  }

  if (dayCount >= 10 && dayCount <= 20) {
    return adjestedMopPerDay * dayCount * 1.1;
  }

  return (mop / DurationConstants.THIRTY_DAYS) * dayCount;
}

export function calEstPrice(estimatePriceBean) {
  const {
    genericImpressions,
    specificImpressions,
    mop,
    occupancy,
    dayCount,
    tgPriceFactor,
    genPriceFactor,
  } = estimatePriceBean;
  const adjestedMop = calculateAdjustedMop(mop, occupancy, dayCount);

  const estCost = {
    genericCost: 0,
    specificCost: 0,
  };

  // Estimated Price (if Tg is Selected)
  if (genericImpressions && specificImpressions) {
    const totalImp = (genericImpressions + specificImpressions) * dayCount;
    const pricePerImpression = adjestedMop / totalImp;

    estCost.genericCost =
      genericImpressions * pricePerImpression * (genPriceFactor / 100);
    estCost.specificCost =
      specificImpressions * pricePerImpression * (tgPriceFactor / 100);

    return estCost;
  }

  // Estimated Price (if Tg is not Selected)
  // This is same as ==> (adjestedMop / totalImp) * totalImp * (genPriceFactor / 100);
  estCost.genericCost = adjestedMop * (genPriceFactor / 100);
  return estCost;
}
