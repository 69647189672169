import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { getCampaignPlan } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { getCampaignPerformanceByCityId } from "../../actions/campaign-performance/CampaignPerformanceActions";

// Constants and Utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";

// Components
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import Spinner from "../../../components/spinner/Spinner";
import CityViewCards from "../components/CityViewCards";
import MapView from "../components/map-view/MapView";
import {
  CampaignDetails,
  CancelCampaign,
  StopCampaign,
} from "../components/HeaderNav";
import MediaDetailsTable from "../components/MediaDetailsTable";
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Pages and Section
import CityPerformanceTable from "../components/CityPerformanceTable";
import { getRoadStretchesOfOrg } from "../../../actions/org/OrgRoadStretchActions";

// Page-Component
function HeaderSection({ campaign }) {
  const updateStateLoading = useSelector(
    (state) => state.c_campaignBasicInfo.updateStateLoading
  );

  if (!campaign) {
    return null;
  }

  const EnableCancelCampaign = isAgencySpecific(CancelCampaign);
  const EnableStopCampaign = isAgencySpecific(StopCampaign);

  const pageTitle = <CampaignDetails campaign={campaign} />;
  const pageActions = (
    <div className="d-flex mt-2">
      <div>
        {EnableCancelCampaign && <EnableCancelCampaign campaign={campaign} />}
        {EnableStopCampaign && <StopCampaign campaignId={campaign.id} />}
      </div>
      {updateStateLoading && <Spinner className="mx-3" />}
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}

/**
 * Page
 */
function CampaignLivePage() {
  // State
  const { campaignId, cityId } = useParams();

  // Get campaignPlan
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);
  const { targetGroupId } = campaignPlan;

  // tg-name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Campaign Media
  const campaignMedia = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  const campaignMediaOtsLogSummary = useSelector(
    (state) => state.campaignPerformance.campaignMediaOtsLogSummary
  );

  const redirectLinkObj = {
    campaignOverviewLink: constructRedirectPath(`/campaign/${campaignId}/live`),
    citySummaryLink: constructRedirectPath(
      `/campaign/${campaignId}/city/:cityId/live`
    ),
  };

  const citySelected = { cityId };

  // City road stretches
  const roadStretchMap = useSelector(
    (state) => state.orgRoadStretch.orgRoadStretches
  );

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCampaignPlan(campaignId, cityId));
    dispatch(getBasicCampaignDetails(campaignId));
    dispatch(getCampaignPerformanceByCityId(campaignId, cityId));
    dispatch(getRoadStretchesOfOrg(false, cityId, "", 1, 1000));
  }, [dispatch, campaignId, cityId]);

  useGetTgInfo(targetGroupId);

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar
        campaign={campaign}
        redirectLinkObj={redirectLinkObj}
        citySelected={citySelected}
      />

      <div className="content-wrapper">
        {/* Page Header Section */}
        <HeaderSection campaign={campaign} />

        {/* Page-Content */}
        <div className="page-content">
          {/* city view Cards Section */}
          <CityViewCards
            tgName={tgName}
            campaign={campaign}
            cityPlan={campaignPlan}
            campaignMedia={campaignMedia}
          />

          {/* Campaign Cities Performance Table */}
          <CityPerformanceTable
            campaignPlan={campaignPlan}
            campaignMedia={campaignMedia}
            roadStretchMap={roadStretchMap}
            campaignMediaOtsLogSummary={campaignMediaOtsLogSummary}
          />

          {/* Map View */}
          <MapView
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchMap}
          />

          {/* Media Sites Table */}
          <MediaDetailsTable
            campaignId={campaignId}
            campaign={campaign}
            campaignPlan={campaignPlan}
            campaignMediaOtsLogSummary={campaignMediaOtsLogSummary}
            roadStretchMap={roadStretchMap}
          />
        </div>
      </div>
    </>
  );
}

export default CampaignLivePage;
