export const Boundaries = {
  GET_BOUNDARIES: "GET_BOUNDARIES",
  GET_BOUNDARIES_SUCCESS: "GET_BOUNDARIES_SUCCESS",
  GET_BOUNDARIES_FAILURE: "GET_BOUNDARIES_FAILURE",

  SET_RADIOLIST_SELECTED: "SET_RADIOLIST_SELECTED",
  SET_RADIO_STATUS: "SET_RADIO_STATUS",

  GET_WARD_INFO: "GET_WARD_INFO",
  GET_WARD_INFO_SUCCESS: "GET_WARD_INFO_SUCCESS",
  GET_WARD_INFO_FAILURE: "GET_WARD_INFO_FAILURE",

  GET_PINCODE_INFO: "GET_PINCODE_INFO",
  GET_PINCODE_INFO_SUCCESS: "GET_PINCODE_INFO_SUCCESS",
  GET_PINCODE_INFO_FAILURE: "GET_PINCODE_INFO_FAILURE",

  MAPPING: { None: "", Ward: "Ward", Pincode: "Pincode" },

  RESET: "RESET_BOUNDARIES",
  UN_SELECT: "UN_SELECT_BOUNDARIES",

  // for boundary map settings
  SET_MAX_COLOR: "SET_MAX_COLOR",
  SET_BOUNDARY_STEPS: "SET_BOUNDARY_STEPS",
};
