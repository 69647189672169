// Components
import Spinner from "../../../components/spinner/Spinner";

/**
 * ALERT MESSAGE BOX
 */
function AlertMessage({
  className = "",
  spinnerClassName = "",
  textMessage = "",
  isLoading = true,
}) {
  return (
    <div
      className={`alert alert-dismissible alert-info d-flex align-items-center ${className}`}
    >
      {isLoading && <Spinner className={spinnerClassName} />}
      <span className="pl-2">{textMessage}</span>
    </div>
  );
}

export default AlertMessage;
