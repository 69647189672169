// Tabs
import ArterialRouteTab from "./ArterialRouteTab";
import ZonesTab from "./ZonesTab";
import MediaSitesTab from "./MediaSitesTab";
import PointsOfInterestTab from "./PointsOfInterestTab";
import TargetGroupTab from "./TargetGroupsTab";
import TouchPointsTab from "./TouchPointsTab";

// Constants
const contents = [
  <>
    <i className="fas fa-users my-2 fa-2x opacity-75"></i>
    <p className="pb-0">Target Group</p>
  </>,
  <>
    <i className="fas fa-border-all my-2 fa-2x opacity-75"></i>
    <p className="pb-0">Zone</p>
  </>,
  <>
    <i className="fas fa-image my-2 fa-2x opacity-75"></i>
    <p className="pb-0">Media Type</p>
  </>,
  <>
    <i className="fas fa-border-none my-2 fa-2x opacity-75"></i>
    <p className="pb-0">Arterial Route</p>
  </>,
  <>
    <i className="fas fa-map-marker-alt my-2 fa-2x opacity-75"></i>
    <p className="pb-0">Points of Interest</p>
  </>,
  <>
    <i className="fas fa-hand-pointer my-2 fa-2x opacity-75"></i>
    <p className="pb-0">Touch Point</p>
  </>,
];

function SelectedContent({ selectedTab, cityId }) {
  switch (selectedTab) {
    case 1:
      return <TargetGroupTab />;
    case 2:
      return <ZonesTab />;
    case 3:
      return <MediaSitesTab />;
    case 4:
      return <ArterialRouteTab />;
    case 5:
      return <PointsOfInterestTab cityId={cityId} />;
    case 6:
      return <TouchPointsTab />;

    default:
      return null;
  }
}

export default function PlanningMapTabsSection({
  selectedTab,
  tabOnClick,
  cityId,
}) {
  return (
    <div className="toohl-map-tabs-container">
      <div className="tab-items">
        {contents.map((content, index) => (
          <div
            key={index}
            className={`tab-item text-primary ${
              selectedTab === index + 1 ? "active" : ""
            }`}
            onClick={() => tabOnClick(index + 1)}
            disabled={!cityId}
          >
            {content}
          </div>
        ))}
      </div>
      {!!selectedTab && (
        <div className="tab-cont bg-white">
          <SelectedContent selectedTab={selectedTab} cityId={cityId} />
        </div>
      )}
    </div>
  );
}
