import {
  MapExploreSites,
  MediaSites,
} from "../../../constants/action-constants/map-view/media-sites/MediaSitesActionConstants";

// Utils and Constants
import { filterMedia } from "../../../utils/MediaUtils";
import {
  DefaultFilterObjects,
  MediaColor,
} from "../../../constants/GeneralConstants";

//intital state
const initialState = {
  originalMediaSites: [],
  filteredMediaSites: [],
  pagination: {},
  city: {},
  err: "",
  loading: false,

  // This stores the total media Sites
  totalMediaSites: [],

  // Below fields store the state related to mapping of seller media to campaign
  mapSellerMediaIsLoading: false,
  mapSellerMediaErr: "",

  // temp
  mapRef: "",
  generateMapRef: false,

  // remove media for campaign
  removeMediaSitesLoading: {},

  // Campaign Media Fill Color
  campaignMediaFillColor: MediaColor.campaign, // DarkBlue

  // CampaignMedia FIlters
  campaignMediaFilterObj: DefaultFilterObjects.media,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MediaSites.GET_MEDIA_SITES:
      return {
        ...state,
        loading: true,
      };
    case MediaSites.GET_MEDIA_SITES_SUCCESS: {
      const { items, pagination } = action.payload.result;

      // Filtering the Media According to the SavedMapState
      const filteredMediaList = filterMedia(
        items,
        state.campaignMediaFilterObj
      );
      return {
        ...state,
        loading: false,
        city: action.payload.city,
        pagination: pagination,
        originalMediaSites: items,
        filteredMediaSites: filteredMediaList,
        totalMediaSites: state.totalMediaSites.concat(items),
      };
    }

    case MediaSites.GET_MEDIA_SITES_FAILURE:
      return {
        ...state,
        err: action.payload,
        loading: false,
      };

    // Below handles the mapping of explore sites to campaign
    case MapExploreSites.MAP_EXPLORE_SITES:
      return {
        ...state,
        mapSellerMediaIsLoading: true,
      };
    case MapExploreSites.MAP_EXPLORE_SITES_SUCCESS:
      return {
        ...state,
        mapSellerMediaIsLoading: false,
        totalMediaSites: state.totalMediaSites.concat(action.payload.items),
        // TODO : Revisit this as the pagination goes on toss
        filteredMediaSites: state.filteredMediaSites.concat(
          action.payload.items
        ),
      };

    case MapExploreSites.MAP_EXPLORE_SITES_FAILURE:
      return {
        ...state,
        mapSellerMediaErr: action.payload,
        mapSellerMediaIsLoading: false,
      };

    // Remove media site from campaign
    //----------------------------------------------------------------------
    case MediaSites.REMOVE_MEDIA_SITE: {
      const { id } = action.payload.campaignMediaInfo;
      return {
        ...state,
        removeMediaSitesLoading: {
          ...state.removeMediaSitesLoading,
          [id]: true,
        },
      };
    }

    case MediaSites.REMOVE_MEDIA_SITE_SUCCESS:
      return {
        ...state,
        removeMediaSitesLoading: {},
      };

    case MediaSites.REMOVE_MEDIA_SITE_FAILURE:
      return {
        ...state,
        removeMediaSitesLoading: {},
        error: action.payload.err,
      };

    // set CampaignMedia Fill Color
    case MediaSites.SET_CAMPAIGN_MEDIA_FILL_COLOR: {
      const { color } = action.payload;
      return {
        ...state,
        campaignMediaFillColor: color,
      };
    }

    // set CampaignMedia FITLERS
    case MediaSites.SET_CAMPAIGN_MEDIA_FILTERS: {
      const { appliedFiltersObj } = action.payload;
      const filteredMediaList = filterMedia(
        state.originalMediaSites,
        appliedFiltersObj
      );
      return {
        ...state,
        campaignMediaFilterObj: appliedFiltersObj,
        filteredMediaSites: filteredMediaList,
      };
    }

    case MediaSites.RESET:
      return initialState;

    default:
      return state;
  }
};
