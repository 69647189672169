import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Actions
import {
  resetRoadStretches,
  createRoadStretch,
} from "../../../actions/org/OrgRoadStretchActions";

// Constants and Utils
import { NoSegmentsMessageSection } from "../OrgUtils";

// Components
import Spinner from "../../../components/spinner/Spinner";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Sections and Pages
import RoadStretchCreateMapView from "./RoadStretchCreateMapView";
import OrgSettingsSidebar from "../OrgSettingsSidebar";

// CSS
import "../OrgSettings.scss";

/**
 * this function returns segments ids and names
 * @param {*} selectedSegments
 * @param {*} roadSegmentsMap
 * @returns
 */
function getSelectedSegmentDetails(selectedSegments, roadSegmentsMap) {
  const { segmentIds = {} } = selectedSegments;
  if (Object.keys(segmentIds).length < 1) {
    return { selectedSegmentIds: [], selectedSegmentNames: [] };
  }

  const selectedSegmentNames = Object.keys(segmentIds).reduce(
    (acc, segmentId) => {
      acc.push(roadSegmentsMap[segmentId].name);
      return acc;
    },
    []
  );

  return {
    selectedSegmentIds: Object.keys(segmentIds),
    selectedSegmentNames,
  };
}

// Page Component
// ----------------------------------------------------------------------------
function SelectedSegments({ segmentNames, segmentIds }) {
  return (
    <div className="mb-5 mt-3 pb-5">
      <strong> {"Road Segments Selected : "}</strong>

      {/* If no Segments are selected  */}
      {segmentIds.length < 1 && (
        <p className="font-italic">
          {" "}
          {"Use the map to search and select the Road Segments"}
        </p>
      )}

      {/* If at least one segment is selected */}
      {segmentIds.length > 0 && (
        <ul>
          {segmentIds.map((segmentId, index) => {
            const segmentName = segmentNames[index];
            return (
              <li key={segmentId} className="p-1">
                {segmentId} {`${segmentName ? `(${segmentName})` : ""}`}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

function StretchNameInputSection({ stretchName, setStretchName }) {
  return (
    <div className="form-group mb-2 py-3">
      <label className="control-label">{"Road Stretch Name"}</label>
      <input
        className="form-control rounded-lg"
        placeholder="Enter Stretch Name"
        id={"StretchNameStr"}
        value={stretchName}
        onChange={(e) => setStretchName(e.target.value)}
      />
    </div>
  );
}

function FooterSection({
  stretchName,
  segmentIds,
  setStretchName,
  setCoordinate,
}) {
  const history = useHistory();
  const createStretchLoading = useSelector(
    (state) => state.orgRoadStretch.createStretchLoading
  );

  const dispatch = useDispatch();
  // functions
  function saveStretch() {
    dispatch(createRoadStretch(stretchName, segmentIds, history));
  }

  function clearSelectionFn() {
    dispatch(resetRoadStretches());
    setCoordinate([]);
    setStretchName("");
  }

  return (
    <>
      <hr className="divider m-0"></hr>
      <div className="d-flex py-3 px-2 mt-2">
        <button
          className="btn btn-outline-primary shadow-none mr-2 px-4"
          onClick={clearSelectionFn}
        >
          {"Clear Selection"}
        </button>
        <button
          type="button"
          onClick={saveStretch}
          className="btn btn-primary shadow-none"
          disabled={createStretchLoading || !stretchName || !segmentIds.length}
        >
          {"Save"}
        </button>
        {createStretchLoading && <Spinner className="mx-1" />}
      </div>
    </>
  );
}

/**
 * Page Component
 */
export default function RoadStretchCreatePage() {
  // State
  const [stretchName, setStretchName] = useState("");
  // coordinate is to show PinDrop Point on map
  const [coordinate, setCoordinate] = useState([]);

  // Selector
  const selectedSegments = useSelector(
    (state) => state.orgRoadStretch.selectedSegments
  );

  // Fetch the roadSegments from the reducer
  const roadSegmentsMap = useSelector(
    (state) => state.orgRoadStretch.roadSegmentsMap
  );

  // Selected segment Name and Ids
  const { selectedSegmentIds, selectedSegmentNames } =
    getSelectedSegmentDetails(selectedSegments, roadSegmentsMap);

  // No Closest Segments Msg Section
  const pageActions = (
    <NoSegmentsMessageSection
      roadSegments={Object.keys(roadSegmentsMap)}
      locationStr={coordinate.toString()}
      className={"pt-1"}
    />
  );

  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={5} />

      <div className="content-wrapper map-content-wrapper">
        {/** Map Layout :: Left */}
        <div className="map-layout-left">
          <PageHeader
            title={"New Road Stretch"}
            shadow={false}
            border={true}
            actions={pageActions}
          />

          {/* Input for Stretch Name */}
          {selectedSegmentIds.length > 0 && (
            <StretchNameInputSection
              stretchName={stretchName}
              setStretchName={setStretchName}
            />
          )}

          {/* Section to display selected segment names */}
          <SelectedSegments
            segmentNames={selectedSegmentNames}
            segmentIds={selectedSegmentIds}
          />

          {/* Section for "save" and "clear selection" buttons*/}
          <FooterSection
            stretchName={stretchName}
            segmentIds={selectedSegmentIds}
            setStretchName={setStretchName}
            setCoordinate={setCoordinate}
          />
        </div>

        {/** Map Section */}
        <RoadStretchCreateMapView
          coordinate={coordinate}
          setCoordinate={setCoordinate}
        />
      </div>
    </>
  );
}
