import React from "react";
import { useDispatch } from "react-redux";

// Actions
import { closeTabContentPreviewForm } from "../../actions/campaign-planning/TabContentPreviewFormActions";

// Constants
import { ProohFormDataTargets } from "../../constants/GeneralConstants";
import { DummyBoundaryButtonNames, DummyTGButtonNames } from "./TabConstants";

// Components
import Chips from "../../../components/Chips/Chips";

export default function TabContentPreviewForm() {
  const dispatch = useDispatch();

  const isSelectedContentPresent = true;

  return (
    <div
      className="modal fade"
      id={ProohFormDataTargets.tabContentPreviewForm}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {/* Form Header */}
          <div className="modal-header bg-alt px-5">
            <h4 className="modal-title">Selected Information</h4>
          </div>

          {/* Form Body */}
          <div className="modal-body px-5">
            {/* if content not present */}
            {!isSelectedContentPresent && (
              <h4 className="text-center py-4 font-italic text-muted">
                No Content Selected
              </h4>
            )}

            {/* if content present */}
            {isSelectedContentPresent && (
              <>
                <Chips
                  title="Target group Data Layers"
                  selectedItems={DummyTGButtonNames}
                  showHrTag="true"
                  containerClassName="flex-wrap"
                />
                <Chips
                  title="Boundaries"
                  selectedItems={DummyBoundaryButtonNames}
                  showHrTag="true"
                  containerClassName="flex-wrap"
                />
              </>
            )}
          </div>

          {/* Form Footer */}
          <div className="modal-footer px-5">
            <button
              type="button"
              className="btn btn-outline-primary btn-action mt-2 mr-3 rounded-lg"
              data-dismiss="modal"
              onClick={() => dispatch(closeTabContentPreviewForm())}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
