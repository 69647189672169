import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

// Components
import { PlainDropdown } from "../../../components/dropdown/Dropdown";

function getSortingOptions(tgId) {
  if (tgId) {
    return [
      {
        id: "none",
        label: "",
      },
      {
        id: "totalOts",
        label: "Total Imp",
      },
      {
        id: "otsLitSpecific",
        label: "Tg Imp",
      },
      {
        id: "otsLitSpecificPercentage",
        label: "Tg %",
      },
    ];
  }

  return [
    {
      id: "none",
      label: "",
    },
    {
      id: "totalOts",
      label: "Total Imp",
    },
  ];
}

const sortButtonDataMap = {
  totalOts: "Total Imp",
  otsLitSpecific: "Tg Imp",
  otsLitSpecificPercentage: "Tg %",
};

function SortingBtn({ requestRoadStretchSort, requestRoadSegmentsSort }) {
  const [sortingId, setSortingId] = useState("");
  const tgId = useSelector((state) => state.tgSpecificOts.tgId);

  function handleSort(id) {
    setSortingId(id);
    const label = sortButtonDataMap[id];
    requestRoadStretchSort(id, label);
    requestRoadSegmentsSort(id, label);
  }

  const sortingOptions = getSortingOptions(tgId);

  return (
    <PlainDropdown
      items={sortingOptions}
      onItemSelect={(id) => handleSort(id)}
      className="btn px-2 shadow-none"
      textClassName="px-1 text-primary"
      selectedId={sortingId}
      placeHolder="Sort"
      dropMenuClassName="dropdown-menu-right"
    />
  );
}

const useSortableData = (roadsData) => {
  const [sortConfig, setSortConfig] = useState(null);
  const sortedItems = useMemo(() => {
    const sortableItems = [...roadsData];
    function getTgSpecificPercentage(value) {
      const { targetOtsLit, genericOtsLit } = value;
      const totalOts = targetOtsLit + genericOtsLit;
      const percentage = (targetOtsLit / totalOts) * 100;
      return percentage;
    }

    function getTotalOts(value) {
      const { otsAvg, otsLitAvg } = value;

      if (otsAvg && otsLitAvg) {
        return otsAvg + otsLitAvg;
      }

      const { genericOtsLit, genericOts, targetOtsLit, targetOts } = value;
      const totalOts =
        (genericOtsLit ? genericOtsLit : genericOts) +
        (targetOtsLit ? targetOtsLit : targetOts);
      return totalOts;
    }

    function getTgOts(value) {
      const { targetOtsLit, genericOts } = value;
      return targetOtsLit ? targetOtsLit : genericOts;
    }

    // sort config contains key and label
    if (sortConfig !== null) {
      sortableItems.sort((valueOne, valueTwo) => {
        //comparing Tg Specific % impressions
        if (
          sortConfig.label === "Tg %" &&
          getTgSpecificPercentage(valueTwo) > getTgSpecificPercentage(valueOne)
        ) {
          return 1;
        }

        //if value one is greater than value two it returns ascending order
        if (
          sortConfig.label === "Tg %" &&
          getTgSpecificPercentage(valueTwo) < getTgSpecificPercentage(valueOne)
        ) {
          return -1;
        }

        //comparing total Impressions
        if (
          sortConfig.label === "Total Imp" &&
          getTotalOts(valueOne) < getTotalOts(valueTwo)
        ) {
          return 1;
        }

        if (
          sortConfig.label === "Total Imp" &&
          getTotalOts(valueOne) > getTotalOts(valueTwo)
        ) {
          return -1;
        }

        //comparing tg Specific
        if (
          sortConfig.label === "Tg Imp" &&
          getTgOts(valueOne) < getTgOts(valueTwo)
        ) {
          return 1;
        }

        if (
          sortConfig.label === "Tg Imp" &&
          getTgOts(valueOne) > getTgOts(valueTwo)
        ) {
          return -1;
        }

        return 0;
      });
    }
    // if the sort config is null it will return unsorted data
    return sortableItems;
  }, [roadsData, sortConfig]);

  const requestSort = (key, label) => {
    setSortConfig({ key, label });
  };

  return [sortedItems, requestSort, sortConfig];
};

export { useSortableData, SortingBtn };
