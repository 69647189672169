import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//Actions
import {
  addCitiesToCampaign,
  removeCityFromCampaign,
  updateCampaignDuration,
  updateCampaignTitle,
} from "../../../../actions/campaign/CampaignActions";
import { openMultiCitySelectionForm } from "../../../../actions/campaign-planning/MultiCitySelectionFormActions";
import { getRegionNames } from "../../../../actions/regions/RegionActions";
import { getCampaignOverview } from "../../../../actions/campaign-report/CampaignReportActions";

// Urls
import { RedirectTo } from "../../../../urls/PageLinksURL";

// Constants and Utils
import { FormDataTargets } from "../../../../constants/GeneralConstants";
import { ManageMonitoringCell } from "../../../../utils/MonitoringUtils";
import { constructRedirectPath } from "../../../../utils/redirect-utils/RedirectUtils";

// Components
import PageHeader from "../../../../mavin/components/page-header/PageHeader";
import Spinner from "../../../../components/spinner/Spinner";
import TableHeaders from "../../../../components/table/TableHeaders";
import CampaignSidebar from "../../../../components/campaign-sidebar/CampaignSidebar";
import MultiCitySelectionForm from "../../../../mavin/components/multi-city-selection-form/MultiCitySelectionForm";
import {
  CityNameCell,
  DurationCell,
  HamburgerCell,
  MediaCountCell,
} from "../../../../components/campaign-table-row/CampaignRowCells";
import TitleEditor from "../../../../components/title-editor/TitleEditor";
import AlertMessage from "../../../../mavin/components/alert-message/AlertMessage";
import ConfirmationForm from "../../../../components/confirmation-form/ConfirmationForm";
import OverviewSection from "../../../../pages/campaign-report/campaign-view/OverviewSection";
import NoTableDataMessage from "../../../../components/table/NoTableDataMessage";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "City",
      className: "col-1",
    },
    subTitle: {
      displayName: "Status",
      className: "sub-text",
    },
  },
  {
    title: {
      displayName: "No. of sites",
      className: "col-1 align-top text-right",
    },
  },
  {
    title: {
      displayName: "Start and End date",
      className: "col-3 text-right",
    },
    subTitle: {
      displayName: "Duration",
      className: "sub-text",
    },
  },
  {
    title: {
      displayName: "Monitoring",
      className: "col-3 align-top text-right",
    },
  },
  {
    title: {
      displayName: "Actions",
      className: "col-3 text-right",
    },
  },
  {
    title: {
      displayName: "",
      className: "col-1",
    },
  },
];

// Page Components
function TableHeaderSection() {
  const dispatch = useDispatch();

  // Selector State
  const addCitiesLoading = useSelector(
    (state) => state.campaign.addCitiesLoading
  );
  // update campaignDuration
  const campaignDurationChanging = useSelector(
    (state) => state.campaign.campaignDurationChanging
  );

  return (
    <div className="navbar px-0 mt-5">
      <div className="d-flex">
        <h3 className="mr-3">{"Overview"}</h3>
        {campaignDurationChanging && (
          <AlertMessage
            className="py-1"
            spinnerClassName="spinner-border-sm"
            textMessage="Updating Metrics"
          />
        )}
      </div>
      <div className="row mx-0">
        {addCitiesLoading && <Spinner className="spinner-border-sm m-1" />}
        <button
          type="button"
          className="btn btn-link shadow-none p-0 mx-1"
          data-toggle="modal"
          data-target={`#${FormDataTargets.multiCitySelectionForm}`}
          onClick={() => dispatch(openMultiCitySelectionForm())}
        >
          {"+Add Cities"}
        </button>
      </div>
    </div>
  );
}

function CampaignPlanOverviewRow({ cityInfo }) {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const {
    cityId,
    startTimestamp,
    endTimestamp,
    duration,
    isMonitoringEnabled,
  } = cityInfo;

  const { campaign = {} } = useSelector(
    (state) => state.campaignReport.campaignSummary
  );

  const { targetGroupId = "" } = campaign;

  function onDateSelect(selectedDate) {
    dispatch(updateCampaignDuration(selectedDate, cityInfo));
  }
  const dateObj = { startTimestamp, endTimestamp };

  // Urls
  // link to city-specific-view
  const campaignPlanCityViewPageUrl = RedirectTo.campaignPlanCityViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);
  const campaignPlanMapViewPageUrl = RedirectTo.campaignPlanMapViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);

  // link to monitoring city view page
  const monitorCityViewPageUrl = RedirectTo.monitorCityViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);

  return (
    <tr>
      <CityNameCell
        cityElementStyle="align-middle"
        cityInfo={cityInfo}
        redirectUrl={campaignPlanCityViewPageUrl}
      />

      <MediaCountCell
        mediaCountElementStyle="p-3 text-right align-middle"
        cityInfo={cityInfo}
      />

      <DurationCell
        durationElementStyle="text-right pr-0 align-middle"
        duration={duration}
        onDateSelect={onDateSelect}
        dateObj={dateObj}
      />

      <ManageMonitoringCell
        isMonitoringEnabled={isMonitoringEnabled}
        className="text-right align-middle"
        redirectUrl={monitorCityViewPageUrl}
        target={"_blank"}
      />

      <td className="text-right align-middle">
        <Link
          to={{
            pathname: constructRedirectPath(campaignPlanMapViewPageUrl),
            state: { targetGroupId },
          }}
        >
          {"Select media on map"}
        </Link>
      </td>

      <HamburgerCell
        hamburgerElementStyle="text-right align-middle"
        cityInfo={cityInfo}
      />
    </tr>
  );
}

function CampaignPlanOverviewTable({ infoByCity = {} }) {
  const isCampaignHaveAnyCity = Object.keys(infoByCity).length > 0;

  return (
    <div className="table-responsive">
      <table className="table">
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
        <tbody>
          {!isCampaignHaveAnyCity && (
            <NoTableDataMessage
              colSpan={6}
              message="Campaign don't have any city"
            />
          )}

          {/* table rows */}
          {isCampaignHaveAnyCity &&
            Object.values(infoByCity).map((cityInfo) => (
              <CampaignPlanOverviewRow
                cityInfo={cityInfo}
                key={cityInfo.cityId}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}

function NoCitiesMessage({ infoByCity }) {
  if (Object.keys(infoByCity).length > 0) {
    return null;
  }

  return (
    <div className="bg-alt border p-2 text-center">
      <p className="mb-0">{"No Cities added to the Campaign"}</p>
    </div>
  );
}

/**
 * Page
 */
function CampaignPlanOverviewPage() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const { campaign = {}, summary = {} } = useSelector(
    (state) => state.campaignReport.campaignSummary
  );

  const infoByCity = campaign.infoByCity || {};

  const loading = useSelector(
    (state) => state.campaignReport.campaignSummaryLoading
  );

  // region Names
  const regionsName = useSelector((state) => state.region.regionsName) || [];

  // for "MULTIPLE-City-Selection-form"
  const openMultiCitySelectionForm = useSelector(
    (state) => state.multiCitySelectionModal.openModal
  );

  // Title Loading
  const titleLoader = useSelector((state) => state.campaign.titleUpdateLoader);

  // Confirmation Form :: [true/false and dataObject]
  const openConfirmationForm = useSelector(
    (state) => state.confirmationModal.openModal
  );
  const processDataObject = useSelector(
    (state) => state.confirmationModal.processDataObject
  );

  // storing infoByCity as {id: "", label: ""}
  const formInfoByCity = Object.values(infoByCity).map((city) => {
    return {
      id: city.cityId,
      label: city.cityName,
    };
  });

  //Dispatch
  useEffect(() => {
    dispatch(getCampaignOverview(campaignId));
    dispatch(getRegionNames());
  }, [dispatch, campaignId]);

  // Link to pass in CampaignSidebar
  const redirectLinkObj = {
    campaignOverviewLink: RedirectTo.campaignPlanOverviewPageUrl.replace(
      ":campaignId",
      campaignId
    ),
    citySummaryLink: RedirectTo.campaignPlanCityViewPageUrl.replace(
      ":campaignId",
      campaignId
    ),
  };

  // Functions
  function addCities(selectedCities) {
    // only calling API when a new-city is added
    if (selectedCities.length > formInfoByCity.length) {
      dispatch(addCitiesToCampaign(campaignId, selectedCities));
    }
  }

  // Functions to "remove city from existing campaign"
  function removeCity() {
    dispatch(removeCityFromCampaign(campaignId, processDataObject.cityId));
  }

  function PageTitle() {
    return (
      <TitleEditor
        title={campaign.title}
        onTitleChange={(titleStr) =>
          dispatch(updateCampaignTitle(titleStr, campaignId))
        }
        loader={titleLoader}
        inputBoxClass={"rounded-lg"}
        titleClass={"px-0 mb-0 h1"}
      />
    );
  }

  // Checks for page loading
  if (loading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <>
      {/* Sidebar */}
      <CampaignSidebar campaign={campaign} redirectLinkObj={redirectLinkObj} />

      {/* Main Content */}
      <div className="content-wrapper">
        {/* Page-Header */}
        <PageHeader title={<PageTitle />} />

        <div className="page-content">
          {/* Campaign Performance and Stats Card */}
          <OverviewSection campaign={campaign} summary={summary} />

          {/* Table Header section */}
          <TableHeaderSection />

          {/* No Cities Message */}
          <NoCitiesMessage infoByCity={infoByCity} />

          {/* Campaign Overview Table */}
          <CampaignPlanOverviewTable infoByCity={infoByCity} />
        </div>
      </div>

      {/* MODALS */}
      {openMultiCitySelectionForm && (
        <MultiCitySelectionForm
          regionsName={regionsName}
          onSubmit={addCities}
          citiesList={formInfoByCity}
          disabledCities={Object.keys(infoByCity)}
        />
      )}

      {/* Confirmation-Form */}
      {openConfirmationForm && <ConfirmationForm onClick={removeCity} />}
    </>
  );
}

export default CampaignPlanOverviewPage;
