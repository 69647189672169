import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import {
  getMonitoringCampaignSummary,
  getMonitoringCitySummary,
} from "../../../actions/campaign-monitoring/MonitoringSettingsActions";

// Constants and Utils
import { PageSize } from "../../../constants/GeneralConstants";
import {
  constructMediaLogAnalytics,
  getElapsedDays,
  groupItemsByDay,
  updateMediaLogs,
} from "../../../utils/MonitoringUtils";

// Url
import { RedirectTo } from "../../../urls/PageLinksURL";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import Pagination from "../../../components/pagination/Pagination";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import { InfoCell } from "../../../components/campaign-media-table-row/MediaRow";
import Spinner from "../../../components/spinner/Spinner";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Site Name",
    },
    subTitle: {
      displayName: "Media type, Dimensions, Lighting, Region",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "Contact",
    },
    subTitle: {
      displayName: "Email and Phone no.",
      className: "sub-text",
    },
  },
  {
    title: {
      displayName: "Day 1",
    },
    subTitle: {
      displayName: "Date",
      className: "sub-text",
    },
  },
  {
    title: {
      displayName: "Day 2",
    },
    subTitle: {
      displayName: "Date",
      className: "sub-text",
    },
  },
  {
    title: {
      displayName: "Day 3",
    },
    subTitle: {
      displayName: "Date",
      className: "sub-text",
    },
  },
  {
    title: {
      displayName: "Day 4",
    },
    subTitle: {
      displayName: "Date",
      className: "sub-text",
    },
  },
  {
    title: {
      displayName: "Day 5",
    },
    subTitle: {
      displayName: "Date",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "Day 6",
    },
    subTitle: {
      displayName: "Date",
      className: "sub-text",
    },
  },
  {
    title: {
      displayName: "Day 7",
    },
    subTitle: {
      displayName: "Date",
      className: "sub-text",
    },
  },
  {
    title: {
      displayName: "Job done",
    },
    subTitle: {
      displayName: "Default %",
      className: "sub-text",
    },
  },
];
// Page Components
function ContactCell({ monitorUser }) {
  if (Object.keys(monitorUser).length === 0) {
    return <td className="p-2"></td>;
  }
  const { name = "", emailId = "", phoneNo = "" } = monitorUser;

  return (
    <td className="p-2">
      <span className="d-block">{name}</span>
      <span className="d-block">{emailId}</span>
      <span className="d-block">{phoneNo}</span>
    </td>
  );
}

function MediaLogCells({ updatedMediaLogsDayWise }) {
  if (Object.keys(updatedMediaLogsDayWise).length === 0) {
    return (
      <>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </>
    );
  }

  const mediaLogDays = Object.keys(updatedMediaLogsDayWise);
  const elapsedDays = getElapsedDays(mediaLogDays);

  //  URLs Array till days completed till now
  const urlsArray = elapsedDays.map((date) => ({
    ...updatedMediaLogsDayWise[date],
    date: date,
  }));

  // Extract last 7 days  add null values at the beginning if fewer than 7
  const recentUrlsArray = Array(7)
    .fill(null)
    .concat(urlsArray.slice(-7))
    .slice(-7);

  return (
    <>
      {recentUrlsArray.map((eachUrlObj, i) => {
        return (
          <td key={i} className={eachUrlObj ? "" : "bg-secondary"}>
            {eachUrlObj && (
              <>
                <img
                  src={eachUrlObj?.latestImageUrl?.url}
                  className="log-image"
                />
                <p className="mb-0 mt-2">{eachUrlObj?.date}</p>
              </>
            )}
          </td>
        );
      })}
    </>
  );
}
function AnalyticsCell({ mediaAnalytics = {} }) {
  const { duration, jobDoneCount, defaultedPercentage } = mediaAnalytics;

  return (
    <td>
      <p className="mb-0">
        {jobDoneCount} of {duration}
      </p>
      <p>{defaultedPercentage?.toFixed(1)} %</p>
    </td>
  );
}

/**
 * Monitoring Campaign CityView Table ROW -------------------------------------
 */
function MonitoringCampaignCityViewTableRow({ item }) {
  const { media, monitorUser, monitorLogs, settings = {} } = item;
  const mediaLogsDayWise = groupItemsByDay(monitorLogs);
  let updatedMediaLogsDayWise;

  if (settings && Object.keys(settings).length > 0) {
    updatedMediaLogsDayWise = updateMediaLogs(
      settings.startTimestamp,
      settings.endTimestamp,
      settings.frequencyDays,
      mediaLogsDayWise
    );
  } else {
    updatedMediaLogsDayWise = {};
  }
  const mediaAnalytics = constructMediaLogAnalytics(updatedMediaLogsDayWise);
  const { campaignId, id: mediaId } = media;

  // Redirect Url
  const monitorMediaLogPageUrl = RedirectTo.monitorMediaLogPageUrl
    .replace(":campaignId", campaignId)
    .replace(":mediaId", mediaId);

  return (
    <tr>
      <InfoCell
        mediaElementStyle="p-2"
        media={media}
        redirectUrl={monitorMediaLogPageUrl}
      />

      {monitorUser && Object.keys(monitorUser).length > 0 && (
        <ContactCell monitorUser={monitorUser} />
      )}

      {monitorUser && Object.keys(monitorUser).length === 0 && <td></td>}
      <MediaLogCells updatedMediaLogsDayWise={updatedMediaLogsDayWise} />

      {Object.keys(updatedMediaLogsDayWise).length > 0 && (
        <AnalyticsCell mediaAnalytics={mediaAnalytics} />
      )}
    </tr>
  );
}

/**
 * Campaign Campaign-cityview table
 */
function MonitoringCampaignCitySummaryTable({
  citySummaryItems,
  pagination,
  loadCityMedia,
}) {
  const { totalCount, pageNo, pageSize } = pagination;
  return (
    <div className="table-responsive mt-4">
      <table className="table monitoring-table-media table-bordered">
        {/* Table Header */}
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />

        {/* Table Row */}
        <tbody>
          {citySummaryItems.map((item, i) => (
            <MonitoringCampaignCityViewTableRow item={item} key={i} />
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={loadCityMedia}
      />
    </div>
  );
}

export default function MonitoringCampaignCitySummaryPage() {
  const { id, cityId } = useParams();

  // Selector state
  //  monitoring city-summary
  const citySummaryLoading = useSelector(
    (state) => state.monitoringSettings.monitoringCitySummaryLoading
  );
  const monitoringCitySummary =
    useSelector((state) => state.monitoringSettings.monitoringCitySummary) ||
    {};
  const citySummaryItems = monitoringCitySummary.items || [];
  const pagination = monitoringCitySummary.pagination || {};

  // monitoring campaign summary
  // for "total MediaCount" and "CampaignTitle"
  const campaignSummaryLoading = useSelector(
    (state) => state.monitoringSettings.campaignSummaryLoading
  );
  const monitoringCampaignSummary =
    useSelector((state) => state.monitoringSettings.campaignSummary) || {};
  const campaign = monitoringCampaignSummary?.campaign;
  const citySummary = campaign?.infoByCity?.[cityId] || {};
  const citySelected = {
    cityId: cityId,
    cityName: citySummary.cityName || cityId,
  };

  // Link to pass in CampaignSidebar
  const redirectLinkObj = {
    campaignOverviewLink: RedirectTo.monitorCampaignViewPageUrl.replace(
      ":campaignId",
      id
    ),
    citySummaryLink: RedirectTo.monitorCitySummaryPageUrl.replace(
      ":campaignId",
      id
    ),
  };

  const pageNumber = 1,
    pageSizeCount = PageSize.MonitoringCitySummary;

  const pageTitle = campaign?.title + " :: " + citySelected?.cityName;

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMonitoringCampaignSummary(id));
    dispatch(getMonitoringCitySummary(id, cityId, pageNumber, pageSizeCount));
  }, [dispatch, id, cityId, pageSizeCount]);

  // Functions
  function loadCityMedia(pageNumber, pageSizeCount) {
    dispatch(
      getMonitoringCitySummary(id, cityId, pageNumber, pageSizeCount, "", true)
    );
  }

  // Page-loading
  if (citySummaryLoading || campaignSummaryLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <>
      <CampaignSidebar
        campaign={campaign}
        citySelected={citySelected}
        redirectLinkObj={redirectLinkObj}
      />

      {/* Page-Content */}
      <div className="content-wrapper">
        {/* Page-Header */}
        <PageHeader title={pageTitle} />

        <div className="page-content">
          {/* Action and Filter Section */}

          {/* monitoring city summary table */}
          <MonitoringCampaignCitySummaryTable
            citySummaryItems={citySummaryItems}
            pagination={pagination}
            loadCityMedia={loadCityMedia}
          />
        </div>
      </div>
    </>
  );
}
