import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  selectMetaDataMarker,
  unSelectMetaDataMarker,
} from "../../../actions/map-view/MetaDataActions";
import {
  addMediaInfluenceCircle,
  removeMediaInfluenceCircle,
  removeMediaMarkers,
  setMediaInfluenceCircleRadius,
  showMediaMarkers,
} from "../../../actions/map-view/media-sites/LegendAndInfluenceActions";
import { setInventoryMediaFillColor } from "../../../actions/map-view/media-sites/ExploreSitesActions";
import { setCampaignMediaFillColor } from "../../../actions/map-view/media-sites/MediaSitesActions";

// Constants and Utils
import { CheckBox } from "./MapControlsUtils";
import { getItem } from "../../../utils/localstorage";
import {
  LocalStorageKeys,
  OrganizationIdsMapping,
  PatrikaSellerId,
  RadiusConstants,
} from "../../../constants/GeneralConstants";
import { checkIsOrgSpecific } from "../../../utils/OrgUtils";

// Components
import CardCollapsible from "../../../components/card-collapsible/CardCollapsible";

// Page Components
function MediaSitesCheckBoxes({ settings, toggleMediaSitesMarker }) {
  const dispatch = useDispatch();
  const { id: campaignId } = useParams();

  const { fillColor } = settings;

  // Selector States
  const campaignMediaList = useSelector(
    (state) => state.mapViewMediaSites.filteredMediaSites
  );
  const exploreMediaList = useSelector(
    (state) => state.mapViewExploreSites.exploreSites
  );
  const mediaTypeToShow = useSelector(
    (state) => state.legendAndInfluence.mediaTypeToShow
  );

  const user = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  const { orgId } = user;
  const { patrika } = OrganizationIdsMapping;
  const isPatrikaSpecific = checkIsOrgSpecific(orgId, patrika);

  const getPatrikaInventory = useCallback(
    (mediaList) => {
      return mediaList.filter((media) => media.sellerId === PatrikaSellerId);
    },
    [isPatrikaSpecific]
  );

  const updatedMediaList = isPatrikaSpecific
    ? getPatrikaInventory(exploreMediaList)
    : exploreMediaList;

  // calculating the mediaCount for legend according to "SelectedMediaType"
  const campaignMediaCount = mediaTypeToShow.campaignMedia
    ? campaignMediaList.length
    : 0;
  const inventoryMediaCount = mediaTypeToShow.inventoryMedia
    ? updatedMediaList.length
    : 0;

  return (
    <div className="sec">
      <p className="sec-title">{"Media Sites"}</p>
      {/* CampaignMedia Checkbox */}
      {campaignId && (
        <div className="d-flex justify-content-between">
          <CheckBox
            id={"chk-media-camp"}
            name={"campaignMedia"}
            labelText={`Media Selected (${campaignMediaCount})`}
            checked={settings.campaignMedia}
            onChange={toggleMediaSitesMarker}
          />
          <input
            type="color"
            id="campaign-media-color-picker"
            className="form-control border-0 p-0 col-2 h-auto shadow-none"
            value={fillColor.campaignMedia}
            onChange={({ target }) =>
              dispatch(setCampaignMediaFillColor(target.value))
            }
          ></input>
        </div>
      )}

      {/* InventoryMedia Checkbox */}
      <div className="d-flex justify-content-between">
        <CheckBox
          id={"chk-media-inventory"}
          name={"inventoryMedia"}
          labelText={`Media Inventory (${inventoryMediaCount})`}
          checked={settings.inventoryMedia}
          onChange={toggleMediaSitesMarker}
        />
        <input
          type="color"
          id="inventory-media-color-picker"
          className="form-control border-0 p-0 col-2 h-auto shadow-none"
          value={fillColor.inventoryMedia}
          onChange={({ target }) =>
            dispatch(setInventoryMediaFillColor(target.value))
          }
        ></input>
      </div>
    </div>
  );
}

function MediaMetaDataCheckBoxes({ metadata, toggleMetaDataMarkerText }) {
  const mediaMetaDataConstants = [
    {
      id: "chk-media-meta-title",
      name: "title",
      labelText: "Title",
      checked: metadata.title,
    },
    {
      id: "chk-media-meta-image",
      name: "image",
      labelText: "Image",
      checked: metadata.image,
    },
    {
      id: "chk-media-meta-dimensions",
      name: "dimensions",
      labelText: "Dimensions",
      checked: metadata.dimensions,
    },
    {
      id: "chk-media-meta-lts",
      name: "lts",
      labelText: "Lts",
      checked: metadata.lts,
    },
    {
      id: "chk-media-meta-ots",
      name: "ots",
      labelText: "Impressions",
      checked: metadata.ots,
    },
  ];

  return (
    <div className="sec">
      <p className="sec-title">{"Media Metadata"}</p>
      {mediaMetaDataConstants.map((item, i) => (
        <CheckBox
          key={i}
          id={item.id}
          name={item.name}
          labelText={item.labelText}
          checked={item.checked}
          onChange={toggleMetaDataMarkerText}
        />
      ))}
    </div>
  );
}

function MediaInfluenceCircle({ showMediaInfluenceCircle }) {
  const dispatch = useDispatch();

  const mediaInfluenceCircleRadius = useSelector(
    (state) => state.legendAndInfluence.mediaInfluenceCircleRadius
  );

  // Function
  function toggleMediaInfluenceCircle() {
    const action = showMediaInfluenceCircle
      ? removeMediaInfluenceCircle
      : addMediaInfluenceCircle;
    dispatch(action());
  }

  return (
    <div className="sec">
      <CheckBox
        id={"chk-media-cirle"}
        name={"mediaInfluenceCircle"}
        labelText={"Show Media Influence Circle"}
        checked={showMediaInfluenceCircle}
        onChange={toggleMediaInfluenceCircle}
      />

      <div className="form-group">
        <label htmlFor="select-media-circle">{"Radius of Influence"}</label>
        <select
          id="select-media-circle"
          value={mediaInfluenceCircleRadius}
          className="form-control shadow-none rounded-lg"
          onChange={(e) =>
            dispatch(setMediaInfluenceCircleRadius(e.target.value))
          }
        >
          {RadiusConstants.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

// Controls :: MediaSites
function MediaControlsPanel({ settings = {}, count = 0 }) {
  const dispatch = useDispatch();

  const { metadata = {}, circle: showMediaInfluenceCircle } = settings;

  // Functions
  // toggles metaData text
  function toggleMetaDataMarkerText(metadataKey) {
    const action = metadata[metadataKey]
      ? unSelectMetaDataMarker
      : selectMetaDataMarker;
    dispatch(action(metadataKey));
  }

  // toggles media-sites markers
  function toggleMediaSitesMarker(mediaTypeKey) {
    const action = settings[mediaTypeKey]
      ? removeMediaMarkers
      : showMediaMarkers;
    dispatch(action(mediaTypeKey));
  }

  return (
    <CardCollapsible
      id="media-controls"
      header={"Media Settings"}
      subHeader={`Showing ${count} Media Markers`}
    >
      {/* Media Sites */}
      <MediaSitesCheckBoxes
        settings={settings}
        toggleMediaSitesMarker={toggleMediaSitesMarker}
      />

      {/* Media MetaData */}
      <MediaMetaDataCheckBoxes
        metadata={metadata}
        toggleMetaDataMarkerText={toggleMetaDataMarkerText}
      />

      {/* mediaSites Influence Circle */}
      <MediaInfluenceCircle
        showMediaInfluenceCircle={showMediaInfluenceCircle}
      />
    </CardCollapsible>
  );
}

export default MediaControlsPanel;
