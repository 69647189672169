import { useDispatch } from "react-redux";

// Actions
import { setPoiStatus } from "../../../actions/map-view/poi/PoiActions";
import { unSelectPoiBrands } from "../../../actions/map-view/poi/PoiSelectionActions";

// Constants
import { Status } from "../../../constants/GeneralConstants";

// Page Components
function SelectedPoiList({ eachBrandName, eachBrandData }) {
  const dispatch = useDispatch();

  if (!eachBrandData || eachBrandData.length === 0) {
    return null;
  }

  // BrandGroup
  const brandGroup = eachBrandData[0]?.group;

  // to Remove Selected Brand
  function onClickRemoveBrand() {
    dispatch(setPoiStatus(eachBrandName, Status.UNCHECKED));
    dispatch(unSelectPoiBrands([eachBrandName]));
  }

  return (
    <div className="d-flex justify-content-between p-2 border-bottom">
      <span>
        {brandGroup}
        {" > "}
        {eachBrandName} ({eachBrandData.length})
      </span>
      <button
        className="btn add-button shadow-none btn-outline-primary"
        onClick={onClickRemoveBrand}
      >
        {"Remove"}
      </button>
    </div>
  );
}

function SelectedPoiSection({ selectedBrandData }) {
  return (
    <>
      {Object.keys(selectedBrandData).map((eachBrandName) => (
        <SelectedPoiList
          key={eachBrandName}
          eachBrandData={selectedBrandData[eachBrandName]}
          eachBrandName={eachBrandName}
        />
      ))}
    </>
  );
}

export default SelectedPoiSection;
