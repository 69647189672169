/* eslint-disable no-case-declarations */
import { OrgProfile } from "../../constants/action-constants/org/OrgProfileActionConstants";

const initialState = {
  loading: false,
  orgProfileInfo: {},
  orgProfileLogo: "",
  error: "",

  //update org profile
  orgProfileLoading: false,

  //update org logo
  orgProfileLogoLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //get org profile
    case OrgProfile.GET_ORG_PROFILE:
      return {
        ...state,
        loading: true,
      };

    case OrgProfile.GET_ORG_PROFILE_SUCCESS:
      const { orgProfile } = action.payload;
      return {
        ...state,
        loading: false,
        orgProfileInfo: orgProfile,
        orgProfileLogo: orgProfile.logoURL,
      };
    case OrgProfile.GET_ORG_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    //update org profile
    case OrgProfile.UPDATE_ORG_PROFILE: {
      return {
        ...state,
        orgProfileLoading: true,
      };
    }
    case OrgProfile.UPDATE_ORG_PROFILE_SUCCESS: {
      return {
        ...state,
        orgProfileLoading: false,
      };
    }
    case OrgProfile.UPDATE_ORG_PROFILE_FAILURE: {
      return {
        ...state,
        orgProfileLoading: false,
        error: action.payload,
      };
    }

    //update org profile logo
    case OrgProfile.UPDATE_ORG_PROFILE_LOGO: {
      return {
        ...state,
        orgProfileLogoLoading: true,
      };
    }
    case OrgProfile.UPDATE_ORG_PROFILE_LOGO_SUCCESS: {
      return {
        ...state,
        orgProfileLogoLoading: false,
        orgProfileLogo: action.payload.logo,
      };
    }
    case OrgProfile.UPDATE_ORG_PROFILE_LOGO_FAILURE: {
      return {
        ...state,
        orgProfileLogoLoading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
