import { toast } from "react-toastify";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// API
import {
  archiveRoadStretch,
  createRoadStretch,
  getRoadStretchesByIds,
  getRoadStretchesOfOrg,
  restoreRoadStretch,
  getRoadStretchInfoByIdFn,
  roadStretchPriceRefreshFn,
} from "../../apis/OrgRoadStretchAPI";
import { closestRoadSegmentsData } from "../../apis/RoadSegmentAPI";

// Contants and Utils
import { CreateNewRoadStretches, RoadStretch } from "../../constants/action-constants/RoadStretchActionConstants";
import { getErrorMessage } from "../../utils/util";

export function* getRoadStretches(action) {
  const { stretchIds } = action.payload;
  try {
    // api response for "road-stretch-details"
    const roadStretchDetailsMap = yield getRoadStretchesByIds(stretchIds);
    yield put({
      type: RoadStretch.GET_ROAD_STRETCHES_BY_IDS_SUCCESS,
      payload: { roadStretchDetailsMap },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: RoadStretch.GET_ROAD_STRETCHES_BY_IDS_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* getRoadStretchesOfOrganization(action) {
  const { excludeArchive, cityId, keyWord, pageNumber, pageSize } = action.payload;
  try {
    // api response for "road-stretch-details"
    const result = yield getRoadStretchesOfOrg(excludeArchive, cityId, keyWord, pageNumber, pageSize);

    yield put({
      type: RoadStretch.GET_ROAD_STRETCHES_OF_ORG_SUCCESS,
      payload: { roadStretches: result.items, pagination: result.pagination },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: RoadStretch.GET_ROAD_STRETCHES_OF_ORG_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* archiveStretch(action) {
  const { stretchId } = action.payload;
  try {
    yield archiveRoadStretch(stretchId);
    yield put({
      type: RoadStretch.UPDATE_ROAD_STRETCH_STATUS,
      payload: { id: stretchId, isArchived: true },
    });
    yield put({
      type: RoadStretch.ARCHIVE_ROAD_STRETCH_SUCCESS,
      payload: { stretchId },
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: RoadStretch.ARCHIVE_ROAD_STRETCH_FAILURE,
      payload: { stretchId, errMsg },
    });
    toast.error(errMsg);
  }
}

function* restoreStretch(action) {
  const { stretchId } = action.payload;
  try {
    yield restoreRoadStretch(stretchId);
    yield put({
      type: RoadStretch.UPDATE_ROAD_STRETCH_STATUS,
      payload: { id: stretchId, isArchived: false },
    });
    yield put({
      type: RoadStretch.RESTORE_ROAD_STRETCH_SUCCESS,
      payload: { stretchId },
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: RoadStretch.RESTORE_ROAD_STRETCH_FAILURE,
      payload: { stretchId, errMsg },
    });
    toast.error(errMsg);
  }
}

// for creating a new Rsad Stretch
export function* createStretch(action) {
  const { stretchName, segmentIds, history } = action.payload;
  try {
    const stretchBean = { name: stretchName, roadSegmentIds: segmentIds };

    yield createRoadStretch(stretchBean);
    yield put({
      type: CreateNewRoadStretches.CREATE_ROAD_STRETCH_SUCCESS,
    });

    // Here we are resetting the state of createRoadStretch Reducer.
    yield put({
      type: CreateNewRoadStretches.RESET,
    });

    // Redirecting to the Dashboard
    history.push("/org/road-stretch/list");

    // Success message on the Dashboard Page
    toast.success(`Successfully created Road-Stretch : ${stretchName}`);
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: CreateNewRoadStretches.CREATE_ROAD_STRETCH_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* getClosestRoadSegments(action) {
  const { locationStr, inclNullableTraffic } = action.payload;

  try {
    const roadSegments = yield closestRoadSegmentsData(locationStr, inclNullableTraffic);

    yield put({
      type: CreateNewRoadStretches.GET_CLOSEST_ROAD_SG_SUCCESS,
      payload: { roadSegments: roadSegments },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: CreateNewRoadStretches.GET_CLOSEST_ROAD_SG_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

// Single Road-Stretch Info By ID Saga
export function* getRoadStretchInfoById(action) {
  const { stretchId } = action.payload;

  try {
    const stretchInfo = yield getRoadStretchInfoByIdFn(stretchId);

    yield put({
      type: RoadStretch.GET_ROAD_STRETCH_INFO_BY_ID_SUCCESS,
      payload: { stretchInfo },
    });
  } catch (error) {
    const errMsg = getErrorMessage(error);
    yield put({
      type: RoadStretch.GET_ROAD_STRETCH_INFO_BY_ID_FAILURE,
      payload: error,
    });
    toast.error(errMsg);
  }
}

export function* RoadStretchPriceRefresh(action) {
  const { stretchId } = action.payload;

  try {
    yield roadStretchPriceRefreshFn(stretchId);

    yield put({
      type: RoadStretch.ROAD_STRETCH_PRICE_REFRESH_SUCCESS,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: RoadStretch.ROAD_STRETCH_PRICE_REFRESH_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(RoadStretch.GET_ROAD_STRETCHES_BY_IDS, getRoadStretches),

    takeLatest(RoadStretch.GET_ROAD_STRETCHES_OF_ORG, getRoadStretchesOfOrganization),

    takeLatest(RoadStretch.ARCHIVE_ROAD_STRETCH, archiveStretch),
    takeLatest(RoadStretch.RESTORE_ROAD_STRETCH, restoreStretch),
    takeEvery(CreateNewRoadStretches.GET_CLOSEST_ROAD_SG, getClosestRoadSegments),
    takeLatest(CreateNewRoadStretches.CREATE_ROAD_STRETCH, createStretch),
    takeEvery(RoadStretch.GET_ROAD_STRETCH_INFO_BY_ID, getRoadStretchInfoById),
    takeEvery(RoadStretch.ROAD_STRETCH_PRICE_REFRESH, RoadStretchPriceRefresh),
  ]);
}
