//Utils
import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

//Constants
import {
  getOrgProfileUrl,
  updateOrgProfileUrl,
  updateOrgProfileLogoUrl,
} from "../urls/OrgProfileURL";

export async function getOrgProfileInfo() {
  return sendGetRequest(getOrgProfileUrl);
}

export async function updateOrgProfileInfo(orgProfileObj) {
  return sendCreateOrUpdateRequest(
    updateOrgProfileUrl,
    orgProfileObj,
    true,
    "put"
  );
}

export async function updateOrgProfileLogoFn(file) {
  const formData = new FormData();
  formData.append("files", file);
  return sendCreateOrUpdateRequest(updateOrgProfileLogoUrl, formData);
}
