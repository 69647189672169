import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

// Actions
import { setInventoryMediaAppliedFilters } from "../../../actions/map-view/media-sites/ExploreSitesActions";

// Constants and Utils
import { filterMedia, sortMediaByImpression } from "../../../utils/MediaUtils";
import { MediaInventoryOptions } from "../../../constants/GeneralConstants";

// Components
import { PlainDropdown } from "../../../components/dropdown/Dropdown";
import MediaFilter from "../../../components/media-filter/MediaFilter";

export function SortMediaList({ onSortingApplied, className = "" }) {
  const sortItems = [
    { id: "none", label: "None" },
    { id: "ots", label: "Impression" },
    { id: "lts", label: "Lts" },
  ];

  return (
    <div className="sort-btn">
      <PlainDropdown
        items={sortItems}
        className={`btn shadow-none border ${className}`}
        placeHolder="Sort By"
        onItemSelect={onSortingApplied}
        dropMenuClassName="dropdown-menu-right"
      />
    </div>
  );
}

// Media Inventory Switch
export function MediaInventorySwitch({
  inventoryToShow,
  setInventoryToShow = () => {},
  className = "",
}) {
  const sortItems = [
    {
      id: MediaInventoryOptions.campaign,
      label: "Campaign Media",
      className: "mr-2",
      title: "Click to switch to campaign media list",
    },
    {
      id: MediaInventoryOptions.org,
      label: "Org Media",
      title: "Click to switch to org media list",
    },
  ];

  function onSortingApplied(id) {
    // constants
    const { campaign, org } = MediaInventoryOptions;

    if (id === campaign) {
      setInventoryToShow(campaign);
      return;
    }

    setInventoryToShow(org);
  }

  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        {sortItems.map((item) => {
          const { id, label, className = "", title } = item;
          const activeStyle = inventoryToShow === id ? "btn-primary" : "border";
          return (
            <button
              key={id}
              title={title}
              className={`btn shadow-none px-3 ${className} ${activeStyle}`}
              onClick={() => onSortingApplied(id)}
            >
              {label}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export function SelectedFilter({ filters }) {
  const { mediaTypes = [] } = filters;
  return (
    <div className="row mx-0">
      {mediaTypes.map((info, i) => (
        <div
          key={i}
          className="d-flex border align-items-center px-2 py-1 mr-2 mt-2"
        >
          <p className="mb-0 font-weight-bold">{info}</p>
        </div>
      ))}
    </div>
  );
}

export function SearchFilterAndSortMediaSection({
  mediaList = [],
  setExploreSitesList = () => {},
  listingViewLabels,
  isOrgMedia,

  // switch inventory specific
  inventoryToShow,
  setInventoryToShow = () => {},
}) {
  const dispatch = useDispatch();
  const cityId = mediaList.length > 0 && mediaList[0]?.cityId;
  // State
  // sort and search
  const [searchQuery, setSearchQuery] = useState("");
  const [sortingKey, setSortingKey] = useState("none");

  // Selector
  const inventoryMediaFilterObj = useSelector(
    (state) => state.mapViewExploreSites.inventoryMediaFilterObj
  );

  // just to call this function for initial render
  useEffect(() => {
    doFilterAndSort(searchQuery, inventoryMediaFilterObj, sortingKey);
  }, [mediaList.length]);

  useEffect(() => {
    if (cityId) {
      doFilterAndSort(searchQuery, inventoryMediaFilterObj, sortingKey);
    }
  }, [cityId]);

  // filter and sort function
  function doFilterAndSort(searchedText, appliedFilters, sortKey) {
    // updating searched text
    setSearchQuery(searchedText);

    // updating filter state
    dispatch(setInventoryMediaAppliedFilters(appliedFilters));

    // updating sort key
    setSortingKey(sortKey);

    const filtersObj = { ...appliedFilters };

    // search will be executed when searchedText length is greater than 1
    if (searchedText.length > 1) {
      filtersObj.title = searchedText;
    }

    // filter media util
    const filteredMedia = filterMedia(mediaList, filtersObj, isOrgMedia);
    const result = sortMediaByImpression(filteredMedia, sortKey);

    // updating the state
    setExploreSitesList(result);
  }
  const { search } = listingViewLabels;

  return (
    <>
      <div className="d-flex align-items-center col-12 pl-0 mt-2">
        {/* Search input */}
        <input
          type="search"
          className="form-control shadow-none col"
          placeholder={search.placeholder}
          onChange={({ target }) =>
            doFilterAndSort(target.value, inventoryMediaFilterObj, sortingKey)
          }
        />

        {/* mantaray specific dropdown for switching inventory  */}
        {inventoryToShow && (
          <MediaInventorySwitch
            className="ml-2"
            inventoryToShow={inventoryToShow}
            setInventoryToShow={setInventoryToShow}
          />
        )}

        {/*Sort media dropdown */}
        <SortMediaList
          className="mx-2 px-3 col"
          onSortingApplied={(sortKey) =>
            doFilterAndSort(searchQuery, inventoryMediaFilterObj, sortKey)
          }
        />

        {/* media filter */}
        <MediaFilter
          className="px-0 ml-3 col"
          onFiltersApplied={(appliedFilters) =>
            doFilterAndSort(searchQuery, appliedFilters, sortingKey)
          }
          isOrgMedia={isOrgMedia}
          mediaFilterObj={inventoryMediaFilterObj}
        />
      </div>

      {/* Shows selected media types */}
      <SelectedFilter filters={inventoryMediaFilterObj} />
    </>
  );
}
