import { OrgProfile } from "../../constants/action-constants/org/OrgProfileActionConstants";

export const getOrgProfile = () => ({
  type: OrgProfile.GET_ORG_PROFILE,
});

export const updateOrgProfile = (orgProfileObj) => ({
  type: OrgProfile.UPDATE_ORG_PROFILE,
  payload: { orgProfileObj },
});

export const updateOrgProfileLogo = (files) => ({
  type: OrgProfile.UPDATE_ORG_PROFILE_LOGO,
  payload: { files },
});
