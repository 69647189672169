import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Apis
import { getSavedMapState } from "../../../apis/map-view/SaveMapStateAPI";

// Constants and Utils
import { ActionTypes } from "../../constants/ActionConstants";
import { getErrorMessage } from "../../../utils/util";

// Update CampaignPlan State
export function* getMapState(action) {
  const { campaignId, cityId } = action.payload;
  try {
    const mapState = yield getSavedMapState(campaignId, cityId);

    yield put({
      type: ActionTypes.MapState.GET_MAP_STATE_SUCCESS,
      payload: { mapState },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.MapState.GET_MAP_STATE_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.MapState.GET_MAP_STATE, getMapState)]);
}
