/* eslint-disable no-case-declarations */
import { Campaign } from "../../constants/action-constants/CampaignActionConstants";

const initialState = {
  //get campaigns-------------------------------
  campaigns: [],
  error: "",
  loading: false,

  //create campaign loader----------------------------------
  createCampaignLoader: false,

  //Title update loader ---------------------------------------
  titleUpdateLoader: false,

  //update campaign duration-------------------------------------
  campaignDurationChanging: false,

  // shared campaign
  sharedCampaignList: [],
  sharedCampaignLoading: false,

  // add cities to existing campaign -------------------------------
  addCitiesLoading: false,
  addCitiesError: "",

  // remove city from campaign -------------------------------
  removeCityLoading: false,
  removeCityError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Get all campaigns
    //---------------------------------------------------------------
    case Campaign.GET_CAMPAIGNS:
      return {
        ...state,
        loading: true,
      };
    case Campaign.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
        loading: false,
      };
    case Campaign.GET_CAMPAIGNS_FAILURE:
      return {
        ...state,
        err: action.payload,
        loading: false,
      };

    //Update campaign title
    //-----------------------------------------------------------------
    case Campaign.UPDATE_CAMPAIGN_TITLE:
      return {
        ...state,
        titleUpdateLoader: true,
      };

    case Campaign.UPDATE_CAMPAIGN_TITLE_SUCCESS:
      return {
        ...state,
        titleUpdateLoader: false,
      };
    case Campaign.UPDATE_CAMPAIGN_TITLE_FAILURE:
      return {
        ...state,
        titleUpdateLoader: false,
        err: action.payload,
      };

    //create campaign
    //-----------------------------------------------------------------
    case Campaign.CREATE_CAMPAIGN: {
      return {
        ...state,
        createCampaignLoader: true,
      };
    }
    case Campaign.CREATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        createCampaignLoader: false,
      };
    }
    case Campaign.CREATE_CAMPAIGN_FAILURE: {
      return {
        ...state,
        createCampaignLoader: false,
        error: action.payload,
      };
    }

    //update campaign duration
    //--------------------------------------------------------------
    case Campaign.UPDATE_CAMPAIGN_DURATION:
      return {
        ...state,
        campaignDurationChanging: true,
      };

    case Campaign.UPDATE_CAMPAIGN_DURATION_SUCCESS:
      return {
        ...state,
        campaignDurationChanging: false,
      };
    case Campaign.UPDATE_CAMPAIGN_DURATION_FAILURE:
      return {
        ...state,
        campaignDurationChanging: false,
        error: action.payload,
      };

    // Get shared campaigns
    //------------------------------------------------------------------
    case Campaign.GET_SHARED_CAMPAIGN:
      return {
        ...state,
        sharedCampaignLoading: true,
      };
    case Campaign.GET_SHARED_CAMPAIGN_SUCCESS:
      const sharedCampaignList = action.payload.sharedCampaignList;
      return {
        ...state,
        sharedCampaignLoading: false,
        sharedCampaignList: sharedCampaignList.items,
      };
    case Campaign.GET_SHARED_CAMPAIGN_FAILURE:
      return {
        ...state,
        sharedCampaignLoading: false,
        error: action.payload,
      };

    // add cities to existing campaign
    // -----------------------------------------------------------------
    case Campaign.ADD_CITIES_TO_EXISTING_CAMPAIGN:
      return {
        ...state,
        addCitiesLoading: true,
      };
    case Campaign.ADD_CITIES_TO_EXISTING_CAMPAIGN_SUCCESS:
      return {
        ...state,
        addCitiesLoading: false,
      };
    case Campaign.ADD_CITIES_TO_EXISTING_CAMPAIGN_FAILURE:
      return {
        ...state,
        addCitiesLoading: false,
        addCitiesError: action.payload,
      };

    // Remove city from existing campaign
    // -----------------------------------------------------------------
    case Campaign.REMOVE_CITY_FROM_CAMPAIGN:
      return {
        ...state,
        removeCityLoading: true,
      };
    case Campaign.REMOVE_CITY_FROM_CAMPAIGN_SUCCESS:
      return {
        ...state,
        removeCityLoading: false,
      };
    case Campaign.REMOVE_CITY_FROM_CAMPAIGN_FAILURE:
      return {
        ...state,
        removeCityLoading: false,
        removeCityError: action.payload,
      };

    default:
      return state;
  }
};
