import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

// Actions
import {
  getResultingPoiLayers,
  getTargetGroupAttributes,
  resetTargetGroupAttributes,
  resetTgGroup,
  setSelected,
  setTgAttributes,
  setTgStatus,
} from "../../../actions/org/OrgTargetGroupActions";
import { removeTgHeatMapData } from "../../../actions/geo-data/GeoDataActions";
import {
  clearRegionDataByCity,
  getRegionNames,
} from "../../../actions/regions/RegionActions";

// Utils and Constants
import { OrgViewPageLoader } from "../OrgUtils";
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";

// Components
import OrgSettingsSidebar from "../OrgSettingsSidebar";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Components :: Page and Sections
import AttributeSelectionView from "./AttributeSelectionView";
import AttributeReviewView from "./AttributeReviewView";
import TargetGroupCreateMapView from "./TargetGroupCreateMapView";

// CSS
import "../OrgSettings.scss";

// Page Components
// ----------------------------------------------------------------------------
function LeftSectionHeader({
  isReviewView,
  setIsReviewView,
  tgId,
  existingEditableTgName,
}) {
  const dispatch = useDispatch();

  // Selector State
  const dataList = useSelector((state) => state.orgTargetGroup.dataList);
  const attSelectedLength = useSelector(
    (state) => Object.keys(state.orgTargetGroup.attSelectionMap).length
  );

  // Functions
  function onClickAction() {
    dispatch(getResultingPoiLayers(dataList));
    setIsReviewView(true);
    toast.info("Select City to view Heatmap.");
  }

  const pageTitle = tgId
    ? `Edit Target Group - ${existingEditableTgName}`
    : "New Target Group";
  const pageActions = (
    <>
      {!isReviewView && (
        <button
          type="button"
          className="btn shadow-none btn-primary rounded-lg"
          onClick={onClickAction}
          disabled={attSelectedLength === 0}
        >
          {"Review"}
        </button>
      )}
    </>
  );

  return (
    <PageHeader
      title={pageTitle}
      actions={pageActions}
      shadow={false}
      border={true}
    />
  );
}

/**
 * Page
 */
export default function TargetGroupCreatePage() {
  const dispatch = useDispatch();
  // tgId from params if we come from "Tg-Listing-Page"
  const { targetGroupId: tgId } = useParams();

  // State
  const [isReviewView, setIsReviewView] = useState(false);

  // Selector
  const tgAttrList = useSelector((state) => state.orgTargetGroup.dataList);
  const isGetTgAttrLoading = useSelector(
    (state) => state.orgTargetGroup.isGetTgAttrLoading
  );

  // TG info Data (only if editing tg)
  const tgInfoLoading = useSelector(
    (state) => state.orgTargetGroup.tgInfoLoading
  );
  const originalData = useSelector(
    (state) => state.orgTargetGroup.originalData
  );
  const tgInfo = useSelector((state) => state.orgTargetGroup.tgInfo);
  const existingEditableTgName =
    useSelector((state) => state.orgTargetGroup.tgName) || "";

  // Dispatch
  useEffect(() => {
    dispatch(resetTgGroup());
    dispatch(clearRegionDataByCity());
    dispatch(removeTgHeatMapData());
    dispatch(resetTargetGroupAttributes());

    dispatch(getTargetGroupAttributes());
    dispatch(getRegionNames());
  }, [dispatch]);

  // only if there is TgId (edit-case)
  useGetTgInfo(tgId);

  // for "Edit-Target-Group" (for removing the default-selection of poiTypes)
  useEffect(() => {
    if (
      Object.keys(tgInfo).length > 0 &&
      Object.keys(originalData).length > 0
    ) {
      dispatch(setTgAttributes(tgInfo, originalData.targetGroupAttrs));
    }
  }, [dispatch, JSON.stringify(tgInfo), JSON.stringify(originalData)]);

  // Functions
  function actionOnCheckBoxSelect(selectedIndex, label) {
    dispatch(setSelected(selectedIndex, label));
  }

  function actionOnAddButton(labelId, status) {
    dispatch(setTgStatus(labelId, status));
  }

  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={4} />

      <div className="content-wrapper map-content-wrapper target-group">
        {/* Loading till TG-attributes fetched */}
        {(isGetTgAttrLoading || tgInfoLoading) && <OrgViewPageLoader />}

        {/* Section :: Left */}
        {!isGetTgAttrLoading && !tgInfoLoading && (
          <div className="map-layout-left">
            {/* Left Header  */}
            <LeftSectionHeader
              isReviewView={isReviewView}
              setIsReviewView={setIsReviewView}
              tgId={tgId}
              existingEditableTgName={existingEditableTgName}
            />
            {/** Selection View */}
            {!isReviewView && (
              <AttributeSelectionView
                data={tgAttrList}
                setSelected={actionOnCheckBoxSelect}
                setAdded={actionOnAddButton}
              />
            )}

            {/** Review View */}
            {isReviewView && (
              <AttributeReviewView
                setIsReviewView={setIsReviewView}
                tgId={tgId}
                existingEditableTgName={existingEditableTgName}
              />
            )}
          </div>
        )}

        {/** Map Section :: Right */}
        {!isGetTgAttrLoading && !tgInfoLoading && (
          <TargetGroupCreateMapView isReviewView={isReviewView} />
        )}
      </div>
    </>
  );
}
