import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getCampaignMedia } from "../../../actions/campaign-media/CampaignMediaActions";

// Constants and utils
import { defaultPagination } from "../../../constants/UrlConstants";
import { CampaignState } from "./planningConstants";

//  Components
import TableHeaders from "../../../components/table/TableHeaders";
import { LtsCell } from "../../../components/campaign-media-table-row/MediaRow";
import {
  MediaInfo,
  TgSpecificOts,
  GenericOts,
  MediaDuration,
  SetUpMonitoring,
  MonitoringScheduled,
  MonitoringLive,
  MonitoringComplete,
} from "./MediaRow";

function getEstTableHeaders() {
  return [
    {
      title: {
        displayName: "Site Name",
        className: "col-3",
      },
    },

    {
      element: (
        <th className="col-2">
          <p className="mb-1">Zone</p>
          <p className="mb-1">Area</p>
          <p className="mb-0">Route</p>
        </th>
      ),
    },
    {
      title: {
        displayName: "Road Stretch",
        className: "col-1 text-right",
      },
    },

    {
      title: {
        displayName: "Start and End Date",
        className: "col-2 text-right",
      },
      subTitle: {
        displayName: "Duration",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "Est Target Imp",
        className: "col-2 text-right",
      },
    },
    {
      title: {
        displayName: "Est General Imp",
        className: "col-1 text-right",
      },
    },
    {
      title: {
        displayName: "LTS",
        className: "col-1 text-right",
      },
    },
  ];
}

function getConfirmedTableHeaders() {
  return [
    {
      title: {
        displayName: "Site Name",
        className: "align-top",
      },
    },

    {
      title: {
        displayName: "Road Stretch",
        className: "align-top text-right",
      },
    },

    {
      title: {
        displayName: "Start and End Date",
        className: "text-right",
      },
      subTitle: {
        displayName: "Duration",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "Est Target Imp",
        className: "text-right align-top",
      },
    },
    {
      title: {
        displayName: "Est General Imp",
        className: "text-right align-top",
      },
    },
    {
      title: {
        displayName: "LTS",
        className: "align-top text-right",
      },
    },

    {
      title: {
        displayName: "Monitoring",
        className: "align-top text-right",
      },
    },
  ];
}

function getLiveTableHeaders() {
  return [
    {
      title: {
        displayName: "Site Name",
        className: "align-top",
      },
    },
    {
      title: {
        displayName: "Road Stretch",
        className: "col-1 text-right",
      },
    },
    {
      title: {
        displayName: "Start and End Date",
        className: "text-right",
      },
      subTitle: {
        displayName: "Duration",
        className: "sub-text",
      },
    },

    {
      title: {
        displayName: "Live Target Imp",
        className: "text-right",
      },
      subTitle: {
        displayName: "Est Impression",
        className: "sub-text",
      },
    },

    {
      title: {
        displayName: "Live General Imp",
        className: "align-top text-right",
      },
      subTitle: {
        displayName: "Est Impression",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "LTS",
        className: "text-right align-top",
      },
    },

    {
      title: {
        displayName: "Monitoring",
        className: "text-right",
      },
      subTitle: {
        displayName: "Last updated",
        className: "sub-text",
      },
    },
  ];
}

function getFinishedTableHeaders() {
  return [
    {
      title: {
        displayName: "Site Name",
        className: "align-top",
      },
    },

    {
      title: {
        displayName: "Road Stretch",
        className: "align-top text-right",
      },
    },

    {
      title: {
        displayName: "Start and End Date",
        className: "text-right",
      },
      subTitle: {
        displayName: "Duration",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "Est Target Imp",
        className: "text-right align-top",
      },
    },
    {
      title: {
        displayName: "Est General Imp",
        className: "text-right align-top",
      },
    },
    {
      title: {
        displayName: "LTS",
        className: "align-top text-right",
      },
    },

    {
      title: {
        displayName: "Monitoring",
        className: "align-top text-right",
      },
    },
  ];
}

function getTableHeaders(planStatus) {
  if (planStatus === CampaignState.LIVE) {
    return getLiveTableHeaders();
  }

  if (planStatus === CampaignState.COMPLETED) {
    return getFinishedTableHeaders();
  }

  if (
    planStatus === CampaignState.CONFIRMED ||
    planStatus === CampaignState.SCHEDULED
  ) {
    return getConfirmedTableHeaders();
  }

  return getEstTableHeaders();
}

export function RoadStretchInfo({ stretchInfo = {} }) {
  return <td className="text-right align-top">{stretchInfo.name}</td>;
}

function ZoneRouteAndAreaInfo({ media }) {
  const { zone = "Delhi-W Delhi", area = "Area 1", route = "Route 2" } = media;
  return (
    <td className="align-top">
      <p className="mb-1" title={zone}>
        {zone}
      </p>
      <p className="mb-1" title={area}>
        {area}
      </p>
      <p className="mb-0" title={route}>
        {route}
      </p>
    </td>
  );
}

function MonitorSettings({ media }) {
  const { isMonitoringEnabled } = media;
  if (isMonitoringEnabled) {
    return <MonitoringScheduled media={media} />;
  }
  return <SetUpMonitoring media={media} />;
}

function MediaDetailsRow({
  media,
  campaign,
  mediaPerformance,
  otsDetails = {},
  stretchInfo,
}) {
  // Calculations for Estimated Impressions
  const { genericOts, genericOtsLit, targetOts, targetOtsLit } = otsDetails;
  const estTgSpecific = targetOtsLit ?? targetOts;
  const estGeneric = genericOtsLit ?? genericOts;

  // Calculation for Live Impressions
  const { targetOtsServedTotal, genericOtsServedTotal } = mediaPerformance;

  return (
    <tr>
      <MediaInfo media={media} />
      <ZoneRouteAndAreaInfo media={media} />
      <RoadStretchInfo stretchInfo={stretchInfo} />
      <MediaDuration media={media} />
      <TgSpecificOts
        estTgSpecific={estTgSpecific}
        liveTgSpecific={targetOtsServedTotal}
      />
      <GenericOts estGeneric={estGeneric} liveGeneric={genericOtsServedTotal} />
      <LtsCell ltsElementClass={"text-right align-top"} media={media} />
      {campaign.planStatus === CampaignState.SCHEDULED && (
        <MonitoringScheduled media={media} />
      )}
      {campaign.planStatus === CampaignState.CONFIRMED && (
        <MonitorSettings media={media} />
      )}
      {campaign.planStatus === CampaignState.LIVE && (
        <MonitoringLive media={media} />
      )}
      {campaign.planStatus === CampaignState.COMPLETED && (
        <MonitoringComplete media={media} />
      )}
    </tr>
  );
}

/**
 * Table
 */
function MediaDetailsTable({
  campaignId,
  campaign,
  campaignPlan,
  campaignMediaOtsLogSummary = {},
  roadStretchMap,
}) {
  // Selector
  const mediaDetails = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  const { roadStretchOtsMap = {}, cityId } = campaignPlan;
  const { planStatus } = campaign;

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getCampaignMedia(
        campaignId,
        defaultPagination.pageNo,
        defaultPagination.pageSize
      )
    );
  }, [campaignId, cityId, dispatch]);

  if (
    mediaDetails.length < 1 ||
    !campaignPlan ||
    Object.keys(roadStretchMap).length < 1
  ) {
    return null;
  }

  const tableHeaders = getTableHeaders(planStatus);

  return (
    <div className="table-responsive mt-5">
      <h4>{"Media Sites"}</h4>
      <table className="table table-media">
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
        <tbody>
          {mediaDetails.map((eachMedia) => (
            <MediaDetailsRow
              key={eachMedia.id}
              media={eachMedia}
              otsDetails={roadStretchOtsMap[eachMedia.roadStretchId]}
              campaign={campaign}
              mediaPerformance={campaignMediaOtsLogSummary[eachMedia.id] || {}}
              stretchInfo={roadStretchMap[eachMedia.roadStretchId]}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MediaDetailsTable;
