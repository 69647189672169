import { getItem } from "./localstorage";

/**
 * @param {string} featureName
 * @returns true/false based on if user has access to a particular module
 */
export function hasAccess(featureName) {
  const features = getItem("permissions") || [];
  return features.includes(featureName);
}
