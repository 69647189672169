import { useState } from "react";
import moment from "moment";

// Rechart
import {
  Legend,
  Line,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart as RadChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  LineChart as ReactLineChart,
  Pie,
  Cell,
  PieChart as ReactPieChart,
  Area,
  AreaChart,
  Sector,
  BarChart as ReactBarChart,
  Bar,
  ComposedChart,
  CartesianGrid,
} from "recharts";

// Chart Constant
const CHART_COLORS = [
  "#0070b4",
  "#ff700e",
  "#00b029",
  "#f00029",
  "#a646bd",
  "#984f4b",
  "#fd4bc2",
];

// used to render the "Lines" in the "LineChart"
const DEFAULT_LINES_DATA = [
  {
    type: "linear",
    dataKey: "Hour Average",
  },
  {
    type: "linear",
    dataKey: "Hour Maximum",
  },
  {
    type: "linear",
    dataKey: "Hour Minimum",
  },
];

// "LineChart" Margin
const DEFAULT_LINE_CHART_MARGIN = {
  top: 5,
  right: 5,
  left: 35,
  bottom: 5,
};

/**
 * Radar Chart
 */
function RadarChart({ data, height }) {
  return (
    <ResponsiveContainer height={height}>
      <RadChart data={data}>
        <PolarGrid />
        <PolarRadiusAxis domain={[0, 5]} tickCount={6} />
        <PolarAngleAxis dataKey={"label"} />
        <Radar name="Media" dataKey="media" fill="#82ca9d" fillOpacity={0.3} />
        <Radar
          name="Region"
          dataKey="region"
          fill="#8884d8"
          fillOpacity={0.3}
        />
        <Legend />
      </RadChart>
    </ResponsiveContainer>
  );
}

/**
 * Line Chart
 */
function LineChart({
  data = [],
  linesData = DEFAULT_LINES_DATA,
  xAxisLabel = "",
  yAxisLabel = "",
  interval = 0,
  chartMargin = DEFAULT_LINE_CHART_MARGIN,
  showCartesianGrid = true,
  showCustomXAxisTick = true,
}) {
  return (
    <ResponsiveContainer height={330}>
      <ReactLineChart data={data} margin={chartMargin}>
        {showCartesianGrid && <CartesianGrid strokeDasharray="3 3" />}

        {/* X-Axis */}
        {!showCustomXAxisTick && (
          <XAxis
            dataKey="name"
            interval={interval}
            label={{ value: xAxisLabel, position: "insideBottomRight" }}
          />
        )}
        {showCustomXAxisTick && (
          <XAxis
            dataKey="name"
            interval={interval}
            label={{ value: xAxisLabel, position: "insideBottomRight" }}
            height={65}
            tick={<CustomXAxisTick />}
          />
        )}

        <YAxis width={50} label={{ value: yAxisLabel, angle: -90, dx: -20 }} />
        <Tooltip />
        <Legend />

        {/* Lines User wants to return in chart */}
        {linesData.map((eachLine, i) => (
          <Line
            key={i}
            type={eachLine.type}
            dataKey={eachLine.dataKey}
            stroke={eachLine.stroke || CHART_COLORS[i]}
          />
        ))}
      </ReactLineChart>
    </ResponsiveContainer>
  );
}

// for Hover animation
function renderActiveShape(props) {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    midAngle,
    fill,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + innerRadius + 10 * cos;
  const sy = cy + innerRadius + 10 * sin;

  return (
    <Sector
      cx={sx}
      cy={sy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  );
}

function renderCustomizedLabel(props) {
  const { cx, cy, midAngle, outerRadius, value, fill } = props;
  const RADIAN = Math.PI / 180;
  const radius = outerRadius + 20;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const percentage = value ? `${value}%` : "";
  return (
    <text
      x={x}
      y={y}
      fill={fill}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {percentage}
    </text>
  );
}

// Custom X-Axis
function CustomXAxisTick(props) {
  const {
    // default props
    x,
    y,
    payload,

    // additional
    fill = "#666",
    rotateDegree = -45,
    maxCharacterToShow = 8,
  } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        y={5}
        textAnchor="end"
        fill={fill}
        transform={`rotate(${rotateDegree})`}
      >
        {/* showing only "maxCharacterToShow" letters of value */}
        {payload.value.substring(0, maxCharacterToShow)}
      </text>
    </g>
  );
}

/**
 * Pie Chart
 */
function PieChart({
  data,
  colors = CHART_COLORS,
  height = 350,
  showCustomToolTip = false,
}) {
  const [activeIndex, setActiveIndex] = useState(null);

  function onMouseOver(_data, index) {
    setActiveIndex(index);
  }

  function onMouseLeave() {
    setActiveIndex(null);
  }

  function CustomTooltip(props) {
    const { active, payload } = props;
    const { name, value, count } = payload[0]?.payload || {};
    const isHovered = active && payload && payload.length;

    if (!isHovered) {
      return null;
    }

    // data
    const data = [
      { label: "Name", value: name },
      { label: "Percentage", value: `${value}%` },
    ];

    if (count) {
      data.push({ label: "Count", value: count });
    }

    return (
      <div
        className="border p-2 bg-alt rounded-lg"
        style={{ maxWidth: "200px" }}
      >
        {data.map((eachData, i) => (
          <p key={i} className="mb-0">
            {eachData.label}: {eachData.value}
          </p>
        ))}
      </div>
    );
  }

  return (
    <ResponsiveContainer height={height}>
      <ReactPieChart>
        <Pie
          activeIndex={activeIndex}
          isAnimationActive={true}
          data={data}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={120}
          fill="#8884d8"
          dataKey="value"
          activeShape={renderActiveShape}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
        >
          {data.map((_entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>

        {showCustomToolTip && <Tooltip content={<CustomTooltip />} />}

        <Legend
          verticalAlign="bottom"
          align="center"
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          payload={data.map((item, index) => {
            const { name, value } = item;
            return {
              value: `${name} (${value}%)`,
              color: value ? colors[index % colors.length] : "#bebebe", // gray color,
            };
          })}
        />
      </ReactPieChart>
    </ResponsiveContainer>
  );
}

/**
 * Time series chart
 */
function TimeSeriesChart({ data, label }) {
  function formatXAxis(tickItem) {
    return moment(tickItem).format("MM-DD");
  }

  return (
    <ResponsiveContainer height={330}>
      <AreaChart data={data}>
        <XAxis dataKey="key" tickFormatter={formatXAxis} interval={20} />
        <YAxis
          dataKey={label}
          width={25}
          domain={["auto", () => 0]}
          tickCount={7}
        />
        <Tooltip />
        <Legend />
        <Area
          type="linear"
          dot={{ fill: "#0070b4", stroke: "#0070b4", strokeWidth: 1 }}
          dataKey={label}
          fill="#cce2f0"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

/**
 * Bar Chart
 */
function BarChart({
  data = [],
  barsData = [],
  barSize = 20,
  interval = 0,
  showCartesianGrid = false,
  showCustomXAxisTick = false,
  yAxisWidth = 80,
}) {
  return (
    <ResponsiveContainer height={330}>
      <ReactBarChart data={data}>
        {showCartesianGrid && <CartesianGrid strokeDasharray="3 3" />}

        {/* X- Axis */}
        {!showCustomXAxisTick && <XAxis dataKey="name" interval={interval} />}
        {showCustomXAxisTick && (
          <XAxis
            dataKey="name"
            interval={interval}
            height={65}
            tick={<CustomXAxisTick />}
          />
        )}

        <YAxis width={yAxisWidth} />
        <Tooltip />
        <Legend />

        {/* Bars User wants to return in chart */}
        {barsData.map((eachBar, i) => (
          <Bar
            key={i}
            dataKey={eachBar.dataKey}
            barSize={barSize}
            fill={CHART_COLORS[i]}
          />
        ))}
      </ReactBarChart>
    </ResponsiveContainer>
  );
}

/**
 * Bar With Line Chart
 */
function BarWithLineChart({
  data = [],
  barSize = 10,
  interval = 0,
  barLabel,
  lineLabel,
  yAxisLabel = "",
  showCartesianGrid = true,
  showCustomXAxisTick = false,
}) {
  return (
    <ResponsiveContainer height={330}>
      <ComposedChart data={data}>
        {showCartesianGrid && <CartesianGrid strokeDasharray="3 3" />}

        {/* X-Axis */}
        {!showCustomXAxisTick && <XAxis dataKey="name" interval={interval} />}
        {showCustomXAxisTick && (
          <XAxis
            dataKey="name"
            interval={interval}
            height={65}
            tick={<CustomXAxisTick />}
          />
        )}

        <YAxis label={{ value: yAxisLabel, angle: -90, dx: -20 }} />
        <Tooltip />
        <Legend />
        <Bar dataKey={barLabel} barSize={barSize} fill={CHART_COLORS[0]} />
        <Line dataKey={lineLabel} stroke={CHART_COLORS[1]} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export {
  LineChart,
  PieChart,
  TimeSeriesChart,
  RadarChart,
  BarChart,
  BarWithLineChart,
};
