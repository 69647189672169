import { ActionTypes } from "../../../constants/ActionConstants";

const initialState = {
  openModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TabContentPreviewForm.OPEN_TAB_CONTENT_PREVIEW_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case ActionTypes.TabContentPreviewForm.CLOSE_TAB_CONTENT_PREVIEW_MODAL:
      return {
        ...state,
        openModal: false,
      };

    default:
      return state;
  }
};
