import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { getTgInfo } from "../../../actions/org/OrgTargetGroupActions";
import { removeTgHeatMapData } from "../../../actions/geo-data/GeoDataActions";
import {
  clearRegionDataByCity,
  getRegionNames,
} from "../../../actions/regions/RegionActions";

// Utils and Constants
import { CitySelectInputSection, OrgViewPageLoader } from "../OrgUtils";
import { India, MapZoom } from "../../../constants/GeneralConstants";

// Components
import TargetGroupHeatmap from "../../../components/map/target-group-heatmap/TargetGroupHeatmap";
import LLMap from "../../../components/map/leaflet-map/LLMap";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Pages and Sections
import OrgSettingsSidebar from "../OrgSettingsSidebar";

// CSS
import "../OrgSettings.scss";

// Page Component
function ResultingPoiLayersSection({ resultingPoiLayersName }) {
  return (
    <div className="my-3">
      <h3>{"Resulting POI Layers"}</h3>
      <ul className="mb-0">
        {resultingPoiLayersName.map((eachPoi, index) => (
          <li key={index} className="p-1">
            <span>{eachPoi}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

function TGViewPageMapSection({ tgId, resPoiLayers }) {
  // all poiTypeLayer points combined array
  const allPoiPointsArr = useSelector((state) => state.geoData.allPoiPointsArr);

  // After selecting any city, below is the regionData
  const regionData = useSelector((state) => state.region.regionData);
  const { bbox, center = {} } = regionData;
  const { latitude, longitude } = center;
  const regionCenter = [latitude, longitude];

  // this is to stop calling heatmapData from previous bbox state
  const [cityBbox, setCityBbox] = useState(bbox);
  useEffect(() => {
    setCityBbox("");
  }, []);

  // handling the "Center" and "Zoom" (if city selected or not)
  const regionDataLength = Object.keys(regionData).length;
  const mapCenter = regionDataLength > 0 ? regionCenter : India.mapCenter;
  const zoom = regionDataLength > 0 ? MapZoom.zoomLevel11 : India.mapZoomLevel5;

  return (
    <div className="map-layout-right">
      <div className="org-map-cont leaflet-container">
        {/* CitySelection */}
        {bbox !== cityBbox && (
          <CitySelectInputSection bbox={bbox} resPoiLayers={resPoiLayers} />
        )}

        {/* Map View */}
        <LLMap center={mapCenter} zoom={zoom}>
          <TargetGroupHeatmap hmkey={tgId} allPoiPointsArr={allPoiPointsArr} />
        </LLMap>
      </div>
    </div>
  );
}

/**
 * Page
 */
function TargetGroupViewPage() {
  const dispatch = useDispatch();
  const { id: tgId } = useParams();

  // Selector
  const tgInfoLoading = useSelector(
    (state) => state.orgTargetGroup.tgInfoLoading
  );

  const poiTypesMap =
    useSelector((state) => state.orgTargetGroup.tgInfo.poiTypesMap) || {};

  const targetGroupInfo =
    useSelector((state) => state.orgTargetGroup.tgInfo.targetGroup) || {};
  const { resultLayers = [] } = targetGroupInfo;

  // Resulting Poi Layers Map
  const resPoiLayersMap = resultLayers.reduce((acc, eachLayer) => {
    const poiTypeId = eachLayer.poiTypeId;
    const poiTypeName = poiTypesMap[poiTypeId].name;
    acc[poiTypeId] = poiTypeName;
    return acc;
  }, {});

  // Dispatch
  useEffect(() => {
    dispatch(clearRegionDataByCity());
    dispatch(removeTgHeatMapData());

    // Getting Regions name
    dispatch(getRegionNames());

    // getting TgInfo
    dispatch(getTgInfo(tgId));
  }, [dispatch, tgId]);

  const pageTitle = `Target Group :: ${targetGroupInfo?.name}`;

  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={4} />

      <div className="content-wrapper map-content-wrapper">
        {tgInfoLoading && <OrgViewPageLoader />}
        {!tgInfoLoading && (
          <>
            <div className="map-layout-left">
              {/* Header Section */}
              <PageHeader title={pageTitle} shadow={false} border={true} />

              {/* Resulting POI Layer section */}
              <ResultingPoiLayersSection
                resultingPoiLayersName={Object.values(resPoiLayersMap)}
              />
            </div>

            {/** Map Section */}
            <TGViewPageMapSection
              tgId={tgId}
              resPoiLayers={Object.keys(resPoiLayersMap)}
            />
          </>
        )}
      </div>
    </>
  );
}

export default TargetGroupViewPage;
