// Utils and Constants
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import { Percentage } from "../../../constants/GeneralConstants";

// Components
import TableHeaders from "../../../components/table/TableHeaders";

//Section Constants
const tableHeaders = [
  {
    title: {
      displayName: "Total Impressions",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Total Reach",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Frequency",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "TG Impressions",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "TG Reach",
      className: "col-2",
    },
  },
];

// Segment Ots Splitted Table Row
function SegmentOtsSplittedTableRow({ segmentOtsAndReachInfo }) {
  const { tgReach, totalReach, tgImp, totalImpressions, frequency } =
    segmentOtsAndReachInfo;

  return (
    <tr>
      <td>{formatText(toLocaleString(totalImpressions))}</td>
      <td>{formatText(toLocaleString(totalReach))}</td>
      <td>{formatText(toLocaleString(frequency))}</td>
      <td>{formatText(toLocaleString(tgImp))}</td>
      <td>{formatText(toLocaleString(tgReach))}</td>
      {/* <td>{formatText(toLocaleString(malePercentage))}</td> */}
      {/* <td>{formatText(toLocaleString(femalePercentage))}</td> */}
    </tr>
  );
}

/** Segment Ots Splitted Table
 *
 *  @param {*} segmentOtsAndReachInfo =>an object containing information about  selected road segment otas and reach info
 *  @param {*}populationGenderSplitPercentage=>an object containing male female split percentage
 
 */
export default function SegmentOtsSplittedTable({
  segmentOtsAndReachInfo,
  populationGenderSplitPercentage,
}) {
  // const { tgReach, totalReach, tgImp, totalImpressions, frequency } =
  //   segmentOtsAndReachInfo;

  // TODO:uncomment when required

  // // male female percentages
  // const {
  //   "MALE %": malePopulationPercentage,
  //   "FEMALE %": femalePopulationPercentage,
  // } = populationGenderSplitPercentage || {};

  // male total ots percentage
  // const totalOtsMalePercentage = Math.ceil(
  //   (malePopulationPercentage * totalImpressions) / Percentage.HUNDRED
  // );

  // female total ots percentage
  // const totalOtsFemalePercentage = Math.ceil(
  //   (femalePopulationPercentage * totalImpressions) / Percentage.HUNDRED
  // );

  // male total reach percentage
  // const totalReachMalePercentage = Math.ceil(
  //   (malePopulationPercentage * totalReach) / Percentage.HUNDRED
  // );

  // female total reach percentage
  // const totalReachFemalePercentage = Math.ceil(
  //   (femalePopulationPercentage * totalReach) / Percentage.HUNDRED
  // );

  //  Ots table Content

  return (
    <div className="table-responsive mt-4">
      <h4>Impressions</h4>

      <table className="table table-media">
        {/* Table header */}
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />

        <tbody>
          {/* Table rows */}

          <SegmentOtsSplittedTableRow
            segmentOtsAndReachInfo={segmentOtsAndReachInfo}
          />
        </tbody>
      </table>
    </div>
  );
}
