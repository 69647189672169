import moment from "moment";

// Constants and Utils
import {
  calculateAdjustedMop,
  calculatePriceFactor,
} from "../../utils/PriceFactorUtil";
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";
import {
  DATE_FORMATS,
  DurationConstants,
} from "../../../constants/GeneralConstants";

/**
 * Function which returns the initialState for the media Available dates
 *
 * 1) For existing media (campaign media) ==> we take from it
 * 2) If it is SellerMedia we will take the campaign Plan dates
 * @returns {startDate: value, endDate: value}
 */
export function getInitialDatesState(campaignMedia, campaignPlan) {
  if (!campaignMedia && !campaignPlan) {
    return { startDate: "", endDate: "" };
  }

  const { startTimestamp, endTimestamp } = campaignMedia
    ? campaignMedia
    : campaignPlan;

  return {
    startDate: new Date(startTimestamp),
    endDate: new Date(endTimestamp),
  };
}

function getOtsDetails(roadOts, roadStretch, duration) {
  // Specific Ots Details
  const otsDetails = {
    genericOts: "",
    genericOtsLit: "",
    targetOts: "",
    targetOtsLit: "",
  };
  if (roadOts.targetOts || roadOts.targetOtsLit) {
    const { genericOts, genericOtsLit, targetOts, targetOtsLit } = roadOts;
    otsDetails.genericOts = genericOts;
    otsDetails.genericOtsLit = genericOtsLit;
    otsDetails.targetOts = targetOts;
    otsDetails.targetOtsLit = targetOtsLit;
  } else {
    const { genericOts, genericOtsLit, targetOts, targetOtsLit } = roadStretch;
    otsDetails.genericOts = genericOts;
    otsDetails.genericOtsLit = genericOtsLit;
    otsDetails.targetOts = targetOts;
    otsDetails.targetOtsLit = targetOtsLit;
  }

  // Total Ots calculations
  const otsGeneric =
    duration *
    (otsDetails.genericOtsLit
      ? otsDetails.genericOtsLit
      : otsDetails.genericOts);

  const otsTarget =
    duration *
    (otsDetails.targetOtsLit ? otsDetails.targetOtsLit : otsDetails.targetOts);

  const otsTotal = otsTarget ? otsGeneric + otsTarget : otsGeneric;
  return { otsGeneric, otsTarget, otsTotal };
}

export function getInitialPrices(
  campaignPlan,
  roadStretchId,
  media,
  campaignMedia,
  roadOts,
  duration,
  mediaPriceInfo
) {
  const { targetGroupCPM, genericCPM, roadStretchOtsMap } = campaignPlan;
  const { minOperatingPrice: mop, occupancyAvg: occupancy } = mediaPriceInfo;

  const { pricing, ltsSummary } = media;

  // Ots Details
  const { otsGeneric, otsTarget, otsTotal } = getOtsDetails(
    roadOts,
    roadStretchOtsMap[roadStretchId],
    duration
  );

  // Price Factor based on Lts
  const priceFactor = calculatePriceFactor(ltsSummary);
  const pricePerDay = pricing.price / DurationConstants.THIRTY_DAYS;

  // Mop Calculation..
  const adjMop = calculateAdjustedMop(mop, occupancy, duration);
  const pricePerImp = adjMop / otsTotal;

  // Calulated suggested, seller and buyer prices
  const suggestedPrice = otsTarget
    ? (pricePerImp * (targetGroupCPM / 100) * otsTarget +
        pricePerDay * (genericCPM / 100) * otsGeneric) *
      priceFactor
    : pricePerDay * (genericCPM / 100) * otsTotal * priceFactor;

  return {
    totalOts: toLocaleString(otsTotal),
    suggestedPrice,
    mop: adjMop,
  };
}

export function constructCampaignMediaBean(
  dateObj,
  media,
  priceMode,
  sellerPrice,
  price,
  roadStretchId
) {
  // DD-MM-YYYY
  const dateFormat = DATE_FORMATS.date_month_year;
  const startDateStr = moment(dateObj.startDate).format(dateFormat);
  const endDateStr = moment(dateObj.endDate).format(dateFormat);
  return {
    startDateStr,
    endDateStr,
    mediaId: media.mediaId,
    priceMode,
    priceFactor: calculatePriceFactor(media.ltsSummary),
    roadStretchId,
    sellerPrice: new Number(sellerPrice.replace(/,/g, "")),
    price: new Number(price.replace(/,/g, "")),
  };
}
