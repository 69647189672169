import { CampaignBudget } from "../../action-constants/CampaignBudgetActionConstants";

// These below actions are for "Campaign Budget Exceed" form
export const openCampaignBudgetExceedForm = (processDataObject) => ({
  type: CampaignBudget.OPEN_CAMPAIGN_BUDGET_EXCEED_MODAL,
  payload: processDataObject,
});

export const closeCampaignBudgetExceedForm = () => ({
  type: CampaignBudget.CLOSE_CAMPAIGN_BUDGET_EXCEED_MODAL,
});
