import { useEffect } from "react";
import { MapContainer, TileLayer, useMap, ZoomControl } from "react-leaflet";

// Constants
import { MapZoom } from "../../../constants/GeneralConstants";

// Components
import MapImage from "./MapImage";
import MapTiles from "../map-tile";

// CSS
import "leaflet/dist/leaflet.css";

// Page Constants
const DEFAULT_TILE_NAME = "osm";
const DEFAULT_ZOOM = MapZoom.zoomLevel12;

// Page Components
function LLChangeView({ center, zoom }) {
  const [latitude, longitude] = center;
  const map = useMap();

  useEffect(() => {
    map.setView([latitude, longitude], zoom);
  }, [map, latitude, longitude, zoom]);
  return null;
}

/**
 * Custom Leaflet Map with utility functions
 */
function LLMap(props) {
  // id
  const mapId = props.mapId;

  // Tiles
  const tileName = props.tile;
  const mapTileInfo = MapTiles[tileName];

  // Map View
  const center = props.center;
  const initZoom = props.zoom;
  const zoomControl = props.zoomControl;
  const scrollWheelZoom = props.scrollWheelZoom;

  return (
    <MapContainer
      key={mapId}
      id={mapId}
      center={center}
      zoom={initZoom}
      zoomControl={false}
      scrollWheelZoom={scrollWheelZoom}
      zoomSnap={0.1} //[zoomSnap,zoomDelta] => used to set the Level of ZoomIn-ZoomOut for Button (+/-)
      zoomDelta={0.5} //[zoomSnap,zoomDelta] => Documentation :: https://devdocs.io/leaflet~1.7/
    >
      <TileLayer attribution={mapTileInfo.attribution} url={mapTileInfo.url} />
      <LLChangeView center={center} zoom={initZoom} />
      <ZoomControl position={zoomControl} />
      <MapImage />
      {props.children}
    </MapContainer>
  );
}

LLMap.defaultProps = {
  tile: DEFAULT_TILE_NAME,
  zoom: DEFAULT_ZOOM,
  zoomControl: "bottomright",
  scrollWheelZoom: true,
};

export default LLMap;
