import React from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

// Actions
import { updateCampaignDuration } from "../../../actions/campaign/CampaignActions";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import {
  CityNameCell,
  MediaCountCell,
  DurationCell,
  ImpressionsCell,
  LtsCell,
  HamburgerCell,
} from "../../../components/campaign-table-row/CampaignRowCells";
import {
  FrequencyCell,
  ReachCell,
} from "../../../components/campaign-media-table-row/MediaRow";
import { OtsAndReachHeader } from "../../../components/table/OtsAndReachHeader";
import NoTableDataMessage from "../../../components/table/NoTableDataMessage";

// Page Constants
const otsHeaderInfo = [
  {
    displayText: "TG Impressions",
    className: "bg-light-green",
    percentage: "25",
  },
  {
    displayText: "Total Impressions",
    className: "bg-light-blue",
    percentage: "100",
  },
];

const reachHeaderInfo = [
  {
    displayText: "TG Reach",
    className: "bg-light-pink",
    percentage: "25",
  },
  {
    displayText: "Total Reach",
    className: "bg-light-yellow",
    percentage: "100",
  },
];

const campaignSummaryTableHeaders = () => [
  {
    title: {
      displayName: "City",
      className: "w-10 align-middle",
    },
    subTitle: {
      displayName: "Status",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "No. of sites",
      className: "w-8 align-middle text-right",
    },
    subTitle: {
      displayName: "",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "Start and end date",
      className: "w-20 align-middle text-right",
    },
    subTitle: {
      displayName: "Duration",
      className: "sub-text",
    },
  },
  {
    element: (
      <OtsAndReachHeader
        headerInfo={otsHeaderInfo}
        subText="Sites"
        className="w-20"
      />
    ),
  },
  {
    element: (
      <OtsAndReachHeader
        headerInfo={reachHeaderInfo}
        subText="Population"
        className="w-20"
      />
    ),
  },

  {
    title: {
      displayName: "Frequency",
      className: "w-9 text-right align-middle",
    },
  },

  {
    title: {
      displayName: "LTS",
      className: "w-8 text-right align-middle",
    },
    subTitle: {
      displayName: "Sites",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "",
      className: "w-8 text-right",
    },
    subTitle: {
      displayName: "",
      className: "sub-text",
    },
  },
];

function CampaignSummaryTableRow({ campaignId, cityInfo }) {
  const dispatch = useDispatch();
  const { cityId, endTimestamp, startTimestamp, reachFrequency, population } =
    cityInfo;

  // function to update date
  function onDateSelect(selectedDate) {
    dispatch(updateCampaignDuration(selectedDate, cityInfo));
  }

  const dateObj = { startTimestamp, endTimestamp };

  const campaignCityViewPageUrl = RedirectTo.campaignCityViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);

  return (
    <tr key={`camp-${cityId}`}>
      <CityNameCell
        cityElementStyle="p-3 align-middle"
        cityInfo={cityInfo}
        redirectUrl={campaignCityViewPageUrl}
      />

      <MediaCountCell
        mediaCountElementStyle="align-middle text-right"
        cityInfo={cityInfo}
      />

      <DurationCell
        durationElementStyle="px-0 align-middle text-right"
        duration={cityInfo.duration}
        onDateSelect={onDateSelect}
        dateObj={dateObj}
      />

      <ImpressionsCell
        impressionsElementStyle="align-middle"
        cityInfo={cityInfo}
      />

      <ReachCell
        reachElementStyle="align-middle"
        reachFrequency={reachFrequency}
        cityPopulation={population}
        showPopulation={true}
      />
      <FrequencyCell
        frequencyElementClass={"p-3 text-right align-middle"}
        reachFrequency={reachFrequency}
      />

      <LtsCell
        ltsElementClass="p-3 text-right align-middle"
        cityInfo={cityInfo}
      />

      <HamburgerCell
        hamburgerElementStyle="p-3 text-right align-middle"
        cityInfo={cityInfo}
      />
    </tr>
  );
}

function CampaignViewTable({ infoByCity }) {
  const { id } = useParams();

  const isCampaignHaveAnyCity = Object.keys(infoByCity).length > 0;

  return (
    <div className="table-responsive mt-3">
      <table className="table table-media">
        <TableHeaders
          tableHeaders={campaignSummaryTableHeaders()}
          headerClass={"thead"}
        />
        <tbody>
          {!isCampaignHaveAnyCity && (
            <NoTableDataMessage
              colSpan={6}
              message="Campaign don't have any city"
            />
          )}

          {/* Table Row */}
          {isCampaignHaveAnyCity &&
            Object.values(infoByCity).map((cityInfo) => {
              return (
                <CampaignSummaryTableRow
                  key={cityInfo.cityId}
                  campaignId={id}
                  cityInfo={cityInfo}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default CampaignViewTable;
