import { MapViewImage } from "../constants/action-constants/MapViewImageActionConstants";

const initialState = {
  // City Summary Map Image
  imgDownloading: false,
  imgErr: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MapViewImage.DOWNLOAD_MAP_IMAGE: {
      return {
        ...state,
        imgDownloading: true,
      };
    }

    case MapViewImage.DOWNLOAD_MAP_IMAGE_SUCCESS: {
      return {
        ...state,
        imgDownloading: false,
      };
    }

    case MapViewImage.DOWNLOAD_MAP_IMAGE_FAILURE: {
      return {
        ...state,
        imgDownloading: false,
        imgErr: action.payload,
      };
    }
    default:
      return state;
  }
};
