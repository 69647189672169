import { all, put, select, takeLatest } from "redux-saga/effects";

//Api
import {
  deleteCampaignMedia,
  getCampaignMediaByCity,
  mapSellerMediaToCampaign,
} from "../../../apis/CampaignMediaAPI";

//Constants
import {
  MapExploreSites,
  MediaSites,
  MediaSitesSelection,
} from "../../../constants/action-constants/map-view/media-sites/MediaSitesActionConstants";
import { defaultPagination } from "../../../constants/UrlConstants";
import { CampaignMedia } from "../../../constants/action-constants/CampaignMediaActionConstants";

function mediaFilter(applyFilters) {
  if (!applyFilters) {
    return null;
  }

  const filters = select((state) =>
    Object.keys(state.filter.appliedFilters).reduce((acc, eachKey) => {
      if (!state.filter.appliedFilters[eachKey]) {
        return acc;
      }
      acc.push(eachKey);
      return acc;
    }, [])
  );
  return filters;
}

export function* getMapViewMediaSites(action) {
  try {
    const {
      id,
      cityId,
      pn = 1,
      ps = 10,
      applyFilters = false,
    } = action.payload;

    //media filter
    const filters = yield mediaFilter(applyFilters);

    //making ajax call
    const data = yield getCampaignMediaByCity(id, cityId, pn, ps, filters);

    //dispatching action
    yield put({
      type: MediaSites.GET_MEDIA_SITES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: MediaSites.GET_MEDIA_SITES_FAILURE,
      payload: err,
    });
  }
}

export function* mapExploreSites(action) {
  try {
    const { campaignId, mediaIds } = action.payload;

    //making ajax call
    const data = yield mapSellerMediaToCampaign(campaignId, mediaIds);

    // Add selection
    yield put({
      type: MediaSitesSelection.SELECT_MULTIPLE,
      payload: {
        ids: mediaIds,
      },
    });

    //dispatching action
    yield put({
      type: MapExploreSites.MAP_EXPLORE_SITES_SUCCESS,
      payload: {
        items: data,
      },
    });
  } catch (err) {
    yield put({
      type: MapExploreSites.MAP_EXPLORE_SITES_FAILURE,
      payload: err,
    });
  }
}

export function* removeMediaFromCampaign(action) {
  const { campaignId, campaignMediaInfo } = action.payload;
  const { cityId, id } = campaignMediaInfo;

  // productId
  const productId = process.env.REACT_APP_PRODUCT_ID;

  try {
    yield deleteCampaignMedia(campaignId, id);

    // api call to refresh campaign media list after media is removed
    const campaignMediaList = yield getCampaignMediaByCity(
      campaignId,
      cityId,
      defaultPagination.pageNo,
      defaultPagination.pageSize
    );

    yield put({
      type: MediaSites.REMOVE_MEDIA_SITE_SUCCESS,
    });

    yield put({
      type: MediaSites.GET_MEDIA_SITES_SUCCESS,
      payload: campaignMediaList,
    });

    if (productId) {
      yield put({
        type: CampaignMedia.CAMPAIGN_CITYVIEW_SUCCESS,
        data: campaignMediaList,
      });
    }
    // dispatching action
  } catch (err) {
    yield put({
      type: MediaSites.REMOVE_MEDIA_SITE_FAILURE,
      payload: { err },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(MediaSites.GET_MEDIA_SITES, getMapViewMediaSites),
    takeLatest(MapExploreSites.MAP_EXPLORE_SITES, mapExploreSites),
    takeLatest(MediaSites.REMOVE_MEDIA_SITE, removeMediaFromCampaign),
  ]);
}
