/* eslint-disable require-yield */
import { toast } from "react-toastify";
import { all, put, takeEvery } from "redux-saga/effects";
import { closestRoadSegmentsData } from "../../apis/RoadSegmentAPI";
import { ActionTypes } from "../../constants/ActionConstants";
import { getErrorMessage } from "../../utils/util";

// function to get "otsTotal" of all rdsgs
function* totalImpressions(allRdsgArray) {
  const totalOts = allRdsgArray.reduce(
    (total, eachRdsg) =>
      (eachRdsg.otsLitAvg ? eachRdsg.otsLitAvg : eachRdsg.otsAvg) + total,
    0
  );
  return totalOts;
}

/**
 * this function gives array of all roadSegments by using media's "lat-long"
 * by calling API multiple times for all "closestRoadSegments"
 * @param {*} latLongArr
 * @returns roadSegments
 */
function* getSegmentsOts(latLongArr) {
  const latLongAndOtsObj = {};
  for (let i = 0; i < latLongArr.length; i++) {
    const latLongStr = latLongArr[i];
    // calling API for closest-road-segments
    const allRdsgArray = yield closestRoadSegmentsData(latLongStr);
    const otsTotal = yield totalImpressions(allRdsgArray);
    // since we only need "otsTotal" of all-rdsgs,
    // so using data as object of {"latLong as key" : "otsTotal as value"}
    latLongAndOtsObj[latLongStr] = otsTotal;
  }
  return latLongAndOtsObj;
}

// for getting all-medias-closest-road-segments using array of lat-long strings
export function* getOtsOfLatLongs(action) {
  const { latLongArr } = action.payload;
  try {
    const data = yield getSegmentsOts(latLongArr);

    yield put({
      type: ActionTypes.SellerExcelOverview.GET_OTS_OF_LAT_LONGS_SUCCESS,
      data: data,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.SellerExcelOverview.GET_OTS_OF_LAT_LONGS_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeEvery(
      ActionTypes.SellerExcelOverview.GET_OTS_OF_LAT_LONGS,
      getOtsOfLatLongs
    ),
  ]);
}
