// CAMPAIGN-MONITORING
export const Monitoring = {
  // Campaign-Summary
  GET_MONITORING_CAMPAIGN_SUMMARY: "GET_MONITORING_CAMPAIGN_SUMMARY",
  GET_MONITORING_CAMPAIGN_SUMMARY_SUCCESS:
    "GET_MONITORING_CAMPAIGN_SUMMARY_SUCCESS",
  GET_MONITORING_CAMPAIGN_SUMMARY_FAILURE:
    "GET_MONITORING_CAMPAIGN_SUMMARY_FAILURE",

  // City-Summary
  GET_MONITORING_CITY_SUMMARY: "GET_MONITORING_CITY_SUMMARY",
  GET_MONITORING_CITY_SUMMARY_SUCCESS: "GET_MONITORING_CITY_SUMMARY_SUCCESS",
  GET_MONITORING_CITY_SUMMARY_FAILURE: "GET_MONITORING_CITY_SUMMARY_FAILURE",

  // Monitoring Media-Sites Selection
  SELECT: "MEDIA_SITES_SELECT",
  SELECT_MULTIPLE: "MEDIA_SITES_SELECT_MULTIPLE",
  UNSELECT: "MEDIA_SITES_UNSELECT",
  UNSELECT_MULTIPLE: "MEDIA_SITES_UNSELECT_MULTIPLE",
  RESET_SELECTION: "RESET_SELECTION",

  // Monitoring Media Settings
  GET_MONITORING_MEDIA_SETTING: "GET_MONITORING_MEDIA_SETTING",
  GET_MONITORING_MEDIA_SETTING_SUCCESS: "GET_MONITORING_MEDIA_SETTING_SUCCESS",
  GET_MONITORING_MEDIA_SETTING_FAILURE: "GET_MONITORING_MEDIA_SETTING_FAILURE",

  // Monitoring Media Log
  GET_MONITORING_MEDIA_LOG: "GET_MONITORING_MEDIA_LOG",
  GET_MONITORING_MEDIA_LOG_SUCCESS: "GET_MONITORING_MEDIA_LOG_SUCCESS",
  GET_MONITORING_MEDIA_LOG_FAILURE: "GET_MONITORING_MEDIA_LOG_FAILURE",

  // Setup Create Monitoring Form Actions
  CREATE_SETUP_MONITORING: "CREATE_SETUP_MONITORING",
  CREATE_SETUP_MONITORING_SUCCESS: "CREATE_SETUP_MONITORING_SUCCESS",
  CREATE_SETUP_MONITORING_FAILURE: "CREATE_SETUP_MONITORING_FAILURE",

  // Open-Close MediaSetupMonitoring Form Modal Actions
  OPEN_SETUP_MONITORING_MODAL: "OPEN_SETUP_MONITORING_MODAL",
  CLOSE_SETUP_MONITORING_MODAL: "CLOSE_SETUP_MONITORING_MODAL",

  // Cancel site monitoring actions
  CANCEL_SITE_MONITORING: "CANCEL_SITE_MONITORING",
  CANCEL_SITE_MONITORING_SUCCESS: "CANCEL_SITE_MONITORING_SUCCESS",
  CANCEL_SITE_MONITORING_FAILURE: "CANCEL_SITE_MONITORING_FAILURE",

  // Download Monitoring Log pdf report..
  DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT:
    "DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT",
  DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT_SUCCESS:
    "DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT_SUCCESS",
  DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT_FAILURE:
    "DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT_FAILURE",

  // monitoring media log image Modal
  OPEN_MONITORING_MEDIA_LOG_IMAGE_MODAL:
    "OPEN_MONITORING_MEDIA_LOG_IMAGE_MODAL",
  CLOSE_MONITORING_MEDIA_LOG_IMAGE_MODAL:
    "CLOSE_MONITORING_MEDIA_LOG_IMAGE_MODAL",

  // Download monitoring log image
  DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE: "DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE",
  DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE_SUCCESS:
    "DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE_SUCCESS",
  DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE_FAILURE:
    "DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE_FAILURE",
};
