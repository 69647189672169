import { CampaignMediaSelection } from "../../action-constants/CampaignMediaSelectionActionConstants";

// These below actions are for "Confirm Campaign Media Selection" form
export const openConfirmCampaignMediaSelectionForm = () => ({
  type: CampaignMediaSelection.OPEN_CONFIRM_CAMPAIGN_MEDIA_SELECTION_MODAL,
});

export const closeConfirmCampaignMediaSelectionForm = () => ({
  type: CampaignMediaSelection.CLOSE_CONFIRM_CAMPAIGN_MEDIA_SELECTION_MODAL,
});
