/**
 * takes the indexes/values as input
 * @returns the rating out of "5",
 */
function Rating({ displayName, value, ratingStar }) {
  // if no value is given it shows (4/5) = (80%) = (index = 0.8)
  const indexValue = value ?? 0.8;
  const ratingText = (Math.round(indexValue * 500) / 100).toFixed(1) + "/5";

  // function to get dynamic star rating
  function getRatingStar() {
    const rating = indexValue * 5;

    const ratingStars = [...Array(5)].map((_v, i) => {
      if (i < Math.floor(rating)) {
        return <i key={i} className="fa fa-star" aria-hidden="true"></i>;
      } else if (i < Math.ceil(rating)) {
        return (
          <i key={i} className="fa fa-star-half-alt" aria-hidden="true"></i>
        );
      } else {
        return <i key={i} className="far fa-star" aria-hidden="true"></i>;
      }
    });

    return ratingStars;
  }

  return (
    <div className="d-flex">
      {displayName && <span className="col-4 px-0">{displayName}</span>}
      <div className="col-8">
        <span>{ratingText}</span>
        <span className="mx-1">{ratingStar && getRatingStar()}</span>
      </div>
    </div>
  );
}

Rating.defaultProps = {
  displayName: "",
  ratingStar: true,
};

export default Rating;
