// Utils And Constants
import { selectFile } from "../../common-utils/file-utils/FileUtils";
import { ImageFileTypes, MediaType } from "../../constants/GeneralConstants";

// Components
import LitStatus from "../add-media-lit-status/LitStatus";

export function MediaImageUploadAndPreview({
  imageFiles,
  previewImages,
  setPreviewImages,
  setImageFiles,
}) {
  const fileSelectedHandler = async () => {
    const files = await selectFile(true, ImageFileTypes);
    const previewFiles = [...previewImages];
    const uploadedImages = [...imageFiles];
    for (let i = 0; i < files.length; i++) {
      const eachImage = files[i];
      previewFiles.push(URL.createObjectURL(eachImage));
      uploadedImages.push(files[i]);
    }

    setPreviewImages(previewFiles);
    setImageFiles(uploadedImages);
  };
  return (
    <div className="form-group row">
      <label className="col-3 col-form-label">{"Image"}</label>

      <div className="col-9">
        <button
          className="btn btn-link shadow-none pl-0 pr-3"
          onClick={fileSelectedHandler}
        >
          + Add Images
        </button>
        {previewImages.length >= 0 &&
          previewImages.map((previewImage, i) => (
            <img
              key={i}
              className="image-thumbnail pr-2 mb-2"
              src={previewImage}
            />
          ))}
      </div>
    </div>
  );
}

export function CreateMediaModalTitle() {
  return (
    <div className="bg-alt modal-header px-5">
      <h4 className="modal-title">Create Media</h4>
    </div>
  );
}

export function CreateMediaModalFooter({ handleSubmitForm, handleClearForm }) {
  const cancelAndSaveButtonDetails = [
    {
      label: "Cancel",
      className: "btn-outline-primary",
      onClick: handleClearForm,
    },
    {
      label: "Save",
      className: "btn-primary",
      onClick: handleSubmitForm,
    },
  ];

  return (
    <div className="modal-footer px-5">
      {cancelAndSaveButtonDetails.map((item, i) => (
        <button
          key={i}
          type="button"
          className={`btn ${item.className} btn-action mt-2`}
          data-dismiss="modal"
          onClick={item.onClick}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
}

export function MediaLocationAndTitle({
  locationStr,
  title,
  setLocationStr,
  setTitle,
}) {
  const locationAndTitle = [
    {
      displayTag: "Location",
      placeholder: "Longitude latitude or address",
      setValueFunction: setLocationStr,
      value: locationStr,
      inputBoxStyle: "rounded-lg",
    },
    {
      displayTag: "Site Name",
      placeholder: "Enter site name. eg Indira Nagar Junction facing Metro",
      setValueFunction: setTitle,
      value: title,
      inputBoxStyle: "rounded-lg",
    },
  ];
  return (
    <>
      {locationAndTitle.map((info, i) => (
        <div key={i} className="form-group row">
          <label className="col-3 col-form-label">{info.displayTag}</label>
          <div className="col-9">
            <input
              className={`form-control shadow-none ${info.inputBoxStyle}`}
              placeholder={info.placeholder}
              value={info.value}
              onChange={({ target }) => info.setValueFunction(target.value)}
            />
          </div>
        </div>
      ))}
    </>
  );
}

export function MediaTypeAndLitStatus({
  type,
  setType,
  litStatusStr,
  setLitStatusStr,
  className = "",
}) {
  return (
    <div className="form-group row">
      <label className="col-3 col-form-label"> Media Type </label>

      <div className="col-3">
        <select
          className={`form-control shadow-none ${className}`}
          onChange={({ target }) => setType(target.value)}
          value={type}
        >
          <option default>Select</option>
          {Object.values(MediaType).map((filter, i) => (
            <option key={i} value={filter}>
              {filter}
            </option>
          ))}
        </select>
      </div>

      <LitStatus
        onChange={(id, value) => setLitStatusStr(value)}
        litStatusStr={litStatusStr}
        className="col-6"
      />
    </div>
  );
}

export function MediaDimension({
  mediaDimensionValuesAndFunction,
  className = "",
}) {
  const { width, height, elevation, setWidth, setHeight, setElevation } =
    mediaDimensionValuesAndFunction;

  const dimensionInfo = [
    {
      placeholder: "Width",
      setValueFunction: setWidth,
      value: width,
    },
    {
      placeholder: "Height",
      setValueFunction: setHeight,
      value: height,
    },
    {
      placeholder: "Elevation",
      setValueFunction: setElevation,
      value: elevation,
    },
  ];

  return (
    <div className="form-group row">
      <label className="col-3 col-form-label">{"Dimensions (in ft)"}</label>
      {dimensionInfo.map((info, i) => (
        <div key={i} className="col-3">
          <input
            type="number"
            className={`form-control shadow-none ${className}`}
            placeholder={info.placeholder}
            value={info.value}
            onChange={({ target }) => info.setValueFunction(target.value)}
          />
        </div>
      ))}
    </div>
  );
}

export function ContactDetails({
  sellerContactNo,
  setSellerContactNo,
  className = "",
}) {
  return (
    <div className="form-group row">
      <label className="col-3 col-form-label">{"Contact Details"}</label>
      <div className="col-9">
        <input
          className={`form-control shadow-none ${className}`}
          placeholder={"(+91)  (xxxxxxxxxx)"}
          value={sellerContactNo}
          onChange={({ target }) => setSellerContactNo(target.value)}
        />
      </div>
    </div>
  );
}
