import { ActionTypes } from "../../constants/ActionConstants";

const initialState = {
  mapState: {},
  mapStateLoading: false,
  mapStateErr: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.MapState.GET_MAP_STATE:
      return {
        ...state,
        mapStateLoading: true,
      };

    case ActionTypes.MapState.GET_MAP_STATE_SUCCESS: {
      return {
        ...state,
        mapStateLoading: true,
        mapState: action.payload.mapState,
      };
    }

    case ActionTypes.MapState.GET_MAP_STATE_FAILURE:
      return {
        ...state,
        mapStateLoading: false,
        mapStateErr: action.payload,
      };

    case ActionTypes.MapState.RESET_MAP_STATE:
      return initialState;

    default:
      return state;
  }
};
