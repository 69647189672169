export const Buyer = {
  // To get buyers list
  GET_BUYERS_LIST: "GET_BUYERS_LIST",
  GET_BUYERS_LIST_SUCCESS: "GET_BUYERS_LIST_SUCCESS",
  GET_BUYERS_LIST_FAILURE: "GET_BUYERS_LIST_FAILURE",

  // To get buyer users by buyer id
  GET_USERS_BY_BUYER_ID: "GET_USERS_BY_BUYER_ID",
  GET_USERS_BY_BUYER_ID_SUCCESS: "GET_USERS_BY_BUYER_ID_SUCCESS",
  GET_USERS_BY_BUYER_ID_FAILURE: "GET_USERS_BY_BUYER_ID_FAILURE",

  // To clear the users state
  CLEAR_BUYER_USERS: "CLEAR_BUYER_USERS",
};
