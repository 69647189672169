/* eslint-disable no-case-declarations */
import { LocationOfIntrest } from "../../action-constants/LocationOfIntrestActionConstants";

const initialState = {
  openModal: false,

  // toggle influence circle markers
  IsLocationOfIntrestInfluenceCircleVisible: false,
  locationOfIntrestInfluenceCircleRadius: 1000,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LocationOfIntrest.OPEN_LOCATION_OF_INTREST_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case LocationOfIntrest.CLOSE_LOCATION_OF_INTREST_MODAL:
      return {
        ...state,
        openModal: false,
      };

    // Toggle Influence Circle Markers
    case LocationOfIntrest.ADD_LOCATION_OF_INTREST_INFLUENCE_CIRCLE:
      return {
        ...state,
        IsLocationOfIntrestInfluenceCircleVisible: true,
      };

    case LocationOfIntrest.REMOVE_LOCATION_OF_INTREST_INFLUENCE_CIRCLE:
      return {
        ...state,
        IsLocationOfIntrestInfluenceCircleVisible: false,
      };

    case LocationOfIntrest.SET_LOCATION_OF_INTREST_INFLUENCE_CIRCLE_RADIUS:
      return {
        ...state,
        locationOfIntrestInfluenceCircleRadius: action.payload.radius,
      };

    case LocationOfIntrest.RESET_LOCATION_OF_INTREST_INFLUENCE_CIRCLE_RADIUS:
      return {
        ...state,
        IsLocationOfIntrestInfluenceCircleVisible: false,
        locationOfIntrestInfluenceCircleRadius: 1000,
      };

    default:
      return state;
  }
};
