import { Region } from "../../constants/action-constants/RegionActionConstants";

//intital state
const initialState = {
  regionDataLoading: false,
  regionDataError: "",
  regionData: {},

  // REGIONS NAME
  namesLoading: false,
  namesError: "",
  regionsName: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Region.GET_REGION_DATA_BY_CITY:
      return {
        ...state,
        regionDataLoading: true,
      };
    case Region.GET_REGION_DATA_BY_CITY_SUCCESS:
      return {
        ...state,
        regionData: action.payload.regionDataObject,
        regionDataLoading: false,
      };

    case Region.GET_REGION_DATA_BY_CITY_FAILURE:
      return {
        ...state,
        regionDataLoading: false,
        regionDataError: action.payload,
      };

    case Region.CLEAR_REGION_DATA_BY_CITY:
      return {
        ...state,
        regionData: {},
      };

    case Region.GET_REGION_NAMES:
      return {
        ...state,
        namesLoading: true,
      };

    case Region.GET_REGION_NAMES_SUCCESS: {
      const nameItems = Object.keys(action.regionsNames).map((key) => {
        return {
          id: `${action.regionsNames[key]}`,
          label: key,
        };
      });
      return {
        ...state,
        regionsName: nameItems,
        namesLoading: false,
      };
    }

    case Region.GET_REGION_NAMES_FAILURE:
      return {
        ...state,
        namesLoading: false,
        namesError: action.payload,
      };

    default:
      return state;
  }
};
