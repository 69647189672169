import React from "react";
import { useSelector } from "react-redux";

// Constants
import { RedirectTo } from "../../../urls/PageLinksURL";

// Page sections
import MapTab from "../MapTab";
import SelectedDataLayersSection from "./SelectedDataLayersSection";
import DataLayersListingSection from "./DataLayersListingSection";

// constants
const selectedViewLabels = {
  header: {
    title: "Data Layers",
    changeViewBtnText: "Add Data Layer",
  },
  body: {
    emptyText: "Add Data Layer",
    emptyIconCls: "fas fa-layer-group",
  },
  search: {
    placeholder: "Search data layers",
    fields: ["name"],
  },
};

const listingViewLabels = {
  header: {
    title: "Add Data Layers",
    createBtnText: "Create New Data Layer",
    createBtnUrl: `${RedirectTo.orgDataLayerCreateUrl}`,
  },
  body: {
    emptyText: "No Data Layers are available",
  },
  search: {
    placeholder: "Search data layers",
    fields: ["name"],
  },
};

/**
 * TAB - DATA LAYERS
 */
function DataLayersTab({ cityId }) {
  // Selector
  const dataLayerDetailsMap = useSelector(
    (state) => state.orgDataLayers.dataLayerDetailsMap
  );

  const isLayerSelected = useSelector(
    (state) => state.dataLayersSelection.isLayerSelected
  );

  // filtered the selected data layer
  const selectedDataLayersList = Object.values(dataLayerDetailsMap).filter(
    (selectedLayer) => isLayerSelected[selectedLayer.id] && selectedLayer
  );

  // Functions
  // getting content for "selectedViewContent"
  function checkForSelectedViewContent() {
    if (selectedDataLayersList.length === 0) {
      return null;
    }
    return (
      <SelectedDataLayersSection
        selectedDataLayersList={selectedDataLayersList}
        selectedViewLabels={selectedViewLabels}
      />
    );
  }

  // Selected View :: Body
  const selectedViewContent = checkForSelectedViewContent();
  // Listing View :: Body
  const listingViewContent = (
    <DataLayersListingSection
      selectedViewLabels={selectedViewLabels}
      cityId={cityId}
    />
  );

  // Tab Rendered Content ------------------------------------
  return (
    <MapTab
      selectedViewLabels={selectedViewLabels}
      selectedViewChildren={selectedViewContent}
      listingViewLabels={listingViewLabels}
      listingViewChildren={listingViewContent}
    />
  );
}

export default DataLayersTab;
