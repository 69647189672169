import { toast } from "react-toastify";
import { all, put, select, takeLatest } from "redux-saga/effects";

// Apis
import { getSecInfo } from "../../apis/map-view/SecAPI";

// Constants
import { Sec } from "../../constants/action-constants/map-view/SecActionConstants";

// Utils
import { getErrorMessage } from "../../utils/util";

/**
 *  Get Sec Data
 */
export function* getSecData(action) {
  try {
    const { cityBBox } = action.payload;

    // Api call
    const existingSecData = yield select((state) => state.sec.secData);
    if (Object.keys(existingSecData).length > 0) {
      yield put({
        type: Sec.GET_SEC_INFO_SUCCESS,
        payload: existingSecData,
      });
      return;
    }

    const response = yield getSecInfo(cityBBox);
    yield put({
      type: Sec.GET_SEC_INFO_SUCCESS,
      payload: response.result,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Sec.GET_SEC_INFO_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(Sec.GET_SEC_INFO, getSecData)]);
}
