export const SellerMedia = {
  GET_SELLER_MEDIA_BY_STRETCH_IDS: "GET_SELLER_MEDIA_BY_STRETCH_IDS",
  GET_SELLER_MEDIA_BY_STRETCH_IDS_SUCCESS:
    "GET_SELLER_MEDIA_BY_STRETCH_IDS_SUCCESS",
  GET_SELLER_MEDIA_BY_STRETCH_IDS_FAILURE:
    "GET_SELLER_MEDIA_BY_STRETCH_IDS_FAILURE",
  // Reset
  RESET_SELLER_MEDIA_BY_STRETCH_IDS: "RESET_SELLER_MEDIA_BY_STRETCH_IDS",

  // Seller media by city
  GET_SELLER_MEDIA_BY_CITY: "GET_SELLER_MEDIA_BY_CITY",
  GET_SELLER_MEDIA_BY_CITY_SUCCESS: "GET_SELLER_MEDIA_BY_CITY_SUCCESS",
  GET_SELLER_MEDIA_BY_CITY_FAILURE: "GET_SELLER_MEDIA_BY_CITY_FAILURE",
  // clear data
  CLEAR_SELLER_MEDIA_BY_CITY: "CLEAR_SELLER_MEDIA_BY_CITY",

  // Update mavin metrics for media
  UPDATE_METRICS_FOR_SELLER_MEDIA: "UPDATE_METRICS_FOR_SELLER_MEDIA",
  UPDATE_METRICS_FOR_SELLER_MEDIA_SUCCESS:
    "UPDATE_METRICS_FOR_SELLER_MEDIA_SUCCESS",
  UPDATE_METRICS_FOR_SELLER_MEDIA_FAILURE:
    "UPDATE_METRICS_FOR_SELLER_MEDIA_FAILURE",

  // get Seller Media Info
  GET_SELLER_MEDIA_INFO: "GET_SELLER_MEDIA_INFO",
  GET_SELLER_MEDIA_INFO_SUCCESS: "GET_SELLER_MEDIA_INFO_SUCCESS",
  GET_SELLER_MEDIA_INFO_FAILURE: "GET_SELLER_MEDIA_INFO_FAILURE",

  // Update Seller Media Info
  UPDATE_SELLER_MEDIA_INFO: "UPDATE_SELLER_MEDIA_INFO",
  UPDATE_SELLER_MEDIA_INFO_SUCCESS: "UPDATE_SELLER_MEDIA_INFO_SUCCESS",
  UPDATE_SELLER_MEDIA_INFO_FAILURE: "UPDATE_SELLER_MEDIA_INFO_FAILURE",

  // Add Seller Media Images (single or multiple both)
  ADD_SELLER_MEDIA_IMAGES: "ADD_SELLER_MEDIA_IMAGES",
  ADD_SELLER_MEDIA_IMAGES_SUCCESS: "ADD_SELLER_MEDIA_IMAGES_SUCCESS",
  ADD_SELLER_MEDIA_IMAGES_FAILURE: "ADD_SELLER_MEDIA_IMAGES_FAILURE",

  // Remove Seller Media Image (single only)
  REMOVE_SELLER_MEDIA_IMAGE: "REMOVE_SELLER_MEDIA_IMAGE",
  REMOVE_SELLER_MEDIA_IMAGE_SUCCESS: "REMOVE_SELLER_MEDIA_IMAGE_SUCCESS",
  REMOVE_SELLER_MEDIA_IMAGE_FAILURE: "REMOVE_SELLER_MEDIA_IMAGE_FAILURE",

  // Enable Seller Media
  ENABLE_SELLER_MEDIA: "ENABLE_SELLER_MEDIA",
  ENABLE_SELLER_MEDIA_SUCCESS: "ENABLE_SELLER_MEDIA_SUCCESS",
  ENABLE_SELLER_MEDIA_FAILURE: "ENABLE_SELLER_MEDIA_FAILURE",

  // Disable Seller Media
  DISABLE_SELLER_MEDIA: "DISABLE_SELLER_MEDIA",
  DISABLE_SELLER_MEDIA_SUCCESS: "DISABLE_SELLER_MEDIA_SUCCESS",
  DISABLE_SELLER_MEDIA_FAILURE: "DISABLE_SELLER_MEDIA_FAILURE",

  // Update seller media status
  UPDATE_SELLER_MEDIA_STATUS: "UPDATE_SELLER_MEDIA_STATUS",

  // Seller Inventory Upload
  SELLER_INVENTORY_UPLOAD: "SELLER_INVENTORY_UPLOAD",
  SELLER_INVENTORY_UPLOAD_SUCCESS: "SELLER_INVENTORY_UPLOAD_SUCCESS",
  SELLER_INVENTORY_UPLOAD_FAILURE: "SELLER_INVENTORY_UPLOAD_FAILURE",

  // Seller Inventory Template Download
  DOWNLOAD_SELLER_INVENTORY_TEMPLATE: "DOWNLOAD_SELLER_INVENTORY_TEMPLATE",
  DOWNLOAD_SELLER_INVENTORY_TEMPLATE_SUCCESS:
    "DOWNLOAD_SELLER_INVENTORY_TEMPLATE_SUCCESS",
  DOWNLOAD_SELLER_INVENTORY_TEMPLATE_FAILURE:
    "DOWNLOAD_SELLER_INVENTORY_TEMPLATE_FAILURE",

  // Seller Media Applied Filters
  SET_SELLER_MEDIA_FILTERS: "SET_SELLER_MEDIA_FILTERS",
};
