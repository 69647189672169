// URLS
import { getV2SCSSUrl } from "../../urls/map-view/SecURL";

// Constants and utils
import { sendGetRequest } from "../../utils/api/apiUtils";

export async function getSecInfo(cityBBox) {
  const bbox = cityBBox.split(",");
  const params = {
    nw: bbox[1].concat(",", bbox[0]),
    se: bbox[3].concat(",", bbox[2]),
  };
  return sendGetRequest(getV2SCSSUrl, params);
}
