// Utils
import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

// Urls
import { addOrgMediaImagesUrl, getOrgMediaInfoUrl } from "../urls/OrgMediaURL";

// org Media Information
export async function getOrgMediaInfoFn(orgId, mediaId) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const url = getOrgMediaInfoUrl
    .replace(":orgId", orgId)
    .replace(":mediaId", mediaId);
  return sendGetRequest(url, {}, true, headerParams);
}

// add Org media images
export async function addOrgMediaImagesFn(orgId, mediaId, files) {
  const formData = new FormData();
  formData.append("type", "IMAGE");
  Object.values(files).forEach((file) => formData.append("files", file));

  return sendCreateOrUpdateRequest(
    addOrgMediaImagesUrl.replace(":orgId", orgId).replace(":mediaId", mediaId),
    formData
  );
}
