import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Circle, Marker } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getLocationSummary,
  resetLocationSummary,
} from "../../actions/location-summary/LocationSummaryActions";

// Utils and constants
import {
  getCoordinates,
  isValidCoordinate,
} from "../../common-utils/geo-util/coordinatesUtil";
import { India } from "../../constants/GeneralConstants";
import { DemographicSummaryComponent, getMapZoomLevel } from "./ToolsUtils";

// Components
import LLMap from "../../components/map/leaflet-map/LLMap";
import PageHeader from "../../mavin/components/page-header/PageHeader";
import MapIcon from "../../components/map/map-icon/MapIcon";
import { ButtonWithLoader } from "../../mavin/components/button/Button";
import PlacesAutocomplete from "../../components/places-autocomplete/PlacesAutocomplete";
import PinDropLocationAndZoom from "../../components/map/pin-drop-select/PinDropLocationAndZoom";
import RadiusSelector from "../../mavin/components/location-summary/RadiusSelector";

// Page Components
function MapView({ coordinate, radius, getCoordinatesFromPinDrop }) {
  // State
  const [mapZoom, setMapZoom] = useState(null);
  // Center
  const center = coordinate.length > 1 ? coordinate : India.mapCenter;
  const zoom = getMapZoomLevel(coordinate, mapZoom);
  const icon = new MapIcon({ type: "selected-poi" });

  return (
    <div className="map-layout-right bg-alt">
      <LLMap center={center} zoom={zoom}>
        {coordinate.length > 1 && <Marker position={coordinate} icon={icon} />}
        {coordinate.length > 1 && <Circle center={center} radius={radius} />}
        <PinDropLocationAndZoom
          onLocationSelect={getCoordinatesFromPinDrop}
          mapZoom={mapZoom}
          setMapZoom={setMapZoom}
        />
      </LLMap>
    </div>
  );
}

/**
 * TOOL - Demographic
 */
export default function DemographicsToolPage() {
  const dispatch = useDispatch();

  // States
  const [radius, setRadius] = useState(1000);

  // This state is used to avoid loading in "get Demographics" when we change
  // radius of the pi-chart (to understnad remove this and check the functionality)
  const [loadingByButton, setLoadingButton] = useState(true);
  const [latLong, setLatLong] = useState("");
  const [coordinates, setCoordinates] = useState([]);

  //Selector
  const data = useSelector((state) => state.locationSummary.data);
  const isLoading = useSelector((state) => state.locationSummary.dataLoading);

  //Dispatch
  useEffect(() => {
    // Reset  Demographic summary
    dispatch(resetLocationSummary());

    // to clear the "Coordinates" if there is NO "latLng" string
    if (!latLong) {
      setCoordinates([]);
    }
  }, [dispatch, latLong]);

  // Tool Button submit function
  function getDemographics() {
    if (!isValidCoordinate(latLong)) {
      toast.error("In-Valid location details");
      return;
    }

    // Reset the location summary details
    dispatch(resetLocationSummary());

    // Rest the radius
    setRadius(1000);

    setLoadingButton(true);
    const coordinates = getCoordinates(latLong);
    setCoordinates(coordinates);

    dispatch(
      getLocationSummary({
        latitude: coordinates[0],
        longitude: coordinates[1],
        distance: radius,
      })
    );
  }

  // This call-back function is used to pick the lat-lng
  function getCoordinatesFromPinDrop({ lat, lng }) {
    const coordinateString = `${lat},${lng}`;
    setLatLong(coordinateString);
    setCoordinates([lat, lng]);
  }

  function getSearchedLocation(locationStr) {
    setLatLong(locationStr);

    if (isValidCoordinate(locationStr)) {
      setCoordinates(getCoordinates(locationStr));
    }
  }

  function changeRadius(radius) {
    setLoadingButton(false);
    setRadius(radius);
    dispatch(
      getLocationSummary({
        latitude: coordinates[0],
        longitude: coordinates[1],
        distance: radius,
      })
    );
  }

  return (
    <div className="content-wrapper map-content-wrapper">
      {/* Map Left Section */}
      <div className="map-layout-left">
        <PageHeader title={"Demographics"} shadow={false} />
        <hr className="divider my-3 row"></hr>

        {/* Location Search */}
        <PlacesAutocomplete
          className={"rounded-lg shadow-none"}
          placeholder={"Search location or enter Lat Lng"}
          locationStr={latLong}
          setLocationStr={getSearchedLocation}
        />

        {/* Get Demographics button */}
        <div className="text-right mt-3">
          <ButtonWithLoader
            onClickFunction={getDemographics}
            isDisabled={isLoading && loadingByButton}
            displayContent={"Get Demographics"}
            loader={isLoading && loadingByButton}
          />
        </div>
        <hr className="divider row"></hr>

        {data && (
          <>
            {/* Radius Selector */}
            <RadiusSelector
              title={"Location Summary Within"}
              onItemSelect={changeRadius}
              isLoading={isLoading}
            />

            {/* Demographic Summary Component (vertically-aligned) */}
            <DemographicSummaryComponent
              coordinates={coordinates}
              demographicData={data}
            />
          </>
        )}
      </div>

      {/* Map View */}
      <MapView
        coordinate={coordinates}
        radius={radius}
        getCoordinatesFromPinDrop={getCoordinatesFromPinDrop}
      />
    </div>
  );
}
