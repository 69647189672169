import { useSelector } from "react-redux";

// Utils
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import { calEstPrice } from "../../utils/PriceFactorUtil";
import { getStretchImpressions } from "../../utils/campaignPlanningUtil";

// Components
import Spinner from "../../../components/spinner/Spinner";
import { ProgressBar as HeaderProgressBar } from "../../../components/progress-bar/ProgressBar";
import TableHeaders from "../../../components/table/TableHeaders";
import { AddCaptiveAreaSection, StretchSelectionButton } from "./PlanningUtils";
import { SortingBtn } from "./SortingUtils";

// Table Headers
const tableHeaders = (
  stretchesCount,
  requestRoadSegmentsSort,
  requestRoadStretchSort
) => [
  {
    element: <RoadStretchHeader stretchesCount={stretchesCount} />,
  },
  {
    element: <OtsHeader />,
  },
  {
    title: {
      displayName: "Avg. MOP",
      className: "col-2 align-middle",
    },
  },
  {
    element: (
      <ActionHeader
        requestRoadSegmentsSort={requestRoadSegmentsSort}
        requestRoadStretchSort={requestRoadStretchSort}
      />
    ),
  },
];

function RoadStretchHeader({ stretchesCount }) {
  return (
    <th className="col-3 align-middle">
      <p className="mb-0">Road Stretches ({stretchesCount})</p>
      {/* <AddCaptiveAreaSection /> */}
    </th>
  );
}

function OtsHeader() {
  return (
    <th className="col-5 pr-4 align-middle">
      <div className="row no-gutters">
        <span className="col-5">Imp TG</span>
        <div className="col-7">
          <HeaderProgressBar className="bg-light-green" percentage={40} />
        </div>
      </div>

      <div className="row no-gutters">
        <span className="col-5">Imp Gen</span>
        <div className="col-7">
          <HeaderProgressBar className="bg-light-blue" percentage={100} />
        </div>
      </div>
    </th>
  );
}

function ActionHeader({ requestRoadSegmentsSort, requestRoadStretchSort }) {
  return (
    <th className="col-2 align-middle text-right">
      <div className="d-flex justify-content-end">
        <SortingBtn
          requestRoadStretchSort={requestRoadStretchSort}
          requestRoadSegmentsSort={requestRoadSegmentsSort}
        />
      </div>
    </th>
  );
}

function ProgressBar({ baseOts, currentOts, className }) {
  const width = currentOts ? (currentOts / baseOts) * 100 : 0;
  const widthPercentage = width + "%";

  return (
    <span
      className={`progress progress-bar ${className}`}
      role="progressbar"
      style={{ width: widthPercentage }}
      aria-valuenow={width}
      aria-valuemin="0"
      aria-valuemax="100"
    ></span>
  );
}

function ImpressionInfoCell({ stretchInfo, baseOts, dayCount }) {
  const {
    otsAvg,
    otsLitAvg,
    genericOtsLit,
    genericOts,
    targetOtsLit,
    targetOts,
  } = stretchInfo;

  const estImpressions = otsLitAvg ?? otsAvg;

  // for TG-specific-impressions
  const genericImpressions = genericOtsLit ?? genericOts;
  const specificImpressions = targetOtsLit ?? targetOts;

  if (genericImpressions || specificImpressions) {
    return (
      <td className="col-5 pr-4 align-middle">
        <div className="row no-gutters">
          <span
            className="col-5"
            title={toStringWithRoundUp(dayCount * specificImpressions)}
          >
            {specificImpressions
              ? toStringWithRoundUp(dayCount * specificImpressions)
              : "NA"}
          </span>
          <div className="col-7">
            <ProgressBar
              baseOts={baseOts}
              currentOts={specificImpressions}
              className="bg-light-green"
            />
          </div>
        </div>

        <div className="row no-gutters">
          <span className="col-5">
            {genericImpressions
              ? toStringWithRoundUp(dayCount * genericImpressions)
              : "NA"}
          </span>
          <div className="col-7">
            <ProgressBar
              baseOts={baseOts}
              currentOts={genericImpressions}
              className="bg-light-blue"
            />
          </div>
        </div>
      </td>
    );
  }

  return (
    <td className="col-5 pr-4 align-middle">
      <div className="row no-gutters">
        <span className="col-5">
          {estImpressions
            ? toStringWithRoundUp(dayCount * estImpressions)
            : "NA"}
        </span>
        <div className="col-7">
          <ProgressBar
            baseOts={baseOts}
            currentOts={estImpressions}
            className="bg-light-blue"
          />
        </div>
      </div>
    </td>
  );
}

function StretchInfoCell({ stretchInfo, dayCount }) {
  const { name } = stretchInfo;
  const { genericImpressions, specificImpressions } =
    getStretchImpressions(stretchInfo);

  // Total Impression ==> After selecting TG
  if (specificImpressions) {
    const totalImpressions = specificImpressions + genericImpressions;
    return (
      <td className="col-3 align-middle">
        <p className="mb-0 text-truncate stretch-info-cell" title={name}>
          {name}
        </p>
        <div className="d-flex sub-text">
          <span>Sites: --</span>
          <span className="px-1">|</span>
          <span>
            Total Imp:{" "}
            {totalImpressions
              ? toStringWithRoundUp(dayCount * totalImpressions)
              : "NA"}
          </span>
        </div>
      </td>
    );
  }

  // Total Impressions ==> Before selecting TG
  return (
    <td className="col-3 align-middle">
      <p className="mb-0 text-truncate stretch-info-cell" title={name}>
        {name}
      </p>
      <div className="d-flex sub-text">
        <span>Sites: --</span>
        <span className="px-1">|</span>
        <span>
          Total Imp:{" "}
          {genericImpressions
            ? toStringWithRoundUp(dayCount * genericImpressions)
            : "NA"}
        </span>
      </div>
    </td>
  );
}

function EstimatedPriceCell({ stretchInfo, dayCount }) {
  const { minOperatingPriceAvg: mop = 1, occupancyAvg: occupancy = 1 } =
    stretchInfo;
  const { genericImpressions, specificImpressions } =
    getStretchImpressions(stretchInfo);

  const genPriceFactor = useSelector((state) => state.campaignPlanning.genCpm);
  const tgPriceFactor = useSelector((state) => state.campaignPlanning.tgCpm);

  const estimatePriceBean = {
    genericImpressions,
    specificImpressions,
    mop,
    occupancy,
    dayCount,
    tgPriceFactor,
    genPriceFactor,
  };
  const { genericCost, specificCost } = calEstPrice(estimatePriceBean);
  const totalEstCost = specificCost ? genericCost + specificCost : genericCost;

  return (
    <td className="col-2 align-middle">
      <p className="mb-0">{toStringWithRoundUp(mop ? mop : "NA")}</p>
      <p className="mb-0">
        {toStringWithRoundUp(totalEstCost ? totalEstCost : "NA")}
      </p>
    </td>
  );
}

function RoadStretchTableRow({ stretchInfo, baseOts, dayCount }) {
  return (
    <tr>
      {/* stretch info cell */}
      <StretchInfoCell stretchInfo={stretchInfo} dayCount={dayCount} />

      {/* impression info cell */}
      <ImpressionInfoCell
        stretchInfo={stretchInfo}
        baseOts={baseOts}
        dayCount={dayCount}
      />

      {/* estimated price cell */}
      <EstimatedPriceCell stretchInfo={stretchInfo} dayCount={dayCount} />

      {/* stretch selection button */}
      <StretchSelectionButton stretchInfo={stretchInfo} />
    </tr>
  );
}

function RoadStretchTableBody({ roadStretchData, baseOts }) {
  // Duration
  const duration = useSelector(
    (state) => state.campaignPlanning.durationInDays
  );

  // RoadStretch data is empty return null
  if (roadStretchData.length === 0) {
    return null;
  }

  // Road stretch table row
  return (
    <>
      {roadStretchData.map((stretchInfo) => (
        <RoadStretchTableRow
          key={stretchInfo.id}
          stretchInfo={stretchInfo}
          dayCount={duration}
          baseOts={baseOts}
        />
      ))}
    </>
  );
}

/**
 * Road Stretch Table
 */
function RoadStretchTable({
  roadStretchData,
  baseOts,
  roadStretchLoading,
  requestRoadSegmentsSort,
  requestRoadStretchSort,
}) {
  if (roadStretchLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-4">
        <Spinner />
        <span className="ml-2">Please wait, Road Stretches are Loading...</span>
      </div>
    );
  }

  const isRoadStretchesPresent = roadStretchData.length > 0;

  // no stretches msg
  if (!isRoadStretchesPresent) {
    return null;
  }

  // stretches table
  return (
    <table className="table">
      <TableHeaders
        tableHeaders={tableHeaders(
          roadStretchData.length,
          requestRoadSegmentsSort,
          requestRoadStretchSort
        )}
        headerClass="bg-white"
        requiredSubHeader={false}
      />

      <tbody>
        <RoadStretchTableBody
          roadStretchData={roadStretchData}
          baseOts={baseOts}
          roadStretchLoading={roadStretchLoading}
        />
      </tbody>
    </table>
  );
}

export default RoadStretchTable;
