import { useSelector } from "react-redux";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Pages And Section
import SelectedStretchesSection from "./SelectedStretchesSection";
import StretchesListingSection from "./StretchesListingSection";
import MapTab from "../MapTab";

// constants
const selectedViewLabels = {
  header: {
    title: "Road Stretches",
    changeViewBtnText: "Add Road Stretches To Map",
  },
  body: {
    emptyText: "Add Road Stretches",
    emptyIconCls: "fas fa-road",
  },
  search: {
    placeholder: "Search road stretch",
    fields: ["name"],
  },
};

const listingViewLabels = {
  header: {
    title: "Add Road Stretches",
    createBtnText: "Create New Road Stretch",
    createBtnUrl: `${RedirectTo.orgRoadStretchCreateUrl}`,
  },
  body: {
    emptyText: "No Road-Stretches are available for this city",
  },
  search: {
    placeholder: "Search road stretches",
    fields: ["name"],
  },
};

// Page Functions
/**
 * @param {*} roadStretchList
 * @param {*} selectedStretchIds
 * @returns getting only selected Stretches Info from all stretches
 */
function getSelectedStretches(roadStretchMap, selectedStretchIds) {
  const selectedStretchesArray = selectedStretchIds.reduce((acc, stretchId) => {
    acc.push(roadStretchMap[stretchId]);
    return acc;
  }, []);
  return selectedStretchesArray;
}

/**
 * TAB - ROAD STRETCHES
 */
function RoadStretchesTab({ cityId }) {
  // Selector State
  const roadStretchMap = useSelector(
    (state) => state.orgRoadStretch.orgRoadStretches
  );

  // Selected Stretches Data
  const selectedStretchIds = useSelector((state) =>
    Object.keys(state.roadStretchesSelection.selectedStretchesMapView)
  );
  const selectedStretchesArray = getSelectedStretches(
    roadStretchMap,
    selectedStretchIds
  );

  // Function
  // getting content for "selectedViewContent"
  function checkForSelectedViewContent() {
    if (!selectedStretchIds || selectedStretchIds.length === 0) {
      return null;
    }
    return (
      <SelectedStretchesSection
        selectedStretchesArray={selectedStretchesArray}
        selectedViewLabels={selectedViewLabels}
      />
    );
  }

  // Selected View :: Body
  const selectedViewContent = checkForSelectedViewContent();
  // Listing View :: Body
  const listingViewContent = (
    <StretchesListingSection
      roadStretchList={Object.values(roadStretchMap)}
      listingViewLabels={listingViewLabels}
      cityId={cityId}
    />
  );

  // Tab Rendered Content ------------------------------------
  return (
    <MapTab
      selectedViewLabels={selectedViewLabels}
      selectedViewChildren={selectedViewContent}
      listingViewLabels={listingViewLabels}
      listingViewChildren={listingViewContent}
    />
  );
}

export default RoadStretchesTab;
