import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

// Constants & Utils
import {
  DATE_FORMATS,
  DurationConstants,
  StatusIcon,
} from "../../../constants/GeneralConstants";
import {
  constructDateString,
  getDifferenceInDays,
} from "../../../common-utils/date-utils/DateUtils";
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import { CityRedirect } from "../components/CampaignRedirect";

// Table Constants
const LiveCityHighlightsTableHeaders = [
  {
    title: {
      displayName: "City",
      className: "",
    },
    subTitle: {
      displayName: "Status",
      className: "sub-text",
    },
  },
  {
    title: {
      displayName: "No. of sites",
      className: "align-top text-right",
    },
  },
  {
    title: {
      displayName: "Start and End date",
      className: "text-right",
    },
    subTitle: {
      displayName: "Duration",
      className: "sub-text text-right",
    },
  },
  {
    title: {
      displayName: "Live Target Imp",
      className: "text-right",
    },
    subTitle: {
      displayName: "Est Impressions",
      className: "sub-text text-right",
    },
  },
  {
    title: {
      displayName: "Live General Imp",
      className: "text-right",
    },
    subTitle: {
      displayName: "Est Impressions",
      className: "sub-text text-right",
    },
  },
  {
    title: {
      displayName: "Monitoring",
      className: "text-right align-top",
    },
  },
];

function CityCell({ cityName, cityId, campaign }) {
  const { icon, color, statusText } = StatusIcon["LIVE"];
  const { id: campaignId, planStatus: state } = campaign;
  return (
    <td>
      <CityRedirect
        className="mb-0"
        campaignId={campaignId}
        cityId={cityId}
        state={state}
        displayText={cityName}
      />
      {/* <p className="mb-0 btn-link">{cityName}</p> */}
      <div className={color}>
        <i className={`fa ${icon} pr-1`}></i>
        {statusText}
      </div>
    </td>
  );
}

function SitesCountCell({ mediaCount }) {
  return (
    <td className="text-right align-top">
      <p className="mb-0">
        {/* TODO - show the count of live sites */}
        <b>{"--"}</b>
        {" of "}
        <b>{mediaCount}</b>
        {" are live"}
      </p>
    </td>
  );
}

function SiteDurationCell({ campaign }) {
  const { startTimestamp, endTimestamp } = campaign;

  const campaignDate = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  const campaignDuration = getDifferenceInDays(startTimestamp, endTimestamp);
  return (
    <td className="text-right">
      <p className="mb-0">{`${campaignDate}`}</p>
      <span className="sub-text">{`${campaignDuration} days`}</span>
    </td>
  );
}

function LiveTgCell({ planByCity, campaignPerformance }) {
  const { totalTargetOts, totalTargetOtsLit } = planByCity;

  const targetOtsServedTotal = campaignPerformance?.targetOtsServedTotal;

  // TODO:: Remove 30 as multiplication factor - fix PlanData
  const targetOts =
    (totalTargetOtsLit ?? totalTargetOts) * DurationConstants.THIRTY_DAYS;

  return (
    <td className="text-right">
      <p className="mb-0">{toLocaleString(targetOtsServedTotal) || "--"}</p>
      <span className="sub-text">{toLocaleString(targetOts)}</span>
    </td>
  );
}

function LiveGenericCell({ planByCity, campaignPerformance }) {
  const { totalGenericOts, totalGenericOtsLit } = planByCity;

  const genericOtsServedTotal = campaignPerformance?.genericOtsServedTotal;
  // TODO:: Remove 30 as multiplication factor - fix PlanData
  const genericOts =
    (totalGenericOtsLit ?? totalGenericOts) * DurationConstants.THIRTY_DAYS;

  return (
    <td className="text-right">
      <p className="mb-0">{toLocaleString(genericOtsServedTotal) || "--"}</p>
      <span className="sub-text">{toLocaleString(genericOts)}</span>
    </td>
  );
}

function CityLtsCell() {
  return (
    <td className="text-right">
      <p className="mb-0">{"3.7/5"}</p>
      <span className="sub-text">{"3/3 sites"}</span>
    </td>
  );
}

function CityMonitoringCell({ campaignId, cityId }) {
  return (
    <td className="text-right">
      <Link to={`/monitor/settings/campaign/${campaignId}/city/${cityId}`}>
        View Updates
      </Link>
    </td>
  );
}

function LiveCityHighlightsTableRow({
  planByCity,
  campaignPerformance,
  cityId,
}) {
  // Selector
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  const { campaignId } = useParams();

  const { infoByCity = {} } = campaign;
  const cityName = infoByCity[cityId]?.cityName;
  const mediaCount = infoByCity[cityId]?.mediaCount;

  return (
    <tr>
      <CityCell cityName={cityName} cityId={cityId} campaign={campaign} />
      <SitesCountCell mediaCount={mediaCount} />
      <SiteDurationCell campaign={campaign} />
      <LiveTgCell
        planByCity={planByCity}
        campaignPerformance={campaignPerformance}
      />
      <LiveGenericCell
        planByCity={planByCity}
        campaignPerformance={campaignPerformance}
      />
      <CityMonitoringCell campaignId={campaignId} cityId={cityId} />
    </tr>
  );
}

function LiveCityHighlightsTable({ campaignPlanOverview }) {
  // Selector
  const campaignPerformance =
    useSelector((state) => state.campaignPerformance.campaignPerformance) || {};

  const { planByCity = {} } = campaignPlanOverview;
  return (
    <div className="mt-5">
      <h4>{"City Highlights"}</h4>
      <div className="table-responsive mt-3">
        <table className="table table-media">
          <TableHeaders
            tableHeaders={LiveCityHighlightsTableHeaders}
            headerClass={"thead"}
          />
          <tbody>
            {Object.keys(planByCity).map((eachCityId, i) => (
              <LiveCityHighlightsTableRow
                key={i}
                planByCity={planByCity[eachCityId]}
                cityId={eachCityId}
                campaignPerformance={campaignPerformance[eachCityId]}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LiveCityHighlightsTable;
