import { useState } from "react";
import { useDispatch } from "react-redux";

//Actions
import { createMedia } from "../../actions/campaign-media/CampaignMediaActions";

// Constants and Utils
import { FormDataTargets } from "../../constants/GeneralConstants";

//Components
import {
  ContactDetails,
  MediaLocationAndTitle,
  MediaDimension,
  MediaImageUploadAndPreview,
  MediaTypeAndLitStatus,
  CreateMediaModalFooter,
  CreateMediaModalTitle,
} from "./CampaignMediaFormUtils";

function ModalContent({ campaignId, cityId }) {
  // State
  // Dimentions
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [elevation, setElevation] = useState("");

  // Media Type
  const [type, setType] = useState("");

  // Site Name
  const [title, setTitle] = useState("");

  // Lit Status
  const [litStatusStr, setLitStatusStr] = useState("");

  //longitude and latitude string
  const [locationStr, setLocationStr] = useState("");

  // Seller contact number
  const [sellerContactNo, setSellerContactNo] = useState("");

  // Media Images
  const [imageFiles, setImageFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  //Dispatch
  const dispatch = useDispatch();

  function handleSubmitForm() {
    const createMediaBean = {
      width,
      height,
      elevation,
      type,
      title,
      litStatusStr,
      locationStr,
    };
    dispatch(createMedia(createMediaBean, imageFiles, campaignId, cityId));
  }

  // to clear the input state when cancel button is clicked
  function handleClearForm() {
    setWidth("");
    setHeight("");
    setElevation("");
    setType("");
    setTitle("");
    setLitStatusStr("");
    setLocationStr("");
    setSellerContactNo("");
    setImageFiles([]);
    setPreviewImages([]);
  }

  const mediaDimensionValuesAndFunction = {
    width,
    height,
    elevation,
    setWidth,
    setHeight,
    setElevation,
  };

  return (
    <div className="modal-content">
      {/* modal title */}
      <CreateMediaModalTitle />

      <div className="modal-body px-5">
        {/* Media location and name */}
        <MediaLocationAndTitle
          locationStr={locationStr}
          title={title}
          setLocationStr={setLocationStr}
          setTitle={setTitle}
        />

        {/* Media type & Lit status  */}
        <MediaTypeAndLitStatus
          type={type}
          setType={setType}
          litStatusStr={litStatusStr}
          setLitStatusStr={setLitStatusStr}
          className="rounded-lg"
        />

        {/* Media dimension */}
        <MediaDimension
          mediaDimensionValuesAndFunction={mediaDimensionValuesAndFunction}
          className="rounded-lg"
        />

        <ContactDetails
          sellerContactNo={sellerContactNo}
          setSellerContactNo={setSellerContactNo}
          className="rounded-lg"
        />

        {/* Upload media image and preview */}
        <MediaImageUploadAndPreview
          imageFiles={imageFiles}
          previewImages={previewImages}
          setPreviewImages={setPreviewImages}
          setImageFiles={setImageFiles}
        />
      </div>

      {/* Modal Footer */}
      <CreateMediaModalFooter
        handleSubmitForm={handleSubmitForm}
        handleClearForm={handleClearForm}
      />
    </div>
  );
}

function CampaignMediaForm({ campaignId, cityId }) {
  return (
    <div
      className="modal fade"
      id={FormDataTargets.campaignMediaForm}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <ModalContent campaignId={campaignId} cityId={cityId} />
      </div>
    </div>
  );
}

export default CampaignMediaForm;
