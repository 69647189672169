import { getItem } from "../../utils/localstorage";
import { OrgType } from "../../constants/OrgTypeConstants";
import { LocalStorageKeys } from "../../constants/GeneralConstants";

// Validates whether the OriginalComponent is to be rendered or not
// If the Organization Type id Media Agency then will render the component..
export const isAgencySpecific = (OriginalComponent) => {
  const organisationType = getItem(LocalStorageKeys.ORG_TYPE);

  if (organisationType === OrgType.MEDIA_AGENCY) {
    return OriginalComponent;
  }
  return null;
};
