import { LocationSummary } from "../../constants/action-constants/LocationSummaryActionConstants";

// initial state
const initialState = {
  // location summary
  dataLoading: false,
  data: "",
  dataErr: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LocationSummary.GET_LOCATION_SUMMARY:
      return {
        ...state,
        dataLoading: true,
      };

    case LocationSummary.GET_LOCATION_SUMMARY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        dataLoading: false,
      };
    }

    case LocationSummary.GET_LOCATION_SUMMARY_FAILURE: {
      return {
        ...state,
        dataLoading: false,
        dataErr: action.payload,
      };
    }

    case LocationSummary.RESET_LOCATION_SUMMARY: {
      return initialState;
    }

    default:
      return state;
  }
};
