import { useState } from "react";

// Components
import Spinner from "../spinner/Spinner";

/**
 * COMPONENT :: Title Editor
 */
function TitleEditor({
  title,
  onTitleChange,
  loader,
  titleClass = "",
  inputBoxClass = "",
}) {
  // States
  const [titleBox, setTitleBox] = useState(false);
  const [titleStr, setTitleStr] = useState(title);

  // Functions
  const setTitleFunction = (e) => {
    if (!titleStr && e.key === "Enter") {
      setTitleStr(title);
      setTitleBox(false);
      return;
    }

    if (e.key === "Enter") {
      onTitleChange(titleStr);
      setTitleBox(false);
    }
  };

  if (titleBox) {
    return (
      <input
        type="text"
        onKeyPress={(e) => setTitleFunction(e)}
        value={titleStr}
        onChange={({ target }) => setTitleStr(target.value)}
        className={`form-control shadow-none ${inputBoxClass}`}
        placeholder="Type Title And Press Enter"
      ></input>
    );
  }

  return (
    <div className="d-flex">
      <h2 className={`text-truncate title ${titleClass}`} title={title}>
        {title}
      </h2>
      <button
        type="button"
        className="btn px-2 pb-0 shadow-none"
        onClick={() => setTitleBox(true)}
      >
        <i className="fa fa-pen text-primary"></i>
      </button>
      {loader && <Spinner className="mx-2" />}
    </div>
  );
}

export default TitleEditor;
