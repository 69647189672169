/* eslint-disable require-yield */
import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Api
import {
  getRegionDataByCityId,
  getRegionNamesList,
} from "../../apis/RegionAPI";

// Constants & Utils
import { Region } from "../../constants/action-constants/RegionActionConstants";
import { setErrorStatusCode } from "../../utils/ErrorUtils";
import { getErrorMessage } from "../../utils/util";

// for getting region data for specific city
export function* getRegionDataByCity(action) {
  const { cityId } = action.payload;
  try {
    const regionsData = yield getRegionDataByCityId(cityId);

    const {
      aspectRatioIndexAvg,
      areaIndexAvg,
      clutterIndexAvg,
      elevationIndexAvg,
      tiltIndexAvg,
      name,
      id,
      bbox,
      center,
      population,
    } = regionsData;

    const regionDataIndexes = {
      aspectRatioIndexAvg,
      areaIndexAvg,
      clutterIndexAvg,
      elevationIndexAvg,
      tiltIndexAvg,
    };

    // required data
    const regionDataObject = {
      name,
      id,
      bbox,
      center,
      regionDataIndexes,
      population,
    };

    yield put({
      type: Region.GET_REGION_DATA_BY_CITY_SUCCESS,
      payload: { regionDataObject },
    });
  } catch (error) {
    // stores the error and render the error image when the api fails
    setErrorStatusCode(error);

    yield put({
      type: Region.GET_REGION_DATA_BY_CITY_FAILURE,
      payload: error,
    });
  }
}

export function* getRegionNames() {
  try {
    // dummy api response
    const regionsNames = yield getRegionNamesList();

    yield put({
      type: Region.GET_REGION_NAMES_SUCCESS,
      regionsNames: regionsNames,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Region.GET_REGION_NAMES_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(Region.GET_REGION_DATA_BY_CITY, getRegionDataByCity),
    takeLatest(Region.GET_REGION_NAMES, getRegionNames),
  ]);
}
