// Utils and Constants
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";

// Components
import TableHeaders from "../../../components/table/TableHeaders";

//Section Constants
const tableHeaders = [
  {
    title: {
      displayName: "Feeder Road",
      className: "col-6 ",
    },
  },
  {
    title: {
      displayName: "Probability Split Contribution",
      className: "col-6 ",
    },
  },
];

// Probability Split Ots Table Row
function ProbabilitySplitOtsTableRow({ feederRoad }) {
  const { ots, id } = feederRoad;

  return (
    <tr>
      <td>{id}</td>
      <td>{formatText(toLocaleString(Math.ceil(ots)))}</td>
    </tr>
  );
}

/** Probability Split Ots Table
 *
 * @param {*} feederRoads=> roads  connected to selected road segment directly andd indirectly
 */
export default function ProbabilitySplitOtsTable({ feederRoads }) {
  if (Object.keys(feederRoads).length === 0) {
    return (
      <div className="mt-4">
        <h4>Feeder Roads</h4>
        <h5 className=" mt-3 text-center font-italic">
          Feeder Roads Information not available
        </h5>
      </div>
    );
  }

  // feederRoadsArray
  const feederRoadsArray = Object.keys(feederRoads).reduce(
    (acc, eachFeederRoad) => {
      return [
        ...acc,
        {
          ots: feederRoads[eachFeederRoad],
          id: eachFeederRoad,
        },
      ];
    },
    []
  );
  return (
    <div className="table-responsive mt-4">
      <h4>Feeder Roads</h4>

      <table className="table table-media">
        {/* Table header */}
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />

        <tbody>
          {/* Table rows */}
          {feederRoadsArray.map((feederRoad, i) => (
            <ProbabilitySplitOtsTableRow feederRoad={feederRoad} key={i} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
