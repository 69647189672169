import LocationOfIntrestReducer from "./map-view/LocationOfIntrestReducer";
import CampaignBudgetReducer from "./campaign/CampaignBudgetReducer";
import CampaignMediaSelectionReducer from "./campaign/CampaignMediaSelectionReducer";

export const mantarayReducers = {
  // Location Of Intrest Reducer
  locationOfIntrest: LocationOfIntrestReducer,

  // Campaign Budget
  campaignBudget: CampaignBudgetReducer,

  // Campaign Media Selection
  campaignMediaSelection: CampaignMediaSelectionReducer,
};
