import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Actions
import {
  clearBuyerUsersList,
  getUsersByBuyerId,
} from "../../../actions/buyer/BuyerActions";
import { updateCampaignAndSaveSettings } from "../../actions/campaign-review/CampaignReviewActions";

// Constants and Utils
import { ProohFormDataTargets } from "../../constants/GeneralConstants";

//Components
import Spinner from "../../../components/spinner/Spinner";

function ModalTitle() {
  return (
    <div className="modal-header bg-alt px-5">
      <h4 className="modal-title">Send confirmation</h4>
    </div>
  );
}

function ModalFooter({ handleSubmitForm, handleClearForm, disableSendButton }) {
  const cancelAndSaveButtonDetails = [
    {
      label: "Cancel",
      className: "btn-outline-primary",
      onClick: handleClearForm,
      disable: false,
    },
    {
      label: "Send",
      className: "btn-primary",
      onClick: handleSubmitForm,
      disable: disableSendButton,
    },
  ];

  return (
    <div className="modal-footer px-5">
      {cancelAndSaveButtonDetails.map((item, i) => (
        <button
          key={i}
          type="button"
          disabled={item.disable || item.loader}
          onClick={item.onClick}
          className={`btn ${item.className} btn-action`}
          data-dismiss="modal"
        >
          {item.label}
        </button>
      ))}
    </div>
  );
}

function CampaignName({ title, setTitle }) {
  return (
    <div className="form-group row">
      <label htmlFor="campaignName" className="col-2 px-0 col-form-label">
        Campaign Name
      </label>
      <div className="col-10 px-0">
        <input
          type="text"
          value={title}
          className="form-control shadow-none rounded-lg"
          onChange={({ target }) => setTitle(target.value)}
          placeholder="Enter campaign name"
          id="campaignName"
        />
      </div>
    </div>
  );
}

function BuyerName({ buyerName, setBuyerName, setBuyerId }) {
  // State
  const [suggestedBuyersName, setSuggestedBuyersName] = useState([]);

  //Selector
  const buyersList = useSelector((state) => state.buyer.buyersList) || [];

  //Dispatch
  const dispatch = useDispatch();

  // Search buyer From Input Function
  function searchBrandFn({ target }) {
    const inputText = target.value;
    setBuyerName(inputText);
    if (inputText.length <= 1) {
      return setSuggestedBuyersName([]), setBuyerId("");
    }

    const suggestion = buyersList.filter((eachBuyer) =>
      eachBuyer.name.toLowerCase().includes(inputText.toLowerCase())
    );
    setSuggestedBuyersName(suggestion);
  }

  // function to select buyer from suggestions
  function handleBrandSelect(eachBuyer) {
    setSuggestedBuyersName([]);
    setBuyerId(eachBuyer.id);
    setBuyerName(eachBuyer.name);

    //action to get users by buyer id
    dispatch(getUsersByBuyerId(eachBuyer.id));
  }

  return (
    <div className="form-group row">
      <label htmlFor="brand" className="col-2 px-0 col-form-label">
        Brand Name
      </label>
      <div className="col-10 px-0">
        <input
          type="search"
          className={`form-control rounded-lg shadow-none`}
          placeholder={"Search brand"}
          id="brand"
          value={buyerName}
          onChange={searchBrandFn}
        />

        {/* Suggested Buyer name*/}
        {suggestedBuyersName.length > 0 && (
          <div className="border rounded-lg mt-1 bg-alt">
            {/* we are showing only five suggestions which matches search input */}
            {suggestedBuyersName.slice(0, 5).map((eachBuyer, i) => (
              <li
                key={i}
                className="dropdown-item cursor-pointer"
                onClick={() => handleBrandSelect(eachBuyer)}
              >
                {eachBuyer.name}
              </li>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function UserSelectionSection({ user, handleUsers, handleCheckedValue }) {
  return (
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="checkbox"
        value={user.id}
        checked={handleCheckedValue(user.id)}
        onClick={handleUsers}
      />
      <p className="m-0 pl-2">
        <span>{user.firstName}</span>
        <span className="pl-1">({user.emailId})</span>
      </p>
    </div>
  );
}

function Contacts({ saveSettings, setSaveSettings }) {
  //Selector
  const usersList = useSelector((state) => state.buyer.usersList);
  const usersListLoading = useSelector((state) => state.buyer.usersListLoading);

  const { campaignId } = useParams();

  function handleUsers(e) {
    // to select value
    if (e.currentTarget.checked) {
      const shareSettingObj = {
        entityType: "CAMPAIGN",
        entityId: campaignId,
        sharedWith: e.target.value,
        accessLevel: "READ",
        notes: "Please review the Campaign and Approve.",
      };
      setSaveSettings({ ...saveSettings, [e.target.value]: shareSettingObj });
    } else {
      // to un-select value

      // to remove un selected id form object
      const {
        [e.target.value]: {},
        ...updatedSaveSettings
      } = saveSettings;
      setSaveSettings(updatedSaveSettings);
    }
  }

  // to handle checked value
  function handleCheckedValue(value) {
    return Object.keys(saveSettings).some((info) => info === value) || false;
  }

  // checks for users list loading
  if (usersListLoading) {
    return (
      <div className={" d-flex justify-content-center my-2"}>
        <Spinner />
      </div>
    );
  }

  // checks for userList
  if (!usersList.length) {
    return null;
  }

  return (
    <div className="form-group row">
      <label htmlFor="staticEmail" className="col-2 px-0 col-form-label">
        Contacts
      </label>

      {/* Users name and email */}
      <div className="d-flex flex-column mt-2 ">
        {usersList.map((user, i) => (
          <UserSelectionSection
            key={i}
            user={user}
            handleCheckedValue={handleCheckedValue}
            handleUsers={handleUsers}
          />
        ))}
      </div>
    </div>
  );
}

function CampaignConfirmationForm() {
  //State
  const [title, setTitle] = useState("");
  const [buyerId, setBuyerId] = useState("");
  const [buyerName, setBuyerName] = useState("");
  const [saveSettings, setSaveSettings] = useState({});

  const history = useHistory();

  //Dispatch
  const dispatch = useDispatch();

  const { campaignId } = useParams();

  function handleSubmitForm() {
    dispatch(
      updateCampaignAndSaveSettings(
        title,
        buyerId,
        campaignId,
        saveSettings,
        history
      )
    );
  }

  function handleClearForm() {
    setBuyerName("");
    setTitle("");
    setBuyerId("");
    setSaveSettings([]);
    dispatch(clearBuyerUsersList());
  }

  // disable send button if any one of the field is empty
  const disableSendButton =
    !title || !buyerId || Object.keys(saveSettings).length < 1;

  return (
    <div
      className="modal fade"
      id={ProohFormDataTargets.sendConfirmationForm}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        {/* Modal content */}

        <div className="modal-content">
          {/* modal title */}
          <ModalTitle />

          <div className="modal-body px-5">
            {/* Campaign Name */}
            <CampaignName title={title} setTitle={setTitle} />

            {/* Buyer Name  */}
            <BuyerName
              buyerName={buyerName}
              setBuyerName={setBuyerName}
              setBuyerId={setBuyerId}
            />

            {/* Contacts */}
            <Contacts
              saveSettings={saveSettings}
              setSaveSettings={setSaveSettings}
            />
          </div>

          {/* Modal Footer */}
          <ModalFooter
            handleSubmitForm={handleSubmitForm}
            disableSendButton={disableSendButton}
            handleClearForm={handleClearForm}
          />
        </div>
      </div>
    </div>
  );
}

export default CampaignConfirmationForm;
