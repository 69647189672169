import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  addSelectedRoadStretch,
  removeSelectedRoadStretch,
} from "../../../actions/map-view/RoadStretchesSelectionActions";
import { getRoadStretchesOfOrg } from "../../../actions/org/OrgRoadStretchActions";

// Constants and Utils
import { defaultPagination } from "../../../constants/UrlConstants";

// Components
import SearchInputLocal from "../../../components/search-input-local/SearchInputLocal";
import Spinner from "../../../components/spinner/Spinner";

// Page-Component
function StretchListing({ stretchInfo, isStretchSelected }) {
  const dispatch = useDispatch();

  const { name, id } = stretchInfo;

  // selected and select button config
  const buttonClassName = isStretchSelected
    ? "btn-outline-success"
    : "btn-outline-primary";

  const buttonIconClassName = isStretchSelected ? "fa fa-check" : "";
  const dispatchFunction = isStretchSelected
    ? removeSelectedRoadStretch
    : addSelectedRoadStretch;
  const buttonLabel = isStretchSelected ? "Added" : "Add";

  return (
    <div className="navbar px-0 border-top py-2">
      <span className="mt-1">{name}</span>
      <button
        className={`btn ml-2 ${buttonClassName} add-button shadow-none`}
        onClick={() => dispatch(dispatchFunction(id))}
      >
        {buttonIconClassName && <i className={buttonIconClassName}></i>}
        {buttonLabel}
      </button>
    </div>
  );
}

// Stretch Listing Section
function StretchesListingSection({
  roadStretchList,
  listingViewLabels = {},
  cityId,
}) {
  const dispatch = useDispatch();

  // Labels
  const { body, search } = listingViewLabels;

  // State
  const [roadStretches, setRoadStretches] = useState([]);

  // Selector State
  const stretchLoading = useSelector(
    (state) => state.orgRoadStretch.orgRoadStretchesLoading
  );
  const roadStretchListCityId = useSelector(
    (state) => state.orgRoadStretch.cityId
  );

  const selectedStretchIds = useSelector((state) =>
    Object.keys(state.roadStretchesSelection.selectedStretchesMapView)
  );

  // Param Constants
  const pageNo = defaultPagination.pageNumber;
  const pageSize = defaultPagination.pageSize;

  // Dispatch
  useEffect(() => {
    if (roadStretchList.length === 0 || roadStretchListCityId !== cityId) {
      dispatch(getRoadStretchesOfOrg(true, cityId, "", pageNo, pageSize));
    }
  }, [dispatch, cityId]);

  if (stretchLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {/* Search Input */}
      <div className="flex-grow-1">
        <SearchInputLocal
          placeholder={search.placeholder}
          dataList={roadStretchList}
          searchFields={search.fields}
          onSearchComplete={setRoadStretches}
        />
        <hr className="my-2" />
      </div>

      {/* if road-stretches not exist for city */}
      {roadStretchList.length === 0 && (
        <div className="text-center mt-3">
          <span className="font-italic">{body.emptyText}</span>
        </div>
      )}

      {/* if road-stretches exist for city */}
      {roadStretches.length > 0 &&
        roadStretches.map((stretchInfo) => (
          <StretchListing
            key={stretchInfo.id}
            stretchInfo={stretchInfo}
            isStretchSelected={selectedStretchIds.includes(stretchInfo.id)}
          />
        ))}
    </>
  );
}

export default StretchesListingSection;
