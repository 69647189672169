import { ButtonWithLoader } from "../../mavin/components/button/Button";

function SelectedFilesCount({ noOfFileSelected }) {
  if (!noOfFileSelected) {
    return <span className="text-muted mr-2">No File selected</span>;
  }

  const text =
    noOfFileSelected > 1
      ? `${noOfFileSelected} files selected`
      : `${noOfFileSelected} file selected`;

  return <span className="mr-2 text-success">{text}</span>;
}

function SelectButton({ buttonLabel, onClick, noOfFileSelected }) {
  return (
    <div className="d-flex align-items-center justify-content-end">
      <SelectedFilesCount noOfFileSelected={noOfFileSelected} />
      <ButtonWithLoader
        displayContent={buttonLabel}
        className={`btn-action rounded-0 float-right`}
        onClickFunction={onClick}
      />
    </div>
  );
}

function InventoryUploadTemplateCard({
  uploadSiteListData = {},
  uploadTemplateFunction,
  uploadImageFunction,
  selectedFilesCount = {},
}) {
  const {
    step,
    title,
    message,
    imageUploadButtonLabel,
    excelUploadButtonLabel,
  } = uploadSiteListData;

  const { selectedImageCount, selectedFileCount } = selectedFilesCount;
  return (
    <div className="card shadow mb-4 mt-3">
      <div className="d-flex justify-content-between">
        <div className="card-content">
          <p className="card__step">{step}</p>
          <p className="card__title">{title}</p>
          <p className="card__message">
            {message}
            <a className="text-primary"> Learn more</a>
          </p>
        </div>
        <div>
          <SelectButton
            buttonLabel={imageUploadButtonLabel}
            onClick={uploadImageFunction}
            noOfFileSelected={selectedImageCount}
          />
          <br />
          <SelectButton
            buttonLabel={excelUploadButtonLabel}
            onClick={uploadTemplateFunction}
            noOfFileSelected={selectedFileCount}
          />
        </div>
      </div>
    </div>
  );
}

export default InventoryUploadTemplateCard;
