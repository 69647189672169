/**
 * This function renders a  bar component with customizable class names and percentage.
 * @param {string} className - An optional class name for the progress bar. Default is an empty string.
 * @param {number} percentage - The percentage value of the bar. Default is 0.
 */
export function ProgressBar({ className = "", percentage = 0 }) {
  const widthStyle = isNaN(percentage) ? "0%" : `${percentage}%`;

  return (
    <div className="progress mb-0">
      <div
        className={`progress-bar ${className}`}
        style={{ width: widthStyle }}
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  );
}
