import { DivIcon } from "leaflet";

import "./MapIcon.scss";

/**
 * Icon Configuration
 *
 * @param {String} type POI type
 * @param {String} bgShape Shape of the Icon - ex. square, circle
 * @param {String} bgColor Background colour of the Icon.
 * @param {String} iconCls Any custom classes to be added to the Icon.
 */
function IconConfig(type, bgShape, bgColor, iconCls) {
  // Sanity checks
  if (!type) {
    throw new Error("IconConfig :: Type is invalid!");
  }

  this.type = type;
  this.bgShape = bgShape || "square-smooth";
  this.bgColor = bgColor || "blue";
  this.iconCls = iconCls || "map-icon-" + type;
}

/**
 * Default (pre-defined) Icon configurations.
 */
const IconConfigs = {
  pin: new IconConfig("pin", "pin", "blue"),

  // Food and Travels
  travel: new IconConfig("travel", "pin", "blue"),
  restaurants: new IconConfig("restaurants", "pin", "yellow"),

  // Sports
  sports: new IconConfig("sports", "square", "blue"),

  // Electronics
  "electronic-stores": new IconConfig(
    "electronic-stores",
    "square-smooth",
    "green"
  ),
  "mobiles-electronics": new IconConfig(
    "mobiles-electronics",
    "square-smooth",
    "green"
  ),

  // Clothings
  innerwears: new IconConfig("innerwears", "square", "gray"),
  "clothes-shoes-accessories": new IconConfig(
    "clothes-shoes-accessories",
    "square",
    "blue"
  ),

  // Markets
  supermarkets: new IconConfig("supermarkets", "square", "orange"),
  "wholesale-grocery-markets": new IconConfig(
    "wholesale-grocery-markets",
    "square",
    "yellow"
  ),
  "wholesale-flea-markets": new IconConfig(
    "wholesale-flea-markets",
    "pin",
    "pink"
  ),
  "fashion-stores-departmental-stores": new IconConfig(
    "fashion-stores-departmental-stores",
    "circle",
    "red"
  ),

  // Banks and Atms
  "banks-atms": new IconConfig("banks-atms", "pin", "green"),

  // Finance
  "finance-companies": new IconConfig("finance-companies", "pin", "purple"),

  // Cinema
  "books-cinema": new IconConfig("books-cinema", "square", "red"),

  // Luxury and Beauty and Toys
  jewellery: new IconConfig("jewellery", "pin", "blue"),
  "beauty-stores": new IconConfig("beauty-stores", "pin", "blue"),
  "luxury-brands": new IconConfig("luxury-brands", "pin", "teal"),
  "perfume-beauty": new IconConfig("perfume-beauty", "pin", "blue"),
  "toys-babies": new IconConfig("toys-babies", "pin", "blue"),
  "home-kitchen": new IconConfig("home-kitchen", "pin", "blue"),

  // Hardware and Spares
  "hardware-stores": new IconConfig("hardware-stores", "pin", "pink"),
  "cars-motorcycles-spares": new IconConfig(
    "cars-motorcycles-spares",
    "pin",
    "yellow"
  ),

  // Malls and Shopping-Centres
  malls: new IconConfig("malls", "pin", "gray"),
  "shopping-mall": new IconConfig("shopping-mall", "pin", "red"),
  "shopping-centres": new IconConfig("shopping-centres", "pin", "pink"),
  "shopping-complex": new IconConfig("shopping-complex", "pin", "lime"),

  // Educational Institutes
  schools: new IconConfig("schools", "pin", "olive"),
  colleges: new IconConfig("colleges", "pin", "red"),
  universities: new IconConfig("universities", "pin", "yellow"),

  // Corporate Offices
  "coworking-space": new IconConfig("coworking-space", "pin", "teal"),
  "corporate-companies": new IconConfig(
    "corporate-companies",
    "pin",
    "crimson"
  ),

  // Others
  "selected-poi": new IconConfig("selected-poi", "pin", "red"),
  "un-selected-poi": new IconConfig("un-selected-poi", "pin", "yellow"),
  "selected-media": new IconConfig("selected-media", "circle", "orange"),
  "un-selected-media": new IconConfig("un-selected-media", "circle", "blue"),
};

/**
 * Custom Leaflet Map Icon
 */
function MapIcon(props) {
  const type = props.type;

  const cleanTypeStr = type.replace(new RegExp("[^a-zA-Z0-9]", "g"), "-");
  const typeStr = cleanTypeStr
    .replace(new RegExp("-+", "g"), "-")
    .toLowerCase();

  const config = IconConfigs[typeStr] || IconConfigs["pin"];
  const clsStr =
    "map-icon fa " +
    config.bgShape +
    " " +
    config.bgColor +
    " " +
    config.iconCls;

  const icon = new DivIcon({
    className: clsStr,
  });

  return icon;
}

export default MapIcon;
