import { ActionTypes } from "../../constants/ActionConstants";

const initialState = {
  // Media Type
  mediaTypesCountMap: {},
  mediaTypeSelection: {},

  // RoadStretch Type
  roadStretchIdsMap: {},
  roadStretchSelection: {},

  // Pois
  poiSelectionMap: {},
  poiToBrandData: {},
  poiToBrandDataLoading: false,
  poiToBrandDataErr: "",

  // TargetGroups

  targetGroups: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Select/unselect Media Types
    //--------------------------------------------------------------------------------
    case ActionTypes.MapView.SELECT_MEDIA_TYPE:
      return {
        ...state,
        mediaTypeSelection: {
          ...state.mediaTypeSelection,
          [action.payload.mediaType]: true,
        },
      };

    case ActionTypes.MapView.UNSELECT_MEDIA_TYPE: {
      return {
        ...state,
        mediaTypeSelection: {
          ...state.mediaTypeSelection,
          [action.payload.mediaType]: false,
        },
      };
    }

    case ActionTypes.MapView.SET_MEDIA_TYPES: {
      const { mediaTypesCountMap } = action.payload;

      const mediaTypeSelection = Object.keys(mediaTypesCountMap).reduce(
        (acc, eachMediaType) => {
          acc[eachMediaType] = true;
          return acc;
        },
        {}
      );

      return {
        ...state,
        mediaTypesCountMap,
        mediaTypeSelection,
      };
    }

    // Select/unselect RoadStretch Type (selected, inventory)
    //--------------------------------------------------------------------------------
    case ActionTypes.MapView.SET_ROAD_STRETCHES: {
      const { selectedStretchIds, unSelectedStretchIds } = action.payload;
      const roadStretchSelection = {
        Inventory: unSelectedStretchIds.length === 0 ? false : true,
        Selected: selectedStretchIds.length === 0 ? false : true,
      };
      const roadStretchIdsMap = {
        Inventory: unSelectedStretchIds,
        Selected: selectedStretchIds,
      };

      return {
        ...state,
        roadStretchIdsMap: roadStretchIdsMap,
        roadStretchSelection: roadStretchSelection,
      };
    }

    case ActionTypes.MapView.SELECT_ROAD_STRETCH_TYPE:
      return {
        ...state,
        roadStretchSelection: {
          ...state.roadStretchSelection,
          [action.payload.stretchType]: true,
        },
      };

    case ActionTypes.MapView.UNSELECT_ROAD_STRETCH_TYPE: {
      return {
        ...state,
        roadStretchSelection: {
          ...state.roadStretchSelection,
          [action.payload.stretchType]: false,
        },
      };
    }

    // Select/unselect Pois
    //--------------------------------------------------------------------------------
    case ActionTypes.MapView.SET_POI_AND_BRANDS:
      return {
        ...state,
        poiToBrandDataLoading: true,
      };

    case ActionTypes.MapView.SET_POI_AND_BRANDS_SUCCESS: {
      const poiSelectionMap = Object.keys(
        action.payload.poiIdToBrandData
      ).reduce((acc, eachPoiId) => {
        acc[eachPoiId] = true;
        return acc;
      }, {});
      return {
        ...state,
        poiToBrandDataLoading: false,
        poiToBrandData: action.payload.poiIdToBrandData,
        poiSelectionMap: poiSelectionMap,
      };
    }

    case ActionTypes.MapView.SET_POI_AND_BRANDS_FAILURE:
      return {
        ...state,
        poiToBrandDataLoading: false,
        poiToBrandDataErr: action.payload,
      };

    case ActionTypes.MapView.SELECT_POI: {
      return {
        ...state,
        poiSelectionMap: {
          ...state.poiSelectionMap,
          [action.payload.poi]: true,
        },
      };
    }
    case ActionTypes.MapView.UNSELECT_POI: {
      return {
        ...state,
        poiSelectionMap: {
          ...state.poiSelectionMap,
          [action.payload.poi]: false,
        },
      };
    }

    // Select/unselect Target Group
    //--------------------------------------------------------------------------------
    case ActionTypes.MapView.SET_TG:
      return {
        ...state,
        targetGroups: { [action.payload.tgId]: true },
      };

    case ActionTypes.MapView.SELECT_TG:
      return {
        ...state,
        targetGroups: { ...state.targetGroups, [action.payload.tgId]: true },
      };

    case ActionTypes.MapView.UNSELECT_TG: {
      return {
        ...state,
        targetGroups: { ...state.targetGroups, [action.payload.tgId]: false },
      };
    }
    default:
      return state;
  }
};
