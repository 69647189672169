import { useSelector } from "react-redux";

// Constants and Utils
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";
import {
  BoundarySphericalAreaFactor,
  Status,
} from "../../../constants/GeneralConstants";

// Components
import DataShades from "../../../utils/DataShades";

// Pincode/Ward Boundary Related Methods
// ----------------------------------------------------------------------------

/**
 * Temp Labels for consuming V2 / KYP data.
 */
const _propertyLabelsMap = {
  "": "",
  "Population Density": "population",
  "Restaurant (cost of two)": "restaurants",
  "Real Estate (per sq.ft cost)": "realtySale",
};

export function _getPropValueOfGeoJsonFeature(ft, propLabel) {
  // Get the corresponding property key for the Property Label
  const property = _propertyLabelsMap[propLabel] || "";

  // Feature Properties
  const ftProps = ft.properties || {};
  const propVal = ftProps[property] ? Math.ceil(ftProps[property].mean) : 0;
  return propVal;
}

function _getPropValuesOfGeoJsonFeatures(features, propLabel) {
  // iterate and get values
  const values = features.map((ft) => {
    return _getPropValueOfGeoJsonFeature(ft, propLabel);
  });

  return values;
}

/**
 *  Boundary Markers Data
 */
export function getBoundaryMarkersData(
  polygonData,
  selected,
  boundaryMapSettings = {}
) {
  const defaultResult = {
    polygonFeatures: [],
    dataShades: {},
    _prop: "",
    boundaryLegendData: [],
  };

  // Boundary Settings
  const { maxColor, noOfSteps } = boundaryMapSettings;
  const colorConfig = {
    gradient: true,
    noOfColors: noOfSteps,
    startColor: maxColor,
  };

  // Parse Selection
  const selection = selected.split("_");
  const _type = selection[0];
  const _prop = selection.length > 1 ? selection[1] : "";

  if (Object.keys(polygonData).length < 1) {
    return defaultResult;
  }

  // Check if NONE is selected
  if (!_type || _type === "" || _type === "None" || _type === "none") {
    return defaultResult;
  }

  // Get data  based on the selection
  let polygonGeoJSON = [];
  if (_type === "Pincode" && polygonData.pincode) {
    polygonGeoJSON = polygonData.pincode;
  } else if (_type === "Ward" && polygonData.ward) {
    polygonGeoJSON = polygonData.ward;
  } else {
    return defaultResult;
  }

  const polygonFeatures = polygonGeoJSON.features || [];
  const propValsRange = _getPropValuesOfGeoJsonFeatures(polygonFeatures, _prop);
  const dataShades = new DataShades(propValsRange, colorConfig);
  const boundaryLegendData = dataShades.getLegend();

  const boundaryMarkersData = {
    polygonFeatures,
    dataShades,
    _prop,
    boundaryLegendData,
  };

  // Final Returned Data
  return boundaryMarkersData;
}

// getting selected boundary label and Id
export function getSelectedBoundaryLabelAndId(dataList) {
  // default value
  const selectedBoundary = {
    parentLabel: "",
    childLabel: "",
    id: "",
  };

  dataList.forEach((data) => {
    if (data.status === Status.CHECKED) {
      // setting "parentLabel"
      selectedBoundary["parentLabel"] = data.label;

      data.children.forEach((child) => {
        if (child.status === Status.CHECKED) {
          // setting "childLabel" and "id"
          selectedBoundary["childLabel"] = child.label;
          selectedBoundary["id"] = child.id;
          return;
        }
      });
      return;
    }
  });

  return selectedBoundary;
}

// MapView Info box Component (Ward and Pincode)
export function MapInfoBox({ hoveredBoundaryInfo }) {
  // Boundaries data list
  const dataList = useSelector((state) => state.boundaries.dataList);

  // if No "hoveredBoundaryInfo" then not returning InfoBox
  if (Object.keys(hoveredBoundaryInfo).length === 0) {
    return null;
  }

  // info data
  const { name = "", alias, area, population = {} } = hoveredBoundaryInfo;

  // Getting Selected Boundary Data
  const selectedBoundary = getSelectedBoundaryLabelAndId(dataList);
  const parentLabel = selectedBoundary.parentLabel;

  const boundaryPincode = parentLabel === "Pincode" ? name : "";
  const boundaryName = parentLabel === "Pincode" ? alias : name;
  // dividing with sphericalArea
  const boundaryArea = area
    ? Math.round(area / BoundarySphericalAreaFactor)
    : "--";
  const boundaryPopulation = population.mean
    ? toLocaleString(population.mean)
    : "--";

  return (
    <div className="map-info-box p-3 rounded-lg bg-white border">
      <h3 className="font-weight-bold text-truncate" title={boundaryPincode}>
        {boundaryPincode}
      </h3>
      <h4 className="text-truncate" title={boundaryName}>
        {boundaryName}
      </h4>
      {/* km&#178; ==> Kilometer square */}
      <p className="mb-0">Area : {boundaryArea} km&#178;</p>
      <span className="text-truncate" title={boundaryPopulation}>
        Population : {boundaryPopulation} (per sqkm)
      </span>
    </div>
  );
}
