import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import {
  addOrgMediaImages,
  getOrgMediaInfo,
} from "../../actions/org/OrgMediaActions";

// Constants and Utils
import { selectFile } from "../../common-utils/file-utils/FileUtils";
import { downloadPdf } from "../../common-utils/file-utils/PdfUtil";
import {
  FilenameExtensions,
  ImageFileTypes,
  ReportConstants,
} from "../../constants/GeneralConstants";

// Components
import Spinner from "../../components/spinner/Spinner";
import MediaInfo from "../../mavin/components/media-info/MediaInfo";
import PageHeader from "../../mavin/components/page-header/PageHeader";
import MediaSitePerformance from "../../mavin/components/media-site-performance/MediaSitePerformance";
import LocationSummary from "../../mavin/components/location-summary/LocationSummary";
import HourlyTrafficDataSection from "../../mavin/components/hourly-traffic-data/HourlyTrafficDataSection";
import MediaImageWithMap from "../../mavin/components/media-image-with-map/MediaImageWithMap";

// Page Components
function DownloadReport({ mediaTitle }) {
  //State
  const [downloadingReport, setDownloadingReport] = useState(false);

  // file name
  const pdfFileName = mediaTitle
    ? `${mediaTitle}${FilenameExtensions.pdf}`
    : ReportConstants.MEDIA_PDF_REPORT;

  // functions
  async function printDocument() {
    setDownloadingReport(true);
    const input = document.getElementById("cont-org-media-details");
    await downloadPdf(input, pdfFileName);
    setDownloadingReport(false);
  }

  //Checks for downloading report
  if (downloadingReport) {
    return <Spinner />;
  }

  return (
    <p
      className="text-primary cursor-pointer my-2"
      onClick={printDocument}
      data-html2canvas-ignore="true"
    >
      Download Media PDF Report
    </p>
  );
}

/**
 * Main Page - Org Media Details Page
 */
function OrgMediaDetailPage() {
  const dispatch = useDispatch();
  const { mediaId, orgId } = useParams();

  // State
  const [radius, setRadius] = useState(1000);

  // Selector
  const media = useSelector((state) => state.orgMedia.orgMediaInfo);
  const orgMediaInfoLoading = useSelector(
    (state) => state.orgMedia.orgMediaInfoLoading
  );

  // Org Media Traffic Data
  const kypTrafficData = useSelector((state) => state.orgMedia.kypTrafficData);

  const orgMediaFileItems = useSelector(
    (state) => state.orgMedia.orgMediaFileItems
  );
  const addOrgMediaImagesLoading = useSelector(
    (state) => state.orgMedia.addOrgMediaImagesLoading
  );

  // Dispatch
  useEffect(() => {
    dispatch(getOrgMediaInfo(mediaId, orgId));
  }, [dispatch, mediaId]);

  // function
  async function addOrgMediaImageFn() {
    const files = await selectFile(true, ImageFileTypes);
    dispatch(addOrgMediaImages(orgId, mediaId, files));
  }

  // Page Loading
  if (orgMediaInfoLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  if (Object.keys(media).length === 0) {
    return null;
  }

  const { latitude, longitude, title } = media;

  const pageActions = <DownloadReport mediaTitle={title} />;

  return (
    <div className="content-wrapper media-page" id="cont-org-media-details">
      {/* Page Header */}
      <PageHeader title="Media Report" actions={pageActions} shadow={true} />

      <div className="page-content">
        {/* Media info  */}
        <MediaInfo media={media} titleClassName="h2" />
        <hr className="divider"></hr>

        {/* Site performance */}
        <MediaSitePerformance
          media={media}
          title="Site Performance"
          className="py-3"
        />
        <hr className="divider"></hr>

        {/* Media image and map view*/}
        <MediaImageWithMap
          media={media}
          fileItems={orgMediaFileItems}
          loading={orgMediaInfoLoading || addOrgMediaImagesLoading}
          onClick={addOrgMediaImageFn}
        />

        {/* Traffic Data */}
        <HourlyTrafficDataSection
          media={media}
          kypTrafficData={kypTrafficData}
        />

        {/* Org Media Location Summary */}
        {latitude && longitude && (
          <LocationSummary
            latitude={latitude}
            longitude={longitude}
            radius={radius}
            setRadius={setRadius}
          />
        )}
      </div>
    </div>
  );
}

export default OrgMediaDetailPage;
