export const RoadSegment = {
  // Actions Related to Road-segment Selection
  REMOVE_ROAD_SEGMENT: "REMOVE_ROAD_SEGMENT",
  SELECT_ROAD_SEGMENT: "SELECT_ROAD_SEGMENT",

  // Actions related to adding the closest roadSegment selected in the
  // Add poi/captive area form
  ADD_ROAD_SEGMENT: "ADD_ROAD_SEGMENT",
  REMOVE_CLOSEST_ROAD_SG: "REMOVE_CLOSEST_ROAD_SG",

  // Action to get media related to roadSegment
  GET_ROAD_SEGMENT_MEDIA: "GET_ROAD_SEGMENT_MEDIA",
  GET_ROAD_SEGMENT_MEDIA_SUCCESS: "GET_ROAD_SEGMENT_MEDIA_SUCCESS",
  GET_ROAD_SEGMENT_MEDIA_FAILURE: "GET_ROAD_SEGMENT_MEDIA_FAILURE",

  GET_PLANNING_ROAD_SEGMENTS: "GET_PLANNING_ROAD_SEGMENTS",
  GET_PLANNING_ROAD_SEGMENTS_SUCCESS: "GET_PLANNING_ROAD_SEGMENTS_SUCCESS",
  GET_PLANNING_ROAD_SEGMENTS_FAILURE: "GET_PLANNING_ROAD_SEGMENTS_FAILURE",

  GET_ROAD_SEGMENTS: "GET_ROAD_SEGMENTS",
  GET_ROAD_SEGMENTS_SUCCESS: "GET_ROAD_SEGMENTS_SUCCESS",
  GET_ROAD_SEGMENTS_FAILURE: "GET_ROAD_SEGMENTS_FAILURE",

  // Reset
  RESET_ROAD_SEGMENTS: "RESET_ROAD_SEGMENTS",
};
