import { all, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// API
import { getMapImage } from "../utils/api";

// Utils and constants..
import { getErrorMessage } from "../utils/util";
import { MapViewImage } from "../constants/action-constants/MapViewImageActionConstants";

export function* getMapViewImage(action) {
  try {
    const { campaignId, cityId } = action.payload;
    const mapState = yield getMapImage(campaignId, cityId);
    if (!mapState || !mapState.imageFileItem) {
      //dispatching action
      yield put({
        type: MapViewImage.GET_MAP_VIEW_IMAGE_SUCCESS,
        payload: { imageUrl: "NoMapImage" },
      });
      return;
    }

    //dispatching action
    yield put({
      type: MapViewImage.GET_MAP_VIEW_IMAGE_SUCCESS,
      payload: { imageUrl: mapState.imageFileItem.originalUrl },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: MapViewImage.GET_MAP_VIEW_IMAGE_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(MapViewImage.GET_MAP_VIEW_IMAGE, getMapViewImage)]);
}
