import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import { updateSellerLogo } from "../../actions/seller/SellerActions";

// Constants and Utils
import { selectFile } from "../../common-utils/file-utils/FileUtils";
import { ImageFileTypes } from "../../constants/GeneralConstants";

// Components
import { ButtonWithLoader } from "../../mavin/components/button/Button";
import Spinner from "../../components/spinner/Spinner";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// CSS
import "./Seller.scss";

// Components
function SellerWebsiteMediaImage({ mediaImageUrl, title }) {
  if (!mediaImageUrl) {
    return (
      <i className="fa fa-images fa-10x opacity-25 d-flex align-items-center justify-content-center text-primary card__media-img"></i>
    );
  }
  return (
    <img src={mediaImageUrl} className="p-2 card__media-img" alt={title} />
  );
}

// Media Card
export function SellerWebsiteMediaCard({ media = {}, isCardDisabled = false }) {
  const {
    width,
    height,
    title,
    litStatus,
    region,
    cityName,
    type,
    fileItems = [],
    id: mediaId,
    sellerId,
  } = media;

  // image Url
  const mediaImageUrl = fileItems[0]?.previewUrl;

  // Seller Media Detail Page Url
  const sellerMediaDetailPageUrl = RedirectTo.sellerMediaDetailPageUrl
    .replace(":mediaId", mediaId)
    .replace(":sellerId", sellerId);

  // className to disable "Seller-media-Card"
  const isDisabled = isCardDisabled ? "disabled" : "";

  return (
    <Link
      to={sellerMediaDetailPageUrl}
      className={`col-4 my-4 text-decoration-none seller-website-media-card ${isDisabled}`}
      target="_blank"
    >
      <div className="card shadow card__media">
        {/* Media Image */}
        <SellerWebsiteMediaImage mediaImageUrl={mediaImageUrl} title={title} />

        {/* Media Details */}
        <div className="card-body p-3">
          <h4 className="card-title text-truncate-2" title={title}>
            {title}
          </h4>
          <p className="mb-2 text-dark">{`${type} | ${height} ft x ${width} ft`}</p>
          <p className="mb-2 text-dark">{litStatus}</p>
          <p className="mb-2 text-dark">{`${region}, ${cityName}`}</p>
        </div>
      </div>
    </Link>
  );
}

export function CompanyLogo({ logoURL, isPreviewPage }) {
  const dispatch = useDispatch();

  // Logo Loading
  const sellerLogoLoading = useSelector(
    (state) => state.seller.updateSellerLogoLoading
  );

  // functions
  // this function only called on SellerWebsiteSetupPage (that means "isPreviewPage=false")
  async function updateSellerLogoFn() {
    if (!isPreviewPage) {
      const fileArray = await selectFile(false, ImageFileTypes);
      dispatch(updateSellerLogo(fileArray[0]));
    }
  }

  if (sellerLogoLoading) {
    return (
      <div className="border img-thumbnail-md d-flex justify-content-around align-items-center">
        <Spinner />
      </div>
    );
  }

  if (!logoURL) {
    return (
      <div className="border p-3" onClick={updateSellerLogoFn}>
        <i className="fas fa-yin-yang fa-10x opacity-25"></i>
      </div>
    );
  }

  return (
    <div className="card">
      <img
        className="img-thumbnail-lg p-1 bg-white p-3"
        src={logoURL}
        alt={"logo"}
        onClick={updateSellerLogoFn}
      />
    </div>
  );
}

export function ProfileInfoSection({
  sellerInfo = {},
  isPreviewPage,
  onClick,
  loader = false,
}) {
  const {
    name,
    description,
    website,
    mainAddress = {},
    email,
    logoURL,
  } = sellerInfo;

  const { line1, line2, city, state, zipcode, contactNo } = mainAddress;

  // Redirect Url
  const sellerWebsiteSetupPageUrl = RedirectTo.sellerWebsiteSetupPageUrl;

  // Edit button content
  const editBtnContent = (
    <span>
      {"Edit"}
      <i className="fa fa-pen ml-2"></i>
    </span>
  );

  return (
    <div className="row py-5">
      {/* Seller Logo Logo */}
      <div className="col-3 text-center">
        <CompanyLogo logoURL={logoURL} isPreviewPage={isPreviewPage} />
      </div>

      {/* Profile info */}
      <div className="col-6 d-flex align-items-center">
        <div>
          <h3 className="font-weight-bold">
            {name ? name : "Display_name_of_the_company"}
          </h3>
          <p className="mb-3">
            {description ? description : "Some_description_about_the_company"}
          </p>
          <div className="">
            <p className="mb-0">
              {line1 && <span className="d-block">{line1},</span>}
              {line2 && <span className="d-block">{line2},</span>}
              <span>
                {city}, {state} - {zipcode}
              </span>
            </p>
            <p className="mb-1 mt-0">
              {contactNo ? `Ph. No.: ${contactNo}` : ""}
              {/* TODO::Backend support needed for Email */}
              {email ? ` | Email: ${email}` : ""}
            </p>
            <p className="mb-1">
              {website ? website : "https://mywebsite.com"}
            </p>
          </div>
        </div>
      </div>

      <div className="col-3">
        {!isPreviewPage ? (
          <ButtonWithLoader
            className="float-right"
            onClickFunction={onClick}
            displayContent={editBtnContent}
            loader={loader}
            isDisabled={loader}
          />
        ) : (
          <Link
            className="btn btn-outline-primary float-right rounded-lg"
            to={sellerWebsiteSetupPageUrl}
          >
            {"Continue Editing"}
          </Link>
        )}
      </div>
    </div>
  );
}
