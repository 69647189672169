export const ReachAndFrequency = {
  // Reach and Frequency Tool
  GET_REACH_AND_FREQUENCY_SUMMARY: "GET_REACH_AND_FREQUENCY_SUMMARY",
  GET_REACH_AND_FREQUENCY_SUMMARY_SUCCESS:
    "GET_REACH_AND_FREQUENCY_SUMMARY_SUCCESS",
  GET_REACH_AND_FREQUENCY_SUMMARY_FAILURE:
    "GET_REACH_AND_FREQUENCY_SUMMARY_FAILURE",

  // Reset data
  RESET_REACH_AND_FREQUENCY_SUMMARY: "RESET_REACH_AND_FREQUENCY_SUMMARY",
};
