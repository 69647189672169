import { useSelector } from "react-redux";

// Utils and Constants
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import { TgDetailsRow } from "./TgDetailsRow";
import CardCollapsible from "../../../components/card-collapsible/CardCollapsible";

function getTgPerformanceTableHeaders(submissionImpact) {
  let tgPerformanceHeades = [
    {
      title: {
        displayName: "Target Group",
      },
    },
    {
      title: {
        displayName: "CPM (Rs)",
      },
    },
    {
      title: {
        displayName: "Imp",
      },
      subTitle: {
        displayName: "Planned",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "Cost",
      },
      subTitle: {
        displayName: "Planned",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "Est Imp",
      },
      subTitle: {
        displayName: "CPM",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "Est Cost",
      },
      subTitle: {
        displayName: "CPM",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "Est Imp",
      },
      subTitle: {
        displayName: "Fixed",
        className: "sub-text",
      },
    },
    {
      title: {
        displayName: "Est Cost",
      },
      subTitle: {
        displayName: "Fixed",
        className: "sub-text",
      },
    },
  ];

  const submissionImpactHeaders = [
    {
      title: {
        displayName: "Submission Impact",
      },
      subTitle: {
        displayName: "On Impressions",
        className: "sub-text",
      },
    },

    {
      title: {
        displayName: "Submission Impact",
      },
      subTitle: {
        displayName: "On Cost",
        className: "sub-text",
      },
    },
  ];

  if (submissionImpact) {
    tgPerformanceHeades = [...tgPerformanceHeades, ...submissionImpactHeaders];
  }

  return tgPerformanceHeades;
}

function getCpmEstOtsAndCost(cpmMedia, genCpm, specificCpm, plannedDuration) {
  const estOts = Object.keys(cpmMedia).reduce(
    (acc, eachMediaId) => {
      const { otsSplit, duration } = cpmMedia[eachMediaId];
      const { genericOts, genericOtsLit, targetOts, targetOtsLit } = otsSplit;

      acc["estOts_gen"] =
        acc["estOts_gen"] +
        (genericOtsLit ? duration * genericOtsLit : duration * genericOts) /
          plannedDuration;

      if (targetOtsLit || targetOts) {
        acc["estOts_specific"] =
          acc["estOts_specific"] +
          (targetOtsLit ? duration * targetOtsLit : duration * targetOts) /
            plannedDuration;
      }
      return acc;
    },
    { estOts_gen: 0, estOts_specific: 0 }
  );

  return {
    estOts_gen: estOts["estOts_gen"],
    estCost_gen: toStringWithRoundUp(estOts["estOts_gen"] * genCpm),
    estOts_specific: estOts["estOts_specific"],
    estCost_specific: toStringWithRoundUp(
      estOts["estOts_specific"] * specificCpm
    ),
  };
}

function getFixedEstOtsAndCost(fixedMedia, plannedDuration) {
  return Object.keys(fixedMedia).reduce(
    (acc, eachMediaId) => {
      const { otsSplit, cost, duration } = fixedMedia[eachMediaId];
      const { genericOts, genericOtsLit, targetOts, targetOtsLit } = otsSplit;
      acc["estOts_gen"] =
        acc["estOts_gen"] +
        (genericOtsLit ? duration * genericOtsLit : duration * genericOts) /
          plannedDuration;

      if (targetOtsLit || targetOts) {
        acc["estOts_specific"] =
          acc["estOts_specific"] +
          (targetOtsLit ? duration * targetOtsLit : duration * targetOts) /
            plannedDuration;
        acc["estCost_gen"] = (acc["estCost_gen"] + cost / 2) * plannedDuration;
        acc["estCost_specific"] =
          (acc["estCost_specific"] + cost / 2) * plannedDuration;
        return acc;
      }

      acc["estCost_gen"] = acc["estCost_gen"] + cost;
      return acc;
    },
    { estOts_gen: 0, estCost_gen: 0, estOts_specific: 0, estCost_specific: 0 }
  );
}

function constructEstOtsAndCostDetails(cpmEstDetails, fixedEstDetails) {
  const estOtsAndCostGen = {
    estOtsCpm: cpmEstDetails["estOts_gen"],
    estCostCpm: cpmEstDetails["estCost_gen"],
    estOtsFixed: fixedEstDetails["estOts_gen"],
    estCostFixed: fixedEstDetails["estCost_gen"],
  };

  const estOtsAndCostpecific = {
    estOtsCpm: cpmEstDetails["estOts_specific"],
    estCostCpm: cpmEstDetails["estCost_specific"],
    estOtsFixed: fixedEstDetails["estOts_specific"],
    estCostFixed: fixedEstDetails["estCost_specific"],
  };

  return { estOtsAndCostGen, estOtsAndCostpecific };
}

function TgDetailsTable({
  tgName,
  campaignPlan,
  campaign,
  submissionImpact = false,
}) {
  const fixedMedia = useSelector(
    (state) => state.submissionImpact.fixedPriceMedia
  );

  const cpmMedia = useSelector((state) => state.submissionImpact.cpmPriceMedia);

  if (Object.keys(campaignPlan).length < 1 || !campaign) {
    return null;
  }

  const {
    totalGenericOts,
    totalGenericOtsLit,
    totalTargetOts,
    totalTargetOtsLit,
    genericCPM,
    targetGroupCPM,
  } = campaignPlan;

  const plannedDuration = !campaign.days ? 1 : campaign.days;

  const tgPerformanceTableHeaders =
    getTgPerformanceTableHeaders(submissionImpact);

  // Planned Ots Details
  const otsGenPlanned =
    (totalGenericOtsLit ? totalGenericOtsLit : totalGenericOts) *
    plannedDuration;
  const otsSpePlanned =
    (totalTargetOtsLit ? totalTargetOtsLit : totalTargetOts) * plannedDuration;

  //  Planned Cost details
  const costGenPlanned = genericCPM
    ? toStringWithRoundUp(genericCPM * otsGenPlanned)
    : "NA";
  const costSpePlanned = targetGroupCPM
    ? toStringWithRoundUp(targetGroupCPM * otsSpePlanned)
    : "NA";

  // cpmEstDetails
  const cpmEstDetails = getCpmEstOtsAndCost(
    cpmMedia,
    genericCPM,
    targetGroupCPM,
    plannedDuration
  );

  // fixedEstDetails
  const fixedExtDetails = getFixedEstOtsAndCost(fixedMedia, plannedDuration);

  const { estOtsAndCostGen, estOtsAndCostpecific } =
    constructEstOtsAndCostDetails(cpmEstDetails, fixedExtDetails);

  const tgDetailsGeneric = {
    otsPlanned: otsGenPlanned,
    costPlanned: costGenPlanned,
    cpm: genericCPM,
    displayTest: "G-General",
    estOtsAndCost: estOtsAndCostGen,
    submissionImpact: submissionImpact,
  };

  const tgDetailsSpecific = {
    otsPlanned: otsSpePlanned,
    costPlanned: costSpePlanned,
    cpm: targetGroupCPM,
    displayTest: `TG-${tgName}`,
    estOtsAndCost: estOtsAndCostpecific,
    submissionImpact: submissionImpact,
  };

  return (
    <div className="table-responsive mt-4">
      <table className="table table-media">
        <TableHeaders
          tableHeaders={tgPerformanceTableHeaders}
          headerClass={"thead"}
        />
        <tbody>
          <TgDetailsRow tgDetails={tgDetailsGeneric} />
          {tgName && <TgDetailsRow tgDetails={tgDetailsSpecific} />}
        </tbody>
      </table>
    </div>
  );
}

export function EstimatedPriceBreakDown({
  tgName,
  campaignPlan,
  campaign,
  submissionImpact = false,
}) {
  const header = <h4>Estimated Price Breakdown</h4>;

  return (
    <CardCollapsible
      id="estimated-price-breakdown"
      className="mt-5"
      header={header}
    >
      <TgDetailsTable
        tgName={tgName}
        campaign={campaign}
        campaignPlan={campaignPlan}
        submissionImpact={submissionImpact}
      />
    </CardCollapsible>
  );
}
