// Utils & Constants
import {
  DATE_FORMATS,
  DurationConstants,
} from "../../constants/GeneralConstants";
import { calEstPrice } from "./PriceFactorUtil";

export function getOtsAndCostDetails(roadInfo, campaignPlan, duration) {
  const roadOts = campaignPlan.targetGroupId
    ? campaignPlan.roadStretchOtsMap[roadInfo.id]
    : roadInfo;

  if (!roadOts) {
    return { estTgSpecific: "", estGeneric: "", estTotal: "" };
  }
  const {
    genericOts,
    genericOtsLit,
    targetOts,
    targetOtsLit,
    otsAvg,
    otsLitAvg,
  } = roadOts;

  const estTotalWhenTgNotSelected =
    ((otsLitAvg ?? otsAvg) / DurationConstants.THIRTY_DAYS) * duration;
  const estTgSpecific = (targetOtsLit ?? targetOts) * duration;
  const estGeneric = (genericOtsLit ?? genericOts) * duration;
  const estTotal =
    estTgSpecific && estGeneric
      ? estTgSpecific + estGeneric
      : estTotalWhenTgNotSelected;

  return { estTgSpecific, estGeneric, estTotal };
}

// idToObjectMap ==> roadIdToStretchMap or segmentMap
function mapMediaToSegmentOrStretchIds(roadIdToMediaMap, idToRoadMap) {
  return Object.keys(idToRoadMap).reduce((acc, eachRoadId) => {
    // // Check if the media exists with the roadId
    if (!roadIdToMediaMap[eachRoadId]) {
      return acc;
    }

    const stretchId = idToRoadMap[eachRoadId].id;
    const roadMediaArray = acc[stretchId] || [];
    const newRoadMediaArray = roadMediaArray.concat(
      roadIdToMediaMap[eachRoadId]
    );

    acc[stretchId] = newRoadMediaArray;
    return acc;
  }, {});
}

function constructRoadIdToStretchMap(stretchMap) {
  if (Object.values(stretchMap).length === 0) {
    return {};
  }

  return Object.values(stretchMap).reduce((acc, eachStretch) => {
    const roadIds = eachStretch["roadSegmentIds"];
    const eachRoadIdToObjectMap = roadIds.reduce((idAcc, eachRoadId) => {
      idAcc[eachRoadId] = eachStretch;
      return idAcc;
    }, {});
    acc = { ...acc, ...eachRoadIdToObjectMap };
    return acc;
  }, {});
}

function constructRoadIdToMediaMap(mediaDetails) {
  if (mediaDetails.length === 0) {
    return {};
  }

  const roadIdToMediaMap = {};
  for (const eachMedia of mediaDetails) {
    const roadIds = eachMedia["roadIds"];

    for (const eachRoadId of roadIds) {
      const roadMediaArr = roadIdToMediaMap[eachRoadId] || [];

      // Add current media
      roadMediaArr.push(eachMedia);
      roadIdToMediaMap[eachRoadId] = roadMediaArr;
    }
  }
  return roadIdToMediaMap;
}

function includeRoadWithEmptyMedia(roadMap, roadIdToMediaListMap) {
  // Check for the roads with no Media
  const stretchIdToEmptyMediaListMap = Object.keys(roadMap).reduce(
    (acc, roadKey) => {
      if (roadIdToMediaListMap[roadKey]) {
        return acc;
      }
      acc[roadKey] = [];
      return acc;
    },
    {}
  );

  return {
    ...roadIdToMediaListMap,
    ...stretchIdToEmptyMediaListMap,
  };
}

export function mapMediaToSegmentsAndStretches(
  mediaDetails,
  segmentMap,
  stretchMap
) {
  let stretchIdToMediaListMap = {};
  let segmentIdToMediaListMap = {};

  if (Object.keys(segmentMap) == 0 && Object.keys(stretchMap).length == 0) {
    return { stretchIdToMediaListMap, segmentIdToMediaListMap };
  }
  // Construct roadIdToMediaMap
  const roadIdToMediaMap = constructRoadIdToMediaMap(mediaDetails);

  // Construct roadIdsToStretchMap
  const roadIdToStretchMap = constructRoadIdToStretchMap(stretchMap);

  // Map to store the Stretch Id to the MediaList
  stretchIdToMediaListMap = mapMediaToSegmentOrStretchIds(
    roadIdToMediaMap,
    roadIdToStretchMap
  );

  // include stretches with no Media
  stretchIdToMediaListMap = includeRoadWithEmptyMedia(
    stretchMap,
    stretchIdToMediaListMap
  );

  // Map to store the Segment Id to the MediaList
  segmentIdToMediaListMap = mapMediaToSegmentOrStretchIds(
    roadIdToMediaMap,
    segmentMap
  );

  // include Segments with no Media
  segmentIdToMediaListMap = includeRoadWithEmptyMedia(
    segmentMap,
    segmentIdToMediaListMap
  );

  return { stretchIdToMediaListMap, segmentIdToMediaListMap };
}

export function getTableHeaders(totalMediaCount, selectedMediaCount) {
  return [
    {
      title: {
        displayName: "Name",
        className: "col-2 align-top",
      },
    },
    {
      title: {
        displayName: "Sites Selected",
        className: "col-2 text-center",
      },
      subTitle: {
        displayName: `${selectedMediaCount} / ${totalMediaCount}`,
        className: "d-block text-primary",
      },
    },
    {
      title: {
        displayName: "Est Imp On Stretch",
        className: "col-2 text-left align-top",
      },
    },
    {
      title: {
        displayName: "Total Imp",
        className: "col-2 text-left align-top",
      },
      subTitle: {
        displayName: "(Est Imp * No.of Sites)",
      },
    },
    {
      title: {
        displayName: "Total Cost",
        className: "col-2 text-left align-top",
      },
    },
    {
      title: {
        displayName: "Selection Status",
        className: "col-1 text-center align-top",
      },
    },
  ];
}

/**
 *
 * @param {*} campaign
 * @returns
 * @deprecated
 */
export function constructDateString(campaign) {
  if (!campaign || !campaign.startDate || !campaign.endDate) {
    return "XXXX-XXXX";
  }

  return `${campaign.startDate.format(
    DATE_FORMATS.full_month_with_date_year
  )} - ${campaign.endDate.format(DATE_FORMATS.full_month_with_date_year)}`;
}

export function constructTotalCost(campaign, tgOts, genericOts) {
  if (!campaign || !campaign.tgCPM || !campaign.genericCPM) {
    return "XXXXX";
  }
  return tgOts * campaign.tgCPM + genericOts * campaign.genericCPM;
}

export function getStretchImpressions(stretchInfo) {
  // TODO: Backend is sending in-consistent keys in different API's
  // some API's as ots and otsLit, and in some API's otsAvg, otsLitAvg..
  const {
    ots,
    otsLit,
    otsAvg,
    otsLitAvg,
    genericOtsLit,
    genericOts,
    targetOts,
    targetOtsLit,
  } = stretchInfo;

  const estImpressions = otsLitAvg ?? otsLit ?? ots ?? otsAvg ?? 0;

  // for TG-specific-impressions
  const genericImpressions = genericOtsLit ?? genericOts ?? 0;
  const specificImpressions = targetOtsLit ?? targetOts ?? 0;

  return {
    genericImpressions: genericImpressions
      ? genericImpressions
      : estImpressions,
    specificImpressions,
  };
}

/**
 *
 * @param {*} roadStretchOtsMap  ==> this has the selected roadStretchInfo (Impressions)
 * @param {*} roadStretchMap  ==> this has the mop and occupancy info
 * @param {*} dayCount
 * @param {*} tgPriceFactor
 * @param {*} genPriceFactor
 * @returns
 */
export function constructTotalImpAndCost1(
  roadStretchOtsMap,
  roadStretchMap,
  dayCount,
  tgPriceFactor,
  genPriceFactor
) {
  if (Object.keys(roadStretchMap).length < 1) {
    return {
      genericImpressions: 0,
      specificImpressions: 0,
      specificCost: 0,
      genericCost: 0,
    };
  }

  const totalOtsAndCost = Object.keys(roadStretchOtsMap).reduce(
    (acc, eachStretchId) => {
      const eachStretchOts = roadStretchOtsMap[eachStretchId];
      const { genericImpressions, specificImpressions } =
        getStretchImpressions(eachStretchOts);

      // Total Impressions..
      if (genericImpressions) {
        acc.genericImpressions = genericImpressions + acc.genericImpressions;
      }
      if (specificImpressions) {
        acc.specificImpressions = acc.specificImpressions + specificImpressions;
      }

      // Calculation of EstPrice
      const { minOperatingPriceAvg = "", occupancyAvg = "" } =
        roadStretchMap[eachStretchId];
      if (minOperatingPriceAvg && occupancyAvg) {
        const estimatePriceBean = {
          genericImpressions,
          specificImpressions,
          mop: minOperatingPriceAvg,
          occupancy: occupancyAvg,
          dayCount,
          tgPriceFactor,
          genPriceFactor,
        };
        const { genericCost, specificCost } = calEstPrice(estimatePriceBean);
        acc.genericCost = acc.genericCost + genericCost;
        acc.specificCost = specificCost
          ? acc.specificCost + specificCost
          : acc.specificCost;
      }

      return acc;
    },
    {
      genericImpressions: 0,
      specificImpressions: 0,
      specificCost: 0,
      genericCost: 0,
    }
  );
  totalOtsAndCost.genericImpressions =
    totalOtsAndCost.genericImpressions * dayCount;

  totalOtsAndCost.specificImpressions =
    totalOtsAndCost.specificImpressions * dayCount;

  return totalOtsAndCost;
}

export function constructTotalImpAndCost(
  selectedRoadStretches,
  roadStretchOtsMap,
  dayCount,
  tgPriceFactor,
  genPriceFactor
) {
  if (Object.keys(selectedRoadStretches).length < 1) {
    return {
      genericImpressions: 0,
      specificImpressions: 0,
      specificCost: 0,
      genericCost: 0,
    };
  }

  const totalOtsAndCost = Object.keys(selectedRoadStretches).reduce(
    (acc, eachStretchId) => {
      const eachStretchOts = roadStretchOtsMap[eachStretchId];
      const { genericImpressions, specificImpressions } =
        getStretchImpressions(eachStretchOts);

      // Total Impressions..
      if (genericImpressions) {
        acc.genericImpressions = genericImpressions + acc.genericImpressions;
      }
      if (specificImpressions) {
        acc.specificImpressions = acc.specificImpressions + specificImpressions;
      }

      // Calculation of EstPrice
      const { minOperatingPriceAvg = "", occupancyAvg = "" } =
        selectedRoadStretches[eachStretchId];
      if (minOperatingPriceAvg && occupancyAvg) {
        const estimatePriceBean = {
          genericImpressions,
          specificImpressions,
          mop: minOperatingPriceAvg,
          occupancy: occupancyAvg,
          dayCount,
          tgPriceFactor,
          genPriceFactor,
        };
        const { genericCost, specificCost } = calEstPrice(estimatePriceBean);
        acc.genericCost = acc.genericCost + genericCost;
        acc.specificCost = specificCost
          ? acc.specificCost + specificCost
          : acc.specificCost;
      }

      return acc;
    },
    {
      genericImpressions: 0,
      specificImpressions: 0,
      specificCost: 0,
      genericCost: 0,
    }
  );
  totalOtsAndCost.genericImpressions =
    totalOtsAndCost.genericImpressions * dayCount;

  totalOtsAndCost.specificImpressions =
    totalOtsAndCost.specificImpressions * dayCount;

  return totalOtsAndCost;
}
