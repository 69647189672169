import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// Action
import { removeSelectedSec } from "../../../actions/map-view/SecActions";

// Component
import { OutlineButtonWithLoader } from "../../../mavin/components/button/Button";

function SelectedSecList({ eachSec }) {
  const dispatch = useDispatch();

  const secLabel = eachSec.split("_")[1];

  return (
    <div className="navbar px-0 py-2 border-bottom">
      <label className="form-check-label ml-2">{secLabel}</label>

      <OutlineButtonWithLoader
        displayContent={"Remove"}
        onClickFunction={() => dispatch(removeSelectedSec(eachSec))}
        className={`add-button `}
      />
    </div>
  );
}

function SelectedSecSection() {
  const selectedSecObj = useSelector((state) => state.sec.selectedSec);

  return (
    <>
      {Object.keys(selectedSecObj).map((eachSec) => (
        <SelectedSecList key={eachSec} eachSec={eachSec} />
      ))}
    </>
  );
}

export default SelectedSecSection;
