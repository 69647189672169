import Spinner from "../../../components/spinner/Spinner";

function constructClassNameString(isPrimary, className, customClassName) {
  if (customClassName) {
    return customClassName;
  }

  if (isPrimary) {
    return `btn btn-primary rounded-lg shadow-none px-3 ${className}`;
  }

  return `btn btn-outline-primary rounded-lg shadow-none px-3 ${className}`;
}

function Button({
  displayContent,
  onClickFunction,
  loader,
  isDisabled,
  className = "",
  buttonClassName = "",
  isPrimary = true,
  spinnerClassName = "text-light",
  ...rest
}) {
  const classNameString = constructClassNameString(
    isPrimary,
    className,
    buttonClassName
  );
  return (
    <button
      className={classNameString}
      onClick={onClickFunction}
      disabled={isDisabled}
      {...rest}
    >
      {displayContent}
      {loader && (
        <Spinner className={`spinner-border-sm ml-1 ${spinnerClassName}`} />
      )}
    </button>
  );
}

export function CustomButton({
  displayContent,
  onClickFunction,
  loader,
  isDisabled,
  buttonClassName,
  spinnerClassName,
  ...rest
}) {
  return (
    <Button
      displayContent={displayContent}
      onClickFunction={onClickFunction}
      loader={loader}
      isDisabled={isDisabled}
      buttonClassName={buttonClassName}
      spinnerClassName={spinnerClassName}
      {...rest}
    />
  );
}

export function ButtonWithLoader({
  displayContent,
  onClickFunction,
  loader,
  isDisabled,
  className = "",
  ...rest
}) {
  return (
    <Button
      displayContent={displayContent}
      onClickFunction={onClickFunction}
      loader={loader}
      isDisabled={isDisabled}
      className={className}
      {...rest}
    />
  );
}

export function OutlineButtonWithLoader({
  displayContent,
  onClickFunction,
  loader,
  isDisabled,
  className = "",
  spinnerClassName = "text-primary",
  ...rest
}) {
  return (
    <Button
      displayContent={displayContent}
      onClickFunction={onClickFunction}
      loader={loader}
      isDisabled={isDisabled}
      className={className}
      isPrimary={false}
      spinnerClassName={spinnerClassName}
      {...rest}
    />
  );
}
