import { useEffect, useState } from "react";
import { getItem, setItem } from "./localstorage";

// Constants
const LOCAL_STORAGE_KEY = "error_status_code";
const DISPATCH_EVENT_KEY = "store_error_code";

// To set the error code in local storage
export function setErrorStatusCode(err = {}) {
  const { status } = err.response;

  setItem(LOCAL_STORAGE_KEY, JSON.stringify({ errorStatusCode: status }));

  // dispatching event
  window.dispatchEvent(new Event(DISPATCH_EVENT_KEY));
}

// Hook
export function useGetErrorStatusCode() {
  // State to store error status
  const [statusCode, setErrorCode] = useState(null);

  // To get the error code in local storage
  function getErrorStatusCode() {
    const error = getItem(LOCAL_STORAGE_KEY);
    const { errorStatusCode } = JSON.parse(error);
    return errorStatusCode;
  }

  useEffect(() => {
    const onStorage = () => setErrorCode(getErrorStatusCode());

    // Event listener will get triggered whenever we store error code in local storage. event will dispatched in "setErrorStatusCode" fn
    window.addEventListener(DISPATCH_EVENT_KEY, onStorage);

    // to remove event listener
    return () => {
      window.removeEventListener(DISPATCH_EVENT_KEY, onStorage);
    };
  }, []);

  return statusCode;
}
