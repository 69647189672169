import React from "react";

function LitStatus({ onChange, litStatusStr, className = "" }) {
  function handleOnChange(value) {
    onChange("litStatusStr", value);
  }

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${className}`}
    >
      <input
        type="radio"
        id="createMedia__frontLit"
        onChange={() => handleOnChange("FRONT_LIT")}
        name="flexRadioDefault"
        checked={litStatusStr === "FRONT_LIT"}
      />
      <label className="m-0 pl-2 mr-5" htmlFor="createMedia__frontLit">
        Front Lit
      </label>

      <input
        type="radio"
        id="createMedia__backLit"
        onChange={() => handleOnChange("BACK_LIT")}
        name="flexRadioDefault"
        checked={litStatusStr === "BACK_LIT"}
      />
      <label className="m-0 pl-2 mr-5" htmlFor="createMedia__backLit">
        Back Lit
      </label>

      <input
        type="radio"
        id="createMedia__noLit"
        onChange={() => handleOnChange("NO_LIT")}
        name="flexRadioDefault"
        checked={litStatusStr === "NO_LIT"}
      />
      <label className="m-0 pl-2" htmlFor="createMedia__noLit">
        Not Lit
      </label>
    </div>
  );
}

export default LitStatus;
