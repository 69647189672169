import { Link } from "react-router-dom";

// Utils
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";

// CSS
import "./DashboardCard.scss";

// in props 'card' is the card-details-object.
// and 'i' is index of card.
// className => card class
// isDisabledBtn => to disable button
function DashboardCard(props) {
  const { card, i, className = "" } = props;
  const cardkey = "ft-card-" + i;
  const iconClass = card.iconClass || "";
  const { isDisabledBtn = false } = card;
  // class to disable button
  const btnClassName = isDisabledBtn ? "disabled" : "";

  return (
    <div className={`card feature-card shadow ${className}`} key={cardkey}>
      <div className="card-body">
        {iconClass && (
          <div className="p-4">
            <i className={iconClass}></i>
          </div>
        )}
        <h4 className="card-title">{card.title}</h4>
        <p className="card-text">{card.content}</p>
        <Link
          className={`btn btn-primary btn-padded ${btnClassName}`}
          to={constructRedirectPath(card.CTALink)}
        >
          {card.CTALabel}
        </Link>
      </div>
    </div>
  );
}

export default DashboardCard;
