export const TargetGroup = {
  // get Target-Group
  GET_TARGET_GROUPS: "GET_TARGET_GROUPS",
  GET_TARGET_GROUPS_SUCCESS: "GET_TARGET_GROUPS_SUCCESS",
  GET_TARGET_GROUPS_FAILURE: "GET_TARGET_GROUPS_FAILURE",

  // Target Group Attributes
  GET_TARGET_GROUP_ATTRIBUTES: "GET_TARGET_GROUP_ATTRIBUTES",
  GET_TARGET_GROUP_ATTRIBUTES_SUCCESS: "GET_TARGET_GROUP_ATTRIBUTES_SUCCESS",
  GET_TARGET_GROUP_ATTRIBUTES_FAILURE: "GET_TARGET_GROUP_ATTRIBUTES_FAILURE",

  // Reset target-group-attributes
  RESET_TARGET_GROUP_ATTRIBUTES: "RESET_TARGET_GROUP_ATTRIBUTES",

  // Review Target Group
  REVIEW_TARGET_GROUP: "REVIEW_TARGET_GROUP",

  // create Target Group actions
  CREATE_TARGET_GROUP: "CREATE_TARGET_GROUP",
  CREATE_TARGET_GROUP_SUCCESS: "CREATE_TARGET_GROUP_SUCCESS",
  CREATE_TARGET_GROUP_FAILURE: "CREATE_TARGET_GROUP_FAILURE",

  // update Target Group
  UPDATE_TARGET_GROUP: "UPDATE_TARGET_GROUP",
  UPDATE_TARGET_GROUP_SUCCESS: "UPDATE_TARGET_GROUP_SUCCESS",
  UPDATE_TARGET_GROUP_FAILURE: "UPDATE_TARGET_GROUP_FAILURE",

  // Target Group Add Actions
  SET_TG_SELECTED: "SET_TG_SELECTED",
  SET_TG_STATUS: "SET_TG_STATUS",

  // Reset
  RESET_TARGET_GROUPS: "RESET_TARGET_GROUPS",

  // Archive and Restore Tg
  ARCHIVE_TARGET_GROUP: "ARCHIVE_TARGET_GROUP",
  ARCHIVE_TARGET_GROUP_SUCCESS: "ARCHIVE_TARGET_GROUP_SUCCESS",
  ARCHIVE_TARGET_GROUP_FAILURE: "ARCHIVE_TARGET_GROUP_FAILURE",

  RESTORE_TARGET_GROUP: "RESTORE_TARGET_GROUP",
  RESTORE_TARGET_GROUP_SUCCESS: "RESTORE_TARGET_GROUP_SUCCESS",
  RESTORE_TARGET_GROUP_FAILURE: "RESTORE_TARGET_GROUP_FAILURE",

  // Update Target Group
  UPDATE_TARGET_GROUP_STATUS: "UPDATE_TARGET_GROUP_STATUS",

  // Target-group-performance
  GET_TG_BASIC_INFO: "GET_TG_BASIC_INFO",
  GET_TG_BASIC_INFO_SUCCESS: "GET_TG_BASIC_INFO_SUCCESS",
  GET_TG_BASIC_INFO_FAILURE: "GET_TG_BASIC_INFO_FAILURE",
  // clear tg-info
  CLEAR_TG_BASIC_INFO: "CLEAR_TG_BASIC_INFO",

  // Set/Remove Selected TG Id
  SET_SELECTED_TG_ID: "SET_SELECTED_TG_ID",
  REMOVE_SELECTED_TG_ID: "REMOVE_SELECTED_TG_ID",

  // Set TargetGroup Attributes in TargetGroupCreatePage for Edit Case..
  SET_TARGET_GROUP_ATTRIBUTES: "SET_TARGET_GROUP_ATTRIBUTES",
};
