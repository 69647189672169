import { OrgMedia } from "../../constants/action-constants/org/OrgMediaActionConstants";

//intital state
const initialState = {
  // Org Media Information
  // ------------------------------------------------------------------
  orgMediaInfo: {},
  kypTrafficData: {},
  orgMediaFileItems: [],
  orgMediaInfoLoading: false,
  orgMediaInfoError: "",

  // Add Org Media Images
  // ------------------------------------------------------------------
  addOrgMediaImagesLoading: false,
  addOrgMediaImagesError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Org Media Information
    // ------------------------------------------------------------------
    case OrgMedia.GET_ORG_MEDIA_INFO:
      return {
        ...state,
        orgMediaInfoLoading: true,
      };

    case OrgMedia.GET_ORG_MEDIA_INFO_SUCCESS:
      return {
        ...state,
        orgMediaInfoLoading: false,
        orgMediaInfo: action.orgMediaInfo,
        kypTrafficData: action.kypTrafficData,
        orgMediaFileItems: action.orgMediaInfo.fileItems,
      };

    case OrgMedia.GET_ORG_MEDIA_INFO_FAILURE:
      return {
        ...state,
        orgMediaInfoLoading: false,
        orgMediaInfoError: action.payload,
      };

    // Add org Media Images
    // ------------------------------------------------------------------
    case OrgMedia.ADD_ORG_MEDIA_IMAGES:
      return {
        ...state,
        addOrgMediaImagesLoading: true,
      };

    case OrgMedia.ADD_ORG_MEDIA_IMAGES_SUCCESS:
      return {
        ...state,
        addOrgMediaImagesLoading: false,
        sellerMediaFileItems: action.sellerMediaFileItems,
      };

    case OrgMedia.ADD_ORG_MEDIA_IMAGES_FAILURE:
      return {
        ...state,
        addOrgMediaImagesLoading: false,
        addOrgMediaImagesError: action.payload,
      };

    default:
      return state;
  }
};
