import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../../constants/ActionConstants";
import * as XLSX from "xlsx";

// this function to get the uploaded "excel-file-data"
// in JSON format.
function getExcelFileData(files) {
  const fileType = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const response = new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(files[0]);

    fileReader.onload = (e) => {
      const targetResult = e.target.result;
      const workbook = XLSX.read(targetResult, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = {
        sheetData: XLSX.utils.sheet_to_json(worksheet),
        sheetName: files[0].name,
      };
      resolve(data);
    };

    fileReader.onerror = reject;
  });

  return response;
}

export function* sellerUploadTemplate(action) {
  const { files } = action.payload;
  try {
    // response(JSON) of uploaded-excel-file
    const response = yield getExcelFileData(files);

    yield put({
      type: ActionTypes.SellerExcelUpload.SELLER_UPLOAD_TEMPLATE_SUCCESS,
      response: response,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.SellerExcelUpload.SELLER_UPLOAD_TEMPLATE_FAILURE,
      payload: error,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.SellerExcelUpload.SELLER_UPLOAD_TEMPLATE,
      sellerUploadTemplate
    ),
  ]);
}
