import { useSelector } from "react-redux";

// Constants and Utils
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import { constructSingleDateString } from "../../../common-utils/date-utils/DateUtils";
import { DATE_FORMATS, MediaColor } from "../../../constants/GeneralConstants";
import { MetricsOptionsDropdownItems } from "../GraphReportsConstants";
import {
  constructPieChartDataMap,
  getAllCitiesMedias,
} from "../GraphReportsUtils";
import { getMediaIcon } from "../../../utils/MediaUtils";

// Components
import { PlainDropdown } from "../../../components/dropdown/Dropdown";
import Spinner from "../../../components/spinner/Spinner";
import { PieChart } from "../../../components/charts/Charts";
import LLMap from "../../../components/map/leaflet-map/LLMap";
import MapMediaMarkerPopup from "../../../components/map/markers/MapMediaMarkerPopup";
import MapDraggedAndZoomed from "../../../components/map/map-drag-and-zoom/MapDraggedAndZoomed";

// Metrics Selector
function MetricsSelector({
  selectedMetric,
  setSelectedMetric = () => {},
  isLoading = false,
}) {
  return (
    <div className="d-flex align-items-center flex-wrap">
      <h4 className="font-weight-bold pr-2 mb-0">City Metrics Split For</h4>
      <div className="d-flex align-items-center">
        <PlainDropdown
          items={MetricsOptionsDropdownItems}
          onItemSelect={(id) => setSelectedMetric(id)}
          selectedId={selectedMetric}
          className="h5 border rounded-lg mb-0 px-2 py-1"
          textClassName="text-primary"
        />
        {isLoading && <Spinner className="spinner-border-sm mx-2" />}
      </div>
    </div>
  );
}

// Campaign Cards
function CampaignCards({ campaign = {}, summary = {} }) {
  // data
  const { totalTargetedPopulation, totalMediaInventoryCoverage } = summary;
  const { title, cityCount, startTimestamp, endTimestamp, mediaCount } =
    campaign;

  // date (start and end)
  const startDate = constructSingleDateString(
    startTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );
  const endDate = constructSingleDateString(
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  // campaign info
  const campaignInfo = [
    {
      label: "Campaign Name",
      value: title,
    },
    {
      label: "Market Cover",
      value: `${toLocaleString(cityCount) || 0} Cities`,
    },
    {
      label: "Duration",
      value: `${startDate} to ${endDate}`,
    },
  ];

  // campaign Metrics
  const campaignMetrics = [
    {
      label: "Target Population",
      value: formatText(toLocaleString(totalTargetedPopulation)),
    },
    {
      label: "Total Media Sites",
      value: toLocaleString(mediaCount) || 0,
    },
    {
      label: "Media Inventory Coverage",
      value: `${formatText(
        toLocaleString(totalMediaInventoryCoverage)
      )} Sq. Ft.`,
    },
  ];

  return (
    <>
      {/* info card */}
      <div className="border p-3 my-3">
        {campaignInfo.map((info, i) => (
          <p key={i} className="mb-2 fs-6 text-truncate">
            <span className="font-weight-bold">{info.label} :</span>{" "}
            <span title={info.value}>{info.value}</span>
          </p>
        ))}
      </div>

      {/* metrics card */}
      <div className="border p-3">
        {campaignMetrics.map((info, i) => (
          <p key={i} className="mb-2 fs-6">
            <span className="font-weight-bold">{info.label} :</span>{" "}
            <span>{info.value}</span>
          </p>
        ))}
      </div>
      <hr />
    </>
  );
}

// Graph Report Map Section
function GraphReportMapSection({
  campaignCityWiseMedias = {},
  mapCenter,
  setMapCenter = () => {},
  mapZoom,
  setMapZoom = () => {},
}) {
  // all cities medias
  const allCitiesMedias = getAllCitiesMedias(campaignCityWiseMedias);

  // media icon
  const mediaIcon = getMediaIcon(MediaColor.campaign);

  return (
    <div className="col-7 px-0 bg-alt border">
      <LLMap center={mapCenter} zoom={mapZoom}>
        {/* Markers */}
        <MapMediaMarkerPopup mediaList={allCitiesMedias} icon={mediaIcon} />

        {/* Drag & Zoom Event Capture Component */}
        <MapDraggedAndZoomed
          setMapZoom={setMapZoom}
          setMapCenter={setMapCenter}
        />
      </LLMap>
    </div>
  );
}

/**
 * Metrics And Map Section
 */
function CampaignMetricsAndMapSection({
  selectedMetric,
  setSelectedMetric = () => {},
  mapCenter,
  setMapCenter = () => {},
  mapZoom,
  setMapZoom = () => {},
}) {
  // Selector
  const { campaign, summary } = useSelector(
    (state) => state.campaignReport.campaignSummary
  );

  // medias
  const campaignCityWiseMedias = useSelector(
    (state) => state.campaignGraphReport.campaignCityWiseMedias
  );

  // data
  const { mediaCount } = campaign;
  const isMediaExist = Boolean(mediaCount);

  // pie Chart Data map
  const constructedPieChartDataMap = constructPieChartDataMap(
    campaign,
    selectedMetric
  );
  const pieChartData = constructedPieChartDataMap[selectedMetric];

  return (
    <div className="row">
      {/* left section */}
      <div className="col-5 border-top border-bottom">
        {/* Cards */}
        <CampaignCards campaign={campaign} summary={summary} />

        {!isMediaExist && (
          <div className="p-5 m-5 text-center">
            <div className="py-5">
              <i className="fa fa-images opacity-50 fa-3x" />
              <p className="mt-2">
                Graph report can not be generated <br /> as campaign do not have
                any media sites.
              </p>
            </div>
          </div>
        )}

        {isMediaExist && (
          <div className="mb-2">
            {/* metrics selector */}
            <MetricsSelector
              selectedMetric={selectedMetric}
              setSelectedMetric={setSelectedMetric}
            />

            {/* chart */}
            <PieChart
              data={pieChartData}
              height={400}
              showCustomToolTip={true}
            />
          </div>
        )}
      </div>

      {/* right section : map */}
      <GraphReportMapSection
        campaignCityWiseMedias={campaignCityWiseMedias}
        mapCenter={mapCenter}
        setMapCenter={setMapCenter}
        mapZoom={mapZoom}
        setMapZoom={setMapZoom}
      />
    </div>
  );
}

export default CampaignMetricsAndMapSection;
