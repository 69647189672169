import { Ots } from "../../constants/action-constants/mavin-tools/OtsActionConstants";

const initialState = {
  // By Road Segment Id
  // ---------------------------------------------------
  segmentOtsInfo: {},
  segmentInfoError: "",
  segmentInfoLoading: false,

  // Ots tool  new  methodology
  // By Road Segment Id
  segmentOtsAndReachInfo: {},
  segmentOtsAndReachInfoError: "",
  segmentOtsAndReachInfoLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // By Road Segment Id
    // --------------------------------------------------------------------
    case Ots.GET_SEGMENT_OTS_INFO:
      return {
        ...state,
        segmentInfoLoading: true,
      };

    case Ots.GET_SEGMENT_OTS_INFO_SUCCESS:
      return {
        ...state,
        segmentOtsInfo: action.segmentOtsInfo,
        segmentInfoLoading: false,
      };

    case Ots.GET_SEGMENT_OTS_INFO_FAILURE:
      return {
        ...state,
        segmentInfoLoading: false,
        segmentInfoError: action.payload,
      };

    case Ots.CLEAR_SEGMENT_OTS_INFO:
      return {
        ...state,
        segmentOtsInfo: {},
      };

    // Ots tool  new  methodology
    // By Road Segment Id
    // --------------------------------------------------------------------
    case Ots.GET_SEGMENT_OTS_AND_REACH_INFO:
      return {
        ...state,
        segmentOtsAndReachInfoLoading: true,
      };

    case Ots.GET_SEGMENT_OTS_AND_REACH_INFO_SUCCESS:
      return {
        ...state,
        segmentOtsAndReachInfo: action.payload,
        segmentOtsAndReachInfoLoading: false,
      };

    case Ots.GET_SEGMENT_OTS_AND_REACH_INFO_FAILURE:
      return {
        ...state,
        segmentOtsAndReachInfoLoading: false,
        segmentOtsAndReachInfoError: action.payload,
      };

    case Ots.CLEAR_SEGMENT_OTS_AND_REACH_INFO:
      return {
        ...state,
        segmentOtsAndReachInfo: {},
      };

    default:
      return state;
  }
};
