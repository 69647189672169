import { ActionTypes } from "../constants/ActionConstants";

// These below actions are for "Confirmation" form
export const openConfirmationForm = (processDataObject) => ({
  type: ActionTypes.ConfirmationFormModal.OPEN_CONFIRMATION_MODAL,
  payload: { processDataObject },
});

export const closeConfirmationForm = () => ({
  type: ActionTypes.ConfirmationFormModal.CLOSE_CONFIRMATION_MODAL,
});
