/* eslint-disable no-case-declarations */
import { RoadStretch } from "../../constants/action-constants/RoadStretchActionConstants";

//initial state
const initialState = {
  // Selected Road-Stretches on MapView
  selectedStretchesMapView: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    // add/remove selected "road-stretch" on "map-view"
    case RoadStretch.ADD_SELECTED_ROAD_STRETCH:
      return {
        ...state,
        selectedStretchesMapView: {
          ...state.selectedStretchesMapView,
          [action.payload.stretchId]: true,
        },
      };

    case RoadStretch.REMOVE_SELECTED_ROAD_STRETCH:
      const updatedRoadStretchMap = { ...state.selectedStretchesMapView };
      delete updatedRoadStretchMap[action.payload.stretchId];

      return {
        ...state,
        selectedStretchesMapView: updatedRoadStretchMap,
      };

    // used In "Restore-save-map-state"
    case RoadStretch.ADD_ALL_SELECTED_ROAD_STRETCHES:
      const roadStretchIdMap = action.payload.roadStretchIds.reduce(
        (acc, stretchId) => {
          acc[stretchId] = true;
          return acc;
        },
        {}
      );
      return {
        ...state,
        selectedStretchesMapView: {
          ...state.selectedStretchesMapView,
          ...roadStretchIdMap,
        },
      };

    case RoadStretch.RESET_SELECTED_ROAD_STRETCHES_IN_MAP_VIEW:
      return initialState;

    default:
      return state;
  }
};
