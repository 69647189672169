import { MetaData } from "../../constants/action-constants/map-view/MetaDataActionConstants";

//intital state
const initialState = {
  metaData: {
    title: true,
    image: true,
    dimensions: true,
    lts: true,
    ots: true,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MetaData.SELECT:
      return {
        ...state,
        metaData: {
          ...state.metaData,
          [action.payload.id]: true,
        },
      };

    case MetaData.UN_SELECT:
      return {
        ...state,
        metaData: {
          ...state.metaData,
          [action.payload.id]: false,
        },
      };

    case MetaData.ASSIGN:
      return {
        ...state,
        metaData: action.payload.metaData,
      };

    case MetaData.RESET:
      return {
        ...state,
        metaData: {
          title: true,
          image: true,
          dimensions: true,
          lts: true,
          ots: true,
        },
      };

    default:
      return state;
  }
};
