import { ActionTypes } from "../../constants/ActionConstants";

export const setTgCpm = (tgCpmStr) => ({
  type: ActionTypes.SingleSiteReport.SET_TG_CPM,
  payload: { tgCpmStr },
});

export const setGenCpm = (genCpmStr) => ({
  type: ActionTypes.SingleSiteReport.SET_GEN_CPM,
  payload: { genCpmStr },
});
