// Component
// ----------------------------------------------------------------------------
function TitleSection({ body = "" }) {
  // if "body" is String then return :
  if (typeof body === "string") {
    return (
      <h2 title={body} className="title text-truncate mb-0">
        {body}
      </h2>
    );
  }

  // returning component itself
  return <div>{body}</div>;
}

/**
 * PageHeader Component
 */
function PageHeader({
  title,
  actions,
  shadow = true,
  border = false,
  className = "",
}) {
  // Props
  const shadowCls = shadow ? "shadow" : "";
  const borderCls = border ? "border" : "";

  return (
    <div className={`page-header ${shadowCls} ${borderCls} ${className}`}>
      {/** Title */}
      <TitleSection body={title} />

      {/** Actions */}
      {actions && <div className="page-actions">{actions}</div>}
    </div>
  );
}

export default PageHeader;
