import { Marker, Polyline, Popup, Tooltip } from "react-leaflet";

// Constants and Utils
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";
import { MapZoom } from "../../../constants/GeneralConstants";

// Component
import LLMap from "../../../components/map/leaflet-map/LLMap";
import MapIcon from "../../../components/map/map-icon/MapIcon";

function SegmentLine({ eachSegment }) {
  const { otsLitAvg, otsAvg } = eachSegment;
  const estImpressions = otsLitAvg ?? otsAvg;
  const segmentPoints = eachSegment.trace || [];

  const color = { color: "blue", weight: 8 };

  const marker = {
    id: eachSegment.id,
    markerText: [
      `Impressions: ${toLocaleString(estImpressions)}`,
      `Name: ${eachSegment.name}`,
    ],
  };

  return (
    <Polyline pathOptions={color} positions={segmentPoints}>
      <Tooltip permanent={true} direction="bottom">
        {marker.markerText.map((string, i) => (
          <b className="d-block" key={i}>
            {string}
          </b>
        ))}
      </Tooltip>
    </Polyline>
  );
}

/**
 * Page Component
 */
function ReportMapView({ locationStr, allRoadSegmentsData }) {
  const locationArray = locationStr.split(",");
  const latitude = locationArray[0];
  const longitude = locationArray[1];

  // map-point-marker
  const marker = {
    type: "un-selected-poi",
    markerText: [`Latitude: ${latitude}`, `Longitude: ${longitude}`],
  };
  const icon = new MapIcon({ type: marker.type });

  return (
    <>
      {latitude && longitude && (
        <LLMap center={[latitude, longitude]} zoom={MapZoom.zoomLevel15}>
          <Marker position={locationArray} icon={icon}>
            <Popup>
              {marker.markerText.map((string, i) => (
                <b className="d-block" key={i}>
                  {string}
                </b>
              ))}
            </Popup>
          </Marker>
          {allRoadSegmentsData.length > 0 &&
            allRoadSegmentsData.map((eachSegment) => (
              <SegmentLine key={eachSegment.id} eachSegment={eachSegment} />
            ))}
        </LLMap>
      )}
    </>
  );
}

export default ReportMapView;
