export function saveFile(filename, data) {
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = filename;
  link.href = URL.createObjectURL(
    new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  link.click();
}

export function saveImageFile(filename, data, imageType) {
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = filename;
  link.href = URL.createObjectURL(
    new Blob([data], {
      type: `image/${imageType}`,
    })
  );
  link.click();
}

export function selectFile(isMultiSelect = false, fileExtensions = []) {
  return new Promise((resolve) => {
    function handleFileSelect(e) {
      const file = e.target.files;
      resolve(file);
    }

    const input = document.createElement("input");
    input.type = "file";
    input.multiple = isMultiSelect;
    if (fileExtensions && fileExtensions.length) {
      input.accept = fileExtensions.join(",");
    }
    input.onchange = handleFileSelect;
    input.click();
  });
}
