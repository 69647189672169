import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  disableSellerSite,
  enableSellerSite,
  getSellerInfoById,
} from "../../../actions/seller/SellerActions";
import { openConfirmationForm } from "../../../actions/ConfirmationFormActions";

// Utils and Constants
import { SellerWebsiteMediaCard, ProfileInfoSection } from "../SellerUtils";
import { FormDataTargets } from "../../../constants/GeneralConstants";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Components
import { ButtonWithLoader } from "../../../mavin/components/button/Button";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import Spinner from "../../../components/spinner/Spinner";
import ConfirmationForm from "../../../components/confirmation-form/ConfirmationForm";
import AlertForm from "../../../components/alert-form/AlertForm";

// Pages and Sections
import SellerProfileEditForm from "./SellerProfileEditForm";

// Page Constants
const URL_HOST_MAVIN = process.env.REACT_APP_URL_HOST_MAVIN;

const mediaInfo = [
  {
    title: "Media 1",
    id: "1",
    type: "Unipole",
    cityName: "Bangalore",
    region: "Whitefield",
    height: "30",
    width: "7",
    litStatus: "FRONT_LIT",
    fileItems: [
      {
        previewUrl:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/1_times_square_night_2013.jpg/800px-1_times_square_night_2013.jpg",
      },
    ],
  },
  {
    title: "Media 2",
    id: "2",
    type: "Billboard",
    cityName: "Bangalore",
    region: "Whitefield",
    height: "30",
    width: "7",
    litStatus: "BACK_LIT",
    fileItems: [
      {
        previewUrl:
          "https://www.ceotodaymagazine.com/CEO-Today/wp-content/uploads/2018/10/The-Power-of-Billboard-Advertising-1.jpg",
      },
    ],
  },
  {
    title: "Media 3",
    id: "3",
    type: "FOB",
    cityName: "Bangalore",
    region: "Whitefield",
    height: "30",
    width: "7",
    litStatus: "NO_LIT",
    fileItems: [
      {
        previewUrl:
          "https://www.ceotodaymagazine.com/CEO-Today/wp-content/uploads/2018/10/The-Power-of-Billboard-Advertising-1.jpg",
      },
    ],
  },
  {
    title: "Media 4",
    id: "4",
    type: "DOOH",
    cityName: "Bangalore",
    region: "Whitefield",
    height: "30",
    width: "7",
    litStatus: "FRONT_LIT",
    fileItems: [
      {
        previewUrl:
          "https://www.ceotodaymagazine.com/CEO-Today/wp-content/uploads/2018/10/The-Power-of-Billboard-Advertising-1.jpg",
      },
    ],
  },
];

// Media Setup
function MediaSetupSection() {
  // card title
  const cardTitle = (
    <>
      <h2 className="mb-0">{"Setup your media"}</h2>
      <p className="mb-0">
        Configure how your media should be displayed as part of the website.{" "}
        <a href="" className="d-none">
          Learn more
        </a>
      </p>
    </>
  );

  const cardActions = (
    <ButtonWithLoader
      displayContent={"Media Display Settings"}
      className={"mt-2 pt-1 d-none"}
    />
  );

  return (
    <div className="mt-4">
      <hr className="my-0" />
      <PageHeader
        className="px-0"
        title={cardTitle}
        actions={cardActions}
        shadow={false}
      />
      <hr className="my-0" />
      <div className="row">
        {mediaInfo.map((media) => (
          <SellerWebsiteMediaCard
            key={media.id}
            media={media}
            isCardDisabled={true}
          />
        ))}
      </div>
    </div>
  );
}

// Profile Edit and View
function ProfileViewAndEdit({ sellerInfo }) {
  // State
  const [isEdit, setIsEdit] = useState(false);

  // Selector State
  const updateSellerInfoLoading = useSelector(
    (state) => state.seller.updateSellerInfoLoading
  );

  // if "isEdit" is true - edit form will be rendered
  if (isEdit) {
    return (
      <SellerProfileEditForm sellerInfo={sellerInfo} setIsEdit={setIsEdit} />
    );
  }

  return (
    <ProfileInfoSection
      sellerInfo={sellerInfo}
      isPreviewPage={false}
      onClick={() => setIsEdit(true)}
      loader={updateSellerInfoLoading}
    />
  );
}

// Website Preview Button
function PreviewButton() {
  const history = useHistory();

  // Redirect Url
  const sellerWebsitePreviewPageUrl = RedirectTo.sellerWebsitePreviewPageUrl;

  return (
    <button
      className="btn btn-outline-primary rounded-lg mx-2 shadow-none"
      onClick={() => history.push(sellerWebsitePreviewPageUrl)}
    >
      {"Preview"}
    </button>
  );
}

// if website is Published
function GoToPublicWebsiteBtn({ sellerId }) {
  // Public Seller Website Url
  const sellerPublicWebsiteUrl = `${URL_HOST_MAVIN}/s/${sellerId}`;

  return (
    <a
      className="btn btn-outline-success rounded-lg mx-2 shadow-none"
      href={sellerPublicWebsiteUrl}
      rel="noreferrer"
      target="_blank"
    >
      {"Go to Website"}
    </a>
  );
}

// Setup Public Profile
function SetupPublicProfileSection({ sellerInfo }) {
  const dispatch = useDispatch();

  // Selector
  const enableSellerSiteLoading = useSelector(
    (state) => state.seller.enableSellerSiteLoading
  );
  const disableSellerSiteLoading = useSelector(
    (state) => state.seller.disableSellerSiteLoading
  );

  const { isPublished, id: sellerId } = sellerInfo;

  // publish website variables
  const publishButtonClassName = isPublished ? "btn-danger" : "btn-success";
  const publishButtonLabel = isPublished
    ? "Unpublish Website"
    : "Publish Website";
  const publishDispatchFn = isPublished
    ? openConfirmationForm
    : enableSellerSite;

  // card title
  const cardTitle = (
    <>
      <h2 className="mb-0">{"Public profile setup"}</h2>
      <p className="mb-0">
        {"This is what the world is going to see of your business."}
      </p>
    </>
  );

  const cardActions = (
    <div className="mt-2 pt-1">
      {/* NOT-PUBLISHED -> Preview Website Button */}
      <PreviewButton />

      {/* PUBLISHED -> Public Website Button */}
      {isPublished && <GoToPublicWebsiteBtn sellerId={sellerId} />}

      {/* Publish and Unpublish Button */}
      <button
        className={`btn ${publishButtonClassName} rounded-lg shadow-none px-3 ml-2`}
        data-toggle="modal"
        data-target={`#${FormDataTargets.confirmationForm}`}
        onClick={() => dispatch(publishDispatchFn())}
        disabled={enableSellerSiteLoading || disableSellerSiteLoading}
      >
        {publishButtonLabel}
        {(enableSellerSiteLoading || disableSellerSiteLoading) && (
          <Spinner className="ml-1 spinner-border-sm text-light" />
        )}
      </button>
    </div>
  );

  return (
    <>
      <PageHeader
        className="px-0"
        title={cardTitle}
        actions={cardActions}
        shadow={false}
      />
      <hr className="my-0" />

      {/* Profile view and Edit */}
      <ProfileViewAndEdit sellerInfo={sellerInfo} />
    </>
  );
}

/**
 * Main Page - Seller Website Setup
 */
export default function SellerWebsiteSetupPage() {
  const dispatch = useDispatch();

  // Selector State
  const sellerInfo = useSelector((state) => state.seller.sellerInfo);
  const sellerInfoLoading = useSelector(
    (state) => state.seller.sellerInfoLoading
  );

  // Confirmation Form
  const openConfirmationForm = useSelector(
    (state) => state.confirmationModal.openModal
  );

  // Website Publish Success Form
  const showAlertForm = useSelector((state) => state.alertModal.openModal);

  // Dispatch
  useEffect(() => {
    dispatch(getSellerInfoById());
  }, [dispatch]);

  // Page Loading
  if (sellerInfoLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  // Alert Modal Content
  const alertContent = (
    <p className="mb-0 h5 text-success">
      <span className="d-block mb-2">{"Congratulations!!!"}</span>
      <span>{"Website has been successfully published."}</span>
    </p>
  );

  return (
    <>
      <div className="content-wrapper min-h-100 bg-alt">
        <div className="container">
          <div className="seller-website-page ">
            {/* Public Profile Setup */}
            <SetupPublicProfileSection sellerInfo={sellerInfo} />

            {/* Media Setup */}
            <MediaSetupSection />
          </div>
        </div>
      </div>

      {/* Modals */}
      {/* Confirmation Modal */}
      {openConfirmationForm && (
        <ConfirmationForm
          bodyText={"Are you sure? Do you want to un-publish website"}
          onClick={() => dispatch(disableSellerSite())}
        />
      )}

      {/* Website Publish Success */}
      {showAlertForm && <AlertForm bodyContent={alertContent} />}
    </>
  );
}
