import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  addLocationOfIntrestInfluenceCircle,
  removeLocationOfIntrestInfluenceCircle,
  setLocationOfIntrestInfluenceCircleRadius,
} from "../../../actions/map-view/LocationOfIntrestActions";

// Constants and Utils
import {
  FormDataTargets,
  locationOfIntrestRadiusConstants,
} from "../../../../constants/GeneralConstants";
import { LocationSearchInput } from "../../../../pages/mavin-tools/ToolsUtils";

/*
  LocationOfIntrestForm
 */
export default function LocationOfIntrestForm({
  locationStr,
  setLocationStr,
  setCoordinate,
  center = [],
}) {
  // dispatch
  const dispatch = useDispatch();

  // selector
  const locationOfIntrestInfluenceCircleRadius = useSelector(
    (state) => state.locationOfIntrest.locationOfIntrestInfluenceCircleRadius
  );

  function handleCancelFn() {
    setLocationStr("");
    dispatch(removeLocationOfIntrestInfluenceCircle());
  }

  return (
    <div
      className="modal fade"
      id={FormDataTargets.locationOfIntrestForm}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {/*  Header */}
          <div className="modal-header bg-alt px-5">
            <h4 className="modal-title">Location Of Interest</h4>
          </div>

          {/* Form body */}
          <div className="modal-body px-5">
            <form>
              <div className="form-group">
                <label className="col-form-label">Search Location</label>
                {/*  Location Search Input*/}
                <LocationSearchInput
                  locationStr={locationStr}
                  setLocationStr={setLocationStr}
                  setCoordinate={setCoordinate}
                  center={center}
                />
              </div>

              <div className="form-group">
                <label htmlFor="select-media-circle">
                  {"Radius of Influence"}
                </label>
                <select
                  id="select-media-circle"
                  value={locationOfIntrestInfluenceCircleRadius}
                  className="form-control shadow-none rounded-lg"
                  onChange={(e) =>
                    dispatch(
                      setLocationOfIntrestInfluenceCircleRadius(e.target.value)
                    )
                  }
                >
                  {locationOfIntrestRadiusConstants.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>

          {/* Form Footer */}
          <div className="modal-footer px-5">
            <button
              type="button"
              className="btn btn-outline-primary btn-action mt-2 mr-3 rounded-lg"
              data-dismiss="modal"
              onClick={handleCancelFn}
            >
              {"Cancel"}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-action mt-2 rounded-lg"
              data-dismiss="modal"
              onClick={() => dispatch(addLocationOfIntrestInfluenceCircle())}
            >
              {"Confirm"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
