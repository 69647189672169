import React from "react";

// Components
import { ProgressBar } from "../progress-bar/ProgressBar";

/**
 * This function renders a table header cell with specific information and a bar chart.

 * @param {Array} headerInfo - An array of objects containing information for each header element.
 * @param {string} subText - The text to be displayed below the bar.
 */
export function OtsAndReachHeader({ headerInfo, subText, className = "" }) {
  return (
    <th className={`text-left align-middle ${className}`}>
      {headerInfo.map((eachHeaderInfo, i) => (
        <p className="m-0 d-flex align-items-center" key={i}>
          <span
            className={`d-inline-block border border-dark mr-2 square ${eachHeaderInfo.className}`}
          ></span>
          {eachHeaderInfo.displayText}
        </p>
      ))}

      <div className="mt-2">
        {headerInfo.map((eachHeaderInfo, i) => (
          <React.Fragment key={i}>
            <ProgressBar
              className={eachHeaderInfo.className}
              percentage={eachHeaderInfo.percentage}
            />
          </React.Fragment>
        ))}
      </div>
      <small className="sub-text mt-1">{subText}</small>
    </th>
  );
}
