// URLS
import {
  restoreMapStateUrl,
  saveMapStateUrl,
} from "../../urls/map-view/SaveMapStateURL";

// Constants and Utils
import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../../utils/api/apiUtils";

export async function saveMapState(campaignId, cityId, requestBody) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = saveMapStateUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);
  return sendCreateOrUpdateRequest(
    url,
    requestBody,
    true,
    "PUT",
    headerParams,
    {}
  );
}

export async function getSavedMapState(campaignId, cityId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = restoreMapStateUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);
  return sendGetRequest(url, {}, true, headerParams);
}
