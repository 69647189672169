import { ActionTypes } from "../../../constants/ActionConstants";

// These below actions are for "Target-Group" form
export const openTargetGroupForm = () => ({
  type: ActionTypes.TargetGroupForm.OPEN_TARGET_GROUP_MODAL,
});

export const closeTargetGroupForm = () => ({
  type: ActionTypes.TargetGroupForm.CLOSE_TARGET_GROUP_MODAL,
});
