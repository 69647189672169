import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getTargetGroups,
  setSelectedTgId,
} from "../../../actions/org/OrgTargetGroupActions";
import {
  getTgHeatMapData,
  removeTgHeatMapData,
} from "../../../actions/geo-data/GeoDataActions";

// Constants
import { defaultPagination } from "../../../constants/UrlConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";
import SearchInputLocal from "../../../components/search-input-local/SearchInputLocal";

// Page Components
function TargetGroupInfo({ eachTgInfo, bbox }) {
  // Selector State
  const poiTypeLayerDataLoading = useSelector(
    (state) => state.geoData.poiTypeLayerDataLoading
  );
  // selected tg
  const selectedTgId = useSelector(
    (state) => state.orgTargetGroup.selectedTgId
  );

  // Dispatch
  const dispatch = useDispatch();

  // Function
  function getTgHeatMapFn() {
    dispatch(removeTgHeatMapData());
    dispatch(setSelectedTgId(tgId));
    dispatch(getTgHeatMapData(resPoiLayers, bbox));
  }

  // to get id and all resultingPoiLayers
  const { id: tgId, resultLayers = [] } = eachTgInfo;

  const resPoiLayers = resultLayers.map((eachLayer) => eachLayer.poiTypeId);

  // selected and select button config
  const buttonLabel = selectedTgId === tgId ? "Selected" : "Select";

  const buttonClassName =
    selectedTgId === tgId ? "btn-outline-success" : "btn-outline-primary";

  const buttonIconClassName = selectedTgId === tgId ? "fa fa-check" : "";

  // tgSelect loading
  const selectTgLoader = poiTypeLayerDataLoading && selectedTgId === tgId;

  return (
    <div className="p-2 border-bottom navbar">
      <span className="px-2">{eachTgInfo.name}</span>
      <div>
        {selectTgLoader && <Spinner className="spinner-border-sm" />}
        <button
          disabled={selectTgLoader}
          className={`btn ml-2 ${buttonClassName} add-button shadow-none`}
          onClick={getTgHeatMapFn}
        >
          <i className={buttonIconClassName}></i> {buttonLabel}{" "}
        </button>
      </div>
    </div>
  );
}

export default function TargetGroupListingSection({ listingViewLabels, bbox }) {
  // State
  const [targetGroups, setTargetGroups] = useState([]);

  // Selector State
  const getTgLoading = useSelector(
    (state) => state.orgTargetGroup.targetGroupLoading
  );

  // for listing of TG's (includes all information)
  const targetGroupsList = useSelector((state) => state.orgTargetGroup.tgList);

  const pageNumber = defaultPagination.pageNo,
    PageSize = defaultPagination.pageSize;

  const { search } = listingViewLabels;

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    if (targetGroupsList.length === 0) {
      dispatch(getTargetGroups(true, "", pageNumber, PageSize));
    }
  }, [dispatch]);

  // Loading
  if (getTgLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {/* Search Input Local */}
      <SearchInputLocal
        placeholder={search.placeholder}
        dataList={targetGroupsList}
        searchFields={search.fields}
        onSearchComplete={setTargetGroups}
      />
      <hr className="my-2" />

      {/* TG list table */}
      {targetGroups.map((eachTgInfo) => (
        <TargetGroupInfo
          key={eachTgInfo.id}
          eachTgInfo={eachTgInfo}
          bbox={bbox}
        />
      ))}

      {/* If there is no target group found */}
      {targetGroupsList.length < 1 && (
        <p className="text-center mt-3 font-italic">
          {"Target Groups are not available"}
        </p>
      )}
    </>
  );
}
