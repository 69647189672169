import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

// TODO: As of now we are going with jsPDF and html2canvas, we can use
// existing html2pdf which internally uses jsPdf and html2canvas.
export async function downloadPdf(element, fileName) {
  //https://stackoverflow.com/questions/25904440/jspdf-addhtml-multiple-canvas-page
  const x = await html2canvas(element, {
    allowTaint: true,
    useCORS: true,
  });
  const imgData = x.toDataURL("img/jpg");
  const imgWidth = 210;
  const pageHeight = 295;
  const imgHeight = (x.height * imgWidth) / x.width;
  let heightLeft = imgHeight;
  const doc = new jsPDF("p", "mm");
  let position = 0;

  doc.addImage(imgData, "JPG", 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(imgData, "JPG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }
  doc.save(fileName);
}
