import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Actions
import {
  createTargetGroup,
  updateTargetGroup,
} from "../../../actions/org/OrgTargetGroupActions";
import { removeTgHeatMapData } from "../../../actions/geo-data/GeoDataActions";
import { clearRegionDataByCity } from "../../../actions/regions/RegionActions";

// Components
import Spinner from "../../../components/spinner/Spinner";

// Page Components
// ----------------------------------------------------------------------------
function EachAttribute({ attrName, selectedPois }) {
  return (
    <>
      <b>{attrName}</b>
      <ul className="mb-0">
        {selectedPois.map((eachPoi, index) => (
          <li key={index} className="p-1">
            <span>{eachPoi}</span>
          </li>
        ))}
      </ul>
    </>
  );
}

function SelectedAttributes({ attSelections }) {
  return (
    <div className="col-4 py-2 border-right">
      <h3>{"Attribute Selections"}</h3>
      {Object.keys(attSelections).map((eachKey) => (
        <EachAttribute
          key={eachKey}
          attrName={eachKey}
          selectedPois={attSelections[eachKey]}
        />
      ))}
    </div>
  );
}

function ResultantPoiLayers({ resPoiLayers }) {
  // eachPoiLayer ==> AGE_GROUP_Male_Bar
  const strimmedPoiLayers = resPoiLayers.map(
    (eachPoiLayer) => eachPoiLayer.split("__")[2]
  );
  const uniqueResPoiLayers = [...new Set(strimmedPoiLayers)];
  return (
    <div className="col-8 py-2">
      <h3>{"Resulting POI Layers"}</h3>
      <ul className="mb-0">
        {uniqueResPoiLayers.map((eachPoi, index) => (
          <li key={index} className="p-1">
            <span>{eachPoi}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

function constructCreateTgRequest(selectedPois, poiNameToLayerMap, tgName) {
  const tgBean = {};
  tgBean["name"] = tgName;

  const layers = selectedPois.reduce((acc, eachSelectedPoi) => {
    acc.push(poiNameToLayerMap[eachSelectedPoi]);
    return acc;
  }, []);

  tgBean["layers"] = layers;
  return tgBean;
}

function Footer({ tgName, setIsReviewView, tgId, existingEditableTgName }) {
  const dispatch = useDispatch();
  const history = useHistory();

  // Selector States
  const selectedPois = useSelector(
    (state) => state.orgTargetGroup.selectedPois
  );

  const poiNameToLayerMap = useSelector(
    (state) => state.orgTargetGroup.poiNameToLayerMap
  );

  const createTgLoading = useSelector(
    (state) => state.orgTargetGroup.isCreateTgLoading
  );
  const isUpdateTgLoading = useSelector(
    (state) => state.orgTargetGroup.isUpdateTgLoading
  );

  // Functions
  // for creating/updating of TG
  function constructTgRequest() {
    const tgBean = constructCreateTgRequest(
      selectedPois,
      poiNameToLayerMap,
      tgName ?? existingEditableTgName
    );
    const action = tgId
      ? updateTargetGroup(tgBean, history, tgId, existingEditableTgName)
      : createTargetGroup(tgBean, history);
    dispatch(action);
  }

  function onBackToEditingClick() {
    dispatch(removeTgHeatMapData());
    dispatch(clearRegionDataByCity());
    setIsReviewView(false);
  }

  return (
    <>
      <hr className="divider m-0"></hr>
      <div className="d-flex py-3">
        <button
          className="btn btn-outline-primary mr-2 px-4 shadow-none"
          onClick={onBackToEditingClick}
        >
          {"Back To Editing"}
        </button>
        <button
          type="button"
          onClick={constructTgRequest}
          className="btn btn-primary px-4 shadow-none"
          disabled={!tgName}
        >
          {"Save"}
        </button>
        {(createTgLoading || isUpdateTgLoading) && <Spinner className="ml-2" />}
      </div>
    </>
  );
}

export default function TgReview({
  setIsReviewView,
  tgId,
  existingEditableTgName,
}) {
  const [tgName, setTgName] = useState(existingEditableTgName);

  const attSelections = useSelector(
    (state) => state.orgTargetGroup.attSelections
  );

  const resPoiLayers = useSelector(
    (state) => state.orgTargetGroup.resPoiLayers
  );

  if (Object.keys(attSelections).length === 0) {
    return null;
  }

  return (
    <>
      <div className="form-group">
        <label className="control-label">{"Target Group Name"}</label>
        <input
          type="search"
          className="form-control rounded-lg"
          placeholder="Enter Target Group Name"
          id={"TgNameStr"}
          value={tgName}
          onChange={(e) => setTgName(e.target.value)}
          disabled={resPoiLayers.length < 1}
        />
      </div>

      <div className="list">
        <div className="d-flex border">
          <SelectedAttributes attSelections={attSelections} />
          <ResultantPoiLayers resPoiLayers={resPoiLayers} />
        </div>
        <Footer
          tgName={tgName}
          setIsReviewView={setIsReviewView}
          tgId={tgId}
          existingEditableTgName={existingEditableTgName}
        />
      </div>
    </>
  );
}
