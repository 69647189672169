import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { setSelectedBoundaries } from "../../actions/campaign-planning/CampaignPlanningActions";

// Components
import NestedList from "../../components/nested-list/NestedList";

export default function ZonesTab() {
  const dispatch = useDispatch();

  const boundariesData = useSelector(
    (state) => state.campaignPlanning.boundariesData
  );

  function actionOnSelect(selectedIndex, label) {
    dispatch(setSelectedBoundaries(selectedIndex, label));
  }

  return (
    <>
      <h2 className="mb-0">Zones</h2>
      <hr className="my-3"></hr>
      <NestedList
        data={boundariesData}
        selectAllAttr={false}
        setSelected={actionOnSelect}
        onCheckboxChange={() => {}}
      />
    </>
  );
}
