import { ActionTypes } from "../constants/ActionConstants";

/**
 * This is the common Popup for all Actions which needs
 * "Confirmation Message" for eg: "delete" and "remove".
 *
 * @openModal : key used to open and close the popup
 * @processDataObject : collection of data to be processed.
 */
const initialState = {
  openModal: false,
  processDataObject: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ConfirmationFormModal.OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        openModal: true,
        processDataObject: action.payload.processDataObject,
      };
    case ActionTypes.ConfirmationFormModal.CLOSE_CONFIRMATION_MODAL:
      return {
        ...state,
        openModal: false,
      };

    default:
      return state;
  }
};
