import { useState } from "react";
import { useEffect } from "react";

// Propreties

const DEFAULT_SEARCH_FIELD = "searchText";

// Util Functions
// ----------------------------------------------------------------------------

function prepareSearchableList(dataList, searchFields = []) {
  const searchableList = dataList.map((item) => {
    // Default case for search fields
    if (searchFields.length === 0) {
      searchFields.push(DEFAULT_SEARCH_FIELD);
    }

    // Prepare Search Text for each item
    const searchTxt = searchFields //
      .map((field) => item[field] || "") //
      .join(" ") //
      .toLowerCase();

    // Update item with search text
    item[DEFAULT_SEARCH_FIELD] = searchTxt;
    return item;
  });

  return searchableList;
}

// Component
// ----------------------------------------------------------------------------

export default function SearchInputLocal({
  className = "",
  placeholder = "Search",
  dataList,
  searchFields = [],
  searchTextMinLength = 2,
  onSearchComplete,
}) {
  // Component State
  const [searchableList, setSearchableList] = useState([]);

  // One-time preparation of Search Data
  useEffect(() => {
    const searchableList = prepareSearchableList(dataList, searchFields);
    setSearchableList(searchableList);

    // for the first render data list value will be directly passed to the onSearchComplete
    onSearchComplete(searchableList);
  }, [dataList.length]);

  // search function
  function doSearch(inpQuery = "") {
    const query = inpQuery.trim().toLowerCase();

    // Search only if query min-length is matched
    if (query.length >= searchTextMinLength) {
      const resultList = searchableList.filter((item) =>
        item.searchText.includes(query)
      );

      onSearchComplete(resultList);
      return;
    }

    // default return the entire list
    onSearchComplete(searchableList);
  }

  return (
    <input
      type="search"
      className={`form-control shadow-none ${className}`}
      placeholder={placeholder}
      onChange={(e) => doSearch(e.target.value)}
    />
  );
}
