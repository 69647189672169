import { Status } from "../../constants/GeneralConstants";

export function getSelectedIndex(dataList) {
  for (const [index, data] of dataList.entries()) {
    if (data.type) {
      const childIndex = data.children.findIndex(
        (childData) => childData.isSelected
      );
      if (childIndex !== -1) {
        return { parentIndex: index, childIndex: childIndex };
      }
    }
    if (data.isSelected) {
      return { parentIndex: index, childIndex: -1 };
    }
  }
  return { parentIndex: -1, childIndex: -1 };
}

export function getTitle(selectedIndex, dataList) {
  if (selectedIndex.parentIndex === -1) {
    return "";
  }

  if (selectedIndex.childIndex === -1) {
    return dataList[selectedIndex.parentIndex].label;
  }

  return dataList[selectedIndex.parentIndex].children[selectedIndex.childIndex]
    .label;
}

export function isSubSectionOpen(selectedIndex, dataList) {
  if (selectedIndex.parentIndex === -1 && selectedIndex.childIndex === -1) {
    return false;
  }

  if (
    selectedIndex.parentIndex !== -1 &&
    selectedIndex.childIndex === -1 &&
    dataList[selectedIndex.parentIndex].children.length < 1
  ) {
    return false;
  }

  if (
    selectedIndex.parentIndex !== -1 &&
    selectedIndex.childIndex !== -1 &&
    dataList[selectedIndex.parentIndex].children[selectedIndex.childIndex]
      .children.length < 1
  ) {
    return false;
  }
  return true;
}

export function filterDataList(dataList, path) {
  if (Object.keys(path) < 1) {
    return dataList;
  }
  if (path.parentIndex !== -1 && path.childIndex === -1) {
    dataList = dataList[path.parentIndex].children;
    return dataList;
  }

  if (path.parentIndex !== -1 && path.childIndex !== -1) {
    dataList = dataList[path.parentIndex].children[path.childIndex].children;
    return dataList;
  }
  return dataList;
}

export function getTag(children) {
  if (children.length === 0) {
    return "";
  }
  const checkedCount = children.filter(
    (child) => child.status === Status.CHECKED
  ).length;
  return `${checkedCount}/${children.length}`;
}

export function getTgTag(children) {
  if (children.length === 0) {
    return "";
  }
  const checkedCount = children.filter(
    (child) => child.status === Status.CHECKED
  ).length;

  const totalCount = children.length;

  return { checkedCount, totalCount };
}
