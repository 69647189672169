export const RegionsData = [
  {
    ID: "city-agra",
    "MALE %": 53.5203017100104,
    "FEMALE %": 46.47969828998961,
  },
  {
    ID: "city-ahmedabad",
    "MALE %": 52.50808359600334,
    "FEMALE %": 47.491916403996655,
  },
  {
    ID: "city-aurangabad",
    "MALE %": 51.9946523739206,
    "FEMALE %": 48.0053476260794,
  },
  {
    ID: "city-bangalore",
    "MALE %": 50.68423797196318,
    "FEMALE %": 49.31576346482334,
  },
  {
    ID: "city-bhilai",
    "MALE %": 51.29719175134512,
    "FEMALE %": 48.70280824865489,
  },
  {
    ID: "city-bhopal",
    "MALE %": 52.13405691346947,
    "FEMALE %": 47.86594308653053,
  },
  {
    ID: "city-chandigarh",
    "MALE %": 55.00552657939277,
    "FEMALE %": 44.994473420607235,
  },
  {
    ID: "city-chennai",
    "MALE %": 50.26853784888494,
    "FEMALE %": 49.73146215111506,
  },
  {
    ID: "city-coimbatore",
    "MALE %": 50.00792685753941,
    "FEMALE %": 49.99207314246058,
  },
  {
    ID: "city-delhi-ncr",
    "MALE %": 53.53318795989617,
    "FEMALE %": 46.466806859050905,
  },
  {
    ID: "city-dhanbad",
    "MALE %": 52.3733613788902,
    "FEMALE %": 47.6266386211098,
  },
  {
    ID: "city-durg",
    "MALE %": 50.303977436511126,
    "FEMALE %": 49.696022563488874,
  },
  {
    ID: "city-ghaziabad",
    "MALE %": 53.16152528117749,
    "FEMALE %": 46.83847471882251,
  },
  {
    ID: "city-gwalior",
    "MALE %": 53.65688444873323,
    "FEMALE %": 46.34311555126677,
  },
  {
    ID: "city-hyderabad",
    "MALE %": 51.17706125181301,
    "FEMALE %": 48.82293874818699,
  },
  {
    ID: "city-indore",
    "MALE %": 51.87012098420723,
    "FEMALE %": 48.12987901579277,
  },
  {
    ID: "city-jabalpur",
    "MALE %": 51.85254556572887,
    "FEMALE %": 48.14745443427113,
  },
  {
    ID: "city-jaipur",
    "MALE %": 52.34551123409752,
    "FEMALE %": 47.654488765902485,
  },
  {
    ID: "city-jamshedpur",
    "MALE %": 52.10588321370605,
    "FEMALE %": 47.89411678629396,
  },
  {
    ID: "city-jodhpur",
    "MALE %": 52.179065722502294,
    "FEMALE %": 47.82093427749771,
  },
  {
    ID: "city-kannur",
    "MALE %": 60.00815647124062,
    "FEMALE %": 68.1405201559705,
  },
  {
    ID: "city-kanpur",
    "MALE %": 53.692686144332825,
    "FEMALE %": 46.307313855667175,
  },
  {
    ID: "city-cochin",
    "MALE %": 49.263158788597,
    "FEMALE %": 50.736841211403,
  },
  {
    ID: "city-kolkata",
    "MALE %": 52.41107493875041,
    "FEMALE %": 47.58892506124958,
  },
  {
    ID: "city-kollam",
    "MALE %": 47.3165424839371,
    "FEMALE %": 52.6834575160629,
  },
  {
    ID: "city-kota",
    "MALE %": 52.34003569051603,
    "FEMALE %": 47.65996430948397,
  },
  {
    ID: "city-kozhikode",
    "MALE %": 47.66045706239416,
    "FEMALE %": 52.33954293760584,
  },
  {
    ID: "city-lucknow",
    "MALE %": 52.16907938754963,
    "FEMALE %": 47.83092061245037,
  },
  {
    ID: "city-madurai",
    "MALE %": 50.24189457413958,
    "FEMALE %": 49.75810542586042,
  },
  {
    ID: "city-malappuram",
    "MALE %": 47.662689690526186,
    "FEMALE %": 52.337310309473814,
  },
  {
    ID: "city-meerut",
    "MALE %": 53.01707479313799,
    "FEMALE %": 46.982925206862,
  },
  {
    ID: "city-greater-mumbai",
    "MALE %": 54.953464324,
    "FEMALE %": 45.046535675888876,
  },
  {
    ID: "city-nagpur",
    "MALE %": 51.250443939159894,
    "FEMALE %": 48.74955606084011,
  },
  {
    ID: "city-nashik",
    "MALE %": 51.69623834989802,
    "FEMALE %": 48.30376165010198,
  },
  {
    ID: "city-patna",
    "MALE %": 52.728104241972495,
    "FEMALE %": 47.271895758027505,
  },
  {
    ID: "city-allahabad",
    "MALE %": 52.59660544645941,
    "FEMALE %": 47.40339455354059,
  },
  {
    ID: "city-pune",
    "MALE %": 52.22072540926555,
    "FEMALE %": 47.77927459073445,
  },
  {
    ID: "city-raipur",
    "MALE %": 50.399852728476326,
    "FEMALE %": 49.60014727152367,
  },
  {
    ID: "city-rajkot",
    "MALE %": 51.896842390775774,
    "FEMALE %": 48.103157609224226,
  },
  {
    ID: "city-ranchi",
    "MALE %": 51.29742113484971,
    "FEMALE %": 48.702578865150286,
  },
  {
    ID: "city-srinagar",
    "MALE %": 52.644622568103905,
    "FEMALE %": 47.355377431896095,
  },
  {
    ID: "city-surat",
    "MALE %": 55.94547290303527,
    "FEMALE %": 44.054527096964726,
  },
  {
    ID: "city-thrissur",
    "MALE %": 47.442126595085114,
    "FEMALE %": 52.55787340491488,
  },
  {
    ID: "city-trichy",
    "MALE %": 49.67450664618197,
    "FEMALE %": 50.32549335381804,
  },
  {
    ID: "city-thiruvananthpuram",
    "MALE %": 47.90892431803177,
    "FEMALE %": 52.091075681968235,
  },
  {
    ID: "city-vadodara",
    "MALE %": 51.702585322995866,
    "FEMALE %": 48.29741467700414,
  },
  {
    ID: "city-varanasi",
    "MALE %": 52.269255218559294,
    "FEMALE %": 47.7307447814407,
  },
  {
    ID: "city-vijayawada",
    "MALE %": 50.325099818474925,
    "FEMALE %": 49.67490018152507,
  },
  {
    ID: "city-visakhapatnam",
    "MALE %": 49.851188460866275,
    "FEMALE %": 50.14881153913373,
  },
  {
    ID: "city-shamshabad",
    "MALE %": 51.3,
    "FEMALE %": 48.7,
  },
];
