import { all, put, takeLatest } from "redux-saga/effects";

// Api
import { getLocationSummaryFn } from "../../apis/LocationSummaryAPI";

// Constants & Utils
import { CompareLocations } from "../../constants/action-constants/mavin-tools/CompareLocationsActionConstants";
import { getErrorMessage } from "../../utils/util";

function* getCompareLocationSummary(action) {
  try {
    const { coordinate1, coordinate2, radius } = action.payload;
    const [latitude1, longitude1] = coordinate1;
    const [latitude2, longitude2] = coordinate2;

    // Api call ==> effects will get executed in parallel
    const [location1Summary, location2Summary] = yield all([
      getLocationSummaryFn(latitude1, longitude1, radius),
      getLocationSummaryFn(latitude2, longitude2, radius),
    ]);

    // Creating LocationSummaryMap with their Coordinates
    const compareLocationSummary = {};
    compareLocationSummary[coordinate1] = location1Summary.result;
    compareLocationSummary[coordinate2] = location2Summary.result;

    yield put({
      type: CompareLocations.GET_COMPARE_LOCATION_SUMMARY_SUCCESS,
      compareLocationSummary: compareLocationSummary,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put({
      type: CompareLocations.GET_COMPARE_LOCATION_SUMMARY_FAILURE,
      payload: errorMessage,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      CompareLocations.GET_COMPARE_LOCATION_SUMMARY,
      getCompareLocationSummary
    ),
  ]);
}
