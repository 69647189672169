import { useSelector } from "react-redux";

// Components
import DataPointMarkers from "../../../components/data-point-markers/DataPointMarkers";

function DataLayerMarkers({ cityId }) {
  const dataPointsMap = useSelector((state) =>
    Object.keys(state.dataLayersSelection.isLayerSelected).reduce(
      (acc, layerId) => {
        if (!state.dataLayersSelection.isLayerSelected[layerId]) {
          return acc;
        }
        const citiesPoints =
          state.orgDataLayers.dataLayerDetailsMap[layerId]?.dataPointMap || {};
        const filteredPoints = citiesPoints[cityId] || [];
        acc[layerId] = filteredPoints;
        return acc;
      },
      {}
    )
  );

  return Object.keys(dataPointsMap).map((layerId, index) => {
    return (
      <DataPointMarkers
        key={layerId}
        layerIdx={index}
        dataPoints={dataPointsMap[layerId]}
      />
    );
  });
}

export default DataLayerMarkers;
