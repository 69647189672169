import { SingleSiteAnalyser } from "../../constants/action-constants/mavin-tools/SingleSiteAnalyserActionConstants";

// Single Site Report tool Info
export const getSingleSiteAnalyserInfo = (mediaInfoBean) => ({
  type: SingleSiteAnalyser.GET_SINGLE_SITE_ANALYSER_INFO,
  payload: { mediaInfoBean },
});

export const resetSingleSiteAnalyserInfo = () => ({
  type: SingleSiteAnalyser.RESET_SINGLE_SITE_ANALYSER_INFO,
});
