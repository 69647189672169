import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

// Actions
import { getCampaignMediaByCity } from "../../../../actions/campaign-media/CampaignMediaActions";
import { getCityOverview } from "../../../../actions/campaign-report/CampaignCityReportActions";
import { getMapViewExploreSites } from "../../../../actions/map-view/media-sites/ExploreSitesActions";
import { removeMediaSite } from "../../../../actions/map-view/media-sites/MediaSitesActions";
import { openConfirmCampaignMediaSelectionForm } from "../../../actions/campaign/CampaignMediaSelectionActions";

// Constants and Utils
import { defaultPagination } from "../../../../constants/UrlConstants";
import {
  FormDataTargets,
  LocalStorageKeys,
  MediaInventoryOptions,
} from "../../../../constants/GeneralConstants";
import { getItem } from "../../../../utils/localstorage";
import { constructRedirectPath } from "../../../../utils/redirect-utils/RedirectUtils";

// Urls
import { RedirectTo } from "../../../../urls/PageLinksURL";

// Component
import Spinner from "../../../../components/spinner/Spinner";
import PageHeader from "../../../../mavin/components/page-header/PageHeader";
import CampaignSidebar from "../../../../components/campaign-sidebar/CampaignSidebar";
import { CampaignCityPerformance } from "../../../../mavin/components/campaign-city-performance/CampaignCityPerformance";
import ConfirmationForm from "../../../../components/confirmation-form/ConfirmationForm";
import OrgMediaTableWithActions from "./OrgMediaTableWithActions";
import CampaignMediaTableWithActions from "./CampaignMediaTableWithActions";
import CampaignBudgetExceedForm from "../../../components/campaign/CampaignBudgetExceedForm";
import CampaignMediaConfirmSelectionForm from "../../../components/campaign/CampaignMediaConfirmSelectionForm";

/**
 * Page
 */
function CampaignPlanCityviewPage() {
  const dispatch = useDispatch();
  const { campaignId: id, cityId } = useParams();

  // State
  const [selectedMediaSites, setSelectedMediaSites] = useState([]);
  const [inventoryToShow, setInventoryToShow] = useState(
    MediaInventoryOptions.org
  );

  // Selector State
  const citySummaryLoading = useSelector(
    (state) => state.campaignCityReport.citySummaryLoading
  );
  const { campaign = {}, summary: citySummary = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );

  const { targetGroupId = "", budget = 0, spend = 0 } = campaign;

  // CityMedia and Data
  const cityMediaLoading = useSelector(
    (state) => state.campaignMedia.cityLoading
  );
  const cityMediaSummary = useSelector(
    (state) => state.campaignMedia.citySummary
  );
  const cityMediaSummaryResult = cityMediaSummary.result || {};
  const { items = [] } = cityMediaSummaryResult;

  // Confirmation Form :: [true/false and dataObject]
  const openConfirmationForm = useSelector(
    (state) => state.confirmationModal.openModal
  );
  const processDataObject = useSelector(
    (state) => state.confirmationModal.processDataObject
  );

  // loader for adding media sites to campaign
  const selectedSitesLoading = useSelector(
    (state) => state.exploreSitesSelection.selectedSitesLoading
  );

  // list
  const mediaList = useSelector(
    (state) => state.mapViewExploreSites.exploreSites
  );

  // modal state
  const openBudgetExceedForm = useSelector(
    (state) => state.campaignBudget.openModal
  );
  const budgetExceedProcessDataObject = useSelector(
    (state) => state.campaignBudget.processDataObject
  );
  const {
    campaignMediaBean,
    setIsSiteAdded,
    remainingBudget: exceedingBudget,
  } = budgetExceedProcessDataObject;

  // confirm media selection modal state
  const openConfirmMediaSelectionForm = useSelector(
    (state) => state.campaignMediaSelection.openModal
  );

  // Link to pass in CampaignSidebar
  const redirectLinkObj = {
    campaignOverviewLink: RedirectTo.campaignPlanOverviewPageUrl.replace(
      ":campaignId",
      id
    ),
    citySummaryLink: RedirectTo.campaignPlanCityViewPageUrl.replace(
      ":campaignId",
      id
    ),
  };

  const cityInfo = {
    cityId: cityId,
    cityName: citySummary?.cityName || cityId,
  };

  const pageNumber = defaultPagination.pageNo;
  const pageSize = defaultPagination.pageSizeMax;

  const user = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  const { orgId } = user;

  const isOrgMedia = true;

  // checking key to show which media inventory : campaign or org
  const isShowOrgMediaInventory = inventoryToShow === MediaInventoryOptions.org;

  // useEffect
  useEffect(() => {
    setSelectedMediaSites([]);
    dispatch(
      getMapViewExploreSites(cityId, isOrgMedia, orgId, pageNumber, pageSize)
    );
    dispatch(getCityOverview(id, cityId));
    dispatch(getCampaignMediaByCity(id, cityId, pageNumber, pageSize));
  }, [dispatch, id, cityId]);

  useEffect(() => {
    dispatch(getCityOverview(id, cityId));
    setSelectedMediaSites([]);
  }, [items.length]);

  // This is passed in "ConfirmAction Form" to Remove selected media-site
  function removeMediaSiteFn() {
    dispatch(removeMediaSite(id, processDataObject.campaignMediaInfo));
  }

  // handle budget exceed submit
  function handleBudgetExceed() {
    setIsSiteAdded(true);
    setSelectedMediaSites((prev) => [...prev, campaignMediaBean]);
  }

  // link to city-specific-Map-view
  const campaignPlanMapViewPageUrl = RedirectTo.campaignPlanMapViewPageUrl
    .replace(":campaignId", id)
    .replace(":cityId", cityId);

  const pageTitle = campaign.title + " :: " + cityInfo.cityName;
  const pageHeaderAction = (
    <>
      <button
        className="btn btn-primary rounded-lg px-3 mr-3 shadow-none"
        onClick={() => dispatch(openConfirmCampaignMediaSelectionForm())}
        disabled={selectedMediaSites.length === 0 || selectedSitesLoading}
        data-toggle="modal"
        data-target={`#${FormDataTargets.confirmCampaignMediaSelection}`}
      >
        {selectedSitesLoading ? "Adding Media..." : "Add To Campaign"}
        {selectedSitesLoading && (
          <Spinner className="ml-2 spinner-border-sm text-light" />
        )}
      </button>
      <Link
        className="btn btn-primary rounded-lg px-3 shadow-none"
        to={{
          pathname: constructRedirectPath(campaignPlanMapViewPageUrl),
          state: { targetGroupId },
        }}
      >
        Select Media on Map
      </Link>
    </>
  );

  //checks for page loading
  if (citySummaryLoading || cityMediaLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <>
      {/* Sidebar */}
      <CampaignSidebar
        campaign={campaign}
        citySelected={cityInfo}
        redirectLinkObj={redirectLinkObj}
      />

      {/* Main Content */}
      <div className="content-wrapper">
        {/* Page Header */}
        <PageHeader title={pageTitle} actions={pageHeaderAction} />

        {/* Page Content */}
        <div className="page-content">
          {/* Performance Card */}
          <CampaignCityPerformance
            citySummary={citySummary}
            budget={budget}
            spend={spend}
          />

          {/* Org Media Table And Actions */}
          {isShowOrgMediaInventory && (
            <OrgMediaTableWithActions
              mediaList={mediaList}
              campaignMediaSites={items}
              selectedMediaSites={selectedMediaSites}
              setSelectedMediaSites={setSelectedMediaSites}
              isOrgMedia={isOrgMedia}
              inventoryToShow={inventoryToShow}
              setInventoryToShow={setInventoryToShow}
            />
          )}

          {/* Campaign Media Table And Actions */}
          {!isShowOrgMediaInventory && (
            <CampaignMediaTableWithActions
              mediaList={items}
              inventoryToShow={inventoryToShow}
              setInventoryToShow={setInventoryToShow}
            />
          )}

          {/** Modals */}
          {openConfirmationForm && (
            <ConfirmationForm onClick={removeMediaSiteFn} />
          )}

          {/* Campaign Budget Exceed */}
          {openBudgetExceedForm && (
            <CampaignBudgetExceedForm
              exceedingBudget={exceedingBudget}
              handleSubmit={handleBudgetExceed}
            />
          )}

          {/* Confirm Campaign Media Selection */}
          {openConfirmMediaSelectionForm && (
            <CampaignMediaConfirmSelectionForm
              selectedMediaSites={selectedMediaSites}
              budget={budget}
              spend={spend}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default CampaignPlanCityviewPage;
