export const OrgMedia = {
  // get Org Media Info
  GET_ORG_MEDIA_INFO: "GET_ORG_MEDIA_INFO",
  GET_ORG_MEDIA_INFO_SUCCESS: "GET_ORG_MEDIA_INFO_SUCCESS",
  GET_ORG_MEDIA_INFO_FAILURE: "GET_ORG_MEDIA_INFO_FAILURE",

  // Add Org Media Images
  ADD_ORG_MEDIA_IMAGES: "ADD_ORG_MEDIA_IMAGES",
  ADD_ORG_MEDIA_IMAGES_SUCCESS: "ADD_ORG_MEDIA_IMAGES_SUCCESS",
  ADD_ORG_MEDIA_IMAGES_FAILURE: "ADD_ORG_MEDIA_IMAGES_FAILURE",
};
