import React from "react";
import { Route } from "react-router-dom";

// Components
import AppThemes from "./theme/AppThemes";
import TopNav from "./components/top-nav/TopNav";

/**
 * Routes which doesn't need USER Authentication.
 */
function PublicRoute(props) {
  // Input props
  const {
    component: PublicComponent,
    sidebar = false,
    topNav = true,
    ...rest
  } = props;

  // Deployed Theme
  const deployTheme = AppThemes.getTheme();

  // Layout classes
  const sidebarCls = sidebar ? "layout-sidebar" : "";
  const topNavCls = topNav ? "layout-top-nav" : "";

  return (
    <Route
      {...rest}
      render={(props) => (
        <div
          className={`page ${sidebarCls} ${topNavCls}`}
          data-theme={deployTheme.id}
        >
          {/* Main Header */}
          {topNav && <TopNav user={{}} isUserAuthenticated={false} />}

          {/* Sidebar and Main Content */}
          <div className="main-content">
            <PublicComponent {...props} />
          </div>
        </div>
      )}
    />
  );
}

export default PublicRoute;
