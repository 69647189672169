/* eslint-disable no-case-declarations */
import { ActionTypes } from "../constants/ActionConstants";

const initialState = {
  // download-temp
  downloadLoading: false,
  downloadError: "",

  // upload-temp
  uploadLoading: false,
  uploadError: {},
  campaignData: {},
  mediaCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // download-teplate
    case ActionTypes.SiteUpload.DOWNLOAD_TEMPLATE:
      return {
        ...state,
        downloadLoading: true,
      };

    case ActionTypes.SiteUpload.DOWNLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadLoading: false,
      };

    case ActionTypes.SiteUpload.DOWNLOAD_TEMPLATE_FAILURE:
      return {
        ...state,
        downloadLoading: false,
        downloadError: action.payload,
      };

    // upload-template
    case ActionTypes.SiteUpload.UPLOAD_TEMPLATE:
      return {
        ...state,
        uploadLoading: true,
        uploadError: {},
      };

    case ActionTypes.SiteUpload.UPLOAD_TEMPLATE_SUCCESS:
      const campaignId = action.campaignId;
      const data = action.payload.data;
      const mediaCount = campaignId ? data.length : data.mediaCount;
      return {
        ...state,
        uploadLoading: false,
        campaignData: data,
        uploadError: {},
        mediaCount: mediaCount,
      };

    case ActionTypes.SiteUpload.UPLOAD_TEMPLATE_FAILURE:
      const excelError = action.payload.response.data;
      return {
        ...state,
        uploadLoading: false,
        uploadError: excelError,
        campaignData: {},
        mediaCount: 0,
      };

    case ActionTypes.SiteUpload.RESET_SITE_UPLOAD_STATE:
      return {
        ...state,
        uploadLoading: false,
        campaignData: {},
        uploadError: {},
        mediaCount: 0,
      };

    default:
      return state;
  }
};
