import React from "react";
import { Link } from "react-router-dom";

// Constants
import { DispatchFrom } from "../../constants/GeneralConstants";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// this component is to redirect user to same page from where user came,
// like from "/home" or "/city-summary-page"
function CloseSiteUpload({ campaignId, cityId, pageFrom }) {
  // Urls
  const homeUrl = RedirectTo.homeUrl;
  const campaignCityViewPageUrl = RedirectTo.campaignCityViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);

  const monitorCityViewPageUrl = RedirectTo.monitorCityViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);

  // because we have different link for "camp-analyser" and "monitoring" side
  const redirectLink =
    pageFrom === DispatchFrom.campaignAnalyser
      ? campaignCityViewPageUrl
      : monitorCityViewPageUrl;

  const link = campaignId && cityId ? redirectLink : homeUrl;

  return (
    <Link to={link}>
      <button type="button" className="close" aria-label="Close">
        x
      </button>
    </Link>
  );
}

export { CloseSiteUpload };
