import {
  getSegmentOtsInfoUrl,
  getLocationOtsInfoUrl,
  getSegmentOtsAndReachInfoUrl,
} from "../../urls/mavin-tools/OtsToolURL";
import { sendCreateOrUpdateRequest } from "../../utils/api/apiUtils";

// By Segment Id
export async function getSegmentOtsInfoFn(
  id,
  isOneWaySegment,
  lanesCount,
  type
) {
  // Request Headers
  const headerParams = {
    "Content-Type": "application/json",
  };

  const body = {
    roadId: id,
    type: type,
    lanes: Number(lanesCount),
    isOneWay: isOneWaySegment,
    durationInDays: 30,
  };

  return sendCreateOrUpdateRequest(
    getSegmentOtsInfoUrl,
    body,
    true,
    "POST",
    headerParams
  );
}

// By Segment Id,for "OTS" tool new methodology
export async function getSegmentOtsAndReachInfoFn(
  roadId,
  targetGroupId,
  startTS,
  duration
) {
  // Request Headers
  const headerParams = {
    "Content-Type": "application/json",
  };

  const body = {
    roadId,
    targetGroupId,
    startTS,
    durationInDays: duration,
  };

  return sendCreateOrUpdateRequest(
    getSegmentOtsAndReachInfoUrl,
    body,
    true,
    "POST",
    headerParams
  );
}

// By Location (lat-lng)
export async function getLocationOtsInfoFn(latitude, longitude) {
  // Request Headers
  const headerParams = {
    "Content-Type": "application/json",
  };

  const body = {
    lit: "Front",
    tilt: "",
    latitude,
    longitude,
  };

  return sendCreateOrUpdateRequest(
    getLocationOtsInfoUrl,
    body,
    true,
    "POST",
    headerParams
  );
}
