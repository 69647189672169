import { CampaignMediaSelection } from "../../action-constants/CampaignMediaSelectionActionConstants";

const initialState = {
  openModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CampaignMediaSelection.OPEN_CONFIRM_CAMPAIGN_MEDIA_SELECTION_MODAL: {
      return {
        ...state,
        openModal: true,
      };
    }

    case CampaignMediaSelection.CLOSE_CONFIRM_CAMPAIGN_MEDIA_SELECTION_MODAL:
      return {
        ...state,
        openModal: false,
      };

    default:
      return state;
  }
};
