import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Circle } from "react-leaflet";

// Actions
import { getRegionDataByCity } from "../../actions/regions/RegionActions";
import {
  getSellerMediaByCity,
  setSellerMediaAppliedFilters,
} from "../../actions/seller/SellerMediaActions";

// Utils & Constants
import { filterMedia, getMediaIcon } from "../../utils/MediaUtils";
import {
  DefaultFilterObjects,
  MapZoom,
  MediaColor,
} from "../../constants/GeneralConstants";
import { getSellerId } from "../../utils/SellerUtils";
import { InfluenceCircleStyles } from "../../constants/CssConstants";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Components
import {
  ImpressionsCell,
  InfoCell,
  LtsCell,
} from "../../components/campaign-media-table-row/MediaRow";
import LLMap from "../../components/map/leaflet-map/LLMap";
import MediaFilterLocal from "../../components/media-filter/MediaFilter";
import SearchInput from "../../components/search-input/SearchInput";
import Spinner from "../../components/spinner/Spinner";
import TableHeaders from "../../components/table/TableHeaders";
import PageHeader from "../../mavin/components/page-header/PageHeader";
import MapMediaMarkerPopup from "../../components/map/markers/MapMediaMarkerPopup";
import { SelectedFilter } from "../map-view/media-sites/MediaSitesUtils";

// Page Constants

export const tableHeaders = [
  {
    title: {
      displayName: "Site Name",
      className: "col-6",
    },
    subTitle: {
      displayName: "Media type, Dimensions, Lighting, Region",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "Impressions",
      className: "col-3 text-center",
    },
  },

  {
    title: {
      displayName: "LTS",
      className: "col-3 text-center",
    },
  },
];

function MapViewSection({ regionData, mediaList, influenceRadius }) {
  // Center
  const { latitude, longitude } = regionData.center || {};
  const center = [latitude, longitude];
  const zoom = MapZoom.zoomLevel11;

  if (!latitude || !longitude) {
    return null;
  }

  // SellerMedia Marker Color
  const sellerMediaIcon = getMediaIcon(MediaColor.inventory);

  return (
    <LLMap center={center} zoom={zoom}>
      {/* Marker */}
      <MapMediaMarkerPopup mediaList={mediaList} icon={sellerMediaIcon} />

      {/* Media influence circle markers */}
      {mediaList.map((media, i) => (
        <Circle
          key={i}
          center={[media.latitude, media.longitude]}
          pathOptions={InfluenceCircleStyles.media}
          radius={influenceRadius}
        />
      ))}
    </LLMap>
  );
}

function SellerMediaTableRow({ media }) {
  // Redirect Url
  const sellerMediaViewPageUrl = RedirectTo.sellerMediaDetailPageUrl
    .replace(":mediaId", media.id)
    .replace(":sellerId", media.sellerId);

  return (
    <tr>
      <InfoCell
        mediaElementStyle={"p-2"}
        media={media}
        redirectUrl={sellerMediaViewPageUrl}
      />

      <ImpressionsCell
        impressionsElementStyle={"p-2 text-center align-middle"}
        media={media}
      />

      <LtsCell ltsElementClass={"p-2 align-middle text-center"} media={media} />
    </tr>
  );
}

function SellerMediaTable({ mediaList }) {
  if (mediaList.length < 1) {
    return null;
  }

  return (
    <div className="table-responsive">
      <table className="table table-media">
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />

        <tbody>
          {mediaList.map((media) => (
            <SellerMediaTableRow key={media.id} media={media} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function SearchAndFilterSection({
  mediaList,
  setSellerMediaList,
  setInfluenceRadius,
}) {
  const dispatch = useDispatch();

  // State
  // filter and search
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState(DefaultFilterObjects.media);

  // Selector
  const sellerMediaFilterObj = useSelector(
    (state) => state.sellerMedia.sellerMediaFilterObj
  );

  useEffect(() => {
    doSearchAndFilter(searchQuery, sellerMediaFilterObj);
  }, [mediaList.length]);

  function doSearchAndFilter(searchedText, appliedFilters) {
    // updating searched text
    setSearchQuery(searchedText);

    // updating filter state
    dispatch(setSellerMediaAppliedFilters(appliedFilters));

    // updating filter state
    setFilters(appliedFilters);

    const filtersObj = { ...appliedFilters };

    // search will be executed when searchedText length is greater than 1
    if (searchedText.length > 1) {
      filtersObj.title = searchedText;
    }

    const filteredMedia = filterMedia(mediaList, filtersObj);
    setSellerMediaList(filteredMedia);
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        {/* Search input */}
        <div className="col-4 px-0">
          <SearchInput
            placeholder={"Search media sites inventory"}
            className={"shadow-none rounded-lg"}
            searchButton={false}
            onTextChange={({ target }) =>
              doSearchAndFilter(target.value, filters)
            }
          />
        </div>

        <div className="d-flex text-right">
          {/* Influence Circle Radius Selector */}
          <div className="border border-secondary rounded-lg d-flex align-items-center">
            <span className="text-secondary px-2">Influence Radius</span>
            <select
              className="btn pl-1 text-primary p-0 shadow-none"
              onClick={({ target }) => setInfluenceRadius(target.value)}
            >
              <option value="500">500 Metres</option>
              <option value="1000">1 KM</option>
              <option value="1500">1.5 KMs</option>
            </select>
          </div>

          {/* Filter */}
          <MediaFilterLocal
            onFiltersApplied={(appliedFilters) =>
              doSearchAndFilter(searchQuery, appliedFilters)
            }
            className={"rounded-lg shadow-none ml-2"}
            mediaFilterObj={filters}
          />
        </div>
      </div>

      {/* Shows selected media types */}
      <SelectedFilter filters={sellerMediaFilterObj} />
    </>
  );
}

/**
 * Main Page
 */
function SellerInventoryMapViewPage() {
  // Dispatch
  const dispatch = useDispatch();
  const { cityId } = useParams();

  // State
  const [sellerMediaList, setSellerMediaList] = useState([]);
  // influence radius
  const [influenceRadius, setInfluenceRadius] = useState("500");

  // Selector
  const mediaList = useSelector(
    (state) => state.sellerMedia.sellerMediaByCityList
  );

  const regionData = useSelector((state) => state.region.regionData);

  const regionDataLoading = useSelector(
    (state) => state.region.regionDataLoading
  );

  const mediaListLoading = useSelector(
    (state) => state.sellerMedia.sellerMediaByCityLoading
  );

  // Seller Id
  const sellerId = getSellerId();

  useEffect(() => {
    dispatch(getSellerMediaByCity(cityId, sellerId));
    dispatch(getRegionDataByCity(cityId));
  }, [dispatch, cityId]);

  // Checks for page loading
  if (mediaListLoading || regionDataLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  // City Name
  const { name } = regionData;
  const pageTitle = `Inventory :: ${name}`;

  return (
    <div className="content-wrapper map-content-wrapper">
      {/* Map Left Section */}
      <div className="map-layout-left">
        <PageHeader title={pageTitle} shadow={false} />
        <hr className="divider my-3 row"></hr>

        <SearchAndFilterSection
          mediaList={mediaList}
          setSellerMediaList={setSellerMediaList}
          setInfluenceRadius={setInfluenceRadius}
        />
        <hr className="divider my-3 row"></hr>

        {/* No media found message */}
        {sellerMediaList.length < 1 && (
          <p className="text-center mt-5 font-italic">No media found</p>
        )}

        {/* Media Table */}
        <SellerMediaTable mediaList={sellerMediaList} />
      </div>

      {/* Map Right Section */}
      <div className="map-layout-right bg-alt">
        <MapViewSection
          regionData={regionData}
          mediaList={mediaList}
          influenceRadius={influenceRadius}
        />
      </div>
    </div>
  );
}

export default SellerInventoryMapViewPage;
