import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Circle } from "react-leaflet";

// Utils and Constants
import { getMediaIcon } from "../../../utils/MediaUtils";
import { InfluenceCircleStyles } from "../../../constants/CssConstants";
import {
  LocalStorageKeys,
  MarkerZIndexOffsetOptions,
  OrganizationIdsMapping,
  PatrikaSellerId,
} from "../../../constants/GeneralConstants";
import { getItem } from "../../../utils/localstorage";
import { checkIsOrgSpecific } from "../../../utils/OrgUtils";

// Components
import MapMediaMarkerPopup from "../../../components/map/markers/MapMediaMarkerPopup";

/**
 * Media Site Markers
 */
function SiteMarkers({ isOrgMedia = false }) {
  // MediaList
  const campaignMediaList = useSelector(
    (state) => state.mapViewMediaSites.filteredMediaSites
  );
  const inventoryMediaList = useSelector(
    (state) => state.mapViewExploreSites.exploreSites
  );

  // MetaData
  const metaData = useSelector((state) => state.mapMetaData.metaData);

  const mediaTypeToShow = useSelector(
    (state) => state.legendAndInfluence.mediaTypeToShow
  );
  const isShowInventoryMedia = mediaTypeToShow?.inventoryMedia; // true/false
  const isShowCampaignMedia = mediaTypeToShow?.campaignMedia; // true/false

  // to show/hide ==> media-influence-marker
  const showMediaInfluenceCircle = useSelector(
    (state) => state.legendAndInfluence.showMediaInfluenceCircle
  );
  // true/false
  const mediaInfluenceCircleRadius = useSelector(
    (state) => state.legendAndInfluence.mediaInfluenceCircleRadius
  );

  const inventoryMediaFillColor = useSelector(
    (state) => state.mapViewExploreSites.inventoryMediaFillColor
  );
  const campaignMediaFillColor = useSelector(
    (state) => state.mapViewMediaSites.campaignMediaFillColor
  );

  // CampaignMedia Icon Marker
  const campaignMediaIcon = getMediaIcon(campaignMediaFillColor);
  // InventoryMedia Icon Marker
  const inventoryMediaIcon = getMediaIcon(inventoryMediaFillColor, isOrgMedia);

  // for patrika
  const user = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  const { orgId } = user;
  const { patrika } = OrganizationIdsMapping;
  const isPatrikaSpecific = checkIsOrgSpecific(orgId, patrika);

  const getPatrikaInventory = useCallback(
    (mediaList) => {
      return mediaList.filter((media) => media.sellerId === PatrikaSellerId);
    },
    [isPatrikaSpecific]
  );

  const filteredMediaList = isPatrikaSpecific
    ? getPatrikaInventory(inventoryMediaList)
    : inventoryMediaList;

  return (
    <>
      {/* Markers for "Inventory Media Sites" */}
      {isShowInventoryMedia && (
        <MapMediaMarkerPopup
          mediaList={filteredMediaList}
          icon={inventoryMediaIcon}
          propsToShow={metaData}
        />
      )}

      {/* InventoryMedia influence circle markers */}
      {showMediaInfluenceCircle &&
        isShowInventoryMedia &&
        filteredMediaList.map((media, i) => (
          <Circle
            key={i}
            center={[media.latitude, media.longitude]}
            pathOptions={InfluenceCircleStyles.media}
            radius={mediaInfluenceCircleRadius}
          />
        ))}

      {/* Markers for "Campaign Media Sites */}
      {isShowCampaignMedia && (
        <MapMediaMarkerPopup
          mediaList={campaignMediaList}
          icon={campaignMediaIcon}
          propsToShow={metaData}
          zIndexOffset={MarkerZIndexOffsetOptions.Max}
        />
      )}

      {/* CampaignMedia influence circle markers */}
      {showMediaInfluenceCircle &&
        isShowCampaignMedia &&
        campaignMediaList.map((media, i) => (
          <Circle
            key={i}
            center={[media.latitude, media.longitude]}
            pathOptions={InfluenceCircleStyles.media}
            radius={mediaInfluenceCircleRadius}
          />
        ))}
    </>
  );
}

export default SiteMarkers;
