import { useRef } from "react";

function LitStatus({ litIndex, setDayCountAndLit, dayCountAndLitIndex }) {
  const litStatus = ["Lit", "Not Lit"];

  function handleLitStatus(lit, index) {
    setDayCountAndLit((prev) => ({
      ...prev,
      lit,
    }));

    dayCountAndLitIndex.current.litIndex = index;
  }

  return (
    <div className="pl-4">
      <label className="d-block">Site Lighting</label>
      <div className="btn-group" role="group">
        {litStatus.map((status, index) => (
          <button
            type="button"
            key={index}
            id={index}
            className={`btn border px-3 ${
              litIndex === index && "btn-primary"
            } shadow-none`}
            onClick={() => handleLitStatus(status, index)}
          >
            {status}
          </button>
        ))}
      </div>
    </div>
  );
}

function DaySelector({
  dayCountIndex,
  setDayCountAndLit,
  dayCountAndLitIndex,
}) {
  const performanceDays = ["1", "7", "15", "21", "30"];

  function handleSelectDay(day, index) {
    setDayCountAndLit((prev) => ({
      ...prev,
      dayCount: day,
    }));

    dayCountAndLitIndex.current.dayCountIndex = index;
  }

  return (
    <div>
      <label className="d-block">Site Performance for the last</label>
      <div className="btn-group" role="group">
        {performanceDays.map((day, index) => (
          <button
            type="button"
            key={index}
            id={index}
            className={`btn border px-3 ${
              dayCountIndex === index && "btn-primary"
            } shadow-none`}
            onClick={() => handleSelectDay(day, index)}
          >
            {`${day} days`}
          </button>
        ))}
      </div>
    </div>
  );
}

/**
 * Page Section
 */
function DayAndLitContainer({ setDayCountAndLit }) {
  const dayCountAndLitIndex = useRef({
    dayCountIndex: 0,
    litIndex: 0,
  });

  const { dayCountIndex, litIndex } = dayCountAndLitIndex.current;

  return (
    <>
      <div className="d-flex p-3">
        <DaySelector
          dayCountIndex={dayCountIndex}
          setDayCountAndLit={setDayCountAndLit}
          dayCountAndLitIndex={dayCountAndLitIndex}
        />
        <LitStatus
          litIndex={litIndex}
          setDayCountAndLit={setDayCountAndLit}
          dayCountAndLitIndex={dayCountAndLitIndex}
        />
      </div>
      <hr className="divider m-0"></hr>
    </>
  );
}

export default DayAndLitContainer;
