//Utils
import { sendGetRequest } from "../utils/api/apiUtils";

//Constants
import { getBuyersListUrl, getusersByBuyerIdUrl } from "../urls/BuyerURL";

export async function getBuyersListFn() {
  return sendGetRequest(getBuyersListUrl);
}

export async function getUsersByBuyerIdFn(buyerId) {
  const url = getusersByBuyerIdUrl.replace(":buyerId", buyerId);
  return sendGetRequest(url);
}
