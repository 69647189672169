import { useMapEvents } from "react-leaflet";

/**
 * Map Dragged And Zoomed
 */
export default function MapDraggedAndZoomed({
  setMapCenter = () => {},
  setMapZoom = () => {},
}) {
  // map events
  const mapEvents = useMapEvents({
    zoomend: () => {
      // Zoom
      setMapZoom(mapEvents.getZoom());

      // Center
      const { lat, lng } = mapEvents.getCenter();
      setMapCenter([lat, lng]);
    },

    dragend: () => {
      // Center
      const { lat, lng } = mapEvents.getCenter();
      setMapCenter([lat, lng]);
    },
  });

  return null;
}
