import { CampaignPlanning } from "../../constants/UrlConstants";
import {
  getTgSpecificOtsForCity,
  getTgSpecificOtsForSegmentUrl,
} from "../../urls/TgURL";
import { sendGetRequest } from "../api/apiUtils";

function getQueryParamsForTgSpecificOts(stretchIds, segmentIds, mediaIds) {
  const params = {};

  // If stretchIds present then add to the query params
  if (stretchIds.length > 0) {
    const stretchIdsString = stretchIds.join();
    params.roadStretchIds = stretchIdsString;
  }

  // If segmentIds present then add to the query params
  if (segmentIds.length > 0) {
    const segmentIdsString = segmentIds.join();
    params.roadSegmentIds = segmentIdsString;
  }

  // If mediaIds present then add to the query params
  if (mediaIds.length > 0) {
    const mediaIdsString = mediaIds.join();
    params.mediaIds = mediaIdsString;
  }

  return params;
}

export async function getTgOtsOfRoadStretchesOfCity(tgId, cityId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    _city: cityId,
  };

  const url = getTgSpecificOtsForCity.replace(":tgId", tgId);

  return sendGetRequest(url, params, true, headerParams);
}

export async function getTgSpecificOtsData(
  tgId,
  stretchIds,
  segmentIds,
  mediaIds
) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = getQueryParamsForTgSpecificOts(
    stretchIds,
    segmentIds,
    mediaIds
  );

  const url = CampaignPlanning.getTgSpecificOts.replace(":tgId", tgId);

  return sendGetRequest(url, params, true, headerParams);
}

export async function getTgSpecificOtsForSegmentFn(tgId, segmentId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getTgSpecificOtsForSegmentUrl
    .replace(":tgId", tgId)
    .replace(":segmentId", segmentId);

  return sendGetRequest(url, true, headerParams);
}

export async function getSegmentMediaDetails(segmentIds) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const roadSegmentIds = segmentIds.join();
  const params = {
    roadSegmentIds: roadSegmentIds,
  };

  return sendGetRequest(
    CampaignPlanning.getSegmentMedia,
    params,
    true,
    headerParams
  );
}

export async function getCampaignPlanMedia(mediaIds, campaignId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const campaignPlanMediaIds = mediaIds.join();
  const params = {
    mediaIds: campaignPlanMediaIds,
  };

  const url = CampaignPlanning.getCampaignPlanMedia.replace(
    ":campaignId",
    campaignId
  );

  return sendGetRequest(url, params, true, headerParams);
}
