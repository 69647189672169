import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import jwt_decode from "jwt-decode";

// Utils and constants
import { getItem } from "./utils/localstorage";
import { LocalStorageKeys } from "./constants/GeneralConstants";

// Components
import AppThemes from "./theme/AppThemes";
import TopNav from "./components/top-nav/TopNav";

// Private Methods
// --------------------------------------------------------------------------

function _getUserFromLocalStorage() {
  // Get UserInfo
  const exUser = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  return exUser;
}

function _isTokenExpired(token) {
  const decodedJwt = jwt_decode(token);
  decodedJwt.exp * 1000 < Date.now();
}

function _isUserAuthenticated(user) {
  if (Object.keys(user).length < 1) {
    return false;
  }
  const userRoles = user.roles || [];
  const isUserAuthenticated =
    user.token && userRoles.length > 0 && !_isTokenExpired(user.token);
  return isUserAuthenticated;
}

/**
 * Routes which needs the USER Authenticated.
 */
function ProtectedRoute(props) {
  // Input props
  const {
    component: ProtectedComponent,
    sidebar = false,
    topNav = true,
    ...rest
  } = props;

  // Get Authenticated User from Global(redux) State
  const authUser = useSelector((state) => state.auth.authUser);
  const user =
    Object.keys(authUser).length > 0
      ? authUser
      : _getUserFromLocalStorage() || {};

  if (!_isUserAuthenticated(user)) {
    localStorage.clear();
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { redirectURL: props.location },
        }}
      />
    );
  }

  // User Org settings
  const org = user.org || {};
  const orgThemeId = org.themeId || "default";

  // Theme
  const deployTheme = AppThemes.getTheme();
  const deployThemeId = deployTheme.id;

  const finalThemeId = deployThemeId === "default" ? orgThemeId : deployThemeId;

  // Layout classes
  const sidebarCls = sidebar ? "layout-sidebar" : "";
  const topNavCls = topNav ? "layout-top-nav" : "";

  return (
    <Route
      {...rest}
      render={(props) => (
        <div
          className={`page ${sidebarCls} ${topNavCls}`}
          data-theme={finalThemeId}
        >
          {/* Main Header */}
          {topNav && <TopNav user={user} isUserAuthenticated={true} />}

          {/* Sidebar and Main Content */}
          <div className="main-content">
            <ProtectedComponent {...props} />
          </div>

          {/* Page level container for Toasts */}
          <ToastContainer autoClose={5000} />
        </div>
      )}
    />
  );
}

export default ProtectedRoute;
