import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../constants/ActionConstants";
import { saveFile } from "../common-utils/file-utils/FileUtils";
import { getErrorMessage } from "../utils/util";
import { toast } from "react-toastify";
import {
  downloadTemplateData,
  uploadExcelTemplate,
  uploadMediaFileFromCampaignContext,
} from "../apis/SiteUploadAPI";

export function* downloadTemplate(action) {
  const { fileName } = action.payload;

  try {
    const response = yield downloadTemplateData();
    saveFile(fileName, response);

    yield put({
      type: ActionTypes.SiteUpload.DOWNLOAD_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.SiteUpload.DOWNLOAD_TEMPLATE_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* uploadTemplate(action) {
  const { campaignId, files, isMonitoring, tgId = "" } = action.payload;

  const formData = new FormData();
  formData.append("file", files[0]);
  if (tgId) {
    formData.append("targetGroupId", tgId);
  }

  try {
    const data = campaignId
      ? yield uploadMediaFileFromCampaignContext(
          campaignId,
          formData,
          isMonitoring
        )
      : yield uploadExcelTemplate(formData, isMonitoring);

    yield put({
      type: ActionTypes.SiteUpload.UPLOAD_TEMPLATE_SUCCESS,
      payload: { data },
      campaignId: campaignId,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.SiteUpload.UPLOAD_TEMPLATE_FAILURE,
      payload: error,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.SiteUpload.DOWNLOAD_TEMPLATE, downloadTemplate),
    takeLatest(ActionTypes.SiteUpload.UPLOAD_TEMPLATE, uploadTemplate),
  ]);
}
