import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../../constants/ActionConstants";
import { getCampaign, updateCampaignState } from "../../apis/CampaignAPI";
import { getCampaignList } from "../../apis/CampaignAPI";
import { getErrorMessage } from "../../utils/util";

export function* getCampaignBasicInfo(action) {
  const { campaignId } = action.payload;
  try {
    const campaignDetails = yield getCampaign(campaignId);

    yield put({
      type: ActionTypes.CampaignDetails.GET_CAMPAIGN_BASIC_INFO_SUCCESS,
      payload: { campaignDetails },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignDetails.GET_CAMPAIGN_BASIC_INFO_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* updateState(action) {
  const { campaignId, state } = action.payload;
  try {
    yield updateCampaignState(campaignId, state);
    yield put({
      type: ActionTypes.CampaignDetails.UPDATE_CAMPAIGN_STATE_SUCCESS,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignDetails.UPDATE_CAMPAIGN_STATE_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* getCampList() {
  try {
    const result = yield getCampaignList();

    yield put({
      type: ActionTypes.CampaignDetails.GET_CAMPAIGN_LIST_SUCCESS,
      payload: { campaignList: result.items },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignDetails.GET_CAMPAIGN_LIST_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.CampaignDetails.GET_CAMPAIGN_BASIC_INFO,
      getCampaignBasicInfo
    ),

    takeLatest(ActionTypes.CampaignDetails.UPDATE_CAMPAIGN_STATE, updateState),
    takeLatest(ActionTypes.CampaignDetails.GET_CAMPAIGN_LIST, getCampList),
  ]);
}
