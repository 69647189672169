import { ActionTypes } from "../../../constants/ActionConstants";
import { RoadSegment } from "../../../constants/action-constants/RoadSegmentActionConstants";

//initial state
const initialState = {
  openModal: false,

  // RoadSegment States
  roadSegments: [],
  getRsErr: "",
  getRsLoading: false,
  roadSegmentIds: [],

  // Exact Lat Long States
  exactLatLong: [],
};

/**
 * This function updates the segments trace to be able to
 * display on the map..
 * @param {*} roadSegments
 */
function updateSegmentTrace(roadSegments) {
  roadSegments.map((eachSegment) => {
    const trace = eachSegment.trace.map((eachCoordinate) => [
      eachCoordinate.latitude,
      eachCoordinate.longitude,
    ]);
    eachSegment.trace = trace;
  });
  return roadSegments;
}

/**
 * This function updates the segments ids array
 * @param {*} roadSegments
 */
function getSegmentIds(roadSegments) {
  const ids = roadSegments.map((eachSegment) => eachSegment.id);
  return ids;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PoiForm.OPEN_POI_AND_CAPTIVE_AREA_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case ActionTypes.PoiForm.CLOSE_POI_AND_CAPTIVE_AREA_MODAL:
      return {
        ...state,
        openModal: false,
      };

    case ActionTypes.PoiForm.GET_CLOSEST_ROAD_SG:
      return {
        ...state,
        getRsLoading: true,
      };

    case ActionTypes.PoiForm.GET_CLOSEST_ROAD_SG_SUCCESS:
      return {
        ...state,
        roadSegments: updateSegmentTrace(action.payload.roadSegments),
        exactLatLong: [],
        roadSegmentIds: getSegmentIds(action.payload.roadSegments),
        getRsLoading: false,
      };

    case ActionTypes.PoiForm.GET_CLOSEST_ROAD_SG_FAILURE:
      return {
        ...state,
        getRsLoading: false,
        getRsErr: action.payload,
      };

    case ActionTypes.ExactLatLong.GET_EXACT_LAT_LONG: {
      const locationStr = action.payload.locationStr;
      const latLongArray =
        locationStr.length > 0
          ? locationStr.split(",").map((i) => Number(i))
          : "";
      return {
        ...state,
        exactLatLong: latLongArray,
        roadSegments: [],
      };
    }

    case RoadSegment.REMOVE_CLOSEST_ROAD_SG:
      return {
        ...state,
        roadSegments: [],
      };

    default:
      return state;
  }
};
