import { Buyer } from "../../constants/action-constants/BuyerActionConstants";

export const getBuyersList = () => ({
  type: Buyer.GET_BUYERS_LIST,
});

export const getUsersByBuyerId = (buyerId) => ({
  type: Buyer.GET_USERS_BY_BUYER_ID,
  payload: { buyerId },
});

export const clearBuyerUsersList = () => ({
  type: Buyer.CLEAR_BUYER_USERS,
});
