import { useHistory } from "react-router-dom";

// Components
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import { continueToPlanning } from "../components/CampaignRedirect";
import {
  CampaignDetails,
  SaveAsDraft,
  Review,
  ContinueEditing,
} from "../components/HeaderNav";

export default function HeaderSection({ campaign, cityId }) {
  const history = useHistory();
  const EnableContinueEditing = isAgencySpecific(ContinueEditing);
  const EnableSaveAsDraft = isAgencySpecific(SaveAsDraft);
  const EnableReview = isAgencySpecific(Review);

  const pageTitle = <CampaignDetails campaign={campaign} />;
  const pageActions = (
    <div className="d-flex ">
      {EnableContinueEditing && (
        <EnableContinueEditing
          onClickAction={() => continueToPlanning(campaign.id, cityId, history)}
        />
      )}
      {EnableSaveAsDraft && <EnableSaveAsDraft />}
      {EnableReview && (
        <EnableReview campaignId={campaign.id} cityId={cityId} />
      )}
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}
