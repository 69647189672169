export const Seller = {
  // Seller summary by media id
  GET_SELLER_SUMMARY_BY_MEDIA_ID: "GET_SELLER_SUMMARY_BY_MEDIA_ID",
  GET_SELLER_SUMMARY_BY_MEDIA_ID_SUCCESS:
    "GET_SELLER_SUMMARY_BY_MEDIA_ID_SUCCESS",
  GET_SELLER_SUMMARY_BY_MEDIA_ID_FAILURE:
    "GET_SELLER_SUMMARY_BY_MEDIA_ID_FAILURE",

  // Seller summary by cityId
  GET_SELLER_SUMMARY_BY_CITY: "GET_SELLER_SUMMARY_BY_CITY",
  GET_SELLER_SUMMARY_BY_CITY_SUCCESS: "GET_SELLER_SUMMARY_BY_CITY_SUCCESS",
  GET_SELLER_SUMMARY_BY_CITY_FAILURE: "GET_SELLER_SUMMARY_BY_CITY_FAILURE",

  // Seller Inventory summary
  GET_SELLER_INVENTORY_SUMMARY: "GET_SELLER_INVENTORY_SUMMARY",
  GET_SELLER_INVENTORY_SUMMARY_SUCCESS: "GET_SELLER_INVENTORY_SUMMARY_SUCCESS",
  GET_SELLER_INVENTORY_SUMMARY_FAILURE: "GET_SELLER_INVENTORY_SUMMARY_FAILURE",

  // Seller Info By ID
  GET_SELLER_INFO_BY_ID: "GET_SELLER_INFO_BY_ID",
  GET_SELLER_INFO_BY_ID_SUCCESS: "GET_SELLER_INFO_BY_ID_SUCCESS",
  GET_SELLER_INFO_BY_ID_FAILURE: "GET_SELLER_INFO_BY_ID_FAILURE",

  // Update Seller Info
  UPDATE_SELLER_INFO: "UPDATE_SELLER_INFO",
  UPDATE_SELLER_INFO_SUCCESS: "UPDATE_SELLER_INFO_SUCCESS",
  UPDATE_SELLER_INFO_FAILURE: "UPDATE_SELLER_INFO_FAILURE",

  // Update Seller Logo
  UPDATE_SELLER_LOGO: "UPDATE_SELLER_LOGO",
  UPDATE_SELLER_LOGO_SUCCESS: "UPDATE_SELLER_LOGO_SUCCESS",
  UPDATE_SELLER_LOGO_FAILURE: "UPDATE_SELLER_LOGO_FAILURE",

  // Seller info by search
  SEARCH_SELLER: "SEARCH_SELLER",
  SEARCH_SELLER_SUCCESS: "SEARCH_SELLER_SUCCESS",
  SEARCH_SELLER_FAILURE: "SEARCH_SELLER_FAILURE",
  //Reset Searched Seller
  CLEAR_SEARCHED_SELLER: "CLEAR_SEARCHED_SELLER",

  // Enable Seller Site
  ENABLE_SELLER_SITE: "ENABLE_SELLER_SITE",
  ENABLE_SELLER_SITE_SUCCESS: "ENABLE_SELLER_SITE_SUCCESS",
  ENABLE_SELLER_SITE_FAILURE: "ENABLE_SELLER_SITE_FAILURE",

  // Disable Seller Site
  DISABLE_SELLER_SITE: "DISABLE_SELLER_SITE",
  DISABLE_SELLER_SITE_SUCCESS: "DISABLE_SELLER_SITE_SUCCESS",
  DISABLE_SELLER_SITE_FAILURE: "DISABLE_SELLER_SITE_FAILURE",
};
