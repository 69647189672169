export const DeviceConstants = [
  { id: "1", label: "Device 1" },
  { id: "2", label: "Device 2" },
  { id: "3", label: "Device 3" },
  { id: "4", label: "Device 4" },
  { id: "5", label: "Device 5" },
  { id: "6", label: "Device 6" },
  { id: "7", label: "Device 7" },
];

export const OtsData = {
  male: 2300854,
  female: 496548,
};

export const ReachData = {
  male: 56300854,
  female: 196548,
};

export const VehicularData = {
  fourWheelers: 896589,
  twoWheelers: 605785905,
  buses: 31735,
  pedestrians: 9438789675,
};

export const VehicularSplitPieChartData = [
  { name: "Four Wheelers", value: 25.5, count: "8,96,589" },
  { name: "Two Wheelers", value: 44.5, count: "60,57,85,905" },
  { name: "Bus", value: 8, count: "31,735" },
  { name: "Pedestrians", value: 22, count: "9,43,87,89,675" },
];

export const HourlyTrafficProfileSplit = [
  {
    "Hour Average": 23.99999999999999,
    "Hour Maximum": 29,
    "Hour Minimum": 67,
    name: "6 am",
  },
  {
    "Hour Average": 67.99999999999999,
    "Hour Maximum": 12,
    "Hour Minimum": 57,
    name: "7 am",
  },
  {
    "Hour Average": 34.99999999999999,
    "Hour Maximum": 10,
    "Hour Minimum": 67,
    name: "8 am",
  },
  {
    "Hour Average": 12.99999999999999,
    "Hour Maximum": 45,
    "Hour Minimum": 38,
    name: "9 am",
  },
  {
    "Hour Average": 23.99999999999999,
    "Hour Maximum": 78,
    "Hour Minimum": 23,
    name: "10 am",
  },
  {
    "Hour Average": 23.99999999999999,
    "Hour Maximum": 49,
    "Hour Minimum": 12,
    name: "11 am",
  },
  {
    "Hour Average": 67.99999999999999,
    "Hour Maximum": 40,
    "Hour Minimum": 15,
    name: "12 pm",
  },
  {
    "Hour Average": 78.99999999999999,
    "Hour Maximum": 12,
    "Hour Minimum": 45,
    name: "1 pm",
  },
];

export const HourlyOtsProfileByGenderData = [
  { Male: 9567, Female: 8376, name: "6 am" },
  { Male: 7098, Female: 3347, name: "7 am" },
  { Male: 4847, Female: 9809, name: "8 am" },
  { Male: 3545, Female: 8376, name: "9 am" },
  { Male: 7899, Female: 6757, name: "10 am" },
  { Male: 6234, Female: 8445, name: "11 am" },
  { Male: 2345, Female: 4354, name: "12 pm" },
  { Male: 9871, Female: 8376, name: "1 pm" },
];
