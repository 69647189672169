import { ActionTypes } from "../../../constants/ActionConstants";

const initialState = {
  openModal: false,

  // states for fetching selected Pois
  getSelectedPoisLoading: false,
  errFetchingPois: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PoiSelectionForm.OPEN_POI_SELECTION_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case ActionTypes.PoiSelectionForm.CLOSE_POI_SELECTION_MODAL:
      return {
        ...state,
        openModal: false,
      };

    case ActionTypes.PoiSelectionForm.GET_SELECTED_POI_INFO:
      return {
        ...state,
        getSelectedPoisLoading: true,
      };

    case ActionTypes.PoiSelectionForm.GET_SELECTED_POI_INFO_SUCCESS:
      return {
        ...state,
        getSelectedPoisLoading: false,
      };

    case ActionTypes.PoiSelectionForm.GET_SELECTED_POI_INFO_FAILURE:
      return {
        ...state,
        getSelectedPoisLoading: false,
        errFetchingPois: action.payload,
      };

    default:
      return state;
  }
};
