/* eslint-disable no-case-declarations */
import { ActionTypes } from "../../constants/ActionConstants";

const initialState = {
  // storing all lat-long strings OTS from array"
  allLatLongsOts: {},
  error: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SellerExcelOverview.GET_OTS_OF_LAT_LONGS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.SellerExcelOverview.GET_OTS_OF_LAT_LONGS_SUCCESS:
      return {
        ...state,
        allLatLongsOts: action.data,
        loading: false,
      };

    case ActionTypes.SellerExcelOverview.GET_OTS_OF_LAT_LONGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
