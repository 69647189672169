// URLS
import {
  getV2PinCodeUrl,
  getV2WardUrl,
} from "../../urls/map-view/BoundariesURL";

// Constants and Utils
import { sendGetRequest } from "../../utils/api/apiUtils";
import boundaries from "../../utils/boundaries.json";

function extractPolygonData(geoData) {
  const polygonData = geoData.reduce((acc, data) => {
    acc.push({
      type: "Feature",
      properties: data.properties,
      geometry: data.geometry,
    });
    return acc;
  }, []);
  return { type: "FeatureCollection", features: polygonData };
}

export async function getWardInfo(cityId) {
  const url = getV2WardUrl.replace(":cityId", cityId);
  const response = await sendGetRequest(url);
  const polygonData = extractPolygonData(response.result);
  return { polygonData };
}

export async function getPinCodeInfo(cityId) {
  const url = getV2PinCodeUrl.replace(":cityId", cityId);
  const response = await sendGetRequest(url);
  const polygonData = extractPolygonData(response.result);
  return { polygonData };
}

export function getBoundaries(cityId) {
  return boundaries;
}
