/* eslint-disable no-case-declarations */
import { ActionTypes } from "../../constants/ActionConstants";

const initialState = {
  // uploading
  uploadLoading: false,
  uploadErr: "",
  excelResponse: {},
  mediaCount: 0,
  latLongArr: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // for upload-template
    case ActionTypes.SellerExcelUpload.SELLER_UPLOAD_TEMPLATE:
      return {
        ...state,
        uploadLoading: true,
      };

    case ActionTypes.SellerExcelUpload.SELLER_UPLOAD_TEMPLATE_SUCCESS:
      const response = action.response;
      const mediaCount = response.sheetData.length;
      const latLongArr = response.sheetData.map((obj) => obj["Geo Location"]);
      return {
        ...state,
        uploadLoading: false,
        excelResponse: response,
        mediaCount: mediaCount,
        latLongArr: latLongArr,
      };

    case ActionTypes.SellerExcelUpload.SELLER_UPLOAD_TEMPLATE_FAILURE:
      return {
        ...state,
        uploadLoading: false,
        uploadErr: action.payload,
      };

    case ActionTypes.SellerExcelUpload.RESET_EXCEL_DATA:
      return {
        ...state,
        excelResponse: {},
        mediaCount: 0,
      };

    default:
      return state;
  }
};
