/* eslint-disable require-yield */
import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Apis
import {
  getSellerSummaryByCityFn,
  getSellerSummaryByMediaIdFn,
} from "../../apis/SellerAnalyticsAPI";

// Contants and Utils
import { Seller } from "../../constants/action-constants/seller/SellerActionConstants";
import { getSellerId } from "../../utils/SellerUtils";
import { getErrorMessage } from "../../utils/util";

function* getSellerSummaryByCity(action) {
  const { cityId, dateRange } = action.payload;
  try {
    const sellerId = getSellerId();

    const sellerSummary = yield getSellerSummaryByCityFn(
      cityId,
      sellerId,
      dateRange
    );

    yield put({
      type: Seller.GET_SELLER_SUMMARY_BY_CITY_SUCCESS,
      payload: { sellerSummary, dateRange },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Seller.GET_SELLER_SUMMARY_BY_CITY_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* getSellerSummaryByMediaId(action) {
  try {
    const { mediaId, dateRange } = action.payload;

    const sellerId = getSellerId();

    const sellerMediaSummary = yield getSellerSummaryByMediaIdFn(
      mediaId,
      sellerId,
      dateRange
    );

    yield put({
      type: Seller.GET_SELLER_SUMMARY_BY_MEDIA_ID_SUCCESS,
      payload: { sellerMediaSummary },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: Seller.GET_SELLER_SUMMARY_BY_MEDIA_ID_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(Seller.GET_SELLER_SUMMARY_BY_CITY, getSellerSummaryByCity),
    takeLatest(
      Seller.GET_SELLER_SUMMARY_BY_MEDIA_ID,
      getSellerSummaryByMediaId
    ),
  ]);
}
