import { useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";

// Actions
import { restoreCampaignPlan } from "../actions/campaign-planning/CampaignPlanningActions";
import {
  setMediaTypes,
  setPoiAndBrandInfo,
  setRoadStretches,
  setTg,
} from "../actions/map-view/MapViewActions";
import { getMediaPriceByIds } from "../../actions/org/OrgMediaPriceActions";

export function useRestoreCampaignPlan(campaignId, queryCityId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (campaignId && queryCityId) {
      dispatch(restoreCampaignPlan(campaignId, queryCityId));
      return;
    }
  }, [dispatch, campaignId, queryCityId]);

  return null;
}

export function useSetMediaTypes(campaignMedia) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (campaignMedia.length > 0) {
      // Media Types constructed from Campaign Media
      const mediaTypesMap = _.groupBy(
        campaignMedia,
        (campaignMedia) => campaignMedia.type
      );
      const mediaTypeCountMap = Object.keys(mediaTypesMap).reduce(
        (acc, eachKey) => {
          const eachValue = mediaTypesMap[eachKey];
          acc[eachKey] = eachValue.length;
          return acc;
        },
        {}
      );

      dispatch(setMediaTypes(mediaTypeCountMap));
    }
  }, [dispatch, campaignMedia.length]);

  return null;
}

export function useSetPoiBrands(poiBrandIdsMap, cityId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(poiBrandIdsMap).length > 0) {
      dispatch(setPoiAndBrandInfo(poiBrandIdsMap, cityId));
    }
  }, [dispatch, JSON.stringify(poiBrandIdsMap)]);

  return null;
}

export function useSetRoadStretches(roadStretchMap, roadStretchOtsMap) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(roadStretchMap).length > 0) {
      const selectedStretchIds = Object.keys(roadStretchOtsMap);
      const unSelectedStretchIds = Object.keys(roadStretchMap).reduce(
        (acc, eachStretchId) => {
          if (roadStretchOtsMap[eachStretchId]) {
            return acc;
          }
          acc.push(eachStretchId);
          return acc;
        },
        []
      );
      dispatch(setRoadStretches(selectedStretchIds, unSelectedStretchIds));
    }
  }, [
    dispatch,
    JSON.stringify(roadStretchMap),
    JSON.stringify(roadStretchOtsMap),
  ]);
  return null;
}

export function useSetTgSelected(tgId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (tgId) {
      dispatch(setTg(tgId));
    }
  }, [dispatch, tgId]);

  return null;
}

export function useGetMediaPrice(mediaIds) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (mediaIds.length > 0) {
      dispatch(getMediaPriceByIds(mediaIds));
    }
  }, [dispatch, JSON.stringify(mediaIds)]);

  return null;
}
