export const formatDate = (str) => {
  const date = new Date(str);
  const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("-");
};

export const formatTimestamp = (ts) => {
  const date = new Date(ts);
  const monthAndDay = date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
  const year = date.toLocaleDateString("en-US", { year: "numeric" });
  return `${monthAndDay}, ${year}`;
};
