import { toast } from "react-toastify";
import { all, put, select, takeLatest } from "redux-saga/effects";

// Api
import { getSegmentDetails } from "../../../apis/RoadSegmentAPI";

// Constants and Utils
import { ActionTypes } from "../../../constants/ActionConstants";
import { RoadSegment } from "../../../constants/action-constants/RoadSegmentActionConstants";
import { getSegmentMediaDetails } from "../../../utils/api/campaignPlanning";
import { getErrorMessage } from "../../../utils/util";

function getMediaIds(mediaInfo) {
  // getting "segmentMediaIds" from "mediaInfo"
  const mediaIdArrays = Object.values(mediaInfo).map((obj) =>
    Object.values(obj).map((media) => media.mediaId)
  );

  const mediaIds = mediaIdArrays.flat();
  return mediaIds;
}

// for getting closest-road-segment
export function* getSegmentMedia(action) {
  const { segmentIds } = action.payload;
  try {
    const mediaInfo = yield getSegmentMediaDetails(segmentIds);
    yield put({
      type: RoadSegment.GET_ROAD_SEGMENT_MEDIA_SUCCESS,
      payload: { mediaInfo },
    });

    // Dispatch an action to get TgOts (if Tg Selected) for the segment
    // and its media
    const tgId = yield select((state) => state.tgSpecificOts.tgId);
    if (tgId) {
      yield put({
        type: ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS,
        payload: {
          tgId: tgId,
          stretchIds: [],
          segmentIds: segmentIds,
          mediaIds: getMediaIds(mediaInfo),
        },
      });
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: RoadSegment.GET_ROAD_SEGMENT_MEDIA_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

// for getting closest-road-segment
// TODO : Same saga is present in roadSegment sage can we refactor it
export function* getSegmentsData(action) {
  const { segmentIds } = action.payload;
  try {
    const segmentsMap = yield getSegmentDetails(segmentIds);
    yield put({
      type: RoadSegment.GET_PLANNING_ROAD_SEGMENTS_SUCCESS,
      payload: { segmentsMap },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: RoadSegment.GET_PLANNING_ROAD_SEGMENTS_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(RoadSegment.GET_ROAD_SEGMENT_MEDIA, getSegmentMedia),
    takeLatest(RoadSegment.GET_PLANNING_ROAD_SEGMENTS, getSegmentsData),
  ]);
}
