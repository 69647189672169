// React Related Imports
import { all, delay, put, takeLatest } from "redux-saga/effects";

// Constants
import { Auth } from "../../constants/action-constants/AuthActionConstants";
import { LocalStorageKeys, UserRoles } from "../../constants/GeneralConstants";

// Api
import {
  changeUserPassword,
  resetUserPassword,
  userLogin,
  forgotPasswordFn,
} from "../../apis/AuthAPI";

// Utilities
import { setToken } from "../../utils/token";
import { setItem } from "../../utils/localstorage";

export function* login(action) {
  try {
    //getting email and password from action
    const { email, password } = action.payload;

    const loggedInData = yield userLogin(email, password);

    // Check if the user belongs to the organizations white Listed..
    const { org: orgDetails = {}, roles = [], token } = loggedInData;
    const { id: orgId } = orgDetails;

    // If WhiteList Organizations are not defined we will not allow anyone
    // NOTE: We are making this mandatory to define the whitelist organization details

    const allowedOrgIds = process.env.REACT_APP_WHITELIST_ORG_IDS ?? "";
    if (!allowedOrgIds.includes(orgId)) {
      //If user does not fall into any of the whitelisted organizations
      // does not allow to login into the system..
      yield put({
        type: Auth.USER_LOGIN_FAILURE,
        payload: "You are not registered to login into the system..",
      });
      return;
    }

    //setting token in localstorage :: TODO remove the setToken.
    setToken(token);

    //store permissions in local storage
    setItem(LocalStorageKeys.PERMISSIONS, roles);

    //set user details in local storage
    setItem(LocalStorageKeys.USER, JSON.stringify(loggedInData));

    //set org type in local storage
    setItem(LocalStorageKeys.ORG_TYPE, orgDetails.type);

    // Not storing the "platformUpdatesModal" key in case of "Seller-User"
    const isSellerUser = roles.includes(UserRoles.ROLE_SELLER);
    if (!isSellerUser) {
      // Setting key for first time user visit for "Platform-Updates-Form"
      setItem(LocalStorageKeys.PLATFORM_UPDATES_MODAL, "true");
    }

    //dispatching action
    yield put({
      type: Auth.USER_LOGIN_SUCCESS,
      payload: { loggedInData },
    });
  } catch (err) {
    yield put({
      type: Auth.USER_LOGIN_FAILURE,
      payload: err,
    });
  }
}

/**
 * Logout
 */
// TODO: This has no functional implementation, needs to be addressed...
export function* logout() {
  try {
    yield delay(200);

    yield put({
      type: Auth.USER_LOGOUT_SUCCESS,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: Auth.USER_LOGOUT_FAILURE,
      payload: err,
    });
  }
}

/**
 * RESET PASSWORD
 */
export function* resetPassword(action) {
  try {
    const { newPassword, token } = action.payload;

    yield resetUserPassword(newPassword, token);
    yield put({
      type: Auth.RESET_PASSWORD_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: Auth.RESET_PASSWORD_FAILURE,
      payload: err,
    });
  }
}

/**
 * Forgot Password
 */

function* forgotPassword(action) {
  try {
    const { email } = action.payload;
    yield forgotPasswordFn(email);
    yield put({
      type: Auth.FORGOT_PASSWORD_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: Auth.FORGOT_PASSWORD_FAILURE,
      payload: err,
    });
  }
}

/**
 * CHANGE PASSWORD
 */
export function* changePassword(action) {
  try {
    //getting email from action
    const { oldPassword, newPassword } = action.payload.data;

    /**
     * TODO: this is not working right now,
     * NEEDS TO BE FIXED
     */
    yield changeUserPassword(oldPassword, newPassword);

    //dispatching action
    yield put({
      type: Auth.CHANGE_PASSWORD_SUCCESS,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: Auth.CHANGE_PASSWORD_FAILURE,
      payload: err,
    });
  }
}

/**
 * User Sagas
 */
export default function* root() {
  yield all([
    takeLatest(Auth.USER_LOGIN, login),
    takeLatest(Auth.USER_LOGOUT, logout),
    takeLatest(Auth.RESET_PASSWORD, resetPassword),
    takeLatest(Auth.FORGOT_PASSWORD, forgotPassword),
    takeLatest(Auth.CHANGE_PASSWORD, changePassword),
  ]);
}
