import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// Actions
import { getSellerInventorySummary } from "../../actions/seller/SellerActions";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Constants and Utils
import { prepareMediaTypesCount } from "../../utils/MediaUtils";

//Components
import TableHeaders from "../../components/table/TableHeaders";
import PageHeader from "../../mavin/components/page-header/PageHeader";
import Spinner from "../../components/spinner/Spinner";

// Url constants
const { sellerInventoryCityViewPageUrl, sellerInventoryMapViewPageUrl } =
  RedirectTo;

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "City",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "Billboards",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "Gantries",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "Unipoles",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "FOB",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "Transit Stations",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "DOOH",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "Total Sites",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "Actions",
      className: "col-2 text-right",
    },
  },
  {
    title: {
      displayName: "",
      className: "col-2 text-right",
    },
  },
];

// Page Components
function SellerInventoryTableRow({ cityInfo, cityMediaTypeSummary }) {
  const { id: cityId, name } = cityInfo;

  //
  const mediaTypesCount = prepareMediaTypesCount(cityMediaTypeSummary);
  const { BILLBOARDS, UNIPOLES, GANTRIES, FOB, TRANSIT_STATIONS, DOOH, TOTAL } =
    mediaTypesCount;

  // Urls
  const inventoryCityViewUrl = sellerInventoryCityViewPageUrl.replace(
    ":cityId",
    cityId
  );

  const inventoryMapViewUrl = sellerInventoryMapViewPageUrl.replace(
    ":cityId",
    cityId
  );

  return (
    <tr>
      <td>
        <Link className="text-truncate" to={inventoryCityViewUrl} title={name}>
          {name}
        </Link>
      </td>
      <td>{BILLBOARDS.count}</td>
      <td>{GANTRIES.count}</td>
      <td>{UNIPOLES.count}</td>
      <td>{FOB.count}</td>
      <td>{TRANSIT_STATIONS.count}</td>
      <td>{DOOH.count}</td>
      <td>{TOTAL}</td>
      <td className="text-right">
        <Link to={inventoryCityViewUrl}>{"View Media"}</Link>
      </td>
      <td className="text-right">
        <Link to={inventoryMapViewUrl}>{"View Media on Map"}</Link>
      </td>
    </tr>
  );
}

/**
 * Main Page
 */

function SellerInventoryHomePage() {
  const dispatch = useDispatch();

  // Selector State
  const sellerInventoryLoading = useSelector(
    (state) => state.seller.sellerInventoryLoading
  );
  const sellerInventorySummary = useSelector(
    (state) => state.seller.sellerInventory
  );
  const inventoryCities = sellerInventorySummary.cities || {};
  const mediaSummary = sellerInventorySummary.mediaSummary || {};

  // Dispatch
  useEffect(() => {
    dispatch(getSellerInventorySummary());
  }, [dispatch]);

  if (sellerInventoryLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  const { sellerInventoryUpload } = RedirectTo;

  const pageHeaderActionBtn = (
    <div>
      <Link className={"btn btn-primary mx-3 d-none"} to={"/"}>
        {"Create Single Media"}
      </Link>

      <Link className={"btn btn-primary"} to={sellerInventoryUpload}>
        {"Upload Media via Excel"}
      </Link>
    </div>
  );

  return (
    <div className="content-wrapper h-100">
      {/* Page Header */}
      <PageHeader
        title={"Inventory Home"}
        border={false}
        shadow={true}
        actions={pageHeaderActionBtn}
      />

      <div className="page-content">
        {/* Seller Inventory Table */}
        <div className="table-responsive mt-3">
          <table className="table">
            <TableHeaders
              scope={"col"}
              tableHeaders={tableHeaders}
              headerClass={"thead"}
            />
            <tbody>
              {Object.values(inventoryCities).map((cityInfo) => (
                <SellerInventoryTableRow
                  key={cityInfo.id}
                  cityInfo={cityInfo}
                  cityMediaTypeSummary={mediaSummary[cityInfo.id]}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SellerInventoryHomePage;
