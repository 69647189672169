// RGB to HEX
function toHexColor(r, g, b) {
  let hexStr = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  hexStr = hexStr.split(".")[0];

  return "#" + hexStr;
}

/**
 * Break the HEX code of a color to R, G, B components.
 */
function RGB(hexColor) {
  const hex = hexColor.charAt(0) == "#" ? hexColor.substr(1, 6) : hexColor;
  // Properties
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  return { r, g, b };
}

/**
 * Given a Start Color and no. of Colors, generates an array of colors with linear Gradient
 *
 * @param {String} color
 * @param {Number} noOfColors no. pf colors to be generated
 * @returns an Array of Colors
 */

export function Gradient(color, noOfColors = 5) {
  // Input Properties
  const colorRGB = new RGB(color);
  const R = colorRGB.r;
  const G = colorRGB.g;
  const B = colorRGB.b;

  // Generate palette
  const palette = new Array(noOfColors).fill(0).map((v, idx) => {
    const percentage = Math.abs((100 / noOfColors) * (idx + 1));
    const color = `rgb(${R} ${G} ${B}/${percentage}%)`;
    return color;
  });

  return palette;
}

/**
 * Given a start color, end color and a no.of colors needed,
 * this generates a pallette of colors that are equally spaced.
 */
export function Shades(startColor, endColor, noOfColors = 5) {
  // Input Properties
  const startRGB = new RGB(startColor);
  const endRGB = new RGB(endColor);

  // Step size
  const rStep = (endRGB.r - startRGB.r) / (noOfColors - 1);
  const gStep = (endRGB.g - startRGB.g) / (noOfColors - 1);
  const bStep = (endRGB.b - startRGB.b) / (noOfColors - 1);

  // Generate palette
  const palette = new Array(noOfColors).fill(0).map((v, idx) => {
    const R = startRGB.r + rStep * idx;
    const G = startRGB.g + gStep * idx;
    const B = startRGB.b + bStep * idx;

    // Color String
    return toHexColor(R, G, B);
  });

  return palette;
}

/**

 * Assigns colors from the pallette to data points based on it's values.
 */
function DataShades(inData = [], colorConfig = {}) {
  const {
    gradient = true,
    noOfColors = 5,
    startColor = "#f03b20",
    endColor = "#ffeda0",
  } = colorConfig;

  const palette = gradient
    ? Gradient(startColor, noOfColors)
    : Shades(startColor, endColor, noOfColors);

  // Input properties
  const data = inData.filter((i) => !isNaN(i));
  const dataMin = Math.min.apply(null, data);
  const dataMax = Math.max.apply(null, data);

  // Utilities
  // --------------------------------------------------------------------------

  function getColor(value) {
    // Default
    if (!value || value === dataMin) {
      return palette[0];
    }

    // Calculate indes
    const percent = (value - dataMin) / (dataMax - dataMin);
    const idx = parseInt(percent * noOfColors);

    return palette[idx < noOfColors ? idx : noOfColors - 1];
  }

  function getLegend() {
    // Palette
    const stepSize = Math.ceil((dataMax - dataMin) / palette.length);

    // Prepare Legend
    const legend = new Array(palette.length).fill(0).map((v, idx) => {
      return [
        dataMin + stepSize * idx,
        dataMin + stepSize * (idx + 1),
        palette[idx],
      ];
    });

    return legend;
  }

  return {
    getColor,
    getLegend,
  };
}

export default DataShades;
