import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { getDataLayerDetails } from "../../../actions/org/OrgDataLayerActions";

// Constants and Utils
import { MapZoom } from "../../../constants/GeneralConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";
import LLMap from "../../../components/map/leaflet-map/LLMap";
import { PlainDropdown } from "../../../components/dropdown/Dropdown";
import DataPointMarkers from "../../../components/data-point-markers/DataPointMarkers";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Pages and Sections
import OrgSettingsSidebar from "../OrgSettingsSidebar";

// CSS
import "../OrgSettings.scss";

// Page-Components
function DataPoint({ name, summary }) {
  return (
    <div className="my-2">
      <span className="font-weight-bold">{name}</span>
      <span className="text-muted d-block">{summary}</span>
    </div>
  );
}

function DataLayerSection({ dropdownItems, dataPoints, onCityChange }) {
  return (
    <>
      <PlainDropdown
        className="btn border px-3 shadow-none my-3"
        items={dropdownItems}
        onItemSelect={onCityChange}
      />
      <div className="d-flex flex-column">
        <span className="my-2 text-primary">{`${dataPoints.length} point(s) of interest`}</span>
        {dataPoints.map((dataPoint) => (
          <DataPoint
            key={dataPoint.name}
            name={dataPoint.name}
            summary={dataPoint.summary}
          />
        ))}
      </div>
    </>
  );
}

function DataLayerViewMapSection({ formattedCenter, dataPoints }) {
  if (!formattedCenter || formattedCenter.length === 0) {
    return null;
  }

  return (
    <div className="map-layout-right">
      <div className="org-map-cont leaflet-container">
        <LLMap center={formattedCenter} zoom={MapZoom.zoomLevel10}>
          <DataPointMarkers layerIdx={0} dataPoints={dataPoints} />
        </LLMap>
      </div>
    </div>
  );
}

/**
 * Page
 */
export default function DataLayerViewPage() {
  const { id } = useParams();
  // State
  const [cityId, setCityId] = useState();

  // Selector
  const dataLayer =
    useSelector((state) => state.orgDataLayers.dataLayerDetailsMap[id]) || {};
  const regions = dataLayer.regions || {};
  const loading = useSelector(
    (state) => state.orgDataLayers.viewDataLayerLoading
  );

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDataLayerDetails(id));
  }, [dispatch, id]);

  // setting cityId to render Map initially
  const firstCityId = dataLayer?.cities?.[0]?.id;
  useEffect(() => {
    setCityId(firstCityId);
  }, [firstCityId]);

  // Functions
  function getFormattedCenter() {
    if (!cityId) {
      return null;
    }
    if (Object.keys(regions).length < 1) {
      return null;
    }

    const latitude = regions[cityId].center.latitude;
    const longitude = regions[cityId].center.longitude;
    const center = [latitude, longitude];
    return center;
  }

  // Center accordingly city-selected
  const formattedCenter = getFormattedCenter();
  const dataPoints = dataLayer.dataPointMap?.[cityId] || [];
  const citiesItems = Object.keys(regions).map((cityId) => ({
    id: cityId,
    label: `${regions[cityId].name}`,
  }));

  const pageTitle = `POINT :: ${dataLayer.name}`;

  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={6} />

      <div className="content-wrapper map-content-wrapper">
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner className="mt-2" />
          </div>
        ) : (
          <>
            {/** Map Layout :: Left */}
            <div className="map-layout-left">
              {/* Page-Header */}
              <PageHeader title={pageTitle} shadow={false} border={true} />

              {/* Breadcrumb */}
              {/* <Breadcrumb items={breadcrumbItems} /> */}

              {/* Data layers list */}
              <DataLayerSection
                dropdownItems={citiesItems}
                dataPoints={dataPoints}
                onCityChange={setCityId}
              />
            </div>

            {/* Map Layout :: Right */}
            <DataLayerViewMapSection
              formattedCenter={formattedCenter}
              dataPoints={dataPoints}
            />
          </>
        )}
      </div>
    </>
  );
}
