import { Lts } from "../../constants/action-constants/mavin-tools/LtsActionConstants";

// for Lts tool
export const getLtsInfo = (mediaInfoBean) => ({
  type: Lts.GET_LTS_INFO,
  payload: { mediaInfoBean },
});

export const resetLtsInfo = () => ({
  type: Lts.RESET_LTS_INFO,
});
