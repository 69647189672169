import { MetaData } from "../../constants/action-constants/map-view/MetaDataActionConstants";

export const selectMetaDataMarker = (metadataKey) => ({
  type: MetaData.SELECT,
  payload: { id: metadataKey },
});

export const unSelectMetaDataMarker = (metadataKey) => ({
  type: MetaData.UN_SELECT,
  payload: { id: metadataKey },
});

export const assign = (metaData) => ({
  type: MetaData.ASSIGN,
  payload: { metaData },
});

export const resetMetaData = () => ({
  type: MetaData.RESET,
});
