import { GeoData } from "../../constants/action-constants/GeoDataActionConstants";

export const getTgHeatMapData = (resPoiLayers, bbox) => ({
  type: GeoData.GET_POI_TYPE_LAYER_DATA,
  payload: {
    resPoiLayers,
    bbox,
  },
});

export const removeTgHeatMapData = () => ({
  type: GeoData.REMOVE_POI_TYPE_LAYER_DATA,
});
