import { ActionTypes } from "../../constants/ActionConstants";

//intital state
const initialState = {
  // Campaign Planning details for "CampaignId and CityId"
  // --------------------------------------------------------------
  campaignPlanLoading: false,
  campaignPlanErr: "",
  campaignPlan: "",

  // Campaign Planning details for "CampaignId"
  // ------------------------------------------------------------
  campaignPlanOverviewLoading: false,
  campaignPlanOverviewError: "",
  campaignPlanOverview: "",

  // Campaign Planning upsate status
  // ------------------------------------------------------------
  updateStateLoading: false,
  updateStateErr: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Campaign Planning details for "CampaignId and CityId"
    // -----------------------------------------------------------------------
    case ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN:
      return {
        ...state,
        campaignPlanLoading: true,
      };
    case ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN_SUCCESS: {
      const campaignPlan = action.payload.campaignPlan;
      const { tgId, cityId } = campaignPlan;

      return {
        ...state,
        campaignPlanLoading: false,
        campaignPlan: campaignPlan,
        tgId: tgId,
        cityId: cityId,
      };
    }

    case ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN_FAILURE:
      return {
        ...state,
        campaignPlanLoading: false,
        campaignPlanErr: action.payload,
      };

    // Campaign Planning details for "CampaignId"
    // ------------------------------------------------------------------------
    case ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN_OVERVIEW:
      return {
        ...state,
        campaignPlanOverviewLoading: true,
      };
    case ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN_OVERVIEW_SUCCESS: {
      const { campaignPlanOverview } = action.payload;
      return {
        ...state,
        campaignPlanOverviewLoading: false,
        campaignPlanOverview: campaignPlanOverview,
      };
    }

    case ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN_OVERVIEW_FAILURE:
      return {
        ...state,
        campaignPlanOverviewLoading: false,
        campaignPlanOverviewError: action.payload,
      };

    // Campaign Plan States..
    case ActionTypes.CampaignPlanDetails.UPDATE_CAMPAIGN_PLAN_STATUS:
      return {
        ...state,
        updateStateLoading: true,
      };

    case ActionTypes.CampaignPlanDetails.UPDATE_CAMPAIGN_PLAN_STATUS_SUCCESS:
      return {
        ...state,
        updateStateLoading: false,
      };

    case ActionTypes.CampaignPlanDetails.UPDATE_CAMPAIGN_PLAN_STATUS_FAILURE:
      return {
        ...state,
        updateStateLoading: false,
        updateStateErr: action.payload,
      };

    case ActionTypes.CampaignPlanDetails.RESET_CAMPAIGN_PLAN_DETAILS:
      return initialState;

    default:
      return state;
  }
};
