import { useSelector } from "react-redux";
import { GeoJSON } from "react-leaflet";

// Utils
import {
  getBoundaryMarkersData,
  _getPropValueOfGeoJsonFeature,
} from "./BoundariesUtil";

// GeoJSON Polygon Feature Callback
const geoJsonFeatureCallbacks = (feature, layer, setHoveredBoundaryInfo) => {
  layer.on({
    mouseover: function (e) {
      const _layer = e.target;
      _layer.setStyle({
        weight: 3,
        fillOpacity: 0.9,
      });
      setHoveredBoundaryInfo(feature.properties);
    },
    mouseout: function (e) {
      const _layer = e.target;
      _layer.setStyle({
        weight: 1,
        fillOpacity: 0.7,
      });
      setHoveredBoundaryInfo({});
    },
  });
};

// Main Function
// ----------------------------------------------------------------------------

function BoundaryMarkers({ setHoveredBoundaryInfo }) {
  // Selector State
  const polygonData =
    useSelector((state) => state.boundaries.polygonData) || {};
  const selected = useSelector((state) => state.boundaries.selectedLabel) || "";

  // Selector
  const boundaryMapSettings =
    useSelector((state) => state.boundaries.boundaryMapSettings) || {};

  // getting Boundary Markers Data
  const { polygonFeatures, dataShades, _prop } = getBoundaryMarkersData(
    polygonData,
    selected,
    boundaryMapSettings
  );

  // Add each Region
  const geoJsonLayers = polygonFeatures.map((ft) => {
    // Properties
    const ftProps = ft.properties || {};

    // Feature Style
    const propVal = _getPropValueOfGeoJsonFeature(ft, _prop);
    const ftStyle = {
      weight: 1,
      opacity: 1,
      color: "#000",
      dashArray: "3",
      fillColor: dataShades.getColor(propVal),
      fillOpacity: 0.7,
    };

    return (
      <>
        <GeoJSON
          key={ftProps.id}
          data={ft}
          style={ftStyle}
          onEachFeature={(feature, layer) =>
            geoJsonFeatureCallbacks(feature, layer, setHoveredBoundaryInfo)
          }
        />
      </>
    );
  });

  return geoJsonLayers;
}

export default BoundaryMarkers;
