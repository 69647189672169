import { ActionTypes } from "../../../constants/ActionConstants";

const initialState = {
  // Create media modal
  openModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Open and close create media modal
    //--------------------------------------------------------------------------------
    case ActionTypes.CreateMediaFormModal.OPEN_MEDIA_CREATE_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case ActionTypes.CreateMediaFormModal.CLOSE_MEDIA_CREATE_MODAL:
      return {
        ...state,
        openModal: false,
      };
    default:
      return state;
  }
};
