import { Polyline, Popup } from "react-leaflet";
import { useSelector } from "react-redux";

/**
 * Page Section
 */
function RoadStretchMarkers() {
  // Added Stretches
  const selectedStretchIds = useSelector((state) =>
    Object.keys(state.roadStretchesSelection.selectedStretchesMapView)
  );

  // total stretches of city
  const orgRoadStretches = useSelector(
    (state) => state.orgRoadStretch.orgRoadStretches
  );

  // selected stretches to show on map
  const selectedStretchesForMap = selectedStretchIds.reduce(
    (acc, stretchId) => {
      acc.push(orgRoadStretches[stretchId]);
      return acc;
    },
    []
  );

  // Style stretch
  const stretchStyle = {
    weight: 10,
    color: "blue",
    opacity: 1,
  };

  return (
    <>
      {selectedStretchesForMap.map((eachStretch) => (
        <Polyline
          key={eachStretch.id}
          pathOptions={stretchStyle}
          positions={eachStretch.trace}
        >
          <Popup>
            <b className="d-block">{eachStretch.name}</b>
          </Popup>
        </Polyline>
      ))}
    </>
  );
}

export default RoadStretchMarkers;
