// Constants
import { Status } from "../../../constants/GeneralConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";
import {
  filterDataList,
  getSelectedIndex,
  getTitle,
  isSubSectionOpen,
  getTgTag,
} from "../../../components/nested-checklist/nestedCheckListUtil";

// Page Components
/**
 * @param {{isSelected: boolean, tag: string}} props
 */
function Tag({ isSelected, selectedCount, totalCount }) {
  const faIconClass = isSelected ? "fa fa-angle-down" : "fa fa-angle-right";

  return (
    <>
      {(isSelected || selectedCount > 0) && (
        <span className="pr-1">{`${selectedCount}/${totalCount}`}</span>
      )}
      <i className={`mt-1 ${faIconClass}`}></i>
    </>
  );
}

function Checkbox({ data, onInputAction, loading }) {
  const { label, status } = data;
  function onChangeAction(e, status) {
    e.stopPropagation();
    onInputAction(label, status);
  }

  return (
    <input
      type="checkbox"
      onChange={(e) => {
        onChangeAction(e, Status.CHECKED);
      }}
      disabled={loading}
    />
  );
}

function isLoading(data, label, loadingMap) {
  if (!loadingMap) {
    return false;
  }

  const hasChildren = data.children.length > 0;
  if (!hasChildren) {
    return loadingMap[label] === true ? true : false;
  }

  for (const eachChild of data.children) {
    if (loadingMap[eachChild.label] === true) {
      return true;
    }
  }
}

function ListElement({
  data,
  index,
  onLabelClick,
  loadingMap,
  onCheckboxChange = () => {},
}) {
  const key = `${data.label}_${index}`;

  const { label, isSelected } = data;
  const hasChildren = data.children.length > 0;
  const { checkedCount, totalCount } = getTgTag(data.children);
  const loading = isLoading(data, label, loadingMap);

  return (
    <div
      key={key}
      className={`navbar p-2 ${isSelected ? "bg-alt" : ""}`}
      onClick={(e) => {
        if (e.target.tagName !== "INPUT") {
          onLabelClick && onLabelClick(label);
        }
      }}
    >
      <span className="form-check p-0 cursor-pointer">{label}</span>
      <div className="d-flex">
        {loading && <Spinner className="spinner-grow-sm m-1" />}
        {/* If checkboxes should be shown and data level is 2 */}
        {data.level === 2 && (
          <Checkbox
            data={data}
            onInputAction={onCheckboxChange}
            loading={loading}
          />
        )}

        {/* Render Tag if there are children */}
        {hasChildren && (
          <Tag
            isSelected={isSelected}
            selectedCount={checkedCount}
            totalCount={totalCount}
          />
        )}
      </div>
    </div>
  );
}

function CollapseList({ data, index, onLabelClick, onAddButton, loadingMap }) {
  const dataLabel = data.label;
  return (
    <div
      key={`${dataLabel}_${index}`}
      className="panel-group"
      id={`accordion_${index}`}
    >
      <div id={`collapse_${index}`} className="panel-collapse p-2 collapse in">
        {data.children.map((eachData) => (
          <ListElement
            key={eachData.label}
            data={eachData}
            index={index}
            onLabelClick={onLabelClick}
            onAddButton={onAddButton}
            loadingMap={loadingMap}
          />
        ))}
      </div>
    </div>
  );
}

/**
 * @param {{
 *    dataList: [{label: string, isSelected: boolean, status: string}],
 *    onLabelClick: (label: string) => void,
 *    onStatusChange: (label: string, isChecked: boolean) => void,
 * }} props
 */
function List({ dataList, onLabelClick, loadingMap, onCheckboxChange }) {
  if (!dataList || !dataList.length) {
    return null;
  }

  return dataList.map((data, index) => {
    const { type } = data;
    if (type) {
      return (
        <CollapseList
          key={index}
          index={index}
          data={data}
          onLabelClick={onLabelClick}
          loadingMap={loadingMap}
        />
      );
    }
    return (
      <ListElement
        key={index}
        data={data}
        index={index}
        onLabelClick={onLabelClick}
        loadingMap={loadingMap}
        onCheckboxChange={onCheckboxChange}
      />
    );
  });
}

function TitleSection({ title }) {
  if (!title) {
    return null;
  }

  return (
    <div className="navbar p-2 border-bottom">
      {/* Title */}
      <h3 className="font-weight-bold text-truncate col-8 px-0" title={title}>
        {title}
      </h3>
    </div>
  );
}

/**
 * Component
 */
function NestedList({
  data,
  title,
  path,
  loadingMap,
  setSelected,
  setAdded,
  selectAllAttr, // select all attributes button "boolean"
  onCheckboxChange,
}) {
  const dataList = filterDataList(data, path);
  const selectedIndex = getSelectedIndex(dataList);

  const subSectionOpen = isSubSectionOpen(selectedIndex, dataList);
  const titleToBeDisplayed = getTitle(selectedIndex, dataList);

  return (
    <div className={`list ${title ? "col-6" : ""}`}>
      <div className="d-flex">
        <div className={`${title ? "col-12 px-0" : "col-6"}`}>
          {/* Title */}
          <TitleSection title={title} />

          {/* listing */}
          <List
            dataList={dataList}
            loadingMap={loadingMap}
            onLabelClick={(label) => setSelected(selectedIndex, label)}
            onCheckboxChange={onCheckboxChange}
          />
        </div>

        {/* nested listing of attributes */}
        {subSectionOpen && (
          <>
            <div className="border-left"></div>
            <NestedList
              data={dataList}
              loadingMap={loadingMap}
              title={titleToBeDisplayed}
              path={selectedIndex}
              setSelected={setSelected}
              setAdded={setAdded}
              selectAllAttr={selectAllAttr}
              onCheckboxChange={onCheckboxChange}
            />
          </>
        )}
      </div>
    </div>
  );
}

NestedList.defaultProps = {
  path: {},
  selectAllAttr: false,
};

export default NestedList;
