import { useSelector } from "react-redux";
import LLHeatLayer from "../../../components/map/leaflet-heat-layer/LLHeatLayer";

// Util Functions
// ----------------------------------------------------------------------------

/**
 * Filters Heatmap points that are relevant to the passed SEC Class.
 *
 * @param {Array} points Array of data points
 * @param {Object} stats Descriptive statistics of the data points.
 * @param {String} secCls SEC Class type (SEC A, SEC B etc..)
 * @returns filtered points as a Array.
 */
function _filterSECdataByProp(points, stats, secCls) {
  // Filter data points by economic section/class
  const percentiles = stats.percentiles;
  let dataMinVal = stats.min;
  let dataMaxVal = stats.max;

  // Identify the property value bounds as per the SEC class
  const secClsType = secCls.toLowerCase();
  if (secClsType === "sec a+" || secClsType === "sec ap") {
    dataMaxVal = stats.max;
    dataMinVal = percentiles[95];
  } else if (secClsType === "sec a") {
    dataMaxVal = percentiles[95];
    dataMinVal = percentiles[65];
  } else if (secClsType === "sec b+" || secClsType === "sec bp") {
    dataMaxVal = percentiles[65];
    dataMinVal = percentiles[35];
  } else if (secClsType === "sec b") {
    dataMaxVal = percentiles[35];
    dataMinVal = percentiles[10];
  } else if (secClsType === "sec c") {
    dataMaxVal = percentiles[10];
    dataMinVal = stats.min;
  }

  // Filter points as per the bounds SEC Class bounds
  const filteredPoints = [];
  for (const i in points) {
    const p = points[i];
    const pVal = p[2];

    if (pVal >= dataMinVal && pVal <= dataMaxVal) {
      filteredPoints.push([p[0], p[1], 1]);
    }
  }

  return filteredPoints;
}

// Component
// ----------------------------------------------------------------------------

function SecHeatLayerSection({ secClsList = [] }) {
  const secData = useSelector((state) => state.sec.secData);

  // Global Data
  const points = secData.points || [];
  if (points.length < 1) {
    return null;
  }
  const stats = secData.stats || {};

  // Selection
  const secClsData = [];
  for (const idx in secClsList) {
    const _secCls = secClsList[idx];

    // filter data by prop
    const _propData = _filterSECdataByProp(points, stats, _secCls);

    // append to global data array
    secClsData.push(..._propData);
  }

  // heatmap style options
  const hmRadius = 40;
  const hmBlur = 40;
  const hmKey = secClsList
    .join("_")
    .replace(new RegExp("[\\s+]", "g"), "_")
    .toLowerCase();

  const _options = {
    radius: hmRadius,
    blur: hmBlur,
  };

  return <LLHeatLayer key={hmKey} points={secClsData} options={_options} />;
}

/**
 * Parent Component
 */
function SecLayer() {
  const selectedSecObj = useSelector((state) => state.sec.selectedSec);

  const selectdSecClsList = Object.keys(selectedSecObj).map((sec) => {
    const splits = sec.split("_");
    return splits.length > 0 ? splits[1] : "";
  });

  return <SecHeatLayerSection secClsList={selectdSecClsList} />;
}

export default SecLayer;
