import React from "react";

// Constants and Utils
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";

// Component
export default function Population({ population }) {
  return (
    <p>
      <strong>{"Population: "}</strong>
      <span>
        <b>{population ? toLocaleString(population) : "--"}</b>
        {" as of 2020"}
      </span>
    </p>
  );
}
