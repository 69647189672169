import { ReachAndFrequency } from "../../constants/action-constants/mavin-tools/ReachAndFrequencyActionConstants";

const initialState = {
  // for Reach and Frequency tool
  // ---------------------------------------------------
  reachAndFrequencySummary: {},
  reachAndFrequencySummaryErr: "",
  reachAndFrequencySummaryLoading: false,
};

// Updating rawData with one new field "totalUniqueDevices"
function constructRAFData(result) {
  let totalUniqueDevices = 0;

  const {
    devicesCounts,
    newDeviceCounts,
    frequencyList,
    cumulativeReach,
    cumulativeFrequency,
    otsLitAvg,
  } = result;

  /**
   * converting object of array to array of object
   * Input => {key1: [1,2,3], key2: [4,5,6]}
   * Output => [{key1: 1, key2: 4},{key1: 2, key2: 5},{key1: 3, key2: 6}]
   */
  const dailyMobilityData = devicesCounts.map((value, index) => {
    const uniqueDevices = newDeviceCounts[index] || 0;
    totalUniqueDevices += uniqueDevices;
    return {
      deviceCount: devicesCounts[index],
      uniqueDeviceCount: newDeviceCounts[index],
      avgFrequency: frequencyList[index],
      cumulativeReach: cumulativeReach[index],
      cumulativeFrequency: cumulativeFrequency[index],
      totalUniqueDevices,
    };
  });

  const reachAndFrequencyData = { dailyMobilityData, otsLitAvg };

  return reachAndFrequencyData;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ReachAndFrequency.GET_REACH_AND_FREQUENCY_SUMMARY:
      return {
        ...state,
        reachAndFrequencySummaryLoading: true,
      };

    case ReachAndFrequency.GET_REACH_AND_FREQUENCY_SUMMARY_SUCCESS:
      return {
        ...state,
        reachAndFrequencySummaryLoading: false,
        reachAndFrequencySummary: constructRAFData(
          action.payload.reachAndFrequencySummary
        ),
      };

    case ReachAndFrequency.GET_REACH_AND_FREQUENCY_SUMMARY_FAILURE:
      return {
        ...state,
        reachAndFrequencySummaryLoading: false,
        reachAndFrequencySummaryErr: action.payload,
      };

    case ReachAndFrequency.RESET_REACH_AND_FREQUENCY_SUMMARY:
      return initialState;

    default:
      return state;
  }
};
