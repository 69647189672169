import { ActionTypes } from "../../../constants/ActionConstants";

export const updateCampaignAndSaveSettings = (
  title,
  buyerId,
  campaignId,
  saveSettings,
  history
) => ({
  type: ActionTypes.CampaignReview.UPDATE_CAMPAIGN_AND_SAVE_SETTINGS,
  payload: { title, buyerId, campaignId, saveSettings, history },
});
