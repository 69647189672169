import { useDispatch, useSelector } from "react-redux";

// Actions
import { getLocationSummary } from "../../../actions/location-summary/LocationSummaryActions";
import { getSingleSiteAnalyserInfo } from "../../../actions/mavin-tools/SingleSiteAnalyserActions";

// Constants and Utils
import { SiteMapViewSection } from "../ToolsUtils";

// Components
import SiteDetailsFormSection from "../../../mavin/components/media-info/SiteDetailsFormSection";

// CSS
import "../MavinTools.scss";

/**
 * Page Section
 */
function SiteDetailAndMapSection({
  coordinate,
  setLocationStr,
  locationStr,
  setCoordinate,
  radius,
}) {
  const dispatch = useDispatch();

  // Selector States
  // Loading States
  const singleSiteAnalyserInfoLoading = useSelector(
    (state) => state.singleSiteAnalyserTool.singleSiteAnalyserInfoLoading
  );
  const locationSummaryLoading = useSelector(
    (state) => state.locationSummary.dataLoading
  );

  // Function
  function dispatchFunction(mediaInfoBean, latitude, longitude) {
    dispatch(getSingleSiteAnalyserInfo(mediaInfoBean));
    dispatch(
      getLocationSummary({
        latitude,
        longitude,
        distance: radius,
      })
    );
  }

  return (
    <div className="d-flex">
      {/* Component :: Site Details Form */}
      <SiteDetailsFormSection
        className="col-6 single-site-detail pl-0"
        coordinate={coordinate}
        dispatchFunction={dispatchFunction}
        buttonLabel={"Generate Report"}
        isLoading={locationSummaryLoading || singleSiteAnalyserInfoLoading}
        showTgSelect={true}
      />

      {/* MapView  */}
      <SiteMapViewSection
        locationStr={locationStr}
        setLocationStr={setLocationStr}
        coordinate={coordinate}
        setCoordinate={setCoordinate}
      />
    </div>
  );
}

export default SiteDetailAndMapSection;
