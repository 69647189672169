// Constants and Utils
import {
  downloadFile,
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";
import { getToken } from "../utils/token";
import { roundUpNearestInteger } from "../common-utils/number-utils/NumberUtils";

// Urls
import {
  approxOtsForCityUrl,
  downloadCityMediasPdfReportsUrl,
  downloadCityPdfReportUrl,
  getCitySummaryUrl,
} from "../urls/CampaignCityReportURL";

export async function getCitySummaryData(campaignId, cityId) {
  // getting urls
  const cityOverviewUrl = getCitySummaryUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);

  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendGetRequest(cityOverviewUrl, {}, true, headerParams);
}

export async function approxMavinOts(campaignId, cityId) {
  // Request Headers
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(
    approxOtsForCityUrl
      .replace(":campaignId", campaignId)
      .replace(":cityId", cityId),
    {},
    true,
    "PUT",
    headerParams
  );
}

export async function downloadCityPdfReportFn(
  campaignId,
  cityId,
  mapCenter,
  mapZoom
) {
  const headerParams = {
    Authorization: "Bearer " + getToken(),
  };

  // string => "lat,lng"
  const centerAsString = mapCenter.join(",");

  // round off zoom
  const roundOffZoom = roundUpNearestInteger(mapZoom);

  // params
  const params = {
    center: centerAsString,
    zoom: roundOffZoom,
  };

  return downloadFile(
    downloadCityPdfReportUrl
      .replace(":campaignId", campaignId)
      .replace(":cityId", cityId),
    true,
    headerParams,
    params
  );
}

export async function downloadCityMediasPdfReportsFn(campaignId, cityId) {
  // Request Headers
  const headerParams = {
    Authorization: "Bearer " + getToken(),
  };

  return downloadFile(
    downloadCityMediasPdfReportsUrl
      .replace(":campaignId", campaignId)
      .replace(":cityId", cityId),
    true,
    headerParams
  );
}
