// Content List
export function ContentList({ list, className = "", heading }) {
  return (
    <>
      <p className="mb-2">{heading}</p>
      <ul className={className}>
        {list.map((info, i) => (
          <li key={i}>
            {info.content}
            <ul>
              {info.subList?.map((subList) => (
                <li key={subList}>{subList}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </>
  );
}

// Content Heading
export function ContentHeading({ heading, className = "" }) {
  return <h4 className={`font-weight-bold ${className}`}>{heading}:</h4>;
}

// Content Paragraph
export function ContentParagraph({ paragraph, className = "" }) {
  return <p className={className}>{paragraph}</p>;
}
