import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import {
  archiveStretch,
  getRoadStretchesOfOrg,
  restoreStretch,
  stretchPriceRefresh,
} from "../../../actions/org/OrgRoadStretchActions";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Utils
import { formatTimestamp } from "../../../common-utils/date-utils/FormatDateUtils";
import { LoadingData } from "../OrgUtils";
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";

// Components
import Spinner from "../../../components/spinner/Spinner";
import TableHeaders from "../../../components/table/TableHeaders";
import Pagination from "../../../components/pagination/Pagination";
import SearchInput from "../../../components/search-input/SearchInput";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import { CustomButton } from "../../../mavin/components/button/Button";

// Pages and Sections
import OrgSettingsSidebar from "../OrgSettingsSidebar";

// Page CSS and Includes
import "../OrgSettings.scss";

// Page-Constants
const tableHeaders = [
  {
    title: {
      displayName: "City",
    },
  },

  {
    title: {
      displayName: "Name",
    },
  },

  {
    title: {
      displayName: "Created On",
    },
  },

  {
    title: {
      displayName: "Last Updated",
    },
  },

  {
    title: {
      displayName: "Status",
    },
  },

  {
    title: {
      displayName: "Price",
    },
  },

  {
    title: {
      displayName: "Action",
    },
  },

  {
    title: {
      displayName: "",
    },
  },
];

// Page Components
// ----------------------------------------------------------------------------
function TableActionsSection({ searchText, setSearchText }) {
  // labels
  const TXT_PLACEHOLDER_SEARCH = "Search Road Stretches";

  // Functions
  const dispatch = useDispatch();
  function onClickSearch() {
    dispatch(getRoadStretchesOfOrg("", searchText));
  }

  function onSearchTextChange(e) {
    setSearchText(e.target.value);
  }

  return (
    <div className="table-header">
      <SearchInput
        placeholder={TXT_PLACEHOLDER_SEARCH}
        onClickSearch={onClickSearch}
        onTextChange={onSearchTextChange}
      />
    </div>
  );
}

function CityName({ stretchInfo }) {
  const { cityName, cityId } = stretchInfo;

  return (
    <td>
      <div>{cityName ?? cityId}</div>
    </td>
  );
}

function StretchName({ stretchInfo }) {
  const { name, id } = stretchInfo;
  return (
    <td>
      <Link
        key={id}
        to={{
          pathname: RedirectTo.orgRoadStretchViewPageUrl.replace(
            ":stretchId",
            id
          ),
        }}
      >
        {name}
      </Link>
    </td>
  );
}

function StretchCreationDate({ stretchInfo }) {
  const { createdOn } = stretchInfo;

  return (
    <td>
      <div>{new Date(createdOn).toDateString()}</div>
    </td>
  );
}

function StretchLastUpdatedOn({ stretchInfo }) {
  const { updatedOn } = stretchInfo;
  return (
    <td>
      <div>{new Date(updatedOn).toDateString()}</div>
    </td>
  );
}

function StretchStatus({ stretchInfo }) {
  const { updatedOn, isArchived } = stretchInfo;
  const status = isArchived
    ? `Archived on ${formatTimestamp(updatedOn)}`
    : "Active";
  return (
    <td>
      <div>{status}</div>
    </td>
  );
}

function StretchAction({ stretchInfo }) {
  const { id, isArchived } = stretchInfo;
  const actionProcessing = useSelector(
    (state) => state.orgRoadStretch.isStretchArchiveRestore[id]
  );

  const dispatch = useDispatch();

  function onActionClick() {
    const action = isArchived ? restoreStretch : archiveStretch;
    dispatch(action(id));
  }

  if (actionProcessing) {
    return (
      <td>
        <Spinner className="spinner-border-sm" />
      </td>
    );
  }

  return (
    <td>
      <button
        key={id}
        className="btn btn-link shadow-none p-0"
        onClick={onActionClick}
      >
        {isArchived ? "Unarchive" : "Archive"}
      </button>
    </td>
  );
}

function StretchMediaPriceAvg({ stretchInfo }) {
  return <td>{formatText(toLocaleString(stretchInfo.priceAvg))}</td>;
}

function RefreshPriceBtn({ stretchInfo }) {
  const dispatch = useDispatch();

  const { id: stretchId } = stretchInfo;

  // Selector
  const priceRefreshLoading = useSelector(
    (state) => state.orgRoadStretch.stretchPriceRefreshLoading[stretchId]
  );

  function onClick() {
    dispatch(stretchPriceRefresh(stretchId));
  }

  return (
    <td>
      <CustomButton
        displayContent={"Refresh Price"}
        onClickFunction={onClick}
        loader={priceRefreshLoading}
        isDisabled={priceRefreshLoading}
        buttonClassName={"btn btn-link shadow-none p-0"}
        spinnerClassName={"text-primary"}
      />
    </td>
  );
}

function RoadStretchRow({ stretchInfo }) {
  return (
    <tr>
      <CityName stretchInfo={stretchInfo} />
      <StretchName stretchInfo={stretchInfo} />
      <StretchCreationDate stretchInfo={stretchInfo} />
      <StretchLastUpdatedOn stretchInfo={stretchInfo} />
      <StretchStatus stretchInfo={stretchInfo} />
      <StretchMediaPriceAvg stretchInfo={stretchInfo} />
      <RefreshPriceBtn stretchInfo={stretchInfo} />
      <StretchAction stretchInfo={stretchInfo} />
    </tr>
  );
}

function RoadStretchTable({ roadStretchList, pagination, onClickPagination }) {
  const { totalCount, pageNo, pageSize } = pagination;

  // Loading til RoadStretches are fetching
  const stretchLoading = useSelector(
    (state) => state.orgRoadStretch.orgRoadStretchesLoading
  );

  if (stretchLoading) {
    return <LoadingData className="p-1" dataType="Road Stretches" />;
  }

  return (
    <div className="table-responsive">
      <table className="table">
        {/* Table Header */}
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />

        {/* Table Row */}
        <tbody>
          {roadStretchList.map((eachStretch) => (
            <RoadStretchRow key={eachStretch.id} stretchInfo={eachStretch} />
          ))}
        </tbody>
      </table>

      {/* Table-pagination */}
      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={onClickPagination}
      />
    </div>
  );
}

/**
 * Page
 */
export default function RoadStretchListPage() {
  // State
  const [searchText, setSearchText] = useState("");

  // State : Data
  const roadStretchList = useSelector(
    (state) => state.orgRoadStretch.orgRoadStretches
  );
  const pagination = useSelector(
    (state) => state.orgRoadStretch.orgRoadStretchesPagination
  );

  const pageTitle = "Org :: Road Stretches";
  const pageActions = (
    <Link className="btn btn-primary" to={RedirectTo.orgRoadStretchCreateUrl}>
      Create new Road Stretch
    </Link>
  );

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRoadStretchesOfOrg(false));
  }, [dispatch]);

  function onClickPagination(pageNumber, pageSize) {
    dispatch(
      getRoadStretchesOfOrg(false, "", searchText, pageNumber, pageSize)
    );
  }

  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={5} />

      <div className="content-wrapper">
        {/** Page Header */}
        <PageHeader title={pageTitle} actions={pageActions} shadow={true} />

        {/** Page Content */}
        <div className="page-content">
          {/** Table Header actions */}
          <TableActionsSection
            searchText={searchText}
            setSearchText={setSearchText}
          />

          {/** Road-Stretch Table component */}
          <RoadStretchTable
            roadStretchList={Object.values(roadStretchList)}
            pagination={pagination}
            onClickPagination={onClickPagination}
          />
        </div>
      </div>
    </>
  );
}
