import { ActionTypes } from "../../constants/ActionConstants";

export const addCpmPriceMediaImpact = (mediaId, otsSplit, duration) => ({
  type: ActionTypes.SubmissionImpact.ADD_CPM_PRICE_MEDIA_FOR_SUBMISSION_IMPACT,
  payload: { mediaId, otsSplit, duration },
});

export const removeCpmPriceMediaImpact = (mediaId) => ({
  type: ActionTypes.SubmissionImpact
    .REMOVE_CPM_PRICE_MEDIA_FOR_SUBMISSION_IMPACT,
  payload: { mediaId },
});

export const addFixedPriceMediaImpact = (
  mediaId,
  otsSplit,
  cost,
  duration
) => ({
  type: ActionTypes.SubmissionImpact
    .ADD_FIXED_PRICE_MEDIA_FOR_SUBMISSION_IMPACT,
  payload: { mediaId, otsSplit, cost, duration },
});

export const removeFixedPriceMediaImpact = (mediaId) => ({
  type: ActionTypes.SubmissionImpact
    .REMOVE_FIXED_PRICE_MEDIA_FOR_SUBMISSION_IMPACT,
  payload: { mediaId },
});
