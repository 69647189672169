import {
  createCampaignPlanUrl,
  getCampaignPlanByCampaignIdUrl,
  getCampaignPlanByCityUrl,
  updateCampaignPlanUrl,
  updateCampaignPlanStatusUrl,
} from "../urls/CampaignPlanURL";
import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

export async function getCampaignPlan(campaignId, cityId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getCampaignPlanByCityUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);

  return sendGetRequest(url, {}, true, headerParams);
}

export async function getCampaignPlanOverview(campaignId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getCampaignPlanByCampaignIdUrl.replace(":campaignId", campaignId);

  return sendGetRequest(url, {}, true, headerParams);
}

export async function createCampaignPlan(campaignPlanBean) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(
    createCampaignPlanUrl,
    campaignPlanBean,
    true,
    "POST",
    headerParams
  );
}

export async function updateCampaignPlan(campaignId, campaignPlanBean) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const url = updateCampaignPlanUrl.replace(":campaignId", campaignId);

  return sendCreateOrUpdateRequest(
    url,
    campaignPlanBean,
    true,
    "PUT",
    headerParams
  );
}

export async function updateCampaingPlanStatus(campaignId, status) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const params = {
    status: status,
  };
  const url = updateCampaignPlanStatusUrl.replace(":campaignId", campaignId);
  return sendCreateOrUpdateRequest(url, {}, true, "PUT", headerParams, params);
}
