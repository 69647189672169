// Components
import { PlainDropdown } from "../../../components/dropdown/Dropdown";
import Spinner from "../../../components/spinner/Spinner";

// Constants
const dropdownItems = [
  { id: 1000, label: "1 KM" },
  { id: 2000, label: "2 KM" },
  { id: 3000, label: "3 KM" },
  { id: 4000, label: "4 KM" },
  { id: 5000, label: "5 KM" },
];

/**
 * Radius Selector
 */
function RadiusSelector({ onItemSelect, isLoading, title, className = "" }) {
  return (
    <div className={`d-flex align-items-center ${className}`}>
      {title && <h4>{title}</h4>}
      <PlainDropdown
        items={dropdownItems}
        onItemSelect={onItemSelect}
        className="h4 border-0"
        textClassName="text-primary"
      />
      {isLoading && <Spinner className="spinner-border-sm mb-2 mx-1" />}
    </div>
  );
}

export default RadiusSelector;
