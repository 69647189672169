export const OrgMediaPrice = {
  // Update Media Price
  UPDATE_MEDIA_PRICE: "UPDATE_MEDIA_PRICE",
  UPDATE_MEDIA_PRICE_SUCCESS: "UPDATE_MEDIA_PRICE_SUCCESS",
  UPDATE_MEDIA_PRICE_FAILURE: "UPDATE_MEDIA_PRICE_FAILURE",

  // Get media price by ids
  GET_MEDIA_PRICE_BY_IDS: "GET_MEDIA_PRICE_BY_IDS",
  GET_MEDIA_PRICE_BY_IDS_SUCCESS: "GET_MEDIA_PRICE_BY_IDS_SUCCESS",
  GET_MEDIA_PRICE_BY_IDS_FAILURE: "GET_MEDIA_PRICE_BY_IDS_FAILURE",
};
