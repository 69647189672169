import moment from "moment";

/**
 * Calculates the difference between two dates (date created by using new Date()) in days
 *
 * @param {*} dateObj => {startDate : "someDate", endDate: "someDate"}
 * @returns  difference in days
 * @deprecated use new function -> getDifferenceInDays
 */
export function getDifferenceInDaysOfDateObject(dateObj) {
  if (!dateObj || !dateObj.startDate || !dateObj.endDate) {
    return 0;
  }
  const endDateMoment = moment(dateObj.endDate);
  const startDateMoment = moment(dateObj.startDate);
  const diff = endDateMoment.diff(startDateMoment);
  return moment.duration(diff).days();
}
/**
 * Calculates the difference between two dates in days
 *
 * @param {*} startDate ==> StartTimeStamp
 * @param {*} endDate ==> EndTimeStamp
 * @returns  difference in days
 */

export function getDifferenceInDays(startTimestamp, endTimestamp) {
  const diff = endTimestamp - startTimestamp;
  const baseTimestamp = 24 * 60 * 60 * 1000;
  return Math.ceil(diff / baseTimestamp) + 1;
}

/**
 * calculates the difference between two date moment objects
 *
 * @param {*} startDate ==> Date in moment object
 * @param {*} endDate ==> Date in moment object
 * @returns  difference in days
 */
export function getDiffInDaysOfMomentObject(startDate, endDate) {
  if (!startDate || !endDate) {
    return 0;
  }
  const diff = endDate.diff(startDate);
  return moment.duration(diff).days();
}

export function isDate1GreaterThanDate2(date1, date2) {
  // Check if date1 > date2
  return comapreDates(date1, date2) === 1;
}

export function comapreDates(date1, date2) {
  // we are noramlising the time of both the dates to compare only the dates
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);

  if (date1 > date2) {
    return 1;
  } else if (date1 < date2) {
    return -1;
  }

  // where both dates are equal..
  return 0;
}

/**
 *Construct date-String in the given format...
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} format
 * @returns "startDate - endDate"
 */
export function constructDateString(startDate, endDate, format) {
  const startDateMoment = moment(startDate);
  if (!endDate) {
    return `${startDateMoment.format(format)}`;
  }
  const endDateMoment = moment(endDate);
  return `${startDateMoment.format(format)} - ${endDateMoment.format(format)}`;
}

/**
 *Construct single-date-String in the given format...
 * @param {*} timestamp
 * @param {*} format
 * @returns "singleDateString"
 */
export function constructSingleDateString(timestamp, format) {
  if (!timestamp) {
    return null;
  }

  const startDateMoment = moment(timestamp);
  return `${startDateMoment.format(format)}`;
}

/**
 * @param {*} timestamp
 * @returns the Time in "Hours:Minutes AM/PM"
 */
export function getTimeInHoursAndMinutes(timestamp) {
  if (!timestamp) {
    return null;
  }

  // 12Hr format (indian standard time, eg: 02:40 pm )
  return moment(timestamp).format("hh:mm a");
}

/**
 *
 * @param {*} dateString
 * @param {*} format
 * @returns  the timestamp in ms
 */
export function getTimestamp(dateString, format) {
  if (!dateString) {
    return null;
  }
  return moment(dateString, format).valueOf();
}
