import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

// Actions
import {
  applyFilters,
  cancelFilters,
  selectFilter,
  unSelectFilter,
} from "../../actions/filter/FilterActions";

// Utils And Constants..
import { MediaType } from "../../constants/GeneralConstants";

// CSS
import "./Filter.scss";

function filterSelectedFilters(filters, accumulator) {
  return Object.keys(filters).reduce((acc, eachKey) => {
    if (!filters[eachKey]) {
      return acc;
    }
    acc[eachKey] = true;
    return acc;
  }, accumulator);
}

function Filter({ onFiltersApplied, pageSize = 10, className = "" }) {
  const dispatch = useDispatch();

  const finalFilters = useSelector((state) => {
    // Note : Here we are using accumulator as {} rather than []
    // Because deleting of element from {} is easy when compared to []
    const currentFilters = filterSelectedFilters(
      state.filter.currentFilters,
      {}
    );

    const appliedAndCurrentFilters = filterSelectedFilters(
      state.filter.appliedFilters,
      currentFilters
    );

    //  Below logic handles the case where we unselect from the already
    // appliedFilters..
    const requiredFilterKeys = Object.keys(state.filter.currentFilters).reduce(
      (acc, eachKey) => {
        if (state.filter.currentFilters[eachKey]) {
          return acc;
        }
        delete acc[eachKey];
        return acc;
      },
      appliedAndCurrentFilters
    );

    return Object.keys(requiredFilterKeys);
  });

  function toggleFilter(e, filterKey) {
    const action = e.target.checked ? selectFilter : unSelectFilter;
    dispatch(action(filterKey));
  }

  function applyCurrentFilters() {
    const filtersToApply = finalFilters.reduce((acc, eachKey) => {
      acc[eachKey] = true;
      return acc;
    }, {});
    dispatch(applyFilters(filtersToApply));
    onFiltersApplied(1, pageSize);
  }

  function cancelCurrentFilters() {
    dispatch(cancelFilters());
  }

  return (
    <div
      className="position-relative d-flex align-items-center"
      data-html2canvas-ignore="true"
    >
      <button
        id="btnGroupDrop4"
        type="button"
        data-toggle="dropdown"
        className={`btn ml-4 btn-outline-secondary ${className}`}
        aria-haspopup="true"
        aria-expanded="false"
      >
        Filter<i className="fas fa-sliders-h ml-3"></i>
      </button>
      <div
        className="dropdown-menu dropdown-menu-right filter-dropdown "
        aria-labelledby="btnGroupDrop4"
      >
        {" "}
        {Object.entries(MediaType).map((eachFilter) => {
          return (
            <p key={eachFilter[0]}>
              <input
                type="checkbox"
                checked={finalFilters.includes(eachFilter[0])}
                onChange={(e) => toggleFilter(e, eachFilter[0])}
              ></input>{" "}
              {eachFilter[1]}
            </p>
          );
        })}
        <button
          className="btn btn-outline-primary"
          onClick={cancelCurrentFilters}
        >
          Cancel
        </button>
        <button className="btn btn-primary ml-3" onClick={applyCurrentFilters}>
          Apply filters
        </button>
      </div>
    </div>
  );
}

export default Filter;
