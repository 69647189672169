import { Link } from "react-router-dom";

// Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// CSS
import "../CampaignMonitoring.scss";

// Page-Constants
const excelUploadData = {
  image: <i className="far fa-copy fa-2x"></i>,
  title: "Upload excel",
  text: "Upload your shortlisted site list to kick start your monitoring",
  buttonLabel: "Upload site list",
  linkTo: RedirectTo.monitorCampaignCreatePageUrl,
};

const pickMapData = {
  image: <i className="fas fa-map-marker-alt fa-2x"></i>,
  title: "Pick from map view",
  text: "Analyze the map region and pick sites you would like to monitor",
  buttonLabel: "Start with map view",
  linkTo: RedirectTo.monitorSelectionViaMapUrl,
};

function Card({ data }) {
  const { image, title, text, buttonLabel, linkTo } = data;
  return (
    <div className="card col-3 px-0 mx-3 shadow-lg rounded-lg">
      <div className="card-body">
        <div className="font-icon text-primary">{image}</div>
        <div className="px-5">
          <h4 className="font-weight-bold">{title}</h4>
          <h5 className="text-muted">{text}</h5>
        </div>
        <div className="my-4">
          <Link className="btn btn-primary btn__draw" to={linkTo}>
            {buttonLabel}
          </Link>
        </div>
      </div>
    </div>
  );
}

function PageCards() {
  return (
    <div className="d-flex justify-content-center mt-4 text-center">
      <Card data={excelUploadData} />
      <Card data={pickMapData} />
    </div>
  );
}

/**
 * Page
 */
function MonitoringHomePage() {
  return (
    <div className="content-wrapper bg-alt h-100">
      {/* Page-Header */}
      <PageHeader
        title={"Select media sites to monitor"}
        shadow={false}
        border={false}
      />

      {/* Cards */}
      <div className="page-content">
        <PageCards />
      </div>
    </div>
  );
}

export default MonitoringHomePage;
