import { useDispatch } from "react-redux";

// Actions
import { unSelectBoundaries } from "../../../actions/map-view/BoundariesActions";

// Tab Section
function SelectedBoundariesSection({ selectedBoundary = {} }) {
  const dispatch = useDispatch();

  const { parentLabel, childLabel, id } = selectedBoundary;

  return (
    <div className="d-flex justify-content-between py-3">
      <span>
        {parentLabel}
        {" :: "}
        {childLabel}
      </span>
      <button
        className="btn add-button btn-outline-primary shadow-none"
        onClick={() => dispatch(unSelectBoundaries(id))}
      >
        {"Remove"}
      </button>
    </div>
  );
}

export default SelectedBoundariesSection;
