import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getCampaignOverview } from "../../../actions/campaign-report/CampaignReportActions";
import {
  downloadCampaignGraphPdfReport,
  getCampaignMediaDemographicStats,
} from "../../../actions/graph-reports/CampaignGraphReportActions";

// Utils and Constants
import { MetricKeys } from "../GraphReportsConstants";
import { India } from "../../../constants/GeneralConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Page Components
import CampaignMetricsAndMapSection from "./CampaignMetricsAndMapSection";
import CampaignImpressionsSplitSection from "./CampaignImpressionsSplitSection";
import CampaignReachSplitSection from "./CampaignReachSplitSection";
import CampaignMediaGenderRatioAgeSplitSection from "./CampaignMediaGenderRatioAgeSplitSection";
import CampaignOverviewTable from "./CampaignOverviewTable";

// Download Graph Report
function DownloadGraphReport({
  campaignId,
  campaignTitle,
  selectedMetric,
  selectedCityId,
  mapCenter,
  mapZoom,
}) {
  const dispatch = useDispatch();

  // Selector
  const downloadGraphReportLoading = useSelector(
    (state) => state.campaignGraphReport.downloadCampaignGraphPdfReportLoading
  );

  function handleDownloadReport() {
    dispatch(
      downloadCampaignGraphPdfReport(
        campaignId,
        campaignTitle,
        selectedCityId,
        selectedMetric,
        mapCenter,
        mapZoom
      )
    );
  }

  // Checks for downloading report
  if (downloadGraphReportLoading) {
    return <Spinner />;
  }

  return (
    <p
      className="text-primary cursor-pointer my-2"
      onClick={handleDownloadReport}
      data-html2canvas-ignore="true"
    >
      Download Graph Report
    </p>
  );
}

/**
 * Campaign Graph Report Page
 */
function CampaignGraphReportPage() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  // States
  const [selectedMetric, setSelectedMetric] = useState(MetricKeys.impressions);
  const [selectedCityId, setSelectedCityId] = useState("");
  const [mapCenter, setMapCenter] = useState(India.mapCenter);
  const [mapZoom, setMapZoom] = useState(India.mapZoomLevel5);

  // Selector
  const { campaign, summary } = useSelector(
    (state) => state.campaignReport.campaignSummary
  );
  const campaignSummaryLoading = useSelector(
    (state) => state.campaignReport.campaignSummaryLoading
  );

  // Campaign Media Demographic stats
  const campaignCityWiseMedias = useSelector(
    (state) => state.campaignGraphReport.campaignCityWiseMedias
  );
  const campaignMediaDemographicStatsLoading = useSelector(
    (state) => state.campaignGraphReport.campaignMediaDemographicStatsLoading
  );

  // Dispatch
  useEffect(() => {
    // overview
    dispatch(getCampaignOverview(campaignId));

    // demographic stats for media
    dispatch(getCampaignMediaDemographicStats(campaignId));
  }, [dispatch, campaignId]);

  // Checks for page loading
  if (campaignSummaryLoading || campaignMediaDemographicStatsLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  // Checks for campaign and summary
  if (!campaign || !summary) {
    return null;
  }

  // data
  const { infoByCity, mediaCount, title = "" } = campaign;
  const isMediaExist = Boolean(mediaCount);

  // page actions
  const pageActions = (
    <DownloadGraphReport
      campaignId={campaignId}
      campaignTitle={title}
      selectedMetric={selectedMetric}
      selectedCityId={selectedCityId}
      mapCenter={mapCenter}
      mapZoom={mapZoom}
    />
  );

  return (
    <div className="content-wrapper">
      {/* Page Header */}
      <PageHeader title="Campaign Graph Report" actions={pageActions} />

      {/* Page Contents */}
      <div className="page-content">
        {/* Metrics and Map Section */}
        <CampaignMetricsAndMapSection
          selectedMetric={selectedMetric}
          setSelectedMetric={setSelectedMetric}
          mapCenter={mapCenter}
          setMapCenter={setMapCenter}
          mapZoom={mapZoom}
          setMapZoom={setMapZoom}
        />

        {isMediaExist && (
          <>
            {/* impressions split section */}
            <CampaignImpressionsSplitSection infoByCity={infoByCity} />

            {/* reach split section */}
            <CampaignReachSplitSection infoByCity={infoByCity} />

            {/* Media gender-ratio and age-split section */}
            <CampaignMediaGenderRatioAgeSplitSection
              campaignCityWiseMedias={campaignCityWiseMedias}
              selectedCityId={selectedCityId}
              setSelectedCityId={setSelectedCityId}
            />

            {/* Campaign Overview Table */}
            <CampaignOverviewTable infoByCity={infoByCity} />
          </>
        )}
      </div>
    </div>
  );
}

export default CampaignGraphReportPage;
