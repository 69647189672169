// Utils and Constants
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";

// Section Components
function TgName({ displayTest }) {
  return (
    <td>
      <div>
        <p className="mb-0">{displayTest}</p>
      </div>
    </td>
  );
}

function CPM({ cpm }) {
  return (
    <td>
      <span>{cpm ? cpm : "XXX"}</span>
    </td>
  );
}

function Cost({ cost }) {
  const costString = Number(cost) !== 0 ? cost : "NA";
  return (
    <td>
      <span>{costString}</span>
    </td>
  );
}

function Impressions({ ots }) {
  const otsString = Number(ots) !== 0 ? toLocaleString(ots) : "NA";
  return (
    <td>
      <span>{otsString}</span>
    </td>
  );
}

function SubmissionImpact({ plannedValue, estValue }) {
  if (Number(estValue) === 0) {
    return (
      <td>
        <span>{"NA"}</span>
      </td>
    );
  }
  let percentageClass = "bg-danger";
  const diff = estValue - plannedValue;
  if (diff > 0) {
    percentageClass = "bg-success";
  } else if (diff === 0) {
    percentageClass = "";
  }
  const diffPer = (Math.abs(diff) / plannedValue) * 100;
  return (
    <td>
      <span>{diff === 0 ? "No Impact" : toLocaleString(Math.abs(diff))}</span>
      <small
        className={`${percentageClass} ml-2 py-1 px-2 text-white rounded-lg`}
      >
        {diffPer.toFixed()}%
      </small>
    </td>
  );
}

/**
 * TG Details Row Section
 */
export function TgDetailsRow({ tgDetails }) {
  const {
    otsPlanned,
    costPlanned,
    cpm,
    displayTest,
    estOtsAndCost,
    submissionImpact,
  } = tgDetails;
  const { estOtsCpm, estCostCpm, estOtsFixed, estCostFixed } = estOtsAndCost;

  return (
    <tr>
      {/* Tg Name*/}
      <TgName displayTest={displayTest} />

      {/* Cpm Details*/}
      <CPM cpm={cpm} />

      {/* Planned Ots And Cost*/}
      <Impressions ots={otsPlanned} />
      <Cost cost={costPlanned} />

      {/* Est Ots And Cpm Cost*/}
      <Impressions ots={estOtsCpm} />
      <Cost cost={estCostCpm} />

      {/* Est Ots And Fixed Cost*/}
      <Impressions ots={estOtsFixed} />
      <Cost cost={estCostFixed} />

      {/* SubmissionImpact for Ots*/}
      {submissionImpact && (
        <SubmissionImpact
          plannedValue={otsPlanned}
          estValue={estOtsCpm + estOtsFixed}
        />
      )}

      {/* SubmissionImpact for Cost*/}
      {submissionImpact && (
        <SubmissionImpact
          plannedValue={costPlanned}
          estValue={estCostCpm + estCostFixed}
        />
      )}
    </tr>
  );
}
