import { useSelector } from "react-redux";

// Constants
import { ProgressTexts } from "../../constants/GeneralConstants";

// Components
import { Progress } from "../progress-status/Progress";

// this returns the "success/error" message after uploading excel-file
function SuccessAndErrorStatus({ mediaCount, excelErrors }) {
  const excelErrorKeys = Object.keys(excelErrors);

  if (mediaCount) {
    return (
      <p className="text-success">
        <i className="fa fa-check-circle" aria-hidden="true"></i> {mediaCount}{" "}
        sites succesfully uploaded.
      </p>
    );
  }

  if (excelErrorKeys.length > 0) {
    return (
      <div className="text-danger">
        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
        {excelErrorKeys.length} errors found. Please fix them and uploading
        again. Errors are listed below
        <br />
        {excelErrorKeys.map((errorKey, i) => {
          return (
            <div key={i}>
              <span className="text-danger">
                {"-->"} {errorKey}:{excelErrors[errorKey]}
              </span>
              <br></br>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}

function UploadTemplateCard({
  uploadSiteListData,
  reducerKeyValuesObj,
  uploadTemplateFunction,
  isUploadTemplateButtonDisabled = false,
}) {
  const { step, title, message, buttonLabel } = uploadSiteListData;
  const uploadProgressTexts = ProgressTexts.Uploads;
  // reducer states for upload-component
  const { reducerKey, selectorValue } = reducerKeyValuesObj;

  const loading = useSelector(
    (state) => state[reducerKey][selectorValue.loading]
  );
  const excelErrors = useSelector(
    (state) => state[reducerKey][selectorValue.error]
  );
  const mediaCount = useSelector(
    (state) => state[reducerKey][selectorValue.mediaCount]
  );

  return (
    <div className="card shadow mb-4 mt-3">
      <div className="d-flex justify-content-between">
        <div className="card-content">
          <p className="card__step">{step}</p>
          <p className="card__title">{title}</p>
          <p className="card__message">
            {message}
            <a className="text-primary"> Learn more</a>
          </p>
          <SuccessAndErrorStatus
            mediaCount={mediaCount}
            excelErrors={excelErrors}
          />
        </div>
        {loading ? (
          <Progress className="mr-4" progressTexts={uploadProgressTexts} />
        ) : (
          <div className="card-action">
            <button
              className="btn btn-primary btn-action mt-5"
              onClick={uploadTemplateFunction}
              disabled={isUploadTemplateButtonDisabled}
            >
              {buttonLabel}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default UploadTemplateCard;
