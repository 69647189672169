export function isDivDisabled(index, isFromCampaignPlanner) {
  // index === 1 (is MediaTab)
  // we are disabling map view tab except media sites
  // so for that we are checking the condition -> index !==1
  // except first index(media sites) we are disable all other tab
  if (!isFromCampaignPlanner && index !== 1) {
    return true;
  }
  return false;
}
