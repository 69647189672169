import React from "react";

function SubHeader({ subTitle = {} }) {
  return (
    <span className={`sub-text ${subTitle.className}`}>
      {subTitle.displayName ? subTitle.displayName : <span>&nbsp;</span>}
    </span>
  );
}

function Header({ header, requiredSubHeader }) {
  if (header.element) {
    return header.element;
  }
  const { title, subTitle } = header;

  return (
    <th className={title.className}>
      {title.displayName}
      {requiredSubHeader && <SubHeader subTitle={subTitle} />}
    </th>
  );
}

function TableHeaders({
  tableHeaders,
  headerClass,
  scope,
  requiredSubHeader = true,
}) {
  return (
    <thead className={headerClass} scope={scope}>
      <tr>
        {tableHeaders.map((header, index) => (
          <Header
            header={header}
            key={index}
            requiredSubHeader={requiredSubHeader}
          />
        ))}
      </tr>
    </thead>
  );
}

export default TableHeaders;
