import { ActionTypes } from "../../../constants/ActionConstants";

const initialState = {
  poiDetails: [],
  loading: false,
  err: "",

  // Below details stores the "poi-selections"
  selectedPoi: {},
};

function removeExactLatLong(exactLatLong, poiDetails, selectedPoi) {
  const newExistingLatLongArray = poiDetails.reduce((acc, eachLatLong) => {
    const eachLatLongString = eachLatLong.trace.join();
    if (eachLatLongString === exactLatLong) {
      return acc;
    }
    acc.push(eachLatLong);
    return acc;
  }, []);

  delete selectedPoi[exactLatLong];
  const newSelectedPoi = { ...selectedPoi };
  return { newExistingLatLongArray, newSelectedPoi };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ExactLatLong.ADD_EXACT_LAT_LONG: {
      const poiTitle = action.payload.poiName;
      const exactLatLong = action.payload.exactLatLong;
      return {
        ...state,
        poiDetails: [
          ...state.poiDetails,
          {
            trace: exactLatLong,
            title: poiTitle ? poiTitle : "Added POI",
          },
        ],
        selectedPoi: {
          ...state.selectedPoi,
          [action.payload.exactLatLong]: true,
        },
      };
    }

    case ActionTypes.ExactLatLong.REMOVE_EXACT_LAT_LONG: {
      const exactLatLong = action.payload.exactLatLong;
      const { newExistingLatLongArray, newSelectedPoi } = removeExactLatLong(
        exactLatLong,
        state.poiDetails,
        state.selectedPoi
      );
      return {
        ...state,
        poiDetails: newExistingLatLongArray,
        selectedPoi: newSelectedPoi,
      };
    }

    case ActionTypes.ExactLatLong.RESET_POI_SELECTION:
      return initialState;

    default:
      return state;
  }
};
