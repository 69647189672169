import { all, put, takeLatest } from "redux-saga/effects";

// Api
import {
  createCampaignMedia,
  getCampaignMediaByCity,
} from "../../../apis/CampaignMediaAPI";

// Constants
import { ExploreSites } from "../../../constants/action-constants/map-view/media-sites/ExploreSitesActionConstants";
import { MediaSites } from "../../../constants/action-constants/map-view/media-sites/MediaSitesActionConstants";
import { defaultPagination } from "../../../constants/UrlConstants";
import { CampaignMedia } from "../../../constants/action-constants/CampaignMediaActionConstants";

export function* addExploreSiteToCampaign(action) {
  const { campaignId, mediaList } = action.payload;
  const mediaIds = mediaList.map((media) => media.mediaId);
  const cityId = mediaList[0].cityId;

  try {
    // api call to add inventory media to campaign
    yield createCampaignMedia(campaignId, "", mediaList);

    // api call to refresh campaign media list after media is added
    const data = yield getCampaignMediaByCity(
      campaignId,
      cityId,
      defaultPagination.pageNo,
      defaultPagination.pageSize
    );

    yield put({
      type: ExploreSites.ADD_EXPLORE_SITE_SUCCESS,
      payload: { mediaIds },
    });

    yield put({
      type: MediaSites.GET_MEDIA_SITES_SUCCESS,
      payload: data,
    });

    yield put({
      type: CampaignMedia.CAMPAIGN_CITYVIEW_SUCCESS,
      data: data,
    });
  } catch (err) {
    yield put({
      type: ExploreSites.ADD_EXPLORE_SITE_FAILURE,
      payload: { err, mediaIds },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ExploreSites.ADD_EXPLORE_SITE, addExploreSiteToCampaign),
  ]);
}
