export const SaveMapState = {
  Save: {
    SAVE_MAP_CONTAINER: "SAVE_MAP_CONTAINER",
    SAVE_MAP_STATE: "SAVE_MAP_STATE",
    SAVE_MAP_STATE_SUCCESS: "SAVE_MAP_STATE_SUCCESS",
    SAVE_MAP_STATE_FAILURE: "SAVE_MAP_STATE_FAILURE",
  },

  Restore: {
    RESTORE_SAVE_MAP_STATE: "RESTORE_SAVE_MAP_STATE",
    RESTORE_SAVE_MAP_STATE_SUCCESS: "RESTORE_SAVE_MAP_STATE_SUCCESS",
    RESTORE_SAVE_MAP_STATE_FAILURE: "RESTORE_SAVE_MAP_STATE_FAILURE",
  },
};
