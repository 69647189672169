/**
 * format time in "AM" : "PM"
 * @param {*} hour : hour in string or number : 1,2,3,11 ...
 */
export function formatTime(hour) {
  const hour24 = parseInt(hour);
  const formattedHour = hour24 % 12 || 12;
  const period = hour24 < 12 ? "AM" : "PM";
  return `${formattedHour} ${period}`;
}
