// Utils and Constants
import { ContentHeading, ContentList, ContentParagraph } from "./HelpPageUtils";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Components
import HelpSidebar from "./HelpSidebar";
import PageHeader from "../../mavin/components/page-header/PageHeader";

function OtsDescription() {
  const otsDescription = `The basis for estimating the total number of impressions for any OOH site is its geo location (Latitudinal and
  Longitudinal coordinates). As all the inventory on our platform is geo-tagged, we are aware of the exact location of the site as well
  as the road(s) from which it would be visible. Basis this, we use the following 3 inputs for estimating the number of impressions:`;

  const otsDescriptionList = [
    { content: "Traffic speed estimates from Google Traffic API" },
    { content: "Data for number of lanes in each road from Open Street Maps" },
    {
      content:
        "Mode-wise traffic distribution data from DULT and other government bodies.",
    },
  ];

  const OtsDescriptionFooter = `The following section talks in detail about each of the inputs and the data that is derived from the same. 
    The process for estimating the no. of impressions from these 3 inputs is explained thereafter.`;

  return (
    <>
      <ContentParagraph paragraph={otsDescription} />
      <ContentList list={otsDescriptionList} className="font-weight-bold" />
      <ContentParagraph paragraph={OtsDescriptionFooter} />
    </>
  );
}

function GoogleTrafficApi() {
  const googleTrafficApiContentOne = `Google provides APIs (Application Programming Interface) for estimating the time taken for travelling
  between any 2 locations (This is how Google provides an Estimated Time of Arrival (ETA) when you are travelling from one place to another).
  We are using this API to reverse engineer the traffic speeds on the road(s) next to the OOH site and building a traffic profile using the same.`;

  const googleTrafficApiContentTwo = `As we already know the geo-location of the site, we first identify the road(s) which is closest to the site.
  Once the road(s) have been identified, we take 2 points on the road and query Google to return the time taken to travel between these 2 points,
  which gives us ‘t’. As we know the distance ‘d’ between the 2 points which we have selected, we use simple physics to calculate the current speed
  (v) = d/t.`;

  const googleTrafficApiContentThree = `The speed data is collected continuously for the entire day, starting at 6 AM in the morning and up till
  11 PM in the night. For each hour, we query Google for the ‘t’ and calculate the ‘v’. For each day, we collect 18 data points. This process is
  repeated for an entire month (so as to average out any traffic speed variations on a particular day). Once this data is collected, we can then
  plot the traffic speed profile for the road on which the site is located (shown below): As we are collecting 30 data points (1 point for each
  day of the month) for each hour of the day, we are also able to show the profiles for the max. observed speed at each hour, the minimum observed
  speed at each hour and the avg. speed profiles (which is the monthly average of the speeds which are observed).`;

  return (
    <>
      <ContentHeading heading="Google Traffic APIs" />
      <ContentParagraph paragraph={googleTrafficApiContentOne} />
      <ContentParagraph paragraph={googleTrafficApiContentTwo} />
      <ContentParagraph paragraph={googleTrafficApiContentThree} />
    </>
  );
}

function OpenStreetMaps() {
  const OpenStreetMapsContent = `As the name suggests, Open Street Maps (OSM) is an open-source mapping repository. The data on OSM is crowd-sourced
  (as was done with Google Maps when it was launched initially). The advantage with OSM is that all the geo data, in terms of points of interest,
  road details etc. is readily available. We have used the data from OSM to get the details of the no. of lanes on each road across all cities in
  India. The assumption here is that the more the traffic on the road, the higher will be the no. of lanes on that road and hence getting the data 
  for the no. of lanes on each road will help us in estimating the total traffic (and therefore the total estimated impressions) for each road.`;

  return (
    <>
      <ContentHeading heading="Open Street Maps" />
      <ContentParagraph paragraph={OpenStreetMapsContent} />
    </>
  );
}

function ModeWiseTrafficDistribution() {
  const ModeWiseTrafficDistributionContent = `While from the above two inputs, we get the speed and the no. of lanes for traffic movement, we still
  don’t have any data on the traffic distribution, i.e. what is the actual composition of the vehicles on the road. This data is derived from the
  Directorate of Urban Land Transport (DULT) and other government bodies (such as Traffic Police, RTO etc.) or research organizations. What DULT
  essentially does is divide bigger cities such as Bangalore into smaller sub-zones. These zones can vary in size, with the avg. radius of each
  zone in Bangalore being approximately 4.5 km. For each sub-zone, they give a mode-wise traffic breakup of the vehicles on the road. In other 
  words, they tell us what percentage of traffic is made up of cars, taxis, two-wheelers, autos, buses etc. A sample data set for one sub-zone
  is as listed below:`;

  const percentageTrafficCompositionContent = `Walk 5.5 2-Wheelers 31.8 3-Wheelers (Autos) 10.3 Cars & Taxis 41.2 Buses 3.9 LCVs 3.9 Trucks 3.4 
  This helps us identify the actual traffic composition on the road next to the OOH site. That is, given 100 vehicles passing by a given site, 
  what would be the breakup of the type of vehicles is given by the data from DULT.`;

  return (
    <>
      <ContentHeading heading="Mode-wise Traffic Distribution" />
      <ContentParagraph paragraph={ModeWiseTrafficDistributionContent} />
      <ContentHeading heading="Transportation Mode Percentage Traffic Composition" />
      <ContentParagraph paragraph={percentageTrafficCompositionContent} />
    </>
  );
}

function CalculatingTheTotalNoOfImpressions() {
  const totalImpressionContent = `Using the above mentioned inputs, we estimate the no. of impressions as follows:`;

  const totalImpressionList = [
    {
      content: `Based on the geo-location of the site, we identify the closest road from which the site would be visible.`,
    },
    {
      content: `To begin with, we assume that this road has only 1 lane for ease of calculations and the width of this lane is assumed to be
      3.5 meters (standard lane width in India).`,
    },
    {
      content: `Using the DULT data, we identify the sub-zone within which this road is located and use that to determine the mode-wise traffic
      breakup on the given road.`,
    },
    {
      content: `Assuming that there are 100 vehicles on the road, we know the total number of vehicles of each type (i.e. of the 100 vehicles, we
      know that there will be 30 2-Wheelers, 20 Autos, 40 Cars and 10 Buses for example).`,
    },
    {
      content: `As the vehicles are moving at a given speed, we assume that the bumper-to-bumper space between the vehicles = 2 seconds * vehicle
      speed (the time of 2 seconds is taken as this is the standard reaction time) and the side-to-side distance between the vehicles is assumed to be
      0.5 meters.`,
    },
    {
      content: `Next, we assume a standard size for each type of vehicle. For example, we assume that the standard size of a bike is 1.5 m x 0.5 m 
      (length x breadth). Similarly, the standard size of a car is assumed to be 4.5 m x 2 m.`,
    },
    {
      content: `With these standard vehicular sizes and the inter-vehicular distances listed in #5 above, we calculate the total length of the road
      which would be required to fit the 100 vehicles (with the breakup mentioned in #4), where the width of the lane = 3.5 m.`,
    },
    {
      content: `Let us suppose that this length is L. We now know that in a single lane of width 3.5 m and length ‘L’, we can fit 100 vehicles in the
      proportion mentioned in #4.`,
    },
    {
      content: `From Google Traffic APIs, we know that these vehicles are traveling at the speed of ‘v’.`,
    },
    {
      content: `Therefore, the rate total distance that can be travelled in 1 day (from 6 AM in the morning to 10 PM at night) = speed * time = v * 16`,
    },

    {
      content: `Given that 100 vehicles fit in a road of length L, the number of vehicles that would fit in a road of length 16v = 16v/L.`,
    },
    {
      content: `From #11, we get the total number of vehicles that would pass by the site in 1 day. We also know the mode-wise breakup of these vehicles,
      determined using the DULT data. In other words, if a total of 10000 vehicles pass by the given site in a day, we know that 30% of these (i.e. 3000)
      would be 2-Wheelers, 20% (i.e. 2000) would be autos and so on.`,
    },
    {
      content: `We now assume that there are ‘x’ passengers on each mode of transport. The value of x is as given below:`,
      subList: [
        "1 for Walk",
        "1.5 for 2-Wheelers",
        "2.5 for Autos",
        "3 for Cars",
        "32 for Buses",
      ],
    },
    {
      content: `Thus, given x and the total number of vehicles of each kind, we can calculate the actual number of people who would pass by the site in 1 day.
      This is then multiplied by 30 to calculate the total number of people passing-by the site in 1 month = total impressions per month.`,
    },
  ];

  return (
    <>
      <ContentHeading heading="Calculating the Total No. of Impressions" />
      <ContentList
        list={totalImpressionList}
        heading={totalImpressionContent}
      />
    </>
  );
}

/**
 * Ots Help Page
 */
export default function OtsHelpPage() {
  const pageTitle =
    "Methodology For Estimating Total Impressions: Opportunity To See [OTS]";

  return (
    <>
      <HelpSidebar activePageUrl={RedirectTo.helpOtsPageUrl} />

      <div className="content-wrapper">
        <PageHeader title={pageTitle} shadow={true} />

        <div className="page-content">
          <OtsDescription />
          <GoogleTrafficApi />
          <OpenStreetMaps />
          <ModeWiseTrafficDistribution />
          <CalculatingTheTotalNoOfImpressions />
        </div>
      </div>
    </>
  );
}
