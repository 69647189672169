import React from "react";
import { useSelector } from "react-redux";

// Constants and Utils
import { RedirectTo } from "../../../urls/PageLinksURL";

// Page sections
import MapTab from "../MapTab";
import SelectedTargetGroupSection from "./SelectedTargetGroupSection";
import TargetGroupListingSection from "./TargetGroupListingSection";

// constants
const selectedViewLabels = {
  header: {
    title: "Target Group",
    changeViewBtnText: "Select Target Group",
  },
  body: {
    emptyText: "Select Target Group",
    emptyIconCls: "fas fa-users",
  },
};

const listingViewLabels = {
  header: {
    title: "Add Target Group",
    createBtnText: "Create New Target Group",
    createBtnUrl: `${RedirectTo.orgTargetGroupCreateUrl}`,
  },

  search: {
    placeholder: "Search target group",
    fields: ["name"],
  },
};

/**
 * TAB - TARGET GROUP
 */
function TargetGroupTab({ bbox }) {
  // Selector
  const selectedTgId = useSelector(
    (state) => state.orgTargetGroup.selectedTgId
  );

  // Function
  // getting content for "selectedViewContent"
  function checkForSelectedViewContent() {
    if (!selectedTgId) return null;
    return <SelectedTargetGroupSection selectedTgId={selectedTgId} />;
  }

  // Selected View :: Body
  const selectedViewContent = checkForSelectedViewContent();
  // Listing View :: Body
  const listingViewContent = (
    <TargetGroupListingSection
      listingViewLabels={listingViewLabels}
      bbox={bbox}
    />
  );

  // Tab Rendered Content ------------------------------------
  return (
    <MapTab
      selectedViewLabels={selectedViewLabels}
      selectedViewChildren={selectedViewContent}
      listingViewLabels={listingViewLabels}
      listingViewChildren={listingViewContent}
    />
  );
}

export default TargetGroupTab;
