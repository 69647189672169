import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router";
import { Marker, Popup } from "react-leaflet";

// Actions
import {
  downloadMapImage,
  getMapImage,
} from "../../../actions/citySummary/MapViewImageActions";

// Constants and Utils
import { getMediaIcon } from "../../../utils/MediaUtils";
import {
  MediaColor,
  MediaSources,
  UrlKeys,
} from "../../../constants/GeneralConstants";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { useUrlKeyCheck } from "../../../mavin/utils/hooks/HooksUtil";

// Url
import { RedirectTo } from "../../../urls/PageLinksURL";

// Components
import Spinner from "../../../components/spinner/Spinner";
import LLMap from "../../../components/map/leaflet-map/LLMap";
import MapDraggedAndZoomed from "../../../components/map/map-drag-and-zoom/MapDraggedAndZoomed";

function DownloadMapImage() {
  //Dispatch
  const dispatch = useDispatch();

  //Selector
  const imageUrl = useSelector((state) => state.mapViewImage.imageUrl);
  const imageDownloading = useSelector((state) => state.cities.imgDownloading);

  //Checks for image loading
  if (imageDownloading) {
    return <Spinner />;
  }
  return (
    <button
      className="btn btn-outline-primary"
      data-html2canvas-ignore="true"
      onClick={() => dispatch(downloadMapImage(imageUrl))}
    >
      Download image
    </button>
  );
}

function Footer({ campaignId, cityId, targetGroupId }) {
  // Url
  const campaignMapViewPageUrl = RedirectTo.campaignMapViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);

  return (
    <div className="px-4 py-3 border-top d-flex justify-content-end">
      <div>
        {/* <DownloadMapImage /> */}
        <Link
          data-html2canvas-ignore="true"
          to={{
            pathname: constructRedirectPath(campaignMapViewPageUrl),
            state: { targetGroupId },
          }}
          className="btn btn-outline-primary ml-3"
        >
          Edit map view
        </Link>
      </div>
    </div>
  );
}

// get final media icon
function getFinalMediaIcon(media = {}, isMantaraySpecific = false) {
  const { mediaSource } = media;

  // checking media is added from orgMediaInventory or not
  const isMediaAddedFromOrgInventory = mediaSource === MediaSources.ORG_MEDIA;

  // org media icon
  if (isMantaraySpecific && isMediaAddedFromOrgInventory) {
    return getMediaIcon(MediaColor.campaign, true);
  }

  return getMediaIcon(MediaColor.campaign);
}

// City MapView
function CityMapView({
  mediaList,
  mapCenter,
  setMapCenter,
  mapZoom,
  setMapZoom,
}) {
  // checking if url contains product id and using accordingly
  const isMantaraySpecific = useUrlKeyCheck(UrlKeys.mantaraySpecific);

  // if no center present, return null
  if (Object.keys(mapCenter).length === 0) {
    return null;
  }

  return (
    <LLMap center={mapCenter} zoom={mapZoom} scrollWheelZoom={false}>
      {mediaList.map((media) => {
        const { id, latitude, longitude, title } = media;

        // media icon
        const finalMediaIcon = getFinalMediaIcon(media, isMantaraySpecific);

        return (
          <Marker
            key={id}
            position={[latitude, longitude]}
            icon={finalMediaIcon}
          >
            <Popup>
              <b>{title}</b>
            </Popup>
          </Marker>
        );
      })}

      {/* Drag & Zoom Event Capture Component */}
      <MapDraggedAndZoomed
        setMapZoom={setMapZoom}
        setMapCenter={setMapCenter}
      />
    </LLMap>
  );
}

function MapViewImage({
  imageUrl,
  mediaList,
  mapCenter,
  setMapCenter,
  mapZoom,
  setMapZoom,
}) {
  if (imageUrl === "NoMapImage") {
    return (
      <CityMapView
        mediaList={mediaList}
        mapCenter={mapCenter}
        setMapCenter={setMapCenter}
        mapZoom={mapZoom}
        setMapZoom={setMapZoom}
      />
    );
  }

  // NOTE: We are appending timeStamp and crossOrigin parameter to solve the
  // Issue of loading svg images from server (AWS) in chrome..
  // Reference : https://github.com/niklasvh/html2canvas/issues/154
  // TODO:: code is working without below return statement also , but keeping it for future reference
  // return (
  //   <img
  //     className="map-img-cont img-fluid"
  //     src={imageUrl + "?time=" + new Date().valueOf()}
  //     crossOrigin=""
  //   />
  // );
  return (
    <img className="map-img-cont img-fluid" src={imageUrl} crossOrigin="" />
  );
}

function CampaignCityMapView({
  mediaList,
  targetGroupId,
  mapCenter,
  setMapCenter = () => {},
  mapZoom,
  setMapZoom = () => {},
}) {
  const { id, cityId } = useParams();

  // Selector
  const { summary = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );
  const imageUrl = useSelector((state) => state.mapViewImage.imageUrl);
  const cityCenter = summary?.cityCenter || {};
  const { latitude, longitude } = cityCenter;

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMapImage(id, cityId));
  }, [dispatch, id, cityId]);

  // update map center state
  useEffect(() => {
    if (latitude && longitude) {
      setMapCenter([latitude, longitude]);
    }
  }, [latitude, longitude]);

  return (
    <div className="mt-5">
      <h4 className="sec-title">Map View with Media Markers</h4>
      <div className="card mt-2">
        <div className="map-img-cont bg-alt">
          {/* Map image */}
          <MapViewImage
            imageUrl={imageUrl}
            mediaList={mediaList}
            mapCenter={mapCenter}
            setMapCenter={setMapCenter}
            mapZoom={mapZoom}
            setMapZoom={setMapZoom}
          />
        </div>
        {/* Map footer */}
        <Footer campaignId={id} cityId={cityId} targetGroupId={targetGroupId} />
      </div>
    </div>
  );
}

export default CampaignCityMapView;
