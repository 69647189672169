// Utils and Constants
import { ContentHeading, ContentList, ContentParagraph } from "./HelpPageUtils";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Components
import PageHeader from "../../mavin/components/page-header/PageHeader";
import HelpSidebar from "./HelpSidebar";

function LtsDescription() {
  const ltsDescription = `LTS or Likelihood to See is a measure of the physical characteristics of the site. As the OTS
   (Opportunity to See or Total no. of monthly impressions) is based on the geo-location only, it does not take into
   account the physical features such as height, width, elevation etc. of the site. These factors are, however, necessary to
   determine the viewability of a site. Even if a billboard is located at a prime junction in a city, if it is too small or
   if it is at a very high elevation (on top of a multi-storey building), it will not be visible to the pedestrians or passengers.
   The LTS score helps in determining how viewable a given site is. The LTS score is a weighted average of the below mentioned indices.`;

  return <ContentParagraph paragraph={ltsDescription} />;
}

function ARISection() {
  const ariContent = `This index measures the aesthetics of the billboard. Human visual field of view has instantaneous field of view of
   120°El x 200°Az for two eyes. Where 120° is the Elevation representing the vertical movement and 200° is the azimuthal angle representing
   the horizontal movement. If a given object matches with our visual field we register better perception of depth, colour and hence the
   message is more receptive. Based on this field of vision, we assume 16:9 to be the most optimal aspect ratio for human vision. Any
   billboard having a width-height ratio of 16:9 is given a 5 star rating, with the rating decreasing progressively as we move away from
   the optimal aspect ratio of 16:9`;

  return (
    <>
      <ContentHeading heading="Aspect ratio Index (ARI) "></ContentHeading>
      <ContentParagraph paragraph={ariContent} />
    </>
  );
}

function AISection() {
  const aiContent = ` Area index is the measure of the total size of the billboard. It is a known fact that the bigger the billboard is,
  the easier it is to spot. Thus, the area index captures the overall size of the billboards (relative to the size of all other billboards
  in a given city) and helps in identifying the viewability of the billboard. In order to calculate the Area Index of a billboard, we
  first calculate the average size of all the billboards in a given city as well as the standard deviation in their sizes. From here,
  the rating is given as follows:`;

  const aiList = [
    {
      content:
        "Area of billboard &lt; Avg. area of city billboards – Std. deviation: 3 Star",
    },
    {
      content:
        "Avg. area – Std. deviation &lt; Area of billboard &lt; Avg. area + Std. deviation: 4 Star",
    },
    { content: "Area of billboard &gt; Avg. area + Std. deviation: 5 star" },
  ];
  return (
    <>
      <ContentHeading heading="Area Index (AI)"></ContentHeading>
      <ContentParagraph paragraph={aiContent} />
      <ContentList list={aiList} />
    </>
  );
}

function TAISection() {
  const taiContent = `Angle of the billboard with respect to the incoming traffic is the tilt angle. Tilt angle plays a significant role
   to determine the magnitude of impression of the billboard on the viewer. Billboards placed facing the incoming traffic register an
   increased engagement due to increased viewability and an ease of view. While Billboards placed parallel to the incoming traffic are
   least likely to be noticed. Therefore, for cases where tilt angle = 0 (head-on traffic), we give a 5 star rating, whereas for cases
   where tilt angle = 90 (site is parallel to the road), we give a 1 star rating (for any other tilt angle, the rating is calculated
   accordingly).`;

  return (
    <>
      <ContentHeading heading="Tilt Angle Index (TAI)" />
      <ContentParagraph paragraph={taiContent} />
    </>
  );
}

function EISection() {
  const eiContent = `Elevation index is the measure of the elevation (from the ground) of the billboard. A low elevation corresponds to a
  better viewability of the billboard. Billboards located out of the line of sight of the people fail to turn a lot of heads. Hence 
  billboards which are closer to the line of sight show up with higher Elevation Index in our scientific evaluation. Based on this line
  of enquiry, a billboard which is located at eye-level (6 feet) is given a 5 star rating, with the EI decreasing progressively as the
  elevation of the billboard increases. Beyond an elevation of 50 ft. we give a 1 star EI rating.`;

  return (
    <>
      <ContentHeading heading="Elevation Index (EI)" />
      <ContentParagraph paragraph={eiContent} />
    </>
  );
}

function CISection() {
  const ciContent = ` If there is too much clutter surrounding a billboard, there is a fair chance that it might get missed out in all the
  chaos. Therefore, the assumption is that an individual billboard will be most noticeable whereas if there are many billboards at the same
  location, the chances that the viewer would be able to give sufficient attention to any single billboard decreases substantially. <br/> 
  Using this logic, the CI of any site is determined as follows:`;

  const ciList = [
    { content: "No other billboard within a 25 meter radius – 5 star rating" },
    { content: "1 other billboard within a 25 meter radius – 4 star rating" },
    { content: "2 other billboards within a 25 meter radius – 3 star rating" },
    { content: "3 other billboards within a 25 meter radius – 2 star rating" },
    {
      content: "4 or more billboards within a 25 meter radius – 1 star rating",
    },
  ];
  const ciFormulaHeaderText = `Once the individual indices have been estimated, the overall Likelihood to See (LTS) is calculated as:`;

  const ciFormula =
    "LTS = 0.2 * ARI + 0.2 * AI + 0.2 * TAI + 0.2 * EI + 0.2 * CI";

  const ciFormulaFooterText = `As expected, the higher the LTS score, the more viewable the given site would be.`;
  return (
    <>
      <ContentHeading heading="Clutter Index (CI)" />
      <ContentParagraph paragraph={ciContent} />
      <ContentList list={ciList} />
      <ContentParagraph paragraph={ciFormulaHeaderText} />
      <ContentParagraph paragraph={ciFormula} className="font-weight-bold" />
      <ContentParagraph paragraph={ciFormulaFooterText} />
    </>
  );
}

/**
 * Lts Help Page
 */
export default function LtsHelpPage() {
  const pageTitle = "Methodology For Calculating: Likelihood To See [LTS]";
  return (
    <>
      <HelpSidebar activePageUrl={RedirectTo.helpLtsPageUrl} />

      <div className="content-wrapper">
        <PageHeader title={pageTitle} shadow={true} />

        <div className="page-content">
          <LtsDescription />
          <ARISection />
          <AISection />
          <TAISection />
          <EISection />
          <CISection />
        </div>
      </div>
    </>
  );
}
