import React from "react";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { generateMavinMetrics } from "../../../actions/campaign-media/CampaignMediaActions";

//Components
import Spinner from "../../../components/spinner/Spinner";

function generateMetricsMessage(reportTobeGeneratedCount) {
  return `${reportTobeGeneratedCount} media report not generated, `;
}

function GenerateMetrics({ reportTobeGeneratedCount, onClickGenerateMetrics }) {
  return (
    reportTobeGeneratedCount > 0 && (
      <div className="d-flex flex-column">
        <span className="text-info">
          {generateMetricsMessage(reportTobeGeneratedCount)}
        </span>
        <button
          data-html2canvas-ignore="true"
          className="btn btn-link pl-0"
          onClick={onClickGenerateMetrics}
        >
          {"Click here to generate Metrics"}
        </button>
      </div>
    )
  );
}

function GenerateMavinMetrics({ campaignId, cityId }) {
  //Dispatch
  const dispatch = useDispatch();

  // Selectors to map state values
  const { summary: citySummary = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );
  const isLoading = useSelector(
    (state) => state.campaignMedia.generateMavinMetricsLoader
  );
  const reportStatusCount = citySummary.reportStatusCounts || {};
  const reportTobeGenerated = reportStatusCount.TODO;

  async function generateMetrics() {
    dispatch(generateMavinMetrics(campaignId, cityId));
  }

  //Check for generate mavin loading
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <GenerateMetrics
      reportTobeGeneratedCount={reportTobeGenerated}
      onClickGenerateMetrics={generateMetrics}
    />
  );
}

export default GenerateMavinMetrics;
