export const CampaignPlanning = {
  getRegionsData: "/api/v3/defs/region/:cityId",
  getSegmentMedia: "/api/v3/media/search/roadsegment",
  getTgSpecificOts: "/api/v3/mavin/ots/tg/:tgId",
  getCampaignPlanMedia: "/api/v3/plan/campaign/:campaignId/media",
};

export const CampaignMedia = {
  getCampaignMedia: "/api/v3/campaign/:campaignId/media",
};

export const Campaign = {
  getCampaign: "/api/v3/campaign/:campaignId",
  getCampaignList: "/api/v3/campaign/list",
  updateCampaignTitle: "/api/v3/campaign/:campaignId",
};

export const defaultPagination = {
  // Default pagination details
  pageNo: 1,
  pageSize: 100,
  pageSizeMax: 4000,
};
