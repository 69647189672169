import { useDispatch } from "react-redux";

// Actions
import { closeAlertForm } from "../../actions/AlertFormActions";

// Constants and Utils
import { FormDataTargets } from "../../constants/GeneralConstants";

/**
 * Alert Form Modal
 * @input bodyText -> text message
 * can be used as error/success/info any kind of content as popup
 */
export default function AlertForm({ bodyContent }) {
  const dispatch = useDispatch();

  return (
    <>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        id={FormDataTargets.alertForm}
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {/* Form body */}
            <div className="modal-body px-5 py-4">{bodyContent}</div>

            {/* Form Footer */}
            <div className="modal-footer border-0 px-5">
              <button
                type="button"
                className="btn btn-sm btn-primary rounded-lg"
                data-dismiss="modal"
                onClick={() => dispatch(closeAlertForm())}
              >
                {"OK"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
}
