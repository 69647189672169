import { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// API
import {
  downloadDataLayerTemplate,
  uploadDataLayerTemplate,
} from "../../../apis/OrgDataLayerAPI";

// Constants and Utils
import {
  saveFile,
  selectFile,
} from "../../../common-utils/file-utils/FileUtils";
import { ProgressTexts } from "../../../constants/GeneralConstants";

// Components
import { PlainDropdown } from "../../../components/dropdown/Dropdown";
import { Progress } from "../../../components/progress-status/Progress";
import OrgSettingsSidebar from "../OrgSettingsSidebar";

// CSS
import "../OrgSettings.scss";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Page-Constants
const PLACEHOLDER_ID = "select";
const pageTitle = "New Data Layer";

// Page Components
function CustomLayerInput({ onItemSelect, onTextChange }) {
  const dropdownItems = [{ id: "POINT", label: "Points Layer" }];

  function onInputTextChange(event) {
    onTextChange(event.target.value);
  }

  return (
    <div className="d-flex">
      <PlainDropdown
        items={dropdownItems}
        onItemSelect={onItemSelect}
        placeHolder="Select Data Layer Type"
        className="border-secondary btn"
      />
      <input
        className="col-5"
        placeholder="Enter data layer name"
        onChange={onInputTextChange}
      />
    </div>
  );
}

function LayerDetailsStep({ onItemSelect, onTextChange }) {
  return (
    <div className="card border-0 mt-3">
      <div className="card-content">
        <p className="card__step">STEP 1</p>
        <p className="card__title">Data layer details</p>
        <p className="card__message">
          This will be used in the rest of the product as a reference name.
        </p>
      </div>
      <CustomLayerInput
        onItemSelect={onItemSelect}
        onTextChange={onTextChange}
      />
    </div>
  );
}

function Error({ text }) {
  return (
    <p className="text-danger">
      <i className="fa fa-exclamation-circle mx-1"></i>
      {text}
    </p>
  );
}

function ActionButton({ onClick, label, isEnabled }) {
  return (
    <button
      className="btn btn-primary btn-action"
      disabled={!isEnabled}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

ActionButton.defaultProps = {
  isEnabled: true,
};

function DownloadTemplateStep({
  isActionEnabled,
  isDownloading,
  onDownloadTemplateClick,
  errorText,
}) {
  const downloadProgressTexts = ProgressTexts.Downloads;

  return (
    <div className="card border-0 d-flex">
      <div className="d-flex justify-content-between">
        <div className="card-content">
          <p className="card__step">STEP 2</p>
          <h5 className="card__title">Download and fill template</h5>
          <p className="card__message">
            If you have already filled template, jump to Step 3.{" "}
            <a href="#">Learn about templates</a>
          </p>
          {errorText && <Error text={errorText} />}
        </div>
        <div className="action-content pt-4">
          {isDownloading ? (
            <Progress progressTexts={downloadProgressTexts} />
          ) : (
            <ActionButton
              onClick={onDownloadTemplateClick}
              label="Download Template"
              isEnabled={isActionEnabled}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function UploadTemplateStep({ onUploadTemplateClick, isLoading, errorText }) {
  const uploadProgressTexts = ProgressTexts.Uploads;

  return (
    <div className="card border-0 d-flex">
      <div className="d-flex justify-content-between">
        <div className="card-content">
          <p className="card__step">STEP 3</p>
          <h5 className="card__title">Upload data layer</h5>
          <p className="card__text">
            Please upload using the template details recommended to avoid
            errors.
            <a href="#"> Learn more</a>
          </p>
          {errorText && <Error text={errorText} />}
        </div>
        <div className="action-content pt-4">
          {isLoading ? (
            <Progress progressTexts={uploadProgressTexts} />
          ) : (
            <ActionButton
              onClick={onUploadTemplateClick}
              label="Upload Data Layer Template"
            />
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * Page Component
 */
export default function DataLayerCreatePage() {
  // State
  const [isLoading, setLoading] = useState(false);
  const [isDownloadingTemplate, setDownloadingTemplate] = useState(false);
  const [isActionEnabled, setActionEnabled] = useState(true);
  const [downloadError, setDownloadError] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [layerType, setLayerType] = useState();
  const [layerName, setLayerName] = useState();

  const history = useHistory();

  // Functions
  // TODO: move everything to redux
  async function onDownloadTemplateClick() {
    if (!layerType) {
      setDownloadError("Select a layer type to download the template");
      return;
    }
    setDownloadError("");
    setActionEnabled(false);
    setDownloadingTemplate(true);
    try {
      const response = await downloadDataLayerTemplate(layerType);
      saveFile("data_layer_template.xls", response);
    } catch (e) {
      setDownloadError(e.response.data);
    } finally {
      setActionEnabled(true);
      setDownloadingTemplate(false);
    }
  }

  async function onUploadTemplateClick() {
    if (layerType === PLACEHOLDER_ID || !layerName) {
      setUploadError(
        "Select a layer type and enter a layer name to upload the template"
      );
      return;
    }
    setUploadError("");
    const files = await selectFile();
    setLoading(true);
    try {
      const dataLayer = await uploadDataLayerTemplate(
        layerType,
        layerName,
        files[0]
      );
      toast.success(`Successfully uploaded data layer '${layerName}'`);
      history.push(`/org/data-layer/${dataLayer.id}`);
      setLoading(false);
    } catch (e) {
      setUploadError(e.response.data);
      setLoading(false);
    }
  }

  function onLayerTypeChange(id) {
    if (id !== PLACEHOLDER_ID) {
      setDownloadError("");
    }
    setLayerType(id);
  }

  function onLayerNameChange(name) {
    if (name && layerType !== PLACEHOLDER_ID) {
      setUploadError("");
    }
    setLayerName(name);
  }

  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={6} />

      <div className="content-wrapper site-upload">
        {/* Page-Header */}
        <PageHeader title={pageTitle} shadow={true} border={true} />

        {/* Breadcrumb */}
        {/* <Breadcrumb items={breadcrumbItems} /> */}

        {/* Data-Layer detail step */}
        <LayerDetailsStep
          onItemSelect={onLayerTypeChange}
          onTextChange={onLayerNameChange}
        />
        <hr className="divider my-0"></hr>

        {/* Data-layer Download */}
        <DownloadTemplateStep
          isActionEnabled={isActionEnabled}
          isDownloading={isDownloadingTemplate}
          onDownloadTemplateClick={onDownloadTemplateClick}
          errorText={downloadError}
        />
        <hr className="divider my-0"></hr>

        {/* Upload Data-layer */}
        <UploadTemplateStep
          onUploadTemplateClick={onUploadTemplateClick}
          isLoading={isLoading}
          errorText={uploadError}
        />
        <hr className="divider my-0"></hr>
      </div>

      {/** Modals */}
    </>
  );
}
