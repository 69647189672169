import { useDispatch, useSelector } from "react-redux";

// Actions
import { getLocationSummary } from "../../../actions/location-summary/LocationSummaryActions";

// Components
import Landmarks from "../../../mavin/components/location-summary/Landmarks";
import LocationSummaryPieChart from "../../../mavin/components/location-summary/LocationSummaryPieChart";
import Population from "../../../mavin/components/location-summary/Population";
import RadiusSelector from "../../../mavin/components/location-summary/RadiusSelector";
import SocioEconomicIndicators from "../../../mavin/components/location-summary/SocioEconomicIndicators";

/**
 * Demographic summary section
 */
function SiteLocationSummarySection({ setRadius, coordinate }) {
  const dispatch = useDispatch();
  //Selector
  const data = useSelector((state) => state.locationSummary.data);
  const isLoading = useSelector((state) => state.locationSummary.dataLoading);

  const { summary = {}, points = {} } = data;
  const {
    "poi-dist": poiDist,
    population,
    restaurants,
    "realty-society": realtySociety,
  } = summary;

  // Function
  function changeRadius(radius) {
    setRadius(radius);
    dispatch(
      getLocationSummary({
        latitude: coordinate[0],
        longitude: coordinate[1],
        distance: radius,
      })
    );
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {/* Title And Radius Selector */}
      <RadiusSelector
        title={"Location Summary Within"}
        onItemSelect={changeRadius}
        isLoading={isLoading}
        className="py-2"
      />

      <div className="row">
        {/* Location Summary Section */}
        <LocationSummaryPieChart poiDist={poiDist} className="col-6" />

        <div className="col-6">
          {/* Population details */}
          <Population population={population?.total} />
          <hr className="divider"></hr>

          {/* Socia Economic details */}
          <SocioEconomicIndicators
            restaurants={restaurants}
            realtySociety={realtySociety}
          />
          <hr className="divider"></hr>

          {/* Land Mark details */}
          <Landmarks
            points={points}
            latitude={coordinate[0]}
            longitude={coordinate[1]}
          />
        </div>
      </div>
    </>
  );
}

export default SiteLocationSummarySection;
