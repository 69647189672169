import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Apis
import {
  getSellerInfoByIdFn,
  getSellerInventorySummaryFn,
  updateSellerInfoFn,
  updateSellerLogoFn,
  searchSellerFn,
  enableSellerSiteFn,
  disableSellerSiteFn,
} from "../../apis/SellerAPI";

// Utils and constats
import { Seller } from "../../constants/action-constants/seller/SellerActionConstants";
import { AlertForm } from "../../constants/action-constants/AlertModalActionConstants";
import { getErrorMessage } from "../../utils/util";
import { setErrorStatusCode } from "../../utils/ErrorUtils";
import { getSellerId } from "../../utils/SellerUtils";

/**
 *  seller inventory summary
 */
export function* getSellerInventorySummary() {
  const sellerId = getSellerId();

  try {
    // Api call
    const sellerInventory = yield getSellerInventorySummaryFn(sellerId);

    yield put({
      type: Seller.GET_SELLER_INVENTORY_SUMMARY_SUCCESS,
      sellerInventory: sellerInventory,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Seller.GET_SELLER_INVENTORY_SUMMARY_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

/**
 *  seller Info
 */
export function* getSellerInfo() {
  const sellerId = getSellerId();

  try {
    // Api call
    const sellerInfo = yield getSellerInfoByIdFn(sellerId);

    yield put({
      type: Seller.GET_SELLER_INFO_BY_ID_SUCCESS,
      sellerInfo: sellerInfo,
    });
  } catch (error) {
    // stores the error and render the error image when the api fails
    setErrorStatusCode(error);

    yield put({
      type: Seller.GET_SELLER_INFO_BY_ID_FAILURE,
      payload: error,
    });
  }
}

/**
 *  Update seller Info
 */
export function* updateSellerInfo(action) {
  const { sellerInfoBean } = action.payload;
  const sellerId = getSellerId();

  try {
    // Api call to Update Info
    yield updateSellerInfoFn(sellerId, sellerInfoBean);

    // Api call to get updated Info
    const updatedSellerInfo = yield getSellerInfoByIdFn(sellerId);

    yield put({
      type: Seller.UPDATE_SELLER_INFO_SUCCESS,
      updatedSellerInfo: updatedSellerInfo,
    });
    toast.success("Successfully updated profile.");
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Seller.UPDATE_SELLER_INFO_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

/**
 *  Update seller LOGO
 */
export function* updateSellerLogo(action) {
  const { file } = action.payload;
  const sellerId = getSellerId();

  const imageUrl = URL.createObjectURL(file);

  try {
    // Api call to Update Logo
    const responseMsg = yield updateSellerLogoFn(sellerId, file);

    yield put({
      type: Seller.UPDATE_SELLER_LOGO_SUCCESS,
      imageUrl: imageUrl,
    });
    toast.success(responseMsg);
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Seller.UPDATE_SELLER_LOGO_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

/**
 *  Search seller
 */
export function* searchSeller(action) {
  try {
    const { searchText } = action.payload;

    // Api call
    const response = yield searchSellerFn(searchText);

    yield put({
      type: Seller.SEARCH_SELLER_SUCCESS,
      sellerList: response.items,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Seller.SEARCH_SELLER_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

/**
 *  Enable Seller Site
 */
export function* enableSellerSite() {
  try {
    const sellerId = getSellerId();

    // Api call
    yield enableSellerSiteFn(sellerId);

    yield put({
      type: Seller.ENABLE_SELLER_SITE_SUCCESS,
      payload: { isPublished: true },
    });

    // opening Alert Modal for Success Message
    yield put({
      type: AlertForm.OPEN_ALERT_MODAL,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Seller.ENABLE_SELLER_SITE_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

/**
 *  Disable Seller Site
 */
export function* disableSellerSite() {
  try {
    const sellerId = getSellerId();

    // Api call
    yield disableSellerSiteFn(sellerId);

    yield put({
      type: Seller.DISABLE_SELLER_SITE_SUCCESS,
      payload: { isPublished: false },
    });

    toast.success("Successfully Unpublished Website.");
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Seller.DISABLE_SELLER_SITE_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(Seller.GET_SELLER_INVENTORY_SUMMARY, getSellerInventorySummary),
    takeLatest(Seller.GET_SELLER_INFO_BY_ID, getSellerInfo),
    takeLatest(Seller.UPDATE_SELLER_INFO, updateSellerInfo),
    takeLatest(Seller.UPDATE_SELLER_LOGO, updateSellerLogo),
    takeLatest(Seller.SEARCH_SELLER, searchSeller),
    takeLatest(Seller.ENABLE_SELLER_SITE, enableSellerSite),
    takeLatest(Seller.DISABLE_SELLER_SITE, disableSellerSite),
  ]);
}
