// Urls
import { getReachAndFrequencySummaryUrl } from "../../urls/mavin-tools/ReachAndFrequencyToolURL";

//Utils
import { sendCreateOrUpdateRequest } from "../../utils/api/apiUtils";

// Getting full summary
export async function getReachAndFrequencySummaryFn(
  segmentId,
  startDate,
  duration
) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    roadId: segmentId,
    date: startDate,
    duration: duration,
  };
  return sendCreateOrUpdateRequest(
    getReachAndFrequencySummaryUrl,
    {},
    true,
    "POST",
    headerParams,
    params
  );
}
