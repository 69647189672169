import { ActionTypes } from "../../../constants/ActionConstants";

const initialState = {
  // TG-CPM, GENERIC-CPM
  tgCpm: 0,
  genCpm: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SingleSiteReport.SET_TG_CPM:
      return {
        ...state,
        tgCpm: Number(action.payload.tgCpmStr),
      };

    case ActionTypes.SingleSiteReport.SET_GEN_CPM:
      return {
        ...state,
        genCpm: Number(action.payload.genCpmStr),
      };

    default:
      return state;
  }
};
