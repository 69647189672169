import { DivIcon } from "leaflet";
import { Marker, Polyline } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";

// Actions
import {
  openMediaInfo,
  selectMedia,
  unSelectMedia,
} from "../../actions/media-selection/MediaSelectionActions";
import {
  addCpmPriceMediaImpact,
  addFixedPriceMediaImpact,
  removeCpmPriceMediaImpact,
  removeFixedPriceMediaImpact,
} from "../../actions/SubmissionImpactActions";

// Constants and Utils
import { KeysToMediaType, MapZoom } from "../../../constants/GeneralConstants";
import { CPM } from "../../../constants/PriceMode";
import {
  constructCampaignMediaBean,
  getInitialDatesState,
  getInitialPrices,
} from "./MediaContainerUtil";
import { getDifferenceInDaysOfDateObject } from "../../../common-utils/date-utils/DateUtils";

// Components
import LLMap from "../../../components/map/leaflet-map/LLMap";
import TrafficFlowArrow from "../../../components/map/traffic-flow-arrow/TrafficFlowArrow";
import {
  AdditionalButtonState,
  AvailableDateAndOtsAndCPICell,
  BuyerPriceCell,
  MarginValueCell,
  MediaImage,
  MediaInfo,
  MediaTag,
  PricingModeCell,
  SellerEarningCell,
} from "./MediaContainerCells";

// Reusable InfoItem Component
function InfoItem({ label, value }) {
  return (
    <div className="col-4 pl-2">
      <h6 className="font-weight-bold">{label}</h6>
      <p>{value}</p>
    </div>
  );
}

function DOOHMediaInfo({ media }) {
  const {
    loopTiming = "3 min",
    slotTiming = "10 sec",
    costPerMonth = "10,00,000",
    costPerSpot = "1000",
    spotsPerDay = "320",
    spotsPerMonth = "9,600",
    ops = "16",
  } = media || {};

  const slotDetails = `${slotTiming} / ${loopTiming}`;

  const mediaInfo = [
    { label: "Loop Timing", value: loopTiming },
    { label: "Slot Timing", value: slotTiming },
    { label: "Slot Details", value: slotDetails },
    { label: "Cost per Spot", value: costPerSpot },
    { label: "Cost per Month", value: costPerMonth },
    { label: "Cost per Audience IMP", value: "1000" },
    { label: "Spots per Day", value: spotsPerDay },
    { label: "Spots per Month", value: spotsPerMonth },
    { label: "Daily Hours of Ops", value: ops },
  ];

  return (
    <div className="d-flex flex-wrap justify-content-between">
      {mediaInfo.map(({ label, value }) => (
        <InfoItem key={label} label={label} value={value} />
      ))}
    </div>
  );
}

function MaxTrafficInfo({ media }) {
  return (
    <>
      <p className="pl-2">
        <b> Max Traffic:</b> 8 - 10 am | 12 - 2 pm | 4 - 6 pm
      </p>
      <p className="pl-2 mb-0">
        For Automatic ad Scheduling
        <a href="" className="px-2">
          Click here
        </a>
        for Programmatic Buys
      </p>
    </>
  );
}

function MediaDetails({ roadStretchId, media, campaignPlan, tag }) {
  // State to store the priceMode( CPM or FIXED) selected
  const [priceMode, setPriceMode] = useState(CPM);

  // State to store the input buyerPrice
  const [buyerPrice, setBuyerPrice] = useState("");

  // State to store the input sellerPrice
  const [sellerPrice, setSellerPrice] = useState("");

  const { campaignId } = useParams();
  const dispatch = useDispatch();

  const { mediaGroup = "" } = media || {};
  const IsDOOH = mediaGroup === KeysToMediaType.DOOH;

  const otsSplit = campaignPlan.roadStretchOtsMap[roadStretchId];
  const campaignMedia = useSelector(
    (state) => state.mediaSelection.mediaIdToCampaignMedia[media.mediaId]
  );

  // Media Pricing Info..
  const mediaPriceInfo =
    useSelector((state) => state.orgMediaPrice.mediaPriceInfo[media.mediaId]) ||
    {};

  // Media Info Dispaly State
  const displayMedia =
    useSelector(
      (state) => state.mediaSelection.openCloseMediaDetails[media.mediaId]
    ) || false;

  // State for storing the media available dates
  const [dateObj, setDuration] = useState(
    getInitialDatesState(campaignMedia, campaignPlan)
  );
  const duration = getDifferenceInDaysOfDateObject(dateObj);

  const initialPriceDetails = getInitialPrices(
    campaignPlan,
    roadStretchId,
    media,
    campaignMedia,
    otsSplit,
    duration,
    mediaPriceInfo
  );

  const isMediaSelected = useSelector(
    (state) => state.mediaSelection.selectedMedia[media.mediaId]
  );

  // to disable the add and remove media button when the api call is in pending status
  const selectUnSelectMediaLoading = useSelector(
    (state) => state.mediaSelection.selectUnSelectMediaLoading[media.mediaId]
  );

  /**
   * Function which dispatches respective submission impact actions
   * based on priceMode
   * @param {*} priceMode  ==> "CPM" or "FIXED"
   * @param {*} selectMedia
   * @returns
   */
  function dispatchPriceModeActions(
    isCpmEnabled,
    priceMode,
    selectMedia = true
  ) {
    if (!selectMedia) {
      dispatch(removeCpmPriceMediaImpact(media.mediaId));
      dispatch(removeFixedPriceMediaImpact(media.mediaId));
      return;
    }

    if (isCpmEnabled || priceMode === CPM) {
      dispatch(addCpmPriceMediaImpact(media.mediaId, otsSplit, duration));
      dispatch(removeFixedPriceMediaImpact(media.mediaId));
      return;
    }

    dispatch(
      addFixedPriceMediaImpact(
        media.mediaId,
        otsSplit,
        media.pricing.price,
        duration
      )
    );
    dispatch(removeCpmPriceMediaImpact(media.mediaId));
  }

  /**
   * Function which initiates when media is selected
   */
  function addMedia() {
    dispatchPriceModeActions(media.isCpmEnabled, priceMode);
    const campaignMediaBean = constructCampaignMediaBean(
      dateObj,
      media,
      priceMode,
      sellerPrice,
      buyerPrice,
      roadStretchId
    );
    dispatch(selectMedia(campaignId, campaignMediaBean));
  }

  /**
   * Function which initiates when media is unSelected
   */
  function removeMedia() {
    dispatchPriceModeActions(media.isCpmEnabled, priceMode, false);
    dispatch(unSelectMedia(campaignId, campaignMedia, media.mediaId));
  }

  if (!displayMedia) {
    return null;
  }

  return (
    <div className="d-flex border-bottom py-2 mx-0 align-items-stretch">
      <div className="col-6">
        {/* Media Tag and Information */}
        <MediaImage media={media} />
      </div>
      <div className="col-6">
        <MediaTag media={media} tag={tag} />

        <MediaInfo media={media} />
        {!IsDOOH && (
          <>
            <AvailableDateAndOtsAndCPICell
              duration={dateObj}
              setDuration={setDuration}
              isMediaSelected={isMediaSelected}
              initialPriceDetails={initialPriceDetails}
            />
            {/* <PricingModeCell
          media={media}
          campaignMedia={campaignMedia}
          setPriceMode={setPriceMode}
          isMediaSelected={isMediaSelected}
          priceMode={priceMode}
        /> */}
          </>
        )}
        {IsDOOH && (
          <>
            <DOOHMediaInfo media={media} />
            <MaxTrafficInfo media={media} />
          </>
        )}
        <AdditionalButtonState
          isMediaSelected={isMediaSelected}
          selectMedia={addMedia}
          unSelectMedia={removeMedia}
          selectUnSelectMediaLoading={selectUnSelectMediaLoading}
        />
      </div>

      {/* TODO:will remove it if not required after api integration */}
      {/* <div>
        <BuyerPriceCell
          buyerPrice={buyerPrice}
          setBuyerPrice={setBuyerPrice}
          campaignMedia={campaignMedia}
          mop={initialPriceDetails.mop}
        />

        <SellerEarningCell
          sellerPrice={sellerPrice}
          setSellerPrice={setSellerPrice}
          media={media}
          campaignMedia={campaignMedia}
          suggestedPrice={initialPriceDetails.suggestedPrice}
        />
        <MarginValueCell
          sellerPrice={sellerPrice}
          buyerPrice={buyerPrice}
          campaignMedia={campaignMedia}
        />

        <AdditionalButtonState
          isMediaSelected={isMediaSelected}
          selectMedia={addMedia}
          unSelectMedia={removeMedia}
          selectUnSelectMediaLoading={selectUnSelectMediaLoading}
        />
      </div> */}
    </div>
  );
}

function StretchLine({ stretchPoints }) {
  const pathOptions = { color: "orange", weight: 10 };
  return (
    <Polyline pathOptions={pathOptions} positions={stretchPoints}></Polyline>
  );
}

function MediaIcons(mediaId, tag) {
  const mediaIcons = useSelector((state) =>
    state.sellerMedia.sellerMediaIds.reduce((acc, eachMediaId) => {
      let clsStr = "bg-danger";
      if (state.mediaSelection.selectedMedia[mediaId]) {
        clsStr = "bg-success";
      }
      acc[eachMediaId] = new DivIcon({
        className:
          clsStr +
          " media-tag d-flex align-items-center justify-content-center text-white",
        html: tag,
      });
      return acc;
    }, {})
  );
  return mediaIcons[mediaId];
}

export default function MediaContainer({ campaignPlan, roadMedia, roadInfo }) {
  const dispatch = useDispatch();

  if (!roadMedia || roadMedia.length < 1) {
    return null;
  }
  const mediaIconsMap = MediaIcons;
  const { trace } = roadInfo;

  const center = {
    latitude: trace[0][0],
    longitude: trace[0][1],
  };
  const defaultZoom = MapZoom.zoomLevel15;

  function onMediaMarkerClick(e, mediaId) {
    dispatch(openMediaInfo(mediaId));
    e.stopPropagation();
  }

  return (
    <div className="d-flex bg-alt">
      <div className="col-4 px-0">
        <LLMap center={[center.latitude, center.longitude]} zoom={defaultZoom}>
          <StretchLine stretchPoints={trace} />
          <TrafficFlowArrow trace={trace} />
          {roadMedia.map((eachMedia, index) => (
            <Marker
              icon={mediaIconsMap(eachMedia.mediaId, index + 1)}
              key={eachMedia.mediaId}
              position={[eachMedia.latitude, eachMedia.longitude]}
              eventHandlers={{
                click: (e) => onMediaMarkerClick(e, eachMedia.mediaId),
              }}
            />
          ))}
        </LLMap>
      </div>
      <div className="col-8 cont-media-selection">
        {roadMedia.map((eachMedia, index) => (
          <MediaDetails
            key={eachMedia.id}
            media={eachMedia}
            campaignPlan={campaignPlan}
            roadStretchId={roadInfo.id}
            tag={index + 1}
          />
        ))}
      </div>
    </div>
  );
}
