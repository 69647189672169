import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  setGenCpm,
  setTgCpm,
} from "../../../actions/singleSiteReport/singleSiteReport";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import {
  CpmInputCell,
  CpmRsCell,
  ImpressionString,
  TgImpressionCell,
  TgSelectionCell,
} from "./EarningCalculatorTableCells";

// Utils and Constants
import {
  getTgSelectedImpressions,
  getTotalOfSegmentsTgOts,
} from "./ReportUtils";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Target Group",
      className: "col-4",
    },
  },
  {
    title: {
      displayName: "Est Impressions",
      className: "col-3",
    },
  },
  {
    title: {
      displayName: "CPM (Rs)",
      className: "col-2 text-center",
    },
  },
  {
    title: {
      displayName: "Est Earnings(Rs)",
      className: "col-3 text-right",
    },
  },
];

// Page Components
function EarningCalculatorTableRow({ dayCountAndLit }) {
  const { dayCount, lit } = dayCountAndLit;

  // cpm "tg and generic"
  const genCPM = useSelector((state) => state.singleSiteReport.genCpm);
  const tgCPM = useSelector((state) => state.singleSiteReport.tgCpm);

  // for TG-selected-impressions
  const allSegmentsTgOtsMap = useSelector(
    (state) => state.tgSpecificOts.allSegmentsTgOtsMap
  );

  const segmentsCombinedTgOts = getTotalOfSegmentsTgOts(allSegmentsTgOtsMap);
  const { genericOts, genericOtsLit, targetOts, targetOtsLit } =
    segmentsCombinedTgOts;

  // Specific and Generic Impressions
  const specificImpressions = getTgSelectedImpressions(
    targetOts,
    targetOtsLit,
    lit
  );
  const genericImpressions = getTgSelectedImpressions(
    genericOts,
    genericOtsLit,
    lit
  );

  // Dispatch
  const dispatch = useDispatch();
  const setTgCpmFunction = (e) => {
    const tgCpmStr = e.target.value;
    dispatch(setTgCpm(tgCpmStr));
  };

  const setGenCpmFunction = (e) => {
    const genCpmStr = e.target.value;
    dispatch(setGenCpm(genCpmStr));
  };

  return (
    <>
      <tr>
        <TgSelectionCell tgSelectionClass={"px-3 border rounded-lg"} />
        <TgImpressionCell
          impressions={specificImpressions}
          dayCount={dayCount}
        />
        <CpmInputCell setCpmFunction={setTgCpmFunction} />
        <CpmRsCell
          elementStyle={"text-right"}
          impressions={specificImpressions}
          dayCount={dayCount}
          cpm={tgCPM}
        />
      </tr>
      <tr>
        <ImpressionString string={"Generic Impressions"} />
        <TgImpressionCell
          impressions={genericImpressions}
          dayCount={dayCount}
        />
        <CpmInputCell setCpmFunction={setGenCpmFunction} />
        <CpmRsCell
          elementStyle={"text-right"}
          impressions={genericImpressions}
          dayCount={dayCount}
          cpm={genCPM}
        />
      </tr>
    </>
  );
}

// Table Component
function EarningCalculatorTable({ dayCountAndLit }) {
  return (
    <div className="table-responsive p-3">
      <table className="table site-report-table">
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
        <tbody>
          <EarningCalculatorTableRow dayCountAndLit={dayCountAndLit} />
        </tbody>
      </table>
    </div>
  );
}

export default EarningCalculatorTable;
