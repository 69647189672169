import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Api
import {
  updateMediaPriceFn,
  getMediaPriceByIdsFn,
} from "../../apis/OrgMediaPriceAPI";

// Constants, Utils
import { OrgMediaPrice } from "../../constants/action-constants/org/OrgMediaPriceActionConstants";
import { getErrorMessage } from "../../utils/util";

/**
 * Update media price
 */
function* updateMediaPrice(action) {
  const { mediaPriceBean } = action.payload;
  try {
    yield updateMediaPriceFn(mediaPriceBean);

    yield put({
      type: OrgMediaPrice.UPDATE_MEDIA_PRICE_SUCCESS,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);

    yield put({
      type: OrgMediaPrice.UPDATE_MEDIA_PRICE_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
    toast.error(errorMessage);
  }
}

/**
 * Get media price by ids
 */
function* getMediaPriceByIds(action) {
  try {
    const { mediaIds } = action.payload;

    const mediaPriceInfo = yield getMediaPriceByIdsFn(mediaIds.join());

    yield put({
      type: OrgMediaPrice.GET_MEDIA_PRICE_BY_IDS_SUCCESS,
      payload: { mediaPriceInfo },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);

    yield put({
      type: OrgMediaPrice.GET_MEDIA_PRICE_BY_IDS_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(OrgMediaPrice.UPDATE_MEDIA_PRICE, updateMediaPrice),
    takeLatest(OrgMediaPrice.GET_MEDIA_PRICE_BY_IDS, getMediaPriceByIds),
  ]);
}
