import { Filter } from "../constants/action-constants/filter/FilterActionConstants";

//initial state
const initialState = {
  appliedFilters: {},
  currentFilters: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Filter.CANCEL_APPLIED_FILTERS:
      return {
        ...state,
        appliedFilters: {},
        currentFilters: {},
      };
    case Filter.APPLY:
      return {
        ...state,
        appliedFilters: action.payload.filters,
        currentFilters: {},
      };
    case Filter.CANCEL:
      return {
        ...state,
        currentFilters: {},
      };
    case Filter.SELECT:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          [action.payload.filter]: true,
        },
      };

    case Filter.UNSELECT:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          [action.payload.filter]: false,
        },
      };
    default:
      return state;
  }
};
