import { getClosestRoadSegmentsUrl, getRoadSegmentsUrl } from "../urls/RoadSegmentURL";
import { sendGetRequest } from "../utils/api/apiUtils";

export async function getSegmentDetails(segmentIds) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const roadSegmentIds = segmentIds.join();
  const params = {
    roadSegmentIds: roadSegmentIds,
  };

  return sendGetRequest(getRoadSegmentsUrl, params, true, headerParams);
}

export async function closestRoadSegmentsData(locationStr, inclNullableTraffic = false) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  // as of now, passing radius as 200mtrs
  const params = {
    latLng: locationStr,
    radius: 2000,
    inclNullableTraffic,
  };

  return sendGetRequest(getClosestRoadSegmentsUrl, params, true, headerParams);
}
