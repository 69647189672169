import Spinner from "../spinner/Spinner";
import "./Progress.scss";

function Progress(props) {
  const { className, progressTexts } = props;
  return (
    <div className={`progress-status ${className}`}>
      <Spinner />
      <div className="progress-status__text">
        <span className="progress-status__title">{progressTexts.title}</span>
        <span className="progress-status__subtitle">
          {progressTexts.subtitle}
        </span>
      </div>
    </div>
  );
}

export { Progress };
