import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";

function zeroFixedValue(value) {
  if (value === 0) {
    return value;
  }
  return toLocaleString(value);
}

function getAvgRealtyPrice(realtySociety) {
  if (realtySociety.count === 0) {
    return "--";
  }

  return (
    <>
      {`Rs. ${zeroFixedValue(realtySociety.mean)} per sq. ft.
        (Rs. ${zeroFixedValue(realtySociety.min)} - Rs.
        ${zeroFixedValue(realtySociety.max)} per sq. ft.)`}
    </>
  );
}

function getAvgCost(restaurants) {
  if (restaurants.counts === 0) {
    return "--";
  }

  return (
    <>
      {`Rs. ${zeroFixedValue(restaurants.mean)} (Rs. ${zeroFixedValue(
        restaurants.min
      )} - Rs. ${zeroFixedValue(restaurants.max)})`}
    </>
  );
}

export default function SocioEconomicIndicators({
  className = "",
  restaurants = {},
  realtySociety = {},
}) {
  const avgRealtyPrice = getAvgRealtyPrice(realtySociety);
  const avgCostOfTwoMeal = getAvgCost(restaurants);

  return (
    <div className={`socia-economic-indicators ${className}`}>
      <p>
        <strong>Socio-economic indicators</strong>
      </p>
      <p>Apartment complexes: {zeroFixedValue(realtySociety.count)} </p>
      <p>Average Realty Price: {avgRealtyPrice}</p>

      <p>Restaurants: {zeroFixedValue(restaurants.count)} </p>
      <p>Average Cost (For two persons): {avgCostOfTwoMeal}</p>
    </div>
  );
}
