import { ActionTypes } from "../constants/ActionConstants";

export const downloadTemplate = (fileName) => ({
  type: ActionTypes.SiteUpload.DOWNLOAD_TEMPLATE,
  payload: { fileName },
});

export const uploadTemplate = (campaignId, files, isMonitoring, tgId) => ({
  type: ActionTypes.SiteUpload.UPLOAD_TEMPLATE,
  payload: { campaignId, files, isMonitoring, tgId },
});

export const resetSiteUploadState = () => ({
  type: ActionTypes.SiteUpload.RESET_SITE_UPLOAD_STATE,
});
