import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Polyline, Popup } from "react-leaflet";

// Actions
import { getTargetGroups } from "../../../actions/org/OrgTargetGroupActions";
import {
  clearRegionDataByCity,
  getRegionDataByCity,
  getRegionNames,
} from "../../../actions/regions/RegionActions";
import { openSingleCitySelectionForm } from "../../../actions/SingleCitySelectionFormActions";
import { getRoadStretchesOfOrg } from "../../../actions/org/OrgRoadStretchActions";
import {
  clearRouteSummary,
  getRouteSummary,
} from "../../../actions/mavin-tools/RouteAnalysisActions";

// Utils and Constants
import { defaultPagination } from "../../../constants/UrlConstants";
import { SelectedRouteStyle } from "../../../constants/CssConstants";
import {
  DurationOptions,
  FormDataTargets,
  India,
  MapZoom,
} from "../../../constants/GeneralConstants";
import {
  AgeSplitBarData,
  GenderLinesData,
  GenderLinesDataMapping,
  ImpressionsBarData,
  ImpressionsBarDataMapping,
  ReachBarData,
  ReachBarDataMapping,
} from "./RouteAnalysisToolConstants";
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";

// Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import { PlainDropdown } from "../../../components/dropdown/Dropdown";
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";
import DurationSelector from "../../../mavin/components/duration-selector/DurationSelector";
import { ButtonWithLoader } from "../../../mavin/components/button/Button";
import TableHeaders from "../../../components/table/TableHeaders";
import { BarChart, LineChart } from "../../../components/charts/Charts";
import TargetGroupForm from "../../../prooh/pages/campaign-planning/TargetGroupForm";
import SingleCitySelectionForm from "../../../mavin/components/single-city-selection-form/SingleCitySelectionForm";
import LLMap from "../../../components/map/leaflet-map/LLMap";
import { SelectTargetGroup } from "../ToolsUtils";

// page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Total Impressions",
    },
  },
  {
    title: {
      displayName: "Total Reach",
    },
  },
  {
    title: {
      displayName: "Frequency",
    },
  },
  {
    title: {
      displayName: "TG Impressions",
    },
  },
  {
    title: {
      displayName: "TG Reach",
    },
  },
];

const constructChartData = (data, dataMappings, dataKey = "time") =>
  data.map((item) => {
    const formattedItem = { name: item[dataKey] };
    dataMappings.forEach(
      (mapping) => (formattedItem[mapping.label] = item[mapping.valueKey])
    );
    return formattedItem;
  });

function RouteReachSplitGraphSection({ reachData = [] }) {
  const reachSplitData = constructChartData(reachData, ReachBarDataMapping);

  return (
    <div className="mt-4">
      <h4 className="mb-0">Route Reach V/s TG-Reach Split (Hourly Average)</h4>

      {/* bar chart */}
      <div className="pt-4">
        <BarChart
          data={reachSplitData}
          barsData={ReachBarData}
          showCartesianGrid={true}
          showCustomXAxisTick={true}
          barSize={10}
        />
      </div>
      <hr className="my-3" />
    </div>
  );
}

// Impressions Split Graph Section
function RouteImpressionsSplitGraphSection({ impressionsData = [] }) {
  const impressionsSplitData = constructChartData(
    impressionsData,
    ImpressionsBarDataMapping
  );

  return (
    <div className="mt-4">
      <h4 className="mb-0">
        Route Impressions V/s TG-Impressions Split (Hourly Average)
      </h4>
      <div className="pt-4">
        <BarChart
          data={impressionsSplitData}
          barsData={ImpressionsBarData}
          showCartesianGrid={true}
          showCustomXAxisTick={true}
          barSize={10}
        />
        <hr className="my-3" />
      </div>
    </div>
  );
}

function RouteAgeSplitGraphSection({ ageSplitData = [] }) {
  return (
    <div className="mt-4">
      <h4 className="mb-0">Route Age Split Graph (Hourly Average)</h4>
      <div className="pt-4">
        <BarChart
          data={ageSplitData}
          barsData={AgeSplitBarData}
          showCartesianGrid={true}
          showCustomXAxisTick={true}
          barSize={10}
        />
        <hr className="my-3" />
      </div>
    </div>
  );
}

function RouteGenderSplitGraphSection({ genderRatioData = [] }) {
  const genderSplitData = constructChartData(
    genderRatioData,
    GenderLinesDataMapping,
    "name"
  );

  return (
    <div className="mt-4">
      <h4 className="mb-0">Route Gender Split Graph (Hourly Average)</h4>
      <div className="pt-4">
        <LineChart data={genderSplitData} linesData={GenderLinesData} />
        <hr className="my-3" />
      </div>
    </div>
  );
}

function RouteMetricsTable({ routeInfo }) {
  const { totalTgOTS, totalTgReach, totalOTS, totalReach, totalFrequency } =
    routeInfo;
  return (
    <>
      <h2 className="title">Route Metrics</h2>
      <div className="table-responsive">
        <table className="table">
          {/* Table header */}
          <TableHeaders
            tableHeaders={tableHeaders}
            requiredSubHeader={false}
            headerClass={"thead"}
          />

          <tbody>
            <tr>
              <td>{formatText(toLocaleString(totalOTS))}</td>
              <td>{formatText(toLocaleString(totalReach))}</td>
              <td>{totalFrequency}</td>
              <td>{formatText(toLocaleString(totalTgOTS))}</td>
              <td>{formatText(toLocaleString(totalTgReach))}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

// city selector
function SingleCitySelector({ cityName }) {
  const dispatch = useDispatch();

  return (
    <div className="d-flex align-items-center">
      <button
        type="button"
        className="btn border rounded-lg shadow-none px-2 dropdown-toggle w-100"
        data-toggle="modal"
        data-target={`#${FormDataTargets.singleCitySelectionForm}`}
        onClick={() => dispatch(openSingleCitySelectionForm())}
      >
        <span className="pr-1">{cityName ? cityName : "Select City"}</span>
      </button>
    </div>
  );
}

function MapViewWithSelectedRoadStretch({ center = {}, selectedRoute = {} }) {
  const isCenterAvailable = Object.keys(center).length > 0;
  const correctedCenter = isCenterAvailable
    ? [center.latitude, center.longitude]
    : India.mapCenter;
  const { trace = [], label = "" } = selectedRoute;
  const zoom = isCenterAvailable ? MapZoom.zoomLevel10 : India.mapZoomLevel5;

  return (
    <div className="map-layout-right bg-alt">
      <LLMap center={correctedCenter} zoom={zoom}>
        {trace.length > 0 && (
          <Polyline pathOptions={SelectedRouteStyle} positions={trace}>
            <Popup>
              <b>{label}</b>
            </Popup>
          </Polyline>
        )}
      </LLMap>
    </div>
  );
}

// RouteTgAndDurationSection
function RouteTgAndDurationSection({
  orgRoadStretches,
  selectedRouteId,
  setSelectedRouteId,
  cityId,
  selectedTgId,
}) {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState("");
  const [duration, setDuration] = useState(null);

  const orgRoadStretchesLoading = useSelector(
    (state) => state.orgRoadStretch.orgRoadStretchesLoading
  );
  const routeInfoLoading = useSelector(
    (state) => state.routeAnalysisTool.routeInfoLoading
  );

  const isButtonDisabled =
    !selectedTgId ||
    !duration ||
    !startDate ||
    !selectedRouteId ||
    routeInfoLoading;

  const orgRoadStretchesLength = orgRoadStretches.length;

  const message = orgRoadStretchesLoading
    ? "Loading..."
    : "No Routes are Available.";

  // reset on city change
  useEffect(() => {
    setStartDate("");
    setDuration(null);
  }, [cityId]);

  function getRouteInfo() {
    dispatch(clearRouteSummary());

    // Converting "startDate" into Required format
    const startDateTimestamp = Date.parse(startDate);
    dispatch(
      getRouteSummary(
        selectedRouteId,
        selectedTgId,
        startDateTimestamp,
        duration
      )
    );
  }

  if (orgRoadStretchesLoading || orgRoadStretchesLength === 0) {
    return (
      <div className="text-center p-3">
        <p className="font-italic mb-0">{message}</p>
      </div>
    );
  }

  return (
    <>
      <div className="pt-3">
        <PlainDropdown
          items={orgRoadStretches}
          className="btn shadow-none rounded-lg border"
          placeHolder="Select Route"
          onItemSelect={setSelectedRouteId}
          dropMenuClassName="dropdown-fixed-height"
        />
      </div>
      <div className="d-flex justify-content-between align-items-center pt-3">
        <SelectTargetGroup selectedTgId={selectedTgId} />
        <BootstrapDateRangePicker
          isSingleDatePicker={true}
          initialStartDate={startDate}
          onApplyDates={setStartDate}
          placeHolder={"Select Date"}
          styleObject={{
            autoApply: true,
            border: true,
            buttonClassName: "col-2 px-0",
          }}
        />
        <DurationSelector
          items={DurationOptions}
          onItemSelect={setDuration}
          startDate={startDate}
          initialDays={duration}
          buttonClassName="shadow-none col px-2"
        />
        <ButtonWithLoader
          displayContent="Get Analysis"
          loader={routeInfoLoading}
          isDisabled={isButtonDisabled}
          onClick={getRouteInfo}
        />
      </div>
    </>
  );
}

/**
 * Page : Route analysis Tool Page
 */
export default function RouteAnalysisToolPage() {
  const dispatch = useDispatch();

  const [cityId, setCityId] = useState("");
  const [selectedTgId, setSelectedTgId] = useState("");
  const [selectedRouteId, setSelectedRouteId] = useState("");
  const [selectedRoute, setSelectedRoute] = useState({});

  const pageNo = defaultPagination.pageNo,
    pageSize = defaultPagination.pageSizeMax;

  const openTgSetupForm = useSelector(
    (state) => state.targetGroupFormModal.openModal
  );

  const openSingleCitySelectionForm = useSelector(
    (state) => state.singleCitySelectionModal.openModal
  );

  const orgRoadStretchesMap = useSelector(
    (state) => state.orgRoadStretch.orgRoadStretches
  );

  const routeInfo = useSelector((state) => state.routeAnalysisTool.routeInfo);
  const constructedRouteInfo = useSelector(
    (state) => state.routeAnalysisTool.constructedRouteInfo
  );
  const regionsName = useSelector((state) => state.region.regionsName) || [];
  const regionData = useSelector((state) => state.region.regionData);

  const orgRoadStretches = Object.values(orgRoadStretchesMap).map((stretch) => {
    return { id: stretch.id, label: stretch.name, trace: stretch.trace };
  });

  const { impressionsData, reachData, genderRatioData, ageSplitData } =
    constructedRouteInfo;

  const { center, name: cityName } = regionData;

  useEffect(() => {
    dispatch(getTargetGroups(true, "", pageNo, pageSize));
    dispatch(getRegionNames());
    dispatch(clearRegionDataByCity());
  }, [dispatch]);

  // reset on city change
  useEffect(() => {
    dispatch(clearRouteSummary());
    setSelectedRoute({});
    setSelectedRouteId("");
    setSelectedTgId("");
  }, [cityId]);

  useEffect(() => {
    // clear summary when route changed
    dispatch(clearRouteSummary());

    // update route
    if (selectedRouteId) {
      const foundStretch = orgRoadStretches.find(
        (stretch) => stretch.id === selectedRouteId
      );
      setSelectedRoute(foundStretch);
    } else {
      setSelectedRoute({});
    }
  }, [dispatch, selectedRouteId, orgRoadStretches.length]);

  // Functions
  function tgSubmitFn(tgId) {
    setSelectedTgId(tgId);
  }

  function onSubmitCitySelectionForm(cityId) {
    setCityId(cityId);
    dispatch(getRegionDataByCity(cityId));
    dispatch(getRoadStretchesOfOrg("false", cityId));
  }

  return (
    <div className="content-wrapper map-content-wrapper">
      <div className="map-layout-left">
        <PageHeader title="Analyze Route" shadow={false} />
        <hr className="my-3 row"></hr>

        {/* city selector */}
        <SingleCitySelector cityName={cityName} />

        {/* route & tg & other selection */}
        {cityId && (
          <>
            <RouteTgAndDurationSection
              orgRoadStretches={orgRoadStretches}
              selectedRouteId={selectedRouteId}
              setSelectedRouteId={setSelectedRouteId}
              selectedTgId={selectedTgId}
              cityId={cityId}
            />
          </>
        )}

        <hr className="my-3 row" />
        {Object.keys(routeInfo).length > 0 && (
          <>
            <RouteMetricsTable routeInfo={routeInfo} />
            <RouteImpressionsSplitGraphSection
              impressionsData={impressionsData}
            />
            <RouteReachSplitGraphSection reachData={reachData} />
            <RouteGenderSplitGraphSection genderRatioData={genderRatioData} />
            <RouteAgeSplitGraphSection ageSplitData={ageSplitData} />
          </>
        )}
      </div>

      {/* Map VIew */}
      <MapViewWithSelectedRoadStretch
        center={center}
        selectedRoute={selectedRoute}
      />

      {/* Modals */}
      {openSingleCitySelectionForm && (
        <SingleCitySelectionForm
          regionsName={regionsName}
          onSubmit={onSubmitCitySelectionForm}
        />
      )}
      {openTgSetupForm && (
        <TargetGroupForm segmentIds={["abc"]} dispatchFn={tgSubmitFn} />
      )}
    </div>
  );
}
