export const SingleSiteAnalyser = {
  // Single Site Report Tool
  GET_SINGLE_SITE_ANALYSER_INFO: "GET_SINGLE_SITE_ANALYSER_INFO",
  GET_SINGLE_SITE_ANALYSER_INFO_SUCCESS:
    "GET_SINGLE_SITE_ANALYSER_INFO_SUCCESS",
  GET_SINGLE_SITE_ANALYSER_INFO_FAILURE:
    "GET_SINGLE_SITE_ANALYSER_INFO_FAILURE",

  // Reset data
  RESET_SINGLE_SITE_ANALYSER_INFO: "RESET_SINGLE_SITE_ANALYSER_INFO",
};
