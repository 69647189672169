import { useState } from "react";
import { useDispatch } from "react-redux";

// Actions
import { unselectDataLayer } from "../../../actions/map-view/DataLayerSelectionActions";

// Components
import SearchInputLocal from "../../../components/search-input-local/SearchInputLocal";

// Page Components
function DataLayersList({ id, name }) {
  // Dispatch
  const dispatch = useDispatch();
  return (
    <div className="row justify-content-between p-2 border-bottom mt-2">
      <span className="form-check-label ml-2">{name}</span>
      <button
        className={`btn btn-outline-primary add-button shadow-none`}
        onClick={() => dispatch(unselectDataLayer(id))}
      >
        {"Remove"}
      </button>
    </div>
  );
}

/**
 * Selected DataLayer Section
 */
export default function SelectedDataLayersSection({
  selectedDataLayersList,
  selectedViewLabels,
}) {
  // State
  const [selectedDataLayers, setSelectedDataLayersList] = useState([]);

  // Search Labels
  const { search } = selectedViewLabels;

  return (
    <>
      {/* Search datalayers */}
      <SearchInputLocal
        placeholder={search.placeholder}
        className={"mt-2"}
        dataList={selectedDataLayersList}
        searchFields={search.fields}
        onSearchComplete={setSelectedDataLayersList}
      />
      <hr className="divider my-2" />

      {/*Selected Data-Layers Listing */}
      {selectedDataLayers.map((dataLayer) => (
        <DataLayersList
          key={dataLayer.id}
          id={dataLayer.id}
          name={dataLayer.name}
        />
      ))}
    </>
  );
}
