/* eslint-disable no-case-declarations */
import { CampaignGraphReport } from "../../constants/action-constants/CampaignGraphReportActionConstants";

const initialState = {
  // Campaign Media Demographic Stats -------------------------------
  campaignCityWiseMedias: {},
  campaignMediaDemographicStatsLoading: false,
  campaignMediaDemographicStatsError: "",

  // campaign graph pdf Report ------------------------------
  downloadCampaignGraphPdfReportLoading: false,
  downloadCampaignGraphPdfReportError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Campaign Media Demographic Stats
    //-------------------------------------------------------------------------------------
    case CampaignGraphReport.GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS:
      return {
        ...state,
        campaignMediaDemographicStatsLoading: true,
      };

    case CampaignGraphReport.GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS_SUCCESS:
      return {
        ...state,
        campaignMediaDemographicStatsLoading: false,
        campaignCityWiseMedias: action.payload.cityWiseMedias,
      };

    case CampaignGraphReport.GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS_FAILURE:
      return {
        ...state,
        campaignMediaDemographicStatsLoading: false,
        campaignMediaDemographicStatsError: action.payload,
      };

    // Download Report pdf
    //-------------------------------------------------------------------------------------
    case CampaignGraphReport.DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT:
      return {
        ...state,
        downloadCampaignGraphPdfReportLoading: true,
      };

    case CampaignGraphReport.DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT_SUCCESS:
      return {
        ...state,
        downloadCampaignGraphPdfReportLoading: false,
      };

    case CampaignGraphReport.DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT_FAILURE:
      return {
        ...state,
        downloadCampaignGraphPdfReportLoading: false,
        downloadCampaignGraphPdfReportError: action.payload,
      };

    default:
      return state;
  }
};
