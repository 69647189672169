import { PlatformUpdatesForm } from "../constants/action-constants/PlatformUpdatesFormActionConstants";

// These below actions are for "Platform Updates" form
export const openPlatformUpdatesForm = () => ({
  type: PlatformUpdatesForm.OPEN_PLATFORM_UPDATES_MODAL,
});

export const closePlatformUpdatesForm = () => ({
  type: PlatformUpdatesForm.CLOSE_PLATFORM_UPDATES_MODAL,
});
