import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Actions
import { resetTgGroup } from "../../../actions/org/OrgTargetGroupActions";
import {
  getCampaignPlan,
  resetCampaignPlanDetails,
} from "../../../actions/campaign-plan/CampaignPlanActions";
import {
  getBasicCampaignDetails,
  restCampaignDetails,
} from "../../../actions/campaign/CampaignBasicInfoActions";
import { resetRoadStretches } from "../../../actions/org/OrgRoadStretchActions";
import { resetSellerMediaByStretchIds } from "../../../actions/seller/SellerMediaActions";
import { resetMediaSelection } from "../../actions/media-selection/MediaSelectionActions";

// Utils and constants
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";
import {
  OtsData,
  PreviewData,
  SelectionOverviewData,
  SiteSelectionData,
} from "./MediaSelectionPageConstants";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";
import { constructDateString } from "../../../common-utils/date-utils/DateUtils";

// Components
import CityViewCards from "../components/CityViewCards";
import { EstimatedPriceBreakDown } from "../components/EstimatedPriceBreakDown";
import Chips from "../../../components/Chips/Chips";

// Page Sections
import { HeaderSection } from "./HeaderSection";
import RoadAndMediaSection from "./RoadAndMediaSection";
import CreateMediaForm from "./CreateMediaForm";

function StretchSelectionOverview({ campaign, cityPlan }) {
  const { infoByCity = {} } = campaign;
  const { cityId, startTimestamp, endTimestamp } = cityPlan;
  const { cityName } = infoByCity[cityId] || "";
  const dateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  return (
    <div className="row align-items-stretch">
      <div className="col-12 col-xl-6">
        <h5>Stretch Selection overview</h5>
        <div className="border p-3 overview-container">
          <div className="d-flex justify-content-between">
            {SelectionOverviewData.map(({ title, value }) => (
              <div key={title}>
                <h5>{title}</h5>
                <h3>{value}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="col-12 col-xl-6 mt-3 mt-xl-0">
        <div className="d-flex justify-content-between align-items-center">
          <h5>City: {cityName}</h5>
          <h5>{dateString}</h5>
        </div>
        <div className="border p-3 overview-container overflow-x-scroll">
          <div className="d-flex">
            {PreviewData.map(({ title, list }) => (
              <div className="col chips-container" key={title}>
                <Chips
                  title={title}
                  selectedItems={list}
                  headerClassName="sticky-top"
                  containerClassName="scrollable-container flex-column"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function OverviewSection({ data }) {
  return (
    <div className="col-6">
      <div className="border p-3 overview-container">
        <div className="d-flex justify-content-between">
          {data.map(({ title, value }) => (
            <div key={title}>
              <h5>{title}</h5>
              <h3>{value}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function SiteSelectionStatus() {
  return (
    <>
      <h5 className="mt-5">Site Selection overview</h5>
      <div className="row align-items-stretch">
        <OverviewSection data={SiteSelectionData} />
        <OverviewSection data={OtsData} />
      </div>
    </>
  );
}

/**
 * Page
 */
function MediaSelectionPage() {
  const dispatch = useDispatch();
  const { campaignId, cityId } = useParams();

  // Get campaignPlan
  const cityCampaignPlan =
    useSelector((state) => state.campaignPlan.campaignPlan) || {};

  const { targetGroupId } = cityCampaignPlan;
  // tg-name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  //open media modal
  const openCreateMediaModal = useSelector(
    (state) => state.createMediaModal.openModal
  );

  // CampaignMedia
  const campaignMedia = useSelector((state) =>
    Object.values(state.mediaSelection.mediaIdToCampaignMedia)
  );

  useEffect(() => {
    // Reset Actions
    dispatch(resetRoadStretches());
    dispatch(resetSellerMediaByStretchIds());
    dispatch(resetRoadStretches());
    dispatch(resetCampaignPlanDetails());
    dispatch(restCampaignDetails());
    dispatch(resetTgGroup());
    dispatch(resetMediaSelection());

    dispatch(getCampaignPlan(campaignId, cityId));
    dispatch(getBasicCampaignDetails(campaignId));
  }, [dispatch, campaignId, cityId]);

  useGetTgInfo(targetGroupId);

  return (
    <div className="content-wrapper media-selection">
      {/* Header Section */}
      <HeaderSection campaign={campaign} cityId={cityId} />

      <div className="page-content">
        {/* city view Cards */}
        {/* <CityViewCards
          tgName={tgName}
          campaign={campaign}
          cityPlan={cityCampaignPlan}
          campaignMedia={campaignMedia}
        /> */}
        <StretchSelectionOverview
          campaign={campaign}
          cityPlan={cityCampaignPlan}
        />

        <SiteSelectionStatus />

        {/* Estimated Price Breakdown Table */}
        {/* <EstimatedPriceBreakDown
          tgName={tgName}
          campaign={campaign}
          campaignPlan={cityCampaignPlan}
          submissionImpact={true}
        /> */}

        {/* Stretch And Media Selection Table */}
        <RoadAndMediaSection
          campaignId={campaignId}
          campaignPlan={cityCampaignPlan}
        />
      </div>

      {/* MODAL */}
      {openCreateMediaModal && (
        <CreateMediaForm campaignId={campaignId} cityId={cityId} />
      )}
    </div>
  );
}

export default MediaSelectionPage;
