import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getOrgProfile } from "../../../actions/org/OrgProfileActions";
import { getOrgUserStats } from "../../../actions/org/OrgUserManageActions";

// Utils and Constants
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";
import { constructSingleDateString } from "../../../common-utils/date-utils/DateUtils";
import { LoadingData } from "../OrgUtils";

// Component
import OrgSettingsSidebar from "../OrgSettingsSidebar";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Css
import "../OrgSettings.scss";

// Page Components
function UserAccountsCard({ orgUserStats = {} }) {
  const { totalUsers, activeUsers, inActiveUsers } = orgUserStats || {};

  const userStatsData = [
    {
      label: "Total Users",
      value: totalUsers,
    },
    {
      label: "Active Users",
      value: activeUsers,
    },
    {
      label: "Available Users",
      value: inActiveUsers,
    },
  ];

  return (
    <div className="card rounded-lg col-4 mx-3">
      <div className="card-body">
        <h4 className="mb-3">User Accounts</h4>
        {userStatsData.map((data, i) => {
          const { label, value } = data;
          return (
            <p key={i}>
              {label}:{" "}
              <span className="font-weight-bold">
                {value === 0 ? 0 : toLocaleString(value)}
              </span>
            </p>
          );
        })}
      </div>
    </div>
  );
}

function SubscriptionDetailsCard({ orgProfile = {} }) {
  const {
    isSubscriptionActive,
    subscriptionStartTimestamp,
    subscriptionExpiryTimestamp,
    subscriptionDurationInDays,
  } = orgProfile || {};

  // subscription start date
  const subscriptionStartDate = constructSingleDateString(
    subscriptionStartTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  // subscription expiry date
  const subscriptionExpiryDate = constructSingleDateString(
    subscriptionExpiryTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  // checking subscription is annually or not
  const isSubscriptionAnnual = subscriptionDurationInDays === 365;
  const subscriptionType = isSubscriptionAnnual ? "Annual" : "Custom";

  const subscriptionData = [
    {
      label: "Current Subscription type",
      value: subscriptionType,
    },
    {
      label: "Last Renewal Date",
      value: formatText(subscriptionStartDate),
    },
    {
      label: "Next Renewal Date",
      value: formatText(subscriptionExpiryDate),
    },
  ];

  return (
    <div className="card rounded-lg col-4 mx-3">
      <div className="card-body">
        <h4 className="mb-3">Subscription Details</h4>
        {/* inactive subscription */}
        {!isSubscriptionActive && (
          <p className="text-muted font-italic">
            Your subscription has expired.
            <br />
            Please renew the subscription.
          </p>
        )}

        {/* active subscription */}
        {isSubscriptionActive &&
          subscriptionData.map((data, i) => {
            const { label, value } = data;
            return (
              <p key={i}>
                {label}: <span className="font-weight-bold">{value}</span>
              </p>
            );
          })}
      </div>
    </div>
  );
}

function SubscriptionCardSection({
  orgProfile = {},
  orgProfileLoading = false,
  orgUserStats = {},
  orgUserStatsLoading = false,
}) {
  // Checks for profile & user stats loading
  if (orgProfileLoading || orgUserStatsLoading) {
    return <LoadingData dataType="Subscription Info" />;
  }

  return (
    <div className="d-flex">
      <SubscriptionDetailsCard orgProfile={orgProfile} />
      <UserAccountsCard orgUserStats={orgUserStats} />
    </div>
  );
}

/**
 * Page
 */
export default function OrgSubscriptionPage() {
  const dispatch = useDispatch();

  // org profile
  const orgProfile = useSelector((state) => state.orgProfile.orgProfileInfo);
  const orgProfileLoading = useSelector((state) => state.orgProfile.loading);

  // org user stats
  const orgUserStats = useSelector((state) => state.orgUserManage.orgUserStats);
  const orgUserStatsLoading = useSelector(
    (state) => state.orgUserManage.orgUserStatsLoading
  );

  const pageTitle = "Org :: Subscription";

  useEffect(() => {
    dispatch(getOrgProfile());
    dispatch(getOrgUserStats());
  }, [dispatch]);

  return (
    <>
      <OrgSettingsSidebar activeIdx={3} />

      <div className="content-wrapper">
        {/** Page Header */}
        <PageHeader title={pageTitle} shadow={true} />

        {/** Page Content */}
        <div className="page-content">
          <SubscriptionCardSection
            orgProfile={orgProfile}
            orgProfileLoading={orgProfileLoading}
            orgUserStats={orgUserStats}
            orgUserStatsLoading={orgUserStatsLoading}
          />
        </div>
      </div>
    </>
  );
}
