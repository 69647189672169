import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  clearCompareLocationSummary,
  getCompareLocationSummary,
} from "../../actions/mavin-tools/CompareLocationsActions";

// Constants and Utils
import { DemographicSummaryComponent, SiteMapViewSection } from "./ToolsUtils";

// Components
import PageHeader from "../../mavin/components/page-header/PageHeader";
import RadiusSelector from "../../mavin/components/location-summary/RadiusSelector";
import { ButtonWithLoader } from "../../mavin/components/button/Button";

// Page Component/Section
function CompareLocationSummarySection({
  locationStr1,
  locationStr2,
  coordinate1,
  coordinate2,
}) {
  const compareLocationSummary = useSelector(
    (state) => state.compareLocationsTool.compareLocationSummary
  );

  return (
    <div className="d-flex">
      <DemographicSummaryComponent
        fitWithinClassName="col-6"
        locationTitle={"Location 1 ::"}
        coordinates={coordinate1}
        demographicData={compareLocationSummary[locationStr1]}
      />
      <DemographicSummaryComponent
        fitWithinClassName="col-6"
        locationTitle={"Location 2 ::"}
        coordinates={coordinate2}
        demographicData={compareLocationSummary[locationStr2]}
      />
    </div>
  );
}

/**
 * TOOL :: Compare Locations
 */
function CompareLocationsToolPage() {
  const dispatch = useDispatch();
  // States
  const [radius, setRadius] = useState(1000);
  const [loadingByButton, setLoadingByButton] = useState(true);
  const [locationStr1, setLocationStr1] = useState("");
  const [locationStr2, setLocationStr2] = useState("");
  const [coordinate1, setCoordinate1] = useState([]);
  const [coordinate2, setCoordinate2] = useState([]);

  // Selector State
  const compareLocationSummary = useSelector(
    (state) => state.compareLocationsTool.compareLocationSummary
  );
  const compareLocationSummaryLoading = useSelector(
    (state) => state.compareLocationsTool.compareLocationSummaryLoading
  );

  // clear compared location summary info
  useEffect(() => {
    dispatch(clearCompareLocationSummary());

    // to clear the Coordinates
    if (!locationStr1) {
      setCoordinate1([]);
    }
    if (!locationStr2) {
      setCoordinate2([]);
    }
  }, [dispatch, locationStr1, locationStr2]);

  // Functions
  function getDemographics() {
    setLoadingByButton(true);
    dispatch(getCompareLocationSummary(coordinate1, coordinate2, radius));
  }

  function changeRadius(radius) {
    setLoadingByButton(false);
    setRadius(radius);
    dispatch(getCompareLocationSummary(coordinate1, coordinate2, radius));
  }

  return (
    <div className="content-wrapper">
      {/* Map Left Section */}
      <PageHeader title={"Compare Locations"} shadow={false} border={true} />

      <div className="page-content">
        <div className="d-flex single-site-detail">
          {/* Location Map Section */}
          <SiteMapViewSection
            title={"Location 1"}
            locationStr={locationStr1}
            setLocationStr={setLocationStr1}
            coordinate={coordinate1}
            setCoordinate={setCoordinate1}
          />
          <SiteMapViewSection
            title={"Location 2"}
            locationStr={locationStr2}
            setLocationStr={setLocationStr2}
            coordinate={coordinate2}
            setCoordinate={setCoordinate2}
          />
        </div>

        {/* Get Demographics button */}
        <div className="text-center mt-5">
          <ButtonWithLoader
            onClickFunction={getDemographics}
            isDisabled={
              !locationStr1 ||
              !locationStr2 ||
              (compareLocationSummaryLoading && loadingByButton)
            }
            displayContent={"Compare Locations"}
            loader={compareLocationSummaryLoading && loadingByButton}
          />
        </div>
        <hr className="divider"></hr>

        {compareLocationSummary && (
          <>
            {/* Title And Radius Selector */}
            <RadiusSelector
              title={"Compare Location Summary Within"}
              className="p-3"
              onItemSelect={changeRadius}
              isLoading={compareLocationSummaryLoading && !loadingByButton}
            />

            {/* Location summary section of "Location1" and "Location2" */}
            <CompareLocationSummarySection
              locationStr1={locationStr1}
              locationStr2={locationStr2}
              coordinate1={coordinate1}
              coordinate2={coordinate2}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default CompareLocationsToolPage;
