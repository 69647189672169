// Utils and Constants
import { calculatePercentage } from "../../common-utils/number-utils/NumberUtils";
import { toLocaleString } from "../../common-utils/string-utils/StringUtils";
import { getOtsTotal } from "../../utils/mavinMetrics";
import { MetricKeys } from "./GraphReportsConstants";

// sum of metrics
function getMetricTotalOfCities(infoByCity = {}, metricKey) {
  const citiesMetricsArray = Object.values(infoByCity).map((cityInfo) => {
    const { otsTotal, tgOtsTotal } = getOtsTotal(cityInfo) || {};
    const { reachFrequency = {} } = cityInfo;
    const { reach, tgReach, frequency } = reachFrequency;

    switch (metricKey) {
      case MetricKeys.impressions:
        return otsTotal;
      case MetricKeys.tgImpressions:
        return tgOtsTotal;
      case MetricKeys.reach:
        return reach;
      case MetricKeys.tgReach:
        return tgReach;
      case MetricKeys.frequency:
        return frequency;
      default:
        return null;
    }
  });

  // return sum
  return citiesMetricsArray.reduce((acc, curr) => acc + curr, 0);
}

// construct PieChart Data Map
export function constructPieChartDataMap(campaign = {}) {
  const { infoByCity = {} } = campaign;

  // constants
  const { impressions, tgImpressions, reach, tgReach, frequency } = MetricKeys;

  // metrics totals
  const citiesTotalOts = getMetricTotalOfCities(infoByCity, impressions);
  const citiesTotalTgOts = getMetricTotalOfCities(infoByCity, tgImpressions);
  const citiesTotalReach = getMetricTotalOfCities(infoByCity, reach);
  const citiesTotalTgReach = getMetricTotalOfCities(infoByCity, tgReach);
  const citiesTotalFrequency = getMetricTotalOfCities(infoByCity, frequency);

  // impressions split
  const impressionsSplit = Object.values(infoByCity).map((cityInfo) => {
    const { cityName, otsTotal } = cityInfo;
    const otsPercentage = Number(calculatePercentage(otsTotal, citiesTotalOts));
    return {
      name: cityName,
      value: otsPercentage,
      count: toLocaleString(otsTotal),
    };
  });

  // tg impressions split
  const tgImpressionsSplit = Object.values(infoByCity).map((cityInfo) => {
    const { cityName, tgOtsTotal } = cityInfo;
    const tgOtsPercentage = Number(
      calculatePercentage(tgOtsTotal, citiesTotalTgOts)
    );
    return {
      name: cityName,
      value: tgOtsPercentage,
      count: toLocaleString(tgOtsTotal),
    };
  });

  // reach split
  const reachSplit = Object.values(infoByCity).map((cityInfo) => {
    const { cityName, reachFrequency = {} } = cityInfo;
    const { reach } = reachFrequency;
    const reachPercentage = Number(
      calculatePercentage(reach, citiesTotalReach)
    );
    return {
      name: cityName,
      value: reachPercentage,
      count: toLocaleString(reach),
    };
  });

  // tg reach split
  const tgReachSplit = Object.values(infoByCity).map((cityInfo) => {
    const { cityName, reachFrequency = {} } = cityInfo;
    const { tgReach } = reachFrequency;
    const tgReachPercentage = Number(
      calculatePercentage(tgReach, citiesTotalTgReach)
    );
    return {
      name: cityName,
      value: tgReachPercentage,
      count: toLocaleString(tgReach),
    };
  });

  // frequency split
  const frequencySplit = Object.values(infoByCity).map((cityInfo) => {
    const { cityName, reachFrequency = {} } = cityInfo;
    const { frequency } = reachFrequency;
    const frequencyPercentage = Number(
      calculatePercentage(frequency, citiesTotalFrequency)
    );
    return {
      name: cityName,
      value: frequencyPercentage,
      count: toLocaleString(frequency),
    };
  });

  // metrics map
  const metricsMap = {
    [MetricKeys.impressions]: impressionsSplit,
    [MetricKeys.tgImpressions]: tgImpressionsSplit,
    [MetricKeys.reach]: reachSplit,
    [MetricKeys.tgReach]: tgReachSplit,
    [MetricKeys.frequency]: frequencySplit,
  };

  return metricsMap;
}

// construct city Impressions Split
export function constructCityImpressionsSplit(infoByCity) {
  const constructedImpressionsSplit = Object.values(infoByCity).map(
    (cityInfo) => {
      const { cityName } = cityInfo;
      const { otsTotal, tgOtsTotal } = getOtsTotal(cityInfo);

      return {
        name: cityName,
        Impressions: otsTotal,
        "TG Impressions": tgOtsTotal,
      };
    }
  );

  return constructedImpressionsSplit;
}

// construct city reach Split
export function constructCityReachSplit(summaryByCity) {
  const constructedReachSplit = Object.values(summaryByCity).map((cityInfo) => {
    const { cityName, reachFrequency } = cityInfo;
    const { reach, tgReach } = reachFrequency;
    return {
      name: cityName,
      Reach: reach,
      "TG Reach": tgReach,
    };
  });

  return constructedReachSplit;
}

// construct Cities List For Dropdown
export function constructCitiesListForDropdown(campaignCityWiseMedias) {
  const constructedCitiesList = Object.keys(campaignCityWiseMedias).map(
    (campaignCityId) => {
      const cityMediaList = campaignCityWiseMedias[campaignCityId];
      const { cityName } = cityMediaList[0];
      return {
        id: campaignCityId,
        label: cityName,
      };
    }
  );

  return constructedCitiesList;
}

// construct Media Site Wise genderRatio
export function constructMediaSiteWiseGenderRatio(
  campaignCityWiseMedias = {},
  selectedCityId = ""
) {
  // selected city media list
  const mediaListForSelectedCity = campaignCityWiseMedias[selectedCityId] || [];

  // data
  const constructedData = mediaListForSelectedCity.map((media) => {
    const { title, demographicStatistics = {} } = media;
    const { malePercent, femalePercent } = demographicStatistics;
    return {
      name: title,
      Male: malePercent ? malePercent.toFixed(2) : malePercent,
      Female: femalePercent ? femalePercent.toFixed(2) : femalePercent,
    };
  });

  return constructedData;
}

// construct Media Site Wise Age Split
export function constructMediaSiteWiseAgeSplit(
  campaignCityWiseMedias = {},
  selectedCityId = ""
) {
  // selected city media list
  const mediaListForSelectedCity = campaignCityWiseMedias[selectedCityId] || [];

  // data
  const constructedData = mediaListForSelectedCity.map((media) => {
    const { title, demographicStatistics = {} } = media;
    const {
      age_18_25Percent,
      age_26_35Percent,
      age_36_45Percent,
      age_45AbvPercent,
    } = demographicStatistics;

    return {
      name: title,
      "Age(%) 18-25": age_18_25Percent ? age_18_25Percent.toFixed(2) : 0,
      "Age(%) 26-35": age_26_35Percent ? age_26_35Percent.toFixed(2) : 0,
      "Age(%) 36-45": age_36_45Percent ? age_36_45Percent.toFixed(2) : 0,
      "Age(%) 45-Above": age_45AbvPercent ? age_45AbvPercent.toFixed(2) : 0,
    };
  });

  return constructedData;
}

// construct all cities medias
export function getAllCitiesMedias(cityWiseMedias = {}) {
  // all medias
  const allCitiesMedias = [];

  // adding all cities media into single array
  Object.keys(cityWiseMedias).map((cityId) => {
    const cityMediaList = cityWiseMedias[cityId];
    allCitiesMedias.push(...cityMediaList);
  });

  return allCitiesMedias;
}
