import { all, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// Api
import {
  downloadCampaignGraphPdfReportFn,
  getCampaignMediaDemographicStatsApi,
} from "../../apis/CampaignGraphReportAPI";

// Constants
import { CampaignGraphReport } from "../../constants/action-constants/CampaignGraphReportActionConstants";

// Utils
import { setErrorStatusCode } from "../../utils/ErrorUtils";
import {
  FilenameExtensions,
  ReportConstants,
} from "../../constants/GeneralConstants";
import { saveFile } from "../../common-utils/file-utils/FileUtils";
import { getErrorMessage } from "../../utils/util";

/**
 * Campaign Media Demographic stats
 */
export function* getCampaignMediaDemographicStats(action) {
  const { campaignId } = action.payload;
  try {
    const { cityWiseMedias } = yield getCampaignMediaDemographicStatsApi(
      campaignId
    );

    yield put({
      type: CampaignGraphReport.GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS_SUCCESS,
      payload: { cityWiseMedias },
    });
  } catch (error) {
    // stores the error and render the error image when the api fails
    setErrorStatusCode(error);

    yield put({
      type: CampaignGraphReport.GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS_FAILURE,
      payload: { error },
    });
  }
}

// Campaign Graph Pdf Report
export function* downloadCampaignGraphPdfReport(action) {
  try {
    const {
      campaignId,
      campaignTitle,
      selectedCityId,
      selectedMetricKey,
      mapCenter,
      mapZoom,
    } = action.payload;

    const data = yield downloadCampaignGraphPdfReportFn(
      campaignId,
      selectedCityId,
      selectedMetricKey,
      mapCenter,
      mapZoom
    );

    // file name
    const pdfFileName = campaignTitle
      ? `${campaignTitle}_GraphReport${FilenameExtensions.pdf}`
      : ReportConstants.CAMPAIGN_GRAPH_PDF_REPORT;

    // save file
    saveFile(pdfFileName, data);

    yield put({
      type: CampaignGraphReport.DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT_SUCCESS,
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: CampaignGraphReport.DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT_FAILURE,
      payload: err,
    });
    toast.error(errMsg);
  }
}

export default function* root() {
  yield all([
    takeLatest(
      CampaignGraphReport.GET_CAMPAIGN_MEDIA_DEMOGRAPHIC_STATS,
      getCampaignMediaDemographicStats
    ),
    takeLatest(
      CampaignGraphReport.DOWNLOAD_CAMPAIGN_GRAPH_PDF_REPORT,
      downloadCampaignGraphPdfReport
    ),
  ]);
}
