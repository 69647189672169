import { useSelector } from "react-redux";

// Constants and Utils
import { India, MapZoom } from "../../../constants/GeneralConstants";
import { CitySelectInputSection } from "../OrgUtils";

// Components
import LLMap from "../../../components/map/leaflet-map/LLMap";
import TargetGroupHeatmap from "../../../components/map/target-group-heatmap/TargetGroupHeatmap";

// Page Constants
const INDIA_MAP_CENTER = India.mapCenter;
const INDIA_MAP_ZOOM = India.mapZoomLevel5;
const MAP_ZOOM = MapZoom.zoomLevel11;

/**
 * Page Section
 */
function TargetGroupCreateMapView({ isReviewView }) {
  // Array of Selected PoiTypeLayerIds
  const resPoiLayers = useSelector(
    (state) => state.orgTargetGroup.resPoiLayersIds
  );

  // After selecting any city, below is the regionData
  const regionData = useSelector((state) => state.region.regionData);
  const bbox = regionData?.bbox;
  const latitude = regionData?.center?.latitude;
  const longitude = regionData?.center?.longitude;
  const regionCenter = [latitude, longitude];

  // all poiTypeLayer points combined array
  const allPoiPointsArr = useSelector((state) => state.geoData.allPoiPointsArr);

  // handling the "Center" and "Zoom" (if city selected or not)
  const center =
    Object.keys(regionData).length > 0 ? regionCenter : INDIA_MAP_CENTER;
  const zoom = Object.keys(regionData).length > 0 ? MAP_ZOOM : INDIA_MAP_ZOOM;

  return (
    <div className="map-layout-right">
      <div className="org-map-cont leaflet-container">
        {/* CitySelection */}
        {isReviewView && (
          <CitySelectInputSection bbox={bbox} resPoiLayers={resPoiLayers} />
        )}

        {/* Map View */}
        <LLMap center={center} zoom={zoom}>
          {/* HeatMap */}
          <TargetGroupHeatmap
            hmkey={`${isReviewView}`}
            allPoiPointsArr={allPoiPointsArr}
          />
        </LLMap>
      </div>
    </div>
  );
}

export default TargetGroupCreateMapView;
