import { all, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// Apis
import { downloadImage } from "../../utils/api";

// Constants and Utils
import { getErrorMessage } from "../../utils/util";
import { saveImageFile } from "../../common-utils/file-utils/FileUtils";
import { MapViewImage } from "../../constants/action-constants/MapViewImageActionConstants";
import {
  DownloadImagesNameConstants,
  ImageTypes,
} from "../../constants/GeneralConstants";

export function* downloadMapImage(action) {
  try {
    const { imageUrl } = action.payload;

    //const response = yield downloadImage("https://picsum.photos/200/300");
    //saveImageFile("mapImage.jpg", response, "jpg");
    const response = yield downloadImage(imageUrl);
    saveImageFile(
      DownloadImagesNameConstants.MAP_IMAGE,
      response,
      ImageTypes.JPEG
    );
    //dispatching action
    yield put({
      type: MapViewImage.DOWNLOAD_MAP_IMAGE_SUCCESS,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: MapViewImage.DOWNLOAD_MAP_IMAGE_FAILURE,
      payload: err,
    });
    toast.err(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(MapViewImage.DOWNLOAD_MAP_IMAGE, downloadMapImage)]);
}
