import { Seller } from "../../constants/action-constants/seller/SellerActionConstants";

//initial state
const initialState = {
  // Seller Inventory Summary
  // ------------------------------------------------------------------
  sellerInventory: {},
  sellerInventoryLoading: false,
  sellerInventoryError: "",

  // Seller Info By ID
  // ------------------------------------------------------------------
  sellerInfo: {},
  sellerInfoLoading: false,
  sellerInfoError: "",

  // Update Seller Info
  // ------------------------------------------------------------------
  updateSellerInfoLoading: false,
  updateSellerInfoError: "",

  // Update Seller LOGO
  // ------------------------------------------------------------------
  updateSellerLogoLoading: false,
  updateSellerLogoError: "",

  // Search Seller
  // ------------------------------------------------------------------
  sellerList: [],
  sellerListLoading: false,
  sellerListError: "",

  // Enable Seller Site
  // ------------------------------------------------------------------
  enableSellerSiteLoading: false,
  enableSellerSiteErr: "",

  // Disable Seller Site
  // ------------------------------------------------------------------
  disableSellerSiteLoading: false,
  disableSellerSiteErr: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Seller Inventory Summary
    // ------------------------------------------------------------------
    case Seller.GET_SELLER_INVENTORY_SUMMARY:
      return {
        ...state,
        sellerInventoryLoading: true,
      };
    case Seller.GET_SELLER_INVENTORY_SUMMARY_SUCCESS:
      return {
        ...state,
        sellerInventoryLoading: false,
        sellerInventory: action.sellerInventory,
      };
    case Seller.GET_SELLER_INVENTORY_SUMMARY_FAILURE:
      return {
        ...state,
        sellerInventoryLoading: false,
        sellerInventoryError: action.payload,
      };

    // Seller Info By Id
    // ------------------------------------------------------------------
    case Seller.GET_SELLER_INFO_BY_ID:
      return {
        ...state,
        sellerInfoLoading: true,
      };

    case Seller.GET_SELLER_INFO_BY_ID_SUCCESS:
      return {
        ...state,
        sellerInfoLoading: false,
        sellerInfo: action.sellerInfo,
      };

    case Seller.GET_SELLER_INFO_BY_ID_FAILURE:
      return {
        ...state,
        sellerInfoLoading: false,
        sellerInfoError: action.payload,
      };

    // Update Seller Info
    // ------------------------------------------------------------------
    case Seller.UPDATE_SELLER_INFO:
      return {
        ...state,
        updateSellerInfoLoading: true,
      };

    case Seller.UPDATE_SELLER_INFO_SUCCESS:
      return {
        ...state,
        updateSellerInfoLoading: false,
        sellerInfo: action.updatedSellerInfo,
      };

    case Seller.UPDATE_SELLER_INFO_FAILURE:
      return {
        ...state,
        updateSellerInfoLoading: false,
        updateSellerInfoError: action.payload,
      };

    // Update Seller LOGO
    // ------------------------------------------------------------------
    case Seller.UPDATE_SELLER_LOGO:
      return {
        ...state,
        updateSellerLogoLoading: true,
      };

    case Seller.UPDATE_SELLER_LOGO_SUCCESS:
      return {
        ...state,
        updateSellerLogoLoading: false,
        sellerInfo: {
          ...state.sellerInfo,
          logoURL: action.imageUrl,
        },
      };

    case Seller.UPDATE_SELLER_LOGO_FAILURE:
      return {
        ...state,
        updateSellerLogoLoading: false,
        updateSellerLogoError: action.payload,
      };

    // Search Seller
    // ------------------------------------------------------------------
    case Seller.SEARCH_SELLER:
      return {
        ...state,
        sellerListLoading: true,
      };

    case Seller.SEARCH_SELLER_SUCCESS:
      return {
        ...state,
        sellerListLoading: false,
        sellerList: action.sellerList,
      };

    case Seller.SEARCH_SELLER_FAILURE:
      return {
        ...state,
        sellerListLoading: false,
        sellerListError: action.payload,
      };

    // Enable Seller Site
    // ------------------------------------------------------------------
    case Seller.ENABLE_SELLER_SITE:
      return {
        ...state,
        enableSellerSiteLoading: true,
      };

    case Seller.ENABLE_SELLER_SITE_SUCCESS: {
      const { isPublished } = action.payload;
      return {
        ...state,
        enableSellerSiteLoading: false,
        sellerInfo: { ...state.sellerInfo, isPublished },
      };
    }
    case Seller.ENABLE_SELLER_SITE_FAILURE:
      return {
        ...state,
        enableSellerSiteLoading: false,
        enableSellerSiteErr: action.payload,
      };

    // Disable Seller Site
    // ------------------------------------------------------------------
    case Seller.DISABLE_SELLER_SITE:
      return {
        ...state,
        disableSellerSiteLoading: true,
      };

    case Seller.DISABLE_SELLER_SITE_SUCCESS: {
      const { isPublished } = action.payload;
      return {
        ...state,
        disableSellerSiteLoading: false,
        sellerInfo: { ...state.sellerInfo, isPublished },
      };
    }

    case Seller.DISABLE_SELLER_SITE_FAILURE:
      return {
        ...state,
        disableSellerSiteLoading: false,
        disableSellerSiteErr: action.payload,
      };

    // Clear Searched Seller
    // ------------------------------------------------------------------
    case Seller.CLEAR_SEARCHED_SELLER:
      return {
        ...state,
        sellerList: [],
      };
    default:
      return state;
  }
};
