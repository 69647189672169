import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";
import {
  getCampaignPerformance,
  getCampaignPerformanceMap,
} from "../../apis/CampaignPerformanceAPI";
import { ActionTypes } from "../../constants/ActionConstants";
import { getErrorMessage } from "../../utils/util";

export function* getCampPerformance(action) {
  const { campaignId, cityId } = action.payload;
  try {
    const campaignPerformance = yield getCampaignPerformance(
      campaignId,
      cityId
    );

    yield put({
      type: ActionTypes.CampaignPerformance.GET_CAMPAIGN_PERFORMANCE_SUCCESS,
      payload: { campaignPerformance },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignPerformance.GET_CAMPAIGN_PERFORMANCE_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* getCampPerformanceMap(action) {
  const { campaignIds } = action.payload;
  try {
    const campaignPerformanceMap = yield getCampaignPerformanceMap(campaignIds);

    yield put({
      type: ActionTypes.CampaignPerformance
        .GET_CAMPAIGN_PERFORMANCE_MAP_SUCCESS,
      payload: { campaignPerformanceMap },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignPerformance
        .GET_CAMPAIGN_PERFORMANCE_MAP_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.CampaignPerformance.GET_CAMPAIGN_PERFORMANCE,
      getCampPerformance
    ),

    takeLatest(
      ActionTypes.CampaignPerformance.GET_CAMPAIGN_PERFORMANCE_MAP,
      getCampPerformanceMap
    ),
  ]);
}
