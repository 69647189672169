import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

// Actions
import {
  getMapViewMediaSites,
  setCampaignMediaAppliedFilters,
} from "../../../actions/map-view/media-sites/MediaSitesActions";

// Constants & Utils
import {
  MediaInventorySwitch,
  SelectedFilter,
  SortMediaList,
} from "./MediaSitesUtils";
import { filterMedia, sortMediaByImpression } from "../../../utils/MediaUtils";

// Components
import Spinner from "../../../components/spinner/Spinner";
import MediaFilter from "../../../components/media-filter/MediaFilter";

// Page sections
import SelectedSitesTable from "./SelectedSitesTable";

// Section Components
export function SearchFilterAndSortMediaSection({
  mediaList = [],
  setSelectedMediaList = () => {},
  selectedViewLabels,

  // switch inventory specific
  inventoryToShow,
  setInventoryToShow = () => {},
}) {
  const dispatch = useDispatch();

  // State
  // sortingKey and searchQuery
  const [searchQuery, setSearchQuery] = useState("");
  const [sortingKey, setSortingKey] = useState("none");

  // Selector
  const campaignMediaFilterObj = useSelector(
    (state) => state.mapViewMediaSites.campaignMediaFilterObj
  );

  // if media removed from list, filter will be applied to updated media list
  useEffect(() => {
    doFilterAndSort(searchQuery, campaignMediaFilterObj, sortingKey);
  }, [mediaList.length]);

  // filter function
  function doFilterAndSort(searchedText, appliedFilters, sortKey) {
    // updating filter state
    dispatch(setCampaignMediaAppliedFilters(appliedFilters));

    // updating searched text
    setSearchQuery(searchedText);

    // updating sort key
    setSortingKey(sortKey);

    const filtersObj = { ...appliedFilters };

    // search will be executed when searchedText length is greater than 1
    if (searchedText.length > 1) {
      filtersObj.title = searchedText;
    }

    // filter media util
    const filteredMedia = filterMedia(mediaList, filtersObj);

    // sort media util
    const result = sortMediaByImpression(filteredMedia, sortKey);

    // updating state
    setSelectedMediaList(result);
  }

  const { search } = selectedViewLabels;

  return (
    <>
      <div className="d-flex align-items-center col-12 pl-0">
        {/* Search input */}
        <input
          type="search"
          className={`form-control shadow-none col`}
          placeholder={search.placeholder}
          onChange={({ target }) =>
            doFilterAndSort(target.value, campaignMediaFilterObj, sortingKey)
          }
        />

        {/* mantaray specific dropdown for switching inventory  */}
        {inventoryToShow && (
          <MediaInventorySwitch
            inventoryToShow={inventoryToShow}
            setInventoryToShow={setInventoryToShow}
            className="ml-2"
          />
        )}

        {/*Sort media dropdown */}
        <SortMediaList
          onSortingApplied={(sortKey) =>
            doFilterAndSort(searchQuery, campaignMediaFilterObj, sortKey)
          }
          className={" mx-2 px-3 col"}
        />

        {/* Media filter */}
        <MediaFilter
          className={"px-0 ml-3 col"}
          onFiltersApplied={(appliedFilters) =>
            doFilterAndSort(searchQuery, appliedFilters, sortingKey)
          }
          mediaFilterObj={campaignMediaFilterObj}
        />
      </div>

      {/* Shows selected media types */}
      <SelectedFilter filters={campaignMediaFilterObj} />
    </>
  );
}

// MediaSitesTab Section
function SelectedSitesSection({
  mediaList = [],
  selectedViewLabels,
  campaignId,
  cityId,
}) {
  //State
  const dispatch = useDispatch();

  // State
  const [selectedMediaList, setSelectedMediaList] = useState([]);

  //Selectors
  //media loader
  const loading = useSelector((state) => state.mapViewMediaSites.loading);

  //media pagination
  const pagination = useSelector((state) => state.mapViewMediaSites.pagination);

  //for pagination
  function loadCityMedia(pageNumber, pageSize) {
    dispatch(getMapViewMediaSites(campaignId, cityId, pageNumber, pageSize));
  }

  if (loading) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {/* Search, filter and sort */}
      <SearchFilterAndSortMediaSection
        mediaList={mediaList}
        selectedViewLabels={selectedViewLabels}
        setSelectedMediaList={setSelectedMediaList}
      />

      <hr className="divider my-2" />

      {/* Selected Sites Table*/}
      {selectedMediaList.length > 0 && (
        <SelectedSitesTable
          mediaList={selectedMediaList}
          pagination={pagination}
          loadCityMedia={loadCityMedia}
        />
      )}

      {selectedMediaList.length < 1 && (
        <p className="text-center mt-3 font-italic">No selected sites found</p>
      )}
    </>
  );
}

export default SelectedSitesSection;
