// Constants and Utils
import { LocalStorageKeys, UserRoles } from "../constants/GeneralConstants";
import { getItem } from "./localstorage";

/**
 * Function to check whether user is Seller or not
 * @returns true/false
 */
export function checkIsSellerUser() {
  const user = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  const roles = user.roles || [];

  // checking for ROLE_SELLER in "roles"
  const isSellerUser = roles.includes(UserRoles.ROLE_SELLER);

  return isSellerUser;
}

/**
 * Function to get SellerId from User(local storage)
 */
export function getSellerId() {
  const user = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  const seller = user.seller || {};

  return seller.id;
}
