import React from "react";

// Components
import { PlainDropdown } from "../dropdown/Dropdown";

export default function TargetGroupSelectionCard({
  TargetGroupData = {},
  tgList = [],
  campaignTgId,
  handleTgSelection,
}) {
  const { step, title, message } = TargetGroupData || {};
  return (
    <div className="card shadow mb-4 mt-3">
      <div className="d-flex justify-content-between">
        <div className="card-content">
          <p className="card__step">{step}</p>
          <p className="card__title">{title}</p>
          <p className="card__message">
            {message}
            <a className="text-primary"> Learn about templates</a>
          </p>
        </div>
        <div className="card-action">
          <PlainDropdown
            items={tgList}
            className="btn shadow-none rounded-lg border mt-3 btn-action"
            placeHolder="Select Target Group"
            onItemSelect={handleTgSelection}
            dropMenuClassName="dropdown-fixed-height"
            selectedId={campaignTgId} // existing tg for campaign
            disabled={campaignTgId} // disable for uploading in existing campaign
          />

          {/* if uploading excel to existing campaign then froze the tg selection */}
          {campaignTgId && (
            <p className="text-warning my-2 font-weight-bold text-center">
              TARGET GROUP FROZEN
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
