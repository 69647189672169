import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  clearForgotPasswordState,
  forgotPassword,
} from "../../actions/auth/AuthActions";

// Utils
import {
  AuthSubmitButton,
  FailureMessage,
  MessageWithRedirectionBtn,
} from "./AuthUtils";

// Css
import "./Auth.scss";

// Page Constants
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

// Page Components
function EmailInputSection({ emailErr, setEmail, setEmailErr }) {
  function handleEmail({ target }) {
    const { value } = target;

    if (value.trim().length === 0) {
      setEmailErr("Email is required");
      return;
    }

    if (!validEmailRegex.test(value)) {
      setEmailErr("Email is not valid!");
      return;
    }
    setEmailErr("");
    setEmail(target.value);
  }

  return (
    <div className="form-group mb-4">
      <label htmlFor={"email"}>{"Email id"}</label>
      <input
        className={`form-control shadow-none`}
        placeholder={"username@email.com"}
        id={"email"}
        type="text"
        onChange={handleEmail}
      />

      {/*Email Validation Err*/}
      {emailErr && <p className="text-danger pt-1">{emailErr}</p>}
    </div>
  );
}

function ForgotPasswordSuccessMessage() {
  return (
    <div className="bg-success px-5 py-4 rounded-lg font-weight-bold">
      <h5 className="text-center">
        A email with reset instructions has been sent to the given Email id.
        Please follow the instructions to set new Password
      </h5>
    </div>
  );
}

function ForgotPasswordCardSection({ emailErr, setEmail, setEmailErr }) {
  // Selector
  const loading = useSelector((state) => state.auth.forgotPasswordLoading);
  const isResetMailSent = useSelector((state) => state.auth.resetMailSent);
  const errorMessage = useSelector((state) => state.auth.forgotPasswordError);

  // conditionaly shows message after submitting form
  // Success
  if (isResetMailSent) {
    return (
      <MessageWithRedirectionBtn message={<ForgotPasswordSuccessMessage />} />
    );
  }

  // Failure
  if (errorMessage) {
    return <MessageWithRedirectionBtn message={<FailureMessage />} />;
  }

  return (
    <>
      {/* Title */}
      <h2 className="card-title text-center mb-3">Reset your Password</h2>

      <h6 className="text-center mb-5">
        Enter your user account's verified email address and we will send you a
        password reset link.
      </h6>

      {/* email Inputs */}
      <EmailInputSection
        emailErr={emailErr}
        setEmail={setEmail}
        setEmailErr={setEmailErr}
      />

      {/* Submit Button */}
      <AuthSubmitButton label={"Send password reset email"} loading={loading} />
    </>
  );
}

/**
 * Main Page
 */

export default function ForgotPasswordPage() {
  // State
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");

  // Dispatch
  const dispatch = useDispatch();

  // to clear the previous state
  useEffect(() => {
    dispatch(clearForgotPasswordState());
  }, [dispatch]);

  function handleSubmit(e) {
    e.preventDefault();

    // checks for error
    if (emailErr || !email) {
      return;
    }

    dispatch(forgotPassword(email));
  }

  return (
    <div className="content-wrapper h-100 bg-alt">
      <div className="page-content">
        <form className="card shadow-lg auth-page-card" onSubmit={handleSubmit}>
          <ForgotPasswordCardSection
            emailErr={emailErr}
            setEmail={setEmail}
            setEmailErr={setEmailErr}
          />
        </form>
      </div>
    </div>
  );
}
