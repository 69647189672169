import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { resetPassword } from "../../actions/auth/AuthActions";

// Utils
import {
  AuthSubmitButton,
  FailureMessage,
  MessageWithRedirectionBtn,
} from "./AuthUtils";

// Css
import "./Auth.scss";

// Page Components
function PasswordAndConfirmPasswordInput({
  passwordError,
  setNewPassword,
  setConfirmPassword,
}) {
  const inputInfo = [
    {
      label: "Enter password",
      id: "password",
      placeholder: "Password",
      className: "mb-3",
      setValueFunction: setNewPassword,
    },
    {
      label: "Re-enter password",
      id: "Re-enter-password",
      placeholder: "Confirm password",
      className: "mb-0",
      setValueFunction: setConfirmPassword,
    },
  ];

  return (
    <div className="mb-4">
      {inputInfo.map((info) => (
        <div className={`form-group ${info.className}`} key={info.id}>
          <label htmlFor={info.id}>{info.label}</label>
          <input
            className={`form-control shadow-none `}
            placeholder={info.placeholder}
            id={info.id}
            type="password"
            onChange={({ target }) => info.setValueFunction(target.value)}
          />
        </div>
      ))}
      {/* error message */}
      {passwordError && <p className="text-danger pt-1">{passwordError}</p>}
    </div>
  );
}

function PasswordResetSuccessMessage() {
  return (
    <div className="bg-success p-4 rounded-lg font-weight-bold">
      <h5 className="text-center mb-3">Successfully updated the password !!</h5>
      <h5 className="text-center ">
        you can now use the new password to Login to platform
      </h5>
    </div>
  );
}

function PasswordResetCardSection({
  passwordError,
  setNewPassword,
  setConfirmPassword,
}) {
  // Selector
  const isPasswordChanged = useSelector(
    (state) => state.auth.isPasswordChanged
  );
  const loading = useSelector((state) => state.auth.resetPasswordLoading);
  const resetPasswordError = useSelector(
    (state) => state.auth.resetPasswordError
  );

  // conditionaly shows message after submitting form
  // Success
  if (isPasswordChanged) {
    return (
      <MessageWithRedirectionBtn message={<PasswordResetSuccessMessage />} />
    );
  }

  // Failure
  if (resetPasswordError) {
    return <MessageWithRedirectionBtn message={<FailureMessage />} />;
  }

  return (
    <>
      <h2 className="card-title text-center mb-5">Setup New Password</h2>

      {/* password inputs */}
      <PasswordAndConfirmPasswordInput
        passwordError={passwordError}
        setNewPassword={setNewPassword}
        setConfirmPassword={setConfirmPassword}
      />

      {/* submit button */}
      <AuthSubmitButton label={"Set new Password"} loading={loading} />
    </>
  );
}

/**
 * Main Page
 */

export default function PasswordResetPage() {
  // State
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  // Dispatch
  const dispatch = useDispatch();

  const { token } = useParams();

  function submitForm(e) {
    e.preventDefault();

    // checks for password and confirm password
    if (!newPassword) {
      return setPasswordError("Password field is required");
    }

    if (newPassword.length < 8) {
      return setPasswordError("Password must be at least 8 characters long");
    }

    if (newPassword !== confirmPassword) {
      return setPasswordError("Password and confirm password does not match");
    }

    // clear error
    setPasswordError("");
    dispatch(resetPassword(newPassword, token));
  }

  return (
    <div className="content-wrapper h-100 bg-alt">
      <div className="page-content">
        <form className="card shadow-lg auth-page-card" onSubmit={submitForm}>
          <PasswordResetCardSection
            setNewPassword={setNewPassword}
            setConfirmPassword={setConfirmPassword}
            passwordError={passwordError}
          />
        </form>
      </div>
    </div>
  );
}
