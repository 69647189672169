export const getCampaignSummaryUrl = "/api/v3/campaign/:id/summary";

export const generateCampaignReportUrl =
  "/api/v3/campaign/:id/mavin?_f=true&_async=true";

export const downloadExcelReportUrl = "/api/v3/campaign/:id/export/xls";

export const downloadCampaignPdfReportUrl = "/api/v3/campaign/:id/export/pdf";

export const generateCampaignExcelReportUrl =
  "/api/v3/campaign/:id/generateExcelReport";
