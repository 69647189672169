export const ExploreSites = {
  GET_EXPLORE_SITES: "GET_EXPLORE_SITES",
  GET_EXPLORE_SITES_SUCCESS: "GET_EXPLORE_SITES_SUCCESS",
  GET_EXPLORE_SITES_FAILURE: "GET_EXPLORE_SITES_FAILURE",

  // Add explore site
  ADD_EXPLORE_SITE: "ADD_EXPLORE_SITE",
  ADD_EXPLORE_SITE_SUCCESS: "ADD_EXPLORE_SITE_SUCCESS",
  ADD_EXPLORE_SITE_FAILURE: "ADD_EXPLORE_SITE_FAILURE",

  EXPLORE_SITES_SELECT_MULTIPLE: "EXPLORE_SITES_SELECT_MULTIPLE",
  EXPLORE_SITES_UNSELECT_MULTIPLE: "EXPLORE_SITES_UNSELECT_MULTIPLE",

  // set InventoryMedia Fill Color
  SET_INVENTORY_MEDIA_FILL_COLOR: "SET_INVENTORY_MEDIA_FILL_COLOR",

  // Set FILTERS for InventoryMedia
  SET_INVENTORY_MEDIA_FILTERS: "SET_INVENTORY_MEDIA_FILTERS",

  // Reset
  RESET_EXPLORE_SITES: "RESET_EXPLORE_MEDIA_SITES",

  // Reset Explore Sites selections in map view
  RESET_EXPLORE_SITES_SELECTION: "RESET_EXPLORE_SITES_SELECTION",
};
