import "./Chips.scss";

function Chip({ item, customClassName, onDeselect }) {
  return (
    <div className="position-relative pr-2 mr-2 py-2 mt-1">
      <div
        className={`py-1 px-3 rounded-lg text-truncate  ${customClassName}`}
        title={item}
      >
        {item}
      </div>
      <span
        className="rounded-circle deselect"
        onClick={() => onDeselect(item)}
      >
        <i className="fas fa-times"></i>
      </span>
    </div>
  );
}

export default function Chips({
  selectedItems,
  title,
  customClassName = "bg-primary text-white",
  containerClassName = "",
  headerClassName = "",
  showHrTag = false,
  onDeselect = () => {},
}) {
  return (
    <>
      {title && <h5 className={`${headerClassName} bg-white`}>{title}</h5>}
      <div className={`d-flex ${containerClassName}`}>
        {selectedItems.map((item) => (
          <Chip
            key={item}
            item={item}
            customClassName={customClassName}
            onDeselect={onDeselect}
          />
        ))}
      </div>
      {showHrTag && <hr />}
    </>
  );
}
