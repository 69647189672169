import { useDispatch, useSelector } from "react-redux";

// Actions
import { openTargetGroupForm } from "../../actions/campaign-planning/TargetGroupFormActions";

// Constants and Utils
import {
  toLocaleString,
  formatText,
} from "../../../common-utils/string-utils/StringUtils";
import { cpmMultiplierFactor } from "../../utils/CpmUtil";
import { FormDataTargets } from "../../../constants/GeneralConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";

// Page Component
function TgSelectionCell({ tgSelectionClass }) {
  const selectedTgName = useSelector((state) => state.orgTargetGroup.tgName);

  //tg select loader
  const tgSelectLoading = useSelector(
    (state) => state.tgSpecificOts.segmentTgOtsLoading
  );

  const dispatch = useDispatch();
  function openTgFormFunction() {
    dispatch(openTargetGroupForm());
  }

  return (
    <td>
      <div className="d-flex">
        <button
          type="button"
          className={`btn shadow-none ${tgSelectionClass}`}
          data-toggle="modal"
          data-target={`#${FormDataTargets.targetGroupForm}`}
          onClick={openTgFormFunction}
        >
          {selectedTgName ? selectedTgName : "Select TG"}
        </button>
        {tgSelectLoading && <Spinner className="m-2 spinner-border-sm" />}
      </div>
    </td>
  );
}

function TgImpressionCell({ impressions, dayCount, elementStyle }) {
  return (
    <td className={elementStyle}>
      <span>{formatText(toLocaleString(impressions * dayCount))}</span>
    </td>
  );
}

function CpmInputCell({ setCpmFunction }) {
  return (
    <td>
      <div className="d-flex input-group-sm">
        <strong className="pt-1 px-1">X</strong>
        <input
          type="number"
          placeHolder="0"
          className="form-control"
          onChange={setCpmFunction}
        />
        <strong className="pt-1 px-1">=</strong>
      </div>
    </td>
  );
}

function CpmRsCell({ elementStyle, impressions, dayCount, cpm }) {
  return (
    <td className={elementStyle}>
      <span>
        {`Rs. ${cpm
          ? toLocaleString(cpmMultiplierFactor(impressions * dayCount * cpm))
          : 0
          }`}
      </span>
    </td>
  );
}

function ImpressionString({ elementStyle, string }) {
  return (
    <td className={elementStyle}>
      <span>{string}</span>
    </td>
  );
}

export {
  TgSelectionCell,
  TgImpressionCell,
  CpmInputCell,
  CpmRsCell,
  ImpressionString,
};
