import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Actions
import {
  downloadSellerInventoryTemplate,
  uploadSellerInventory,
} from "../../actions/seller/SellerMediaActions";

//Utils & Constants
import { selectFile } from "../../common-utils/file-utils/FileUtils";
import { ImageFileTypes } from "../../constants/GeneralConstants";

// Components
import DownloadTemplateCard from "../../components/site-upload-utils/DownloadTemplateCard";
import InventoryUploadTemplateCard from "../../components/site-upload-utils/InventoryUploadTemplateCard";
import {
  ButtonWithLoader,
  OutlineButtonWithLoader,
} from "../../mavin/components/button/Button";
import PageHeader from "../../mavin/components/page-header/PageHeader";

// Page-Constants
const sellerInventoryUpload = {
  DownloadTemplateData: {
    step: "STEP 1",
    title: "Download and fill template",
    message: "If you already have a filled inventory template jump to step 2.",
    buttonLabel: "Download Template",
  },
  UploadSiteListData: {
    step: "STEP 2",
    title: "Upload Inventory Images and Template",
    message:
      "Please upload using the template format recommended to avoid errors.",
    imageUploadButtonLabel: "Select Images",
    excelUploadButtonLabel: "Select template",
  },
};

// Page Components
function Footer({ onClick, isDisabled }) {
  const history = useHistory();

  // Selector
  const uploadInventoryLoading = useSelector(
    (state) => state.sellerMedia.uploadInventoryLoading
  );

  return (
    <div className="d-flex my-5 px-4 mr-3">
      <div className="ml-auto">
        <OutlineButtonWithLoader
          displayContent={"Cancel"}
          className={"mx-3 px-5"}
          onClickFunction={() => history.goBack()}
        />

        <ButtonWithLoader
          displayContent={"Upload Inventory"}
          onClickFunction={onClick}
          isDisabled={isDisabled || uploadInventoryLoading}
          loader={uploadInventoryLoading}
        />
      </div>
    </div>
  );
}

/**
 * Parent Component
 */
function SellerInventoryUpload() {
  const dispatch = useDispatch();
  const history = useHistory();

  // State
  const [mediaFile, setMediaFile] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);

  // Selector States
  const downloadTemplateLoading = useSelector(
    (state) => state.sellerMedia.downloadInventoryTempLoading
  );

  // this function handles file-uploads
  async function handleSelectFile() {
    const files = await selectFile(false, [".xlsx"]);
    setMediaFile(files[0]);
  }

  async function handleSelectImage() {
    const files = await selectFile(true, ImageFileTypes);
    setImageFiles(files);
  }

  // selected file count
  const selectedFileCount = mediaFile ? 1 : 0;
  const selectedImageCount = imageFiles.length;

  // Upload inventory function
  function handleUploadInventory() {
    const sellerInventoryBean = { mediaFile, imageFiles };
    dispatch(uploadSellerInventory(sellerInventoryBean, history));
  }

  const fileName = "seller_inventory_template.xlsx";

  return (
    <div className="content-wrapper bg-alt h-100 site-upload">
      {/* PageHeader */}
      <PageHeader title={"Upload Inventory sites via Excel"} shadow={false} />

      <div className="page-content">
        <DownloadTemplateCard
          isDisable={downloadTemplateLoading}
          onClick={() => dispatch(downloadSellerInventoryTemplate(fileName))}
          downloadTemplateData={sellerInventoryUpload.DownloadTemplateData}
        />

        {/* for excel-file template upload */}
        <InventoryUploadTemplateCard
          uploadSiteListData={sellerInventoryUpload.UploadSiteListData}
          selectedFilesCount={{ selectedImageCount, selectedFileCount }}
          uploadImageFunction={handleSelectImage}
          uploadTemplateFunction={handleSelectFile}
        />

        {/* Upload and cancel Button  */}
        <Footer onClick={handleUploadInventory} isDisabled={!mediaFile} />
      </div>
    </div>
  );
}

export default SellerInventoryUpload;
