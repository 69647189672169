export const ImpressionsBarData = [
  { dataKey: "Impressions" },
  { dataKey: "TG Impressions" },
];

export const ImpressionsBarDataMapping = [
  { valueKey: "otsTotal", label: "Impressions" },
  { valueKey: "tgOtsTotal", label: "TG Impressions" },
];

export const ReachBarData = [{ dataKey: "Reach" }, { dataKey: "TG Reach" }];

export const ReachBarDataMapping = [
  { valueKey: "reach", label: "Reach" },
  { valueKey: "tgReach", label: "TG Reach" },
];

export const GenderLinesData = [
  { dataKey: "Male", type: "monotone" },
  { dataKey: "Female", type: "monotone" },
];

export const GenderLinesDataMapping = [
  { valueKey: "male", label: "Male" },
  { valueKey: "female", label: "Female" },
];

export const AgeSplitBarData = [
  { dataKey: "18-25" },
  { dataKey: "26-35" },
  { dataKey: "36-45" },
  { dataKey: "45 above" },
];
