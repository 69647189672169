// Constants
import { Campaign } from "../../constants/action-constants/CampaignActionConstants";

export const createCampaign = (newCampaign, history) => ({
  type: Campaign.CREATE_CAMPAIGN,
  payload: { newCampaign, history },
});

export const getAllCampaigns = (searchText, pageNumber, pageSizeCount) => ({
  type: Campaign.GET_CAMPAIGNS,
  payload: { searchText, pageNumber, pageSizeCount },
});

export const updateCampaignTitle = (titleStr, campaignId, dispatchFrom) => ({
  type: Campaign.UPDATE_CAMPAIGN_TITLE,
  payload: { titleStr, campaignId, dispatchFrom },
});

export const updateCampaignDuration = (dateObj, cityInfo) => ({
  type: Campaign.UPDATE_CAMPAIGN_DURATION,
  payload: { dateObj, cityInfo },
});

export const getSharedCampaigns = () => ({
  type: Campaign.GET_SHARED_CAMPAIGN,
});

export const addCitiesToCampaign = (campaignId, selectedCities) => ({
  type: Campaign.ADD_CITIES_TO_EXISTING_CAMPAIGN,
  payload: { campaignId, selectedCities },
});

export const removeCityFromCampaign = (campaignId, cityId, dispatchFrom) => ({
  type: Campaign.REMOVE_CITY_FROM_CAMPAIGN,
  payload: { campaignId, cityId, dispatchFrom },
});
