import { useSelector } from "react-redux";

// Utils and Constants
import {
  toLocaleString,
  formatText,
} from "../../../common-utils/string-utils/StringUtils";
import { cpmMultiplierFactor } from "../../utils/CpmUtil";
import {
  getTotalEstImpressions,
  getTotalOfSegmentsTgOts,
  getTgSelectedImpressions,
} from "./ReportUtils";

// Components
import PerformanceCards from "../../components/performance-card/PerformanceCard";

// page Components
function OtsAndEarningCards({ allRoadSegmentsData, dayCountAndLit }) {
  const { dayCount, lit } = dayCountAndLit;

  // cpm "tg and generic"
  const genCPM = useSelector((state) => state.singleSiteReport.genCpm);
  const tgCPM = useSelector((state) => state.singleSiteReport.tgCpm);

  // total impressions
  const totalEstImpression = getTotalEstImpressions(lit, allRoadSegmentsData);

  // for tg-selected-impressions
  const allSegmentsTgOtsMap = useSelector(
    (state) => state.tgSpecificOts.allSegmentsTgOtsMap
  );

  const segmentsCombinedTgOts = getTotalOfSegmentsTgOts(allSegmentsTgOtsMap);
  const { genericOts, genericOtsLit, targetOts, targetOtsLit } =
    segmentsCombinedTgOts;

  // Specific and Generic Impressions
  const specificImpressions = getTgSelectedImpressions(
    targetOts,
    targetOtsLit,
    lit
  );
  const genericImpressions = getTgSelectedImpressions(
    genericOts,
    genericOtsLit,
    lit
  );

  // costing
  const tgSpecificCost = cpmMultiplierFactor(
    specificImpressions * dayCount * tgCPM
  );
  const tgGenericCost = cpmMultiplierFactor(
    genericImpressions * dayCount * genCPM
  );
  const totalCost = tgSpecificCost + tgGenericCost;

  const cardData = [
    {
      title: "Total Est impressions",
      value: `${formatText(toLocaleString(totalEstImpression * dayCount))}`,
    },
    {
      title: "Est Earnings",
      value: `Rs. ${totalCost ? toLocaleString(totalCost) : 0}`,
    },
  ];

  // Functions
  function cardWidth(index) {
    if (index === 0) {
      return "col-6";
    } else {
      return "col-6";
    }
  }

  return (
    <>
      <div className="d-flex m-3">
        {cardData.map((info, i) => (
          <PerformanceCards key={i} info={info} className={`${cardWidth(i)}`} />
        ))}
      </div>
      <hr className="divider m-0"></hr>
    </>
  );
}

export default OtsAndEarningCards;
