import { sendGetRequest } from "../utils/api/apiUtils";
import {
  getSellerSummaryByCityUrl,
  getSellerSummaryByMediaId,
} from "../urls/SellerAnalyticsURL";

export async function getSellerSummaryByCityFn(cityId, sellerId, dateRange) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  // Converting into TimeStamps
  const startTimeStamp = dateRange.startDate.getTime();
  const endTimeStamp = dateRange.endDate.getTime();

  const params = {
    ts_start: startTimeStamp,
    ts_end: endTimeStamp,
  };

  return sendGetRequest(
    getSellerSummaryByCityUrl
      .replace(":sellerId", sellerId)
      .replace(":cityId", cityId),
    params,
    true,
    headerParams
  );
}

export async function getSellerSummaryByMediaIdFn(
  mediaId,
  sellerId,
  dateRange
) {
  // Converting into TimeStamps
  const startTimeStamp = dateRange.startDate.getTime();
  const endTimeStamp = dateRange.endDate.getTime();

  const params = {
    ts_start: startTimeStamp,
    ts_end: endTimeStamp,
  };

  const url = getSellerSummaryByMediaId
    .replace(":sellerId", sellerId)
    .replace(":sellerMediaId", mediaId);
  return sendGetRequest(url, params);
}
