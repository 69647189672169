import { Boundaries } from "../../constants/action-constants/map-view/BoundariesActionConstants";
import { Status } from "../../constants/GeneralConstants";

//initial state
const initialState = {
  originalData: {},
  dataList: [],
  getBoundariesLoading: false,
  getBoundariesErr: "",
  selectedLabel: "",
  cityId: "",

  polygonData: {},

  // WardInfo
  wardInfoLading: false,
  wardInfoErr: "",

  // PinCodeInfo
  pincodeLoading: false,
  pincodeErr: "",

  // Boundary map Settings
  boundaryMapSettings: {
    maxColor: "#ff0000",
    noOfSteps: 5,
  },
};

function setDatalistSelected(dataList, label) {
  return dataList.map((data) => {
    if (data.label === label) {
      return {
        ...data,
        isSelected: !data.isSelected,
      };
    } else {
      return {
        ...data,
        isSelected: false,
      };
    }
  });
}

function setChildDataListStatus(
  dataList,
  parent,
  parentChecked,
  label,
  status
) {
  return dataList.map((data) => {
    if (data.label === label && parentChecked && data.parent === parent) {
      return {
        ...data,
        status,
      };
    } else {
      return { ...data, status: Status.UNCHECKED };
    }
  });
}

function setDatalistStatus(dataList, id, status) {
  const labels = id.split("_");
  const parentLabel = labels[0];
  const childLabel = labels[1];

  return dataList.map((data) => {
    if (data.label === parentLabel) {
      const children = setChildDataListStatus(
        data.children,
        data.label,
        true,
        childLabel,
        status
      );
      return {
        ...data,
        children,
        status,
      };
    }
    const children = setChildDataListStatus(
      data.children,
      data.type,
      false,
      childLabel,
      status
    );
    return { ...data, children, status: Status.UNCHECKED };
  });
}

function formatRawData(rawData, parentLabel) {
  if (!rawData) {
    return [];
  }
  return Object.keys(rawData).reduce((acc, curr) => {
    const type = rawData[curr].type;
    if (type) {
      delete rawData[curr].type;
    }
    const children = formatRawData(rawData[curr], curr);
    return [
      ...acc,
      {
        label: curr,
        isSelected: false,
        status: Status.UNCHECKED,
        children,
        type,
        id: parentLabel ? `${parentLabel}_${curr}` : curr,
        parent: parentLabel,
      },
    ];
  }, []);
}

function getStatusUpdatedDataList(dataList, id) {
  const labels = id.split("_");
  const parentLabel = labels[0];
  const childLabel = labels[1];

  return dataList.map((data) => {
    const children = data.children.map((child) => {
      if (child.label !== childLabel) {
        return child;
      }
      return {
        ...child,
        status: Status.UNCHECKED,
      };
    });

    if (data.label !== parentLabel) {
      return data;
    }
    return {
      ...data,
      children,
      status: Status.UNCHECKED,
    };
  });
}

const boundariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Boundaries.GET_BOUNDARIES:
      return {
        ...state,
        getBoundariesLoading: true,
        cityId: action.payload.cityId,
      };

    case Boundaries.GET_BOUNDARIES_SUCCESS:
      return {
        ...state,
        getBoundariesLoading: false,
        originalData: action.payload,
        dataList: formatRawData(action.payload),
      };

    case Boundaries.GET_BOUNDARIES_FAILURE:
      return {
        ...state,
        getBoundariesLoading: false,
        getBoundariesErr: action.payload,
      };
    case Boundaries.SET_RADIOLIST_SELECTED: {
      const { label } = action.payload;
      const dataList = setDatalistSelected(state.dataList, label);
      return {
        ...state,
        dataList,
      };
    }
    case Boundaries.SET_RADIO_STATUS: {
      const { id, status } = action.payload;
      const dataList = setDatalistStatus(state.dataList, id, status);
      return {
        ...state,
        dataList,
        selectedLabel: id,
      };
    }

    case Boundaries.GET_WARD_INFO: {
      return {
        ...state,
        wardInfoLading: true,
      };
    }

    case Boundaries.GET_WARD_INFO_SUCCESS: {
      return {
        ...state,
        polygonData: {
          ...state.polygonData,
          ward: action.payload,
        },
        wardInfoLading: false,
      };
    }

    case Boundaries.GET_WARD_INFO_FAILURE: {
      return {
        ...state,
        wardInfoErr: action.payload,
        wardInfoLading: false,
      };
    }

    case Boundaries.GET_PINCODE_INFO: {
      return {
        ...state,
        pincodeLoading: true,
      };
    }

    case Boundaries.GET_PINCODE_INFO_SUCCESS: {
      return {
        ...state,
        polygonData: {
          ...state.polygonData,
          pincode: action.payload,
        },
        pincodeLoading: false,
      };
    }

    case Boundaries.GET_PINCODE_INFO_FAILURE: {
      return {
        ...state,
        pincodeErr: action.payload,
        pincodeLoading: false,
      };
    }

    case Boundaries.UN_SELECT: {
      const { id } = action.payload;
      const dataList = getStatusUpdatedDataList(state.dataList, id);

      return {
        ...state,
        dataList: dataList,
        polygonData: {},
        selectedLabel: "",
      };
    }

    case Boundaries.SET_MAX_COLOR: {
      return {
        ...state,
        boundaryMapSettings: {
          ...state.boundaryMapSettings,
          maxColor: action.payload,
        },
      };
    }

    case Boundaries.SET_BOUNDARY_STEPS: {
      return {
        ...state,
        boundaryMapSettings: {
          ...state.boundaryMapSettings,
          noOfSteps: action.payload,
        },
      };
    }

    case Boundaries.RESET: {
      return initialState;
    }

    default:
      return state;
  }
};

export default boundariesReducer;
