import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import {
  selectPoiBrand,
  unSelectPoiBrands,
} from "../../../actions/map-view/poi/PoiSelectionActions";
import {
  getPoiHierarchy,
  setPoiStatus,
  setSelected,
} from "../../../actions/map-view/poi/PoiActions";

// Constants and Utils
import { getMergedCityId } from "../../../pages/map-view/CityInfoTempFixUtil";
import { Status } from "../../../constants/GeneralConstants";

// Components
import NestedList from "../../../components/nested-list/NestedList";
import Spinner from "../../../components/spinner/Spinner";

export default function PointsOfInterestTab({ cityId }) {
  const dispatch = useDispatch();

  // Selector - poi dataList
  const dataList = useSelector((state) => state.poi.dataList);

  // Selector State
  const getPoiLoading = useSelector((state) => state.poi.getPoiLoading);
  const dataListCityId = useSelector((state) => state.poi.cityId);

  const brandLabelToId = useSelector((state) => state.poi.brandLabelToId);
  const sectorLabelToId = useSelector((state) => state.poi.sectorLabelToId);
  const sectorToBrandMap = useSelector((state) => state.poi.sectorToBrandMap);
  const brandIdToLabel = useSelector((state) => state.poi.brandIdToLabel);
  const brandLoadingMap = useSelector((state) =>
    Object.keys(state.poiSelection.getBrandInfoLoading).reduce(
      (acc, eachBrandId) => {
        if (!state.poiSelection.getBrandInfoLoading[eachBrandId]) {
          return acc;
        }

        const brandLabel = brandIdToLabel[eachBrandId];
        acc[brandLabel] = true;
        return acc;
      },
      {}
    )
  );

  // to handle the "city-delhi-ncr" and "city-greater-mumbai" case
  const updatedCityId = getMergedCityId(cityId);

  // Dispatch
  useEffect(() => {
    if (dataList.length === 0 || dataListCityId !== updatedCityId) {
      dispatch(getPoiHierarchy(updatedCityId));
    }
  }, [dispatch]);

  // Functions
  function actionOnAddButtonSelect(selectedIndex, label) {
    dispatch(setSelected(selectedIndex, label));
  }

  function actionOnAddButtonStatus(selectedIndex, label, status) {
    const brandId = brandLabelToId[label];
    if (brandId) {
      status === Status.CHECKED
        ? dispatch(selectPoiBrand(updatedCityId, brandId))
        : dispatch(unSelectPoiBrands([brandId]));
      return;
    }
    const sectorId = sectorLabelToId[label];
    const brandIds = sectorToBrandMap[sectorId];
    status === Status.CHECKED
      ? brandIds.forEach((brandId) =>
          dispatch(selectPoiBrand(updatedCityId, brandId))
        )
      : dispatch(unSelectPoiBrands(brandIds));
  }

  function actionOnAddButton(label, status) {
    dispatch(setPoiStatus(label, status));
  }
  // Loading
  if (getPoiLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <h2 className="mb-3">Points Of Interest</h2>
      <hr className="my-3"></hr>
      <NestedList
        data={dataList}
        brandLoadingMap={brandLoadingMap}
        setSelected={actionOnAddButtonSelect}
        setAdded={actionOnAddButton}
        setAddButtonStatus={actionOnAddButtonStatus}
        selectAllAttr={true}
        shouldShowCheckboxes={true}
        onCheckboxChange={actionOnAddButtonStatus}
      />
    </>
  );
}
