/* eslint-disable require-yield */
import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Apis
import { closestRoadSegmentsData } from "../../../apis/RoadSegmentAPI";

// Constants and Utils
import { ActionTypes } from "../../../constants/ActionConstants";
import { getErrorMessage } from "../../../utils/util";

// for getting closest-road-segments
export function* getClosestRoadSegments(action) {
  const { locationStr } = action.payload;
  try {
    const roadSegments = yield closestRoadSegmentsData(locationStr);

    yield put({
      type: ActionTypes.PoiForm.GET_CLOSEST_ROAD_SG_SUCCESS,
      payload: { roadSegments: roadSegments },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.PoiForm.GET_CLOSEST_ROAD_SG_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.PoiForm.GET_CLOSEST_ROAD_SG, getClosestRoadSegments),
  ]);
}
