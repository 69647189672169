// URLS
import {
  getPoiNames,
  getPoisOnMap,
  getV2PoiHirarchyUrl,
  getPoiHirarchyUrl,
  getV2BrandInfo,
  getV2SectorInfo,
} from "../urls/PoiURL";

// Constants and Utils
import bang from "../utils/bang.json";
import { sendGetRequest } from "../utils/api/apiUtils";

export async function getPoiBrandData(cityId, brandId) {
  const useV2Data = process.env.REACT_APP_USE_V2_MAP_VIEW;
  if (useV2Data) {
    const v2BrandInfoUrl = getV2BrandInfo
      .replace(":cityId", cityId)
      .replace(":brandId", brandId);

    const brandInfo = await sendGetRequest(v2BrandInfoUrl);
    return brandInfo.result;
  }
  return bang.result;
}

export async function getPoiSectorData(cityId, sectorId) {
  const useV2Data = process.env.REACT_APP_USE_V2_MAP_VIEW;
  if (useV2Data) {
    const v2SectorInfoUrl = getV2SectorInfo
      .replace(":cityId", cityId)
      .replace(":sectorId", sectorId);

    const sectorInfo = await sendGetRequest(v2SectorInfoUrl);
    return sectorInfo.result;
  }
  return bang.result;
}

function restructureV2Data(v2Data) {
  const v3Data = {};
  const poiTypeTrees = [];
  const brandsByPoiType = {};
  Object.entries(v2Data.result).forEach(([sector, brands]) => {
    poiTypeTrees.push({ id: sector, name: sector, children: [] });
    const sectorChildren = brands.map((eachBrand) => ({
      id: eachBrand,
      name: eachBrand,
    }));
    brandsByPoiType[sector] = sectorChildren;
  });
  v3Data.brandsByPoiType = brandsByPoiType;
  v3Data.poiTypeTrees = poiTypeTrees;
  return v3Data;
}

export async function getPoiHierarchy(cityId) {
  const useV2Data = process.env.REACT_APP_USE_V2_MAP_VIEW;
  if (useV2Data) {
    const params = {
      city: cityId,
    };
    const response = await sendGetRequest(getV2PoiHirarchyUrl, params);
    return restructureV2Data(response);
  }

  return sendGetRequest(getPoiHirarchyUrl);
}

export async function getPoiData(inputText, pn, ps) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    pn: pn,
    ps: ps,
    _kw: inputText,
  };

  const url = getPoiNames;
  return sendGetRequest(url, params, true, headerParams);
}

export async function getPoisOnMapView(poiType, cityId) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  return sendGetRequest(
    getPoisOnMap.replace(":cityId", cityId).replace(":poiType", poiType),
    {},
    true,
    headerParams
  );
}
