import { Seller } from "../../constants/action-constants/seller/SellerActionConstants";

//intital state
const initialState = {
  // Seller site earnings page
  sellerMediaSummary: {},
  sellerMediaSummaryLoading: false,
  sellerMediaSummaryErrro: "",

  // Seller earnings page
  sellerSummaryLoading: false,
  sellerSummaryError: "",
  sellerSummary: {},
  dateRange: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Seller earnings
    case Seller.GET_SELLER_SUMMARY_BY_CITY:
      return {
        ...state,
        sellerSummaryLoading: true,
      };
    case Seller.GET_SELLER_SUMMARY_BY_CITY_SUCCESS: {
      return {
        ...state,
        sellerSummaryLoading: false,
        sellerSummary: action.payload.sellerSummary,
        dateRange: action.payload.dateRange,
      };
    }

    case Seller.GET_SELLER_SUMMARY_BY_CITY_FAILURE:
      return {
        ...state,
        sellerSummaryLoading: false,
        sellerSummaryError: action.payload,
      };

    // Seller media site earnings
    case Seller.GET_SELLER_SUMMARY_BY_MEDIA_ID:
      return {
        ...state,
        sellerMediaSummaryLoading: true,
      };

    case Seller.GET_SELLER_SUMMARY_BY_MEDIA_ID_SUCCESS: {
      return {
        ...state,
        sellerMediaSummaryLoading: false,
        sellerMediaSummary: action.payload.sellerMediaSummary,
      };
    }

    case Seller.GET_SELLER_SUMMARY_BY_MEDIA_ID_FAILURE: {
      return {
        ...state,
        sellerMediaSummaryLoading: false,
        sellerMediaSummaryError: action.payload,
      };
    }
    default:
      return state;
  }
};
