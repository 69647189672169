import React from "react";

function FormInput(props) {
  // action="javascript:void(-1)" :: to remove the reloading of page
  // on pressing "Enter button" for "FormInput"
  return (
    <form className="form-inline" action="javascript:void(-1)">
      <div className="form-group">
        <label id={props.content}>{props.displayName}</label>
        {props.children}
      </div>
    </form>
  );
}

function Input(props) {
  function handleChange(event) {
    props.onChange(props.id, event.target.value);
  }

  const className = props.inputBoxStyle ? props.inputBoxStyle : "form-control";

  return (
    <input
      type="text"
      className={className}
      placeholder={props.placeHolder}
      value={props.value}
      onChange={handleChange}
      disabled={props.disabled}
    />
  );
}

export { Input, FormInput };
