//Constants
import {
  updateMediaPriceUrl,
  getMediaPriceByIdsUrl,
} from "../urls/OrgMediaPriceURL";

// Utils
import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

export async function updateMediaPriceFn(mediaPriceBean) {
  return sendCreateOrUpdateRequest(
    updateMediaPriceUrl,
    mediaPriceBean,
    true,
    "PUT"
  );
}

export async function getMediaPriceByIdsFn(mediaIds) {
  const url = getMediaPriceByIdsUrl.replace(":ids", mediaIds);
  return sendGetRequest(url);
}
