import { useEffect } from "react";

// Constants
import {
  constructCitiesListForDropdown,
  constructMediaSiteWiseAgeSplit,
  constructMediaSiteWiseGenderRatio,
} from "../GraphReportsUtils";
import { MediaSiteWiseAgeSplitLinesData } from "../GraphReportsConstants";

// Components
import { PlainDropdown } from "../../../components/dropdown/Dropdown";
import Spinner from "../../../components/spinner/Spinner";
import { BarWithLineChart, LineChart } from "../../../components/charts/Charts";

// Header & City Selector
function HeaderAndCitySelector({
  citiesList = [],
  selectedCityId,
  setSelectedCityId = () => {},
  isLoading = false,
}) {
  return (
    <div className="d-flex align-items-center flex-wrap">
      <h4 className="font-weight-bold pr-2 mb-0">
        City Wise Media Gender Ratio And Age Split
      </h4>
      <div className="d-flex align-items-center">
        <PlainDropdown
          items={citiesList}
          selectedCityId={selectedCityId}
          onItemSelect={(id) => setSelectedCityId(id)}
          className="h5 border rounded-lg mb-0 px-2 py-1"
          textClassName="text-primary"
          dropMenuClassName="max-h-400 overflow-auto"
        />
        {isLoading && <Spinner className="spinner-border-sm mx-2" />}
      </div>
    </div>
  );
}

/**
 * Campaign Media Gender Ratio And Age Split Section
 */
function CampaignMediaGenderRatioAgeSplitSection({
  campaignCityWiseMedias = {},
  selectedCityId,
  setSelectedCityId = () => {},
}) {
  // default / first city id
  const campaignFirstCityId = Object.keys(campaignCityWiseMedias)[0];

  // update first city as selected city
  useEffect(() => {
    setSelectedCityId(campaignFirstCityId);
  }, []);

  // cities list
  const citiesList = constructCitiesListForDropdown(campaignCityWiseMedias);

  // media Site Wise Gender Ratio
  const mediaSiteWiseGenderRatioData = constructMediaSiteWiseGenderRatio(
    campaignCityWiseMedias,
    selectedCityId
  );

  // media Site Wise Age Split
  const mediaSiteWiseAgeSplitData = constructMediaSiteWiseAgeSplit(
    campaignCityWiseMedias,
    selectedCityId
  );

  return (
    <div className="py-4 border-bottom">
      <HeaderAndCitySelector
        citiesList={citiesList}
        selectedCityId={selectedCityId}
        setSelectedCityId={setSelectedCityId}
      />

      <div className="row pt-4">
        {/* Gender ratio section */}
        <div className="col-6 text-center px-5">
          <h5 className="font-weight-bold mb-3">
            Media Site Wise Gender Ratio
          </h5>

          {/* chart */}
          <BarWithLineChart
            data={mediaSiteWiseGenderRatioData}
            barLabel="Male"
            lineLabel="Female"
            yAxisLabel="Percentage (%)"
            showCustomXAxisTick={true}
          />
        </div>

        {/* Age split section */}
        <div className="col-6 border-left text-center px-5">
          <h5 className="font-weight-bold mb-3">Media Site Wise Age Split</h5>

          {/* chart */}
          <LineChart
            data={mediaSiteWiseAgeSplitData}
            linesData={MediaSiteWiseAgeSplitLinesData}
            yAxisLabel="Percentage (%)"
          />
        </div>
      </div>
    </div>
  );
}

export default CampaignMediaGenderRatioAgeSplitSection;
