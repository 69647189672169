export const getCitySummaryUrl = "/api/v3/campaign/:campaignId/summary/:cityId";

export const approxOtsForCityUrl =
  "/api/v3/campaign/:campaignId/mavin/ots_approx?cityId=:cityId&_async=true";

export const downloadCityPdfReportUrl =
  "/api/v3/campaign/:campaignId/city/:cityId/export/pdf";

export const downloadCityMediasPdfReportsUrl =
  "/api/v3/campaign/:campaignId/city/:cityId/media/export/pdf";
