import { toast } from "react-toastify";
import { all, put, takeEvery } from "redux-saga/effects";

//API
import { getPoiBrandData } from "../../../apis/PoiAPI";

// Constants and Utils
import { getErrorMessage } from "../../../utils/util";
import { Poi } from "../../../constants/action-constants/map-view/poi/PoiActionConstants";

function* getPoiBrandInfo(action) {
  try {
    const { brandId, cityId } = action.payload;
    const brandInfo = yield getPoiBrandData(cityId, brandId);
    yield put({
      type: Poi.GET_BRAND_INFO_SUCCESS,
      payload: { brandId, brandInfo, cityId },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: Poi.GET_BRAND_INFO_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeEvery(Poi.GET_BRAND_INFO, getPoiBrandInfo)]);
}
