// Metric Keys
export const MetricKeys = {
  impressions: "impressions",
  tgImpressions: "tgImpressions",
  reach: "reach",
  tgReach: "tgReach",
  frequency: "frequency",
};

// metrics options for dropdown Items
export const MetricsOptionsDropdownItems = [
  { id: MetricKeys.impressions, label: "Impressions" },
  { id: MetricKeys.tgImpressions, label: "TG-Impressions" },
  { id: MetricKeys.reach, label: "Reach" },
  { id: MetricKeys.tgReach, label: "TG-Reach" },
  { id: MetricKeys.frequency, label: "Frequency" },
];

// media Site Wise Age Split Lines Data
export const MediaSiteWiseAgeSplitLinesData = [
  {
    type: "linear",
    dataKey: "Age(%) 18-25",
  },
  {
    type: "linear",
    dataKey: "Age(%) 26-35",
  },
  {
    type: "linear",
    dataKey: "Age(%) 36-45",
  },
  {
    type: "linear",
    dataKey: "Age(%) 45-Above",
  },
];
