// Action Constants
import { OrgMedia } from "../../constants/action-constants/org/OrgMediaActionConstants";

// get Org Media Info
export const getOrgMediaInfo = (mediaId, orgId) => ({
  type: OrgMedia.GET_ORG_MEDIA_INFO,
  payload: { mediaId, orgId },
});

// add Org Media Images
export const addOrgMediaImages = (orgId, mediaId, files) => ({
  type: OrgMedia.ADD_ORG_MEDIA_IMAGES_SUCCESS,
  payload: { orgId, mediaId, files },
});
