import { Marker, Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import MapIcon from "../map-icon/MapIcon";

function MediaMarkers({ media }) {
  const mediaSelected = useSelector(
    (state) => state.planningMedia.selectedMedias[media.mediaId]
  );

  const marker = {
    position: [media.latitude, media.longitude],
    id: media.mediaId,
    markerText: [
      `Impressions: ${
        media.estOts.otsLitGeneric
          ? media.estOts.otsLitGeneric
          : media.estOts.otsGeneric
      }`,
      `Site Title: ${media.title}`,
      `Dimensions: ${media.width}ft x ${media.height}ft`,
    ],
  };

  const selectedType = new MapIcon({ type: "selected-media" });
  const unSelectedType = new MapIcon({ type: "un-selected-media" });
  const icon = mediaSelected ? selectedType : unSelectedType;

  return (
    <Marker position={marker.position} icon={icon}>
      <Popup>
        {marker.markerText.map((string, i) => (
          <b className="d-block" key={i}>
            {string}
          </b>
        ))}
      </Popup>
    </Marker>
  );
}

export default MediaMarkers;
