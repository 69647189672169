import { ActionTypes } from "../../constants/ActionConstants";
import moment from "moment";
import { getDiffInDaysOfMomentObject } from "../../../src/common-utils/date-utils/DateUtils";

//initial state
const initialState = {
  // Campaign details (Note : we are storing the dates in the campaign object)
  campaign: "",
  campaignErr: "",
  campaignLoading: false,

  // Update campaign state
  updateStateLoading: false,
  updateStateErr: "",
  updateStateSuccess: false,
};

function changeCampaignDates(startDate, endDate, campaign) {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const days = getDiffInDaysOfMomentObject(startDateMoment, endDateMoment);
  return {
    ...campaign,
    startDate: startDateMoment,
    endDate: endDateMoment,
    days: days,
    state: "Confirmed",
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CampaignDetails.GET_CAMPAIGN_BASIC_INFO:
      return {
        ...state,
        campaignLoading: true,
      };

    case ActionTypes.CampaignDetails.GET_CAMPAIGN_BASIC_INFO_SUCCESS: {
      const campaign = action.payload.campaignDetails;
      return {
        ...state,
        campaignLoading: false,
        campaign: changeCampaignDates(
          campaign.startTimestamp,
          campaign.endTimestamp,
          campaign
        ),
      };
    }

    case ActionTypes.CampaignDetails.GET_CAMPAIGN_BASIC_INFO_FAILURE:
      return {
        ...state,
        campaignLoading: false,
        campaignErr: action.payload,
      };

    case ActionTypes.CampaignDetails.UPDATE_CAMPAIGN_STATE:
      return {
        ...state,
        updateStateLoading: true,
      };

    case ActionTypes.CampaignDetails.UPDATE_CAMPAIGN_STATE_SUCCESS:
      return {
        ...state,
        updateStateLoading: false,
        updateStateSuccess: true,
      };

    case ActionTypes.CampaignDetails.UPDATE_CAMPAIGN_STATE_FAILURE:
      return {
        ...state,
        updateStateLoading: false,
        updateStateErr: action.payload,
      };

    case ActionTypes.CampaignDetails.UPDATE_CAMPAIGN_PLAN_DATES:
      return {
        ...state,
        campaign: changeCampaignDates(
          action.payload.startDate,
          action.payload.endDate,
          state.campaign
        ),
      };

    case ActionTypes.CampaignDetails.RESET_CAMPAIGN_DETAILS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
