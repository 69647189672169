export const uploadSiteImagesUrl =
  "/api/v3/campaign/:campaignId/media/:mediaId/files?fileType=IMAGE";

export const createMediaUrl = "/api/v3/campaign/:campaignId/media";

export const generateCampaignMediaMetricsUrl =
  "/api/v3/campaign/:campaignId/media/city/:cityId/mavin";

export const getCampaignMediaByIdUrl = "/api/v3/campaign/:id/media/:mediaId";

export const getRoadTrafficUrl = "/api/v1/route/:routeId?geoJson=false";

export const generateMediaIndicesUrl =
  "/api/v3/campaign/:id/media/:mediaId/mavin?_f=true";

export const generateAppMediaIndicesUrl =
  "/api/v3/campaign/:id/media/:mediaId/mavin/ots_approx";

export const updateCampaignMediaByIdUrl = "/api/v3/campaign/:id/media/:mediaId";

// Map Seller media to campaign
export const mapSellerMediaToCampaignUrl =
  "/api/v3/campaign/:campaignId/media/map";

export const getCampaignMediaByCityUrl =
  "/api/v3/campaign/:id/media/search/city?city=:cityId";

export const deleteCampaignMediaUrl =
  "/api/v3/campaign/:campaignId/media/:campaignMediaId";

export const getCampaignMediaUrl = "/api/v3/campaign/:campaignId/media";

export const downloadMediaPdfReportUrl =
  "/api/v3/campaign/:campaignId/media/:mediaId/export/pdf";
