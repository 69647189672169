export const getPoiNames = "/api/v3/defs/poi_type/search";

//for displaying pois on map view
export const getPoisOnMap =
  "/api/v1/poi/region/:cityId/type/:poiType?geoJson=false";

// Poi HirarchyUrls..
export const getPoiHirarchyUrl = "/api/v3/defs/brand/tree/poi_type";
export const getV2PoiHirarchyUrl = "/api/v1/poi/names";

export const getV2BrandInfo =
  "/api/v1/poi/region/:cityId/name/:brandId?geoJson=false";
export const getV2SectorInfo =
  "/api/v1/poi/region/:cityId/type/:sectorId?geoJson=false";
