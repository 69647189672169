import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Actions
import { updateSellerInfo } from "../../../actions/seller/SellerActions";

// Components
import {
  ButtonWithLoader,
  OutlineButtonWithLoader,
} from "../../../mavin/components/button/Button";

// Form Components
function CompanyNameAndWebsite({
  companyName,
  setCompanyName,
  companyWebsite,
  setCompanyWebsite,
}) {
  const nameAndWebsiteConstants = [
    {
      className: "mr-2",
      placeholder: "Company Name",
      value: companyName,
      setValueFunction: setCompanyName,
    },
    {
      className: "ml-2",
      placeholder: "Website (www.website.com)",
      value: companyWebsite,
      setValueFunction: setCompanyWebsite,
    },
  ];

  return (
    <div className="d-flex mb-3">
      {nameAndWebsiteConstants.map((item, i) => (
        <input
          key={i}
          className={`form-control col rounded-lg ${item.className}`}
          placeholder={item.placeholder}
          value={item.value}
          onChange={(e) => item.setValueFunction(e.target.value)}
        />
      ))}
    </div>
  );
}

function DescriptionInput({ companyDescription, setCompanyDescription }) {
  return (
    <input
      className="form-control col rounded-lg mb-3"
      placeholder="Description about the Company"
      value={companyDescription}
      onChange={(e) => setCompanyDescription(e.target.value)}
    />
  );
}

function LocalAddressInput({ companyAddress, setCompanyAddress }) {
  return (
    <input
      className="form-control col rounded-lg mb-3"
      placeholder="Address of the Company"
      value={companyAddress}
      onChange={(e) => setCompanyAddress(e.target.value)}
    />
  );
}

function PostalAddressInput({ postalAddressBean }) {
  const {
    companyCity,
    setCompanyCity,
    companyState,
    setCompanyState,
    companyZipcode,
    setCompanyZipcode,
  } = postalAddressBean;

  const postalAddressConstants = [
    {
      className: "mr-2",
      placeholder: "City Name",
      value: companyCity,
      setValueFunction: setCompanyCity,
    },
    {
      className: "mx-2",
      placeholder: "State",
      value: companyState,
      setValueFunction: setCompanyState,
    },
    {
      className: "ml-2",
      placeholder: "Pincode",
      value: companyZipcode,
      setValueFunction: setCompanyZipcode,
    },
  ];

  return (
    <div className="d-flex mb-3">
      {postalAddressConstants.map((item, i) => (
        <input
          key={i}
          className={`form-control col rounded-lg ${item.className}`}
          placeholder={item.placeholder}
          value={item.value}
          onChange={(e) => item.setValueFunction(e.target.value)}
        />
      ))}
    </div>
  );
}

function ContactAndEmail({
  companyContactNo,
  setCompanyContactNo,
  companyEmail,
  setCompanyEmail,
}) {
  const contactAndEmailConstants = [
    {
      className: "mr-2",
      placeholder: "Contact No.",
      value: companyContactNo,
      setValueFunction: setCompanyContactNo,
    },
    {
      className: "ml-2",
      placeholder: "Email Id",
      value: companyEmail,
      setValueFunction: setCompanyEmail,
    },
  ];
  return (
    <div className="d-flex mb-3">
      {contactAndEmailConstants.map((item, i) => (
        <input
          key={i}
          className={`form-control col rounded-lg ${item.className}`}
          placeholder={item.placeholder}
          value={item.value}
          onChange={(e) => item.setValueFunction(e.target.value)}
        />
      ))}
    </div>
  );
}

// FORM
export default function SellerProfileEditForm({ sellerInfo = {}, setIsEdit }) {
  const dispatch = useDispatch();

  const { name, description, website, mainAddress = {}, email } = sellerInfo;
  const { line1, city, state, zipcode, contactNo } = mainAddress;

  // State
  const [companyName, setCompanyName] = useState(name || "");
  const [companyWebsite, setCompanyWebsite] = useState(website || "");
  const [companyDescription, setCompanyDescription] = useState(
    description || ""
  );

  // Address
  const [companyAddress, setCompanyAddress] = useState(line1 || "");
  const [companyCity, setCompanyCity] = useState(city || "");
  const [companyState, setCompanyState] = useState(state || "");
  const [companyZipcode, setCompanyZipcode] = useState(zipcode || "");
  const [companyContactNo, setCompanyContactNo] = useState(contactNo || "");
  const [companyEmail, setCompanyEmail] = useState(email || "");

  const postalAddressBean = {
    companyCity,
    setCompanyCity,
    companyState,
    setCompanyState,
    companyZipcode,
    setCompanyZipcode,
  };

  const sellerInfoBean = {
    name: companyName,
    description: companyDescription,
    website: companyWebsite,
    line1: companyAddress,
    city: companyCity,
    state: companyState,
    zipcode: companyZipcode,
    contactNo: companyContactNo,
    email: companyEmail,
  };

  //  if user not gives all the inputs
  const isAnyInputNotFilled = Object.keys(sellerInfoBean).some(
    (key) => !sellerInfoBean[key]
  );

  function updateSellerInfoFn() {
    if (isAnyInputNotFilled) {
      toast.error("Please fill all the fields.");
      return;
    }

    dispatch(updateSellerInfo(sellerInfoBean));
    setIsEdit(false);
  }

  return (
    <div className="py-3">
      <CompanyNameAndWebsite
        companyName={companyName}
        setCompanyName={setCompanyName}
        companyWebsite={companyWebsite}
        setCompanyWebsite={setCompanyWebsite}
      />

      <DescriptionInput
        companyDescription={companyDescription}
        setCompanyDescription={setCompanyDescription}
      />

      <LocalAddressInput
        companyAddress={companyAddress}
        setCompanyAddress={setCompanyAddress}
      />

      <PostalAddressInput postalAddressBean={postalAddressBean} />

      <ContactAndEmail
        companyContactNo={companyContactNo}
        setCompanyContactNo={setCompanyContactNo}
        companyEmail={companyEmail}
        setCompanyEmail={setCompanyEmail}
      />

      {/* Save and Cancel button */}
      <div className="d-flex justify-content-end">
        <OutlineButtonWithLoader
          onClickFunction={() => setIsEdit(false)}
          displayContent={"Cancel"}
        />
        <ButtonWithLoader
          displayContent={"Save"}
          className="ml-3 px-4"
          onClickFunction={updateSellerInfoFn}
        />
      </div>
    </div>
  );
}
