import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

//Actions
import { getCampaignMediaByCity } from "../../../actions/campaign-media/CampaignMediaActions";
import {
  getCityOverview,
  downloadCityPdfReport,
  downloadCityMediasPdfReports,
} from "../../../actions/campaign-report/CampaignCityReportActions";
import { cancelAppliedFilters } from "../../../actions/filter/FilterActions";

//Utils and Constants
import { MapZoom, PageSize } from "../../../constants/GeneralConstants";

//Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import Spinner from "../../../components/spinner/Spinner";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import CampaignMediaForm from "../../../components/campaign-media-form/CampaignMediaForm";
import { CampaignCityPerformance } from "../../../mavin/components/campaign-city-performance/CampaignCityPerformance";
import { Dropdown } from "../../../components/dropdown/Dropdown";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Pages and Section
import CampaignCityMapView from "./CampaignCityMapView";
import CityViewSiteOptions from "./CityViewSiteOptions";
import CampaignCityViewMediaTable from "./CampaignCityViewMediaTable";

//Css
import "./CampaignCityViewPage.scss";

// Page Components
function DownloadReport({
  campaignId,
  cityId,
  campaignTitle,
  cityName,
  mediaCount,
  mapCenter,
  mapZoom,
}) {
  const dispatch = useDispatch();

  // Selector
  const downloadingReport = useSelector(
    (state) => state.campaignCityReport.downloadCityPdfReportLoading
  );

  const downloadCityMediasPdfReportsLoading = useSelector(
    (state) => state.campaignCityReport.downloadCityMediasPdfReportsLoading
  );

  const isMediaSitesPresent = mediaCount > 0;

  const reportActionsArray = [
    {
      showOption: true,
      label: "PDF",
      actionHandler: () =>
        dispatch(
          downloadCityPdfReport(
            campaignId,
            cityId,
            campaignTitle,
            cityName,
            mapCenter,
            mapZoom
          )
        ),
    },
    {
      showOption: isMediaSitesPresent,
      label: "Medias PDF",
      actionHandler: () =>
        dispatch(
          downloadCityMediasPdfReports(
            campaignId,
            cityId,
            campaignTitle,
            cityName
          )
        ),
    },
  ];

  if (downloadingReport || downloadCityMediasPdfReportsLoading) {
    return <Spinner className="spinner-border mx-3" />;
  }

  return (
    <Dropdown style="mt-0" buttonName="Download City Reports">
      <div className="dropdown-menu dropdown-menu-right">
        {reportActionsArray.map((action, index) => {
          const { showOption, label, actionHandler } = action;
          if (!showOption) {
            return null;
          }
          return (
            <button
              key={index}
              className="dropdown-item"
              onClick={actionHandler}
            >
              {label}
            </button>
          );
        })}
      </div>
    </Dropdown>
  );
}

function PageHeaderSection({
  campaign = {},
  citySelected = {},
  targetGroupName,
  mapCenter,
  mapZoom,
}) {
  const { cityName, cityId } = citySelected;

  const { title = "", id, infoByCity = {} } = campaign;
  const cityInfo = infoByCity?.[cityId] || {};
  const { mediaCount } = cityInfo || {};

  const pageTitle =
    title + " :: " + cityName + " | TargetGroup :: " + targetGroupName;

  const pageActions = (
    <DownloadReport
      campaignId={id}
      cityId={cityId}
      campaignTitle={title}
      cityName={cityName}
      mediaCount={mediaCount}
      mapCenter={mapCenter}
      mapZoom={mapZoom}
    />
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}

/**
 * Page
 */
export default function CampaignCityViewPage() {
  const { id, cityId } = useParams();

  // state
  const [mapCenter, setMapCenter] = useState([]);
  const [mapZoom, setMapZoom] = useState(MapZoom.zoomLevel9);

  //Selector States
  const citySummaryLoading = useSelector(
    (state) => state.campaignCityReport.citySummaryLoading
  );
  const { campaign = {}, summary: citySummary = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );
  const { population } = citySummary;

  const {
    targetGroupName = "",
    targetGroupId = "",
    budget = 0,
    spend = 0,
  } = campaign;

  // City media summary
  const cityMediaLoading = useSelector(
    (state) => state.campaignMedia.cityLoading
  );
  const cityMediaSummary = useSelector(
    (state) => state.campaignMedia.citySummary
  );
  const cityMediaSummaryResult = cityMediaSummary.result || {};
  const { items = [], pagination = {} } = cityMediaSummaryResult;

  // Create Media Modal Key
  const openMediaForm = useSelector((state) => state.campaignMedia.openModal);

  const citySelected = {
    cityId: cityId,
    cityName: citySummary.cityName || cityId,
  };

  // Link to pass in CampaignSidebar
  const redirectLinkObj = {
    campaignOverviewLink: RedirectTo.campaignViewPageUrl.replace(
      ":campaignId",
      id
    ),
    citySummaryLink: RedirectTo.campaignCityViewPageUrl.replace(
      ":campaignId",
      id
    ),
  };

  // TODO: as of now we are assuming pageSize of 1000 only to unblock
  // PDF report, need to look back on this..
  const pageNumber = 1,
    pageSize = PageSize.CitySummary;

  //Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cancelAppliedFilters());

    dispatch(getCityOverview(id, cityId));
    dispatch(getCampaignMediaByCity(id, cityId, pageNumber, pageSize));
  }, [dispatch, id, cityId, pageSize]);

  // Functions
  function loadCityMedia() {
    dispatch(getCampaignMediaByCity(id, cityId, pageNumber, pageSize, true));
  }

  //checks for page loading
  if (cityMediaLoading || citySummaryLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }
  return (
    <>
      {/* Sidebar */}
      <CampaignSidebar
        campaign={campaign}
        citySelected={citySelected}
        redirectLinkObj={redirectLinkObj}
      />

      <div className="content-wrapper" id="cont-city-details">
        {/* Page Header */}
        <PageHeaderSection
          campaign={campaign}
          citySelected={citySelected}
          targetGroupName={targetGroupName}
          mapCenter={mapCenter}
          mapZoom={mapZoom}
        />

        <div className="page-content">
          {/* Performance Card */}
          <CampaignCityPerformance
            citySummary={citySummary}
            budget={budget}
            spend={spend}
          />

          {/* City Map View */}
          <CampaignCityMapView
            mediaList={items}
            targetGroupId={targetGroupId}
            mapCenter={mapCenter}
            setMapCenter={setMapCenter}
            mapZoom={mapZoom}
            setMapZoom={setMapZoom}
          />

          {/* City Site Option */}
          <CityViewSiteOptions
            loadCityMedia={loadCityMedia}
            targetGroupId={targetGroupId}
          />

          {/* Media Table */}
          <CampaignCityViewMediaTable
            mediaList={items}
            pagination={pagination}
            loadCityMedia={loadCityMedia}
            cityPopulation={population}
          />
        </div>
      </div>

      {/** Modals */}
      {openMediaForm && (
        /* Modal :: Create CampaignMedia */
        <CampaignMediaForm campaignId={id} cityId={cityId} />
      )}
    </>
  );
}
