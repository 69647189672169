import { LocationSummary } from "../../constants/action-constants/LocationSummaryActionConstants";

export const getLocationSummary = (data) => ({
  type: LocationSummary.GET_LOCATION_SUMMARY,
  payload: data,
});

export const resetLocationSummary = () => ({
  type: LocationSummary.RESET_LOCATION_SUMMARY,
});
