export const CampaignMedia = {
  // Campaign Media Create Actions
  CAMPAIGN_MEDIA_CREATE: "CAMPAIGN_MEDIA_CREATE",
  CAMPAIGN_MEDIA_CREATE_FAILURE: "CAMPAIGN_MEDIA_CREATE_FAILURE",
  CAMPAIGN_MEDIA_CREATE_SUCCESS: "CAMPAIGN_MEDIA_CREATE_SUCCESS",

  // Open and close create media modal
  OPEN_CREATE_MEDIA_MODAL: "OPEN_CREATE_MEDIA_MODAL",
  CLOSE_CREATE_MEDIA_MODAL: "CLOSE_CREATE_MEDIA_MODAL",

  // Generate mavin metrics
  GENERATE_MAVIN_METRICS: "GENERATE_MAVIN_METRICS",
  GENERATE_MAVIN_METRICS_SUCCESS: "GENERATE_MAVIN_METRICS_SUCCESS",
  GENERATE_MAVIN_METRICS_FAILURE: "GENERATE_MAVIN_METRICS_FAILURE",

  // Get media details
  GET_MEDIA_BY_ID: "GET_MEDIA_BY_ID",
  GET_MEDIA_BY_ID_SUCCESS: "GET_MEDIA_BY_ID_SUCCESS",
  GET_MEDIA_BY_ID_FAILURE: "GET_MEDIA_BY_ID_FAILURE",

  //get campaign by city
  CAMPAIGN_CITYVIEW: "CAMPAIGN_CITYVIEW",
  CAMPAIGN_CITYVIEW_SUCCESS: "CAMPAIGN_CITYVIEW_SUCCESS",
  CAMPAIGN_CITYVIEW_FAILURE: "CAMPAIGN_CITYVIEW_FAILURE",

  // Upload campaign media images
  UPLOAD_FILE_ITEMS: "UPLOAD_FILE_ITEMS",
  SET_FILE_ITEMS_LOADING: "SET_FILE_ITEMS_LOADING",
  UPLOAD_FILE_ITEMS_SUCCESS: "UPLOAD_FILE_ITEMS_SUCCESS",
  UPLOAD_FILE_ITEMS_FAILURE: "UPLOAD_FILE_ITEMS_FAILURE",
  SKIP_UPLOAD_FILE_ITEMS: "SKIP_UPLOAD_FILE_ITEMS",

  // update campaign media duration
  UPDATE_CAMPAIGN_MEDIA_DURATION: "UPDATE_CAMPAIGN_MEDIA_DURATION",
  UPDATE_CAMPAIGN_MEDIA_DURATION_SUCCESS:
    "UPDATE_CAMPAIGN_MEDIA_DURATION_SUCCESS",
  UPDATE_CAMPAIGN_MEDIA_DURATION_FAILURE:
    "UPDATE_CAMPAIGN_MEDIA_DURATION_FAILURE",

  // add campaign media
  ADD_CAMPAIGN_MEDIA: "ADD_CAMPAIGN_MEDIA",
  ADD_CAMPAIGN_MEDIA_SUCCESS: "ADD_CAMPAIGN_MEDIA_SUCCESS",
  ADD_CAMPAIGN_MEDIA_FAILURE: "ADD_CAMPAIGN_MEDIA_FAILURE",

  // remove campaign media
  REMOVE_CAMPAIGN_MEDIA: "REMOVE_CAMPAIGN_MEDIA",
  REMOVE_CAMPAIGN_MEDIA_SUCCESS: "REMOVE_CAMPAIGN_MEDIA_SUCCESS",
  REMOVE_CAMPAIGN_MEDIA_FAILURE: "REMOVE_CAMPAIGN_MEDIA_FAILURE",

  // Campaign Media Report
  DOWNLOAD_MEDIA_PDF_REPORT: "DOWNLOAD_MEDIA_PDF_REPORT",
  DOWNLOAD_MEDIA_PDF_REPORT_SUCCESS: "DOWNLOAD_MEDIA_PDF_REPORT_SUCCESS",
  DOWNLOAD_MEDIA_PDF_REPORT_FAILURE: "DOWNLOAD_MEDIA_PDF_REPORT_FAILURE",
};
