import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Actions
import { getLocationSummary } from "../../../actions/location-summary/LocationSummaryActions";

//Components
import SocioEconomicIndicators from "./SocioEconomicIndicators";
import Landmarks from "./Landmarks";
import LocationSummaryPieChart from "./LocationSummaryPieChart";
import Population from "./Population";
import RadiusSelector from "./RadiusSelector";
import AlertMessage from "../alert-message/AlertMessage";

/**
 * Location Summary Section
 */
export default function LocationSummary({
  latitude,
  longitude,
  radius = 1000,
  setRadius = () => {},
}) {
  const dispatch = useDispatch();

  //Selector
  const data = useSelector((state) => state.locationSummary.data);
  const isLoading = useSelector((state) => state.locationSummary.dataLoading);

  //Dispatch
  useEffect(() => {
    dispatch(getLocationSummary({ latitude, longitude, distance: radius }));
  }, [dispatch, latitude, longitude, radius]);

  //Checks for data
  if (!data) {
    return (
      <AlertMessage
        className="justify-content-center p-3"
        textMessage="Location Summary is not available for this Site."
        isLoading={false}
      />
    );
  }

  const { summary = {}, points = [] } = data;

  const {
    "poi-dist": poiDist,
    population,
    restaurants,
    "realty-society": realtySociety,
  } = summary;

  return (
    <>
      {/* Title And Radius Selector */}
      <RadiusSelector
        title={"Location Summary Within"}
        onItemSelect={setRadius}
        isLoading={isLoading}
        className="py-2"
      />

      <div className="row">
        {/* Pie-Chart */}
        <LocationSummaryPieChart className="col-6" poiDist={poiDist} />

        <div className="col-6">
          {/* Population info */}
          <Population population={population?.total} />
          <hr className="divider"></hr>

          {/* Socio-economic indicators */}
          <SocioEconomicIndicators
            restaurants={restaurants}
            realtySociety={realtySociety}
          />
          <hr className="divider"></hr>

          {/* Landmarks */}
          <Landmarks
            points={points}
            latitude={latitude}
            longitude={longitude}
          />
          <hr className="divider"></hr>
        </div>
      </div>
    </>
  );
}
