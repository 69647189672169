import { Sec } from "../../constants/action-constants/map-view/SecActionConstants";

export const getSecData = (cityBBox, sec) => ({
  type: Sec.GET_SEC_INFO,
  payload: { cityBBox, sec },
});

export const selectSec = (sec) => ({
  type: Sec.SELECT_SEC,
  payload: { sec },
});

export const removeSelectedSec = (sec) => ({
  type: Sec.REMOVE_SELECTED_SEC,
  payload: { sec },
});

export const resetSecInfo = () => ({
  type: Sec.RESET_SEC,
});
