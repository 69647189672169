import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

//Api
import {
  getOrgProfileInfo,
  updateOrgProfileInfo,
  updateOrgProfileLogoFn,
} from "../../apis/OrgProfileAPI";

//Constans, Utils
import { OrgProfile } from "../../constants/action-constants/org/OrgProfileActionConstants";
import { getErrorMessage } from "../../utils/util";

function* getOrgProfile() {
  try {
    const orgProfile = yield getOrgProfileInfo();
    yield put({
      type: OrgProfile.GET_ORG_PROFILE_SUCCESS,
      payload: { orgProfile },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: OrgProfile.GET_ORG_PROFILE_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* updateOrgProfile(action) {
  try {
    const { orgProfileObj } = action.payload;
    yield updateOrgProfileInfo(orgProfileObj);
    yield put({
      type: OrgProfile.UPDATE_ORG_PROFILE_SUCCESS,
    });
    toast.success("Profile updated successfully");
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: OrgProfile.UPDATE_ORG_PROFILE_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* updateOrgProfileLogo(action) {
  try {
    const { files } = action.payload;
    yield updateOrgProfileLogoFn(files[0]);
    const logo = URL.createObjectURL(files[0]);
    yield put({
      type: OrgProfile.UPDATE_ORG_PROFILE_LOGO_SUCCESS,
      payload: { logo },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: OrgProfile.UPDATE_ORG_PROFILE_LOGO_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(OrgProfile.GET_ORG_PROFILE, getOrgProfile),
    takeLatest(OrgProfile.UPDATE_ORG_PROFILE, updateOrgProfile),
    takeLatest(OrgProfile.UPDATE_ORG_PROFILE_LOGO, updateOrgProfileLogo),
  ]);
}
