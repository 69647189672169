import { all, put, takeLatest } from "redux-saga/effects";

// Api
import { getLtsInfoFn } from "../../apis/mavin-tools/LtsToolAPI";

// Constants & Utils
import { Lts } from "../../constants/action-constants/mavin-tools/LtsActionConstants";
import { getErrorMessage } from "../../utils/util";

function* getLtsInfo(action) {
  try {
    const { mediaInfoBean } = action.payload;

    // Api call
    const ltsInfo = yield getLtsInfoFn(mediaInfoBean);

    yield put({
      type: Lts.GET_LTS_INFO_SUCCESS,
      payload: { ltsInfo, mediaInfoBean },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put({
      type: Lts.GET_LTS_INFO_FAILURE,
      payload: errorMessage,
    });
  }
}

export default function* root() {
  yield all([takeLatest(Lts.GET_LTS_INFO, getLtsInfo)]);
}
