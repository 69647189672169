import { Filter } from "../../constants/action-constants/filter/FilterActionConstants";

export const applyFilters = (filters) => ({
  type: Filter.APPLY,
  payload: { filters },
});

export const cancelFilters = () => ({
  type: Filter.CANCEL,
});

export const cancelAppliedFilters = () => ({
  type: Filter.CANCEL_APPLIED_FILTERS,
});

export const selectFilter = (filter) => ({
  type: Filter.SELECT,
  payload: {
    filter,
  },
});

export const unSelectFilter = (filter) => ({
  type: Filter.UNSELECT,
  payload: {
    filter,
  },
});

export const selectFilters = (filters) => ({
  type: Filter.SELECT_MULTIPLE,
  payload: {
    filters,
  },
});

export const unSelectFilters = (filters) => ({
  type: Filter.UN_SELECT_MULTIPLE,
  payload: {
    filters,
  },
});
