import { LocationOfIntrest } from "../../action-constants/LocationOfIntrestActionConstants";

// These below actions are for "City-Selection" form
export const openLocationOfIntrestForm = () => ({
  type: LocationOfIntrest.OPEN_LOCATION_OF_INTREST_MODAL,
});

export const closeLocationOfIntrestForm = () => ({
  type: LocationOfIntrest.CLOSE_LOCATION_OF_INTREST_MODAL,
});

export const addLocationOfIntrestInfluenceCircle = () => ({
  type: LocationOfIntrest.ADD_LOCATION_OF_INTREST_INFLUENCE_CIRCLE,
});

export const removeLocationOfIntrestInfluenceCircle = () => ({
  type: LocationOfIntrest.REMOVE_LOCATION_OF_INTREST_INFLUENCE_CIRCLE,
});

export const setLocationOfIntrestInfluenceCircleRadius = (radius) => ({
  type: LocationOfIntrest.SET_LOCATION_OF_INTREST_INFLUENCE_CIRCLE_RADIUS,
  payload: { radius },
});

export const resetLocationOfIntrestInfluenceCircleRadius = () => ({
  type: LocationOfIntrest.RESET_LOCATION_OF_INTREST_INFLUENCE_CIRCLE_RADIUS,
});
