import { RouteAnalysis } from "../../constants/action-constants/mavin-tools/RouteAnalysisActionConstants";

export const getRouteSummary = (
  stretchId,
  targetGroupId,
  startTS,
  duration
) => ({
  type: RouteAnalysis.GET_ROUTE_SUMMARY,
  payload: { stretchId, targetGroupId, startTS, duration },
});

export const clearRouteSummary = () => ({
  type: RouteAnalysis.CLEAR_ROUTE_SUMMARY,
});
