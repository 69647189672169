import { all, put, takeLatest } from "redux-saga/effects";

// Api
import { getReachAndFrequencySummaryFn } from "../../apis/mavin-tools/ReachAndFrequencyToolAPI";

// Constants & Utils
import { ReachAndFrequency } from "../../constants/action-constants/mavin-tools/ReachAndFrequencyActionConstants";
import { getErrorMessage } from "../../utils/util";

function* getReachAndFrequencySummary(action) {
  try {
    const { segmentId, startDate, duration } = action.payload;

    // Api call
    const { result } = yield getReachAndFrequencySummaryFn(
      segmentId,
      startDate,
      duration
    );

    yield put({
      type: ReachAndFrequency.GET_REACH_AND_FREQUENCY_SUMMARY_SUCCESS,
      payload: { reachAndFrequencySummary: result },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ReachAndFrequency.GET_REACH_AND_FREQUENCY_SUMMARY_FAILURE,
      payload: errorMessage,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ReachAndFrequency.GET_REACH_AND_FREQUENCY_SUMMARY,
      getReachAndFrequencySummary
    ),
  ]);
}
