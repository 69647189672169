// Action related to "OTS TOOL"
export const Ots = {
  // By Segment Id
  GET_SEGMENT_OTS_INFO: "GET_SEGMENT_OTS_INFO",
  GET_SEGMENT_OTS_INFO_SUCCESS: "GET_SEGMENT_OTS_INFO_SUCCESS",
  GET_SEGMENT_OTS_INFO_FAILURE: "GET_SEGMENT_OTS_INFO_FAILURE",

  // Clear Data
  CLEAR_SEGMENT_OTS_INFO: "CLEAR_SEGMENT_OTS_INFO",

  // for "OTS" tool new methodology
  // Ots and reach info By Segment Id
  GET_SEGMENT_OTS_AND_REACH_INFO: "GET_SEGMENT_OTS_AND_REACH_INFO",
  GET_SEGMENT_OTS_AND_REACH_INFO_SUCCESS:
    "GET_SEGMENT_OTS_AND_REACH_INFO_SUCCESS",
  GET_SEGMENT_OTS_AND_REACH_INFO_FAILURE:
    "GET_SEGMENT_OTS_AND_REACH_INFO_FAILURE",

  // Clear segment ots and reach info
  CLEAR_SEGMENT_OTS_AND_REACH_INFO: "CLEAR_SEGMENT_OTS_AND_REACH_INFO",
};
