export const orgDataLayersSearchUrl = "/api/v3/org/data_layer/search";
export const restoreDataLayerUrl =
  "/api/v3/org/data_layer/:dataLayerId/restore";
export const archiveDataLayerUrl =
  "/api/v3/org/data_layer/:dataLayerId/archive";
export const getDataLayerUrl = "/api/v3/org/data_layer/:dataLayerId";

export const dataLayerDownloadTemplateUrl =
  "/api/v3/org/data_layer/template/:layerType";
export const dataLayerUploadTemplateUrl = "/api/v3/org/data_layer";
