import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Apis
import {
  getCampaignCityPerformanceFn,
  getCampaignPerformanceByIdFn,
} from "../../apis/CampaignPerformanceAPI";

// Constants and Utils
import { ActionTypes } from "../../../constants/ActionConstants";
import { getErrorMessage } from "../../../utils/util";

export function* getCampaignPerformanceById(action) {
  const { campaignId } = action.payload;

  try {
    const data = yield getCampaignPerformanceByIdFn(campaignId);

    yield put({
      type: ActionTypes.CampaignPerformance
        .GET_CAMPAIGN_PERFORMANCE_BY_ID_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignPerformance
        .GET_CAMPAIGN_PERFORMANCE_BY_ID_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* getCampaignCityPerformance(action) {
  const { campaignId, cityId } = action.payload;
  try {
    const cityPerformance = yield getCampaignCityPerformanceFn(
      campaignId,
      cityId
    );

    yield put({
      type: ActionTypes.CampaignPerformance
        .GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID_SUCCESS,
      payload: { cityPerformance },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignPerformance
        .GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.CampaignPerformance.GET_CAMPAIGN_PERFORMANCE_BY_ID,
      getCampaignPerformanceById
    ),
    takeLatest(
      ActionTypes.CampaignPerformance.GET_CAMPAIGN_PERFORMANCE_BY_CITY_ID,
      getCampaignCityPerformance
    ),
  ]);
}
