import { Seller } from "../../constants/action-constants/seller/SellerActionConstants";

export const getSellerInventorySummary = () => ({
  type: Seller.GET_SELLER_INVENTORY_SUMMARY,
});

export const getSellerInfoById = () => ({
  type: Seller.GET_SELLER_INFO_BY_ID,
});

export const updateSellerInfo = (sellerInfoBean) => ({
  type: Seller.UPDATE_SELLER_INFO,
  payload: { sellerInfoBean },
});

export const updateSellerLogo = (file) => ({
  type: Seller.UPDATE_SELLER_LOGO,
  payload: { file },
});

export const searchSeller = (searchText) => ({
  type: Seller.SEARCH_SELLER,
  payload: { searchText },
});

export const resetSearchedSeller = (searchText) => ({
  type: Seller.CLEAR_SEARCHED_SELLER,
  payload: { searchText },
});

export const enableSellerSite = () => ({
  type: Seller.ENABLE_SELLER_SITE,
});

export const disableSellerSite = () => ({
  type: Seller.DISABLE_SELLER_SITE,
});
