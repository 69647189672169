// Constants and Utils
import { constructLineChartData } from "../../../utils/ChartUtils";
import { IsTrafficOtsMethodology } from "../../../common-utils/subscription-utils/SubscriptionUtil";
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
// Components
import { LineChart } from "../../../components/charts/Charts";
import { MonthlyEstImpressionTable } from "../../../mavin/components/media-info/MonthlyEstImpressionTable";
import AlertMessage from "../../../mavin/components/alert-message/AlertMessage";

// Hourly Traffic and Impressions SECTION
function TrafficAndImpressionSection({ roads }) {
  // if "NO-ROAD-FOUND"
  if (roads.length === 0) {
    return (
      <>
        <AlertMessage
          className="justify-content-center p-3"
          textMessage="Unable to find any Roads nearby this Site."
          isLoading={false}
        />
        <hr className="divider"></hr>
      </>
    );
  }

  // from multiple roads(may or may not), we are taking first-road for "Traffic-Data"
  const singleRoadInfo = roads[0] || {};

  const {
    avgSpeedProfile = {},
    maxSpeedProfile = {},
    minSpeedProfile = {},
    otsAvg,
    otsLitAvg,
  } = singleRoadInfo;

  // checking whether "traffic-data" available or not for any one of these 3 profiles
  if (Object.values(avgSpeedProfile).length === 0) {
    return (
      <>
        <AlertMessage
          className="justify-content-center p-3"
          textMessage="Traffic Data is not available for this Site."
          isLoading={false}
        />
        <hr className="divider"></hr>
      </>
    );
  }

  // preparing traffic-data bean
  const trafficData = {
    hour_avg: avgSpeedProfile,
    hour_max: maxSpeedProfile,
    hour_min: minSpeedProfile,
  };

  const chartTrafficData = constructLineChartData(trafficData);

  // preparing data for "Estimate-Impression-Table"
  const impressionInfo = [
    {
      title: "Avg.",
      otsLit: formatText(toLocaleString(otsLitAvg)),
      ots: formatText(toLocaleString(otsAvg)),
    },
  ];
  const isTrafficMethodology = IsTrafficOtsMethodology();

  return (
    <>
      <div className="my-4 d-flex">
        {/* Hourly traffic chart */}
        <div className="col-6 pl-0">
          <h5 className="mb-3">{"Hourly Traffic"}</h5>
          <LineChart data={chartTrafficData} />
        </div>

        {/* Est Monthly Impressions */}
        {isTrafficMethodology && (
          <MonthlyEstImpressionTable
            title={"Average Monthly Impressions Estimates"}
            className={"col-6 pr-0"}
            impressionInfo={impressionInfo}
          />
        )}
      </div>
      <hr className="divider"></hr>
    </>
  );
}

export default TrafficAndImpressionSection;
