import { ActionTypes } from "../../../constants/ActionConstants";

export const mediaSetupMonitoring = (
  inputFieldBean,
  campaignId,
  cityId,
  mediaIds
) => ({
  type: ActionTypes.CampaignConfirmation.MEDIA_SETUP_MONITORING,
  payload: { inputFieldBean, campaignId, cityId, mediaIds },
});
