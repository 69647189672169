import { SingleSiteAnalyser } from "../../constants/action-constants/mavin-tools/SingleSiteAnalyserActionConstants";

const initialState = {
  // for Single Site Analyser Tool
  // ---------------------------------------------------
  singleSiteAnalyserInfo: {},
  singleSiteAnalyserInfoErr: "",
  singleSiteAnalyserInfoLoading: false,
  mediaInfoBean: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SingleSiteAnalyser.GET_SINGLE_SITE_ANALYSER_INFO:
      return {
        ...state,
        singleSiteAnalyserInfoLoading: true,
      };

    case SingleSiteAnalyser.GET_SINGLE_SITE_ANALYSER_INFO_SUCCESS:
      return {
        ...state,
        singleSiteAnalyserInfoLoading: false,
        singleSiteAnalyserInfo: action.payload.singleSiteAnalyserInfo,
        mediaInfoBean: action.payload.mediaInfoBean,
      };

    case SingleSiteAnalyser.GET_SINGLE_SITE_ANALYSER_INFO_FAILURE:
      return {
        ...state,
        singleSiteAnalyserInfoLoading: false,
        singleSiteAnalyserInfoErr: action.payload,
      };

    case SingleSiteAnalyser.RESET_SINGLE_SITE_ANALYSER_INFO:
      return initialState;

    default:
      return state;
  }
};
