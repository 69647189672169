// Url Imports
import { RedirectTo } from "../urls/PageLinksURL";

// Url Constants ----------------------------------------------------
const {
  homeUrl,
  campaignViewPageUrl,
  campaignCityViewPageUrl,
  campaignPlanOverviewPageUrl,
  campaignPlanCityViewPageUrl,
  monitorCampaignViewPageUrl,
  monitorCityViewPageUrl,
  orgDataLayerListUrl,
  mediaSiteSelectionPageUrl,
} = RedirectTo;

// (linkTo & label) Constants here
// --------------------------------------------------------------------------
const home = {
  label: "Home",
  to: homeUrl,
};

const campaignSummary = (campaignId) => ({
  label: "Campaign Summary",
  to: campaignViewPageUrl.replace(":campaignId", campaignId),
});

const citySummary = (campaignId, cityId, cityName) => ({
  label: `${cityName} Summary`,
  to: campaignCityViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId),
});

const planningCampaignSummary = (campaignId) => ({
  label: "Campaign",
  to: campaignPlanOverviewPageUrl.replace(":campaignId", campaignId),
});

const planningCitySummary = (campaignId, cityId, cityName) => ({
  label: `${cityName}`,
  to: campaignPlanCityViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId),
});

const monitorCampaignSummary = (id) => ({
  label: "Monitor Campaign Summary",
  to: monitorCampaignViewPageUrl.replace(":campaignId", id),
});

const monitoringCitySummary = (campaignId, cityId, cityName) => ({
  label: `Monitor ${cityName}`,
  to: monitorCityViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId),
});

// as of now this Url Page is not used
const mediaSiteSelection = {
  label: "Media Site Selection",
  to: mediaSiteSelectionPageUrl,
};

const dataLayersDashboard = {
  label: "Data Layers Dahboard",
  to: orgDataLayerListUrl,
};

// (only label) constants here
// ---------------------------------------------------------------------------------
const campaign = { label: "Campaign Summary" };
const city = (cityName) => ({ label: `${cityName} Summary` });

const monitorCampaign = { label: "Monitor Campaign Summary" };
const monitorCity = (cityName) => ({ label: `Monitor ${cityName}` });

const mapView = { label: "Map View" };
const planningMapView = { label: "Plan on Map" };

const mediaSite = { label: "Campaign Site" };

const latestMonitorLog = { label: "Monitor Media Logs" };
const selectionViaMap = { label: "Selection Via Map" };

const viewCustomDataLayer = { label: "Upload New Data Layer" };

// BreadCrumb Functions for pages here
// -------------------------------------------------------------------------------------
export function bcForCampaignSummary() {
  return [home, campaign];
}
export function bcForCitySummary(campaignId, cityName) {
  return [home, campaignSummary(campaignId), city(cityName)];
}

export function bcForMonitorCampaignSummary() {
  return [home, monitorCampaign];
}
export function bcForMonitorCitySummary(id, cityName) {
  return [home, monitorCampaignSummary(id), monitorCity(cityName)];
}

export function bcForAnalyserMediaSite(campaignId, cityId, cityName) {
  return [
    home,
    campaignSummary(campaignId),
    citySummary(campaignId, cityId, cityName),
    mediaSite,
  ];
}
export function bcForMonitoringMediaSite(campaignId, cityId, cityName) {
  return [
    home,
    monitorCampaignSummary(campaignId),
    monitoringCitySummary(campaignId, cityId, cityName),
    mediaSite,
  ];
}

export function bcForAnalyserMapView(campaignId, cityId, cityName) {
  return [
    home,
    campaignSummary(campaignId),
    citySummary(campaignId, cityId, cityName),
    mapView,
  ];
}
export function bcForPlanningMapView(campaignId, cityId, cityName) {
  return [
    home,
    planningCampaignSummary(campaignId),
    planningCitySummary(campaignId, cityId, cityName),
    planningMapView,
  ];
}

export function bcForLatestLogUpdates(campaignId, cityId, cityName) {
  return [
    home,
    monitorCampaignSummary(campaignId),
    monitoringCitySummary(campaignId, cityId, cityName),
    latestMonitorLog,
  ];
}

export function bcForSelectionViaMap() {
  return [home, mediaSiteSelection, selectionViaMap];
}

export function bcForCustomDataLayers() {
  return [dataLayersDashboard, viewCustomDataLayer];
}
