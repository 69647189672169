// Api
import { LocalStorageKeys } from "../constants/GeneralConstants";
import {
  changePasswordUrl,
  loginUrl,
  resetPasswordUrl,
  forgotPasswordUrl,
} from "../urls/AuthURL";

// Utils & Constants
import { sendCreateOrUpdateRequest } from "../utils/api/apiUtils";
import { getItem } from "../utils/localstorage";

// Login
export async function userLogin(email, password) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const bodyFormData = new FormData();
  bodyFormData.set("username", email);
  bodyFormData.set("password", password);

  return sendCreateOrUpdateRequest(
    loginUrl,
    bodyFormData,
    false,
    "POST",
    headerParams
  );
}

// Reset Password
export async function resetUserPassword(newPassword, token) {
  const bodyFormData = new FormData();
  bodyFormData.set("newPassword ", newPassword);

  const url = resetPasswordUrl.replace(":token", token);
  return sendCreateOrUpdateRequest(url, bodyFormData, false);
}

// forgot password
export async function forgotPasswordFn(email) {
  const url = forgotPasswordUrl.replace(":userEmail", email);

  return sendCreateOrUpdateRequest(url, {}, false);
}

export async function changeUserPassword(oldPassword, newPassword) {
  const userObj = JSON.parse(getItem(LocalStorageKeys.USER));
  const userId = userObj.userId;
  const url = changePasswordUrl.replace(":userId", userId);

  const bodyFormData = new FormData();
  bodyFormData.set("oldPassword", encodeURIComponent(oldPassword));
  bodyFormData.set("newPassword", encodeURIComponent(newPassword));

  const headerParams = {
    " Content-Type": "application/x-www-form-urlencoded",
  };

  return sendCreateOrUpdateRequest(
    url,
    bodyFormData,
    true,
    "PUT",
    headerParams
  );
}
