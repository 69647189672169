import { ActionTypes } from "../../constants/ActionConstants";

const initialState = {
  fixedPriceMedia: {},
  cpmPriceMedia: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SubmissionImpact
      .ADD_CPM_PRICE_MEDIA_FOR_SUBMISSION_IMPACT: {
      const { mediaId, otsSplit, duration } = action.payload;
      return {
        ...state,
        cpmPriceMedia: {
          ...state.cpmPriceMedia,
          [mediaId]: { otsSplit: otsSplit, duration: duration },
        },
      };
    }

    case ActionTypes.SubmissionImpact
      .REMOVE_CPM_PRICE_MEDIA_FOR_SUBMISSION_IMPACT: {
      const { mediaId } = action.payload;
      const cpmPriceMedia = { ...state.cpmPriceMedia };
      delete cpmPriceMedia[mediaId];
      return {
        ...state,
        cpmPriceMedia,
      };
    }

    case ActionTypes.SubmissionImpact
      .ADD_FIXED_PRICE_MEDIA_FOR_SUBMISSION_IMPACT: {
      const { mediaId, otsSplit, cost, duration } = action.payload;
      return {
        ...state,
        fixedPriceMedia: {
          ...state.fixedPriceMedia,
          [mediaId]: { otsSplit: otsSplit, cost: cost, duration: duration },
        },
      };
    }

    case ActionTypes.SubmissionImpact
      .REMOVE_FIXED_PRICE_MEDIA_FOR_SUBMISSION_IMPACT: {
      const { mediaId } = action.payload;
      const fixedPriceMedia = { ...state.fixedPriceMedia };
      delete fixedPriceMedia[mediaId];
      return {
        ...state,
        fixedPriceMedia,
      };
    }

    default:
      return state;
  }
};
