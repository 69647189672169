import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";
import { getSegmentDetails } from "../../apis/RoadSegmentAPI";
import { RoadSegment } from "../../constants/action-constants/RoadSegmentActionConstants";
import { getErrorMessage } from "../../utils/util";

// TODO : Same saga is present in planningRoadSegment sage can we refactor it
export function* getSegmentsData(action) {
  const { segmentIds } = action.payload;
  try {
    const segmentsMap = yield getSegmentDetails(segmentIds);
    yield put({
      type: RoadSegment.GET_ROAD_SEGMENTS_SUCCESS,
      payload: { segmentsMap },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: RoadSegment.GET_ROAD_SEGMENTS_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([takeLatest(RoadSegment.GET_ROAD_SEGMENTS, getSegmentsData)]);
}
